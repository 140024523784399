import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { getAllConfigFiles, postJsonData } from '../../services/apiParticulierService';
import { Formik, Form } from 'formik';
import { useCookies } from 'react-cookie';
import ResteAChargeSchema from './services/schema';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// Images
import logo from '../../assets/images/logo-maprimerenov.jpg';
import logoAnh from '../../assets/images/logo-anh.png';
import logoCee from '../../assets/images/logo-cee.png';
import logoFaire from '../../assets/images/logo-faire.png';
import logoRelance from '../../assets/images/logo-relance.jpg';

import './ResteACharge.scss';
import { ROUTE_MENTIONS_LEGALES } from '../../routing/paths';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const ResteACharge: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [, setCookie] = useCookies();

    const { push } = useHistory();
    const AlertSwal = withReactContent(Swal);

    const date = new Date();
    const year = date.getFullYear();

    let query = useQuery();
    const creds = { agentCode: query.get('agentCode'), password: query.get('password') };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    document.title = 'SOGYS.APP – RAC';

    const resteACharge = async (values: any) => {
        try {
            setLoading(true);

            let response = await postJsonData(`identify?${query}`);

            if (!response) {
                setLoading(false);
                throw new Error();
            }

            if (response.status) {
                await AlertSwal.fire<any>(loginError);
                setLoading(false);
                return true;
            } else {
                response.agentCode = creds.agentCode;
                response.agentPassword = creds.password;
                response.campagne = 'reste_a_charge';
                setCookie('Auth', JSON.stringify(response), { path: '/' });
                // load config files now, just after login success !
                await getAllConfigFiles();
                setLoading(false);
                push('/audit', { from: 'reste-a-charge' });
                return false;
            }
        } catch (error) {
            if (error) {
                await AlertSwal.fire<any>(loginError);
                setLoading(false);
            }
        }
    };

    const loginError: SweetAlertOptions<any> = {
        title: 'Impossible d’accéder à l’audit',
        text: `Votre url de connexion n’est pas reconnue. Merci de vous rapprocher de la personne vous l’ayant communiqué.`,
        icon: 'error',
        width: 550,
        confirmButtonText: 'Fermer',
        focusConfirm: true,
        customClass: {
            confirmButton: 'btn btn-primary min-width',
        },
    };

    return (
        <>
            <div className="container-form rac-container-form">
                <div className="form-sign-in al">
                    <div className="container-logo">
                        <h1 className="sr-only">Reste à charge, MaPrimeRenov'</h1>
                        <img src={logo} alt="MaPrimeRenov" className="rac-logo" />
                    </div>
                    <Formik
                        onSubmit={(values, formikHelpers) => {
                            resteACharge(values).then((reset: boolean | undefined) => {
                                if (reset) {
                                    formikHelpers.resetForm();
                                }
                            });
                        }}
                        initialValues={{
                            agentCode: query.get('agentCode'),
                        }}
                        validationSchema={ResteAChargeSchema}
                    >
                        {({ values }) => {
                            return (
                                <Form className="w-100">
                                    <div className="text-center">
                                        <h2 className="al-title">Bonjour</h2>

                                        <p className="al-text">
                                            Bienvenue sur votre espace de préinscription au programme «Reste à charge, MaPrimeRenov'». 30 mn suffiront à remplir
                                            les 8 étapes de votre dossier.
                                            <br />
                                            En cas de problème lors du remplissage une hotline est à votre disposition{' '}
                                            <span className="font-weight-bold">7 jours sur 7</span> et de <span className="font-weight-bold">8h à 22h</span> au{' '}
                                            <a href="tel:0826046046" className="al-link">
                                                0826 046 046
                                            </a>
                                            .
                                        </p>

                                        <p className="al-text">
                                            Vous recevrez des informations sur l’état d’avancement de votre dossier par mail, au fur et à mesure de son
                                            évolution.
                                        </p>

                                        <button className="btn btn-primary min-width" type="submit" disabled={loading || !values?.agentCode}>
                                            Démarrer mon dossier
                                        </button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>

                    <div className="container-logos" aria-hidden="true">
                        <div className="logos">
                            <img src={logoAnh} alt="" />
                        </div>

                        <div className="logos">
                            <img src={logoCee} alt="" />
                        </div>

                        <div className="logos">
                            <img src={logoRelance} alt="" />
                        </div>

                        <div className="logos">
                            <img src={logoFaire} alt="" />
                        </div>
                    </div>

                    <div className="legal">
                        Tous droits réservés - &copy;{' '}
                        <a href="http://www.sogys.fr" target="_blank" rel="noreferrer">
                            SOGYS
                        </a>{' '}
                        {year === 2021 ? '2021' : `2021 - ${year}`} -{' '}
                        <Link to={ROUTE_MENTIONS_LEGALES} target="_blank">
                            Mentions légales
                        </Link>
                    </div>
                </div>

                <div className="al-pave">
                    <div className="al-card">
                        <h2>&laquo; Les aides MaPrimeRenov' &raquo;</h2>

                        <p>
                            MaPrimeRenov' est une aide au financement de vos travaux de rénovation énergétique, qui dépend de vos ressources et des dépenses
                            prévues. Ce dispositif a évolué depuis 2021/22.
                        </p>

                        <p>
                            2 milliards d'euros : tel est le montant de l'enveloppe que le ministre de l'économie, Bruno Lemaire, vient de mettre sur la table
                            pour financer, en 2022, le dispositif MaPrimeRenov' qui rencontre un succès fulgurant. En effet, pour 2021, le Gouvernement estime
                            entre 700 000 et 800 000 le nombre d'aides distribuées, contre 500 000 initialement prévues. Près de 400 000 dossiers ont déjà été
                            déposés depuis le début de l'année, et 297 003 ont été acceptés !
                        </p>

                        <p>
                            Elargie, MaPrimeRenov' s'adresse aujourd'hui à tous les propriétaires, quels que soient leurs revenus et qu'ils soient occupants ou
                            bailleurs.
                        </p>

                        <p>Des aides pouvant aller jusqu'à 200 000&euro; et un reste à charge quasi nul en fonction de vos revenus.</p>

                        <p>Notre groupe a permis ces derniers mois et à lui seul de faire bénéficier d'une de ces aides à plus de 2000 foyer.</p>

                        <p>Ne perdez pas une seule seconde et prenez 30mn pour remplir votre dossier et nous nous chargeons du reste.</p>

                        <p>
                            &laquo; Réaliser une rénovation énergétique de sa maison c'est réduire ses factures d'énergies de 30 à 75%, augmenter son pouvoir
                            d'achat et valoriser sa maison &raquo;
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResteACharge;
