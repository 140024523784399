import * as Yup from 'yup';

const optionShape = Yup.object().shape({
    label: Yup.string(),
    value: Yup.number(),
});

const optionShapeRequired = optionShape
    .test('is-valid', '', (value) => {
        if (value && typeof value === 'object' && value.label && value.value) {
            return true;
        }
        return false;
    })
    .required();

const schemaPanel = () => {
    return Yup.object().shape({
        orientation: Yup.object()
            .test('is-valid-orientation', '', (value) => {
                if (value && typeof value === 'object' && value.label && value.value) {
                    return true;
                }

                return false;
            })
            .required(),
        columns: optionShapeRequired,
        lines: optionShapeRequired,
        color: Yup.object()
            .test('is-valid-color', '', (value) => {
                if (value && typeof value === 'object' && value.label && value.value) {
                    return true;
                }

                return false;
            })
            .required(),
    });
};

export default schemaPanel;
