import React from 'react';
import { AppFormLabel } from './AppFormLabel';
import { CustomSelect } from '../Select/CustomSelect';
import { FormikValues, FormikState, FormikHelpers, FieldInputProps } from 'formik';
import { SelectEvent } from '../Forms/AppFormUtils';
import { LabelledString } from '../../services/tools/TypeHelper';

/*
 Custom Formik select field
*/

type AppFormSelectFieldProps = {
    disabled?: boolean;
    labelName?: string;
    options: Array<LabelledString>;
    customClass?: string;
    isRequired?: boolean;
    placeholder?: null | string | number | boolean;
    color: string;
    field: FieldInputProps<FormikValues>;
    form: FormikState<FormikValues> & FormikHelpers<FormikValues>;
    inline?: boolean;
    infoTitle?: string;
    infoText?: string | undefined;
    labelVisible?: boolean;
    onCustomChange: (e: SelectEvent<LabelledString | null>) => void;
    customLabelClass: string;
    cdv?: boolean;
};

const AppFormSelectField: React.FC<AppFormSelectFieldProps> = ({
    disabled,
    labelName,
    options,
    isRequired,
    customClass = '',
    placeholder,
    inline = true,
    infoText = undefined,
    labelVisible = false,
    field: { name, value },
    form: { errors, touched, setFieldTouched, setFieldValue },
    onCustomChange,
    customLabelClass,
    cdv = false,
    ...props
}): JSX.Element => {
    const hasError = errors[name] && touched[name];

    const onChange = (option: unknown) => {
        setFieldTouched(name);
        setFieldValue(name, option as LabelledString | null);

        if (onCustomChange) {
            onCustomChange({ id: name, value: option as LabelledString | null });
        }
    };

    const classNames = `${inline ? 'form-row' : 'form-group-column'}${cdv ? ' cdv-fields' : ''} ${customClass}`;
    const labelClassNames = `${inline ? 'form-label' : ''}${cdv ? ' col-form-label text-end' : ''} ${customLabelClass}`;

    return (
        <div className={classNames}>
            <AppFormLabel
                htmlFor={name}
                labelTitle={labelName}
                isRequired={isRequired}
                hidden={labelVisible}
                infoText={infoText}
                customClass={labelClassNames}
                style={disabled ? { opacity: '0.35' } : {}}
                inline={inline}
            />
            <CustomSelect
                defaultValue={value ? { label: value.label, value: value.value } : null}
                value={value ? { label: value.label, value: value.value } : null}
                placeholder={placeholder}
                options={options}
                isDisabled={disabled}
                onChange={onChange}
                {...props}
            />
            {hasError && <span className="field__error">{errors[name] as unknown as string}</span>}
        </div>
    );
};
export default AppFormSelectField;
