import React from 'react';
import { Formik, Form, FormikConfig } from 'formik';

/*
 Custom Formik form
*/

export interface BaseRecord extends Record<string, unknown> {}

interface CustomProps {
    className?: string;
    children: React.ReactNode;
}

type FormProps<T> = CustomProps & FormikConfig<T>;

const AppForm = <T extends BaseRecord = BaseRecord>({ className, children, ...rest }: FormProps<T>): JSX.Element => (
    <Formik {...rest}>{() => <Form className={className}>{children}</Form>}</Formik>
);

export default AppForm;
