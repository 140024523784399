import { StylesConfig } from 'react-select';
import { LabelledString } from '../../../services/tools/TypeHelper';

export type FormValues = {
    length: string;
    width: string;
    height: string;
    heatedRoom: boolean | '';
    airExtraction: boolean | '';
    pressureReducer: boolean | '';
    paving: boolean | '';
    soilType: string;
    detachedPart: boolean | '';
    roomTankInstalledPicture: string;
    doorwayPicture: string;
    outsideWallPicture: string;
};

export const panelStyles: StylesConfig<LabelledString, false> = {
    menuList: (styles) => ({
        ...styles,
        color: '#434D5D',
        borderRadius: '4px',
        backgroundColor: 'white',
        padding: '0',
    }),

    indicatorSeparator: (styles) => ({
        ...styles,
        display: 'none',
    }),

    control: (styles) => ({
        ...styles,
        backgroundColor: '#FFF',
        color: '#434D5D',
        boxShadow: 'none',
        border: '1px solid #A1B5CC',
        minWidth: '70px',
        height: '43px',
        '&:focus': {
            border: '0',
            boxShadow: 'none',
        },
    }),

    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? '#EEFAFF' : 'white',
        color: '#434D5D',

        '&:not(:last-child)': {
            borderBottom: '1px solid #E4E4E4',
        },

        '&:hover': {
            backgroundColor: '#EEFAFF',
            color: '#434D5D',
        },
    }),

    placeholder: (styles) => ({
        ...styles,
        color: '#C1C1C1',
    }),

    singleValue: (styles) => ({
        ...styles,
        color: '#434D5D',
    }),
};

export const soilTypeList: Array<LabelledString> = [
    {
        label: 'Bois',
        value: 'bois',
    },
    {
        label: 'Carrelage',
        value: 'carrelage',
    },
    {
        label: 'Dalle béton',
        value: 'dalle beton',
    },
    {
        label: 'Terre',
        value: 'terre',
    },
    {
        label: 'Autre',
        value: 'autre',
    },
];
