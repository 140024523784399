import React from 'react';
import TopBar from '../components/top-bar/TopBar';
import AdminBar from '../components/admin-bar/AdminBar';

const PageLayout: React.FC = ({ children }) => {
    const className: any = children as any;

    return (
        <>
            <TopBar />
            <div className={'page-container ' + className?.props?.name + (className?.props?.name ? ' main-bar-display' : '')}>{children}</div>
            <AdminBar />
        </>
    );
};

export default PageLayout;
