export type GeoPosition = {
    lat: number;
    lng: number;
};

// GPS du sacré coeur!
export const DEFAULT_LAT = 48.8860944;
export const DEFAULT_LNG = 2.3427737;
export const DEFAULT_POSITION: GeoPosition = {
    lat: DEFAULT_LAT,
    lng: DEFAULT_LNG,
};

export type Path = GeoPosition[];

const PANEL_COLOR = 'color:0x434d5dFF|fillcolor:0x434d5dFF';
// HELP : https://developers.google.com/maps/documentation/maps-static/start?hl=fr

// URL of Google Static Maps + api key
const staticMapBaseUrl = 'https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyDxohMYOWq_Sb_bkcw38ooqqPVCHtqFdiI';

export const createImplantation = (center: GeoPosition, solarPanels?: Array<Path>) => {
    // URL of Google Static Maps
    let staticMapUrl = staticMapBaseUrl;

    // Set the Google Map scale
    staticMapUrl += '&scale=1';

    // Set the Google Map Center
    staticMapUrl += '&center=' + center.lat + ',' + center.lng;

    // Set the output format
    staticMapUrl += '&format=JPEG';

    // Set the Google Map Size
    staticMapUrl += '&size=600x386';

    // Set the Google Map Zoom
    staticMapUrl += '&zoom=18';

    // Set the Google Map Type
    staticMapUrl += '&maptype=satellite';

    solarPanels?.forEach((path) => {
        staticMapUrl += '&path=' + PANEL_COLOR;
        path.forEach((path) => (staticMapUrl += '|' + path.lat + ',' + path.lng));
    });

    return staticMapUrl;
};

const elevator = new window.google.maps.ElevationService();

export const getAltitude = async (location: google.maps.LatLngLiteral): Promise<number> => {
    const prom = new Promise<google.maps.ElevationResult>((resolve, reject) => {
        elevator.getElevationForLocations(
            { locations: [location] },
            (elevations: google.maps.ElevationResult[] | null, status: google.maps.ElevationStatus): void => {
                if (elevations === null || elevations.length === 0) {
                    resolve({ elevation: 0, location: null, resolution: 0 });
                    return;
                }

                resolve(elevations[0]);
                return;
            }
        );
    });

    return (await prom).elevation;
};
