import React from 'react';
import { PolymorphicComponentProps } from '../../services/componentsHelpers/polymorpgic';

type Props = {
    children: React.ReactNode;
    className?: string | undefined;
};

// `TextProps` now uses `PolymorphicComponentProps` to add the `as` prop and inherit its prop
type TextProps<C extends React.ElementType> = PolymorphicComponentProps<C, Props>;

export const Title = <C extends React.ElementType = 'h2'>({ as, children, className = undefined, ...other }: TextProps<C>) => {
    const Component = as || 'h2';

    return (
        <Component className={className} {...other}>
            {children}
        </Component>
    );
};
