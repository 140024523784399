import { Field, useFormikContext } from 'formik';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import CheckboxTriState from '../../../../components/checkbox/TriStateCheckbox';
import { getData } from '../../../../services/apiParticulierService';
import * as storageService from '../../../../services/localStorageService';

import { renderDefaultValueSelect } from '../../../../services/tools/selectValue';
import { LabelledString } from '../../../../services/tools/TypeHelper';
import { panelStyles } from '../PhotovoltaiqueEnums';
import { FormValues, coverConstructionYearList, tilesStateList } from '../PhotovoltaiqueEnums';
import classnames from 'classnames';

interface CoverProps {
    setValues: (values: React.SetStateAction<FormValues>, shouldValidate?: boolean | undefined) => void;
    values: FormValues;
}

const Cover: React.FC<CoverProps> = ({ setValues, values }) => {
    // Cover type list from API
    const [coverTypeList, setCoverTypeList] = useState<Array<LabelledString>>([]);
    const getCoverTypes = async () => {
        const data = await getData(`fixation/coverings/dropdown`);
        // Add 'autre' to the list
        data.push({ label: 'Autre', value: 'autre' });
        // Set full list into 'coverTypeList'
        setCoverTypeList(data);
    };

    // Call getCoverTypes function
    useEffect(() => {
        getCoverTypes();
    }, []);

    const { errors, touched } = useFormikContext<FormValues>();

    const getFieldClasses = (fieldName: keyof FormValues, required = true) => {
        const fieldValue = values[fieldName];
        const fieldError = errors[fieldName];
        const fieldTouched = touched[fieldName];
        const defaultClass = 'form-control';

        return classnames(defaultClass, {
            'has-value': fieldValue !== undefined && fieldValue !== null && fieldValue !== '',
            'is-required': required,
            'is-error': fieldError && fieldTouched,
            'is-valid': !fieldError && fieldTouched,
        });
    };

    return (
        <>
            <div className="row mb-3">
                <div className="col-12 col-md-4 mb-3 mb-md-0">
                    <div className="form-group">
                        <label htmlFor="coverType">
                            Type de couverture<sup>*</sup>
                        </label>
                        <Select
                            value={renderDefaultValueSelect(coverTypeList, values.coverType)}
                            name="coverType"
                            options={coverTypeList}
                            isMulti={false}
                            isClearable={false}
                            isSearchable={false}
                            onChange={(event: LabelledString | null) => {
                                if (event) {
                                    setValues({
                                        ...values,
                                        coverType: event.value,
                                    });

                                    storageService.setPrevisitValue('coverType', event.value, event.label);
                                }

                                if (event && event.value === 'autre') {
                                    setValues({
                                        ...values,
                                        coverType: event.value,
                                        coverTilesDetail: '',
                                    });

                                    storageService.setPrevisitValue('coverType', event.value, event.label);
                                    storageService.setPrevisitValue('coverTilesDetail', '');
                                }
                            }}
                            styles={panelStyles}
                            placeholder="Type de couverture"
                            className={
                                'basic-single' +
                                (values.coverType ? ' filled' : '') +
                                (touched.coverType && errors.coverType ? ' invalid' : '') +
                                (!values.coverType ? ' required' : '')
                            }
                        />
                    </div>
                </div>
                {values.coverType === 'autre' && (
                    <div className="col-12 col-md-4">
                        <div className="form-group">
                            <label htmlFor="coverTilesDetail">
                                Préciser si 'Autre'<sup>*</sup>
                            </label>
                            <Field
                                type="text"
                                name="coverTilesDetail"
                                id="coverTilesDetail"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setValues({
                                        ...values,
                                        coverTilesDetail: e.target.value,
                                    });

                                    storageService.setPrevisitValue('coverTilesDetail', e.target.value);
                                }}
                                placeholder="Préciser si 'Autre'"
                                className={getFieldClasses('coverTilesDetail')}
                            />
                        </div>
                    </div>
                )}
            </div>

            <div className="row">
                <div className="col-12 col-md-4 mb-3">
                    <div className="form-group">
                        <label htmlFor="coverConstructionYear">
                            Année des éléments de la couverture<sup>*</sup>
                        </label>
                        <Select
                            value={renderDefaultValueSelect(coverConstructionYearList, values.coverConstructionYear)}
                            name="coverConstructionYear"
                            options={coverConstructionYearList}
                            isMulti={false}
                            isSearchable={false}
                            isClearable={false}
                            onChange={(event: LabelledString | null) => {
                                if (event) {
                                    setValues({
                                        ...values,
                                        coverConstructionYear: event.value,
                                    });

                                    storageService.setPrevisitValue('coverConstructionYear', event.value, event.label);
                                }
                            }}
                            styles={panelStyles}
                            placeholder="Année des éléments de la couverture"
                            className={
                                'basic-single' +
                                (values.coverConstructionYear ? ' filled' : '') +
                                (touched.coverConstructionYear && errors.coverConstructionYear ? ' invalid' : '') +
                                (!values.coverConstructionYear ? ' required' : '')
                            }
                        />
                    </div>
                </div>
                <div className="col-12 col-md-4 mb-3 mb-md-0">
                    <div className="form-group">
                        <label htmlFor="tilesState">
                            Etat des éléments de couverture<sup>*</sup>
                        </label>
                        <Select
                            value={renderDefaultValueSelect(tilesStateList, values.tilesState)}
                            name="tilesState"
                            options={tilesStateList}
                            isSearchable={false}
                            isMulti={false}
                            isClearable={false}
                            onChange={(event: LabelledString | null) => {
                                if (event) {
                                    setValues({
                                        ...values,
                                        tilesState: event.value,
                                    });

                                    storageService.setPrevisitValue('tilesState', event.value, event.label);
                                }

                                if (event && event.value !== 'tres mauvais etat') {
                                    setValues({
                                        ...values,
                                        tilesState: event.value,
                                        fullRenovation: '',
                                    });

                                    storageService.setPrevisitValue('tilesState', event.value, event.label);
                                    storageService.setPrevisitValue('fullRenovation', '');
                                }
                            }}
                            styles={panelStyles}
                            placeholder="Etat des éléments de couverture"
                            className={
                                'basic-single' +
                                (values.tilesState ? ' filled' : '') +
                                (touched.tilesState && errors.tilesState ? ' invalid' : '') +
                                (!values.tilesState ? ' required' : '')
                            }
                        />
                    </div>
                </div>
                {values.tilesState === 'tres mauvais etat' && (
                    <div className="col-12 col-md-4">
                        <div className="form-group">
                            <CheckboxTriState
                                emptyLabel={true}
                                name="fullRenovation"
                                title="Rénovation du restant du pan de la toiture à prévoir"
                                style={{ height: '43px' }}
                                onCustomChange={(e: boolean) => {
                                    setValues({
                                        ...values,
                                        fullRenovation: e,
                                    });

                                    storageService.setPrevisitValue('fullRenovation', e);
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default Cover;
