import React, { useState } from 'react';

const CookieBanner: React.FC = () => {
  const [, setCookies] = useState(false);
  const domainName = window.location.hostname;

  const createCookie = (value: string) => {
    const now = new Date();
    const time = now.getTime();
    const expire = time + 1000 * 36000 * 365;

    now.setTime(expire);
    document.cookie = 'consent=' + value + ';expires='+ now.toUTCString() + 'Path=/; domain=' + domainName;
  };

  const getCookie = (name: string) => {
    return document.cookie.split(';').some(c => {
      return c.trim().startsWith(name + '=');
    });
  };

  const acceptCookie = () => {
    createCookie('yes');
    setCookies(true);
  };

  const refuseCookie = () => {
    createCookie('no');
    setCookies(true);
  };

  return (
    <>
    {
      getCookie('consent') === false &&
      <div className="cookie-banner">

        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-12 col-md-6 col-lg-8">
              <h5>Politique de cookie</h5>
              <p>Nous utilisons des cookies pour nous assurer de vous offrir la meilleure expérience sur notre site web.</p>
            </div>

            <div className="col-12 col-md-6 col-lg-4">
              <div className="btn-grp justify-content-between no-gap">
                <button className="btn btn-secondary min-width" onClick={acceptCookie}>
                  J'accepte
                </button>

                <button className="btn btn-secondary min-width" onClick={refuseCookie}>
                  Je refuse
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    }
    </>
  );
};

export default CookieBanner;
