import * as storageService from '../localStorageService';
import * as apiParticulierService from '../apiParticulierService';
import { Floor } from '../componentsHelpers/buildingService';
import { isValidUrl } from './TypeHelper';
import { PictureName, getStep8 } from './auditTools';
/**
 * Fonction pour convertir un dataUrl en fichier
 * @param base64 (base64 image)
 * @param fileName (nom image)
 * @param fileExtension (extension du fichier)
 */
export const dataURLtoFile = (base64: string, fileName: string, agentCode: string, fileExtension?: string): File | undefined => {
    let arr = base64.split(',');

    const matches = arr[0].match(/:(.*?);/);
    if (!matches || matches.length <= 1 || arr.length <= 1) return undefined;
    // @ts-ignore
    let mime = matches[1]; // this might crash if base64 is not a base 64 string.
    let bstr = atob(arr[1]); // same
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    const date = new Date().toISOString().split('.')[0].replace(/[:_]/g, '-');
    // retour du fichier converti avec le code agent, le nom et la date
    return new File([u8arr], `${agentCode}-${fileName}-${date}.${fileExtension ? fileExtension : 'jpeg'}`, { type: mime });
};

export const blobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            resolve(reader.result as string);
        };
        reader.onerror = (error) => {
            reject(error);
        };
    });
};

export const convertBase64ToFile = (fileName: string, base64String: string) => {
    if (!base64String) {
        console.error(`La chaîne base64 est undefined pour le fichier ${fileName}`);
    }

    const [mimeType, base64Full] = base64String.split(';');
    if (!base64Full) {
        console.error(`Format base64 invalide pour le fichier ${fileName}`);
        return null; // Ou gérer autrement
    }
    const extension = mimeType.split(':')[1];
    const base64 = base64Full.split(',')[1];
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: extension });
    const file = new File([blob], fileName, { type: extension });

    return file;
};

// Resize image before setting it into base64
export const resizeImage = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        const img = new Image();

        img.onload = () => {
            const canvas: HTMLCanvasElement = document.createElement('canvas');
            let width: number = img.width;
            let height: number = img.height;

            // If image is > 500px
            if (width > 500 || height > 500) {
                if (width > height) {
                    height *= 500 / width;
                    width = 500;
                } else {
                    width *= 500 / height;
                    height = 500;
                }
            }

            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext('2d');
            ctx?.drawImage(img, 0, 0, width, height);

            const base64 = canvas.toDataURL('image/jpeg');
            resolve(base64);
        };

        img.onerror = reject;
        img.src = URL.createObjectURL(file);
    });
};

export type LocalImage = {
    value: string;
    value_label: string;
};

export const createUploadFormDataForPreVisit = (): FormData => {
    const preVisiteFromLocalStorage = storageService.getPrevisit();
    const formData = new FormData();
    // Pictures from LocalStorage PreVisit
    const pvPictures: Record<string, LocalImage> = {
        circuitBreakerPicture: preVisiteFromLocalStorage.circuitBreakerPicture,
        doorwayPicture: preVisiteFromLocalStorage.doorwayPicture,
        roofShapePicture: preVisiteFromLocalStorage.roofShapePicture,
        edfMeterPicture: preVisiteFromLocalStorage.edfMeterPicture,
        electricMeterPicture: preVisiteFromLocalStorage.electricMeterPicture,
        singleStoreyHousePicture: preVisiteFromLocalStorage.singleStoreyHousePicture,
        inverterOrEnergyMeterRoomPicture: preVisiteFromLocalStorage.inverterOrEnergyMeterRoomPicture,
        outsideWallPicture: preVisiteFromLocalStorage.outsideWallPicture,
        panoramicBackPicture1: preVisiteFromLocalStorage.panoramicBackPicture1,
        panoramicBackPicture2: preVisiteFromLocalStorage.panoramicBackPicture2,
        panoramicBackPicture3: preVisiteFromLocalStorage.panoramicBackPicture3,
        panoramicFacePicture1: preVisiteFromLocalStorage.panoramicFacePicture1,
        panoramicFacePicture2: preVisiteFromLocalStorage.panoramicFacePicture2,
        panoramicFacePicture3: preVisiteFromLocalStorage.panoramicFacePicture3,
        roomTankInstalledPicture: preVisiteFromLocalStorage.roomTankInstalledPicture,
        houseRearFacePicture: preVisiteFromLocalStorage.houseRearFacePicture,
        dp5Picture: preVisiteFromLocalStorage.dp5Picture,
    };
    Object.entries(pvPictures).forEach(([key, value]) => {
        if (value.value.length === 0) {
            console.error(`invalid value for '${key}'`);
        } else {
            const file = convertBase64ToFile(value.value_label, value.value);
            if (file) {
                formData.append(key, file);
            } else {
                console.error(`Échec de la conversion du fichier pour '${key}'`);
            }
        }
    });
    return formData;
};

export const sendAllImages = async (): Promise<void> => {
    await sendPictures();
    await sendBuildingPlans();
};

/**
 * Fonction pour envoyer les images et réceptionner des URL après téléversement
 */
const sendPictures = async (): Promise<void> => {
    try {
        const audit = storageService.getAudit();

        const { photos, isValid } = getStep8(audit);

        // Création de l'objet à envoyer
        const uploadJson: Partial<Record<PictureName, File>> = {};
        Object.entries(photos).forEach(([key, photo]) => {
            if (photo?.value && photo.value !== '') {
                const file = dataURLtoFile(photo.value, key, storageService.getCurrentAgent().agentCode);
                if (file) {
                    uploadJson[key as PictureName] = file;
                }
            }
        });

        if (Object.entries(uploadJson).length === 0) return;

        // Envoi de la requête avec les photos
        const response = await apiParticulierService.postFile(uploadJson);
        if (!response || response.success === undefined) return;

        for (const entry of response.success) {
            // Mise à jour des photos avec les nouvelles URLs
            const key = entry.fileKey as PictureName;
            if (photos[key]) {
                photos[key].url = entry.url;
                photos[key].value = undefined; // écraser la valeur en base64
            }
        }

        // injection des nouvelles données dans l'étape 8
        audit.step8 = { ...photos, isValid };

        // Injection dans local storage
        storageService.setAudit(audit);
    } catch (error) {
        console.error(error);
    }
};

/**
 * Fonction pour envoyer les images des plans et réceptionner des URL après téléversement
 */
const sendBuildingPlans = async (): Promise<void> => {
    try {
        const audit = storageService.getAudit();
        // Récupération des plans du bâtiment
        // copie du tableau.
        // C'est un array de floor, mais en pratique il n'y a qu'un seul floor.
        // TODO : refacto transverse, pour que step2.buildingPlans.value soit un Floor et aps un array (voir le read me)
        const buildingPlans: Array<Floor> = [...audit.step2.buildingPlans.value]; // copie du tableau
        // Création de l'objet à envoyer
        const uploadJson: Record<string, File> = {};
        buildingPlans.forEach((buildingPlan: Floor, index: number) => {
            if (
                (!buildingPlan.img || !isValidUrl(buildingPlan.img)) &&
                (!buildingPlan.url || !isValidUrl(buildingPlan.url)) &&
                buildingPlan?.base64 &&
                buildingPlan.base64 !== ''
            ) {
                const file = dataURLtoFile(buildingPlan.base64, `buildingPlan-${buildingPlan.name}`, storageService.getCurrentAgent().agentCode);
                if (file) uploadJson[index] = file;
            }
        });
        // Sortir de la fonction si aucun fichier à envoyer
        if (Object.entries(uploadJson).length === 0) {
            console.error('Erreur lors de la création des fichiers pour les plans du bâtiment 1');
            return;
        }
        if (Object.entries(uploadJson).length !== buildingPlans.length) {
            console.error('Erreur lors de la création des fichiers pour les plans du bâtiment 2');
            return;
        }
        // Envoi de la requête avec les plans
        const response: apiParticulierService.UploadOutput = await apiParticulierService.postFile(uploadJson);
        // Si la réponse n'est pas valide, on ne fait rien (les données en base64 seront envoyées telles quelles)
        if (!response || response.success === undefined) return;

        // Traitement de la réponse réussie
        response.success.forEach((item: apiParticulierService.UploadedImage) => {
            // Recherche du buildingPlan correspondant à item.fileKey
            if (buildingPlans[Number(item.fileKey)]) {
                buildingPlans[Number(item.fileKey)].img = item.url;
                buildingPlans[Number(item.fileKey)].url = item.url;
                buildingPlans[Number(item.fileKey)].base64 = undefined;
            }
        });

        // Mise à jour de l'audit et du stockage local
        audit.step2.buildingPlans.value = [...buildingPlans];

        storageService.setAudit(audit);
    } catch (error) {
        console.error(error);
    }
};

// TODO: à partir de mars 2025, supprimer Floor.img et utiliser uniquement Floor.url
// en attendant, les deux doivent être présents, et img est utilisé, pour la retro-compatibilité
