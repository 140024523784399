import React, { useMemo, useState } from 'react';
import { Formik } from 'formik';
import { FormValues } from './PlanningEnums';
import { validationSchema } from './schema/schema';
import DatePicker from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import { format, isSameDay, getHours, getMinutes } from 'date-fns';
import * as api from '../../../services/apiParticulierService';
import { Link, useHistory } from 'react-router-dom';
import { ROUTE_PV_FINALISATION, ROUTE_PV_PREVISION } from '../../../routing/paths';
import Swal from 'sweetalert2';
import { PropertyType } from '../../../services/localStorageService';
import * as storageService from '../../../services/localStorageService';
import { ModalCounter } from '../../../components/ModalCounter/ModalCounter';
import withReactContent from 'sweetalert2-react-content';
// Icons
import { ReactComponent as IconCalendar } from '../../../assets/icons/pre-visite/icon-calendar.svg';
import { ReactComponent as Loader } from '../../../assets/icons/loader.svg';
// Style
import './custom-calendar.scss';

interface CustomHeaderProps {
    date: Date;
    decreaseMonth: () => void;
    increaseMonth: () => void;
}

const CustomDatePickerHeader = ({ date, decreaseMonth, increaseMonth }: CustomHeaderProps) => {
    return (
        <>
            <button
                type="button"
                className="react-datepicker__navigation react-datepicker__navigation--previous"
                aria-label="Previous Month"
                onClick={decreaseMonth}
            >
                Previous month
            </button>
            <button type="button" className="react-datepicker__navigation react-datepicker__navigation--next" aria-label="Next Month" onClick={increaseMonth}>
                Next Month
            </button>

            <div className="react-datepicker__header ">
                <div className="react-datepicker__current-month">{format(date, 'MMMM yyyy', { locale: fr })}</div>
                <div className="react-datepicker__header__dropdown react-datepicker__header__dropdown--scroll"></div>
            </div>
        </>
    );
};

const Planning: React.FC = () => {
    const { push } = useHistory();
    const AlertSwal = withReactContent(Swal);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [activeSlot, setActiveSlot] = useState<Date | null>(null);
    const [availableSlots, setAvailableSlots] = useState<Array<Date>>([]);
    const flowId = localStorage.getItem('flowId')!;

    // Datas from Audit
    const { step1 } = storageService.getAudit();
    const userAddress: string = step1.userAddress.value;
    const userAddressLatitude = step1.userAddressLatitude.value;
    const userAddressLongitude = step1.userAddressLongitude.value;

    const getAvailableSlots = async () => {
        const dates = await api.getAppointmentSlots();

        // Set dates from API
        setAvailableSlots(dates);
        setStartDate(dates[0]);
        setSelectedDate(dates[0]);
    };

    React.useEffect(() => {
        getAvailableSlots();
    }, []);

    // Formik initial values
    const initialValues: FormValues = useMemo(() => {
        return {
            date:
                storageService.checkPropertyExistThenCreateOrRenderPreVisite(
                    'date',
                    "Réservez votre date d'installation sur le planning",
                    PropertyType['basic']
                ) || availableSlots[0],
            time: false,
        };
    }, [availableSlots]);

    const cleanSlot = (time: number) => {
        return time < 10 ? '0' + time : time;
    };

    const hoursList = useMemo(() => {
        return selectedDate
            ? availableSlots
                  .filter((date) => isSameDay(date, selectedDate))
                  .map((hours) => ({
                      display: `${cleanSlot(getHours(hours))}h${cleanSlot(getMinutes(hours))} - ${cleanSlot(getHours(hours) + 4)}h${cleanSlot(
                          getMinutes(hours)
                      )}`,
                      slot: hours,
                  }))
            : null;
    }, [selectedDate, availableSlots]);

    const changeDate = (date: Date, setValues: (values: React.SetStateAction<FormValues>, shouldValidate?: boolean | undefined) => void) => {
        setSelectedDate(date);
        // Update date above hours
        setStartDate(date);
        setValues({
            date: null,
            time: false,
        });

        storageService.setPrevisitValue('date', null);
    };

    // Hours buttons selection
    const handleSelectedHours = async (
        slot: Date,
        setValues: (values: React.SetStateAction<FormValues>, shouldValidate?: boolean | undefined) => void,
        values: FormValues
    ) => {
        try {
            setActiveSlot(slot);

            setValues({
                ...values,
                date: slot,
                time: true,
            });

            storageService.setPrevisitValue('date', slot.toString());
        } catch (error) {
            console.error(error);
        }
    };

    // Change date format
    const formatDate = (date: Date) => {
        return format(date, 'EEEE d MMMM yyyy', { locale: fr });
    };

    const createInstallationAppointment = async () => {
        try {
            AlertSwal.fire({
                title: "Votre rendez-vous d'installation est en cours de création",
                html: <ModalCounter timer={30} />,
                showConfirmButton: false,
                showCancelButton: false,
                allowOutsideClick: false,
            });
            // Get date from previsit Local storage
            const preVisiteFromLocalStorage = storageService.getPrevisit();
            const appointmentDate = preVisiteFromLocalStorage.date.value;
            // Set values from Audit into Appointment
            const installationAppointment: api.AppointmentInput = {
                date: appointmentDate,
                latitude: Number(userAddressLatitude),
                longitude: Number(userAddressLongitude),
                address: userAddress,
            };
            // Create appointment
            await api.createInstallationAppointment(flowId, installationAppointment);
            console.log('Appointment created');

            AlertSwal.close();

            push(ROUTE_PV_FINALISATION);
        } catch (error) {
            console.error("Appointment can't be created: ", error);

            AlertSwal.close();

            AlertSwal.fire({
                title: 'Impossible de finaliser votre rendez-vous',
                text: `L'accès au service d'envoi est indisponible, veuillez réessayer en vous assurant de disposer d'une connexion internet.`,
                icon: 'error',
                width: 550,
                confirmButtonText: 'Fermer',
                focusConfirm: true,
                customClass: {
                    confirmButton: 'btn btn-primary min-width',
                },
            });
        }
    };

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} validateOnMount={true} enableReinitialize onSubmit={() => {}}>
            {({ values, errors, setValues }) => {
                return (
                    <div className="container">
                        <h1 className="main-title-mini">Planning</h1>
                        <div className="card card-audit-simulator planning mb-5">
                            <div className="card-header">
                                <IconCalendar width={60} height={60} fill="white" />
                                <h2>Planning</h2>
                            </div>
                            <div className="card-body">
                                <h4 className="card-body--sub-title text-center mb-5">Réservez votre date d'installation sur le planning</h4>
                                <div className="row justify-content-center mb-5">
                                    <div className="col-12 col-md-5 mb-5 mb-md-0 position-relative">
                                        {!selectedDate && (
                                            <div className="planning-loader" style={{ minHeight: '377px' }}>
                                                <Loader width={60} height={60} />
                                            </div>
                                        )}

                                        {selectedDate && (
                                            <DatePicker
                                                locale={fr}
                                                dateFormat={'dd/MM/yyyy'}
                                                includeDates={availableSlots}
                                                onChange={(date: Date) => changeDate(date, setValues)}
                                                selected={selectedDate}
                                                inline
                                                renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                                                    <CustomDatePickerHeader date={date} decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} />
                                                )}
                                            />
                                        )}
                                    </div>
                                    <div className="col-12 col-md-5" style={{ minHeight: '377px' }}>
                                        <div className="hours-select">
                                            <p>{startDate ? formatDate(startDate) : ''}</p>
                                            <ul className="hours-select--list">
                                                {hoursList &&
                                                    hoursList.map((hour, index) => (
                                                        <li key={index}>
                                                            <button
                                                                type="button"
                                                                data-selected={activeSlot === hour.slot}
                                                                className="btn btn-hours"
                                                                onClick={() => handleSelectedHours(hour.slot, setValues, values)}
                                                            >
                                                                {hour.display}
                                                            </button>
                                                        </li>
                                                    ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="btn-grp justify-content-center">
                                    <Link to={ROUTE_PV_PREVISION} className="btn btn-retour">
                                        Retour
                                    </Link>
                                    <div className="text-center">
                                        <button
                                            type="button"
                                            className={`btn btn-continue ${Object.entries(errors).length > 0 ? 'disabled' : ''}`}
                                            onClick={createInstallationAppointment}
                                        >
                                            Réserver le créneau
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }}
        </Formik>
    );
};

export default Planning;
