import React, { useEffect, useMemo, useState } from 'react';
import { Back } from '../../../components/Back/Back';
import { Link, useHistory } from 'react-router-dom';
import * as routes from '../../../routing/paths';
import { SortingInfo, TableDataProps, WaitinTableDataProps, convertToRow, filterStatus } from './MonReseauEnums';
import { format } from 'date-fns';
import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    flexRender,
    createColumnHelper,
    getSortedRowModel,
    PaginationState,
} from '@tanstack/react-table';
import * as api from '../../../services/apiParticulierService';
import { SubAgent } from '../../../services/apiParticulierService';
import { useRecoilState } from 'recoil';
import { sponsorState } from '../../../services/Recoil/Atom/SponsorMode.atom';

// Icons
import { ReactComponent as IconEye } from '../../../assets/icons/espace-agent/icon-eye.svg';
import { ReactComponent as IconArrow } from '../../../assets/icons/espace-agent/icon-arrow.svg';

type TabsProps = {
    index: number;
    onClick: React.Dispatch<React.SetStateAction<number>>;
    totalAgent: number;
    waitingTotalAgent: number;
    managerCode: string | null;
};

const Table: React.FC<TableDataProps> = (params: TableDataProps) => {
    const { push } = useHistory();
    const columnHelper = createColumnHelper<SubAgent>();

    const handlePaginationChange = (paginationState: PaginationState) => {
        params.setPagination(paginationState); // Mettre à jour l'état de la pagination
        params.setCurrentPageIndex(paginationState.pageIndex); // Mettre à jour l'index de la page courante

        // Update API
        params.setFetch(true);
    };

    // Define each table columns
    const columns = [
        columnHelper.accessor('createdAt', {
            header: () => 'Date de création',
            cell: (date) => date.getValue(),
        }),
        columnHelper.accessor('lastname', {
            header: () => 'Nom',
            cell: (name) => name.getValue(),
            enableSorting: false,
        }),
        columnHelper.accessor('firstname', {
            header: () => 'Prénom',
            cell: (firstname) => firstname.getValue(),
            enableSorting: false,
        }),
        columnHelper.accessor('agentCode', {
            header: () => 'Code agent',
            cell: (agent) => agent.getValue(),
            enableSorting: false,
        }),
        columnHelper.accessor('networkDeep', {
            header: () => 'Niveau de réseau',
            cell: (agent) => {
                return api.formatNetworkDeep(agent.getValue() ?? 0);
            },
            enableSorting: false,
        }),
        columnHelper.accessor('disabled', {
            header: () => 'Statut',
            cell: (status) => filterStatus(status.getValue()),
            enableSorting: false,
        }),
        columnHelper.accessor('id', {
            header: () => '',
            cell: () => <IconEye width={20} fill="#BDBDBD" />,
            enableSorting: false,
        }),
    ];

    const dataTable = useReactTable({
        data: params.data,
        columns,
        state: {
            sorting: params.sorting,
            pagination: params.pagination,
        },
        //getRowId,
        manualSorting: true,
        manualPagination: true,
        enableMultiSort: false,
        enableRowSelection: true,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: params.setPagination,
        onSortingChange: params.setSorting,
        getSortedRowModel: getSortedRowModel(),
        pageCount: params.totalPages,

        debugTable: false,
    });

    // Remove '_' from cell.id
    const idFilter = (id: string) => {
        return id.split('_')[1];
    };

    // Redirect to cell.url
    const trRedirect = (id: string) => {
        push(`mon-reseau/${id}`);
    };

    return (
        <>
            <table className="table table-list-audit">
                <thead>
                    {dataTable.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <th
                                    key={header.id}
                                    data-name={header.id}
                                    colSpan={header.colSpan}
                                    className={header.column.getCanSort() ? 'cursor-pointer' : 'pe-none'}
                                    style={header.id === 'id' ? { width: '5%' } : undefined}
                                >
                                    <div className="position-relative d-inline-block">
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                        {header.column.getCanSort() ? (
                                            header.column.getIsSorted() ? (
                                                {
                                                    asc: (
                                                        <span className="btn btn-table-sort" data-state={'asc'}>
                                                            <span className="tri"></span>
                                                        </span>
                                                    ),
                                                    desc: (
                                                        <span className="btn btn-table-sort" data-state={'desc'}>
                                                            <span className="tri"></span>
                                                        </span>
                                                    ),
                                                }[(header.column.getIsSorted() as string) ?? null]
                                            ) : (
                                                <span className="btn btn-table-sort" data-state={'default'}>
                                                    <span className="tri"></span>
                                                </span>
                                            )
                                        ) : null}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {dataTable.getRowModel().rows.map((row) => (
                        <tr key={row.id} onClick={() => trRedirect(row.getValue('id'))}>
                            {row.getVisibleCells().map((cell) => (
                                <td
                                    key={cell.id}
                                    align="center"
                                    data-name={idFilter(cell.id)}
                                    className={idFilter(cell.id) !== 'deactivate' ? 'cursor-pointer' : undefined}
                                >
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="table-pagination d-flex align-items-center justify-content-end">
                <div>Élements par page :</div>
                <div className="pagination-select">
                    <select
                        value={dataTable.getState().pagination.pageSize}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            dataTable.setPageSize(Number(e.target.value));
                            params.setFetch(true);
                        }}
                    >
                        {[10, 20, 40].map((pageSize: number) => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    {dataTable.getState().pagination.pageIndex + 1} - {dataTable.getPageCount()}
                </div>
                <button
                    type="button"
                    className="btn-previous"
                    onClick={() => handlePaginationChange({ ...params.pagination, pageIndex: params.pagination.pageIndex - 1 })}
                    disabled={!dataTable.getCanPreviousPage()}
                >
                    <IconArrow fill="grey" />
                </button>
                <button
                    type="button"
                    className="btn-next"
                    onClick={() => handlePaginationChange({ ...params.pagination, pageIndex: params.pagination.pageIndex + 1 })}
                    disabled={!dataTable.getCanNextPage()}
                >
                    <IconArrow fill="grey" />
                </button>
            </div>
        </>
    );
};

const WaitingTable: React.FC<WaitinTableDataProps> = (params: WaitinTableDataProps) => {
    const columnHelper = createColumnHelper<api.SponsorshipAgent>();

    const columns = [
        columnHelper.accessor('createdAt', {
            header: () => 'Date de création',
            cell: (date) => date.getValue(),
        }),
        columnHelper.accessor('lastname', {
            header: () => 'Nom',
            cell: (name) => name.getValue(),
            enableSorting: false,
        }),
        columnHelper.accessor('firstname', {
            header: () => 'Prénom',
            cell: (firstname) => firstname.getValue(),
            enableSorting: false,
        }),

        columnHelper.accessor('sponsorState', {
            header: () => 'Statut',
            cell: (status) => (status.getValue() === 'Created' ? 'En attente de validation' : 'En attente de création de compte'),
            enableSorting: false,
        }),
    ];

    const dataTable = useReactTable({
        data: params.data,
        columns,
        state: {
            sorting: params.sorting,
            pagination: params.pagination,
        },
        //getRowId,
        manualSorting: true,
        manualPagination: true,
        enableMultiSort: false,
        enableRowSelection: true,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: params.setPagination,
        onSortingChange: params.setSorting,
        getSortedRowModel: getSortedRowModel(),
        pageCount: params.totalPages,

        debugTable: false,
    });

    return (
        <table className="table table-list-audit">
            <thead>
                {dataTable.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                            <th
                                key={header.id}
                                data-name={header.id}
                                colSpan={header.colSpan}
                                className={header.column.getCanSort() ? 'cursor-pointer' : 'pe-none'}
                                style={header.id === 'id' ? { width: '5%' } : undefined}
                            >
                                <div className="position-relative d-inline-block">
                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                    {header.column.getCanSort() ? (
                                        header.column.getIsSorted() ? (
                                            {
                                                asc: (
                                                    <span className="btn btn-table-sort" data-state={'asc'}>
                                                        <span className="tri"></span>
                                                    </span>
                                                ),
                                                desc: (
                                                    <span className="btn btn-table-sort" data-state={'desc'}>
                                                        <span className="tri"></span>
                                                    </span>
                                                ),
                                            }[(header.column.getIsSorted() as string) ?? null]
                                        ) : (
                                            <span className="btn btn-table-sort" data-state={'default'}>
                                                <span className="tri"></span>
                                            </span>
                                        )
                                    ) : null}
                                </div>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody>
                {dataTable.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                            <td key={cell.id} align="center">
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

const Tabs: React.FC<TabsProps> = ({ index, onClick, totalAgent, waitingTotalAgent, managerCode }) => {
    const handleTabIndex = (index: number) => {
        onClick(index);
    };

    return (
        <div className="tabs-container">
            <button
                type="button"
                className={index === 0 ? 'btn btn-tab btn-tab--active' : 'btn btn-tab'}
                aria-selected={index === 0}
                aria-controls={`tabpanel-0`}
                onClick={() => handleTabIndex(0)}
                role="tab"
            >
                Agents <span>({totalAgent})</span>
            </button>
            {managerCode && (
                <button
                    type="button"
                    className={index === 1 ? 'btn btn-tab btn-tab--active' : 'btn btn-tab'}
                    aria-selected={index === 1}
                    aria-controls={`tabpanel-1`}
                    onClick={() => handleTabIndex(1)}
                    role="tab"
                    disabled={waitingTotalAgent === 0}
                >
                    Agents en attente ({waitingTotalAgent})
                </button>
            )}
        </div>
    );
};

const MonReseau: React.FC = () => {
    // Table SubAgents
    const [tableData, setTableData] = useState<SubAgent[]>(() => []);
    const [fetch, setFetch] = useState<boolean>(true);
    const [managerCode, setManagerCode] = useState<string | null>(null);
    const [sponsorMode, setSponsorMode] = useState<api.SponsorMode | null>(null);
    const [, setSponsorAtom] = useRecoilState(sponsorState);
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [sorting, setSorting] = useState<SortingInfo[]>([{ id: 'createdAt', desc: true }]);
    const pagination = useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]);
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [agentTotal, setAgentTotal] = useState<number>(0);

    // Table WaitingSubAgents
    const [waitingTableData, setWaitingTableData] = useState<api.SponsorshipAgent[]>(() => []);
    const [waitingFetch, setWaitingFetch] = useState<boolean>(true);
    const [waitingAgentTotal, setWaitingAgentTotal] = useState<number>(0);

    // Load agents
    useEffect(() => {
        const loadMyself = async () => {
            try {
                const subagents = await api.getNetwork();
                const managerCode = (await api.getMyself()).managerCode;
                const sponsorMode = (await api.getMyself()).sponsorMode;
                // Set managerCode
                if (managerCode) {
                    setManagerCode(managerCode);
                }
                // Set sponsorMode
                if (sponsorMode) {
                    setSponsorMode(sponsorMode);
                    setSponsorAtom(sponsorMode);
                }
                // console.log(waitingSubAgents);
                // console.log('subagents:', subagents);
                const formattedSubagents = subagents.elements.map((subagent) => ({
                    ...subagent,
                    createdAt: format(new Date(subagent.createdAt), 'dd/MM/yyyy - kk:mm'),
                }));

                setTableData(formattedSubagents);
                setAgentTotal(subagents.totalElements);
            } catch (error) {
                console.error(error);
            }
        };

        loadMyself();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetch, selectedTab]);

    // Load waiting agents
    useEffect(() => {
        const loadWaitingAgents = async () => {
            try {
                const waitingSubAgents = (await api.getMyself()).sponsorships;
                // console.log(waitingSubAgents);

                const formattedWaitingAgents = waitingSubAgents?.map((agent) => ({
                    ...agent,
                    createdAt: format(new Date(agent.createdAt), 'dd/MM/yyyy - kk:mm'),
                    status: 'En attente',
                }));

                if (formattedWaitingAgents && waitingSubAgents) {
                    setWaitingTableData(formattedWaitingAgents);
                    setWaitingAgentTotal(waitingSubAgents?.length);
                }
            } catch (error) {
                console.error(error);
            }
        };

        loadWaitingAgents();
    }, [waitingFetch, selectedTab]);

    const handleSort = (columnId: string, desc: boolean) => {
        const newSorting: SortingInfo[] = [{ id: columnId, desc }];

        setSorting(newSorting);
    };

    // Pagination refresh
    useEffect(() => {
        if (!fetch) return;

        api.getSubAgents()
            .then((result) => {
                if (result.totalElements === 0) {
                    setTableData([]);
                } else {
                    setTableData(convertToRow(result.elements));
                }
                setTotalPages(result.totalPage);
                setFetch(false);
            })
            .catch((e) => {
                console.error(e);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetch, selectedTab]);

    return (
        <div className="container list-audit">
            <div className="list-audit-header d-flex justify-content-between align-items-center w-100 py-4 mb-5">
                <Back title="Mon espace" url={routes.ROUTE_EA_INDEX} />
                {managerCode && (
                    <div className="btn-grp justify-content-end">
                        <Link to={routes.ROUTE_EA_CREATE} className="btn btn-list study">
                            {sponsorMode === 'Pyramidal' ? <>Demande d'affiliation</> : <>Création d'un sous-agent</>}
                        </Link>
                    </div>
                )}
            </div>

            <div className="container-creation p-4">
                <Tabs index={selectedTab} onClick={setSelectedTab} totalAgent={agentTotal} waitingTotalAgent={waitingAgentTotal} managerCode={managerCode} />
                {selectedTab === 0 && (
                    <Table
                        data={tableData}
                        setData={setTableData}
                        fetch={fetch}
                        setFetch={setFetch}
                        sorting={sorting}
                        setSorting={setSorting}
                        pagination={pagination}
                        setPagination={setPagination}
                        handleSort={handleSort}
                        totalPages={totalPages}
                        setCurrentPageIndex={setCurrentPageIndex}
                        pageIndex={currentPageIndex}
                    />
                )}

                {selectedTab === 1 && <WaitingTable data={waitingTableData} setData={setWaitingTableData} fetch={waitingFetch} setFetch={setWaitingFetch} />}
            </div>
        </div>
    );
};

export default MonReseau;
