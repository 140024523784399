import * as Yup from 'yup';
import { LabelledString } from '../../../../services/tools/TypeHelper';

const LabelledStringSchema = Yup.object().shape({
    label: Yup.string().required(),
    value: Yup.string().required(),
});

const ProductWithoutCustomizationSchema = Yup.object().shape({
    quantity: LabelledStringSchema.required('La quantité est requise'),
    price: Yup.number(),
});

const ProductWithCustomizationSchema = Yup.object().shape({
    quantity: LabelledStringSchema.required('La quantité est requise'),
    price: Yup.number(),
    customization: Yup.object().when('quantity', {
        is: (quantity: LabelledString) => parseInt(quantity?.value || '0', 10) > 0,
        then: Yup.object().shape({
            name: Yup.string().required('Le nom est requis'),
            firstname: Yup.string(), // non obligatoire
            phone: Yup.string().min(10, 'Le numéro de téléphone doit contenir au moins 10 chiffres').required('Le téléphone est requis'),
        }),
        otherwise: Yup.object().shape({}),
    }),
});

const CardVisitSchema = Yup.object().shape({
    quantity: LabelledStringSchema.required('La quantité est requise'),
    price: Yup.number(),
    customization: Yup.object().when('quantity', {
        is: (quantity: LabelledString) => parseInt(quantity?.value || '0', 10) > 0,
        then: Yup.object().shape({
            name: Yup.string().required('Le nom est requis'),
            firstname: Yup.string().required('Le prénom est requis'),
            phone: Yup.string().min(10, 'Le numéro de téléphone doit contenir au moins 10 chiffres'), // non obligatoire
            mobile: Yup.string()
                .matches(/^(06|07)\d{8}$/, 'Le numéro de téléphone doit commencer par 06 ou 07 et contenir 10 chiffres')
                .required('Le téléphone mobile est requis'),
            email: Yup.string().email('Email non valide').required("L'email est requis"),
            address: Yup.string().required("L'adresse est requise"),
            city: Yup.string().required('La ville est requise'),
            postalCode: Yup.string().required('Le code postal est requis'),
        }),
        otherwise: Yup.object().shape({}),
    }),
});

const MarketingSchema = Yup.object()
    .shape({
        "Kakemono - Rénovation d'ampleur": ProductWithoutCustomizationSchema,
        'Kakemono - Renovation énergétique': ProductWithoutCustomizationSchema,
        'Kakemono - Installation photovoltaïque': ProductWithoutCustomizationSchema,
        'Kakemono - Réseau national': ProductWithCustomizationSchema,
        'Kakemono - Améliorer votre habitat': ProductWithCustomizationSchema,
        'Cartes de visite': CardVisitSchema,
    })
    .test('validate-products', 'Validation des produits', function (values) {
        const errors: Record<string, any> = {};

        Object.entries(values).forEach(([productName, product]) => {
            const quantity = parseInt(product.quantity?.value || '0', 10);

            if (quantity > 0) {
                if (productName === 'Cartes de visite') {
                    if (!product.customization.name) errors[`${productName}.customization.name`] = 'Le nom est requis';
                    if (!product.customization.firstname) errors[`${productName}.customization.firstname`] = 'Le prénom est requis';
                    if (!product.customization.email) errors[`${productName}.customization.email`] = "L'email est requis";
                    if (!product.customization.address) errors[`${productName}.customization.address`] = "L'adresse est requise";
                    if (!product.customization.city) errors[`${productName}.customization.city`] = 'La ville est requise';
                    if (!product.customization.postalCode) errors[`${productName}.customization.postalCode`] = 'Le code postal est requis';
                    if (!product.customization.mobile) errors[`${productName}.customization.mobile`] = 'Le téléphone mobile est requis';
                } else if (['Kakemono - Réseau national', 'Kakemono - Améliorer votre habitat'].includes(productName)) {
                    if (!product.customization.name) errors[`${productName}.customization.name`] = 'Le nom est requis';
                    if (!product.customization.phone) errors[`${productName}.customization.phone`] = 'Le téléphone est requis';
                }
            }
        });

        return Object.keys(errors).length > 0 ? this.createError({ path: 'products', message: JSON.stringify(errors) }) : true;
    })
    .test('at-least-one-product', 'Au moins un produit doit être commandé', function (values) {
        const hasProduct = Object.values(values).some((product) => parseInt(product.quantity?.value || '0', 10) > 0);
        return hasProduct;
    });

export const marketingSchema = MarketingSchema;
