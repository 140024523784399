/* eslint-disable no-undef */
// https://codesandbox.io/s/reactgooglemapsapi-editing-a-polygon-popr2?file=/src/index.js:1577-1901
// https://developer.here.com/documentation/examples/maps-js/markers/finding-the-nearest-marker
// http://bl.ocks.org/knownasilya/89a32e572989f0aff1f8
// pre-loading-an-editable-drawingmanager-polygon-in-google-maps : https://stackoverflow.com/questions/15504751/pre-loading-an-editable-drawingmanager-polygon-in-google-maps
import React, { CSSProperties, useCallback, useEffect } from 'react';
import { DrawingManager, GoogleMap, GoogleMapProps, Polygon } from '@react-google-maps/api';
import { addressPolygonOptions, containerStyle, drawingOptions, mapOptions } from './helpers';
import { CustomPolygonNonSerialisable, GeoPosition, Path } from '../HomeInformationsEnums';

type CustomGoogleMapDrawProps = {
    center: GeoPosition;
    polygons: CustomPolygonNonSerialisable[];
    mapRef: React.MutableRefObject<google.maps.Map | null>;
    addressPolygon?: Path;
    googleMapProps?: GoogleMapProps;
    mapContainerStyle?: CSSProperties;
    withDrawing?: boolean;
    onPolygonEditCallback?: (id: string, path: google.maps.MVCArray<google.maps.LatLng>) => void;
    onPolygonCompleteCallback?: (polygon: google.maps.Polygon) => void;
};

export const CustomGoogleMapDraw: React.FC<CustomGoogleMapDrawProps> = ({
    center,
    polygons,
    addressPolygon,
    googleMapProps,
    mapContainerStyle,
    withDrawing,
    mapRef,
    onPolygonEditCallback,
    onPolygonCompleteCallback,
}) => {
    // Callback triggered when a polygon is clicked by user
    const onPolygonComplete = (polygon: google.maps.Polygon) => {
        if (!onPolygonCompleteCallback) return;

        onPolygonCompleteCallback(polygon);
    };

    // The idea is that we clear existing listeners when a handler passed as a prop has been updated then we reattach them.
    useEffect(() => {
        if (onPolygonEditCallback) {
            const listeners: Array<google.maps.MapsEventListener> = [];

            polygons.forEach((polygon) => {
                polygon.instance.getPaths().forEach((path) => {
                    const handleSetAt = () => onPolygonEditCallback(polygon.id, path);
                    const handleInsertAt = () => onPolygonEditCallback(polygon.id, path);
                    const handleRemoveAt = () => onPolygonEditCallback(polygon.id, path);
                    const handleDragEnd = () => onPolygonEditCallback(polygon.id, path);

                    listeners.push(
                        google.maps.event.addListener(path, 'set_at', handleSetAt),
                        google.maps.event.addListener(path, 'insert_at', handleInsertAt),
                        google.maps.event.addListener(path, 'remove_at', handleRemoveAt),
                        google.maps.event.addListener(path, 'dragend', handleDragEnd)
                    );
                });
            });
            // Cleanup event listeners
            return () => {
                listeners.forEach((listener) => google.maps.event.removeListener(listener));
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onPolygonEditCallback]);

    useEffect(() => {
        if (!mapRef.current) return;
        polygons.forEach((polygon) => {
            if (polygon.instance.getMap() !== mapRef.current) {
                polygon.instance.setMap(mapRef.current);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [polygons, mapRef.current]);

    // set ref to map and add custom layers
    const onMapLoad = useCallback(
        (map: google.maps.Map) => {
            mapRef.current = map;
            polygons?.forEach((polygon) => {
                // if the polygon existed before but had no map set
                if (!polygon.instance.getMap()) {
                    polygon.instance.setMap(mapRef.current);
                }
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [mapRef.current]
    );

    return (
        <GoogleMap
            {...googleMapProps}
            onLoad={onMapLoad}
            options={mapOptions}
            mapContainerStyle={{ ...containerStyle, ...mapContainerStyle }}
            center={center}
            zoom={18}
        >
            {withDrawing && <DrawingManager options={drawingOptions} onPolygonComplete={onPolygonComplete} />}
            {addressPolygon && <Polygon paths={addressPolygon} options={addressPolygonOptions} />}
        </GoogleMap>
    );
};
