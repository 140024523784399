import React from 'react';
import { EspaceFormationCards, EspaceFormationCardsFinanco, SogysAcademyCards } from './EspaceFormationEnums';
import withReactContent from 'sweetalert2-react-content';
import * as routes from '../../../routing/paths';
import Swal from 'sweetalert2';
import { Back } from '../../../components/Back/Back';

// Style
import '../EspaceAgent.scss';

// Icons
import { ReactComponent as IconClose } from '../../../assets/icons/icon-close.svg';
import { ReactComponent as IconBook } from '../../../assets/icons/icon-book.svg';

const EspaceFormation: React.FC = () => {
    const AlertSwal = withReactContent(Swal);

    React.useEffect(() => {
        document.title = 'SOGYS.APP - Espace formation';
    }, []);

    const modalPdf = (url: string, title: string) => {
        AlertSwal.fire({
            customClass: {
                htmlContainer: 'modal-pdf',
                closeButton: 'modal-close',
            },
            html: (
                <div className="document-container">
                    <iframe width="100%" height="100%" src={url} title={title} style={{ border: '0' }}></iframe>
                </div>
            ),
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: true,
            showCloseButton: true,
            closeButtonHtml: <IconClose width="34px" height="34px" />,
            padding: 0,
            width: '90vw',
        });
    };

    return (
        <>
            <div className="container mt-5">
                <Back title="Mon espace" url={routes.ROUTE_EA_INDEX} />
            </div>
            <div className="container py-5 px-4">
                <div className="container-creation py-4 row justify-content-center">
                    <div className="row">
                        <h2 className="d-flex align-items-center gap-2">
                            <IconBook fill="#5a5a5a" height={16} />
                            Book de formation
                        </h2>
                    </div>

                    <div className="row mb-4">
                        {EspaceFormationCards.map((card, index) => (
                            <div className={`col-6 col-md-3${EspaceFormationCards.length > 4 ? ' mb-4' : ''}`} key={index}>
                                <div className="card-formation" onClick={() => modalPdf(card.url, card.title)}>
                                    <div className="card-formation--image">
                                        <img src={card.image} alt={card.title} />
                                    </div>
                                    <h5 className="card-formation--title">{card.title}</h5>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="row mb-4">
                        {SogysAcademyCards.map((card, index) => (
                            <div className={`col-6 col-md-3${SogysAcademyCards.length > 4 ? ' mb-4' : ''}`} key={index}>
                                <div className="card-formation" onClick={() => modalPdf(card.url, card.title)}>
                                    <div className="card-formation--image">
                                        <img src={card.image} alt={card.title} />
                                    </div>
                                    <h5 className="card-formation--title">{card.title}</h5>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Financo */}
                    <div className="row">
                        {EspaceFormationCardsFinanco.map((card, index) => (
                            <div className={`col-6 col-md-3${EspaceFormationCardsFinanco.length > 4 ? ' mb-4' : ''}`} key={index}>
                                <div className="card-formation" onClick={() => modalPdf(card.url, card.title)}>
                                    <div className="card-formation--image">
                                        <img src={card.image} alt={card.title} />
                                    </div>
                                    <h5 className="card-formation--title">{card.title}</h5>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default EspaceFormation;
