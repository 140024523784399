import { PaginationState, SortingState } from '@tanstack/react-table';
import { SponsorshipAgent, SubAgent } from '../../../services/apiParticulierService';
import { format } from 'date-fns';

export type TableDataProps = {
    data: Array<SubAgent>;

    setData: React.Dispatch<React.SetStateAction<Array<SubAgent>>>;
    fetch: boolean;
    setFetch: React.Dispatch<React.SetStateAction<boolean>>;

    sorting?: SortingState;
    setSorting?: React.Dispatch<React.SetStateAction<SortingState>>;
    handleSort?: (columnId: string, desc: boolean) => void;

    pagination: PaginationState;
    setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
    totalPages: number;
    pageIndex: number;
    setCurrentPageIndex: React.Dispatch<React.SetStateAction<number>>;
};

export type WaitinTableDataProps = {
    data: Array<SponsorshipAgent>;

    setData: React.Dispatch<React.SetStateAction<Array<SponsorshipAgent>>>;
    fetch: boolean;
    setFetch: React.Dispatch<React.SetStateAction<boolean>>;

    sorting?: SortingState;
    setSorting?: React.Dispatch<React.SetStateAction<SortingState>>;
    handleSort?: (columnId: string, desc: boolean) => void;

    pagination?: PaginationState;
    setPagination?: React.Dispatch<React.SetStateAction<PaginationState>>;
    totalPages?: number;
    pageIndex?: number;
    setCurrentPageIndex?: React.Dispatch<React.SetStateAction<number>>;
};

export interface SortingInfo {
    id: string;
    desc: boolean;
}

export const convertToRow = (flows: Array<SubAgent>): Array<SubAgent> => {
    const a: SubAgent[] = flows.map((f) => {
        return {
            createdAt: format(new Date(f.createdAt), 'dd/MM/yyyy - kk:mm'),
            lastname: f.lastname,
            firstname: f.firstname,
            agentCode: f.agentCode,
            disabled: f.disabled,
            id: '/espace-agent/mon-reseau/' + f.id,
            company: {
                address: '',
                city: '',
                email: '',
                id: '',
                legalType: '',
                name: '',
                siret: '',
                zipCode: '',
            },
            email: f.email,
        };
    });
    return a;
};

// Filter agent status according to 'disabled' state
export const filterStatus = (status: boolean) => {
    switch (status) {
        case true:
            return 'Désactivé';

        case false:
            return 'Activé';

        default:
            return 'En attente';
    }
};
