import React from 'react';
import { useField } from 'formik';
import ReactTooltip from 'react-tooltip';

// Icons
import { ReactComponent as IconHelp } from '../../assets/icons/icon-help.svg';

type CheckboxTriStateProps = {
    name: string;
    emptyLabel?: boolean;
    title: string;
    titleAlt?: string;
    disabled?: boolean;
    style?: React.CSSProperties;
    onCustomChange?: Function;
    helpTitle?: string;
    helpText?: string;
};

const CheckboxTriState: React.FC<CheckboxTriStateProps> = ({ name, emptyLabel, title, titleAlt, disabled, style, onCustomChange, helpTitle, helpText }) => {
    // need "meta" to use "setValue"
    const [field, , helpers] = useField({ name });
    const isChecked = field.value === true;
    const isIndeterminate = field.value === undefined;

    const handleSetFalse = () => {
        helpers.setValue(false);

        if (onCustomChange) {
            onCustomChange(false);
        }
    };

    const handleSetTrue = () => {
        helpers.setValue(true);

        if (onCustomChange) {
            onCustomChange(true);
        }
    };

    return (
        <>
            {emptyLabel && <label>&nbsp;</label>}
            <div
                className={'form-check form-switch custom-switch switch-intermediate' + (field.value === undefined || field.value === '' ? ' undefined' : '')}
                style={style}
            >
                <label className="form-check-label" htmlFor={name}>
                    <span>
                        {title}
                        <sup>*</sup>
                    </span>
                    {helpText && (
                        <div className="help-text" data-tip data-for={`help-${name}`}>
                            <IconHelp fill="#E69736" width={14} height={14} className="icon" />
                        </div>
                    )}
                    {titleAlt && <small>{titleAlt}</small>}
                </label>

                <ReactTooltip id={`help-${name}`} place="bottom" type={'light'} effect={'solid'}>
                    {helpTitle && <h5>{helpTitle}</h5>}
                    <p>{helpText}</p>
                </ReactTooltip>

                <div className="input-values">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        checked={isChecked}
                        ref={(ref: HTMLInputElement | null) => {
                            if (ref) {
                                ref.indeterminate = isIndeterminate;
                            }
                        }}
                        id={name}
                        onChange={() => null}
                        disabled={disabled}
                    />
                    <button type="button" className="btn-true" disabled={disabled} onClick={handleSetTrue}></button>
                    <button type="button" className="btn-false" disabled={disabled} onClick={handleSetFalse}></button>
                </div>
            </div>
        </>
    );
};

export default CheckboxTriState;
