import React, { useEffect, useState } from 'react';
import * as storageService from '../../services/localStorageService';

import PhotoComponent from './photoComponent';

import * as Yup from 'yup';
import { Formik } from 'formik';

import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { postJsonData } from '../../services/apiParticulierService';
import { ROUTE_AUDIT_AL, ROUTE_AUDIT_RAC, ROUTE_SIM_SYNTHESE } from '../../routing/paths';
import { NoticeProps } from '../../services/tools/TypeHelper';

import './Photos.scss';

// Images
import { ReactComponent as IconStep8 } from '../../assets/icons/icon-step8.svg';
import { useStepNavigation } from '../../routing/StepNavigationContext';
import { getStep8, Picture, PictureName } from '../../services/tools/auditTools';

export type PhotosProps = { [key in PictureName]?: Picture };

const Photos: React.FC = () => {
    const { validateThenRoute, returnToHome } = useStepNavigation();
    /**
     * Gestion désactivation formulaire
     */
    const [auditSent, setAuditSent] = useState<boolean>(false);
    useEffect(() => {
        /**
         * Scroll de la page en haut
         */
        window.scrollTo(0, 0);

        /**
         * Vérification si Audit déjà envoyé
         */
        const audit = storageService.getAudit();
        if (audit?.auditSent) {
            setAuditSent(true);
        }
    }, []);

    const heater: string = storageService.getAudit().step3.heaterType.value;
    const heater2: string = storageService.getAudit().step3.heaterType2.value;
    const hasGaz: boolean = heater === '4' || heater2 === '4';
    const clientMail: string = storageService.getAudit().step1.userEmail.value;
    /**
     * 1 - Maison Individuelle
     * 2 - Appartemment
     * 3 - Immeuble Collectif
     * 4 - Bâtiment
     */
    const buildingType = storageService.getAudit().step2.projectType.value;

    const flowId = localStorage.getItem('flowId');
    const AlertSwal = withReactContent(Swal);

    /**
     * Fonction pour déclencher l'ouverture de la modal avec l'image courante
     * @param image (image en base64)
     */
    const openImageModal = (image: string) => {
        AlertSwal.fire({
            imageUrl: image,
            imageWidth: 800,
            showClass: {
                popup: 'photo',
                backdrop: 'swal2-backdrop-show swal2-backdrop-black',
            },
            showCloseButton: true,
            showConfirmButton: false,
        });
    };

    const initialValues: PhotosProps = getStep8().photos;

    const createPictureSchema = (required: string | undefined = undefined) => {
        const baseSchema = Yup.object().shape({
            value: Yup.string().nullable(),
            url: Yup.string().nullable(),
            desc: Yup.string().required('La description est obligatoire'),
        });

        if (!required) {
            return baseSchema.notRequired().nullable();
        }

        return baseSchema
            .test('au-moins-un-remplit', 'Au moins url ou value doit être rempli', function (picture) {
                return picture ? !!(picture.value || picture.url) : false;
            })
            .required(required);
    };
    const validationSchema = Yup.object().shape({
        buildingPictureGeneral: createPictureSchema('La photo de présentation générale est obligatoire'),
        buildingPictureNorth: createPictureSchema('La photo de la face Nord est obligatoire'),
        buildingPictureEast: createPictureSchema('La photo de la face Est est obligatoire'),
        buildingPictureSouth: createPictureSchema('La photo de la face sud est obligatoire'),
        buildingPictureWest: createPictureSchema('La photo de la face ouest est obligatoire'),
        buildingPictureRoofing: createPictureSchema(),
        buildingPictureOther: createPictureSchema(),
        buildingPictureBalcony: createPictureSchema(),
        buildingPicturesSolarMask: createPictureSchema(),
        buildingPicturesheaterGeneratorPower: createPictureSchema('La photo du type de générateur est obligatoire'),
        buildingPicturesheaterFeature: createPictureSchema('La photo du type de générateur (puissance) est obligatoire'),
        buildingPicturesenergyWater: createPictureSchema('La photo eau chaude sanitaire est obligatoire'),
        buildingPicturesPathology1: createPictureSchema(),
        buildingPicturesPathology2: createPictureSchema(),
        buildingPicturesPathology3: createPictureSchema(),
        buildingPicturesPathology4: createPictureSchema(),
        buildingPicturesPathology5: createPictureSchema(),
        buildingPicturesPathology6: createPictureSchema(),
        buildingPicturesPathology7: createPictureSchema(),
        buildingPictureCeilingInsulationPresence: createPictureSchema(),
        buildingPictureFloorInsulationPresence: createPictureSchema(),
        buildingPictureExteriorWall: createPictureSchema(),
        buildingPictureUnheatedRoom: createPictureSchema(),
        buildingPictureDoor1: createPictureSchema('La photo de la porte principale est obligatoire'),
        buildingPictureDoor2: createPictureSchema(),
        buildingPictureDoorUnheatedRoom1Presence: createPictureSchema(),
        buildingPictureBuildingWindows1: createPictureSchema('La photo typologique de la fenêtre 1 est obligatoire'),
        buildingPictureBuildingWindows2: createPictureSchema(),
        buildingPictureDoorWindows1: createPictureSchema(),
        energyCostsElectricInvoice1: createPictureSchema("La photo d'une facture d'électricité est obligatoire"),
        energyCostsElectricInvoice2: createPictureSchema(),
        energyCostsElectricInvoice3: createPictureSchema(),
        energyCostsGasInvoice1: createPictureSchema(hasGaz ? "La photo d'une facture de gaz est obligatoire" : undefined),
        energyCostsGasInvoice2: createPictureSchema(),
        energyCostsGasInvoice3: createPictureSchema(),
    });

    /**
     * Fonction pour confirmer la suppression de l'image (Autre forme)
     */
    const confirmDeleteImage = async () => {
        return await AlertSwal.fire({
            html: `Confirmez-vous la suppression de cette image ?`,
            width: 550,
            confirmButtonText: 'Supprimer',
            cancelButtonText: 'Annuler',
            showCancelButton: true,
            focusCancel: true,
            customClass: {
                confirmButton: 'btn btn-red-border min-width',
                cancelButton: 'btn btn-primary min-width',
            },
        }).then((result) => {
            return result.isConfirmed;
        });
    };

    /**
     * Disable push button
     */
    const checkStepsValidity = () => {
        const audit = storageService.getAudit();
        // Logique inversée :
        if (
            audit.step1.isValid &&
            audit.step2.isValid &&
            audit.step3.isValid &&
            audit.step4.isValid &&
            audit.step5.isValid &&
            audit.step6.isValid &&
            audit.step7.isValid &&
            audit.step8.isValid
        ) {
            return false;
        }

        return true;
    };

    const nocticeType: NoticeProps = 'audit';
    const sendAwaitedPhotos = async (email: string) => {
        if (email) {
            try {
                await postJsonData(`flow/${flowId}/send-notice/${nocticeType}`);
                AlertSwal.fire({
                    title: '',
                    html: <p>L'email Notice de prise de photos sur l'audit a bien été envoyé.</p>,
                    confirmButtonText: 'Fermer',
                    customClass: {
                        confirmButton: 'btn btn-primary min-width',
                    },
                });
            } catch (error) {
                console.error(error);
                AlertSwal.fire({
                    title: 'Erreur',
                    html: <p>Une erreur est survenue lors de l'envoi de votre mail.</p>,
                    confirmButtonText: 'Fermer',
                    customClass: {
                        confirmButton: 'btn btn-primary min-width',
                    },
                });
            }
        } else {
            AlertSwal.fire({
                title: 'Erreur',
                html: <p>Aucun email n'a été renseigné dans l'étape 1.</p>,
                confirmButtonText: 'Fermer',
                customClass: {
                    confirmButton: 'btn btn-primary min-width',
                },
            });
        }
    };

    return (
        <Formik onSubmit={() => {}} initialValues={initialValues} validationSchema={validationSchema} validateOnMount={true}>
            {({ values, isValid, setFieldValue, errors }) => {
                // Mise à jour de l'étape globale
                if (isValid) {
                    let globalCurrentStep = storageService.getAuditCurrentStep();
                    if (8 === globalCurrentStep) {
                        storageService.setAuditCurrentStep(globalCurrentStep + 1);
                    }
                }
                storageService.setAuditStepValidity(8, isValid);
                return (
                    <div className="container">
                        <div className="d-flex align-items-center justify-content-between">
                            <h1 className="main-title-mini">8. Ajouter des photos de l’habitation</h1>
                            <button type="button" className="btn btn-secondary" onClick={() => sendAwaitedPhotos(clientMail)} disabled={auditSent}>
                                Envoyer la liste des photos attendues
                            </button>
                        </div>
                        <h2 className="main-subtitle">Ajouter ou prenez des photos de l’habitation</h2>

                        <div className="card mb-5">
                            <div className="card-title">
                                <IconStep8 width={40} height={40} fill="#76ca4f" />
                                <h2>Photos obligatoires</h2>
                            </div>

                            <div className="card-body">
                                <fieldset className="w-100" disabled={auditSent}>
                                    <div className="row">
                                        <PhotoComponent
                                            title={'Présentation générale'}
                                            text={'Photo de la maison ou du bâtiment en pied'}
                                            fieldValue={'buildingPictureGeneral'}
                                            url={values['buildingPictureGeneral']?.url ?? ''}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            isValid={isValid}
                                            confirmDeleteImage={() => confirmDeleteImage()}
                                            base64Image={values['buildingPictureGeneral']?.value ?? undefined}
                                            openImageModal={openImageModal}
                                            auditSent={auditSent}
                                            required={true}
                                        />

                                        <PhotoComponent
                                            title={'Face Nord'}
                                            text={'Photo de la façade Nord de la maison'}
                                            fieldValue={'buildingPictureNorth'}
                                            url={values['buildingPictureNorth']?.url ?? ''}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            isValid={isValid}
                                            confirmDeleteImage={() => confirmDeleteImage()}
                                            base64Image={values['buildingPictureNorth']?.value ?? undefined}
                                            openImageModal={openImageModal}
                                            auditSent={auditSent}
                                            required={true}
                                        />

                                        <PhotoComponent
                                            title={'Face Est'}
                                            text={'Photo de la façade Est de la maison'}
                                            fieldValue={'buildingPictureEast'}
                                            url={values['buildingPictureEast']?.url ?? ''}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            isValid={isValid}
                                            confirmDeleteImage={() => confirmDeleteImage()}
                                            base64Image={values['buildingPictureEast']?.value ?? undefined}
                                            openImageModal={openImageModal}
                                            auditSent={auditSent}
                                            required={true}
                                        />

                                        <PhotoComponent
                                            title={'Face Sud'}
                                            text={'Photo de la façade Sud de la maison'}
                                            fieldValue={'buildingPictureSouth'}
                                            url={values['buildingPictureSouth']?.url ?? ''}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            isValid={isValid}
                                            confirmDeleteImage={() => confirmDeleteImage()}
                                            base64Image={values['buildingPictureSouth']?.value ?? undefined}
                                            openImageModal={openImageModal}
                                            auditSent={auditSent}
                                            required={true}
                                        />

                                        <PhotoComponent
                                            title={'Face Ouest'}
                                            text={'Photo de la façade Ouest de la maison'}
                                            fieldValue={'buildingPictureWest'}
                                            url={values['buildingPictureWest']?.url ?? ''}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            isValid={isValid}
                                            confirmDeleteImage={() => confirmDeleteImage()}
                                            base64Image={values['buildingPictureWest']?.value ?? undefined}
                                            openImageModal={openImageModal}
                                            auditSent={auditSent}
                                            required={true}
                                        />

                                        <PhotoComponent
                                            title={'Porte principale'}
                                            text={'Photo de la porte principale de la maison'}
                                            fieldValue={'buildingPictureDoor1'}
                                            url={values['buildingPictureDoor1']?.url ?? ''}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            isValid={isValid}
                                            confirmDeleteImage={() => confirmDeleteImage()}
                                            base64Image={values['buildingPictureDoor1']?.value ?? undefined}
                                            openImageModal={openImageModal}
                                            auditSent={auditSent}
                                            required={true}
                                        />

                                        <PhotoComponent
                                            title={'Typologie de fenêtre 1'}
                                            text={'Photo du premier type de fenêtres'}
                                            fieldValue={'buildingPictureBuildingWindows1'}
                                            url={values['buildingPictureBuildingWindows1']?.url ?? ''}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            isValid={isValid}
                                            confirmDeleteImage={() => confirmDeleteImage()}
                                            base64Image={values['buildingPictureBuildingWindows1']?.value ?? undefined}
                                            openImageModal={openImageModal}
                                            auditSent={auditSent}
                                            required={true}
                                        />

                                        {heater !== '999' && (
                                            <>
                                                <PhotoComponent
                                                    title={'Générateurs'}
                                                    text={'Photo du générateur (chaudière, convecteur, pompe à chaleur, etc..)'}
                                                    fieldValue={'buildingPicturesheaterGeneratorPower'}
                                                    url={values['buildingPicturesheaterGeneratorPower']?.url ?? ''}
                                                    values={values}
                                                    setFieldValue={setFieldValue}
                                                    isValid={isValid}
                                                    confirmDeleteImage={() => confirmDeleteImage()}
                                                    base64Image={values['buildingPicturesheaterGeneratorPower']?.value ?? undefined}
                                                    openImageModal={openImageModal}
                                                    auditSent={auditSent}
                                                    required={true}
                                                />

                                                <PhotoComponent
                                                    title={'Emetteurs'}
                                                    text={'Photo des émetteurs de chaleur (radiateurs, convecteurs, splits, etc…)'}
                                                    fieldValue={'buildingPicturesheaterFeature'}
                                                    url={values['buildingPicturesheaterFeature']?.url ?? ''}
                                                    values={values}
                                                    setFieldValue={setFieldValue}
                                                    isValid={isValid}
                                                    confirmDeleteImage={() => confirmDeleteImage()}
                                                    base64Image={values['buildingPicturesheaterFeature']?.value ?? undefined}
                                                    openImageModal={openImageModal}
                                                    auditSent={auditSent}
                                                    required={true}
                                                />
                                            </>
                                        )}

                                        <PhotoComponent
                                            title={'Eau chaude sanitaire'}
                                            text={"Photo du ballon d'eau chaude sanitaire"}
                                            fieldValue={'buildingPicturesenergyWater'}
                                            url={values['buildingPicturesenergyWater']?.url ?? ''}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            isValid={isValid}
                                            confirmDeleteImage={() => confirmDeleteImage()}
                                            base64Image={values['buildingPicturesenergyWater']?.value ?? undefined}
                                            openImageModal={openImageModal}
                                            auditSent={auditSent}
                                            required={true}
                                        />

                                        <PhotoComponent
                                            title={`Facture d'électricité 1`}
                                            text={"Photo de votre facture d'électricité de l'année dernière "}
                                            fieldValue={'energyCostsElectricInvoice1'}
                                            url={values['energyCostsElectricInvoice1']?.url ?? ''}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            isValid={isValid}
                                            confirmDeleteImage={() => confirmDeleteImage()}
                                            base64Image={values['energyCostsElectricInvoice1']?.value ?? undefined}
                                            openImageModal={openImageModal}
                                            auditSent={auditSent}
                                            required={true}
                                        />

                                        {hasGaz && (
                                            <PhotoComponent
                                                title={'Facture de gaz 1'}
                                                text={"Photo de votre facture de gaz de l'année dernière "}
                                                fieldValue={'energyCostsGasInvoice1'}
                                                url={values['energyCostsGasInvoice1']?.url ?? ''}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                isValid={isValid}
                                                confirmDeleteImage={() => confirmDeleteImage()}
                                                base64Image={values['energyCostsGasInvoice1']?.value ?? undefined}
                                                openImageModal={openImageModal}
                                                auditSent={auditSent}
                                                required={true}
                                            />
                                        )}
                                    </div>
                                </fieldset>
                            </div>
                        </div>

                        <div className="card mb-5">
                            <div className="card-title">
                                <IconStep8 width={40} height={40} fill="#76ca4f" />
                                <h2>Photos non obligatoires (recommandées)</h2>
                            </div>

                            <div className="card-body">
                                <fieldset className="w-100" disabled={auditSent}>
                                    <div className="row">
                                        {storageService.getAudit().step2.balcony.value && (
                                            <PhotoComponent
                                                title={'Présence d’un balcon'}
                                                text={'Photo des balcons'}
                                                fieldValue={'buildingPictureBalcony'}
                                                url={values['buildingPictureBalcony']?.url ?? ''}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                isValid={isValid}
                                                confirmDeleteImage={() => confirmDeleteImage()}
                                                base64Image={values['buildingPictureBalcony']?.value ?? undefined}
                                                openImageModal={openImageModal}
                                                auditSent={auditSent}
                                            />
                                        )}

                                        {storageService.getAudit().step7.buildingDoor2Presence.value && (
                                            <PhotoComponent
                                                title={'Porte secondaire'}
                                                text={'Photo de la porte secondaire de la maison'}
                                                fieldValue={'buildingPictureDoor2'}
                                                url={values['buildingPictureDoor2']?.url ?? ''}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                isValid={isValid}
                                                confirmDeleteImage={() => confirmDeleteImage()}
                                                base64Image={values['buildingPictureDoor2']?.value ?? undefined}
                                                openImageModal={openImageModal}
                                                auditSent={auditSent}
                                            />
                                        )}

                                        {storageService.getAudit().step7.buildingDoorUnheatedRoom1Presence.value && (
                                            <PhotoComponent
                                                title={'Porte sur local non-chauffé'}
                                                text={'Photo de la porte sur local non chauffé de la maison'}
                                                fieldValue={'buildingPictureDoorUnheatedRoom1Presence'}
                                                url={values['buildingPictureDoorUnheatedRoom1Presence']?.url ?? ''}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                isValid={isValid}
                                                confirmDeleteImage={() => confirmDeleteImage()}
                                                base64Image={values['buildingPictureDoorUnheatedRoom1Presence']?.value ?? undefined}
                                                openImageModal={openImageModal}
                                                auditSent={auditSent}
                                            />
                                        )}

                                        {storageService.getAudit().step7.buildingWindows2Presence.value && (
                                            <PhotoComponent
                                                title={'Typologie de fenêtre 2'}
                                                text={'Photo du deuxième type de fenêtres'}
                                                fieldValue={'buildingPictureBuildingWindows2'}
                                                url={values['buildingPictureBuildingWindows2']?.url ?? ''}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                isValid={isValid}
                                                confirmDeleteImage={() => confirmDeleteImage()}
                                                base64Image={values['buildingPictureBuildingWindows2']?.value ?? undefined}
                                                openImageModal={openImageModal}
                                                auditSent={auditSent}
                                            />
                                        )}

                                        {storageService.getAudit().step7.buildingDoorWindows1Presence.value && (
                                            <PhotoComponent
                                                title={'Caractéristiques des portes-fenêtres'}
                                                text={"Photo d'une des portes-fenêtres de la maison"}
                                                fieldValue={'buildingPictureDoorWindows1'}
                                                url={values['buildingPictureDoorWindows1']?.url ?? ''}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                isValid={isValid}
                                                confirmDeleteImage={() => confirmDeleteImage()}
                                                base64Image={values['buildingPictureDoorWindows1']?.value ?? undefined}
                                                openImageModal={openImageModal}
                                                auditSent={auditSent}
                                            />
                                        )}

                                        {storageService.getAudit().step2.solarMask.value && (
                                            <PhotoComponent
                                                title={'Masque solaire'}
                                                text={"Photo d'horizon depuis la meilleure exposition de la maison"}
                                                fieldValue={'buildingPicturesSolarMask'}
                                                url={values['buildingPicturesSolarMask']?.url ?? ''}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                isValid={isValid}
                                                confirmDeleteImage={() => confirmDeleteImage()}
                                                base64Image={values['buildingPicturesSolarMask']?.value ?? undefined}
                                                openImageModal={openImageModal}
                                                auditSent={auditSent}
                                            />
                                        )}

                                        {['1', '4'].includes(buildingType) && (
                                            <PhotoComponent
                                                title={'Exposition de la toiture'}
                                                text={'Photo de la toiture de votre maison (la meilleure exposition)'}
                                                fieldValue={'buildingPictureRoofing'}
                                                url={values['buildingPictureRoofing']?.url ?? ''}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                isValid={isValid}
                                                confirmDeleteImage={() => confirmDeleteImage()}
                                                base64Image={values['buildingPictureRoofing']?.value ?? undefined}
                                                openImageModal={openImageModal}
                                                auditSent={auditSent}
                                            />
                                        )}

                                        <PhotoComponent
                                            title={'Combles'}
                                            text={'Photo des combles'}
                                            fieldValue={'buildingPictureCeilingInsulationPresence'}
                                            url={values['buildingPictureCeilingInsulationPresence']?.url ?? ''}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            isValid={isValid}
                                            confirmDeleteImage={() => confirmDeleteImage()}
                                            base64Image={values['buildingPictureCeilingInsulationPresence']?.value ?? undefined}
                                            openImageModal={openImageModal}
                                            auditSent={auditSent}
                                        />

                                        <PhotoComponent
                                            title={'Caractéristiques des planchers'}
                                            text={'Photo du plafond du sous-sol ou du vide sanitaire'}
                                            fieldValue={'buildingPictureFloorInsulationPresence'}
                                            url={values['buildingPictureFloorInsulationPresence']?.url ?? ''}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            isValid={isValid}
                                            confirmDeleteImage={() => confirmDeleteImage()}
                                            base64Image={values['buildingPictureFloorInsulationPresence']?.value ?? undefined}
                                            openImageModal={openImageModal}
                                            auditSent={auditSent}
                                        />

                                        <PhotoComponent
                                            title={'Caractéristiques des murs extérieurs'}
                                            text={"Photo des murs extérieurs en prenant si possible la tranche du mur de la porte d'entrée"}
                                            smallText={true}
                                            fieldValue={'buildingPictureExteriorWall'}
                                            url={values['buildingPictureExteriorWall']?.url ?? ''}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            isValid={isValid}
                                            confirmDeleteImage={() => confirmDeleteImage()}
                                            base64Image={values['buildingPictureExteriorWall']?.value ?? undefined}
                                            openImageModal={openImageModal}
                                            auditSent={auditSent}
                                        />

                                        {['1', '4'].includes(buildingType) && (
                                            <PhotoComponent
                                                title={'Murs sur local non chauffée'}
                                                text={"Photo des murs sur local non chauffé en prenant si possible la tranche du mur de la porte d'entrée"}
                                                smallText={true}
                                                fieldValue={'buildingPictureUnheatedRoom'}
                                                url={values['buildingPictureUnheatedRoom']?.url ?? ''}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                isValid={isValid}
                                                confirmDeleteImage={() => confirmDeleteImage()}
                                                base64Image={values['buildingPictureUnheatedRoom']?.value ?? undefined}
                                                openImageModal={openImageModal}
                                                auditSent={auditSent}
                                            />
                                        )}

                                        {storageService.getAudit().step5.pathology1.value && (
                                            <PhotoComponent
                                                title={'Problème de calfeutrage sur menuiserie'}
                                                text={"Photo de l'espace non calfeutré entre la maçonnerie et le châssis de la fenêtre"}
                                                fieldValue={'buildingPicturesPathology1'}
                                                url={values['buildingPicturesPathology1']?.url ?? ''}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                isValid={isValid}
                                                confirmDeleteImage={() => confirmDeleteImage()}
                                                base64Image={values['buildingPicturesPathology1']?.value ?? undefined}
                                                openImageModal={openImageModal}
                                                auditSent={auditSent}
                                            />
                                        )}

                                        {storageService.getAudit().step5.pathology2.value && (
                                            <PhotoComponent
                                                title={'Moisissures dans l’habitation'}
                                                text={"Photo de l'endroit dans l'habitation ou se trouve de la moisissure"}
                                                fieldValue={'buildingPicturesPathology2'}
                                                url={values['buildingPicturesPathology2']?.url ?? ''}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                isValid={isValid}
                                                confirmDeleteImage={() => confirmDeleteImage()}
                                                base64Image={values['buildingPicturesPathology2']?.value ?? undefined}
                                                openImageModal={openImageModal}
                                                auditSent={auditSent}
                                            />
                                        )}

                                        {storageService.getAudit().step5.pathology3.value && (
                                            <PhotoComponent
                                                title={'Moisissures (Isolation murs intérieur)'}
                                                text={"Photo de l'endroit sur le mur intérieur ou se trouve de la moisissure"}
                                                fieldValue={'buildingPicturesPathology3'}
                                                url={values['buildingPicturesPathology3']?.url ?? ''}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                isValid={isValid}
                                                confirmDeleteImage={() => confirmDeleteImage()}
                                                base64Image={values['buildingPicturesPathology3']?.value ?? undefined}
                                                openImageModal={openImageModal}
                                                auditSent={auditSent}
                                            />
                                        )}

                                        {storageService.getAudit().step5.pathology5.value && (
                                            <PhotoComponent
                                                title={'Moisissures (Isolation murs extérieur)'}
                                                text={"Photo de l'endroit sur le mur extérieur ou se trouve de la moisissure"}
                                                fieldValue={'buildingPicturesPathology5'}
                                                url={values['buildingPicturesPathology5']?.url ?? ''}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                isValid={isValid}
                                                confirmDeleteImage={() => confirmDeleteImage()}
                                                base64Image={values['buildingPicturesPathology5']?.value ?? undefined}
                                                openImageModal={openImageModal}
                                                auditSent={auditSent}
                                            />
                                        )}

                                        {storageService.getAudit().step5.pathology4.value && (
                                            <PhotoComponent
                                                title={'Fissure (sur isolation murs extérieur)'}
                                                text={"Photo de l'endroit où se trouve la fissure"}
                                                fieldValue={'buildingPicturesPathology4'}
                                                url={values['buildingPicturesPathology4']?.url ?? ''}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                isValid={isValid}
                                                confirmDeleteImage={() => confirmDeleteImage()}
                                                base64Image={values['buildingPicturesPathology4']?.value ?? undefined}
                                                openImageModal={openImageModal}
                                                auditSent={auditSent}
                                            />
                                        )}

                                        {storageService.getAudit().step5.pathology6.value && (
                                            <PhotoComponent
                                                title={"Qualité de l'air intérieur"}
                                                text={"Photo de l'état des grilles sur les fenêtres"}
                                                fieldValue={'buildingPicturesPathology6'}
                                                url={values['buildingPicturesPathology6']?.url ?? ''}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                isValid={isValid}
                                                confirmDeleteImage={() => confirmDeleteImage()}
                                                base64Image={values['buildingPicturesPathology6']?.value ?? undefined}
                                                openImageModal={openImageModal}
                                                auditSent={auditSent}
                                            />
                                        )}

                                        {storageService.getAudit().step5.pathology7.value && (
                                            <PhotoComponent
                                                title={'Condensation'}
                                                text={'Photo des endroits où se forme la condensation'}
                                                fieldValue={'buildingPicturesPathology7'}
                                                url={values['buildingPicturesPathology7']?.url ?? ''}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                isValid={isValid}
                                                confirmDeleteImage={() => confirmDeleteImage()}
                                                base64Image={values['buildingPicturesPathology7']?.value ?? undefined}
                                                openImageModal={openImageModal}
                                                auditSent={auditSent}
                                            />
                                        )}

                                        <PhotoComponent
                                            title={`Facture d'électricité 2`}
                                            text={"Photo de votre facture d'électricité de l'avant dernière année "}
                                            fieldValue={'energyCostsElectricInvoice2'}
                                            url={values['energyCostsElectricInvoice2']?.url ?? ''}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            isValid={isValid}
                                            confirmDeleteImage={() => confirmDeleteImage()}
                                            base64Image={values['energyCostsElectricInvoice2']?.value ?? undefined}
                                            openImageModal={openImageModal}
                                            auditSent={auditSent}
                                        />

                                        <PhotoComponent
                                            title={`Facture d'électricité 3`}
                                            text={"Photo de votre facture d'électricité de l'avant dernière année "}
                                            fieldValue={'energyCostsElectricInvoice3'}
                                            url={values['energyCostsElectricInvoice3']?.url ?? ''}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            isValid={isValid}
                                            confirmDeleteImage={() => confirmDeleteImage()}
                                            base64Image={values['energyCostsElectricInvoice3']?.value ?? undefined}
                                            openImageModal={openImageModal}
                                            auditSent={auditSent}
                                        />

                                        {hasGaz && (
                                            <>
                                                <PhotoComponent
                                                    title={'Facture de gaz 2'}
                                                    text={"Photo de votre facture de gaz de l'avant dernière année"}
                                                    fieldValue={'energyCostsGasInvoice2'}
                                                    url={values['energyCostsGasInvoice2']?.url ?? ''}
                                                    values={values}
                                                    setFieldValue={setFieldValue}
                                                    isValid={isValid}
                                                    confirmDeleteImage={() => confirmDeleteImage()}
                                                    base64Image={values['energyCostsGasInvoice2']?.value ?? undefined}
                                                    openImageModal={openImageModal}
                                                    auditSent={auditSent}
                                                />

                                                <PhotoComponent
                                                    title={'Facture de gaz 3'}
                                                    text={"Photo de votre facture de gaz de l'avant dernière année"}
                                                    fieldValue={'energyCostsGasInvoice3'}
                                                    url={values['energyCostsGasInvoice3']?.url ?? ''}
                                                    values={values}
                                                    setFieldValue={setFieldValue}
                                                    isValid={isValid}
                                                    confirmDeleteImage={() => confirmDeleteImage()}
                                                    base64Image={values['energyCostsGasInvoice3']?.value ?? undefined}
                                                    openImageModal={openImageModal}
                                                    auditSent={auditSent}
                                                />
                                            </>
                                        )}

                                        <PhotoComponent
                                            title={'Autre'}
                                            text={'Vous pouvez insérer à cette étape tout autre photo que vous souhaiteriez transmettre'}
                                            smallText={true}
                                            fieldValue={'buildingPictureOther'}
                                            url={values['buildingPictureOther']?.url ?? ''}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            isValid={isValid}
                                            confirmDeleteImage={() => confirmDeleteImage()}
                                            base64Image={values['buildingPictureOther']?.value ?? undefined}
                                            openImageModal={openImageModal}
                                            auditSent={auditSent}
                                        />
                                    </div>
                                </fieldset>
                            </div>
                        </div>

                        <div className="navigation text-center">
                            <button
                                className={'btn btn-secondary maxi-min-width mx-2 mb-3'}
                                onClick={() => {
                                    returnToHome(8, '/audit');
                                }}
                            >
                                Retour au menu principal
                            </button>

                            <button
                                type="button"
                                disabled={checkStepsValidity()}
                                className="btn btn-primary maxi-min-width mx-2 mb-3"
                                onClick={async (e: any) => {
                                    e.preventDefault();

                                    let route;

                                    const campagne = storageService.getAuditCampagne();
                                    switch (campagne) {
                                        case 'action_logement':
                                            route = `${ROUTE_AUDIT_AL}/recapitulatif`;
                                            break;
                                        case 'reste_a_charge':
                                            route = `${ROUTE_AUDIT_RAC}/recapitulatif`;
                                            break;
                                        default:
                                            route = ROUTE_SIM_SYNTHESE;
                                            break;
                                    }
                                    validateThenRoute(8, route);
                                }}
                            >
                                {storageService.getAuditCampagne() ? <>Finaliser mon dossier</> : <>Accéder à ma synthèse</>}
                            </button>
                        </div>
                    </div>
                );
            }}
        </Formik>
    );
};

export default Photos;
