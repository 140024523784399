import React, { useState } from 'react';
import { Field, Formik, FieldProps } from 'formik';
import Select, { StylesConfig } from 'react-select';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { validationSchema } from './schema/schema';
import { tvaWorkList, workTypeList } from './TauxDeTvaEnums';
import { PropertyType } from '../../../services/localStorageService';
import * as storageService from '../../../services/localStorageService';

import { LabelledString } from '../../../services/tools/TypeHelper';
import withReactContent from 'sweetalert2-react-content';
import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import { ROUTE_SIM_ANNEXE, ROUTE_SIM_FINANCEMENT, ROUTE_SIM_RENDEMENT } from '../../../routing/paths';

// Set default value to <Select />
import { renderDefaultValueSelect } from '../../../services/tools/selectValue';
import * as api from '../../../services/apiParticulierService';
import * as bdc from '../parcoursBdcCommmons';
import PdfModalContent from '../../../components/PdfModalContent/PdfModalContent';
import { ModalCounter } from '../../../components/ModalCounter/ModalCounter';

// Icons
import { ReactComponent as IconTva } from '../../../assets/icons/simulator/icon-tva.svg';
import { ReactComponent as Loader } from '../../../assets/icons/loader.svg';
import { getFirstPanneau } from '../../../services/calculs/theme';

// README : voir BonDeCommande.tsx

// Les PDF que cet écran permet de créer
// EN CAS DE CHANGEMENT FAIRE ATTENTION AUX FONCTIONS initialisePdfContext().
const PDF_NAMES_FOR_THIS_SCREEN: api.DocNames[] = ['attestation_tva'];

interface FormValues {
    tvaPojectType: string;
    tvaWork: string;
    tvaProjectStatus: string;
    typeOfWork: string[];
    workCheckboxes: string[];
}

const TauxDeTva: React.FC = () => {
    const [loader, setLoader] = useState<boolean>(false);
    const [pdfLoader, setPdfLoader] = useState<boolean>(false);

    const context = useLocation<bdc.BonDeCommandeContexte>().state;
    const AlertSwal = withReactContent(Swal);

    // Values from step 2

    const step2 = storageService.getAudit().step2;
    // maison / appartement :
    const housingType = step2.projectType;
    // propriétaire / locataire
    const projectStatus = step2.projectStatus;
    // building Age, saying if house is more than 24 months old. => set up tva
    const houseIsOld = step2.buildingAge;

    // Form initialValues
    const initialValues: FormValues = {
        tvaPojectType: housingType.value_label,
        tvaWork: storageService.checkPropertyExistThenCreateOrRenderSimulator('tvaWork', 'Les travaux sont réalisés dans', PropertyType['withLabel']),
        tvaProjectStatus: housingType.value_label,
        typeOfWork: storageService.checkPropertyExistThenCreateOrRenderSimulator('typeOfWork', 'Nature des travaux', PropertyType['typeOfWork']),
        workCheckboxes: storageService.checkPropertyExistThenCreateOrRenderSimulator(
            'workCheckboxes',
            "Les travaux n'affectent que les éléments de second oeuvre suivants",
            PropertyType['workCheckboxes']
        ),
    };

    const [travauxSelect, setTravauxSelect] = useState<string[]>([]);
    const [workSelect, setWorkSelect] = useState<string[]>([]);

    React.useEffect(() => {
        setTravauxSelect(initialValues.workCheckboxes);
        setWorkSelect(initialValues.typeOfWork);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Leave empty to avoid infinite loop

    // 1st range of checkboxes
    const handleFirstRangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>, setValues: Function, values: FormValues) => {
        let newWorkSelect;

        if (workSelect.includes(e.target.value)) {
            newWorkSelect = workSelect.filter((o) => o !== e.target.value);
        } else {
            newWorkSelect = [...workSelect, e.target.value];
        }
        clearPdfContext(); // would force to re-doawnload pdf.

        // Set data into state
        setWorkSelect(newWorkSelect);

        // Set data into Formik values
        setValues({
            ...values,
            typeOfWork: newWorkSelect,
        });

        // Set data into localStorage
        storageService.setSimValue('typeOfWork', newWorkSelect);
    };

    // 2nd range of checkboxes
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, setValues: Function, values: FormValues) => {
        let newTravauxSelect = values.workCheckboxes;

        if (travauxSelect.includes(e.target.value)) {
            newTravauxSelect = travauxSelect.filter((o) => o !== e.target.value);
        } else {
            newTravauxSelect = [...travauxSelect, e.target.value];
        }
        clearPdfContext(); // would force to re-doawnload pdf.

        // Set data into state
        setTravauxSelect(newTravauxSelect);

        // Set data into Formik values
        setValues({ ...values, workCheckboxes: newTravauxSelect });

        // Set data into localStorage
        storageService.setSimValue('workCheckboxes', newTravauxSelect);
    };

    /**
     * Pour le changement de couleurs du react-select
     */
    const panelStyles: StylesConfig<LabelledString, false> = {
        menuList: (styles) => ({
            ...styles,
            color: '#434D5D',
            borderRadius: '4px',
            backgroundColor: 'white',
            padding: '0',
        }),

        indicatorSeparator: (styles) => ({
            ...styles,
            display: 'none',
        }),

        control: (styles) => ({
            ...styles,
            backgroundColor: '#FFF',
            color: '#434D5D',
            boxShadow: 'none',
            border: '1px solid #A1B5CC',
            minWidth: '100px',
            height: '43px',

            '&:focus': {
                border: '0',
                boxShadow: 'none',
            },
        }),

        option: (styles, state) => ({
            ...styles,
            backgroundColor: state.isSelected ? '#EEFAFF' : 'white',
            color: '#434D5D',

            '&:not(:last-child)': {
                borderBottom: '1px solid #E4E4E4',
            },

            '&:hover': {
                backgroundColor: '#EEFAFF',
                color: '#434D5D',
            },
        }),

        singleValue: (styles) => ({
            ...styles,
            color: '#434D5D',
        }),
    };

    // construit la vue des pdf par onglet pour l'affichage. (dans une AlertSwal.fire(...))
    const buildModalContent = (pdfList: Array<api.PdfOutput>): SweetAlertOptions => {
        const pdfContent = bdc.buildModalPdfContentBase();
        pdfContent.html = <PdfModalContent tabs={pdfList} />;
        return pdfContent;
    };

    // construit la modal qui affiche un compte à rebours.
    const buildModalCountDown = (seconds: number): SweetAlertOptions => {
        const content = bdc.buildModalCountDownBase(seconds * 1000);
        content.html = <ModalCounter timer={seconds} />;
        return content;
    };

    const pdfModal = () => {
        // Extract pdfList
        const pdfList = bdc.getOutput(context.pdfState, PDF_NAMES_FOR_THIS_SCREEN);

        // si on a déjà des pdf, on les affiche
        if (pdfList.length !== 0) {
            AlertSwal.fire(buildModalContent(pdfList));
            return;
        }

        setLoader(false);
        setPdfLoader(true);

        const aborter = new AbortController();

        AlertSwal.fire(buildModalCountDown(api.DOWNLOAD_TIME_OUT)).then((value: SweetAlertResult<any>) => {
            bdc.aborterCallback(value, aborter, setPdfLoader);
        });

        // sinon on les télécharge.
        bdc.downloadPdf(aborter, clearPdfContext, initialisePdfContext, context)
            .then(() => {
                // Extract pdfList
                // same call but context has change because we juste downloaded the pdf.
                const pdfList = bdc.getOutput(context.pdfState, PDF_NAMES_FOR_THIS_SCREEN);
                setPdfLoader(false);
                AlertSwal.close();
                AlertSwal.fire(buildModalContent(pdfList));
            })
            .catch((err) => {
                setLoader(false);
                setPdfLoader(false);

                bdc.logBdcError(err);

                // Modal contenant le message d'erreur
                AlertSwal.fire(bdc.buildModalSimpleMessage(api.ERROR_INDISPONIBLE));
            });
    };

    const { push } = useHistory();

    const clearPdfContext = (): void => {
        console.log('clearPdfContext');
        for (const docname of PDF_NAMES_FOR_THIS_SCREEN) {
            context.pdfState[docname] = {};
        }
    };

    // initialise le context des pdf de cet écran.
    const initialisePdfContext = (): api.DocParameters[] => {
        // ATTENTION :
        // DANS l'ideal il faudrait boucler sur PDF_NAMES_FOR_THIS_SCREEN
        // Mais vu que les paramètres sont très différents les uns des autres, on le fait à la main.
        // En cas de changement, s'assurer que tous les PDF décris dans PDF_NAMES_FOR_THIS_SCREEN sont couverts.

        const docName: api.DocNames = 'attestation_tva';
        const input: api.DocParameters<'attestation_tva'> = {
            docName,
            parameters: {
                tva_55: houseIsOld.value ? '1' : '2', // 5.5 pour les maisons de plus de 21 (ou 24 ??) mois.
                type_residence: housingType.value, // 1 :maison, 2: appartement. (par chanche les énums local et icoll correspondent.)
            },
        };
        context.pdfState[docName] = { input, output: undefined };

        return [input];
    };

    //  const ALL_TYPES_RESIDENCES = {
    //     "1": "Maison",
    //     "2": "Appartement"
    // } as const;

    /**
     * détermine si les PDF nécessaires de cet écran sont correctement initialisés!
     * La logique sera différente sur chaque écran !!!
     * On considère qu'un pdf initialisé lorsque
     *  - il est nécessaire
     *  - son input est correctement définie (!undefined)
     * @returns true si tout est initialisé correctement, false sinon.
     */
    const arePdfInitialized = (): boolean => {
        for (const docname of PDF_NAMES_FOR_THIS_SCREEN) {
            if (context.pdfState[docname].input === undefined) {
                return false;
            }
        }
        return true;
    };

    arePdfInitialized();

    const validateAndNavigate = async (): Promise<void> => {
        setLoader(true);
        setPdfLoader(false);

        try {
            // si on a initialisé le(s) pdf(s), on le fait
            if (!arePdfInitialized()) {
                initialisePdfContext();
            }
            // for (const docname of PDF_NAMES_FOR_THIS_SCREEN) {
            //     console.log('Continuer ecran tva, context = ' + JSON.stringify(context.pdfState[docname], null, 2));
            // }
            // puis on navigue.
            push(ROUTE_SIM_FINANCEMENT, context);
            setLoader(false);
        } catch (err) {
            //console.log((err as any).response.message);
            setLoader(false);
            setPdfLoader(false);

            // Modal contenant le message d'erreur
            AlertSwal.fire({
                title: 'Erreur',
                icon: 'error',
                html: <p>{api.ERROR_INDISPONIBLE}</p>,
                width: 600,
                confirmButtonText: 'Fermer',
                customClass: {
                    confirmButton: 'btn btn-continue min-width',
                },
            });
        }
    };
    return (
        <Formik validateOnMount={true} initialValues={initialValues} validationSchema={validationSchema} enableReinitialize onSubmit={() => {}}>
            {({ values, errors, handleBlur, setValues, handleChange }) => {
                return (
                    <div className="container container-audit">
                        <h1 className="main-title-mini">Taux de TVA</h1>

                        <div className="card card-audit-simulator tauxdetva mb-5">
                            <div className="card-header">
                                <IconTva fill="#FFFFFF" />
                                <h2>Définir la nature des locaux</h2>
                            </div>

                            <div className="card-body">
                                <div className="row mb-4">
                                    <div className="col-12 col-md-4 mb-4">
                                        <label htmlFor="tvaPojectType">Type d'habitation</label>
                                        <input type="text" id="tvaPojectType" name="tvaPojectType" value={housingType.value_label} disabled={true} />
                                    </div>

                                    <div className="col-12 col-md-4 mb-4">
                                        <label htmlFor="tvaWork">Les travaux sont réalisés dans</label>
                                        <Select
                                            value={renderDefaultValueSelect(tvaWorkList, values.tvaWork)}
                                            onBlur={handleBlur}
                                            placeholder={''}
                                            options={tvaWorkList}
                                            isMulti={false}
                                            isClearable={false}
                                            isSearchable={false}
                                            onChange={(event) => {
                                                clearPdfContext(); // would force to re-doawnload pdf.
                                                setValues({
                                                    ...values,
                                                    tvaWork: event.value,
                                                });

                                                storageService.setSimValue('tvaWork', event.value, event.label);
                                            }}
                                            inputId={'tvaWork'}
                                            styles={panelStyles}
                                        />
                                    </div>

                                    <div className="col-12 col-md-4 mb-4">
                                        <label htmlFor="tvaProjectStatus">Status résidentiel</label>
                                        <input type="text" id="tvaProjectStatus" name="tvaProjectStatus" value={projectStatus.value_label} disabled={true} />
                                    </div>
                                </div>

                                <div className="row justify-content-center mb-4">
                                    <div className="col-12 col-md-9">
                                        <h3 className="tva-title">Nature des travaux</h3>

                                        <Field name="typeOfWork" id="typeOfWork" onChange={handleChange}>
                                            {({ field }: FieldProps) => {
                                                return (
                                                    <>
                                                        <div className="form-group contract-checkbox">
                                                            <input
                                                                type="checkbox"
                                                                name="travauxAffectent"
                                                                id="travauxAffectent"
                                                                value="travauxAffectent"
                                                                checked={field.value?.includes('travauxAffectent') ?? false}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                    handleFirstRangeCheckbox(e, setValues, values);
                                                                }}
                                                            />
                                                            <label htmlFor="travauxAffectent" className="label-to-p">
                                                                Les travaux n'affectent :<br />
                                                                <span>
                                                                    Ni les fondations, ni les éléments, hors fondations, déterminant la résistance et la
                                                                    rigidité de l'ouvrage, ni la consistance des façades (hors ravalement)
                                                                </span>
                                                            </label>
                                                            <div className="fake-box"></div>
                                                        </div>

                                                        <div className="form-group contract-checkbox">
                                                            <input
                                                                type="checkbox"
                                                                name="travauxAffactent5ou6Elements"
                                                                value="travauxAffactent5ou6Elements"
                                                                id="travauxAffactent5ou6Elements"
                                                                checked={field.value?.includes('travauxAffactent5ou6Elements') ?? false}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                    handleFirstRangeCheckbox(e, setValues, values);

                                                                    if (field.value?.includes('travauxAffactent5ou6Elements')) {
                                                                        setTravauxSelect([]);
                                                                        storageService.removeSimValue('workCheckboxes', false, true);
                                                                    }
                                                                }}
                                                            />

                                                            <label htmlFor="travauxAffactent5ou6Elements" className="label-to-p">
                                                                Les travaux n’affectent pas plus de cinq des six éléments de second oeuvre suivants :
                                                            </label>
                                                            <div className="fake-box"></div>

                                                            <Field name="workCheckboxes" id="workCheckboxes" onChange={handleChange}>
                                                                {({ field }: FieldProps) => (
                                                                    <>
                                                                        {workTypeList.map((element, index) => (
                                                                            <div className="form-check" key={index}>
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="checkbox"
                                                                                    id={element.name}
                                                                                    value={element.name}
                                                                                    onChange={(e) => handleCheckboxChange(e, setValues, values)}
                                                                                    disabled={
                                                                                        !values.typeOfWork?.includes('travauxAffactent5ou6Elements') ||
                                                                                        values.typeOfWork?.length === 0
                                                                                    }
                                                                                    checked={field.value?.includes(element.name) ?? false}
                                                                                />
                                                                                <label className="form-check-label" htmlFor={element.name}>
                                                                                    {element.label}
                                                                                </label>
                                                                            </div>
                                                                        ))}
                                                                    </>
                                                                )}
                                                            </Field>
                                                        </div>

                                                        <div className="form-group contract-checkbox">
                                                            <input
                                                                type="checkbox"
                                                                name="travauxAugmentationSurface"
                                                                id="travauxAugmentationSurface"
                                                                value="travauxAugmentationSurface"
                                                                checked={field.value?.includes('travauxAugmentationSurface') ?? false}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                    handleFirstRangeCheckbox(e, setValues, values);
                                                                }}
                                                            />
                                                            <label htmlFor="travauxAugmentationSurface" className="label-to-p">
                                                                Les travaux n'entrainent pas une augmentation de la surface de plancher hors oeuvre nette
                                                                (majorée pour les bâtiments d'exploitations agricoles de la surface de plancher hors oeuvre
                                                                brute) des locaux existants supérieure à 10%
                                                            </label>
                                                            <div className="fake-box"></div>
                                                        </div>

                                                        <div className="form-group contract-checkbox">
                                                            <input
                                                                type="checkbox"
                                                                name="travauxAddition"
                                                                id="travauxAddition"
                                                                value="travauxAddition"
                                                                checked={field.value?.includes('travauxAddition') ?? false}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                    handleFirstRangeCheckbox(e, setValues, values);
                                                                }}
                                                            />

                                                            <label htmlFor="travauxAddition" className="label-to-p">
                                                                Les travaux ne consistent pas en une surélévation ou une addition de construction
                                                            </label>

                                                            <div className="fake-box"></div>
                                                        </div>
                                                    </>
                                                );
                                            }}
                                        </Field>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="btn-grp justify-content-end">
                            <Link
                                to={{
                                    pathname: getFirstPanneau(context.themes) !== undefined ? ROUTE_SIM_RENDEMENT : ROUTE_SIM_ANNEXE,
                                    state: context,
                                }}
                                className="btn btn-retour"
                            >
                                Retour
                            </Link>

                            <div className={`${Object.entries(errors).length > 0 || pdfLoader || loader ? 'not-allowed' : 'allowed'}`}>
                                <button
                                    type="button"
                                    className={`btn btn-bdc-modal${Object.entries(errors).length > 0 ? ' disabled' : ''}`}
                                    onClick={pdfModal}
                                    disabled={loader || pdfLoader}
                                >
                                    {pdfLoader && <Loader />}
                                    <span style={{ opacity: pdfLoader ? '0' : '1' }}>Visualiser le document</span>
                                </button>
                            </div>
                            <div className={`${Object.entries(errors).length > 0 || pdfLoader || loader ? 'not-allowed' : 'allowed'}`}>
                                <button
                                    className={`btn btn-continue ${Object.entries(errors).length > 0 ? 'disabled' : ''}`}
                                    onClick={validateAndNavigate}
                                    disabled={loader || pdfLoader}
                                >
                                    {loader && <Loader />}
                                    <span style={{ opacity: loader ? '0' : '1' }}>Continuer</span>
                                </button>
                            </div>
                        </div>
                    </div>
                );
            }}
        </Formik>
    );
};

export default TauxDeTva;
