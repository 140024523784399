import React from 'react';

interface Props {
    values: any;
    propName: any;
    name: string;
    handleCheckbox: any;
    onBlur?: any;
    title: string;
    alt?: string;
    emptyLabel?: boolean;
    disabled?: boolean;
    isValid: boolean;
    setValues: any;
    border?: boolean;
}

const CheckboxService: React.FC<Props> = ({ handleCheckbox, onBlur, values, propName, name, title, alt, emptyLabel, disabled, isValid, setValues, border }) => {
    return (
        <>
            {emptyLabel && <label>&nbsp;</label>}
            <div
                className={
                    'form-check form-switch custom-switch switch-intermediate' + (border ? ' green-border' : '') + (values[name] === '' ? ' undefined' : '')
                }
            >
                <label className="form-check-label" htmlFor={name}>
                    <span>
                        {title}
                        <sup>*</sup>
                    </span>
                    {alt && <small>{alt}</small>}
                </label>

                <div className="input-values">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id={name}
                        checked={propName}
                        disabled={disabled}
                        onBlur={onBlur}
                        onChange={() => null}
                    />

                    <button type="button" disabled={disabled} className="btn-true" onClick={() => handleCheckbox(values, isValid, setValues, true)}></button>

                    <button type="button" disabled={disabled} className="btn-false" onClick={() => handleCheckbox(values, isValid, setValues, false)}></button>
                </div>
            </div>
        </>
    );
};

export default CheckboxService;
