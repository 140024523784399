import React, { useEffect, useRef, useState } from 'react';
import * as storageService from '../../services/localStorageService';
import useOnClickOutside from '../../hook/useOnClickOutside';
import { LightMutation, chercherDernierPrixConnu, evaluerMonBienPrixM2 } from '../../services/foncier/service';
import { getLevelCount } from '../../services/tools/auditTools';

// Style
import './ModaleFoncier.scss';

// Icons
import { ReactComponent as IconClose } from '../../assets/icons/icon-close.svg';
import { ReactComponent as IconHouse } from '../../assets/icons/simulator/icon-house.svg';
import { ReactComponent as IconChart } from '../../assets/icons/simulator/icon-chart-up.svg';
interface Props {
    topTitle?: string;
    closeTop?: boolean;
    title: string;
    close: () => void;
}

const ModaleFoncier: React.FC<Props> = (props: Props) => {
    const ref = useRef(null);

    const { audit } = storageService.stepListToAuditAndClient();

    const [lastKnownMutation, setLastKnownMutation] = useState<LightMutation | undefined>(undefined);
    const [prixM2Estime, setPrixM2Estime] = useState<number>(0);

    const handleClickOutsite = () => {
        props.close();
    };

    useOnClickOutside(ref, handleClickOutsite);

    useEffect(() => {
        chercherDernierPrixConnu().then((mutation: LightMutation | undefined) => {
            if (mutation) {
                console.log('dernier prix connu : ' + mutation);
                setLastKnownMutation(mutation);
            }
            evaluerMonBienPrixM2().then((value) => {
                console.log('Evaluation de mon bien : ' + value + '€');
                setPrixM2Estime(value ?? 0);
            });
        });
    }, []);

    const currencyFormatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 0,
    });

    const noCurrencyFormatter = new Intl.NumberFormat('fr-FR', {
        style: 'decimal',
        maximumFractionDigits: 0,
    });

    return (
        <div className="modal modal-custom modal-foncier" tabIndex={0} role="dialog" aria-labelledby={props.title}>
            <div className="modal-dialog" role="document">
                <div className="modal-content" ref={ref}>
                    {props.topTitle && <p className="modal-top-title">{props.topTitle}</p>}

                    {props.closeTop && (
                        <button type="button" className="btn btn-modal-close" onClick={handleClickOutsite}>
                            <IconClose fill="#FD5C21" />
                        </button>
                    )}
                    <div className="modal-header">
                        <h2 className="modal-title modal-title--custom">{props.title}</h2>
                    </div>

                    <div className="modal-body">
                        <div className="row justify-content-between">
                            <div className="col-12 col-md-7">
                                <div className="modal-body--list">
                                    <h3 className="list-title">
                                        <IconHouse fill="#0081d9" width={28} />
                                        Valorisation actuelle
                                    </h3>
                                    <ul>
                                        <li>
                                            Type : <span>{audit.projectType.value === '1' ? 'Maison' : 'Appartement'}</span>
                                        </li>
                                        <li>
                                            Surface : <span>{Math.trunc(+audit.SHab.value)} m²</span>
                                        </li>
                                        {lastKnownMutation && lastKnownMutation.valeurfonc ? (
                                            <>
                                                <li>
                                                    Surface au sol : <span>{parseInt(lastKnownMutation.sbati)} m²</span>
                                                </li>
                                                <li>
                                                    Montant de la dernière transaction* :&nbsp;
                                                    <span>{currencyFormatter.format(Math.round(+lastKnownMutation.valeurfonc))}</span>
                                                </li>
                                                <li>
                                                    Année de la dernière transaction* : <span>{lastKnownMutation.anneemut}</span>
                                                </li>
                                                <li>
                                                    Prix au m² de la dernière transaction* :&nbsp;
                                                    <span>
                                                        {currencyFormatter.format(Math.round(+lastKnownMutation.valeurfonc / +lastKnownMutation.sbati))}
                                                    </span>
                                                </li>
                                            </>
                                        ) : (
                                            <>
                                                <li>
                                                    Surface au sol : <span>{Math.trunc(+audit.SHab.value / getLevelCount(audit, 'horsSSavecCA'))} m²</span>
                                                </li>
                                                {/* <li>
                                                    Montant de la dernière transaction* : <span>inconnu</span>
                                                </li> */}
                                                <li>
                                                    Estimation du prix du bien* :&nbsp;
                                                    <span>
                                                        {prixM2Estime ? currencyFormatter.format(Math.round(+prixM2Estime * +audit.SHab.value)) : <>inconnu</>}
                                                    </span>
                                                </li>
                                                {/* <li>
                                                    Année de la dernière transaction* : <span>inconnu</span>
                                                </li>
                                                <li>
                                                    Prix au m² de la dernière transaction* : <span>inconnu</span>
                                                </li> */}
                                            </>
                                        )}
                                        <li>
                                            Prix au m² médian de la commune* :&nbsp;
                                            <span>{prixM2Estime ? currencyFormatter.format(Math.round(+prixM2Estime)) : <>inconnu</>}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-12 col-md-5">
                                <div className="fallacious-result">
                                    <h3 className="fallacious-result-title">
                                        Valorisation
                                        <br />
                                        <span>aprés</span> rénovation énergetique**
                                    </h3>
                                    {lastKnownMutation && lastKnownMutation.valeurfonc ? (
                                        <div className="fallacious-prices">
                                            <span className="fw-bold">{noCurrencyFormatter.format(Math.round(+lastKnownMutation.valeurfonc * 1.15))}</span>
                                            &nbsp;à&nbsp;
                                            <span className="fw-bold">{currencyFormatter.format(Math.round(+lastKnownMutation.valeurfonc * 1.25))}</span>
                                        </div>
                                    ) : (
                                        <div className="fallacious-prices">
                                            <span className="fw-bold">
                                                {prixM2Estime ? (
                                                    noCurrencyFormatter.format(Math.round(+prixM2Estime * +audit.SHab.value * 1.15))
                                                ) : (
                                                    <i>inconnu</i>
                                                )}
                                            </span>
                                            &nbsp;à&nbsp;
                                            <span className="fw-bold">
                                                {prixM2Estime ? currencyFormatter.format(Math.round(+prixM2Estime * +audit.SHab.value * 1.25)) : <i>inconnu</i>}
                                            </span>
                                        </div>
                                    )}
                                    <div className="fallacious-result-bottom">
                                        <IconChart width="40" fill="#0081d9" />
                                        <p>
                                            +15 à 25%
                                            <br />
                                            <span>de gain</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <p className="fallacious-dot mt-3 mb-0">
                                *Estimation effectuée sur la base des valeurs partagées par la DGFIP (Direction Générale des Finances Publics) pour votre
                                commune
                            </p>
                            <p className="fallacious-dot mt-1 mb-0">
                                **Selon l'association Dinamic créée par le Conseil supérieur du notariat et la Chambre des notaires de Paris.
                                <br />
                                (Développement de l'information Notariale et de l'Analyse du Marché immobilier et de la Conjoncture)
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModaleFoncier;
