import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    tvaPojectType: Yup.string().notRequired(),
    tvaWork: Yup.string().required(`Les travaux sont réalisés dans est obligatoire`),
    tvaProjectStatus: Yup.string().notRequired(),
    // Nature des travaux
    typeOfWork: Yup.array().min(1, 'Vous devez sélectionner au moins 1 option').of(Yup.string().required()),
    // Checkboxes if travauxAffactent5ou6Elements
    workCheckboxes: Yup.array()
        .notRequired()
        .when('typeOfWork', {
            is: (value: string[]) => value.includes('travauxAffactent5ou6Elements'),
            then: Yup.array().min(1, 'Vous devez sélectionner au moins 1 option').of(Yup.string().required()),
        }),
});
