import { StylesConfig } from 'react-select';
import { generateArrayOfYears } from '../../../services/tools/dateService';
import { LabelledString } from '../../../services/tools/TypeHelper';
import { DP5HistoryItem } from './component/DP5/DP5Enums';
import { HistoryItem, ModalType } from '../../../services/tools/konvaTools';
import { BBox } from 'geojson';

export type Point = {
    x: number;
    y: number;
};

export const roofShapeNames = ['toiture', 'cheminee', 'velux', 'chien_assis', 'panneaux_photovoltaiques'] as const;
export type RoofShapeName = (typeof roofShapeNames)[number];

export type RoofShapeModule = Point[];

export type RoofShape = Record<RoofShapeName, { color: string; elements: RoofShapeModule[] }>;

export type FormValues = {
    plannedInstallation: string;
    electricMeterLocalisation: string;
    electricalTypeNetwork: string;
    electricMeterOutside: string;
    circuitBreaker: string;
    distance: string;
    pdlNumber: string;
    electricMeter30m: boolean | '';
    electricMeterSpace: boolean | '';
    remoteReport: boolean | '';
    remoteReportLocalisation: string;
    groundConnection: boolean | '';
    groundConnectionLocalisation: string;
    linkyMeter: boolean | '';
    gutterHeight: string;
    internet: boolean | '';
    fai: string;
    materialStorage: string;
    siteAccessibility: string;
    immediatePavement: boolean | '';
    coverType: string;
    coverTilesDetail: string;
    coverConstructionYear: string;
    tilesState: string;
    fullRenovation: boolean | '';
    drawSchema: boolean;
    distanceBelow3KmFromCoastline: boolean | '';
    roofShape: RoofShape;
    schemaScale: string;
    roofShapePicture: string;
    edfMeterPicture: string;
    electricMeterPicture: string;
    circuitBreakerPicture: string;
    inverterOrEnergyMeterRoomPicture: string;
    houseRearFacePicture: string;
    singleStoreyHousePicture: string;
    history: HistoryItem[];
    historyDP5: DP5HistoryItem[];
    parcel: string | undefined;
    panoramicBackPicture1: string;
    panoramicBackPicture2: string;
    panoramicBackPicture3: string;
    panoramicFacePicture1: string;
    panoramicFacePicture2: string;
    panoramicFacePicture3: string;
};

/**
 * 0 - Maison
 * 1 - Annexe
 * 2 - Sol
 */
export const plannedInstallationList: Array<LabelledString> = [
    {
        label: 'Maison',
        value: '0',
    },
    {
        label: 'Annexe',
        value: '1',
    },
    {
        label: 'Sol',
        value: '2',
    },
];

/**
 * 0 - Dans le maison
 * 1 - A l'extérieur de la maison
 * 2 - Dans une dépendance
 * 3 - En extérieur sur le domaine public, hors coffret
 */
export const electricMeterLocalisationList: Array<LabelledString> = [
    {
        label: 'Dans la maison',
        value: 'dans la maison',
    },
    {
        label: "A l'extérieur de la maison",
        value: 'exterieur',
    },
    {
        label: 'Dans une dépendance',
        value: 'dans une dependance',
    },
    {
        label: 'En extérieur sur le domaine public, hors coffret',
        value: 'public',
    },
];

/**
 * 0 - Aérien (un câble aérien relie le compteur extérieur, ou le réseau EDF, à la maison)
 * 1 - Aéro-souterrain
 * 2 - Souterrain (pas de cable aérien apparent entre le compteur extérieur, ou le réseau EDF, et la maison)
 */
export const electricalTypeNetworkList: Array<LabelledString> = [
    {
        label: 'Aérien (un câble aérien relie le compteur extérieur, ou le réseau EDF, à la maison)',
        value: '0',
    },
    {
        label: 'Aéro-souterrain',
        value: '1',
    },
    {
        label: 'Souterrain (pas de cable aérien apparent entre le compteur extérieur, ou le réseau EDF, et la maison)',
        value: '2',
    },
];

/**
 * 0 - Modèle plutôt large (54cm)
 * 1 - Modèle moyennement large (35cm)
 * 2 - Modèle plutôt étroit (23cm)
 * 3 - Sur poteau, le compteur pouvant être apparant (sans porte)
 * 4 - Non, pas de coffret extérieur ni de compteur extérieur apparent
 */
export const electricMeterOutsideList: Array<LabelledString> = [
    {
        label: 'Modèle plutôt large (54cm)',
        value: '0',
    },
    {
        label: 'Modèle moyennement large (35cm)',
        value: '1',
    },
    {
        label: 'Modèle plutôt étroit (23cm)',
        value: '2',
    },
    {
        label: 'Sur poteau, le compteur pouvant être apparant (sans porte)',
        value: '3',
    },
    {
        label: 'Non, pas de coffret extérieur ni de compteur extérieur apparent',
        value: '4',
    },
];

/**
 * 0 - Dans la maison
 * 1 - Avec le compteur
 * 2 - Avec le compteur à l'éxtérieur
 */
export const circuitBreakerList: Array<LabelledString> = [
    {
        label: 'Dans la maison',
        value: '0',
    },
    {
        label: 'Avec le compteur',
        value: '1',
    },
    {
        label: "Avec le compteur à l'extérieur",
        value: '2',
    },
];

/**
 * Loop
 */

// Step from 0 to 20 1 by 1
const from0To20 = Array.from(Array(20).keys()).map((value) => ({ label: value.toString(), value: value.toString() }));
// Step from 20 to 200 5 by 5
const from20To200 = Array.from(Array(201).keys())
    .filter((value) => value % 5 === 0 && value >= 20)
    .map((value) => ({ label: value.toString(), value: value.toString() }));

export const distanceList = [...from0To20, ...from20To200];

export const panelStyles: StylesConfig<LabelledString, false> = {
    menuList: (styles) => ({
        ...styles,
        color: '#434D5D',
        borderRadius: '4px',
        backgroundColor: 'white',
        padding: '0',
    }),

    indicatorSeparator: (styles) => ({
        ...styles,
        display: 'none',
    }),

    control: (styles) => ({
        ...styles,
        backgroundColor: '#FFF',
        color: '#434D5D',
        boxShadow: 'none',
        border: '1px solid #A1B5CC',
        minWidth: '70px',
        height: '43px',

        '&:focus': {
            border: '0',
            boxShadow: 'none',
        },
    }),

    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? '#EEFAFF' : 'white',
        color: '#434D5D',

        '&:not(:last-child)': {
            borderBottom: '1px solid #E4E4E4',
        },

        '&:hover': {
            backgroundColor: '#EEFAFF',
            color: '#434D5D',
        },
    }),

    placeholder: (styles) => ({
        ...styles,
        color: '#C1C1C1',
        whiteSpace: 'nowrap',
    }),

    singleValue: (styles) => ({
        ...styles,
        color: '#434D5D',
    }),
};

export const gutterHeightList: Array<LabelledString> = [
    {
        label: '3m (maison de plein-pied)',
        value: '3m plein-pied',
    },
    {
        label: '3m (autre)',
        value: '3m autre',
    },
    {
        label: '6m (avec un étage)',
        value: '6m etage',
    },
    {
        label: '6m (avec un accès en 2 fois 3 mètres : autre toit ou terrasse)',
        value: '6m toit ou terrasse',
    },
    {
        label: 'Echafaudage (plus de 6m)',
        value: 'echafaudage',
    },
    {
        label: 'A installer sur le domaine public',
        value: 'domaine public',
    },
    {
        label: 'Autre',
        value: 'autre',
    },
];

export const coverTypeList: Array<LabelledString> = [
    {
        label: 'Tuile canal',
        value: 'tuile canal',
    },
    {
        label: 'Tuile romane',
        value: 'tuile romane',
    },
    {
        label: 'Tuile plate',
        value: 'tuile plate',
    },
    {
        label: 'Autre tuile',
        value: 'autre tuile',
    },
    {
        label: 'Tuile ondulée en fibrociment (Everite ou Eternit)',
        value: 'tuile ondulee',
    },
    {
        label: 'Ardoise',
        value: 'ardoise',
    },
    {
        label: 'Zinc',
        value: 'zinc',
    },
    {
        label: 'Shingle',
        value: 'shingle',
    },
    {
        label: 'Autre',
        value: 'autre',
    },
];

export const coverConstructionYearList: Array<LabelledString> = generateArrayOfYears();

export const tilesStateList: Array<LabelledString> = [
    {
        label: 'Neuf ou équivalent',
        value: 'neuf ou equivalent',
    },
    {
        label: 'Bon état',
        value: 'bon etat',
    },
    {
        label: 'Mauvais état',
        value: 'mauvais etat',
    },
    {
        label: 'Très mauvais état',
        value: 'tres mauvais etat',
    },
];

export const materialStorageList: Array<LabelledString> = [
    {
        label: 'Intérieur',
        value: 'interieur',
    },
    {
        label: 'Extérieur',
        value: 'exterieur',
    },
];

export const siteAccessibilityList: Array<LabelledString> = [
    {
        label: 'Estafette (petit véhicule)',
        value: 'estafette',
    },
    {
        label: 'Camionnette',
        value: 'camionnette',
    },
    {
        label: 'Camion',
        value: 'camion',
    },
    {
        label: 'Camion Semi-remorque',
        value: 'camion semi remorque',
    },
    {
        label: "Pas d'accès",
        value: 'aucun acces',
    },
];

export type HistoryProps = {
    setSelectedElementIndex: React.Dispatch<React.SetStateAction<number | null>>;
    canDraw?: boolean;
    setCanDraw?: React.Dispatch<React.SetStateAction<boolean>>;
    openModal: (type: ModalType) => void;
    parcelNumber: string | undefined;
    updateParcel: (parcel?: string) => Promise<void>;
    disabled: boolean;
    disabledCadastre: boolean;
};

export type HistoryDP5Props = {
    setSelectedElementIndex: React.Dispatch<React.SetStateAction<number | null>>;
    canDraw?: boolean;
    setCanDraw?: React.Dispatch<React.SetStateAction<boolean>>;
    openModal: (type: ModalType) => void;
    disabled: boolean;
};

export type ParcelleProperties = {
    numero: string;
    contenance: number;
    idu: string;
    feuille: number;
    section: string;
    code_dep: string;
    code_arr: string;
    code_com: string;
    code_insee: string;
    nom_com: string;
    com_abs: string;
    echelle: string;
    edition: number;
    bbox: BBox;
};

export type FeuilleProperties = {
    feuille: number;
    section: string;
    code_dep: string;
    code_arr: string;
    code_com: string;
    code_insee: string;
    nom_com: string;
    com_abs: string;
    echelle: string;
    edition: number;
    bbox: BBox;
};

export type DivisionProperties = {
    feuille: number;
    section: string;
    code_dep: string;
    code_arr: string;
    code_com: string;
    code_insee: string;
    nom_com: string;
    com_abs: string;
    echelle: string;
    edition: number;
    bbox: BBox;
};
