import React, { useState } from 'react';
import * as api from '../../services/apiParticulierService';

interface Props {
    tabs: api.PdfOutput[];
}

const PdfModalContent: React.FC<Props> = ({ tabs }) => {
    // First tab is set active by default
    const [activeTab, setActiveTab] = useState<number>(0);

    // Change active tab
    const handleActiveTab = (index: number) => {
        setActiveTab(index);
    };

    // tabs.filter => hide elements with no title and no filePath
    return (
        <div className="tabs">
            <div className="tabs-header">
                {tabs
                    .filter((element) => element.filePath && element.doc_lb)
                    .map((element, index) => (
                        <button
                            className={`btn btn-tabs${activeTab === index ? ' active' : ''}`}
                            key={index}
                            data-index={index}
                            onClick={() => handleActiveTab(index)}
                        >
                            {element.doc_lb}
                        </button>
                    ))}
            </div>
            <div className="tabs-body">
                <iframe title="PDF" src={tabs[activeTab].filePath} width="100%" height="600" id="iframePdf" />
            </div>
        </div>
    );
};

export default PdfModalContent;
