import { StylesConfig } from 'react-select';
import { generateNumberList, LabelledString } from '../../../services/tools/TypeHelper';

export type HeatingCircuit = {
    type: string;
    source: string;
    temperature: string;
};

export type RadiatorCircuit = {
    thermostaticValve: boolean | '';
    width: number | '';
    height: number | '';
    depth: number | '';
    radiatorType: string;
    topView: string;
    inputTemperature: number | '';
    outputTemperature: number | '';
};

export type FormValues = {
    electricityMeterAmps: string;
    heatPump: string;
    heatingInPlaceRetained: boolean | '';
    externalGroup: string;
    wallType: string;
    soilTypeSetup: string;
    externalGroupSetup: string;
    crawlSpace: boolean | '';
    heatingCircuit: HeatingCircuit[];
    // Radiateur
    identicalRadiators: boolean | undefined;
    numberOfRadiator: number | '';
    radiatorCircuit: RadiatorCircuit[];
};

// Start, End, Step
export const heatPumpList: Array<LabelledString> = generateNumberList(0, 90, 1);

export const externalGroupList: Array<LabelledString> = [
    {
        label: 'Nord',
        value: 'N',
    },
    {
        label: 'Est',
        value: 'E',
    },
    {
        label: 'Ouest',
        value: 'O',
    },
    {
        label: 'Sud',
        value: 'S',
    },
];

export const positionList: Array<LabelledString> = [
    {
        label: 'Sol',
        value: 'sol',
    },
    {
        label: 'Murale',
        value: 'murale',
    },
];

export const wallTypeList: Array<LabelledString> = [
    {
        label: 'Murs en pans de bois',
        value: 'murs en pans de bois',
    },
    {
        label: 'Mur double brique pleine',
        value: 'mur double brique pleine',
    },
    {
        label: 'Mur béton banché',
        value: 'mur beton banche',
    },
    {
        label: 'Mur en pierre',
        value: 'mur en pierre',
    },
    {
        label: 'Mur en bois',
        value: 'mur en bois',
    },
    {
        label: 'Mur en brique creuse',
        value: 'mur en brique creuse',
    },
    {
        label: 'Mono mur',
        value: 'mono mur',
    },
    {
        label: 'Mur parpaing creux',
        value: 'mur parpaing creux',
    },
    {
        label: 'Mur brique pleine',
        value: 'mur brique pleine',
    },
    {
        label: 'Mur béton plein',
        value: 'mur beton plein',
    },
    {
        label: 'Béton cellulaire',
        value: 'beton cellulaire',
    },
    {
        label: 'Cloison de plâtre',
        value: 'cloison de platre',
    },
    {
        label: 'Inconnu',
        value: 'inconnu',
    },
];

export const soilTypeList: Array<LabelledString> = [
    {
        label: 'Dallage existant',
        value: 'dallage existant',
    },
    {
        label: 'Dallage à réaliser',
        value: 'dallage a realiser',
    },
    {
        label: 'Dallage réalisé par le client',
        value: 'dallage realise par le client',
    },
];

export const externalGroupSetupList: Array<LabelledString> = [
    {
        label: 'Murs',
        value: 'murs',
    },
    {
        label: 'Sols',
        value: 'sols',
    },
];

export const heatPumpTypeList: Array<LabelledString> = [
    {
        label: 'Direct',
        value: 'Direct',
    },
    {
        label: 'Vannes 3 voies',
        value: 'Vannes 3 voies',
    },
];

export const heatPumpSourceList: Array<LabelledString> = [
    {
        label: 'Radiateur',
        value: 'Radiateur',
    },
    {
        label: 'Plancher chauffant basse température',
        value: 'Plancher chauffant basse température',
    },
    {
        label: 'Ventilo convecteur',
        value: 'Ventilo convecteur',
    },
];

export const panelStyles: StylesConfig<LabelledString, false> = {
    menuList: (styles) => ({
        ...styles,
        color: '#434D5D',
        borderRadius: '4px',
        backgroundColor: 'white',
        padding: '0',
    }),

    indicatorSeparator: (styles) => ({
        ...styles,
        display: 'none',
    }),

    control: (styles) => ({
        ...styles,
        backgroundColor: '#FFF',
        color: '#434D5D',
        boxShadow: 'none',
        border: '1px solid #A1B5CC',
        minWidth: '100px',
        height: '43px',

        '&:focus': {
            border: '0',
            boxShadow: 'none',
        },
    }),

    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? '#EEFAFF' : 'white',
        color: '#434D5D',

        '&:not(:last-child)': {
            borderBottom: '1px solid #E4E4E4',
        },

        '&:hover': {
            backgroundColor: '#EEFAFF',
            color: '#434D5D',
        },
    }),

    placeholder: (styles) => ({
        ...styles,
        color: '#cccccc',
        whiteSpace: 'nowrap',
    }),

    singleValue: (styles) => ({
        ...styles,
        color: '#434D5D',
    }),
};

export const panelDisabled: StylesConfig<LabelledString, false> = {
    control: (styles) => ({
        ...styles,
        height: '43px',
        minWidth: '100px',
        width: '100%',
        backgroundColor: '#F5F5F5',
        border: '1px solid #A1B5CC',
    }),

    placeholder: (styles) => ({
        ...styles,
        color: '#C1C1C1',
    }),

    indicatorSeparator: (styles) => ({
        ...styles,
        display: 'none',
    }),
};

export const radiatorNumberList: Array<LabelledString> = generateNumberList(1, 50, 1);

export const electricityMeterAmpsList: Array<LabelledString> = generateNumberList(5, 200, 5);
