import { isCurrentAuditMar } from '../localStorageService';
import { getFirstPackMMA, getFirstPanneau, Theme } from './theme';

// Read me
//
// Ici, j'ai ajouté les règles pour gérer les compatibilité de selection de package, qu'on ne peut pas traiter directement.
// l'utilisateur clique sur le selecteur d'un package, on traite ce clic.
// puis on refait une passe sur toutes les règles (ce qui peut potentiellement annuler son clic)
//
// A cette heure il n'y a qu'une règle :
//   - si aucun panneau solaire n'est sélectionné, on ne peut pas prendre d'assurance sur ce panneau.

export type PackageSelector = (themes: Array<Theme>) => Array<Theme>;

export type SwalableErrorMessage = { title: string; message: string };
/**
 * La fonction qui va boucler sur les règles spéciales.
 * @param themes prends un CLONE des themes. Ce clone sera modifié ET retourné.
 * @returns les themes passés en paramètres, potentiellement modifiés
 */
export const applyPackageSelectorSpecialRules = (themes: Array<Theme>): { messages: Array<SwalableErrorMessage>; disableValidation: boolean } => {
    const errors = Array<SwalableErrorMessage>();
    let disableValidation: boolean = false;
    for (const rule of rules) {
        const x = rule(themes);
        errors.push(...x.messages);
        if (x.disableValidation === true) disableValidation = disableValidation || x.disableValidation;
    }
    return { messages: errors, disableValidation };
};

/**
 * Cette règle déselectionne tous les packages de type 'Services/Garantie de revenu solaire' si il n'y a pas de panneau solaire.
 * @param themes prends un CLONE des themes. Ce clone sera modifié ET retourné.
 * @returns les themes passés en paramètres, potentiellement modifiés
 */
const removeMmaIfNoSolarPanel = (themes: Array<Theme>): { messages: Array<SwalableErrorMessage>; disableValidation: boolean } => {
    const errors = Array<SwalableErrorMessage>();
    const firstPanneau = getFirstPanneau(themes);

    // Si il y a un panneau, qu'on ait ou non une garantie, c'est ok.
    if (firstPanneau !== undefined) return { messages: errors, disableValidation: false };

    const firstMMa = getFirstPackMMA(themes);
    if (firstMMa) {
        errors.push({
            title: 'Garantie de revenu solaire',
            message: 'Une garantie de revenu solaire ne peut être souscrite que si vous avez sélectionné un produit photovoltaïque',
        });
        firstMMa.applicable = false;
    }
    return { messages: errors, disableValidation: false };
};

const atLeast2IsolationIfMAR = (themes: Array<Theme>): { messages: Array<SwalableErrorMessage>; disableValidation: boolean } => {
    const errors = Array<SwalableErrorMessage>();
    if (!isCurrentAuditMar()) return { messages: errors, disableValidation: false };
    let disableValidation = false;
    let firstIsolationCount = 0;
    for (const theme of themes)
        if (theme.themeType === "Economie d'énergie")
            // if (subTheme.subThemeType === '')
            for (const subTheme of theme.subThemes!)
                for (const pack of subTheme.packages) {
                    if (pack.applicable && pack.mainProduct.categorie_parent === 'Isolation') firstIsolationCount++;
                }

    if (firstIsolationCount < 2) {
        errors.push({
            title: 'Isolation',
            message: "Au moins deux isolations doivent être sélectionnées dans le cadre de la rénovation d'ampleur",
        });
        disableValidation = true;
    }
    return { messages: errors, disableValidation };
};

const rules: Array<(themes: Array<Theme>) => { messages: Array<SwalableErrorMessage>; disableValidation: boolean }> = [
    removeMmaIfNoSolarPanel,
    atLeast2IsolationIfMAR,
];
