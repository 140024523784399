/**
 * Liste de choix de la composition de fenêtre de Type 1
 */
export const buildingWindows1List: { label: string; value: string }[] = [
    {
        label: "PVC",
        value: "0",
    },
    {
        label: "Bois",
        value: "1",
    },
    {
        label: "Métal",
        value: "2",
    },
];
export const buildingWindows1glazingList: { label: string; value: string }[] = [
    {
        label: "Simple",
        value: "0",
    },
    {
        label: "Double",
        value: "1",
    },
    {
        label: "Double fenêtre",
        value: "2",
    },
    {
        label: "Triple",
        value: "3",
    },
];
export const buildingWindows1glazingSizeList: { label: string; value: string }[] = [
    {
        label: "6",
        value: "0",
    },
    {
        label: "8",
        value: "1",
    },
    {
        label: "10",
        value: "2",
    },
    {
        label: "12",
        value: "3",
    },
    {
        label: "15+",
        value: "4",
    },
];

export const buildingDoorWindows1glazingList: { label: string; value: string }[] = [
    {
        label: "Simple",
        value: "0",
    },
    {
        label: "Double",
        value: "1",
    },
    {
        label: "Triple",
        value: "2",
    },
];

/**
 * Liste de choix de la composition de la porte principale
 */
export const buildingDoor1List: { label: string; value: string }[] = [
    {
        label: "PVC",
        value: "0",
    },
    {
        label: "Bois",
        value: "1",
    },
    {
        label: "Métal",
        value: "2",
    },
    {
        label: "Autre",
        value: "3",
    },
];
export const buildingDoor1TypeList: { label: string; value: string }[] = [
    {
        label: "Opaque pleine simple",
        value: "0",
    },
    {
        label: "Opaque pleine isolée",
        value: "1",
    },
    {
        label: "-30% de vitrage simple",
        value: "2",
    },
    {
        label: "30 à 60% de vitrage simple",
        value: "3",
    },
    {
        label: "Avec double vitrage",
        value: "4",
    },
    {
        label: "Précédée d’un sas",
        value: "5",
    },
];

/**
 * Liste des orientations disponibles
 */
export const orientationList: { label: string; value: string; included: string[] }[] = [
    {
        label: "Nord",
        value: "N",
        included: ["N", "E", "S", "O"],
    },
    {
        label: "Nord-Est",
        value: "NE",
        included: ["NE", "SE", "SO", "NO"],
    },
    {
        label: "Est",
        value: "E",
        included: ["N", "E", "S", "O"],
    },
    {
        label: "Sud-Est",
        value: "SE",
        included: ["NE", "SE", "SO", "NO"],
    },
    {
        label: "Sud",
        value: "S",
        included: ["N", "E", "S", "O"],
    },
    {
        label: "Sud-Ouest",
        value: "SO",
        included: ["NE", "SE", "SO", "NO"],
    },
    {
        label: "Ouest",
        value: "O",
        included: ["N", "E", "S", "O"],
    },
    {
        label: "Nord-Ouest",
        value: "NO",
        included: ["NE", "SE", "SO", "NO"],
    },
];
