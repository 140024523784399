import React, { useEffect, useState } from 'react';
import { buildingToSerialisable, CustomPolygonNonSerialisable, exposureList, tiltListModal } from '../../HomeInformationsEnums';
import Select from 'react-select';
import * as storageService from '../../../../services/localStorageService';

interface SolarPanelModalProps {
    show: boolean;
    polygon: CustomPolygonNonSerialisable | null;
    onClose: () => void;
    values: any;
    setValues: React.SetStateAction<any>;
}

const colourStyles = {
    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
        return {
            ...styles,
            backgroundColor: isFocused ? '#76ca4f' : null,
            color: isFocused ? '#ffffff' : '#1F2F3D',
        };
    },
};

export const SolarPanelModal: React.FC<SolarPanelModalProps> = ({ show, onClose, polygon, values, setValues }) => {
    const [resetKey, setResetKey] = useState(0);
    const [inclination, setInclination] = useState<{ label: string; value: number } | null>(null);
    const [exposure, setExposure] = useState<{ label: string; value: string } | null>(null);

    // Set inclination & exposure to null
    const resetFields = () => {
        // Reset select fields
        setInclination(null);
        setExposure(null);
    };

    // Save and push data into solarEnergyPanels
    const handleSave = () => {
        if (!polygon) return;

        const newPolygon: CustomPolygonNonSerialisable = {
            ...polygon,
            inclination: inclination?.value,
            exposure: exposure?.value,
        };

        const newValues: any = {
            ...values,
            solarEnergyPanels: [...values.solarEnergyPanels, newPolygon],
        };

        setValues(newValues);

        const { solarEnergyPanelsSerialisable } = buildingToSerialisable(newValues);

        storageService.setAuditValue(2, true, 'solarEnergyPanelsSerialisable', solarEnergyPanelsSerialisable);

        // Reset select fields
        resetFields();

        onClose();
    };

    // Delete current polygon and reset fields
    const handleCancel = () => {
        if (polygon) {
            polygon.instance.setMap(null);
        }
        // Reset select fields
        resetFields();

        onClose();
    };

    // Check fields value
    const areAllFielsFilled = () => {
        const isInclinaisonComplete = inclination !== null;
        const isExposureComplete = exposure !== null;

        return isInclinaisonComplete && isExposureComplete;
    };

    useEffect(() => {
        setResetKey((prev) => prev + 1);
    }, [show]);

    return (
        <div className={`modal-panels ${show ? 'show' : 'hidden'}`} role="dialog" tabIndex={-1} aria-labelledby="Solar panel" aria-modal="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="modal-header__title">Ajouter une zone d'installation</h2>
                    </div>

                    <div className="modal-body">
                        <div className="container">
                            <div className="row mb-3">
                                <div className="col-12 col-lg-6">
                                    <label className="form-label">Superficie</label>
                                    <div className="input-group">
                                        <input type="number" className="form-control" value={polygon?.area ? Math.round(polygon?.area) : 0} readOnly />
                                        <span className="input-group-text">m2</span>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <label className="form-label">
                                        Inclinaison<sup>*</sup>
                                    </label>
                                    <Select
                                        key={resetKey}
                                        isClearable={false}
                                        isSearchable={false}
                                        isMulti={false}
                                        value={inclination !== null ? { label: inclination?.label, value: inclination?.value } : null}
                                        onChange={(newValue: any) => setInclination({ label: newValue.label, value: Number(newValue.value) })}
                                        options={tiltListModal}
                                        placeholder="Choisir une inclinaison"
                                        className={'basic-single' + (inclination ? ' filled' : '') + (!inclination ? ' required' : '')}
                                        styles={colourStyles}
                                    />
                                </div>
                                <div className="col-12 col-lg-6">
                                    <label>
                                        Orientation<sup>*</sup>
                                    </label>
                                    <Select
                                        key={resetKey + 1}
                                        isClearable={false}
                                        isSearchable={false}
                                        isMulti={false}
                                        value={exposure !== null ? { label: exposure?.label, value: exposure?.value } : null}
                                        onChange={(newValue: any) => setExposure({ label: newValue.label, value: newValue.value })}
                                        options={exposureList}
                                        placeholder="Choisir une orientation"
                                        className={'basic-single' + (exposure ? ' filled' : '') + (!exposure ? ' required' : '')}
                                        styles={colourStyles}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="btn-grp btn-grp--min justify-content-center mt-5">
                            <button type="button" className="btn btn-secondary min-width" onClick={handleCancel}>
                                Annuler
                            </button>
                            <button type="button" className="btn btn-green min-width" onClick={handleSave} disabled={!areAllFielsFilled()}>
                                Ajouter
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
