import { ModeConsommation } from '../../services/calculs/calculPhotovoltaique';
import { Labelled } from '../../services/tools/TypeHelper';

export const modeConsommationList: Array<Labelled<ModeConsommation>> = [
    {
        label: 'Autoconsommation',
        value: 'Autoconsommation',
    },
    {
        label: 'Revente en surplus',
        value: 'ReventeSurplus',
    },
    {
        label: 'Revente totale',
        value: 'ReventeTotale',
    },
];

/** Légendes des couelurs du graphique hsitogramme Avant/Après, dans le SimulatorContainer */
export const legendList: { text: string; class: string; id: number }[] = [
    {
        id: 0,
        text: 'Chauffage',
        class: 'chauffage',
    },
    {
        id: 1,
        text: 'Eau chaude sanitaire',
        class: 'eau',
    },
    {
        id: 2,
        text: 'Electricité ménagère',
        class: 'electricite',
    },
    {
        id: 3,
        text: 'Abonnement Electricité',
        class: 'abonnement-electricite',
    },
    {
        id: 4,
        text: 'Abonnement Gaz',
        class: 'abonnement-gaz',
    },
];
