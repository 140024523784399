import React, { forwardRef, useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage, FormikProps } from 'formik';
import * as yup from 'yup';

// Définir le type pour les données du formulaire
export type ScenarioProps = 'scenario1' | 'scenario2' | 'scenarioPerso' | '';

export type MailMarFormData = {
    comment: string | undefined;
};

export type MailMarFormProps = {
    initialValues: MailMarFormData;
    scenarioPersoText?: string;
    onSubmit: (values: MailMarFormData) => void;
    onClose: () => void;
};

export const MailMarFormSchema = yup.object().shape({
    comment: yup.string().required('Veuillez sélectionner un scénario.'),
});

export const DEFAULT_COMMENT_FOR_MAR_1 = 'Le client a retenu le scénario 1';
export const DEFAULT_COMMENT_FOR_MAR_2 = 'Le client a retenu le scénario 2';
export const DEFAULT_COMMENT_FOR_MAR_PERSO_LABEL = 'Le client a retenu un scénario personnalisé (merci de compléter) : ';
export const DEFAULT_COMMENT_FOR_MAR_PERSO = 'Le client a retenu un scénario personnalisé : ';

const MailMarForm = forwardRef<FormikProps<MailMarFormData>, MailMarFormProps>((props: MailMarFormProps, ref) => {
    const [selectedScenario, setSelectedScenario] = useState<ScenarioProps>('');
    const [scenarios, setScenarios] = useState<{ value: ScenarioProps; label: string; comment: string }[]>([]);

    useEffect(() => {
        setScenarios([
            {
                value: 'scenario1',
                label: DEFAULT_COMMENT_FOR_MAR_1,
                comment: DEFAULT_COMMENT_FOR_MAR_1,
            },
            {
                value: 'scenario2',
                label: DEFAULT_COMMENT_FOR_MAR_2,
                comment: DEFAULT_COMMENT_FOR_MAR_2,
            },
            {
                value: 'scenarioPerso',
                label: DEFAULT_COMMENT_FOR_MAR_PERSO_LABEL,
                comment: DEFAULT_COMMENT_FOR_MAR_PERSO + props.scenarioPersoText,
            },
        ]);
    }, [props.scenarioPersoText]);

    return (
        <div>
            <p>Sélectionnez un commentaire à destination de l'accompagnateur Rénov'</p>
            <Formik
                initialValues={{
                    comment: props.initialValues.comment,
                }}
                validationSchema={MailMarFormSchema}
                onSubmit={props.onSubmit}
                innerRef={ref}
                validateOnMount
                validateOnChange
            >
                {({ values, setValues, isValid }) => (
                    <Form className="container">
                        <div className="row">
                            {scenarios.map((scenario, index) => (
                                <div className="col-12" key={index}>
                                    <div className="form-group">
                                        <div className="custom-radio">
                                            <Field
                                                type="radio"
                                                id={scenario.value}
                                                value={scenario.value}
                                                name="scenario"
                                                checked={selectedScenario === scenario.value}
                                                onChange={() => {
                                                    setSelectedScenario(scenario.value);
                                                    setValues({
                                                        comment: scenario.comment,
                                                    });
                                                }}
                                            />
                                            <div className="fake-radio">{/* Leave empty */}</div>
                                            <label htmlFor={scenario.value}>{scenario.label}</label>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <p className="text-start mt-4">Veuillez saisir les élements que le client souhaiterait installer : </p>
                        <div className="position-relative">
                            <Field
                                as="textarea"
                                name="scenarioPersoText"
                                placeholder="Entrez votre scénario personnalisé"
                                disabled={selectedScenario !== 'scenarioPerso'}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                    setSelectedScenario('scenarioPerso');
                                    const newScenarioPersoText = e.target.value;
                                    setValues({
                                        comment: DEFAULT_COMMENT_FOR_MAR_PERSO + newScenarioPersoText,
                                    });
                                    // Mettre à jour le scénario personnalisé
                                    setScenarios((prevScenarios) =>
                                        prevScenarios.map((scenario) =>
                                            scenario.value === 'scenarioPerso'
                                                ? { ...scenario, comment: DEFAULT_COMMENT_FOR_MAR_PERSO + newScenarioPersoText }
                                                : scenario
                                        )
                                    );
                                }}
                                className="form-control"
                                value={
                                    selectedScenario === 'scenarioPerso' ? values.comment?.replace(DEFAULT_COMMENT_FOR_MAR_PERSO, '') : props.scenarioPersoText
                                }
                            />

                            <ErrorMessage name="scenarioPersoText" component="span" className="invalid-feedback " />
                        </div>
                        <div className="btn-grp justify-content-center mt-5">
                            <button type="button" className="btn btn-retour min-width" onClick={props.onClose}>
                                Annuler
                            </button>
                            <button type="submit" className="btn btn-continue min-width" disabled={!isValid}>
                                Envoyer
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
});
export default MailMarForm;
