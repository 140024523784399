import React from 'react';

import './mentions-legales.scss';

const MentionsLegales: React.FC = () => {

  return (
    <div className="container legal-page">
      <h1 className="main-title-mini">Mentions légales</h1>

      <h2>1. Informations légales</h2>

      <p>
        Le présent site est édité par : SOGYS, Société par Actions Simplifiée, au capital social de 360 000,00 € euros,
        immatriculée au Registre du Commerce et des Sociétés de Paris sous le numéro 900 198 912, dont le siège social est
        situé 76 rue de la Pompe 75016 PARIS, numéro TVA intracommunautaire FR 579 001 989 12.
      </p>

      <p>
        Le responsable de la publication du site internet est Monsieur Nathaniel SABBAN.<br />
        Hébergeur : IKOULA NET<br />
        Adresse de l'hébergeur : 175-177 Rue d'Aguesseau, 92100 Boulogne-Billancourt<br />
        Pour toute information ou conseil, vous pouvez nous joindre au <a href="tel:0826040023">08 26 04 00 23</a> ou par courriel à l’adresse suivante : <a href="mailto:contact@sogys.fr">contact@sogys.fr</a>
      </p>

      <p>
        En conséquence, SOGYS décline toute responsabilité :
      </p>

      <ul>
        <li>Pour toute interruption du site</li>
        <li>Survenance de bogues</li>
        <li>Pour toute inexactitude ou omission portant sur des informations disponibles sur le site;</li>
        <li>
          Pour tous dommages résultant d’une intrusion frauduleuse d’un tiers ayant entraîné une modification des
          informations mises à la disposition sur le site.
        </li>
      </ul>

      <h2>2. Intormatique et liberté</h2>

      <p>
        Les informations collectées sur le site <a href="http://www.sogys.app" target="_blank" rel="noreferrer">www.sogys.app</a> sont destinées à SOGYS.
        Elles font l’objet d’un traitement informatique ayant pour finalité le traitement de votre demande et notre gestion commerciale, en particulier :
      </p>

      <ul>
        <li>La réponse aux demandes d’information formulées sur le site</li>
        <li>L’envoi d’informations commerciales lorsque vous avez donné votre accord ou lorsque la législation en vigueur l’autorise</li>
      </ul>

      <p>
        Certaines des données collectées pourront être communiquées à ses partenaires à des fins de prospection. 
        Les champs identifiés par un astérisque sont obligatoires. En l’absence de réponse ou si les informations fournies sont erronées,
        SOGYS ne pourra pas traiter votre demande.
      </p>

      <p>
        Conformément aux dispositions de la Loi n° 78-17, informatique et libertés du 6 janvier 1978, l’utilisateur est informé qu’il dispose d’un droit
        d’accès, d’interrogation, et de rectification qui lui permet, le cas échéant, de faire rectifier, compléter, mettre à jour,
        verrouiller ou effacer les données personnelles le concernant qui sont inexactes, incomplètes, équivoques, périmées ou dont la collecte,
        l’utilisation, la communication ou la conservation est interdite. 
        L’utilisateur dispose également d’un droit d’opposition au traitement de ses données pour des motifs légitimes ainsi qu’un droit d’opposition
        à ce que ces données soient utilisées à des fins de prospection commerciale. 
        L’ensemble de ces droits s’exerce auprès de SOGYS par courrier postal accompagné d’une copie d’un titre d’identité comportant une
        signature à l’adresse suivante: 
      </p>

      <p>
        SOGYS<br />
        76 rue de la Pompe<br />
        75016 Paris
      </p>

      <h2>3. Liens hypertextes et cookies</h2>

      <p>
        Le site <a href="http://www.sogys.app" target="_blank" rel="noreferrer">www.sogys.app</a> contient un certain nombre de liens hypertextes
        vers d’autres sites, mis en place avec l’autorisation de SOGYS. Cependant, SOGYS n’a pas la possibilité de vérifier le contenu des sites
        ainsi visités, et n’assumera en conséquence aucune responsabilité de ce fait. 
        La navigation sur le site <a href="http://www.sogys.app" target="_blank" rel="noreferrer">www.sogys.app</a> est susceptible de provoquer
        l’installation de cookie sur l’ordinateur de l’utilisateur.
        Un cookie est un fichier de petite taille, qui ne permet pas l’identification de l’utilisateur, mais qui enregistre des informations relatives
        à la navigation d’un ordinateur sur un site. Les données ainsi obtenues visent à faciliter la navigation ultérieure sur le site, et ont
        également vocation à permettre diverses mesures de fréquentation. 
        Le refus d’installation d’un cookie peut entraîner l’impossibilité d’accéder à certains services. L’utilisateur peut toutefois configurer
        son ordinateur de la manière suivante, pour refuser l’installation des cookies  
      </p>

      <p>Pour Mozilla Firefox :</p>
      <ol>
        <li>Choisissez le menu “outil ” puis “Options”</li>
        <li>Cliquez sur l’icône “vie privée”</li>
        <li>Repérez le menu “cookie” et sélectionnez les options qui vous conviennent</li>
      </ol>

      <p>Pour Microsoft Internet Explorer :</p>
      <ol>
        <li>Choisissez le menu “Outils” (ou “Tools”), puis “Options Internet” (ou “Internet Options”)</li>
        <li>Cliquez sur l’onglet “Confidentialité” (ou “Confidentiality”)</li>
        <li>Sélectionnez le niveau souhaité à l’aide du curseur (Haute pour bloquer tous les cookies)</li>
      </ol>

      <p>Pour Safari :</p>
      <ol>
        <li>Dans la barre de menu, choisir le menu Édition</li>
        <li>Dans le menu déroulant, choisir Préférences…</li>
        <li>Choisir l’icône « Sécurité »</li>
        <li>Pour interdire les cookies, choisir l’option « Jamais ». Il n’est pas possible de bloquer les cookies au cas par cas avec ce navigateur</li>
      </ol>

      <p>Pour Chrome :</p>
      <ol>
        <li>Cliquez sur le menu Google Chrome dans la barre d’outils du navigateur</li>
        <li>Sélectionnez Paramètres</li>
        <li>Cliquez sur Afficher les paramètres avancés</li>
        <li>Dans la section “Confidentialité”, cliquez sur le bouton Paramètres de contenu</li>
        <li>Dans la section “Cookies”, vous pouvez modifier les paramètres suivants: Bloquer tous les cookies : sélectionnez l’option “Interdire à tous les sites de stocker des données”.</li>
      </ol>

      <h2>4. Protection du contenu</h2>

      <p>
        L’intégralité du contenu présent ou rendu disponible à travers le site <a href="http://www.sogys.app" target="_blank" rel="noreferrer">www.sogys.app</a>, tels que, les textes, les dessins, les graphiques,
        les images, les photos, les marques et logos, etc; est la propriété de la société SOGYS ou de ses affiliées, ou de tiers.
        Ce contenu est protégé par des droits de propriété intellectuelle. Nous attirons votre attention sur le fait que la violation
        des droits de propriété intellectuelle est constitutive du délit de contrefaçon et sanctionnée pénalement. 
        Il est formellement interdit à toute personne physique ou morale d’extraire, de copier, reproduire, de modifier,
        de retranscrire et/ou de réutiliser, tout ou partie du contenu, sans l’autorisation expresse et par écrit du titulaire des droits. 
      </p>

      <h2>Droit applicable et attribution de juridiction</h2>

      <p>
        Tout litige en relation avec l’utilisation du site <a href="http://www.sogys.app" target="_blank" rel="noreferrer">www.sogys.app</a> est soumis
        au droit français. Il est fait attribution exclusive de juridiction aux tribunaux compétents de Paris.
      </p>

      <div className="back text-center py-5"></div>
    </div>
  );
};

export default MentionsLegales;
