import React, { useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { FormValues, RoofShapeName } from '../PhotovoltaiqueEnums';
import * as storageService from '../../../../services/localStorageService';

interface RoofSchemaProps {
    shapeModule?: RoofShapeName;
    onCanvasPathOver: (pointsPositions: Point[]) => void;
    canvasRef: React.MutableRefObject<HTMLCanvasElement>;
}

export interface Point {
    x: number;
    y: number;
}

const STAGE_HEIGHT = 708;
const STAGE_WIDTH = 701.66;

/**
 * @param param0
 * @returns
 */
const RoofSchema: React.FC<RoofSchemaProps> = ({ shapeModule, onCanvasPathOver, canvasRef }) => {
    const { values } = useFormikContext<FormValues>();
    // const canvasRef = useRef<HTMLCanvasElement>(null!);
    const contextRef = useRef<CanvasRenderingContext2D>(null!);
    const [startDraw, setStartDraw] = useState<boolean>(false);
    const [pointsPosition, setPointsPosition] = useState<Point[]>([]);
    const [cursorClass, setCursorClass] = useState<'cursor-pointer' | 'cursor-disabled' | ''>(!shapeModule ? 'cursor-disabled' : '');
    const [canDraw, setCandraw] = useState<boolean>(shapeModule ? true : false);

    const drawGrid = () => {
        contextRef.current.strokeStyle = 'grey';
        contextRef.current.lineWidth = 0.5;

        for (let y = 0; y < STAGE_HEIGHT; y += 34) {
            drawLine({ x: 0, y: y }, { x: STAGE_WIDTH, y: y });
        }
        for (let x = 0; x < STAGE_WIDTH; x += 34) {
            drawLine({ x: x, y: 0 }, { x: x, y: STAGE_HEIGHT });
        }
    };

    const bolderLines = () => {
        contextRef.current.lineWidth = 1.25;
    };

    // set les refs du canvas et du context lorsque le composant se monte
    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        if (context) {
            contextRef.current = context;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // lorsque le shape module est set (la clé dans roofShape a modifier) on reinit les etats
    useEffect(() => {
        if (shapeModule) {
            setCursorClass('');
            setCandraw(true);
            setPointsPosition([]);
        }
    }, [shapeModule]);

    // lorsque le roofShape est modifié on clear le canvas et on redessine tous les éléments du tableau
    useEffect(() => {
        clear();
        drawAllModules();
        drawGrid();
        bolderLines();
        storageService.setPrevisitValue('roofShapePicture', canvasRef.current.toDataURL());
        storageService.setPrevisitValue('roofShape', values.roofShape);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.roofShape, shapeModule]);

    // dessine tous les elements du tableau puits set la bonne couleur pour le dessin en cours
    const drawAllModules = () => {
        Object.values(values.roofShape).forEach((module, roofshapeIndex) => {
            contextRef.current.fillStyle = module.color;
            contextRef.current.strokeStyle = module.color;
            module.elements.forEach((moduleElementPoints, moduleElementIndex) => {
                moduleElementPoints.forEach((point, index) => {
                    if (moduleElementPoints[index + 1]) {
                        drawLine(point, moduleElementPoints[index + 1]);
                    } else {
                        drawLine(point, moduleElementPoints[0]);
                    }
                    drawPoint(point);
                });
            });
        });
        if (shapeModule) {
            contextRef.current.strokeStyle = values.roofShape[shapeModule].color;
            contextRef.current.fillStyle = values.roofShape[shapeModule].color;
        }
    };

    // retourne la position du curseur dans le canvas
    const getCursorPosition = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
        const { clientX, clientY } = e;
        const rect = e.currentTarget.getBoundingClientRect();
        const x = clientX - rect.left;
        const y = clientY - rect.top;
        return { x, y };
    };

    /**
     *
     * @param e onClick event
     * @returns void
     */
    const onClickCanvas = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
        if (canDraw) {
            const { x, y } = getCursorPosition(e);
            // sert à créer la base64
            if (pointsPosition.length >= 3 && cursorIsOnFirstPointBox({ x, y })) {
                setStartDraw(false);
                setPointsPosition([...pointsPosition, pointsPosition[0]]);
                setCursorClass('cursor-disabled');
                setCandraw(false);
                onCanvasPathOver(pointsPosition);
                return;
            }
            drawPoint({ x, y });

            setPointsPosition([...pointsPosition, { x, y }]);
            if (!startDraw) {
                setStartDraw(true);
            }
        }
    };

    const drawLine = (src: Point, dest: Point) => {
        contextRef.current.beginPath();
        contextRef.current.moveTo(src.x, src.y);
        contextRef.current.lineTo(dest.x, dest.y);
        contextRef.current.stroke();
    };

    const drawPoint = (position: Point) => {
        contextRef.current.fillRect(position.x - 5, position.y - 5, 10, 10);
    };

    const cursorIsOnFirstPointBox = ({ x: pointerX, y: pointerY }: Point) => {
        const firstPoint = pointsPosition[0];
        const firstPointBox = {
            x: firstPoint.x - 5,
            y: firstPoint.y - 5,
        };
        return pointerX > firstPointBox.x && pointerX < firstPointBox.x + 10 && pointerY > firstPointBox.y && pointerY < firstPointBox.y + 10;
    };

    /**
     * cette fonction se déclenche lorsque la souris bouge dans le canvas
     *
     * @param e
     */
    const handleMouseMoveOnCanvas = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
        if (canDraw && startDraw && pointsPosition.length > 0) {
            const cursorPosition = getCursorPosition(e);
            clear();
            drawGrid();
            bolderLines();
            drawAllModules();
            pointsPosition.forEach((points, index) => {
                drawPoint(points);
                if (pointsPosition[index + 1]) {
                    drawLine(points, pointsPosition[index + 1]);
                } else {
                    drawLine(points, cursorPosition);
                }
            });
            if (pointsPosition.length >= 3 && cursorIsOnFirstPointBox(cursorPosition)) {
                setCursorClass('cursor-pointer');
            } else {
                setCursorClass('');
            }
        }
    };

    // clear le canvas
    const clear = () => {
        contextRef.current.clearRect(0, 0, STAGE_WIDTH, STAGE_HEIGHT);
    };

    return (
        <div className={`roof-schema ${cursorClass}`}>
            <canvas
                ref={canvasRef}
                height={STAGE_HEIGHT}
                width={STAGE_WIDTH}
                style={{ width: '100%', height: '100%' }}
                onClick={onClickCanvas}
                onMouseMove={handleMouseMoveOnCanvas}
            />
        </div>
    );
};

export default RoofSchema;
