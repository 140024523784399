// Tableau de conversion - Type d'émetteur
export const heaterFeatureCorrespondance: any = {
    '0': '1', //Convecteur
    '1': '2', //Panneau rayonnant
    '2': '4', //Ventilo convecteur
    '3': '10', //Air soufflé
    '4': '6', //Radiateur avec vanne thermostatique
    '5': '7', //Radiateur sans vanne thermostatique
    '6': '5', //Plancher chauffant
    '7': '8', //Poêle
    '8': '9', //Appareil à bûches
};

// Tableau de conversion type d'énergie - Type de chauffage principal
export const heaterTypeCorrespondance: any = {
    '0': '1', //Electrique
    '1': '5', //Bois
    '2': '1', //Electrique et réseau
    '3': '6', //Elec. thermodynamique (PAC)
    '4': '3', //Gaz
    '6': '2', //Fioul
};
