import { ClientIdentity } from '../localStorageService';
import * as enedisService from '../enedisService';
import { addDays, addHours, addMinutes, addMonths, addYears, format, getDayOfYear, getDaysInMonth, getDaysInYear, isBefore, parse, subYears } from 'date-fns';
import { fr } from 'date-fns/locale';
import { EnedisGraphEntry } from '../../pages/energy-cost-informations/EnedisGraph';
import { GraphData, ScaledGraphData } from '../tools/graphHelper';
import { getCodeInsee } from '../tools/gouv';

export type EnedisResult = {};
export const HARD_CODED_POINT_ID__Romain = '22413748090232'; // 29 RUE NAST CHAMPS SUR MARNE
export const HARD_CODED_POINT_ID__Ylan_1 = '21400723535824'; // 5 RUE GAMBETTA BOULOGNE BILLANCOURT
export const HARD_CODED_POINT_ID__Ylan_2 = '21400434100250'; // 5 RUE GAMBETTA BOULOGNE BILLANCOURT
export const HARD_CODED_POINT_ID__Wassa = '21469609233183'; // 5 RUE DE L EGLISE BOULOGNE BILLANCOURT
export const HARD_CODED_POINT_ID__Fred = '21433284986892'; // 9B RUE DES TILLEULS BOULOGNE BILLANCOURT
export const HARD_CODED_POINT_ID__Nico = '21597105631678'; // 60 rue des robinettes EAUBONNE

export const searchPdl = async (clientIdentity: ClientIdentity): Promise<string[]> => {
    const codeInseeCommune = await getCodeInsee(clientIdentity.userZipCode, clientIdentity.userLocality);

    const points: Array<enedisService.Point> = await enedisService.recherchePoint({
        adresseInstallation: {
            codeInseeCommune,
            codePostal: clientIdentity.userZipCode,
            numeroEtNomVoie: clientIdentity.userAddress,
        },
        domaineTensionAlimentationCode: 'BTINF',
        categorieClientFinalCode: 'RES',
    });

    if (!points || points.length <= 0) return [];

    return points.map((value) => value.id);
};

export const getPdlDetail = async (pointId: string): Promise<enedisService.PointTechniqueContractuel> => {
    // on récupère les données techniques contractuelle, cela permet de connaitre plein de trucs inutile,
    // mais surtout la date à partir de laquelle on a des données.
    const pdlDetail = await enedisService.consulterDonneesTechniquesContractuelles({
        pointId,
        //autorisationClient: true, // géré par l'api.
    });

    return pdlDetail;
};

/**
 * Obtient l'intégralité des mesures détaillées sur tout l'historique disponible.
 * Ne s'arrête pas en cas d'erreur.
 * Fait des appels séquentiels à chacune des données.
 * Attention : longue durée : autour de 3 minutes
 * @param pointId
 * @param pdlDetail
 * @returns La liste concatainée des mesure.
 *
 */
export const getMesureDetaillee24M = async (
    pointId: string,
    pdlDetail: enedisService.PointTechniqueContractuel | undefined = undefined
): Promise<enedisService.ConsultationMesuresDetailleesV3Output> => {
    // on récupère les données techniques contractuelle, cela permet de connaitre plein de trucs inutile,
    // mais surtout la date à partir de laquelle on a des données.
    if (!pdlDetail) pdlDetail = await getPdlDetail(pointId);

    // la date a partir de laquelle on peut obtenir des données :
    const startHistoryDate = parse(pdlDetail.donneesGenerales.dateDerniereModificationFormuleTarifaireAcheminement!.slice(0, 10), 'yyyy-MM-dd', new Date());
    // LIMITATIONS

    // limite de plage
    // la fonction de mesure détaillée ne permet pas d'interroger sur plus d'une semaine.
    // pour faire un mois (par exemple), il faut donc 4 appels successifs

    // limite d'historique
    // la fonction de mesure détaillées ne permet pas d'intérroger l'historique plus vieux que deux ans.

    // limite d'appels
    // Maximum 40 appels par seconde  (au total pour tous les acteurs à niveau mondial   OU   pour moi ?)
    // Maximum 1000 appels par jour.

    console.time('start getMesureDetaillee24M');
    const today = new Date();
    // La date la plus lointaine possible : -2 ans.
    let debut = subYears(today, 2);
    // si -2 ans est avant startHistoryDate, on prend startHistoryDate comme début. (le max a partir duquel on a des données.)
    // Vérifier ceci. Il semble que ca ne soit pas toujours correct.
    if (isBefore(debut, startHistoryDate)) debut = startHistoryDate;
    //let debut = subMonths(today, 4); // for testing, shorten the period to 4 month, or less.
    let fin = addDays(debut, 7);

    let resultGlobal: enedisService.ConsultationMesuresDetailleesV3Output | undefined = undefined;

    while (isBefore(debut, today)) {
        if (isBefore(today, fin)) fin = today;

        let dateDebut = format(debut, 'yyyy-MM-dd', {});
        let dateFin = format(fin, 'yyyy-MM-dd', {});

        const input: enedisService.ConsulterMesuresDetailleesV3Input = {
            pointId,
            mesuresTypeCode: 'COURBE', // pour les conso quotidienne détaillées. (je ne sais rien faire marcher d'autre.)
            grandeurPhysique: 'PA',
            sens: 'SOUTIRAGE',
            dateDebut,
            dateFin,
            /** Ne pas fournir sauf consultation des puissances max. */
            //mesuresPas?: MesuresPas;
            /** Pour le C1-C4 et le P1-P3, indiquer ‘true’ pour des données corrigées, ‘false’ pour des données brutes. pour C5 et PA : false. */
            mesuresCorrigees: false,
            /** Seule la consultation de données de PMAX mensuelle est possible avec l’option EST_TITULAIRE. */
            cadreAcces: 'ACCORD_CLIENT',
        };

        let resultSemaine;
        try {
            resultSemaine = await enedisService.consulterMesuresDetailleesV3(input);
        } catch (ex) {
            console.log('de ' + dateDebut + ' a ' + dateFin + ' ... catch');
            console.log('semaine  = ' + JSON.stringify(resultSemaine, null, 2));
        }
        if (resultSemaine) {
            if (!resultGlobal) {
                resultGlobal = resultSemaine;
            } else {
                const pointsSemaine = resultSemaine.grandeur?.points;
                if (pointsSemaine) {
                    for (let i = 0; i < pointsSemaine.length; ++i) {
                        const element = pointsSemaine[i];
                        if (resultGlobal) resultGlobal.grandeur?.points.push(element);
                    }
                }
            }
        }
        debut = addDays(debut, 7);
        fin = addDays(fin, 7);
    }
    console.timeLog('start getMesureDetaillee24M');
    if (!resultGlobal) {
        console.log('Unkown Error, global result is undefined');
        throw Error();
    }
    return resultGlobal;
};

/**
 * Obtient l'intégralité des mesures détaillées sur tout l'historique disponible.
 * Ne s'arrête pas en cas d'erreur.
 * Fait des appels parrallelisée par batch de lots d'appels.
 * @param pointId
 * @param pdlDetail
 * @returns La liste concatainée des mesure.
 */
export const getMesureDetaillee24M_fast = async (
    pointId: string,
    pdlDetail: enedisService.PointTechniqueContractuel | undefined = undefined
): Promise<enedisService.ConsultationMesuresDetailleesV3Output | undefined> => {
    // attetion la fonction est assez longues et complexe.

    // on récupère les données techniques contractuelle, cela permet de connaitre plein de trucs inutile,
    // mais surtout la date à partir de laquelle on a des données.
    if (!pdlDetail) pdlDetail = await getPdlDetail(pointId);

    //const startHistoryDate = parse(pdlDetail.donneesGenerales.dateDerniereModificationFormuleTarifaireAcheminement!.slice(0, 10), 'yyyy-MM-dd', new Date());
    const startHistoryDate = addYears(new Date(), -2);
    console.log('startHistoryDate =' + startHistoryDate);
    // LIMITATIONS

    // limite de plage
    // la fonction de mesure détaillée ne permet pas d'interroger sur plus d'une semaine.
    // pour faire un mois (par exemple), il faut donc 4 appels successifs

    // limite d'historique
    // la fonction de mesure détaillées ne permet pas d'intérroger l'historique plus vieux que deux ans.

    // limite d'appels
    // Maximum 40 appels par seconde  (au total pour tous les acteurs à niveau mondial   OU   pour moi ?)
    // Maximum 1000 appels par jour.

    console.time("Durée d'obtention des données");

    // construit la période de recherche. debut et fin seront recalculé à chaque passage de boucle.
    const today = new Date();
    // La date la plus lointaine possible : -2 ans.
    let debut = subYears(today, 2);
    // si -2 ans est avant startHistoryDate, on prend startHistoryDate comme début. (le max a partir duquel on a des données.)
    // Vérifier ceci. Il semble que ca ne soit pas toujours correct.
    if (isBefore(debut, startHistoryDate)) debut = startHistoryDate;
    //debut = subMonths(today, 4); // for testing, shorten the period to 4 month, or less.
    let fin = addDays(debut, 7);

    // Définit la taille du paquet d'appels (par exemple, 20)
    // on va envoyer les requêtes par lot de 20.
    const batchSize = 20;
    let batchPromises: Promise<enedisService.ConsultationMesuresDetailleesV3Output>[] = [];
    const resultatsParSemaines = Array<enedisService.ConsultationMesuresDetailleesV3Output>();

    // pour gérer le cas ou on aurait vraiment aucune données en sortie.
    // Cas typique ou l'utilisateur n'a donné aucun droit de partage.
    // on se prendrai que des erreurs 400.
    // Mais on ne s'arrête aps aux erreurs.
    let atLeastOneData: boolean = false;

    // du passé jusqu'à aujourd'hui ...
    while (isBefore(debut, today)) {
        // si la fin est dans le futur, on s'arrête à aujourdh'ui.
        if (isBefore(today, fin)) fin = today;

        let dateDebut = format(debut, 'yyyy-MM-dd', {});
        let dateFin = format(fin, 'yyyy-MM-dd', {});

        const input: enedisService.ConsulterMesuresDetailleesV3Input = {
            pointId,
            mesuresTypeCode: 'COURBE', // pour les conso quotidienne détaillées. (je ne sais rien faire marcher d'autre.)
            grandeurPhysique: 'PA',
            sens: 'SOUTIRAGE',
            dateDebut,
            dateFin,
            /** Ne pas fournir sauf consultation des puissances max. */
            //mesuresPas?: MesuresPas;
            /** Pour le C1-C4 et le P1-P3, indiquer ‘true’ pour des données corrigées, ‘false’ pour des données brutes. pour C5 et PA : false. */
            mesuresCorrigees: false,
            /** Seule la consultation de données de PMAX mensuelle est possible avec l’option EST_TITULAIRE. */
            cadreAcces: 'ACCORD_CLIENT',
        };

        // on encapsule la requete dans une fonction avec le try catch autour
        // eslint-disable-next-line no-loop-func
        let promise = (async (): Promise<enedisService.ConsultationMesuresDetailleesV3Output> => {
            try {
                const result = await enedisService.consulterMesuresDetailleesV3(input);
                // A partir de la on a au moins une données.
                atLeastOneData = true;
                if (!result) return buildEmptyConsulterMesuresDetailleesV3(input);
                return completeEmptyConsulterMesuresDetailleesV3(input, result);
            } catch (error) {
                //console.log("Erreur dans une requête individuelle pour l'input : " + JSON.stringify(input, null, 2));
                // in case of fail, return empty data.
                return buildEmptyConsulterMesuresDetailleesV3(input);
            }
        })();
        // on ajoute la promise dans la liste a traiter d'un coup.
        batchPromises.push(promise);

        // si on a dépassé le seuil, on lance tout.
        if (batchPromises.length >= batchSize) {
            // on lance tout !
            let results = await Promise.all(batchPromises);

            // et voila !
            // on enlève les résultats non utilisable (passé dans le catch de la promise).
            for (const result of results) resultatsParSemaines.push(result);

            // console.log('batch finished, results = ' + resultatsParSemaines.length);
            batchPromises = [];
            // Attendre 2 secondes entre les paquets d'appels
            await new Promise((resolve) => setTimeout(resolve, 1500));
        }

        // on avance d'une semaine pour la semaine suivante.
        debut = addDays(debut, 7);
        fin = addDays(fin, 7);
    } // end while

    // Attendre la fin des dernières requêtes
    // celle qui sont à la fin, et il y en a moins que 20.
    if (batchPromises.length > 0) {
        let results = await Promise.all(batchPromises);

        for (const result of results) if (result) resultatsParSemaines.push(result);
    }

    // Si on a vraiment aucune données, on sort ici.
    if (!atLeastOneData) {
        console.timeLog("Durée d'obtention des données");
        return undefined;
    }

    // Fusionner les résultats
    let resultGlobal = mergeMesureDetaillees(resultatsParSemaines);

    console.timeLog("Durée d'obtention des données");

    // Pourrait être undefined...
    return resultGlobal;
};

const mergeMesureDetaillees = (
    resultatsParSemaines: Array<enedisService.ConsultationMesuresDetailleesV3Output | undefined>
): enedisService.ConsultationMesuresDetailleesV3Output | undefined => {
    let resultGlobal: enedisService.ConsultationMesuresDetailleesV3Output | undefined = undefined;

    for (const resultSemaine of resultatsParSemaines)
        if (resultSemaine) {
            if (!resultGlobal) {
                resultGlobal = resultSemaine;
            } else {
                const pointsSemaine = resultSemaine.grandeur?.points;
                if (pointsSemaine) {
                    for (let i = 0; i < pointsSemaine.length; ++i) {
                        const element = pointsSemaine[i];
                        if (resultGlobal) resultGlobal.grandeur?.points.push(element);
                    }
                }
            }
        }
    return resultGlobal;
};
/**
 * Concernant les calculs de mise en correspondances des données enedis et pvgis,
 * on ne peut pas se permettre d'avoir des données endis vide.
 * Alors on remplit de 0 tout ce qu'on a pas réussit à obtenir.
 * @param input
 * @returns
 */
export const buildEmptyConsulterMesuresDetailleesV3 = (
    input: enedisService.ConsulterMesuresDetailleesV3Input
): enedisService.ConsultationMesuresDetailleesV3Output => {
    let start = parse(input.dateDebut, 'yyyy-MM-dd', new Date(), { locale: fr });
    const fin = parse(input.dateFin, 'yyyy-MM-dd', new Date(), { locale: fr });
    const points: enedisService.MesureDetailleeExt[] = [];
    while (isBefore(start, fin)) {
        start = addMinutes(start, 30);
        points.push({ v: '0', d: format(start, 'yyyy-MM-dd HH:mm:ss'), p: 'PT30M', n: 'B' });
    }
    //console.log('faked : de ' + JSON.stringify(points[0]));
    //console.log('      : à  ' + JSON.stringify(points[points.length - 1]));
    //const result: enedisService.ConsultationMesuresDetailleesV3Output = {
    return {
        pointId: input.pointId,
        mesuresCorrigees: 'BRUT',
        periode: { dateDebut: input.dateDebut, dateFin: input.dateFin },
        modeCalcul: 'MESURE',
        grandeur: {
            grandeurMetier: 'CONS',
            grandeurPhysique: input.grandeurPhysique as enedisService.GrandeurV3['grandeurPhysique'],
            unite: 'W',
            points,
        },
    };
};
/**
 * Concernant les calculs de mise en correspondances des données enedis et pvgis,
 * on ne peut pas se permettre d'avoir des données endis vide.
 * Alors on complète de 0 tout ce qu'on a pas réussit à obtenir.
 * @param input
 * @returns
 */
export const completeEmptyConsulterMesuresDetailleesV3 = (
    input: enedisService.ConsulterMesuresDetailleesV3Input,
    candidate: enedisService.ConsultationMesuresDetailleesV3Output
): enedisService.ConsultationMesuresDetailleesV3Output => {
    if (!candidate.grandeur) return buildEmptyConsulterMesuresDetailleesV3(input);

    const candidatePoints = candidate.grandeur.points;

    let addedCount = 0;
    // Normalement chaque jour doit avoir généralement 48 valeurs, une par demi-heure.
    // 5des fois, c'est par pas de 10M , ou 60M
    // Il faudrait tout completer.
    // Je sais qu'à la fin je vais faire la somme par jour.
    // Il me suffit d'ajouter les jours qui ne sont pas présents.

    const vraiDebut = parse(candidatePoints[0].d, 'yyyy-MM-dd HH:mm:ss', new Date(), { locale: fr });
    const vraiFin = parse(candidatePoints[candidatePoints.length - 1].d, 'yyyy-MM-dd HH:mm:ss', new Date(), { locale: fr });

    let start = addMinutes(parse(input.dateDebut, 'yyyy-MM-dd', new Date(), { locale: fr }), 30);
    const fin = parse(input.dateFin, 'yyyy-MM-dd', new Date(), { locale: fr });

    while (isBefore(start, vraiDebut)) {
        candidate.grandeur.points.splice(addedCount, 0, { v: '0', d: format(start, 'yyyy-MM-dd HH:mm:ss'), p: 'PT30M', n: 'B' });
        start = addMinutes(start, 30);
        addedCount++;
    }
    start = vraiFin;
    while (isBefore(start, fin)) {
        candidate.grandeur.points.push({ v: '0', d: format(start, 'yyyy-MM-dd HH:mm:ss'), p: 'PT30M', n: 'B' });
        start = addMinutes(start, 30);
        //addedCount++;
    }

    return candidate;
};
export type GroupedMesuresDetaillees = ScaledGraphData<EnedisGraphEntry>;

/**
 * regroupe des mesure par jour et par mois.
 * Prends une liste de point issue d'une mesure détaillée, et regroupe ensemble les valeurs qui ont été mesurée le même jour.
 * La nouvelle valeur est la somme des valeurs du jour.
 */
export const groupMesureDetaillee = (detail: enedisService.ConsultationMesuresDetailleesV3Output): GroupedMesuresDetaillees => {
    const points: enedisService.MesureDetailleeExt[] | undefined = detail.grandeur?.points;
    if (!points || points.length <= 0) return { byDay: [], byMonth: [], byYear: [] };

    // on transforme la liste en object indexé sur la date (ca va regrouper tous les jours)
    // la forme sera
    // const day = {
    //     "2023-09-19" : { value: 123, label : "2023-09-19"},
    //     "2023-09-18" : { value: 456, label : "2023-09-18"}
    // }
    // vlaue contiendra la somme de cette journée.

    // pour faciliter la syntaxe, par la suite, on va transformer les points dont la valeur est une string, en number.
    // et je n'ai besoin que de la date.
    // WARNING! normalement tout est en W.
    // on converti tout en KW

    const pointsSimplified = points.map((p) => {
        const puissance = p.v ? +p.v : 0;
        let facteurEnergie = 1;
        switch (p.p) {
            case 'PT5M':
                facteurEnergie = 5 / 60;
                break;
            case 'PT10M':
                facteurEnergie = 10 / 60;
                break;
            case 'PT15M':
                facteurEnergie = 15 / 60;
                break;
            case 'PT30M':
                facteurEnergie = 30 / 60;
                break;
            case 'PT60M':
                facteurEnergie = 60 / 60;
                break; // default too.
            default:
                facteurEnergie = 1;
        }
        return {
            date: p.d,
            puissanceW: puissance, // on élimine toutes les chaines, et les undefined sont mis à 0.
            energieKWh: (puissance * facteurEnergie) / 1000, // energie = puissance * temps en heure
        };
    });

    const indexedHoursEntries: { [k in string]: EnedisGraphEntry } = {};
    pointsSimplified.forEach((element) => {
        const HH = element.date.slice(0, 13); // d est du format yyy-MM-dd HH:mm:ss, la date est composée des 10 premiers caractères
        let date = parse(HH, 'yyyy-MM-dd HH', new Date(), { locale: fr });

        let label = format(date, 'HH-', { locale: fr });
        date = addHours(date, 1);
        label += format(date, 'HH', { locale: fr }) + 'h';
        // si on a pas d'entrée pour la date, on la créée
        if (indexedHoursEntries[HH] === undefined)
            indexedHoursEntries[HH] = {
                puissanceW: element.puissanceW,
                energieKWh: element.energieKWh,
                label,
            };
        else {
            // si on avait déjà une entrée, on incrémente.
            if (indexedHoursEntries[HH].puissanceW === undefined) {
                indexedHoursEntries[HH].puissanceW = element.puissanceW;
                indexedHoursEntries[HH].energieKWh = element.energieKWh;
            } else {
                indexedHoursEntries[HH].puissanceW += element.puissanceW;
                indexedHoursEntries[HH].energieKWh += element.energieKWh;
            }
        }
    });

    const groupedByDay: { [k in string]: GraphData<EnedisGraphEntry> } = {};
    const hourKeys = Object.keys(indexedHoursEntries);
    hourKeys.forEach((hourKey) => {
        const day = hourKey.slice(0, 10); // day est du format yyyy-MM-dd HH le jour est composée des 10 premiers caractères
        if (groupedByDay[day] === undefined)
            groupedByDay[day] = {
                key: day,
                label: format(parse(day, 'yyyy-MM-dd', new Date()), 'dd MMMM yyyy', { locale: fr }),
                data: [],
                total: 0,
            };
        groupedByDay[day].data.push(indexedHoursEntries[hourKey]);
    });

    const indexedDayEntries: { [k in string]: EnedisGraphEntry } = {};
    pointsSimplified.forEach((element) => {
        const day = element.date.slice(0, 10); // d est du format yyy-MM-dd HH:mm:ss, la date est composée des 10 premiers caractères
        // si on a pas d'entrée pour la date, on la créée
        if (indexedDayEntries[day] === undefined)
            indexedDayEntries[day] = {
                puissanceW: element.puissanceW,
                energieKWh: element.energieKWh,
                label: day.slice(8, 10),
            };
        else {
            // si on avait déjà une entrée, on incrémente.
            if (indexedDayEntries[day].puissanceW === undefined) {
                indexedDayEntries[day].puissanceW = element.puissanceW;
                indexedDayEntries[day].energieKWh = element.energieKWh;
            } else {
                indexedDayEntries[day].puissanceW += element.puissanceW;
                indexedDayEntries[day].energieKWh += element.energieKWh;
            }
        }
    });

    // On fait pareil avec les mois.
    const groupedByMonth: { [k in string]: GraphData<EnedisGraphEntry> } = {};
    const dayKeys = Object.keys(indexedDayEntries);
    dayKeys.forEach((dayKey) => {
        const month = dayKey.slice(0, 7); // day est du format yyyy-MM-dd le mois est composée des 7 premiers caractères
        if (groupedByMonth[month] === undefined)
            groupedByMonth[month] = {
                key: month,
                label: format(parse(month, 'yyyy-MM', new Date()), 'MMMM yyyy', { locale: fr }),
                data: [],
                total: 0,
            };
        groupedByMonth[month].data.push(indexedDayEntries[dayKey]);
    });

    // on fait pareil sur les années.
    const groupedByYear: { [k in string]: GraphData<EnedisGraphEntry> } = {};

    const months = Object.keys(groupedByMonth);
    months.forEach((month, index) => {
        const year = month.slice(0, 4); // month est du format yyy-MM l'année est composée des 4 premiers caractères
        let puissanceSumOfMonth = 0;
        let energieSumOfMonth = 0;
        groupedByMonth[month].data.forEach((element) => {
            puissanceSumOfMonth += element.puissanceW;
            energieSumOfMonth += element.energieKWh;
        });
        if (groupedByYear[year] === undefined)
            groupedByYear[year] = {
                key: year,
                label: year,
                data: [],
                total: 0,
            };
        if (groupedByYear[year].data === undefined) groupedByYear[year].data = [];
        groupedByYear[year].data.push({
            puissanceW: puissanceSumOfMonth,
            energieKWh: energieSumOfMonth,
            label: format(parse(month, 'yyyy-MM', new Date()), 'MMMM', { locale: fr }),
        });
    });

    return { byYear: Object.values(groupedByYear), byMonth: Object.values(groupedByMonth), byDay: Object.values(groupedByDay) };
};

export const enrichDay = (byDay: GraphData<EnedisGraphEntry>[]): void => {
    byDay.forEach((day: GraphData<EnedisGraphEntry>) => {
        let date = parse(day.label + ' 00', 'dd MMMM yyyy HH', new Date(), { locale: fr });
        // pour chaque jour manquant on ajoute un 0.
        // Normalement, on prends toutes les données disponible par l'api.
        // donc il ne peut manquer que des jours sur le premier et le dernier mois.
        // Comme les jours sont indexés, on insère à l'index.
        const hoursInDay = 24;
        if (day.data.length !== hoursInDay)
            for (let i = 0; i < hoursInDay; i++) {
                let label = format(date, 'HH-', { locale: fr });
                date = addHours(date, 1);
                label += format(date, 'HH', { locale: fr }) + 'h';
                if (!day.data.find((entry: EnedisGraphEntry) => entry.label! === label)) day.data.splice(i, 0, { label, puissanceW: 0, energieKWh: 0 });
            }

        day.total = 0;
        day.data.forEach((entry: EnedisGraphEntry) => {
            day.total += entry.energieKWh;
        });
    });

    // on a complèté les jours qui n'avaient pas toutes les heures.
    // maintenant on insère au debut les jour pour que ca démarre au premier janvier.
    // Now,
    // We need entire years ! (not only entire month)
    let firstDay = parse(byDay[0].label, 'dd MMMM yyyy', new Date(), { locale: fr });

    if (getDayOfYear(firstDay) !== 1) {
        do {
            firstDay = addDays(firstDay, -1);
            const emptyData: Array<EnedisGraphEntry> = [];
            for (let i = 0; i < 24; i++) {
                let label = format(firstDay, 'HH-', { locale: fr });
                label += format(addHours(firstDay, 1), 'HH', { locale: fr }) + 'h';
                emptyData.push({ label, puissanceW: 0, energieKWh: 0 });
            }

            byDay.unshift({
                key: format(firstDay, 'yyyyMMdd', { locale: fr }),
                label: format(firstDay, 'dd MMMM yyyy', { locale: fr }),
                data: emptyData,
                total: 0,
            });
            if (getDayOfYear(firstDay) === 1) break;
        } while (getDayOfYear(firstDay) !== 1);
    }

    let lastday = parse(byDay[byDay.length - 1].label, 'dd MMMM yyyy', new Date(), { locale: fr });
    const max = getDaysInYear(lastday);

    if (getDayOfYear(lastday) === max) return;

    do {
        lastday = addDays(lastday, 1);
        const emptyData: Array<EnedisGraphEntry> = [];
        for (let i = 0; i < 24; i++) {
            let label = format(lastday, 'HH-', { locale: fr });
            label += format(addHours(lastday, 1), 'HH', { locale: fr }) + 'h';
            emptyData.push({ label, puissanceW: 0, energieKWh: 0 });
        }

        byDay.push({
            key: format(lastday, 'yyyyMMdd', { locale: fr }),
            label: format(lastday, 'dd MMMM yyyy', { locale: fr }),
            data: emptyData,
            total: 0,
        });
    } while (getDayOfYear(lastday) !== max);
};

export const enrichMonth = (byMonth: GraphData<EnedisGraphEntry>[]): void => {
    byMonth.forEach((month: GraphData<EnedisGraphEntry>) => {
        const date = parse(month.label, 'MMMM yyyy', new Date(), { locale: fr });
        // pour chaque jour manquant on ajoute un 0.
        // Normalement, on prends toutes les données disponible par l'api.
        // donc il ne peut manquer que des jours sur le premier et le dernier mois.
        // Comme les jours sont indexés, on insère à l'index.
        const dayInMonth = getDaysInMonth(date);
        if (month.data.length !== dayInMonth)
            for (let i = 0; i < dayInMonth; i++)
                if (!month.data.find((entry: EnedisGraphEntry) => +entry.label! === i + 1))
                    month.data.splice(i, 0, { label: '' + (i + 1), puissanceW: 0, energieKWh: 0 });

        month.total = 0;
        month.data.forEach((entry: EnedisGraphEntry) => {
            month.total += entry.energieKWh;
        });
    });
    // Now,
    // We need entire years ! (not only entire month)
    let firstMonth = parse(byMonth[0].label, 'MMMM yyyy', new Date(), { locale: fr });
    while (firstMonth.getMonth() !== 0) {
        firstMonth = addMonths(firstMonth, -1);
        const emptyData: Array<EnedisGraphEntry> = [];
        const dayInMonth = getDaysInMonth(firstMonth);
        for (let i = 0; i < dayInMonth; i++) emptyData.push({ label: '' + (i + 1), puissanceW: 0, energieKWh: 0 });

        byMonth.unshift({
            key: format(firstMonth, 'yyyyMM', { locale: fr }),
            label: format(firstMonth, 'MMMM yyyy', { locale: fr }),
            data: emptyData,
            total: 0,
        });
    }

    let lastMonth = parse(byMonth[byMonth.length - 1].label, 'MMMM yyyy', new Date(), { locale: fr });
    if (lastMonth.getMonth() === 11) return; // decembre

    do {
        lastMonth = addMonths(lastMonth, 1);
        const emptyData: Array<EnedisGraphEntry> = [];
        const dayInMonth = getDaysInMonth(lastMonth);
        for (let i = 0; i < dayInMonth; i++) emptyData.push({ label: '' + (i + 1), puissanceW: 0, energieKWh: 0 });

        byMonth.push({
            key: format(lastMonth, 'yyyyMM', { locale: fr }),
            label: format(lastMonth, 'MMMM yyyy', { locale: fr }),
            data: emptyData,
            total: 0,
        });
    } while (lastMonth.getMonth() !== 11);
};

export const enrichYear = (byYear: GraphData<EnedisGraphEntry>[]): void => {
    byYear.forEach((year: GraphData<EnedisGraphEntry>) => {
        // pour chaque mois manquant on ajoute un 0.
        // Normalement, on prends toutes les données disponible par l'api.
        // donc il ne peut manquer que des mois sur la premiere et le derniere année.
        // comme les mois sont indexés avec i, on les insère et ca devrait toujours fonctionner.
        const monthInYear = 12;
        if (year.data.length !== monthInYear)
            for (let i = 0; i < monthInYear; i++) {
                const label = format(new Date(+year.label, i, 1), 'MMMM', { locale: fr });
                if (!year.data.find((entry: EnedisGraphEntry) => entry.label === label)) year.data.splice(i, 0, { label, puissanceW: 0, energieKWh: 0 });
            }

        year.total = 0;
        year.data.forEach((entry: EnedisGraphEntry) => {
            year.total += entry.energieKWh;
        });
    });
};

export const lastEnergy = (byPeriod: GraphData<EnedisGraphEntry>[], last: number): number => {
    const all: Array<EnedisGraphEntry> = [];
    for (const period of byPeriod) all.push(...period.data);

    let total = 0;
    let periodCount = 0;
    for (let i = all.length - 1; i >= 0; i--) {
        if (all[i].energieKWh !== 0) {
            total += all[i].energieKWh;
            periodCount++;
            if (periodCount === last) break;
        } else {
        }
    }
    return total;
};
