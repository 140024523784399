import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    electricityMeterAmps: Yup.string().required("L'Ampérage du compteur électrique est obligatoire"),
    heatPump: Yup.string().required("Temperature de l'eau de la chaudière est obligatoire"),
    heatingInPlaceRetained: Yup.boolean().required('Chauffage en place à conserver est obligatoire'),
    externalGroupSetup: Yup.string().required('Endroit où sera installé le groupe extérieur est obligatoire'),
    wallType: Yup.string()
        .notRequired()
        .when('externalGroupSetup', {
            is: (value: string) => value === 'murs',
            then: Yup.string().required('Le type de mur est obligatoire'),
        }),
    soilTypeSetup: Yup.string()
        .notRequired()
        .when('externalGroupSetup', {
            is: (value: string) => value === 'sols',
            then: Yup.string().required('Type de sol où sera installé le groupe extérieur est obligatoire'),
        }),
    externalGroup: Yup.string().required("L'Emplacement du groupe extérieur est obligatoire"),
    crawlSpace: Yup.boolean().required('Le vide sanitaire est obligatoire'),
    heatingCircuit: Yup.array()
        .of(
            Yup.object().shape({
                type: Yup.string(),
                source: Yup.string(),
                temperature: Yup.string(),
            })
        )
        .min(1, 'Vous devez compléter au moins 1 circuit')
        .required('Vous devez ajouter un circuit de chauffage'),
    numberOfRadiator: Yup.number().nullable().required('Le nombre de radiateur est obligatoire'),
    identicalRadiators: Yup.boolean()
        .notRequired()
        .when('numberOfRadiator', {
            is: (value: number) => value > 1,
            then: Yup.boolean().required("Les radiateurs de l'habitation sont-ils identiques est obligatoire"),
        }),
    radiatorCircuit: Yup.array()
        .of(
            Yup.object().shape({
                thermostaticValve: Yup.boolean().required('La vanne thermostatique est obligatoire'),
                width: Yup.number().nullable().typeError('La largeur doit être un chiffre').required('La largeur est obligatoire'),
                height: Yup.number().nullable().typeError('La longueur doit être un chiffre').required('La longueur est obligatoire'),
                depth: Yup.number().nullable().typeError('La profondeur doit être un chiffre').required('La profondeur est obligatoire'),
                inputTemperature: Yup.number().nullable().typeError("La temperature d'entrée doit être un chiffre"),
                outputTemperature: Yup.number().nullable().typeError('La temperature de sortie doit être un chiffre'),
                radiatorType: Yup.string().required('La vue de face est obligatoire'),
                // TODO: sert à passer du context via Formik afin d'atteindre les valeurs d'un parent
                // .test('radiatorType', 'La vue de face est obligatoire', function () {
                //     if (this.options.context && this.options.context.identicalRadiators) {
                //         return false;
                //     }

                //     return true;
                // }),
                topView: Yup.string().required('La vue du dessus est obligatoire'),
                // .test('topView', 'La vue du dessus est obligatoire', function () {
                //     if (this.options.context && this.options.context.identicalRadiators === false) {
                //         return false;
                //     }

                //     return true;
                // }),
            })
        )
        .min(1, 'Vous devez compléter tous les radiateurs')
        .required('Un ou plusieur radiateurs ne sont pas complet'),
});
