import * as Yup from 'yup';
// import { PolygonWithID } from '../Rendement';

export const validationSchema = Yup.object().shape({
    sunlight: Yup.string().notRequired(),
    roofPitch: Yup.string().notRequired(),
    direction: Yup.string().notRequired(),
    installationPower: Yup.string().notRequired(),
    installationEfficiency: Yup.string().notRequired(),
    production: Yup.string().notRequired(),
    insurance: Yup.string().notRequired(),
    contractNumber: Yup.string().notRequired(),
    // solarEnergyPanels: Yup.array()
    //     .min(1, 'Renseignez au moins une zone de pose')
    //     .of(
    //         Yup.object()
    //             .shape({
    //                 id: Yup.string().required('Id is required'),
    //                 instance: Yup.mixed<PolygonWithID>().required(),
    //                 paths: Yup.array()
    //                     .of(
    //                         Yup.object()
    //                             .shape({
    //                                 lat: Yup.number().required(),
    //                                 lng: Yup.number().required(),
    //                             })
    //                             .required()
    //                     )
    //                     .required(),
    //                 area: Yup.number().required(),
    //             })
    //             .required()
    //     )
    //     .required(),
});

// TODO nicolas :
// garantie de revenu solaire : 2 champs obligatoire SI insuranceVisible === true
