import * as storageService from '../../../services/localStorageService';

export const handleCheckboxBalcony = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        balcony: action,
    });

    storageService.setAuditValue(2, isValid, 'balcony', action);

    // Purge balconyLength & picture
    if (values.balcony !== '') {
        storageService.removeAuditValue(2, 'balconyLength');
        storageService.removeAuditValue(8, 'buildingPictureBalcony');

        setValues({
            ...values,
            balcony: action,
            balconyLength: undefined,
            buildingPictureBalcony: '',
        });
    }
};

export const principalResidence = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        principalResidence: action,
    });

    storageService.setAuditValue(2, isValid, 'principalResidence', action);
};

export const waterSoftener = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        waterSoftener: action,
    });

    storageService.setAuditValue(2, isValid, 'waterSoftener', action);

    // Purge waterSoftener
    if (values.waterSoftener !== '') {
        storageService.removeAuditValue(2, 'waterSoftenerOver10Years');
        setValues({
            ...values,
            waterSoftener: action,
            waterSoftenerOver10Years: (values.waterSoftenerOver10Years = ''),
        });
    }
};

export const ledLighting = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        ledLighting: action,
    });

    storageService.setAuditValue(2, isValid, 'ledLighting', action);

    if (values.ledLighting !== '') {
        setValues({
            ...values,
            ledLighting: action,
        });
    }
};

export const alarm = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        alarm: action,
    });

    storageService.setAuditValue(2, isValid, 'alarm', action);

    // Purge alarm
    if (values.alarm !== '') {
        storageService.removeAuditValue(2, 'alarmOver10Years');
        setValues({
            ...values,
            alarm: action,
            alarmOver10Years: (values.alarmOver10Years = ''),
        });
    }
};

export const alarmOver10Years = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        alarmOver10Years: action,
    });

    storageService.setAuditValue(2, isValid, 'alarmOver10Years', action);

    // Purge alarmOver10Years
    if (values.alarmOver10Years) {
        setValues({
            ...values,
            alarmOver10Years: action,
        });
    }
};

export const pool = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        pool: action,
    });

    storageService.setAuditValue(2, isValid, 'pool', action);

    // Purge poolHeat et poolHeatType
    if (values.pool !== '') {
        storageService.removeAuditValue(2, 'poolHeatType', true);
        storageService.removeAuditValue(2, 'poolHeat');

        setValues({
            ...values,
            pool: action,
            poolHeat: (values.pool = false),
            poolHeatType: undefined,
        });
    }
};

export const poolHeat = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        poolHeat: action,
    });

    storageService.setAuditValue(2, isValid, 'poolHeat', action);

    if (values.poolHeat !== '') {
        // Purge poolHeatType
        storageService.removeAuditValue(2, 'poolHeatType', true);

        setValues({
            ...values,
            poolHeat: action,
            poolHeatType: undefined,
        });
    }
};

export const solarMask = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        solarMask: action,
    });

    storageService.setAuditValue(2, isValid, 'solarMask', action);

    // Purge SolarMaskPercent
    if (values.solarMask !== '') {
        storageService.removeAuditValue(2, 'solarMaskPercent', true);

        setValues({
            ...values,
            solarMask: action,
            solarMaskPercent: undefined,
        });
    }
};

export const veranda = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        veranda: action,
    });

    storageService.setAuditValue(2, isValid, 'veranda', action);

    if (values.veranda !== '') {
        // Purge veranda
        storageService.removeAuditValue(2, 'verandaAge', true);

        setValues({
            ...values,
            veranda: action,
            verandaAge: undefined,
        });
    }
};

export const pergola = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        pergola: action,
    });

    storageService.setAuditValue(2, isValid, 'pergola', action);

    if (values.pergola !== '') {
        // Purge pergola
        storageService.removeAuditValue(2, 'pergolaAge', true);

        setValues({
            ...values,
            pergola: action,
            pergolaAge: undefined,
        });
    }
};

export const garageDoor = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        garageDoor: action,
    });

    storageService.setAuditValue(2, isValid, 'garageDoor', action);

    if (values.garageDoor !== '') {
        // Purge garageDoorMotorized
        storageService.removeAuditValue(2, 'garageDoorMotorized');
        setValues({
            ...values,
            garageDoor: action,
            garageDoorMotorized: (values.garageDoorMotorized = false),
        });
    }
};

export const garageDoorMotorized = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        garageDoorMotorized: action,
    });

    storageService.setAuditValue(2, isValid, 'garageDoorMotorized', action);

    if (values.garageDoorMotorized !== '') {
        setValues({
            ...values,
            garageDoorMotorized: action,
        });
    }
};

export const portal = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        portal: action,
    });

    storageService.setAuditValue(2, isValid, 'portal', action);

    if (values.portal !== '') {
        storageService.removeAuditValue(2, 'portalMotorized');

        setValues({
            ...values,
            portal: action,
            portalMotorized: (values.portalMotorized = false),
        });
    }
};

export const portalMotorized = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        portalMotorized: action,
    });

    storageService.setAuditValue(2, isValid, 'portalMotorized', action);

    if (values.portal !== '') {
        setValues({
            ...values,
            portalMotorized: action,
        });
    }
};

export const electricVehicle = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        electricVehicle: action,
    });

    storageService.setAuditValue(2, isValid, 'electricVehicle', action);

    if (values.electricVehicle !== '') {
        // Purge electricalTerminal
        storageService.removeAuditValue(2, 'electricalTerminal');

        setValues({
            ...values,
            electricVehicle: action,
            electricalTerminal: (values.electricalTerminal = ''),
        });
    }
};

export const electricalTerminal = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        electricalTerminal: action,
    });

    storageService.setAuditValue(2, isValid, 'electricalTerminal', action);

    if (values.electricalTerminal !== '') {
        setValues({
            ...values,
            electricalTerminal: action,
        });
    }
};

export const hybridVehicle = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        hybridVehicle: action,
    });

    storageService.setAuditValue(2, isValid, 'hybridVehicle', action);

    if (values.hybridVehicle !== '') {
        setValues({
            ...values,
            hybridVehicle: action,
        });
    }
};

export const energyLabels = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        energyLabels: action,
    });

    storageService.setAuditValue(2, isValid, 'energyLabels', action);

    if (values.energyLabels !== '') {
        setValues({
            ...values,
            energyLabels: action,
        });
    }
};

export const waterSoftenerOver10Years = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        waterSoftenerOver10Years: action,
    });

    storageService.setAuditValue(2, isValid, 'waterSoftenerOver10Years', action);

    if (values.waterSoftenerOver10Years !== '') {
        setValues({
            ...values,
            waterSoftenerOver10Years: action,
        });
    }
};
