import React, { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import { PropertyType } from '../../services/localStorageService';
import * as storageService from '../../services/localStorageService';

import * as Yup from 'yup';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

import html2canvas from 'html2canvas';
import * as buildingPlanService from '../../services/componentsHelpers/buildingPlanService';

import { getTrackBackground, Range } from 'react-range';
import ReactTooltip from 'react-tooltip';
import {
    buildingTypeECSList,
    buildingTypeEmissionList,
    buildingTypeList,
    circulationTypeList,
    floorCountList,
    floorList,
    foldNumberList,
    numberList,
    orientationList,
    poolHeatList,
    projectStatusList,
    projectTypeList,
    roofExposureList,
    serialisableToNonSerialisable,
    shapeList,
    solarMaskList,
    tiltList,
} from './HomeInformationsEnums';
import { Floor } from '../../services/componentsHelpers/buildingService';
import { ROUTE_AUDIT_INFO_SYSTEMES_ENERGETIQUES } from '../../routing/paths';
import { useStepNavigation } from '../../routing/StepNavigationContext';
import { SolarPanels } from './components/SolarPanels';
import { renderDefaultValueSelect } from '../../services/tools/selectValue';
import { domTomCheck } from '../../services/tools/domTomServices';

import AddStepPhotos from '../../services/componentsHelpers/addStepPhotos';
import CheckboxService from '../../components/checkbox/checkboxService';
import {
    handleCheckboxBalcony,
    principalResidence,
    waterSoftener,
    waterSoftenerOver10Years,
    ledLighting,
    alarm,
    alarmOver10Years,
    pool,
    poolHeat,
    solarMask,
    veranda,
    pergola,
    garageDoor,
    garageDoorMotorized,
    portal,
    portalMotorized,
    electricVehicle,
    electricalTerminal,
    hybridVehicle,
    energyLabels,
} from './services/handleCheckbox';

import { generateArrayOfYears } from '../../services/tools/dateService';

import Select, { components, OptionProps } from 'react-select';

import { ReactComponent as IconClientInformations } from '../../assets/icons/icon-client-informations.svg';
import { ReactComponent as IconDraw } from '../../assets/icons/icon-draw.svg';
import { ReactComponent as IconHelp } from '../../assets/icons/icon-help.svg';
import { ReactComponent as IconPool } from '../../assets/icons/icon-pool.svg';

import { ReactComponent as SolarMaskIcon } from '../../assets/icons/icon-solarmask.svg';
import { ReactComponent as RoofIcon } from '../../assets/icons/icon-roof.svg';

import shab from '../../assets/images/image-shab.svg';
import shon from '../../assets/images/image-shon.svg';
import dropHere from '../../assets/icons/icon-upload-file.svg';
import { ReactComponent as ToitureSvg } from '../../assets/images/toiture.svg';
import { ReactComponent as PansSvg } from '../../assets/images/pans-toiture.svg';

import './table.scss';

const HomeInformations: React.FC = () => {
    const { validateThenRoute, returnToHome } = useStepNavigation();
    /**
     * Gestion désactivation formulaire
     */
    const [auditSent, setAuditSent] = useState(false);
    useEffect(() => {
        /**
         * Scroll de la page en haut
         */
        window.scrollTo(0, 0);

        /**
         * Vérification si Audit déjà envoyé
         */
        const template = storageService.getAudit();
        if (template?.auditSent) {
            setAuditSent(true);
        }
    }, []);

    const agentCode = storageService.getCurrentAgent().agentCode;
    const AlertSwal = withReactContent(Swal);

    /**
     * Fonction pour mettre à jour un champ dans le local storage ainsi que dans le form
     * @param event (Event provenant de l'input)
     * @param handleChange (Event du formulaire pour mettre à jour une valeur)
     * @param isValid (Event du formulaire pour savoir s'il est valide)
     * @param step (étape)
     * @param propertyName (nom de la propriété)
     * @param propertyList (List des propriétés)
     */
    const updateValue = (event: any, handleChange: any, isValid: boolean, step: number, propertyName: string, propertyList?: any) => {
        // Obtention du type de variable pour écriture
        const type: any = (validationSchema as any).fields[propertyName].type;
        // Mise à jour du formulaire
        if (propertyName !== 'occupationLevel') {
            handleChange(event);
        }
        // Mise à jour de la valeur du champ label dans le local storage
        if (propertyList) {
            const findLabel = propertyList.filter((property: any) => {
                return property.value === event.target.value;
            });
            return storageService.setAuditValue(step, isValid, propertyName, findLabel[0].value, findLabel[0].label);
        }
        // Cas spécifique pour le range du niveau d'occupation (occupationLevel)
        if (propertyName === 'occupationLevel') {
            return storageService.setAuditValue(step, isValid, 'occupationLevel', type === 'number' ? parseFloat(event) : event);
        }
        // Cas standard
        return storageService.setAuditValue(step, isValid, propertyName, type === 'number' ? parseFloat(event.target.value) : event.target.value);
    };

    /**
     * Fonction pour confirmer la date
     */
    // TODO Nicolas : 21 mois révolus (dans le texte suivant.)
    const confirmBuildYear = async () => {
        return await AlertSwal.fire({
            title: `Année de construction`,
            text: `L'age de la maison est-il supérieur à 2 ans révolus ?`,
            width: 550,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            showCancelButton: true,
            focusConfirm: true,
            allowEscapeKey: false,
            reverseButtons: true,
            allowOutsideClick: false,
            customClass: {
                confirmButton: 'btn btn-primary min-width',
                cancelButton: 'btn btn-secondary min-width',
            },
        }).then((result) => {
            return result.isConfirmed;
        });
    };

    /**
     * Liste des types de project
     */
    let filteredProjectTypeList = projectTypeList;

    // Altération action logement
    if (storageService.getAuditCampagne()) {
        filteredProjectTypeList = [filteredProjectTypeList[0], filteredProjectTypeList[1]];
    }

    /**
     * Pour l'affichage de chaque element du Select avec une icône
     * @param props
     */
    const singleOption = (props: OptionProps<any, any>) => (
        <components.Option {...props} className={'custom-react-select'}>
            {props.data.icon ? <img src={props.data.icon} alt={props.label} className={'img-fluid'} /> : null}
            {props.label}
        </components.Option>
    );

    /**
     * Pour l'affichage de la valeur sélectionnée
     * @param props
     */
    const singleValue = (props: any) => (
        <components.SingleValue {...props} className={'custom-react-select-selected'}>
            {props.data.icon ? <img src={props.data.icon} alt={props.label} className={'img-fluid'} /> : null}
            {props.data.label}
        </components.SingleValue>
    );

    /**
     * Pour le changement de couleurs du react-select
     */
    const colourStyles = {
        option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
            return {
                ...styles,
                backgroundColor: isFocused ? '#76ca4f' : null,
                color: isFocused ? '#ffffff' : '#1F2F3D',
            };
        },
    };

    /**
     * Liste des années de la maison
     */
    const houseAgeList: { label: string; value: string }[] = generateArrayOfYears();
    const currentZipCode: string = storageService.getAudit().step1.userZipCode.value;

    const initialValues = {
        projectType: storageService.checkPropertyExistThenCreateOrRender(2, 'projectType', `Type d’habitation`, PropertyType['withLabel']),
        projectStatus: storageService.checkPropertyExistThenCreateOrRender(2, 'projectStatus', `Statut d’occupation`, PropertyType['withLabel']),
        houseAge: storageService.checkPropertyExistThenCreateOrRender(2, 'houseAge', `Année de construction`, PropertyType['basic']),
        buildingAge: storageService.checkPropertyExistThenCreateOrRender(2, 'buildingAge', `Construction de plus de 2 ans`, PropertyType['boolean']),
        principalResidence: storageService.checkPropertyExistThenCreateOrRender(
            2,
            'principalResidence',
            `L’habitation est votre résidence principale`,
            PropertyType['boolean']
        ),
        floorCount: storageService.checkPropertyExistThenCreateOrRender(2, 'floorCount', `Nombre de niveaux habitables`, PropertyType['withLabel']),
        tilt: storageService.checkPropertyExistThenCreateOrRender(2, 'tilt', `Orientation du toit`, PropertyType['basic']),
        balcony: storageService.checkPropertyExistThenCreateOrRender(2, 'balcony', `Présence de balcons`, PropertyType['boolean']),
        balconyLength: storageService.checkPropertyExistThenCreateOrRender(2, 'balconyLength', `Longueur des balcons`, PropertyType['number']),
        solarMask: storageService.checkPropertyExistThenCreateOrRender(2, 'solarMask', `Présence d'un masque solaire`, PropertyType['boolean']),
        solarMaskPercent: storageService.checkPropertyExistThenCreateOrRender(2, 'solarMaskPercent', `Masque solaire`, PropertyType['withLabel']),
        wallAreaOnCirculation: storageService.checkPropertyExistThenCreateOrRender(
            2,
            'wallAreaOnCirculation',
            `Surface de murs sur circulation`,
            PropertyType['number']
        ),
        circulationType: storageService.checkPropertyExistThenCreateOrRender(2, 'circulationType', `Type de circulation`, PropertyType['withLabel']),
        SHab: storageService.checkPropertyExistThenCreateOrRender(2, 'SHab', `Shab (Surface habitable)`, PropertyType['number']),
        SHON: storageService.checkPropertyExistThenCreateOrRender(2, 'SHON', `SHON (Surface hors œuvre nette)`, PropertyType['number']),
        buildingPlans: storageService.checkPropertyExistThenCreateOrRender(2, 'buildingPlans', `Plans`, PropertyType['building']),
        foldNumber: storageService.checkPropertyExistThenCreateOrRender(2, 'foldNumber', `Nombre de pans`, PropertyType['withLabel']),
        generalOptionsMain: storageService.checkPropertyExistThenCreateOrRender(
            2,
            'generalOptionsMain',
            `Nombre de pièces principales (hors cuisine)`,
            PropertyType['number']
        ),
        generalOptionsBathroom: storageService.checkPropertyExistThenCreateOrRender(
            2,
            'generalOptionsBathroom',
            `Nombre de salle de bains`,
            PropertyType['number']
        ),
        generalOptionsWC: storageService.checkPropertyExistThenCreateOrRender(2, 'generalOptionsWC', `Nombre de WC indépendants`, PropertyType['number']),
        generalOptionsOtherWater: storageService.checkPropertyExistThenCreateOrRender(
            2,
            'generalOptionsOtherWater',
            `Nombre d’autres salles d’eau (cellier, buanderie, …)`,
            PropertyType['number']
        ),
        temperatureTarget: storageService.checkPropertyExistThenCreateOrRender(
            2,
            'temperatureTarget',
            `Température de consigne chauffage`,
            PropertyType['number']
        ),
        buildingPeople: storageService.checkPropertyExistThenCreateOrRender(2, 'buildingPeople', `Nombre d’occupants`, PropertyType['number']),
        occupationLevel: storageService.checkPropertyExistThenCreateOrRender(2, 'occupationLevel', `Niveau d’occupation`, PropertyType['number']),
        pool: storageService.checkPropertyExistThenCreateOrRender(2, 'pool', `Présence d'une piscine`, PropertyType['boolean']),
        poolHeat: storageService.checkPropertyExistThenCreateOrRender(2, 'poolHeat', `La piscine est-elle chauffée`, PropertyType['boolean']),
        poolHeatType: storageService.checkPropertyExistThenCreateOrRender(2, 'poolHeatType', `Type de chauffage pour la piscine`, PropertyType['withLabel']),
        veranda: storageService.checkPropertyExistThenCreateOrRender(2, 'veranda', `Présence d'une véranda ?`, PropertyType['boolean']),
        verandaAge: storageService.checkPropertyExistThenCreateOrRender(2, 'verandaAge', `Année de construction de la véranda`, PropertyType['withLabel']),
        pergola: storageService.checkPropertyExistThenCreateOrRender(2, 'pergola', `Présence d'une pergola ?`, PropertyType['boolean']),
        pergolaAge: storageService.checkPropertyExistThenCreateOrRender(2, 'pergolaAge', `Année de construction de la pergola`, PropertyType['withLabel']),
        waterSoftener: storageService.checkPropertyExistThenCreateOrRender(2, 'waterSoftener', `Présence d'un adoucisseur d'eau ?`, PropertyType['boolean']),
        waterSoftenerOver10Years: storageService.checkPropertyExistThenCreateOrRender(
            2,
            'waterSoftenerOver10Years',
            `L'adoucisseur d'eau  a été installé il y a plus de 10 ans ?`,
            PropertyType['boolean']
        ),
        ledLighting: storageService.checkPropertyExistThenCreateOrRender(2, 'ledLighting', `Eclairage led majoritaire (>50%)`, PropertyType['boolean']),
        alarm: storageService.checkPropertyExistThenCreateOrRender(2, 'alarm', `Présence d'une alarme dans l'habitation ?`, PropertyType['boolean']),
        alarmOver10Years: storageService.checkPropertyExistThenCreateOrRender(
            2,
            'alarmOver10Years',
            `L'alarme a été installé il y a plus de 10 ans`,
            PropertyType['boolean']
        ),
        garageDoor: storageService.checkPropertyExistThenCreateOrRender(2, 'garageDoor', `Présence d'une porte de garage ?`, PropertyType['boolean']),
        garageDoorMotorized: storageService.checkPropertyExistThenCreateOrRender(
            2,
            'garageDoorMotorized',
            `La porte de garage est-elle motorisée`,
            PropertyType['boolean']
        ),
        portal: storageService.checkPropertyExistThenCreateOrRender(2, 'portal', `Présence d'un portail`, PropertyType['boolean']),
        portalMotorized: storageService.checkPropertyExistThenCreateOrRender(2, 'portalMotorized', `Le portail est-il morotisé`, PropertyType['boolean']),
        electricVehicle: storageService.checkPropertyExistThenCreateOrRender(2, 'electricVehicle', `Véhicule électrique`, PropertyType['boolean']),
        electricalTerminal: storageService.checkPropertyExistThenCreateOrRender(
            2,
            'electricalTerminal',
            `Présence d'une borne de recharge électrique ?`,
            PropertyType['boolean']
        ),
        hybridVehicle: storageService.checkPropertyExistThenCreateOrRender(2, 'hybridVehicle', `Véhicule hybride`, PropertyType['boolean']),
        energyLabels: storageService.checkPropertyExistThenCreateOrRender(
            2,
            'energyLabels',
            `Les étiquettes énergétiques des appareils électroménager sont-elles majoritairement des A++`,
            PropertyType['boolean']
        ),
        roofExposure: storageService.checkPropertyExistThenCreateOrRender(2, 'roofExposure', `Exposition de la toiture`, PropertyType['withLabel']),
        buildingTypeDetails: storageService.checkPropertyExistThenCreateOrRender(
            2,
            'buildingTypeDetails',
            `Détail des bâtiments`,
            PropertyType['buildingDetail']
        ),
        solarEnergyPanels: serialisableToNonSerialisable(
            storageService.checkPropertyExistThenCreateOrRender(2, 'solarEnergyPanelsSerialisable', 'Implantation des panneaux solaires', PropertyType['array'])
        ),
        solarEnergyPanelsSerialisable: storageService.checkPropertyExistThenCreateOrRender(
            2,
            'solarEnergyPanelsSerialisable',
            'Implantation des panneaux solaires',
            PropertyType['array']
        ),

        buildingPictureRoofing: storageService.checkPropertyExistThenCreateOrRender(8, 'buildingPictureRoofing', `Photo de la toiture`, PropertyType['photo']),
        buildingPictureBalcony: storageService.checkPropertyExistThenCreateOrRender(8, 'buildingPictureBalcony', `Photo du balcon`, PropertyType['photo']),
        buildingPicturesSolarMask: storageService.checkPropertyExistThenCreateOrRender(
            8,
            'buildingPicturesSolarMask',
            `Photo présence d'une masque solaire`,
            PropertyType['photo']
        ),
    };

    const validationSchema = Yup.object({
        projectType: Yup.string().required(`Le type d'habitation est obligatoire`),
        projectStatus: Yup.string().required(`Vous n'avez pas préciser votre status`),
        principalResidence: Yup.boolean()
            .required(`Vous n'avez pas indiqué si l'habitation est votre résidence principale`)
            .test('principalResidence', `Vous n'avez pas indiqué si l'habitation est votre résidence principale`, (value) => {
                return [true, false].includes(value as boolean);
            }),
        houseAge: Yup.string().required(`L'année de construction est obligatoire`),
        buildingAge: Yup.boolean(),
        floorCount: Yup.string().required(`Le nombre d'étages est obligatoire`),
        tilt: Yup.number()
            .nullable()
            .notRequired()
            .when('projectType', {
                is: (value: string) => ['1', '4'].includes(value),
                then: Yup.number().typeError(`L'inclinaison de la toiture est obligatoire`).required(`L'inclinaison de la toiture est obligatoire`),
            }),
        balcony: Yup.boolean()
            .required(`Vous n'avez pas indiqué si l'habitation comporte des balcons`)
            .test(`balcony`, `Vous n'avez pas indiqué si l'habitation comporte des balcons`, (value) => {
                return [true, false].includes(value as boolean);
            }),
        buildingPictureBalcony: Yup.string().notRequired(),
        balconyLength: Yup.number()
            .nullable()
            .notRequired()
            .when('balcony', {
                is: true,
                then: Yup.number()
                    .min(1, 'La longueur minimale est de 1 mètre')
                    .typeError(`La longueur des balcons est obligatoire`)
                    .required(`La longueur des balcons est obligatoire`),
            }),
        solarMask: Yup.boolean()
            .notRequired()
            .when('projectType', {
                is: (value: string) => ['1', '4'].includes(value),
                then: Yup.boolean()
                    .required(`Vous n'avez pas indiqué si l'habitation est impactée par un masque solaire`)
                    .test('solarMask', `Vous n'avez pas indiqué si l'habitation est impactée par un masque solaire`, (value) => {
                        return [true, false].includes(value as boolean);
                    }),
            }),
        buildingPicturesSolarMask: Yup.string().notRequired(),
        solarMaskPercent: Yup.string()
            .notRequired()
            .when('solarMask', {
                is: true,
                then: Yup.string().required(`Le pourcentage d'exposition est obligatoire`),
            }),
        pool: Yup.boolean()
            .notRequired()
            .when('projectType', {
                is: (value: string) => ['1', '4'].includes(value),
                then: Yup.boolean()
                    .required(`Vous n'avez pas indiqué si l'habitation comporte une piscine`)
                    .test('pool', `Vous n'avez pas indiqué si l'habitation comporte une piscine`, (value) => {
                        return [true, false].includes(value as boolean);
                    }),
            }),
        poolHeat: Yup.boolean()
            .notRequired()
            .when('pool', {
                is: true,
                then: Yup.boolean()
                    .required(`Vous n'avez pas indiqué si la piscine est chauffée`)
                    .test('poolHeat', `Vous n'avez pas indiqué si la piscine est chauffée`, (value) => {
                        return [true, false].includes(value as boolean);
                    }),
            }),
        poolHeatType: Yup.string()
            .notRequired()
            .when('poolHeat', {
                is: true,
                then: Yup.string().required('Le type de chauffage pour la piscine est obligatoire'),
            }),
        veranda: Yup.boolean()
            .notRequired()
            .when('projectType', {
                is: (value: string) => ['1', '4'].includes(value),
                then: Yup.boolean()
                    .required(`Vous n'avez pas précisé si l'habitation comporte une véranda`)
                    .test('veranda', `Vous n'avez pas précisé si l'habitation comporte une véranda`, (value) => {
                        return [true, false].includes(value as boolean);
                    }),
            }),
        verandaAge: Yup.string()
            .notRequired()
            .when('veranda', {
                is: true,
                then: Yup.string().required(`L'année de construction de la véranda est obligatoire`),
            }),
        pergola: Yup.boolean()
            .notRequired()
            .when('projectType', {
                is: (value: string) => ['1', '4'].includes(value),
                then: Yup.boolean()
                    .required(`Vous n'avez pas précisé si l'habitation comporte une pergola`)
                    .test('pergola', `Vous n'avez pas précisé si l'habitation comporte une pergola`, (value) => {
                        return [true, false].includes(value as boolean);
                    }),
            }),
        pergolaAge: Yup.string()
            .notRequired()
            .nullable()
            .when('pergola', {
                is: true,
                then: Yup.string().required(`L'année de construction de la pergola est obligatoire`),
            }),
        waterSoftener: Yup.boolean()
            .required(`Vous n'avez pas précisé si l'habitation comporte un adoucisseur d'eau`)
            .test('waterSoftener', `Vous n'avez pas précisé si l'habitation comporte un adoucisseur d'eau`, (value) => {
                return [true, false].includes(value as boolean);
            }),
        waterSoftenerOver10Years: Yup.boolean()
            .notRequired()
            .when('waterSoftener', {
                is: true,
                then: Yup.boolean()
                    .required(`Vous n'avez pas précisé si l'adoucisseur d'eau a été installé il y a plus de 10 ans`)
                    .test('waterSoftenerOver10Years', `Vous n'avez pas précisé si l'adoucisseur d'eau a été installé il y a plus de 10 ans`, (value) => {
                        return [true, false].includes(value as boolean);
                    }),
            }),
        ledLighting: Yup.boolean()
            .required(`Vous n'avez pas indiqué si l'éclairage est majoritairement au LED (>50%)`)
            .test('ledLighting', `Vous n'avez pas indiqué si l'éclairage est majoritairement au LED (>50%)`, (value) => {
                return [true, false].includes(value as boolean);
            }),
        alarm: Yup.boolean()
            .required(`Vous n'avez pas précisé si l'habitation est équipé d'une alarme`)
            .test('alarm', `Vous n'avez pas précisé si l'habitation est équipé d'une alarme`, (value) => {
                return [true, false].includes(value as boolean);
            }),
        alarmOver10Years: Yup.boolean()
            .notRequired()
            .when('alarm', {
                is: true,
                then: Yup.boolean()
                    .required(`Vous n'avez pas précisé si le système d'alarme a été installé il y a plus de 10 ans`)
                    .test('alarmOver10Years', `Vous n'avez pas précisé si le système d'alarme a été installé il y a plus de 10 ans`, (value) => {
                        return [true, false].includes(value as boolean);
                    }),
            }),
        garageDoor: Yup.boolean()
            .notRequired()
            .when('projectType', {
                is: (value: string) => ['1', '4'].includes(value),
                then: Yup.boolean()
                    .required(`Vous n'avez pas précisé si l'habitation comporte une porte de garage`)
                    .test('garageDoor', `Vous n'avez pas précisé si l'habitation comporte une porte de garage`, (value) => {
                        return [true, false].includes(value as boolean);
                    }),
            }),
        garageDoorMotorized: Yup.boolean()
            .notRequired()
            .when('garageDoor', {
                is: true,
                then: Yup.boolean()
                    .required(`Vous n'avez pas précisé si la porte de garage est motorisée`)
                    .test('garageDoorMotorized', `Vous n'avez pas précisé si la porte de garage est motorisée`, (value) => {
                        return [true, false].includes(value as boolean);
                    }),
            }),
        portal: Yup.boolean()
            .notRequired()
            .when('projectType', {
                is: (value: string) => ['1', '4'].includes(value),
                then: Yup.boolean()
                    .required(`Vous n'avez pas précisé si l'habitation comporte un portail`)
                    .test('portal', `Vous n'avez pas précisé si l'habitation comporte un portail`, (value) => {
                        return [true, false].includes(value as boolean);
                    }),
            }),
        portalMotorized: Yup.boolean()
            .notRequired()
            .when('portal', {
                is: true,
                then: Yup.boolean()
                    .required(`Vous n'avez pas précisé si le portail est motorisé`)
                    .test('portalMotorized', `Vous n'avez pas précisé si le portail est motorisé`, (value) => {
                        return [true, false].includes(value as boolean);
                    }),
            }),
        electricVehicle: Yup.boolean()
            .required(`Vous n'avez pas précisé si l'habitation comporte une voiture électrique`)
            .test('electricVehicle', `Vous n'avez pas précisé si l'habitation comporte une voiture électrique`, (value) => {
                return [true, false].includes(value as boolean);
            }),
        electricalTerminal: Yup.boolean()
            .notRequired()
            .when('electricVehicle', {
                is: true,
                then: Yup.boolean()
                    .required(`Vous n'avez pas précisé si l'habitation dispose d'une borde de recharge électrique`)
                    .test('electricalTerminal', `Vous n'avez pas précisé si l'habitation dispose d'une borde de recharge électrique`, (value) => {
                        return [true, false].includes(value as boolean);
                    }),
            }),
        hybridVehicle: Yup.boolean()
            .required(`Vous n'avez pas précisé si l'habitation comporte une voiture hybride`)
            .test('hybridVehicle', `Vous n'avez pas précisé si l'habitation comporte une voiture hybride`, (value) => {
                return [true, false].includes(value as boolean);
            }),
        energyLabels: Yup.boolean()
            .required(`Vous n'avez pas précisé si les étiques énergétiques des appareils électroménager sont majoritairement des A++`)
            .test('energyLabels', `Vous n'avez pas précisé si les étiques énergétiques des appareils électroménager sont majoritairement des A++`, (value) => {
                return [true, false].includes(value as boolean);
            }),
        wallAreaOnCirculation: Yup.number()
            .nullable()
            .notRequired()
            .when('projectType', {
                is: (value: string) => ['2'].includes(value),
                then: Yup.number()
                    .min(0, 'La longueur minimale est de 0 mètre')
                    .typeError(`La surface des murs en circulation est obligatoire`)
                    .required(`La surface des murs en circulation est obligatoire`),
            }),
        circulationType: Yup.string().when('projectType', {
            is: (value: string) => ['2'].includes(value),
            then: Yup.string().required(`Le type de circulation est obligatoire`),
        }),
        SHab: Yup.number()
            .min(1, 'La surface minimale est de 1 mètre²')
            .typeError(`La SHAB est obligatoire`)
            .required(`La SHAB est obligatoire`)
            .lessThan(Yup.ref('SHON'), 'La valeur SHAB doit être inférieur à la valeur SHON'),
        SHON: Yup.number()
            .min(1, 'La surface minimale est de 1 mètre²')
            .typeError(`La SHON est obligatoire`)
            .required(`La SHON est obligatoire`)
            .moreThan(Yup.ref('SHab'), 'La valeur SHON doit être supérieur à la valeur SHAB'),
        foldNumber: Yup.number()
            .nullable()
            .notRequired()
            .when(['buildingPlans[0].shape', 'projectType'], {
                is: (value: string, projet: number) => ['1', '4'].includes(value, projet),
                then: Yup.number().typeError(`Le nombre de pans est obligatoire`).required(`Le nombre de pans est obligatoire`),
            })
            .when(['buildingPlans[1].shape', 'projectType'], {
                is: (value: string, projet: number) => ['1', '4'].includes(value, projet),
                then: Yup.number().typeError(`Le nombre de pans est obligatoire`).required(`Le nombre de pans est obligatoire`),
            })
            .when(['buildingPlans[2].shape', 'projectType'], {
                is: (value: string, projet: number) => ['1', '4'].includes(value, projet),
                then: Yup.number().typeError(`Le nombre de pans est obligatoire`).required(`Le nombre de pans est obligatoire`),
            })
            .when('projectType', {
                is: (value: string) => ['1', '4'].includes(value),
                then: Yup.number().typeError(`Le nombre de pans est obligatoire`).required(`Le nombre de pans est obligatoire`),
            }),
        buildingPlans: Yup.array().of(
            Yup.object().shape({
                name: Yup.string()
                    .typeError(`Le choix du nom du niveau du croquis est obligatoire`)
                    .required('Le choix du nom du niveau du croquis est obligatoire'),
                shape: Yup.string().typeError(`La forme est obligatoire`).required('La forme est obligatoire'),
                height: Yup.number().typeError(`La hauteur est obligatoire`).required('La hauteur est obligatoire'),
                length: Yup.number().typeError(`La longueur est obligatoire`).required('La longueur est obligatoire'),
                width: Yup.number().typeError(`La largeur est obligatoire`).required('La largeur est obligatoire'),
                l1: Yup.number()
                    .nullable()
                    .notRequired()
                    .when('shape', {
                        is: (value: string) => ['11', '12', '21', '22', '31', '32', '41', '42', '51', '52'].includes(value),
                        then: Yup.number().min(0.5, 'La longueur minimale est de 0.5 mètre').typeError(`L1 est obligatoire`).required(`L1 est obligatoire`),
                    }),
                l2: Yup.number()
                    .nullable()
                    .notRequired()
                    .when('shape', {
                        is: (value: string) => ['11', '12', '21', '22', '31', '32', '41', '42', '51', '52'].includes(value),
                        then: Yup.number().min(0.5, 'La longueur minimale est de 0.5 mètre').typeError(`l2 est obligatoire`).required(`l2 est obligatoire`),
                    }),
                l3: Yup.number()
                    .nullable()
                    .notRequired()
                    .when('shape', {
                        is: (value: string) => ['21', '22', '41', '42', '51'].includes(value),
                        then: Yup.number().min(0.5, 'La longueur minimale est de 0.5 mètre').typeError(`L3 est obligatoire`).required(`L3 est obligatoire`),
                    }),
                l4: Yup.number()
                    .nullable()
                    .notRequired()
                    .when('shape', {
                        is: (value: string) => ['21', '22', '31', '32', '41', '42', '51', '52'].includes(value),
                        then: Yup.number().min(0.5, 'La longueur minimale est de 0.5 mètre').typeError(`L4 est obligatoire`).required(`L4 est obligatoire`),
                    }),
                l5: Yup.number()
                    .nullable()
                    .notRequired()
                    .when('shape', {
                        is: (value: string) => ['41', '42', '51', '52'].includes(value),
                        then: Yup.number().min(0.5, 'La longueur minimale est de 0.5 mètre').typeError(`L5 est obligatoire`).required(`L5 est obligatoire`),
                    }),
                l6: Yup.number()
                    .nullable()
                    .notRequired()
                    .when('shape', {
                        is: (value: string) => ['41', '42', '51', '52'].includes(value),
                        then: Yup.number().min(0.5, 'La longueur minimale est de 0.5 mètre').typeError(`L6 est obligatoire`).required(`L6 est obligatoire`),
                    }),
                l7: Yup.number()
                    .nullable()
                    .notRequired()
                    .when('shape', {
                        is: (value: string) => ['51', '52'].includes(value),
                        then: Yup.number().min(0.5, 'La longueur minimale est de 0.5 mètre').typeError(`L7 est obligatoire`).required(`L7 est obligatoire`),
                    }),
                l8: Yup.number()
                    .nullable()
                    .notRequired()
                    .when('shape', {
                        is: (value: string) => ['51'].includes(value),
                        then: Yup.number().min(0.5, 'La longueur minimale est de 0.5 mètre').typeError(`L8 est obligatoire`).required(`L8 est obligatoire`),
                    }),
                orientation: Yup.string().required(`L'orientation est obligatoire`),
                img: Yup.string().notRequired(),
                base64: Yup.string()
                    .typeError(`La génération du plan est obligatoire`)
                    .required(`La génération du plan est obligatoire`)
                    .when('shape', {
                        is: (value: string) => ['12'].includes(value),
                        then: Yup.string()
                            .typeError(`Un fichier personnalisé de type jpeg/png ou pdf est obligatoire`)
                            .required(`Un fichier personnalisé de type jpeg/png ou pdf est obligatoire`),
                    }),
            })
        ),
        generalOptionsMain: Yup.number()
            .nullable()
            .notRequired()
            .when('projectType', {
                is: (value: string) => ['1', '2', '4'].includes(value),
                then: Yup.number()
                    .min(1, 'Le nombre de pièces minimal est de 1')
                    .integer(`Le nombre de pièces doit être un nombre entier`)
                    .typeError(`Le nombre de pièces est obligatoire`),
            }),
        generalOptionsBathroom: Yup.number()
            .nullable()
            .notRequired()
            .when('projectType', {
                is: (value: string) => ['1', '2', '4'].includes(value),
                then: Yup.number()
                    .min(1, 'Le nombre de salles de bain minimal est de 1')
                    .integer(`Le nombre de salles de bain doit être un nombre entier`)
                    .typeError(`Le nombre de salles de bain est obligatoire`)
                    .required(`Le nombre de salles de bain est obligatoire`),
            }),
        generalOptionsWC: Yup.number()
            .nullable()
            .notRequired()
            .when('projectType', {
                is: (value: string) => ['1', '2', '4'].includes(value),
                then: Yup.number()
                    .min(0, 'Le nombre de WC indépendants minimal est de 0')
                    .integer(`Le nombre de WC doit être un nombre entier`)
                    .typeError(`Le nombre de WC indépendants est obligatoire; indiquer 0 si aucun WC indépendant`)
                    .required(`Le nombre de WC indépendants est obligatoire; indiquer 0 si aucun WC indépendant`),
            }),
        generalOptionsOtherWater: Yup.number()
            .nullable()
            .notRequired()
            .when('projectType', {
                is: (value: string) => ['1', '2', '4'].includes(value),
                then: Yup.number()
                    .min(0, `Le nombre de salles d'eau minimal est de 0`)
                    .integer(`Le nombre de salles d'eau doit être un nombre entier`)
                    .typeError(`Le nombre de salles d'eau minimal est obligatoire`)
                    .required(`Le nombre de salles d'eau minimal est obligatoire`),
            }),
        temperatureTarget: domTomCheck(currentZipCode)
            ? Yup.number().nullable().notRequired()
            : Yup.number()
                  .nullable()
                  .notRequired()
                  .when('projectType', {
                      is: (value: string) => ['1', '2', '4'].includes(value),
                      then: Yup.number()
                          .min(10, `La température de consigne minimale de chauffage est de 10`)
                          .max(30, `La température de consigne maximale de chauffage est de 30`)
                          .typeError(`La température de consigne minimale est obligatoire`)
                          .required(`La température de consigne minimale est obligatoire`),
                  }),
        buildingPeople: Yup.number()
            .nullable()
            .notRequired()
            .when('projectType', {
                is: (value: string) => ['1', '2', '4'].includes(value),
                then: Yup.number()
                    .min(0, `Le nombre d'occupants minimal est de 0`)
                    .integer(`Le nombre d'occupants doit être un nombre entier`)
                    .typeError(`Le nombre d'occupants minimal est obligatoire`)
                    .required(`Le nombre d'occupants minimal est obligatoire`),
            }),
        occupationLevel: Yup.number().required(),
        buildingPictureRoofing: Yup.string().notRequired(),
        roofExposure: Yup.string()
            .notRequired()
            .when('projectType', {
                is: (value: string) => ['1', '4'].includes(value),
                then: Yup.string().nullable().required(`L'exposition de la toiture est obligatoire`),
            }),
        buildingTypeDetails: Yup.array()
            .notRequired()
            .when('projectType', {
                is: (value: string) => value === '3',
                then: Yup.array()
                    .required()
                    .of(
                        Yup.object().shape({
                            buildingType: Yup.object({
                                value: Yup.string().required(`Désignation du logement est obligatoire`),
                            }),
                            buildingTypeSurface: Yup.number()
                                .min(1, `La surface ne peut pas être inférieure à 1`)
                                .max(999, `La surface ne peut pas excéder 999`)
                                .required(`La surface est obligatoire`),
                            identicalBuildingType: Yup.object({
                                value: Yup.string().required(`Le nombre de logement identique est obligatoire`),
                            }),
                            buildingTypeMainRoom: Yup.object({
                                value: Yup.string().required(`Le nombre de pièce principale est obligatoire`),
                            }),
                            buildingTypeBathroom: Yup.object({
                                value: Yup.string().required(`Le nombre de salle de bain est obligatoire`),
                            }),
                            buildingTypeBathroom2: Yup.object({
                                value: Yup.string().required(`Le nombre de salle d'eau est obligatoire`),
                            }),
                            buildingTypeWC: Yup.object({
                                value: Yup.string().required(`Le nombre de WC est obligatoire`),
                            }),
                            buildingTypeEmission: Yup.object({
                                value: Yup.string().required(`Emission est obligatoire`),
                            }),
                            buildingTypeECS: Yup.object({
                                value: Yup.string().required(`ECS est obligatoire`),
                            }),
                            buildingLocked: Yup.boolean(),
                        })
                    ),
            }),
    });

    // Information sur la div screen
    const [screenWidth, setScreenWidth] = useState(0);
    const [screenHeight, setScreenHeight] = useState(0);
    const screenRef = useRef(null);
    const buildingPictureBalcony = useRef(null);
    const buildingPicturesSolarMask = useRef(null);
    const buildingPictureRoofing = useRef(null);
    const [getHouseAge, setHouseAge] = useState(0);

    // Get houseAge to set min date to verandaAgeList
    const minYear = storageService.getAudit().step2.houseAge.value;
    const verandaAndPergolaAgeList: { label: string; value: string }[] = generateArrayOfYears(getHouseAge ? getHouseAge : minYear);

    // Update houseAge for Veranda & Pergola
    const handleHouseAge = (minYear: number) => {
        setHouseAge(minYear);
    };

    /**
     * Lors du redimensionnement de la page
     */
    useEffect(() => {
        function handleResize() {
            // @ts-ignore
            setScreenWidth(screenRef.current?.offsetWidth);
            // @ts-ignore
            setScreenHeight(screenRef.current?.offsetHeight);
        }

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const buildingPlanFile0 = useRef(null);
    const buildingPlanFile1 = useRef(null);
    const buildingPlanFile2 = useRef(null);

    const dragOver = (event: any) => {
        event.preventDefault();
    };

    const dragEnter = (event: any) => {
        event.preventDefault();
    };

    const dragLeave = (event: any) => {
        event.preventDefault();
    };

    /**
     * Fonction pour déclencher l'ouverture de l'input
     * @param reference (nom de la référence)
     */
    const openInputFile = (reference: any) => {
        reference.current.click();
    };

    /**
     * Lors du clic sur la zone de largage
     * @param event
     * @param index
     * @param handleChange
     * @param values
     * @param setFieldValue
     * @param isValid
     */
    const fileClick = async (event: any, index: number, handleChange: any, values: any, setFieldValue: any, isValid: boolean) => {
        event.preventDefault();
        if (event.target.files.length) {
            await handleFiles(event.target.files, index, handleChange, values, setFieldValue, isValid);
        }
    };

    /**
     * Lors du largage du fichier dans la zone
     * @param event
     * @param index
     * @param handleChange
     * @param values (valeurs du formulaire)
     * @param setFieldValue (Methode Formik pour mise à jour d'un élément du formulaire)
     * @param isValid (Valeur de la validité du formulaire)
     */
    const fileDrop = async (event: any, index: number, handleChange: any, values: any, setFieldValue: any, isValid: boolean) => {
        event.preventDefault();
        if (event.dataTransfer.files.length) {
            await handleFiles(event.dataTransfer.files, index, handleChange, values, setFieldValue, isValid);
        }
    };

    /**
     * Manipulation du fichier
     * @param files
     * @param index
     * @param handleChange
     * @param values (valeurs du formulaire)
     * @param setFieldValue (Methode Formik pour mise à jour d'un élément du formulaire)
     * @param isValid (Valeur de la validité du formulaire)
     */
    const handleFiles = async (files: any, index: number, handleChange: any, values: any, setFieldValue: any, isValid: boolean) => {
        if (validateFile(files[0])) {
            if (files[0].type.match('image.*')) {
                // Redimensionnement du fichier Image
                const resizedFile = await resize(files[0], 1000, 1000);
                if (resizedFile) {
                    updateValueBuildingPlan(resizedFile, handleChange, isValid, index, 'base64', setFieldValue);
                }
            } else {
                // Fichier trop gros
                if (files[0].size > 1000000) {
                    return AlertSwal.fire({
                        title: 'Erreur de chargement',
                        html: `Le fichier PDF chargé est trop volumineux<br/>Le poids maximum autorisé est de <b>1Mo</b>.`,
                        icon: 'error',
                        width: 550,
                        confirmButtonText: 'Fermer',
                        focusConfirm: true,
                        customClass: {
                            confirmButton: 'btn btn-primary min-width',
                        },
                    });
                }
                // Transformation en Base64 du fichier PDF puis injection local-storage
                getBase64File(files[0]).then((base64File: any) => {
                    updateValueBuildingPlan(base64File, handleChange, isValid, index, 'base64', setFieldValue);
                });
            }
        } else {
            // Mauvais format
            await AlertSwal.fire({
                title: `Erreur de format`,
                html: `Le format du fichier chargé n'est pas supporté.<br/>Merci de charger une image au format <b>.jpeg</b>, <b>.png</b> ou <b>.pdf</b>.`,
                icon: 'error',
                width: 550,
                confirmButtonText: 'Fermer',
                focusConfirm: true,
                customClass: {
                    confirmButton: 'btn btn-primary min-width',
                },
            });
        }
    };

    /**
     * Fonction pour redimensionner l'image
     * @param file (fichier d'origine)
     * @param max_width (largueur maximale)
     * @param max_height (hauteur maximale)
     */
    async function resize(file: any, max_width: number, max_height: number) {
        return new Promise((resolve, reject) => {
            // Préparation du fileReader
            let fileLoader: any = new FileReader();
            let imageObj = new Image();
            fileLoader.onload = function () {
                imageObj.src = this.result;
            };

            let canvas = document.createElement('canvas');
            let context: any = null;

            // Creation d'un canvas caché por dessiner
            canvas.id = 'hiddenCanvas';
            canvas.style.visibility = 'hidden';

            // Gestion des cas avec abandon
            fileLoader.onabort = function () {
                reject(`Le chargement du fichier a été annulé`);
            };

            // Gestion des cas avec erreurs
            fileLoader.onerror = function () {
                reject(`Une erreur s'est produite lors du chargement du fichier`);
            };

            // Lancement readAsDataURL
            fileLoader.readAsDataURL(file);

            // Dessin de la nouvelle image
            imageObj.onload = async (event: any) => {
                // Définition image avec vérification présence info (pour Safari ne respectant pas le standard HTML5)
                const img = event?.path ? event.path[0] : event.target.outerHTML;

                const MAX_WIDTH = max_width;
                const MAX_HEIGHT = max_height;
                // Définition taille de l'image avec vérification présence infos (pour Safari ne respectant pas le standard HTML5)
                let width = img?.width ? img.width : event.target.naturalWidth;
                let height = img?.height ? img.height : event.target.naturalHeight;

                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                document.body.appendChild(canvas);

                // Définition du context à utiliser
                context = canvas.getContext('2d');

                // Dessin
                context.drawImage(imageObj, 0, 0, width, height);
                let dataUrl = canvas.toDataURL('image/jpeg');

                // const blob = dataURItoBlob(dataUrl);

                resolve(dataUrl);
            };

            // Gestion des cas avec abandon
            imageObj.onabort = function () {
                reject(`Le chargement de l'image a été annulé`);
            };

            imageObj.onerror = function () {
                reject(`Une erreur s'est produite lors du chargement de l'image`);
            };
        });
    }

    /**
     * Fonction pour convertir un PDF en base64
     * @param file
     */
    function getBase64File(file: any) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    /**
     * Fonction pour vérifier le format du fichier chargé
     * @param file (fichier d'origine)
     */
    const validateFile = (file: File) => {
        if (file.type.match('image.*')) {
            return true;
        }
        if (file.type === 'application/pdf') {
            return true;
        }
        return false;
    };

    /**
     * Fonction pour mettre à jour le plan
     * @param event (Event provenant de l'input)
     * @param handleChange (Event du formulaire pour mettre à jour une valeur)
     * @param isValid (Event du formulaire pour savoir s'il est valide)
     * @param index (index du tableau)
     * @param setValues (Event du formulaire pour mettre à jour une propriété)
     * @param values (valeurs du form)
     * @param setTouched (Event du formulaire pour mettre à jour le "touched" d'une propriété)
     */
    const changeBuildingPlan = (event: any, handleChange: any, isValid: boolean, index: number, setValues: any, values: any, setTouched: any) => {
        // Récupération valeur du champ dans le local storage
        let buildingPlansLocalStorage = storageService.checkPropertyExistThenCreateOrRender(2, 'buildingPlans', `Plans`, PropertyType['building']);

        // Récupération du nom du building si disponible
        const buildingPlanName = buildingPlansLocalStorage[index].name;

        // Récupération de la hauteur sous plafond si disponible
        const buildingPlanHeight = buildingPlansLocalStorage[index].height;

        // Mise à jour
        buildingPlansLocalStorage[index] = event.defaultTemplate;
        buildingPlansLocalStorage[index].name = buildingPlanName;
        buildingPlansLocalStorage[index].orientation = '';
        // TODO : this can be 2.399999999999999911182158029987476766109466552734375 not rounded to 2.4
        buildingPlansLocalStorage[index].height = buildingPlanHeight;

        /**
         * Mise à jour du champs buildingPlan
         * Purge du champ foldNumber (Nombre de pans)
         */
        setValues({
            ...values,
            buildingPlans: buildingPlansLocalStorage,
            foldNumber: null,
        });

        // Réinitialisation du touched de la propriété BuildingPlans
        setTouched({
            ...values,
            buildingPlans: false,
        });

        /**
         * Mise à jour du localstorage
         */
        storageService.setAuditValue(2, isValid, 'foldNumber', null);
        storageService.setGlobalBuildingPlan(isValid, buildingPlansLocalStorage);

        // Suppression photo correspondante  dans local storage
        localStorage.removeItem(agentCode + '_image-' + index);
    };

    /**
     * Fonction pour mettre à jour le plan
     * @param values (valeurs du tableau Formik)
     * @param isValid (Event du formulaire pour savoir s'il est valide)
     * @param index (index du tableau)
     * @param setValues (Event du formulaire pour mettre à jour une propriété)
     */
    // const removeBuildingPlan = (values: any, isValid: boolean, index: number, setValues: any) => {
    //
    //     let newBuildingPlans = [...values.buildingPlans.filter((element: any) => JSON.stringify(element) !== JSON.stringify(values?.buildingPlans[index]))];
    //
    //     setValues({
    //         ...values,
    //         buildingPlans: newBuildingPlans
    //     });
    //
    //     localStorageService.setGlobalBuildingPlan(isValid, newBuildingPlans);
    //
    //     // Suppression photo correspondante  dans local storage
    //     localStorage.removeItem(agentCode + '_image-' + index);
    //
    //     // Rafraichissement de la page
    //     window.location.reload(false);
    // };

    /**
     * Fonction pour mettre à jour un champ dans le local storage ainsi que dans le form
     * @param event (Event provenant de l'input)
     * @param handleChange (Event du formulaire pour mettre à jour une valeur)
     * @param isValid (Event du formulaire pour savoir s'il est valide)
     * @param index (index du tableau)
     * @param propertyName (nom de la propriété)
     * @param setFieldValue (Event du formulaire pour mettre à jour une propriété)
     */
    const updateValueBuildingPlan = (event: any, handleChange: any, isValid: boolean, index: number, propertyName: string, setFieldValue: any) => {
        // Mise à jour du formulaire
        if (!['name', 'orientation', 'base64'].includes(propertyName)) {
            handleChange(event);
        }

        // Mise à jour de la valeur du champ dans le local storage
        if (['shape'].includes(propertyName)) {
            storageService.setLocalStorageValueBuildingPlan(isValid, propertyName, index, event.target.value);
        } else if (propertyName === 'base64') {
            storageService.setLocalStorageValueBuildingPlan(isValid, 'base64', index, event);
        } else if (['name', 'orientation'].includes(propertyName)) {
            storageService.setLocalStorageValueBuildingPlan(isValid, propertyName, index, event.value);
        } else {
            storageService.setLocalStorageValueBuildingPlan(isValid, 'base64', index, '');
            storageService.setLocalStorageValueBuildingPlan(isValid, propertyName, index, buildingPlanService.checkNumber(event.target.value));
        }

        // Mise à jour de la valeur dans Formik
        setFieldValue('buildingPlans', storageService.getAudit().step2.buildingPlans.value);
    };

    const [modalPreviewBuildingPlan, showModalPreviewBuildingPlan] = useState<boolean>(false);

    /**
     * Fonction pour vérifier pour chaque forme les valeurs saisies
     * @param values
     * @param index
     */
    const checkValueBuildingPlan = async (values: any, index: number) => {
        try {
            const buildingPlan = values.buildingPlans[index];

            const tolerance = 0.5;
            let errorsList: string[] = [];

            // Forme L
            if (buildingPlan.shape === '11') {
                errorsList = [
                    ...errorsList,
                    buildingPlan.width < buildingPlanService.checkNumber(buildingPlan.l2) + tolerance
                        ? 'La valeur L2 ne peut pas être supérieur à la largeur totale'
                        : '',
                ];
                errorsList = [
                    ...errorsList,
                    buildingPlan.length < buildingPlanService.checkNumber(buildingPlan.l1) + tolerance
                        ? 'La valeur L1 ne peut pas être supérieur à la longueur totale'
                        : '',
                ];
            }

            // Forme L inversé
            if (buildingPlan.shape === '12') {
                errorsList = [
                    ...errorsList,
                    buildingPlan.length < buildingPlanService.checkNumber(buildingPlan.l1) + tolerance
                        ? 'La valeur L1 ne peut pas être supérieur à la longueur totale'
                        : '',
                ];
                errorsList = [
                    ...errorsList,
                    buildingPlan.width < buildingPlanService.checkNumber(buildingPlan.l2) + tolerance
                        ? 'La valeur L2 ne peut pas être supérieur à la largeur totale'
                        : '',
                ];
            }

            // Forme T
            if (buildingPlan.shape === '21') {
                errorsList = [
                    ...errorsList,
                    buildingPlan.length < buildingPlanService.checkNumber(buildingPlan.l1) + buildingPlanService.checkNumber(buildingPlan.l3) + tolerance
                        ? 'La somme des valeurs L1 et L3 ne peut pas être supérieure à la longueur totale'
                        : '',
                ];
                errorsList = [
                    ...errorsList,
                    buildingPlan.width <
                    Math.max(buildingPlanService.checkNumber(buildingPlan.l2), buildingPlanService.checkNumber(buildingPlan.l4)) + tolerance
                        ? 'Une des valeurs L2 ou L4 ne peut pas être supérieure à la largeur totale'
                        : '',
                ];
            }

            // Forme T inversé
            if (buildingPlan.shape === '22') {
                errorsList = [
                    ...errorsList,
                    buildingPlan.length <
                    Math.max(buildingPlanService.checkNumber(buildingPlan.l1), buildingPlanService.checkNumber(buildingPlan.l3)) + tolerance
                        ? 'Une des valeurs L1 ou L3 ne peut pas être supérieure à la longueur totale'
                        : '',
                ];
                errorsList = [
                    ...errorsList,
                    buildingPlan.width < buildingPlanService.checkNumber(buildingPlan.l2) + buildingPlanService.checkNumber(buildingPlan.l4) + tolerance
                        ? 'Une des valeurs L2 ou L4 ne peut pas être supérieure à la largeur totale'
                        : '',
                ];
            }

            // Forme U
            if (buildingPlan.shape === '31') {
                errorsList = [
                    ...errorsList,
                    buildingPlan.length < buildingPlanService.checkNumber(buildingPlan.l1) + buildingPlanService.checkNumber(buildingPlan.l4) + tolerance
                        ? 'La somme des valeurs L1 et L4 ne peut pas être supérieure à la longueur totale'
                        : '',
                ];
                errorsList = [
                    ...errorsList,
                    buildingPlan.width < buildingPlanService.checkNumber(buildingPlan.l2) + tolerance
                        ? 'La valeur L2 ne peut pas être supérieur à la largeur totale'
                        : '',
                ];
            }

            // Forme U inversé
            if (buildingPlan.shape === '32') {
                errorsList = [
                    ...errorsList,
                    buildingPlan.length < buildingPlanService.checkNumber(buildingPlan.l2) + tolerance
                        ? 'La valeur L2 ne peut pas être supérieur à la longueur totale'
                        : '',
                ];
                errorsList = [
                    ...errorsList,
                    buildingPlan.width < buildingPlanService.checkNumber(buildingPlan.l1) + buildingPlanService.checkNumber(buildingPlan.l4) + tolerance
                        ? 'La somme des valeurs L1 et L4 ne peut pas être supérieure à la largeur totale'
                        : '',
                ];
            }

            // Forme Y
            if (buildingPlan.shape === '41') {
                errorsList = [
                    ...errorsList,
                    buildingPlan.length <
                    Math.max(
                        buildingPlanService.checkNumber(buildingPlan.l2) + buildingPlanService.checkNumber(buildingPlan.l6),
                        buildingPlanService.checkNumber(buildingPlan.l4)
                    ) +
                        tolerance
                        ? 'La somme des valeurs L2 et L6 ou la valeur L4 ne peut pas être supérieure à la longueur totale'
                        : '',
                ];
                errorsList = [
                    ...errorsList,
                    buildingPlan.width <
                    Math.max(
                        buildingPlanService.checkNumber(buildingPlan.l3) + buildingPlanService.checkNumber(buildingPlan.l1),
                        buildingPlanService.checkNumber(buildingPlan.l5)
                    ) +
                        tolerance
                        ? 'La somme des valeurs L1 et L3 ou la valeur L5 ne peut pas être supérieure à la largeur totale'
                        : '',
                ];
            }

            // Forme Y inversé
            if (buildingPlan.shape === '42') {
                errorsList = [
                    ...errorsList,
                    buildingPlan.width <
                    Math.max(
                        buildingPlanService.checkNumber(buildingPlan.l3) + buildingPlanService.checkNumber(buildingPlan.l1),
                        buildingPlanService.checkNumber(buildingPlan.l5)
                    ) +
                        tolerance
                        ? 'La somme des valeurs L1 et L3 ou la valeur L5 ne peut pas être supérieure à la largeur totale'
                        : '',
                ];
                errorsList = [
                    ...errorsList,
                    buildingPlan.length <
                    Math.max(
                        buildingPlanService.checkNumber(buildingPlan.l2) + buildingPlanService.checkNumber(buildingPlan.l6),
                        buildingPlanService.checkNumber(buildingPlan.l4)
                    ) +
                        tolerance
                        ? 'La somme des valeurs L2 et L6 ou la valeur L4 ne peut pas être supérieure à la longueur totale'
                        : '',
                ];
            }

            // Forme X
            if (buildingPlan.shape === '51') {
                errorsList = [
                    ...errorsList,
                    buildingPlan.length <
                    Math.max(
                        buildingPlanService.checkNumber(values.buildingPlans[index].l1) + buildingPlanService.checkNumber(values.buildingPlans[index].l3),
                        buildingPlanService.checkNumber(values.buildingPlans[index].l5) + buildingPlanService.checkNumber(values.buildingPlans[index].l7)
                    ) +
                        tolerance
                        ? 'La somme des valeurs L1 et L3 ou L5 et L7 ne peut pas être supérieure à la longueur totale'
                        : '',
                ];
                errorsList = [
                    ...errorsList,
                    buildingPlan.width <
                    Math.max(
                        buildingPlanService.checkNumber(values.buildingPlans[index].l2) + buildingPlanService.checkNumber(values.buildingPlans[index].l6),
                        buildingPlanService.checkNumber(values.buildingPlans[index].l4) + buildingPlanService.checkNumber(values.buildingPlans[index].l8)
                    ) +
                        tolerance
                        ? 'La somme des valeurs L2 et L6 ou L4 et L8 ne peut pas être supérieure à la largeur totale'
                        : '',
                ];
            }

            // Forme H
            if (buildingPlan.shape === '52') {
                errorsList = [
                    ...errorsList,
                    buildingPlan.length <
                    Math.max(
                        buildingPlanService.checkNumber(buildingPlan.l6) + buildingPlanService.checkNumber(buildingPlan.l5),
                        buildingPlanService.checkNumber(buildingPlan.l4) + buildingPlanService.checkNumber(buildingPlan.l1)
                    ) +
                        tolerance
                        ? 'La somme des valeurs L5 et L6 ou L1 et L4 ne peut pas être supérieure à la longueur totale'
                        : '',
                ];
                errorsList = [
                    ...errorsList,
                    buildingPlan.width < buildingPlanService.checkNumber(buildingPlan.l7) + buildingPlanService.checkNumber(buildingPlan.l2) + tolerance
                        ? 'La somme des valeurs L2 et L7 ne peut pas être supérieure à la largeur totale'
                        : '',
                ];
            }

            // Réduction du tableau pour supprimer les champs vides
            errorsList = errorsList.filter((e) => e);

            if (errorsList.length > 0) {
                throw errorsList;
            }
            return true;
        } catch (errorsList) {
            // Affichage message
            AlertSwal.fire({
                title: `Dimensions incohérentes`,
                //@ts-ignore
                html: `<p class="mb-0">Attention, certaines données saisies sont invalides:<br/><span>${errorsList
                    .toString()
                    .replace(/(,)/g, '<br/>')}</span><br/><i class="blue-dark">Une tolérance de 0.5m est appliquée sur les calculs.</i></p>`,
                cancelButtonText: 'Fermer',
                showConfirmButton: false,
                showCancelButton: true,
                focusCancel: true,
                showClass: {
                    popup: 'errors',
                    backdrop: 'swal2-backdrop-show swal2-backdrop-black',
                },
                customClass: {
                    cancelButton: 'btn btn-primary maxi-min-width',
                },
            });
        }
    };

    /**
     * Fonction pour gérer la désactivation du bouton de génération du plan.
     * @param errors
     */
    const returnErrorsWithoutBase64 = (errors: any) => {
        let newErrors = JSON.parse(JSON.stringify(errors));
        if (newErrors.hasOwnProperty('base64')) {
            delete newErrors.base64;
            return newErrors;
        }
        return newErrors;
    };

    /**
     * Fonction pour prendre une photo puis convertir un canvas en une image et injection dans local storage
     * @param handleChange
     * @param isValid
     * @param index
     * @param setFieldValue
     */
    const takePicture = (handleChange: any, isValid: boolean, index: number, setFieldValue: any) => {
        // définition des cibles
        const element = document.querySelector('.real-photo-' + index) as HTMLElement;
        const area = element.getBoundingClientRect();

        // Prise de la photo
        html2canvas(element, {
            scrollX: 0,
            scrollY: 0,
            width: area.width + 80,
            height: area.height,
            scale: 1,
        }).then((canvas) => {
            // Création du blob
            const canvasToURI = canvas.toDataURL('image/png');
            // Mise à jour de la propriété dans le local storage
            updateValueBuildingPlan(canvasToURI, handleChange, isValid, index, 'base64', setFieldValue);
            // Fermeture modal
            showModalPreviewBuildingPlan(false);
        });
    };

    /**
     * Fonction pour confirmer la suppression de l'image (Autre forme)
     */
    const confirmDeleteImage = async () => {
        return await AlertSwal.fire({
            html: `Confirmez-vous la suppression de cette image ?`,
            width: 550,
            confirmButtonText: 'Supprimer',
            cancelButtonText: 'Annuler',
            showCancelButton: true,
            focusCancel: true,
            customClass: {
                confirmButton: 'btn btn-red-border min-width',
                cancelButton: 'btn btn-secondary min-width',
            },
        }).then((result) => {
            return result.isConfirmed;
        });
    };

    /**
     * Fonction pour confirmer la suppression de l'image (Autre forme)
     */
    const confirmResetBuilding = async () => {
        return await AlertSwal.fire({
            html: `Confirmez-vous la réinitialisation des données de ce plan ?`,
            width: 550,
            title: 'Réinitialiser le plan',
            confirmButtonText: 'Réinitialiser',
            cancelButtonText: 'Annuler',
            showCancelButton: true,
            focusCancel: true,
            customClass: {
                confirmButton: 'btn btn-red min-width',
                cancelButton: 'btn btn-primary min-width',
            },
        }).then((result) => {
            return result.isConfirmed;
        });
    };

    /**
     * Update roofExposureList if DOMTOM
     */
    const domTomRoofExposure = () => {
        let tmp = [...roofExposureList];

        if (domTomCheck(currentZipCode) !== undefined) {
            // Empty list
            tmp = [];

            // Update list with "North" collection in specific order
            tmp.push(
                {
                    label: 'Sud',
                    value: '4',
                },
                {
                    label: 'Sud-Est',
                    value: '5',
                },
                {
                    label: 'Sud-Ouest',
                    value: '6',
                },
                {
                    label: 'Nord',
                    value: '1',
                },
                {
                    label: 'Nord-Est',
                    value: '2',
                },
                {
                    label: 'Nord-Ouest',
                    value: '3',
                },
                {
                    label: 'Est',
                    value: '7',
                },
                {
                    label: 'Ouest',
                    value: '8',
                }
            );
        }

        return tmp;
    };

    const mapRef = useRef<google.maps.Map>(null);

    return (
        <Formik
            onSubmit={() => {}}
            initialValues={initialValues}
            isInitialValid={storageService.getAudit().step2.isValid}
            validateOnMount={true}
            validationSchema={validationSchema}
            enableReinitialize={true}
        >
            {({ values, errors, handleChange, isValid, handleBlur, setFieldValue, setValues, touched, setTouched }) => {
                // Mise à jour validité Formik
                storageService.setAuditStepValidity(2, isValid);
                // Mise à jour de l'étape globale
                if (isValid) {
                    let globalCurrentStep = storageService.getAuditCurrentStep();
                    if (2 === globalCurrentStep) {
                        storageService.setAuditCurrentStep(globalCurrentStep + 1);
                    }
                }

                return (
                    <div className="container">
                        <h1 className="main-title-mini">2. Informations générales de l’habitation</h1>
                        <h2 className="main-subtitle">Dimensions, plan, niveaux…</h2>
                        <div className="card mb-5">
                            <div className="card-title">
                                <IconClientInformations fill="#76ca4f" width={40} height={40} className="icon" />
                                <h2>Caractéristiques de l’habitation</h2>
                            </div>
                            <div className="card-body">
                                <fieldset className="w-100" disabled={auditSent}>
                                    <div className="row mb-3">
                                        <div className="col-12 col-md-4 mb-3 mb-md-0" style={{ zIndex: 10 }}>
                                            <label htmlFor="projectType" className="form-label label-flex">
                                                Type d'habitation<sup>*</sup>
                                            </label>
                                            <Select
                                                defaultValue={renderDefaultValueSelect(filteredProjectTypeList, values.projectType)}
                                                options={filteredProjectTypeList}
                                                isClearable={false}
                                                isSearchable={false}
                                                isMulti={false}
                                                isDisabled={auditSent}
                                                inputId="projectType"
                                                onBlur={handleBlur}
                                                onChange={(event: any) => {
                                                    storageService.setAuditValue(2, isValid, 'projectType', event.value, event.label);

                                                    // Purge des autres champs
                                                    if (event.value !== '2') {
                                                        storageService.removeAuditValue(2, 'wallAreaOnCirculation');
                                                        storageService.removeAuditValue(2, 'circulationType', true);
                                                        setValues({
                                                            ...values,
                                                            projectType: event.value,
                                                            wallAreaOnCirculation: undefined,
                                                            circulationType: '',
                                                        });
                                                    } else {
                                                        setValues({
                                                            ...values,
                                                            projectType: event.value,
                                                        });
                                                    }

                                                    if (event.value === '3') {
                                                        storageService.removeAuditValue(2, 'generalOptionsMain');
                                                        storageService.removeAuditValue(2, 'generalOptionsBathroom');
                                                        storageService.removeAuditValue(2, 'generalOptionsWC');
                                                        storageService.removeAuditValue(2, 'generalOptionsOtherWater');
                                                        storageService.removeAuditValue(2, 'temperatureTarget');
                                                        storageService.removeAuditValue(2, 'buildingPeople');

                                                        setValues({
                                                            ...values,
                                                            generalOptionsMain: '',
                                                            generalOptionsBathroom: '',
                                                            generalOptionsWC: '',
                                                            temperatureTarget: '',
                                                            buildingPeople: '',

                                                            projectType: event.value,
                                                        });
                                                    }

                                                    // Purge des champs non compris dans `Appartement` et `Immeuble Collectif`
                                                    if (event.value === '2' || event.value === '3') {
                                                        // Réinitialiser l'étape 2
                                                        storageService.setAuditValue(2, isValid, 'pool', false);
                                                        storageService.setAuditValue(2, isValid, 'solarMask', false);
                                                        storageService.setAuditValue(2, isValid, 'pergola', false);
                                                        storageService.setAuditValue(2, isValid, 'veranda', false);
                                                        storageService.setAuditValue(2, isValid, 'garageDoor', false);
                                                        storageService.setAuditValue(2, isValid, 'alarm', false);

                                                        storageService.removeAuditValue(2, 'tilt', true);
                                                        storageService.removeAuditValue(2, 'roofExposure', true);
                                                        storageService.removeAuditValue(2, 'foldNumber', true);
                                                        storageService.removeAuditValue(2, 'solarMaskPercent', true);
                                                        storageService.removeAuditValue(2, 'poolHeatType', true);
                                                        storageService.removeAuditValue(2, 'verandaAge', true);
                                                        storageService.removeAuditValue(2, 'pergolaAge', true);

                                                        // Réinitialiser l'étape 3
                                                        if (storageService.getAudit().step3.photovoltaique) {
                                                            // Check if `photovoltaique` exists in step3
                                                            storageService.removeAuditValue(3, 'photovoltaique');
                                                            storageService.removeAuditValue(3, 'photovoltaiquePower', true);
                                                            storageService.removeAuditValue(3, 'photovoltaiqueConsumption', true);
                                                        }

                                                        // Réinitialiser l'étape 6
                                                        storageService.removeAuditValue(6, 'ceilingType', true);
                                                        storageService.removeAuditValue(6, 'floorType', true);
                                                        // Réinitialiser l'étape 8
                                                        storageService.removeAuditValue(8, 'buildingPictureRoofing');

                                                        setValues({
                                                            ...values,
                                                            roofExposure: '',
                                                            tilt: '',
                                                            foldNumber: '',
                                                            solarMask: '',
                                                            solarMaskPercent: '',
                                                            pool: '',
                                                            poolHeat: '',
                                                            poolHeatType: '',
                                                            veranda: '',
                                                            verandaAge: '',
                                                            pergola: '',
                                                            pergolaAge: '',
                                                            garageDoor: '',
                                                            garageDoorMotorized: '',
                                                            portal: '',
                                                            portalMotorized: '',

                                                            projectType: event.value,
                                                        });
                                                    }

                                                    if (event.value !== '3') {
                                                        values.buildingTypeDetails = [
                                                            {
                                                                buildingType: '',
                                                                buildingTypeSurface: '',
                                                                identicalBuildingType: '',
                                                                buildingTypeMainRoom: '',
                                                                buildingTypeBathroom: '',
                                                                buildingTypeBathroom2: '',
                                                                buildingTypeWC: '',
                                                                buildingTypeEmission: '',
                                                                buildingTypeECS: '',
                                                            },
                                                        ];

                                                        setValues({
                                                            ...values,
                                                            buildingTypeDetails: values.buildingTypeDetails,
                                                            projectType: event.value,
                                                        });
                                                        // Mise à jour du local storage
                                                        storageService.setAuditValue(2, isValid, 'buildingTypeDetails', values.buildingTypeDetails);
                                                    }

                                                    // Réinitialisation champs étape 3
                                                    storageService.removeAuditValue(3, 'heaterType', true);
                                                    storageService.removeAuditValue(3, 'heaterSubscription', true);
                                                    storageService.removeAuditValue(3, 'heaterFeature', true);
                                                    storageService.removeAuditValue(3, 'heaterType2', true);
                                                    // Déclaration étape 3 invalide
                                                    storageService.setAuditStepValidity(3, false);
                                                }}
                                                className={
                                                    'basic-single' +
                                                    (values.projectType ? ' filled' : '') +
                                                    (touched.projectType && errors.projectType ? ' invalid' : '') +
                                                    (!values.projectType ? ' required' : '')
                                                }
                                                placeholder={`Choisissez le type d'habitation`}
                                                styles={colourStyles}
                                            />
                                            {touched.projectType && errors.projectType && (
                                                <div id="projectTypeFeedback" className="invalid-feedback">
                                                    {errors.projectType}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12 col-md-4 mb-3 mb-md-0">
                                            <label htmlFor="projectStatus" className="form-label label-flex">
                                                Statut d’occupation<sup>*</sup>
                                            </label>

                                            <Select
                                                defaultValue={renderDefaultValueSelect(projectStatusList, values.projectStatus)}
                                                options={projectStatusList}
                                                isClearable={false}
                                                isSearchable={false}
                                                isMulti={false}
                                                isDisabled={auditSent}
                                                inputId="projectStatus"
                                                onBlur={handleBlur}
                                                onChange={(event) => {
                                                    setValues({
                                                        ...values,
                                                        projectStatus: event.value,
                                                    });
                                                    storageService.setAuditValue(2, isValid, 'projectStatus', event.value, event.label);
                                                }}
                                                className={
                                                    'basic-single' +
                                                    (values.projectStatus ? ' filled' : '') +
                                                    (touched.projectStatus && errors.projectStatus ? ' invalid' : '') +
                                                    (!values.projectStatus ? ' required' : '')
                                                }
                                                placeholder={`Propriétaire`}
                                                styles={colourStyles}
                                            />
                                            {touched.projectStatus && errors.projectStatus && <div className="invalid-feedback">{errors.projectStatus}</div>}
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <CheckboxService
                                                handleCheckbox={principalResidence}
                                                values={values}
                                                isValid={isValid}
                                                setValues={setValues}
                                                name={'principalResidence'}
                                                propName={values.principalResidence}
                                                title={'L’habitation est votre résidence principale ?'}
                                                emptyLabel={true}
                                                border={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12 col-md-4 mb-3 mb-md-0">
                                            <label htmlFor="houseAge" className="form-label">
                                                Année de construction<sup>*</sup>
                                            </label>
                                            <Select
                                                defaultValue={renderDefaultValueSelect(houseAgeList, values.houseAge)}
                                                options={houseAgeList}
                                                isClearable={false}
                                                isSearchable={false}
                                                isMulti={false}
                                                isDisabled={auditSent}
                                                inputId="houseAge"
                                                onBlur={handleBlur}
                                                onChange={(event) => {
                                                    storageService.setAuditValue(2, isValid, 'houseAge', event.value);
                                                    // buildingAge : la maison est-elle vieille ?
                                                    //               la maison est-elle vielle de plus deux ans ?
                                                    // == houseIsOld
                                                    // Valeur par défaut :TRUE, house is old.
                                                    storageService.setAuditValue(2, isValid, 'buildingAge', true);
                                                    setValues({
                                                        ...values,
                                                        houseAge: event.value,
                                                        verandaAge: undefined,
                                                        pergolaAge: undefined,
                                                        buildingAge: true,
                                                    });
                                                    // Si l'année est plus vieille de 3 ans, pas de problèmes
                                                    //    => house is old.
                                                    //    => On laisse la valeur par défaut.
                                                    // si l'année est moins vieille que trois ans, selon le mois, on a un doute, on demande !
                                                    if (event.value > (new Date().getFullYear() - 3).toString()) {
                                                        confirmBuildYear().then((houseIsOld: boolean) => {
                                                            if (!houseIsOld) {
                                                                // Sinon, la maison est récente, on met à jour.
                                                                setFieldValue('buildingAge', false);
                                                                storageService.setAuditValue(2, isValid, 'buildingAge', false);
                                                                // console.log('house is old ? ' + getLocalStorageCurrentAgent().step2.buildingAge.value);
                                                            }
                                                            // Si oui, la maison est vieille, on laisse la valeur par défaut.
                                                            // else console.log('house is old ? ' + getLocalStorageCurrentAgent().step2.buildingAge.value);
                                                        });
                                                    } // else console.log('house is old ? ' + getLocalStorageCurrentAgent().step2.buildingAge.value);

                                                    // Mise à jour champs étape 7 si date antérieure à 1990
                                                    if (parseInt(event.value) > 1990) {
                                                        storageService.setAuditValue(7, false, 'buildingWindows1Before1990', false);
                                                        storageService.setAuditValue(7, false, 'buildingWindows2Before1990', false);
                                                    }

                                                    if (event.value && ['1', '4'].includes(values.projectType) && values.pergola && values.veranda) {
                                                        handleHouseAge(event.value);
                                                    }
                                                }}
                                                className={
                                                    'basic-single' +
                                                    (values.houseAge ? ' filled' : '') +
                                                    (touched.houseAge && errors.houseAge ? ' invalid' : '') +
                                                    (!values.houseAge ? ' required' : '')
                                                }
                                                placeholder={'Choisissez une année'}
                                                styles={colourStyles}
                                            />
                                            {touched.houseAge && errors.houseAge && <div className="invalid-feedback">{errors.houseAge}</div>}
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <label htmlFor="floorCount" className="form-label">
                                                Nombre de niveau <sup>*</sup>
                                                <div className="help-text" data-tip data-for="helpFloorCount">
                                                    <IconHelp fill="#E69736" width={14} height={14} className="icon" />
                                                </div>
                                            </label>
                                            <Select
                                                defaultValue={renderDefaultValueSelect(floorCountList, values.floorCount)}
                                                options={floorCountList}
                                                isClearable={false}
                                                isSearchable={false}
                                                isMulti={false}
                                                isDisabled={auditSent}
                                                inputId="floorCount"
                                                onBlur={handleBlur}
                                                onChange={(event) => {
                                                    setValues({
                                                        ...values,
                                                        floorCount: event.value,
                                                    });
                                                    storageService.setAuditValue(2, isValid, 'floorCount', event.value, event.label);
                                                }}
                                                className={
                                                    'basic-single' +
                                                    (values.floorCount ? ' filled' : '') +
                                                    (touched.floorCount && errors.floorCount ? ' invalid' : '') +
                                                    (!values.floorCount ? ' required' : '')
                                                }
                                                placeholder={`2`}
                                                styles={colourStyles}
                                            />
                                            {touched.floorCount && errors.floorCount && <div className="invalid-feedback">{errors.floorCount}</div>}
                                        </div>
                                        <ReactTooltip id="helpFloorCount" place="bottom" type={'light'} effect={'solid'}>
                                            <h3>Nombre de niveau :</h3>
                                            <p>
                                                Veuillez indiquer le nombre de niveau, sous sol inclus. Ne pas rajouter de niveau pour un comble "non aménagé"
                                            </p>
                                        </ReactTooltip>
                                    </div>
                                    <div className={'row ' + (values.projectType === '2' ? 'mb-3' : 'mb-5')}>
                                        <div className="col-12 col-md-6 mb-3 mb-md-0" style={{ minHeight: '70px' }}>
                                            <CheckboxService
                                                handleCheckbox={handleCheckboxBalcony}
                                                values={values}
                                                isValid={isValid}
                                                setValues={setValues}
                                                name={'balcony'}
                                                propName={values.balcony}
                                                title={"Présence de balcons dans l'habitation ?"}
                                                emptyLabel={true}
                                                border={true}
                                            />
                                        </div>
                                        {values.balcony && (
                                            <div className="col-12 col-md-6">
                                                <label htmlFor="balconyLength" className="form-label label-flex">
                                                    Longueur totale des balcons<sup>*</sup>
                                                    <AddStepPhotos
                                                        prop={buildingPictureBalcony}
                                                        values={values}
                                                        setFieldValue={setFieldValue}
                                                        fieldValue={'buildingPictureBalcony'}
                                                    />
                                                </label>
                                                <div className="input-group">
                                                    <input
                                                        type="number"
                                                        min={1}
                                                        className={
                                                            'form-control' +
                                                            (values.balconyLength ? ' filled' : '') +
                                                            (touched.balconyLength && errors.balconyLength ? ' invalid' : '') +
                                                            (!values.balconyLength ? ' required' : '')
                                                        }
                                                        placeholder="25"
                                                        name="balconyLength"
                                                        id="balconyLength"
                                                        onBlur={handleBlur}
                                                        defaultValue={values.balconyLength}
                                                        onChange={(event) => updateValue(event, handleChange, isValid, 2, 'balconyLength')}
                                                        onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                                    />
                                                    <span className="input-group-text">m</span>
                                                </div>
                                                {touched.balconyLength && errors.balconyLength && (
                                                    <div className="invalid-feedback">{errors.balconyLength}</div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    {values.projectType === '2' && (
                                        <div className="row mb-5">
                                            <div className="col-12 col-md-6 mb-3 mb-md-0">
                                                <label htmlFor="wallAreaOnCirculation" className="form-label">
                                                    Surface de murs de votre appartement donnant sur la circulation
                                                    <br />
                                                    (partie commune, couloir, escalier)
                                                    <sup>*</sup>
                                                </label>
                                                <div className="input-group">
                                                    <input
                                                        type="number"
                                                        min={0}
                                                        className={
                                                            'form-control' +
                                                            (values.wallAreaOnCirculation || values.wallAreaOnCirculation === 0 ? ' filled' : '') +
                                                            (touched.wallAreaOnCirculation && errors.wallAreaOnCirculation ? ' invalid' : '') +
                                                            (!values.wallAreaOnCirculation ? ' required' : '')
                                                        }
                                                        placeholder="25"
                                                        name="wallAreaOnCirculation"
                                                        id="wallAreaOnCirculation"
                                                        onBlur={handleBlur}
                                                        defaultValue={values.wallAreaOnCirculation}
                                                        onChange={(event) => updateValue(event, handleChange, isValid, 2, 'wallAreaOnCirculation')}
                                                        onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                                    />
                                                    <span className="input-group-text">m²</span>
                                                </div>
                                                {touched.wallAreaOnCirculation && errors.wallAreaOnCirculation && (
                                                    <div className="invalid-feedback">{errors.wallAreaOnCirculation}</div>
                                                )}
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <label htmlFor="circulationType" className="form-label">
                                                    <br />
                                                    Type de circulation dans l’immeuble<sup>*</sup>
                                                </label>
                                                <Select
                                                    defaultValue={renderDefaultValueSelect(circulationTypeList, values.circulationType)}
                                                    options={circulationTypeList}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    isMulti={false}
                                                    isDisabled={auditSent}
                                                    inputId="circulationType"
                                                    onBlur={handleBlur}
                                                    onChange={(event) => {
                                                        setValues({
                                                            ...values,
                                                            circulationType: event.value,
                                                        });
                                                        storageService.setAuditValue(2, isValid, 'circulationType', event.value, event.label);
                                                    }}
                                                    className={
                                                        'basic-single' +
                                                        (values.circulationType ? ' filled' : '') +
                                                        (touched.circulationType && errors.circulationType ? ' invalid' : '') +
                                                        (!values.circulationType ? ' required' : '')
                                                    }
                                                    placeholder={`Choisissez le type de circulation`}
                                                    styles={colourStyles}
                                                />
                                                {touched.circulationType && errors.circulationType && (
                                                    <div className="invalid-feedback">{errors.circulationType}</div>
                                                )}
                                            </div>
                                        </div>
                                    )}

                                    <div className="row mb-3">
                                        <div className="col-12 col-lg-6 mb-3 mb-lg-0">
                                            <div className="card without-shadow h-100">
                                                <div className="card-title justify-content-center">
                                                    <h2>Surface hors oeuvre nette (SHON)</h2>
                                                    <div className="help-text" data-tip data-for="helpSHon">
                                                        <IconHelp fill="#E69736" width={14} height={14} className="icon" />
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="d-flex flex-column align-items-center justify-content-between h-100">
                                                        <img src={shon} className="img-fluid mb-3" alt="SHON" style={{ maxWidth: 460 }} />
                                                        <p className="mb-auto">
                                                            <small>
                                                                Le but de la SHON est de se rapprocher de la surface réellement occupée par les usagers. Elle ne
                                                                comprend que les parties closes, couvertes et aménageables, de plus de 1,80m de hauteur de
                                                                plafond.
                                                                <br />
                                                                Attention, l’épaisseur des murs est toujours comprise dans la SHON. On déduit aussi les surfaces
                                                                utilisées pour le stationnement (garage), les sous-sols lorsqu’il n’y a pas d’ouverture
                                                                extérieure ou encore les locaux agricoles.
                                                            </small>
                                                        </p>
                                                        <div className="w-100 mt-3">
                                                            <label htmlFor="SHON" className="form-label">
                                                                SHON (Surface hors oeuvre nette)<sup>*</sup>
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    type="number"
                                                                    min={1}
                                                                    className={
                                                                        'form-control' +
                                                                        (values.SHON ? ' filled' : '') +
                                                                        (touched.SHON && errors.SHON ? ' invalid' : '') +
                                                                        (!values.SHON ? ' required' : '')
                                                                    }
                                                                    placeholder="90"
                                                                    id="SHON"
                                                                    onBlur={handleBlur}
                                                                    defaultValue={values.SHON}
                                                                    onChange={(event) => updateValue(event, handleChange, isValid, 2, 'SHON')}
                                                                    onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                                                />
                                                                <span className="input-group-text">m²</span>
                                                            </div>
                                                            {touched.SHON && errors.SHON && <div className="invalid-feedback">{errors.SHON}</div>}
                                                        </div>
                                                    </div>
                                                    <ReactTooltip id="helpSHon" place="bottom" type={'light'} effect={'solid'}>
                                                        <h3>Shon :</h3>
                                                        <p>
                                                            Surface de plancher de chaque niveau, surfaces suivantes déduites : (Tout ce qui n'est pas
                                                            aménageable) Sous-sols et combles non aménageables, Toitures terrasses, Balcons, Loggias, Surfaces
                                                            non closes du rez-de-chaussée, Garages, Surfaces au sol dont les hauteurs sous plafond sont
                                                            inférieures à 1,80 m
                                                        </p>
                                                    </ReactTooltip>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-lg-6">
                                            <div className="card without-shadow h-100">
                                                <div className="card-title justify-content-center">
                                                    <h2>Surface habitable (SHab)</h2>
                                                    <div className="help-text" data-tip data-for="helpSHab">
                                                        <IconHelp fill="#E69736" width={14} height={14} className="icon" />
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="d-flex flex-column align-items-center justify-content-between h-100">
                                                        <img src={shab} className="img-fluid mb-3" alt="SHAB" style={{ maxWidth: 460 }} />
                                                        <p className="mb-auto">
                                                            <small>
                                                                La surface habitable se rapproche le plus de l’espace réel de vie.
                                                                <br />
                                                                Par rapport à la SHON, on exclut simplement l’épaisseur des murs (mesure dite « au nu intérieur
                                                                ») et des cloisons (murs intérieurs), ainsi que l’embrasure des portes et des fenêtres.
                                                            </small>
                                                        </p>
                                                        <div className="w-100 mt-3">
                                                            <label htmlFor="SHab" className="form-label">
                                                                SHAB (Surface habitable)<sup>*</sup>
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    type="number"
                                                                    min={1}
                                                                    className={
                                                                        'form-control' +
                                                                        (values.SHab ? ' filled' : '') +
                                                                        (touched.SHab && errors.SHab ? ' invalid' : '') +
                                                                        (!values.SHab ? ' required' : '')
                                                                    }
                                                                    placeholder="90"
                                                                    id="SHab"
                                                                    onBlur={handleBlur}
                                                                    defaultValue={values.SHab}
                                                                    onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                                                    onChange={(event) => {
                                                                        updateValue(event, handleChange, isValid, 2, 'SHab');
                                                                        // Réinitialisation champs étape 3
                                                                        storageService.removeAuditValue(3, 'heaterHealWholeHouse');
                                                                        storageService.removeAuditValue(3, 'heaterType2', true);
                                                                        // Déclaration étape 3 invalide
                                                                        storageService.setAuditStepValidity(3, false);
                                                                        // Réinitialisation champ étape 7 (SHabAreaLimit)
                                                                        storageService.removeAuditValue(7, 'SHabAreaLimit');
                                                                        // Déclaration étape 7 invalide
                                                                        storageService.setAuditStepValidity(7, false);
                                                                    }}
                                                                />
                                                                <span className="input-group-text">m²</span>
                                                            </div>
                                                            {touched.SHab && errors.SHab && <div className="invalid-feedback">{errors.SHab}</div>}
                                                        </div>
                                                    </div>

                                                    <ReactTooltip id="helpSHab" place="bottom" type={'light'} effect={'solid'}>
                                                        <h3>Shab :</h3>
                                                        <p>
                                                            Surface (des pièces) habitable, surfaces suivantes déduites : Garage, Véranda, Combles non aménagés,
                                                            Sous-sol, Caves, Garages, Remises, Terrasses, Balcons, Loggias, Murs, cloisons, escaliers, gaines
                                                            techniques, embrasures de portes et de fenêtres.
                                                        </p>
                                                    </ReactTooltip>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div className="card mb-5">
                            <div className="card-title">
                                <div className="d-flex align-items-center justify-content-between w-100">
                                    <div className="d-flex align-items-center justify-content-start">
                                        <IconDraw fill="#76ca4f" width={40} height={40} className="icon" />
                                        <h2>Forme du bâtiment</h2>
                                    </div>
                                    {/*<button
                                        className={'btn btn-green min-width icon-add' + (values?.buildingPlans?.length >= 3 ? ' disabled' : '')}
                                        disabled={values?.buildingPlans?.length >= 3}
                                        onClick={() => {
                                            setFieldValue('buildingPlans', [...values.buildingPlans, shapeList[0].defaultTemplate]);
                                            storageService.setAuditValue(2, isValid, 'buildingPlans', [...values.buildingPlans, shapeList[0].defaultTemplate]);
                                        }}>
                                        Ajouter un niveau
                                    </button>*/}
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="accordion" id="buildingPlans">
                                    {/* gros map de 1200 lignes */}
                                    {values.buildingPlans.map((_: Floor, index: number) => {
                                        return (
                                            <div className="card without-shadow border-grey mb-3 accordion-item" key={index}>
                                                <div className="card-title accordion-card accordion-header">
                                                    <div className="d-flex align-items-center justify-content-between w-100">
                                                        <div className="d-flex align-items-center justify-content-start">
                                                            <IconDraw fill="#76ca4f" width={40} height={40} className="icon" />
                                                            <h2>Plan {/*index + 1*/}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    id={'collapse' + index}
                                                    className={'card-body accordion-card accordion-collapse collapse ' + (index === 0 ? 'show' : '')}
                                                    aria-labelledby={'collapseHeading' + index}
                                                    data-bs-parent="#buildingPlans"
                                                >
                                                    <fieldset disabled={auditSent}>
                                                        <div className="row mb-3">
                                                            <div className="col-12 col-md-6 mb-3 mb-md-0">
                                                                <label htmlFor="planName" className="form-label">
                                                                    Choisissez le niveau du croquis réalisé<sup>*</sup>
                                                                </label>
                                                                <Select
                                                                    value={
                                                                        values.buildingPlans[index].name
                                                                            ? floorList[
                                                                                  buildingPlanService.returnIndexElementIntoElementList(
                                                                                      floorList,
                                                                                      values.buildingPlans[index].name
                                                                                  )
                                                                              ]
                                                                            : ''
                                                                    }
                                                                    options={floorList}
                                                                    isClearable={false}
                                                                    isSearchable={false}
                                                                    isMulti={false}
                                                                    inputId={'name'}
                                                                    isDisabled={auditSent}
                                                                    onBlur={handleBlur}
                                                                    onChange={(event: any) => {
                                                                        // Mise à jour des champs de la propriété BuildingPlans
                                                                        updateValueBuildingPlan(event, handleChange, isValid, index, 'name', setFieldValue);
                                                                    }}
                                                                    className={
                                                                        'basic-single' +
                                                                        (values.buildingPlans[index].name ? ' filled' : '') +
                                                                        ((touched as any)['name'] && !values.buildingPlans[index].name ? ' invalid' : '') +
                                                                        (!values.buildingPlans[index].name ? ' required' : '')
                                                                    }
                                                                    placeholder={'Choisissez un niveau'}
                                                                    styles={colourStyles}
                                                                    components={{
                                                                        Option: singleOption,
                                                                        SingleValue: singleValue,
                                                                    }}
                                                                />
                                                                {(touched as any)['name'] && !values.buildingPlans[index].name && (
                                                                    <div className="invalid-feedback">{(errors as any)?.buildingPlans[index]?.name}</div>
                                                                )}
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3 mb-md-0" style={{ zIndex: 10 }}>
                                                                <label htmlFor="shape" className="form-label">
                                                                    Forme<sup>*</sup>
                                                                </label>
                                                                <Select
                                                                    defaultValue={
                                                                        shapeList[
                                                                            buildingPlanService.returnIndexElementIntoElementList(
                                                                                shapeList,
                                                                                values.buildingPlans[index].shape
                                                                            )
                                                                        ]
                                                                    }
                                                                    options={shapeList}
                                                                    isClearable={false}
                                                                    isSearchable={false}
                                                                    isMulti={false}
                                                                    isDisabled={auditSent}
                                                                    onChange={(event: any) => {
                                                                        // Mise à jour des champs de la propriété BuildingPlans
                                                                        changeBuildingPlan(event, handleChange, isValid, index, setValues, values, setTouched);
                                                                    }}
                                                                    className={'basic-single filled'}
                                                                    placeholder={'Choisissez une forme'}
                                                                    styles={colourStyles}
                                                                    components={{
                                                                        Option: singleOption,
                                                                        SingleValue: singleValue,
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={
                                                                'row ' +
                                                                (values.buildingPlans[0].shape === '1' ||
                                                                values?.buildingPlans[1]?.shape === '1' ||
                                                                values?.buildingPlans[2]?.shape === '1'
                                                                    ? 'mb-3'
                                                                    : 'mb-5')
                                                            }
                                                        >
                                                            <div className="col-12 col-md-6 mb-3 mb-md-0">
                                                                <label htmlFor="hauteur" className="form-label">
                                                                    Hauteur sous plafond<sup>*</sup>
                                                                </label>
                                                                <div className="input-group">
                                                                    <input
                                                                        type="number"
                                                                        step="0.01"
                                                                        value={values.buildingPlans[index].height || ''}
                                                                        className={
                                                                            'form-control' +
                                                                            (values.buildingPlans[index]?.height ? ' filled' : '') +
                                                                            ((touched as any)['hauteur-' + index] && !values.buildingPlans[index]?.height
                                                                                ? ' invalid'
                                                                                : '') +
                                                                            (!values.buildingPlans[index]?.height ? ' required' : '')
                                                                        }
                                                                        placeholder="Hauteur sous plafond"
                                                                        min={0}
                                                                        id={'hauteur-' + index}
                                                                        onChange={(event: any) => {
                                                                            updateValueBuildingPlan(
                                                                                event,
                                                                                handleChange,
                                                                                isValid,
                                                                                index,
                                                                                'height',
                                                                                setFieldValue
                                                                            );
                                                                        }}
                                                                        onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                                                    />
                                                                    <span className="input-group-text">m</span>
                                                                </div>
                                                                {(touched as any)['hauteur-' + index] &&
                                                                    (errors as any)?.buildingPlans?.length > 0 &&
                                                                    !values?.buildingPlans[index]?.height && (
                                                                        <div className="invalid-feedback">{(errors as any)?.buildingPlans[index]?.height}</div>
                                                                    )}
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <label htmlFor="orientation" className="form-label">
                                                                    Orientation<sup>*</sup>
                                                                </label>
                                                                <Select
                                                                    value={
                                                                        values.buildingPlans[index].orientation
                                                                            ? orientationList[
                                                                                  buildingPlanService.returnIndexElementIntoElementList(
                                                                                      orientationList,
                                                                                      values.buildingPlans[index].orientation
                                                                                  )
                                                                              ]
                                                                            : ''
                                                                    }
                                                                    options={orientationList}
                                                                    isClearable={false}
                                                                    isSearchable={false}
                                                                    isDisabled={values.buildingPlans[index].base64 || auditSent}
                                                                    inputId="orientation"
                                                                    isMulti={false}
                                                                    onBlur={handleBlur}
                                                                    onChange={(event: any) => {
                                                                        updateValueBuildingPlan(
                                                                            event,
                                                                            handleChange,
                                                                            isValid,
                                                                            index,
                                                                            'orientation',
                                                                            setFieldValue
                                                                        );
                                                                    }}
                                                                    className={
                                                                        'basic-single' +
                                                                        (values.buildingPlans[index].orientation ? ' filled' : '') +
                                                                        ((touched as any)['orientation'] && !values.buildingPlans[index].orientation
                                                                            ? ' invalid'
                                                                            : '') +
                                                                        (!values.buildingPlans[index].orientation ? ' required' : '')
                                                                    }
                                                                    placeholder={'Choisissez une orientation'}
                                                                    styles={colourStyles}
                                                                    components={{
                                                                        Option: singleOption,
                                                                        SingleValue: singleValue,
                                                                    }}
                                                                />
                                                                {(touched as any)['orientation'] && !values.buildingPlans[index].orientation && (
                                                                    <div className="invalid-feedback">L'orientation est obligatoire</div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {(values.buildingPlans[0].shape === '1' ||
                                                            values?.buildingPlans[1]?.shape === '1' ||
                                                            values?.buildingPlans[2]?.shape === '1') && <div className={'row mb-5'}></div>}
                                                        <div className="mb-3">
                                                            <div className="card without-shadow border-grey">
                                                                <div className="card-body">
                                                                    <div className="row mb-5">
                                                                        <div className="col-12 col-md-6 mb-3 mb-md-0">
                                                                            <div className="row mb-3">
                                                                                <div className="col-12 col-lg-6 mb-3 mb-lg-0">
                                                                                    <label htmlFor="longueur" className="form-label">
                                                                                        Longueur totale (L) du niveau<sup>*</sup>
                                                                                    </label>
                                                                                    <div className="input-group">
                                                                                        <input
                                                                                            type="number"
                                                                                            step={0.1}
                                                                                            value={values?.buildingPlans[index]?.length || ''}
                                                                                            className={
                                                                                                'form-control' +
                                                                                                (values.buildingPlans[index]?.length ? ' filled' : '') +
                                                                                                ((touched as any)['length-' + index] &&
                                                                                                !values.buildingPlans[index]?.length
                                                                                                    ? ' invalid'
                                                                                                    : '') +
                                                                                                (!values.buildingPlans[index]?.length ? ' required' : '')
                                                                                            }
                                                                                            id={'length-' + index}
                                                                                            placeholder="Longueur"
                                                                                            onBlur={handleBlur}
                                                                                            max={150}
                                                                                            min={0.5}
                                                                                            onChange={(event: any) => {
                                                                                                if (event.target.value > 150) {
                                                                                                    event.target.value = 150;
                                                                                                }
                                                                                                updateValueBuildingPlan(
                                                                                                    event,
                                                                                                    handleChange,
                                                                                                    isValid,
                                                                                                    index,
                                                                                                    'length',
                                                                                                    setFieldValue
                                                                                                );
                                                                                                storageService.checkPropertyExistThenCreateOrRender(
                                                                                                    2,
                                                                                                    'buildingLength',
                                                                                                    `Longueur`,
                                                                                                    PropertyType['number']
                                                                                                );
                                                                                                storageService.setAuditValue(
                                                                                                    2,
                                                                                                    isValid,
                                                                                                    'buildingLength',
                                                                                                    parseInt(event.target.value)
                                                                                                );
                                                                                            }}
                                                                                            onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
                                                                                                e.currentTarget.blur()
                                                                                            }
                                                                                        />
                                                                                        <span className="input-group-text">m</span>
                                                                                    </div>
                                                                                    {(touched as any)['length-' + index] &&
                                                                                        (errors as any)?.buildingPlans?.length > 0 &&
                                                                                        !values?.buildingPlans[index]?.length && (
                                                                                            <div className="invalid-feedback">
                                                                                                {(errors as any)?.buildingPlans[index]?.length}
                                                                                            </div>
                                                                                        )}
                                                                                </div>
                                                                                <div className="col-12 col-lg-6">
                                                                                    <label htmlFor="largeur" className="form-label">
                                                                                        Largeur totale (l) du niveau<sup>*</sup>
                                                                                    </label>
                                                                                    <div className="input-group">
                                                                                        <input
                                                                                            type="number"
                                                                                            step={0.1}
                                                                                            value={values?.buildingPlans[index]?.width || ''}
                                                                                            className={
                                                                                                'form-control' +
                                                                                                (values.buildingPlans[index]?.width ? ' filled' : '') +
                                                                                                ((touched as any)['width-' + index] &&
                                                                                                !values.buildingPlans[index]?.width
                                                                                                    ? ' invalid'
                                                                                                    : '') +
                                                                                                (!values.buildingPlans[index]?.width ? ' required' : '')
                                                                                            }
                                                                                            id={'width-' + index}
                                                                                            placeholder="Largueur"
                                                                                            onBlur={handleBlur}
                                                                                            max={150}
                                                                                            min={0.5}
                                                                                            onChange={(event: any) => {
                                                                                                if (event.target.value > 150) {
                                                                                                    event.target.value = 150;
                                                                                                }
                                                                                                updateValueBuildingPlan(
                                                                                                    event,
                                                                                                    handleChange,
                                                                                                    isValid,
                                                                                                    index,
                                                                                                    'width',
                                                                                                    setFieldValue
                                                                                                );
                                                                                                storageService.checkPropertyExistThenCreateOrRender(
                                                                                                    2,
                                                                                                    'buildingWidth',
                                                                                                    `Largeur`,
                                                                                                    PropertyType['number']
                                                                                                );
                                                                                                storageService.setAuditValue(
                                                                                                    2,
                                                                                                    isValid,
                                                                                                    'buildingWidth',
                                                                                                    parseInt(event.target.value)
                                                                                                );
                                                                                            }}
                                                                                            onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
                                                                                                e.currentTarget.blur()
                                                                                            }
                                                                                        />
                                                                                        <span className="input-group-text">m</span>
                                                                                    </div>
                                                                                    {(touched as any)['width-' + index] &&
                                                                                        (errors as any)?.buildingPlans?.length > 0 &&
                                                                                        !values?.buildingPlans[index]?.width && (
                                                                                            <div className="invalid-feedback">
                                                                                                {(errors as any)?.buildingPlans[index]?.width}
                                                                                            </div>
                                                                                        )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                {values.buildingPlans[index].hasOwnProperty('l1') && (
                                                                                    <div
                                                                                        className={
                                                                                            'col-6 mb-3' +
                                                                                            (values.buildingPlans[index].shape === '32' ? ' order-1' : '') +
                                                                                            (['41', '42'].includes(values.buildingPlans[index].shape)
                                                                                                ? ' order-1'
                                                                                                : '') +
                                                                                            (values.buildingPlans[index].shape === '52' ? ' order-0' : '')
                                                                                        }
                                                                                    >
                                                                                        <label htmlFor={'l1-' + index} className="form-label">
                                                                                            L1<sup>*</sup>
                                                                                        </label>
                                                                                        <div className="input-group">
                                                                                            <input
                                                                                                type="number"
                                                                                                step={0.1}
                                                                                                value={values?.buildingPlans[index]?.l1 || ''}
                                                                                                className={
                                                                                                    'form-control' +
                                                                                                    (values.buildingPlans[index]?.l1 ? ' filled' : '') +
                                                                                                    ((touched as any)['l1-' + index] &&
                                                                                                    !values.buildingPlans[index]?.l1
                                                                                                        ? ' invalid'
                                                                                                        : '') +
                                                                                                    (!values.buildingPlans[index]?.l1 ? ' required' : '')
                                                                                                }
                                                                                                id={'l1-' + index}
                                                                                                placeholder="L1"
                                                                                                onBlur={handleBlur}
                                                                                                max={999}
                                                                                                min={0.5}
                                                                                                disabled={
                                                                                                    !(
                                                                                                        values?.buildingPlans[index]?.width &&
                                                                                                        values?.buildingPlans[index]?.length
                                                                                                    )
                                                                                                }
                                                                                                onChange={(event: any) => {
                                                                                                    updateValueBuildingPlan(
                                                                                                        event,
                                                                                                        handleChange,
                                                                                                        isValid,
                                                                                                        index,
                                                                                                        'l1',
                                                                                                        setFieldValue
                                                                                                    );
                                                                                                }}
                                                                                                onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
                                                                                                    e.currentTarget.blur()
                                                                                                }
                                                                                            />
                                                                                            <span className="input-group-text">m</span>
                                                                                        </div>
                                                                                        {(touched as any)['l1-' + index] &&
                                                                                            (errors as any)?.buildingPlans?.length > 0 &&
                                                                                            !values?.buildingPlans[index]?.l1 &&
                                                                                            values.buildingPlans[index]?.width &&
                                                                                            values.buildingPlans[index]?.length && (
                                                                                                <div className="invalid-feedback">
                                                                                                    {(errors as any)?.buildingPlans[index]?.l1}
                                                                                                </div>
                                                                                            )}
                                                                                    </div>
                                                                                )}
                                                                                {values.buildingPlans[index].hasOwnProperty('l2') && (
                                                                                    <div
                                                                                        className={
                                                                                            'col-6 mb-3' +
                                                                                            (values.buildingPlans[index].shape === '32' ? ' order-0' : '') +
                                                                                            (['41', '42'].includes(values.buildingPlans[index].shape)
                                                                                                ? ' order-0'
                                                                                                : '') +
                                                                                            (values.buildingPlans[index].shape === '52' ? ' order-1' : '')
                                                                                        }
                                                                                    >
                                                                                        <label htmlFor={'l2-' + index} className="form-label">
                                                                                            L2<sup>*</sup>
                                                                                        </label>
                                                                                        <div className="input-group">
                                                                                            <input
                                                                                                type="number"
                                                                                                step={0.1}
                                                                                                value={values?.buildingPlans[index]?.l2 || ''}
                                                                                                className={
                                                                                                    'form-control' +
                                                                                                    (values.buildingPlans[index]?.l2 ? ' filled' : '') +
                                                                                                    ((touched as any)['l2-' + index] &&
                                                                                                    !values.buildingPlans[index]?.l2
                                                                                                        ? ' invalid'
                                                                                                        : '') +
                                                                                                    (!values.buildingPlans[index]?.l2 ? ' required' : '')
                                                                                                }
                                                                                                id={'l2-' + index}
                                                                                                placeholder="L2"
                                                                                                onBlur={handleBlur}
                                                                                                max={999}
                                                                                                min={0.5}
                                                                                                disabled={
                                                                                                    !(
                                                                                                        values?.buildingPlans[index]?.width &&
                                                                                                        values?.buildingPlans[index]?.length
                                                                                                    )
                                                                                                }
                                                                                                onChange={(event: any) => {
                                                                                                    updateValueBuildingPlan(
                                                                                                        event,
                                                                                                        handleChange,
                                                                                                        isValid,
                                                                                                        index,
                                                                                                        'l2',
                                                                                                        setFieldValue
                                                                                                    );
                                                                                                }}
                                                                                                onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
                                                                                                    e.currentTarget.blur()
                                                                                                }
                                                                                            />
                                                                                            <span className="input-group-text">m</span>
                                                                                        </div>
                                                                                        {(touched as any)['l2-' + index] &&
                                                                                            (errors as any)?.buildingPlans?.length > 0 &&
                                                                                            !values?.buildingPlans[index]?.l2 &&
                                                                                            values.buildingPlans[index]?.width &&
                                                                                            values.buildingPlans[index]?.length && (
                                                                                                <div className="invalid-feedback">
                                                                                                    {(errors as any)?.buildingPlans[index]?.l2}
                                                                                                </div>
                                                                                            )}
                                                                                    </div>
                                                                                )}
                                                                                {values.buildingPlans[index].hasOwnProperty('l3') && (
                                                                                    <div
                                                                                        className={
                                                                                            'col-6 mb-3' +
                                                                                            (['41', '42'].includes(values.buildingPlans[index].shape)
                                                                                                ? ' order-3'
                                                                                                : '')
                                                                                        }
                                                                                    >
                                                                                        <label htmlFor={'l3-' + index} className="form-label">
                                                                                            L3<sup>*</sup>
                                                                                        </label>
                                                                                        <div className="input-group">
                                                                                            <input
                                                                                                type="number"
                                                                                                step={0.1}
                                                                                                value={values?.buildingPlans[index]?.l3 || ''}
                                                                                                className={
                                                                                                    'form-control' +
                                                                                                    (values.buildingPlans[index]?.l3 ? ' filled' : '') +
                                                                                                    ((touched as any)['l3-' + index] &&
                                                                                                    !values.buildingPlans[index]?.l3
                                                                                                        ? ' invalid'
                                                                                                        : '') +
                                                                                                    (!values.buildingPlans[index]?.l3 ? ' required' : '')
                                                                                                }
                                                                                                id={'l3-' + index}
                                                                                                placeholder="L3"
                                                                                                onBlur={handleBlur}
                                                                                                max={999}
                                                                                                min={0.5}
                                                                                                disabled={
                                                                                                    !(
                                                                                                        values?.buildingPlans[index]?.width &&
                                                                                                        values?.buildingPlans[index]?.length
                                                                                                    )
                                                                                                }
                                                                                                onChange={(event: any) => {
                                                                                                    updateValueBuildingPlan(
                                                                                                        event,
                                                                                                        handleChange,
                                                                                                        isValid,
                                                                                                        index,
                                                                                                        'l3',
                                                                                                        setFieldValue
                                                                                                    );
                                                                                                }}
                                                                                                onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
                                                                                                    e.currentTarget.blur()
                                                                                                }
                                                                                            />
                                                                                            <span className="input-group-text">m</span>
                                                                                        </div>
                                                                                        {(touched as any)['l3-' + index] &&
                                                                                            (errors as any)?.buildingPlans?.length > 0 &&
                                                                                            !values?.buildingPlans[index]?.l3 &&
                                                                                            values.buildingPlans[index]?.width &&
                                                                                            values.buildingPlans[index]?.length && (
                                                                                                <div className="invalid-feedback">
                                                                                                    {(errors as any)?.buildingPlans[index]?.l3}
                                                                                                </div>
                                                                                            )}
                                                                                    </div>
                                                                                )}
                                                                                {values.buildingPlans[index].hasOwnProperty('l4') && (
                                                                                    <div
                                                                                        className={
                                                                                            'col-6 mb-3' +
                                                                                            (values.buildingPlans[index].shape === '32'
                                                                                                ? ' offset-6 order-2'
                                                                                                : '') +
                                                                                            (['41', '42'].includes(values.buildingPlans[index].shape)
                                                                                                ? ' order-2'
                                                                                                : '') +
                                                                                            (values.buildingPlans[index].shape === '52' ? ' order-2' : '')
                                                                                        }
                                                                                    >
                                                                                        <label htmlFor={'l4-' + index} className="form-label">
                                                                                            L4<sup>*</sup>
                                                                                        </label>
                                                                                        <div className="input-group">
                                                                                            <input
                                                                                                type="number"
                                                                                                step={0.1}
                                                                                                value={values?.buildingPlans[index]?.l4 || ''}
                                                                                                className={
                                                                                                    'form-control' +
                                                                                                    (values.buildingPlans[index]?.l4 ? ' filled' : '') +
                                                                                                    ((touched as any)['l4-' + index] &&
                                                                                                    !values.buildingPlans[index]?.l4
                                                                                                        ? ' invalid'
                                                                                                        : '') +
                                                                                                    (!values.buildingPlans[index]?.l4 ? ' required' : '')
                                                                                                }
                                                                                                id={'l4-' + index}
                                                                                                placeholder="L4"
                                                                                                onBlur={handleBlur}
                                                                                                max={999}
                                                                                                min={0.5}
                                                                                                disabled={
                                                                                                    !(
                                                                                                        values?.buildingPlans[index]?.width &&
                                                                                                        values?.buildingPlans[index]?.length
                                                                                                    )
                                                                                                }
                                                                                                onChange={(event: any) => {
                                                                                                    updateValueBuildingPlan(
                                                                                                        event,
                                                                                                        handleChange,
                                                                                                        isValid,
                                                                                                        index,
                                                                                                        'l4',
                                                                                                        setFieldValue
                                                                                                    );
                                                                                                }}
                                                                                                onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
                                                                                                    e.currentTarget.blur()
                                                                                                }
                                                                                            />
                                                                                            <span className="input-group-text">m</span>
                                                                                        </div>
                                                                                        {(touched as any)['l4-' + index] &&
                                                                                            (errors as any)?.buildingPlans?.length > 0 &&
                                                                                            !values?.buildingPlans[index]?.l4 &&
                                                                                            values.buildingPlans[index]?.width &&
                                                                                            values.buildingPlans[index]?.length && (
                                                                                                <div className="invalid-feedback">
                                                                                                    {(errors as any)?.buildingPlans[index]?.l4}
                                                                                                </div>
                                                                                            )}
                                                                                    </div>
                                                                                )}
                                                                                {values.buildingPlans[index].hasOwnProperty('l5') && (
                                                                                    <div
                                                                                        className={
                                                                                            'col-6 mb-3' +
                                                                                            (['41', '42'].includes(values.buildingPlans[index].shape)
                                                                                                ? ' order-5'
                                                                                                : '') +
                                                                                            (values.buildingPlans[index].shape === '52' ? ' order-4' : '')
                                                                                        }
                                                                                    >
                                                                                        <label htmlFor={'l5-' + index} className="form-label">
                                                                                            L5<sup>*</sup>
                                                                                        </label>
                                                                                        <div className="input-group">
                                                                                            <input
                                                                                                type="number"
                                                                                                step={0.1}
                                                                                                value={values?.buildingPlans[index]?.l5 || ''}
                                                                                                className={
                                                                                                    'form-control' +
                                                                                                    (values.buildingPlans[index]?.l5 ? ' filled' : '') +
                                                                                                    ((touched as any)['l5-' + index] &&
                                                                                                    !values.buildingPlans[index]?.l5
                                                                                                        ? ' invalid'
                                                                                                        : '') +
                                                                                                    (!values.buildingPlans[index]?.l5 ? ' required' : '')
                                                                                                }
                                                                                                id={'l5-' + index}
                                                                                                placeholder="L5"
                                                                                                onBlur={handleBlur}
                                                                                                max={999}
                                                                                                min={0.5}
                                                                                                disabled={
                                                                                                    !(
                                                                                                        values?.buildingPlans[index]?.width &&
                                                                                                        values?.buildingPlans[index]?.length
                                                                                                    )
                                                                                                }
                                                                                                onChange={(event: any) => {
                                                                                                    updateValueBuildingPlan(
                                                                                                        event,
                                                                                                        handleChange,
                                                                                                        isValid,
                                                                                                        index,
                                                                                                        'l5',
                                                                                                        setFieldValue
                                                                                                    );
                                                                                                }}
                                                                                                onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
                                                                                                    e.currentTarget.blur()
                                                                                                }
                                                                                            />
                                                                                            <span className="input-group-text">m</span>
                                                                                        </div>
                                                                                        {(touched as any)['l5-' + index] &&
                                                                                            (errors as any)?.buildingPlans?.length > 0 &&
                                                                                            !values?.buildingPlans[index]?.l5 &&
                                                                                            values.buildingPlans[index]?.width &&
                                                                                            values.buildingPlans[index]?.length && (
                                                                                                <div className="invalid-feedback">
                                                                                                    {(errors as any)?.buildingPlans[index]?.l5}
                                                                                                </div>
                                                                                            )}
                                                                                    </div>
                                                                                )}
                                                                                {values.buildingPlans[index].shape === '52' && (
                                                                                    <div className={'w-100 order-5'} style={{ height: 1 }}>
                                                                                        &nbsp;
                                                                                    </div>
                                                                                )}
                                                                                {values.buildingPlans[index].hasOwnProperty('l6') && (
                                                                                    <div
                                                                                        className={
                                                                                            'col-6 mb-3' +
                                                                                            (['41', '42'].includes(values.buildingPlans[index].shape)
                                                                                                ? ' order-4'
                                                                                                : '') +
                                                                                            (values.buildingPlans[index].shape === '52' ? ' order-5' : '')
                                                                                        }
                                                                                    >
                                                                                        <label htmlFor={'l6-' + index} className="form-label">
                                                                                            L6<sup>*</sup>
                                                                                        </label>
                                                                                        <div className="input-group">
                                                                                            <input
                                                                                                type="number"
                                                                                                step={0.1}
                                                                                                value={values?.buildingPlans[index]?.l6 || ''}
                                                                                                className={
                                                                                                    'form-control' +
                                                                                                    (values.buildingPlans[index]?.l6 ? ' filled' : '') +
                                                                                                    ((touched as any)['l6-' + index] &&
                                                                                                    !values.buildingPlans[index]?.l6
                                                                                                        ? ' invalid'
                                                                                                        : '') +
                                                                                                    (!values.buildingPlans[index]?.l6 ? ' required' : '')
                                                                                                }
                                                                                                id={'l6-' + index}
                                                                                                placeholder="L6"
                                                                                                onBlur={handleBlur}
                                                                                                max={999}
                                                                                                min={0.5}
                                                                                                disabled={
                                                                                                    !(
                                                                                                        values?.buildingPlans[index]?.width &&
                                                                                                        values?.buildingPlans[index]?.length
                                                                                                    )
                                                                                                }
                                                                                                onChange={(event: any) => {
                                                                                                    updateValueBuildingPlan(
                                                                                                        event,
                                                                                                        handleChange,
                                                                                                        isValid,
                                                                                                        index,
                                                                                                        'l6',
                                                                                                        setFieldValue
                                                                                                    );
                                                                                                }}
                                                                                                onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
                                                                                                    e.currentTarget.blur()
                                                                                                }
                                                                                            />
                                                                                            <span className="input-group-text">m</span>
                                                                                        </div>
                                                                                        {(touched as any)['l6-' + index] &&
                                                                                            (errors as any)?.buildingPlans?.length > 0 &&
                                                                                            !values?.buildingPlans[index]?.l6 &&
                                                                                            values.buildingPlans[index]?.width &&
                                                                                            values.buildingPlans[index]?.length && (
                                                                                                <div className="invalid-feedback">
                                                                                                    {(errors as any)?.buildingPlans[index]?.l6}
                                                                                                </div>
                                                                                            )}
                                                                                    </div>
                                                                                )}
                                                                                {values.buildingPlans[index].hasOwnProperty('l7') && (
                                                                                    <div
                                                                                        className={
                                                                                            'col-6 mb-3' +
                                                                                            (values.buildingPlans[index].shape === '52' ? ' order-3' : '')
                                                                                        }
                                                                                    >
                                                                                        <label htmlFor={'l7-' + index} className="form-label">
                                                                                            L7<sup>*</sup>
                                                                                        </label>
                                                                                        <div className="input-group">
                                                                                            <input
                                                                                                type="number"
                                                                                                step={0.1}
                                                                                                value={values?.buildingPlans[index]?.l7 || ''}
                                                                                                className={
                                                                                                    'form-control' +
                                                                                                    (values.buildingPlans[index]?.l7 ? ' filled' : '') +
                                                                                                    ((touched as any)['l7-' + index] &&
                                                                                                    !values.buildingPlans[index]?.l7
                                                                                                        ? ' invalid'
                                                                                                        : '') +
                                                                                                    (!values.buildingPlans[index]?.l7 ? ' required' : '')
                                                                                                }
                                                                                                id={'l7-' + index}
                                                                                                placeholder="L7"
                                                                                                onBlur={handleBlur}
                                                                                                max={999}
                                                                                                min={0.5}
                                                                                                disabled={
                                                                                                    !(
                                                                                                        values?.buildingPlans[index]?.width &&
                                                                                                        values?.buildingPlans[index]?.length
                                                                                                    )
                                                                                                }
                                                                                                onChange={(event: any) => {
                                                                                                    updateValueBuildingPlan(
                                                                                                        event,
                                                                                                        handleChange,
                                                                                                        isValid,
                                                                                                        index,
                                                                                                        'l7',
                                                                                                        setFieldValue
                                                                                                    );
                                                                                                }}
                                                                                                onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
                                                                                                    e.currentTarget.blur()
                                                                                                }
                                                                                            />
                                                                                            <span className="input-group-text">m</span>
                                                                                        </div>
                                                                                        {(touched as any)['l7-' + index] &&
                                                                                            (errors as any)?.buildingPlans?.length > 0 &&
                                                                                            !values?.buildingPlans[index]?.l7 &&
                                                                                            values.buildingPlans[index]?.width &&
                                                                                            values.buildingPlans[index]?.length && (
                                                                                                <div className="invalid-feedback">
                                                                                                    {(errors as any)?.buildingPlans[index]?.l7}
                                                                                                </div>
                                                                                            )}
                                                                                    </div>
                                                                                )}
                                                                                {values.buildingPlans[index].hasOwnProperty('l8') && (
                                                                                    <div className={'col-6 mb-3'}>
                                                                                        <label htmlFor={'l8-' + index} className="form-label">
                                                                                            L8<sup>*</sup>
                                                                                        </label>
                                                                                        <div className="input-group">
                                                                                            <input
                                                                                                type="number"
                                                                                                step={0.1}
                                                                                                value={values?.buildingPlans[index]?.l8 || ''}
                                                                                                className={
                                                                                                    'form-control' +
                                                                                                    (values.buildingPlans[index]?.l8 ? ' filled' : '') +
                                                                                                    ((touched as any)['l8-' + index] &&
                                                                                                    !values.buildingPlans[index]?.l8
                                                                                                        ? ' invalid'
                                                                                                        : '') +
                                                                                                    (!values.buildingPlans[index]?.l8 ? ' required' : '')
                                                                                                }
                                                                                                id={'l8-' + index}
                                                                                                placeholder="L8"
                                                                                                onBlur={handleBlur}
                                                                                                max={999}
                                                                                                min={0.5}
                                                                                                disabled={
                                                                                                    !(
                                                                                                        values?.buildingPlans[index]?.width &&
                                                                                                        values?.buildingPlans[index]?.length
                                                                                                    )
                                                                                                }
                                                                                                onChange={(event: any) => {
                                                                                                    updateValueBuildingPlan(
                                                                                                        event,
                                                                                                        handleChange,
                                                                                                        isValid,
                                                                                                        index,
                                                                                                        'l8',
                                                                                                        setFieldValue
                                                                                                    );
                                                                                                }}
                                                                                                onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
                                                                                                    e.currentTarget.blur()
                                                                                                }
                                                                                            />
                                                                                            <span className="input-group-text">m</span>
                                                                                        </div>
                                                                                        {(touched as any)['l8-' + index] &&
                                                                                            (errors as any)?.buildingPlans?.length > 0 &&
                                                                                            !values?.buildingPlans[index]?.l8 &&
                                                                                            values.buildingPlans[index]?.width &&
                                                                                            values.buildingPlans[index]?.length && (
                                                                                                <div className="invalid-feedback">
                                                                                                    {(errors as any)?.buildingPlans[index]?.l8}
                                                                                                </div>
                                                                                            )}
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-md-6">
                                                                            {values?.buildingPlans[index]?.shape !== '99' && (
                                                                                <div>
                                                                                    {!values.buildingPlans[index].base64 && (
                                                                                        <div className={'position-relative'}>
                                                                                            {values.buildingPlans[index]['orientation'] && (
                                                                                                <h3 className="title-orientation">
                                                                                                    Façade orientée{' '}
                                                                                                    {
                                                                                                        orientationList.find(
                                                                                                            ({ value }) =>
                                                                                                                value ===
                                                                                                                values.buildingPlans[index]['orientation']
                                                                                                        )?.label
                                                                                                    }
                                                                                                </h3>
                                                                                            )}
                                                                                            <div
                                                                                                id={'compass'}
                                                                                                className={
                                                                                                    values.buildingPlans[index]['orientation'] +
                                                                                                    ' mini' +
                                                                                                    (!values.buildingPlans[index]['orientation']
                                                                                                        ? ' d-none'
                                                                                                        : '')
                                                                                                }
                                                                                            ></div>
                                                                                            <img
                                                                                                src={
                                                                                                    shapeList[
                                                                                                        buildingPlanService.returnIndexElementIntoElementList(
                                                                                                            shapeList,
                                                                                                            values.buildingPlans[index].shape
                                                                                                        )
                                                                                                    ]?.preview
                                                                                                }
                                                                                                className="w-100 img-fluid p-md-4"
                                                                                                alt="preview"
                                                                                                style={{ maxHeight: 500 }}
                                                                                            />
                                                                                        </div>
                                                                                    )}
                                                                                    {values.buildingPlans[index].base64 && (
                                                                                        <div className={'position-relative'}>
                                                                                            {values.buildingPlans[index]['orientation'] && (
                                                                                                <h3 className="title-orientation">
                                                                                                    Façade orientée{' '}
                                                                                                    {
                                                                                                        orientationList.find(
                                                                                                            ({ value }) =>
                                                                                                                value ===
                                                                                                                values.buildingPlans[index]['orientation']
                                                                                                        )?.label
                                                                                                    }
                                                                                                </h3>
                                                                                            )}
                                                                                            <div
                                                                                                id={'compass'}
                                                                                                className={values.buildingPlans[index]['orientation'] + ' mini'}
                                                                                            ></div>
                                                                                            <div
                                                                                                id={'screen'}
                                                                                                className={'mini'}
                                                                                                ref={screenRef}
                                                                                                onClick={() => showModalPreviewBuildingPlan(true)}
                                                                                            >
                                                                                                {buildingPlanService.RenderShape(
                                                                                                    screenWidth === 0 ? 500 : screenWidth,
                                                                                                    screenHeight === 0 ? 500 : screenHeight,
                                                                                                    values.buildingPlans[index]['shape'],
                                                                                                    values.buildingPlans[index]['width'],
                                                                                                    values.buildingPlans[index]['length'],
                                                                                                    values.buildingPlans[index]['l1']
                                                                                                        ? values.buildingPlans[index]['l1']
                                                                                                        : null,
                                                                                                    values.buildingPlans[index]['l2']
                                                                                                        ? values.buildingPlans[index]['l2']
                                                                                                        : null,
                                                                                                    values.buildingPlans[index]['l3']
                                                                                                        ? values.buildingPlans[index]['l3']
                                                                                                        : null,
                                                                                                    values.buildingPlans[index]['l4']
                                                                                                        ? values.buildingPlans[index]['l4']
                                                                                                        : null,
                                                                                                    values.buildingPlans[index]['l5']
                                                                                                        ? values.buildingPlans[index]['l5']
                                                                                                        : null,
                                                                                                    values.buildingPlans[index]['l6']
                                                                                                        ? values.buildingPlans[index]['l6']
                                                                                                        : null,
                                                                                                    values.buildingPlans[index]['l7']
                                                                                                        ? values.buildingPlans[index]['l7']
                                                                                                        : null,
                                                                                                    values.buildingPlans[index]['l8']
                                                                                                        ? values.buildingPlans[index]['l8']
                                                                                                        : null
                                                                                                )}
                                                                                                <span>Cliquer pour agrandir</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                            {values?.buildingPlans[index]?.shape === '99' && (
                                                                                <div
                                                                                    id="upload-custom-file"
                                                                                    className={
                                                                                        (values?.buildingPlans[index]?.base64 ? 'filled' : '') +
                                                                                        (!values?.buildingPlans[index]?.base64 ? 'required' : '')
                                                                                    }
                                                                                >
                                                                                    <input
                                                                                        type="file"
                                                                                        ref={
                                                                                            index === 0
                                                                                                ? buildingPlanFile0
                                                                                                : index === 1
                                                                                                ? buildingPlanFile1
                                                                                                : buildingPlanFile2
                                                                                        }
                                                                                        onChange={(event) =>
                                                                                            fileClick(
                                                                                                event,
                                                                                                index,
                                                                                                handleChange,
                                                                                                values,
                                                                                                setFieldValue,
                                                                                                isValid
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    <div
                                                                                        onClick={() =>
                                                                                            openInputFile(
                                                                                                index === 0
                                                                                                    ? buildingPlanFile0
                                                                                                    : index === 1
                                                                                                    ? buildingPlanFile1
                                                                                                    : buildingPlanFile2
                                                                                            )
                                                                                        }
                                                                                        className={
                                                                                            'preview' +
                                                                                            (values?.buildingPlans[index]?.base64.includes('application/pdf')
                                                                                                ? ' preview-pdf'
                                                                                                : '')
                                                                                        }
                                                                                        style={{
                                                                                            backgroundImage:
                                                                                                values?.buildingPlans[index]?.base64 !== ''
                                                                                                    ? 'url(' + values?.buildingPlans[index]?.base64 + ')'
                                                                                                    : '',
                                                                                        }}
                                                                                        onDragOver={dragOver}
                                                                                        onDragEnter={dragEnter}
                                                                                        onDragLeave={dragLeave}
                                                                                        onDrop={(event) =>
                                                                                            fileDrop(event, index, handleChange, values, setFieldValue, isValid)
                                                                                        }
                                                                                    >
                                                                                        {values?.buildingPlans[index]?.base64 === '' && (
                                                                                            <div id="drop-here">
                                                                                                <img src={dropHere} className="img-fluid" alt="drop-here" />
                                                                                                <span>
                                                                                                    Cliquer ou glisser-déposer pour ajouter une image/PDF
                                                                                                </span>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                    {values?.buildingPlans[index]?.base64 !== '' && (
                                                                                        <button
                                                                                            className="icon-remove"
                                                                                            type={'button'}
                                                                                            onClick={() => {
                                                                                                confirmDeleteImage().then((result) => {
                                                                                                    if (result) {
                                                                                                        // Mise à jour de la propriété dans le local storage
                                                                                                        updateValueBuildingPlan(
                                                                                                            '',
                                                                                                            handleChange,
                                                                                                            isValid,
                                                                                                            index,
                                                                                                            'base64',
                                                                                                            setFieldValue
                                                                                                        );
                                                                                                        // Mise à jour de la valeur dans Formik
                                                                                                        setFieldValue(
                                                                                                            'buildingPlans',
                                                                                                            storageService.getAudit().step2.buildingPlans.value
                                                                                                        );
                                                                                                        storageService.setAuditStepValidity(2, false);
                                                                                                    }
                                                                                                });
                                                                                            }}
                                                                                        >
                                                                                            &nbsp;
                                                                                        </button>
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="navigation text-center">
                                                                        <button
                                                                            className={'btn btn-red-border min-width mx-2 mb-3 mb-md-0'}
                                                                            type="button"
                                                                            onClick={() => {
                                                                                confirmResetBuilding().then((result) => {
                                                                                    if (result) {
                                                                                        changeBuildingPlan(
                                                                                            shapeList[
                                                                                                buildingPlanService.returnIndexElementIntoElementList(
                                                                                                    shapeList,
                                                                                                    values.buildingPlans[index].shape
                                                                                                )
                                                                                            ],
                                                                                            handleChange,
                                                                                            isValid,
                                                                                            index,
                                                                                            setValues,
                                                                                            values,
                                                                                            setTouched
                                                                                        );
                                                                                        storageService.setAuditValue(7, false, 'buildingWindows1details', {
                                                                                            tmp: { count: null, surface: null },
                                                                                        });
                                                                                        storageService.setAuditValue(7, false, 'buildingWindows2details', {});
                                                                                        storageService.setAuditValue(
                                                                                            7,
                                                                                            false,
                                                                                            'buildingDoorWindows1details',
                                                                                            {}
                                                                                        );
                                                                                        storageService.setAuditValue(2, isValid, 'buildingLength', null);
                                                                                        storageService.setAuditValue(2, isValid, 'buildingWidth', null);
                                                                                    }
                                                                                });
                                                                            }}
                                                                        >
                                                                            Réinitialiser
                                                                        </button>
                                                                        {values?.buildingPlans[index]?.shape !== '99' && (
                                                                            <button
                                                                                type="button"
                                                                                disabled={
                                                                                    (errors as any)?.buildingPlans &&
                                                                                    Object.entries(
                                                                                        returnErrorsWithoutBase64((errors as any)?.buildingPlans[index])
                                                                                    )?.length > 0 &&
                                                                                    !values?.buildingPlans[index]?.orientation
                                                                                }
                                                                                onClick={() => {
                                                                                    checkValueBuildingPlan(values, index).then((result: any) => {
                                                                                        if (result) {
                                                                                            takePicture(handleChange, isValid, index, setFieldValue);
                                                                                        }
                                                                                    });
                                                                                }}
                                                                                className={
                                                                                    'btn btn-green-darker min-width mx-2 mb-3 mb-md-0' +
                                                                                    (values?.buildingPlans[index]?.base64 ? ' disabled' : '')
                                                                                }
                                                                            >
                                                                                Générer le plan
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                    {modalPreviewBuildingPlan && <div className="modal-backdrop fade show">&nbsp;</div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                    <div
                                                        className={'modal fade' + (modalPreviewBuildingPlan ? ' show' : '')}
                                                        style={{ display: modalPreviewBuildingPlan ? 'block' : 'none' }}
                                                    >
                                                        <div className="modal-dialog modal-xl modal-dialog-centered">
                                                            <div className="modal-content">
                                                                <div className="modal-body">
                                                                    <div className={'title'}>
                                                                        <h5>Plan du niveau</h5>
                                                                        <button
                                                                            type="button"
                                                                            className="btn-close"
                                                                            onClick={() => showModalPreviewBuildingPlan(false)}
                                                                        >
                                                                            &nbsp;
                                                                        </button>
                                                                    </div>
                                                                    <div className={'position-relative'}>
                                                                        <div id={'compass'} className={values.buildingPlans[index]['orientation']}></div>
                                                                        <div id={'screen'} ref={screenRef}>
                                                                            {buildingPlanService.RenderShape(
                                                                                screenWidth === 0 ? 500 : screenWidth,
                                                                                screenHeight === 0 ? 500 : screenHeight,
                                                                                values.buildingPlans[index]['shape'],
                                                                                values.buildingPlans[index]['width'],
                                                                                values.buildingPlans[index]['length'],
                                                                                values.buildingPlans[index]['l1'] ? values.buildingPlans[index]['l1'] : null,
                                                                                values.buildingPlans[index]['l2'] ? values.buildingPlans[index]['l2'] : null,
                                                                                values.buildingPlans[index]['l3'] ? values.buildingPlans[index]['l3'] : null,
                                                                                values.buildingPlans[index]['l4'] ? values.buildingPlans[index]['l4'] : null,
                                                                                values.buildingPlans[index]['l5'] ? values.buildingPlans[index]['l5'] : null,
                                                                                values.buildingPlans[index]['l6'] ? values.buildingPlans[index]['l6'] : null,
                                                                                values.buildingPlans[index]['l7'] ? values.buildingPlans[index]['l7'] : null,
                                                                                values.buildingPlans[index]['l8'] ? values.buildingPlans[index]['l8'] : null
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="navigation text-center">
                                                                        <button
                                                                            type="button"
                                                                            id="cancelPicture"
                                                                            className="btn btn-primary maxi-min-width mx-2"
                                                                            data-bs-dismiss="modal"
                                                                            onClick={() => showModalPreviewBuildingPlan(false)}
                                                                        >
                                                                            Fermer
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id={'real-photo'} className={'real-photo-' + index}>
                                                    <div>
                                                        <div id={'real-compass'} className={values.buildingPlans[index]['orientation']}></div>
                                                        {buildingPlanService.RenderShape(
                                                            1000,
                                                            1000,
                                                            values.buildingPlans[index]['shape'],
                                                            values.buildingPlans[index]['width'],
                                                            values.buildingPlans[index]['length'],
                                                            values.buildingPlans[index]['l1'] ? values.buildingPlans[index]['l1'] : null,
                                                            values.buildingPlans[index]['l2'] ? values.buildingPlans[index]['l2'] : null,
                                                            values.buildingPlans[index]['l3'] ? values.buildingPlans[index]['l3'] : null,
                                                            values.buildingPlans[index]['l4'] ? values.buildingPlans[index]['l4'] : null,
                                                            values.buildingPlans[index]['l5'] ? values.buildingPlans[index]['l5'] : null,
                                                            values.buildingPlans[index]['l6'] ? values.buildingPlans[index]['l6'] : null,
                                                            values.buildingPlans[index]['l7'] ? values.buildingPlans[index]['l7'] : null,
                                                            values.buildingPlans[index]['l8'] ? values.buildingPlans[index]['l8'] : null
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>

                        {values?.projectType === '3' && (
                            <div className="card mb-5">
                                <div className="card-title">
                                    <IconDraw fill="#76ca4f" width={40} height={40} className="icon" />
                                    <h2>Complément immeuble collectif</h2>
                                </div>

                                <div className="card-body">
                                    <div className="table-separate">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '10%' }}>Désignation du logement</th>
                                                    <th style={{ width: '10%' }}>
                                                        Surface (m<sup>2</sup>)
                                                    </th>
                                                    <th style={{ width: '10%' }}>Nombre de logement identique</th>
                                                    <th style={{ width: '10%' }}>Nombre de pièce principale</th>
                                                    <th style={{ width: '10%' }}>Nombre de salle de bain</th>
                                                    <th style={{ width: '10%' }}>Nombre de salle d’eau</th>
                                                    <th style={{ width: '10%' }}>Nombre de WC</th>
                                                    <th style={{ width: '15%' }}>Emission</th>
                                                    <th style={{ width: '15%' }}>ECS</th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                            </thead>

                                            {Object.keys(values?.buildingTypeDetails)
                                                .slice(0, 20)
                                                .map((item: any) => (
                                                    <tbody key={item}>
                                                        <tr>
                                                            {/* Désignation du logement */}
                                                            <td>
                                                                <Select
                                                                    key={`buildingType__${values.buildingTypeDetails[item].buildingType?.value}`}
                                                                    defaultValue={renderDefaultValueSelect(
                                                                        buildingTypeList,
                                                                        values.buildingTypeDetails[item].buildingType?.value || ''
                                                                    )}
                                                                    options={buildingTypeList}
                                                                    isClearable={false}
                                                                    isMulti={false}
                                                                    isSearchable={false}
                                                                    isDisabled={auditSent || values.buildingTypeDetails[item].buildingLocked}
                                                                    inputId={'buildingType-' + item}
                                                                    onBlur={handleBlur}
                                                                    onChange={(event: any) => {
                                                                        let tmpbuildingType = values.buildingTypeDetails;

                                                                        tmpbuildingType[item].buildingType = {
                                                                            value: event.value,
                                                                            label: event.label,
                                                                        };

                                                                        setValues({
                                                                            ...values,
                                                                            buildingTypeDetails: tmpbuildingType,
                                                                        });

                                                                        storageService.setAuditValue(2, isValid, 'buildingTypeDetails', tmpbuildingType);
                                                                    }}
                                                                    className={
                                                                        'basic-single' +
                                                                        (values.buildingTypeDetails[item].buildingType ? ' filled' : '') +
                                                                        ((touched as any)['buildingType-' + item] &&
                                                                        !values.buildingTypeDetails[item].buildingType
                                                                            ? ' invalid'
                                                                            : '') +
                                                                        (!values.buildingTypeDetails[item].buildingType ? ' required' : '')
                                                                    }
                                                                    placeholder={`T1`}
                                                                    styles={colourStyles}
                                                                />
                                                            </td>
                                                            {/* Surface */}
                                                            <td>
                                                                <div className="table-fit">
                                                                    <input
                                                                        type="number"
                                                                        min={1}
                                                                        max={999}
                                                                        disabled={auditSent || values.buildingTypeDetails[item].buildingLocked}
                                                                        className={
                                                                            'form-control' +
                                                                            (values.buildingTypeDetails[item].buildingTypeSurface ? ' filled' : '') +
                                                                            ((touched as any)['buildingTypeSurface-' + item] &&
                                                                            !values.buildingTypeDetails[item].buildingTypeSurface
                                                                                ? ' invalid'
                                                                                : '') +
                                                                            (!values.buildingTypeDetails[item].buildingTypeSurface ? ' required' : '')
                                                                        }
                                                                        placeholder="20"
                                                                        id={'buildingTypeSurface-' + item}
                                                                        onBlur={handleBlur}
                                                                        value={values.buildingTypeDetails[item].buildingTypeSurface}
                                                                        onChange={(event: any) => {
                                                                            let tmpbuildingTypeDetails = values.buildingTypeDetails;
                                                                            tmpbuildingTypeDetails[item].buildingTypeSurface = event.target.value;

                                                                            setValues({
                                                                                ...values,
                                                                                buildingTypeDetails: tmpbuildingTypeDetails,
                                                                            });

                                                                            storageService.setAuditValue(
                                                                                2,
                                                                                isValid,
                                                                                'buildingTypeDetails',
                                                                                tmpbuildingTypeDetails
                                                                            );
                                                                        }}
                                                                        onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                                                    />
                                                                </div>
                                                            </td>
                                                            {/* Nombre de logement identique */}
                                                            <td>
                                                                <Select
                                                                    key={`identicalBuildingType__${values.buildingTypeDetails[item].identicalBuildingType?.value}`}
                                                                    defaultValue={renderDefaultValueSelect(
                                                                        numberList,
                                                                        values.buildingTypeDetails[item].identicalBuildingType?.value || ''
                                                                    )}
                                                                    options={numberList}
                                                                    isClearable={false}
                                                                    isMulti={false}
                                                                    isSearchable={false}
                                                                    isDisabled={auditSent || values.buildingTypeDetails[item].buildingLocked}
                                                                    inputId={'identicalBuildingType-' + item}
                                                                    onBlur={handleBlur}
                                                                    onChange={(event: any) => {
                                                                        let tmpbuildingType = values.buildingTypeDetails;

                                                                        tmpbuildingType[item].identicalBuildingType = {
                                                                            value: event.value,
                                                                            label: event.label,
                                                                        };

                                                                        setValues({
                                                                            ...values,
                                                                            buildingTypeDetails: tmpbuildingType,
                                                                        });

                                                                        storageService.setAuditValue(2, isValid, 'buildingTypeDetails', tmpbuildingType);
                                                                    }}
                                                                    className={
                                                                        'basic-single' +
                                                                        (values.buildingTypeDetails[item].identicalBuildingType ? ' filled' : '') +
                                                                        ((touched as any)['identicalBuildingType-' + item] &&
                                                                        !values.buildingTypeDetails[item].identicalBuildingType
                                                                            ? ' invalid'
                                                                            : '') +
                                                                        (!values.buildingTypeDetails[item].identicalBuildingType ? ' required' : '')
                                                                    }
                                                                    placeholder={`2`}
                                                                    styles={colourStyles}
                                                                />
                                                            </td>
                                                            {/* Nombre de pièce principale */}
                                                            <td>
                                                                <Select
                                                                    key={`buildingTypeMainRoom__${values.buildingTypeDetails[item].buildingTypeMainRoom?.value}`}
                                                                    defaultValue={renderDefaultValueSelect(
                                                                        numberList,
                                                                        values.buildingTypeDetails[item].buildingTypeMainRoom?.value || ''
                                                                    )}
                                                                    options={numberList.slice(0, 9)}
                                                                    isClearable={false}
                                                                    isMulti={false}
                                                                    isSearchable={false}
                                                                    isDisabled={auditSent || values.buildingTypeDetails[item].buildingLocked}
                                                                    inputId={'buildingTypeMainRoom-' + item}
                                                                    onBlur={handleBlur}
                                                                    onChange={(event: any) => {
                                                                        let tmpbuildingType = values.buildingTypeDetails;

                                                                        tmpbuildingType[item].buildingTypeMainRoom = {
                                                                            value: event.value,
                                                                            label: event.label,
                                                                        };

                                                                        setValues({
                                                                            ...values,
                                                                            buildingTypeDetails: tmpbuildingType,
                                                                        });

                                                                        storageService.setAuditValue(2, isValid, 'buildingTypeDetails', tmpbuildingType);
                                                                    }}
                                                                    className={
                                                                        'basic-single' +
                                                                        (values.buildingTypeDetails[item].buildingTypeMainRoom ? ' filled' : '') +
                                                                        ((touched as any)['buildingTypeMainRoom-' + item] &&
                                                                        !values.buildingTypeDetails[item].buildingTypeMainRoom
                                                                            ? ' invalid'
                                                                            : '') +
                                                                        (!values.buildingTypeDetails[item].buildingTypeMainRoom ? ' required' : '')
                                                                    }
                                                                    placeholder={`2`}
                                                                    styles={colourStyles}
                                                                />
                                                            </td>
                                                            {/* Nombre de salle de bain */}
                                                            <td>
                                                                <Select
                                                                    key={`buildingTypeBathroom__${values.buildingTypeDetails[item].buildingTypeBathroom?.value}`}
                                                                    defaultValue={renderDefaultValueSelect(
                                                                        numberList,
                                                                        values.buildingTypeDetails[item].buildingTypeBathroom?.value || ''
                                                                    )}
                                                                    options={numberList.slice(0, 9)}
                                                                    isClearable={false}
                                                                    isMulti={false}
                                                                    isSearchable={false}
                                                                    isDisabled={auditSent || values.buildingTypeDetails[item].buildingLocked}
                                                                    inputId={'buildingTypeBathroom-' + item}
                                                                    onBlur={handleBlur}
                                                                    onChange={(event: any) => {
                                                                        let tmpbuildingType = values.buildingTypeDetails;

                                                                        tmpbuildingType[item].buildingTypeBathroom = {
                                                                            value: event.value,
                                                                            label: event.label,
                                                                        };

                                                                        setValues({
                                                                            ...values,
                                                                            buildingTypeDetails: tmpbuildingType,
                                                                        });

                                                                        storageService.setAuditValue(2, isValid, 'buildingTypeDetails', tmpbuildingType);
                                                                    }}
                                                                    className={
                                                                        'basic-single' +
                                                                        (values.buildingTypeDetails[item].buildingTypeBathroom ? ' filled' : '') +
                                                                        ((touched as any)['buildingTypeBathroom-' + item] &&
                                                                        !values.buildingTypeDetails[item].buildingTypeBathroom
                                                                            ? ' invalid'
                                                                            : '') +
                                                                        (!values.buildingTypeDetails[item].buildingTypeBathroom ? ' required' : '')
                                                                    }
                                                                    placeholder={`2`}
                                                                    styles={colourStyles}
                                                                />
                                                            </td>
                                                            {/* Nombre de salle d'eau */}
                                                            <td>
                                                                <Select
                                                                    key={`buildingTypeBathroom2__${values.buildingTypeDetails[item].buildingTypeBathroom2?.value}`}
                                                                    defaultValue={renderDefaultValueSelect(
                                                                        numberList,
                                                                        values.buildingTypeDetails[item].buildingTypeBathroom2?.value || ''
                                                                    )}
                                                                    options={numberList.slice(0, 9)}
                                                                    isClearable={false}
                                                                    isMulti={false}
                                                                    isSearchable={false}
                                                                    isDisabled={auditSent || values.buildingTypeDetails[item].buildingLocked}
                                                                    inputId={'buildingTypeBathroom2-' + item}
                                                                    onBlur={handleBlur}
                                                                    onChange={(event: any) => {
                                                                        let tmpbuildingType = values.buildingTypeDetails;

                                                                        tmpbuildingType[item].buildingTypeBathroom2 = {
                                                                            value: event.value,
                                                                            label: event.label,
                                                                        };

                                                                        setValues({
                                                                            ...values,
                                                                            buildingTypeDetails: tmpbuildingType,
                                                                        });

                                                                        storageService.setAuditValue(2, isValid, 'buildingTypeDetails', tmpbuildingType);
                                                                    }}
                                                                    className={
                                                                        'basic-single' +
                                                                        (values.buildingTypeDetails[item].buildingTypeBathroom2 ? ' filled' : '') +
                                                                        ((touched as any)['buildingTypeBathroom2-' + item] &&
                                                                        !values.buildingTypeDetails[item].buildingTypeBathroom2
                                                                            ? ' invalid'
                                                                            : '') +
                                                                        (!values.buildingTypeDetails[item].buildingTypeBathroom2 ? ' required' : '')
                                                                    }
                                                                    placeholder={`2`}
                                                                    styles={colourStyles}
                                                                />
                                                            </td>
                                                            {/* Nombre de WC */}
                                                            <td>
                                                                <Select
                                                                    key={`buildingTypeWC__${values.buildingTypeDetails[item].buildingTypeWC?.value}`}
                                                                    defaultValue={renderDefaultValueSelect(
                                                                        numberList,
                                                                        values.buildingTypeDetails[item].buildingTypeWC?.value || ''
                                                                    )}
                                                                    options={numberList.slice(0, 9)}
                                                                    isClearable={false}
                                                                    isMulti={false}
                                                                    isSearchable={false}
                                                                    isDisabled={auditSent || values.buildingTypeDetails[item].buildingLocked}
                                                                    inputId={'buildingTypeWC-' + item}
                                                                    onBlur={handleBlur}
                                                                    onChange={(event: any) => {
                                                                        let tmpbuildingType = values.buildingTypeDetails;

                                                                        tmpbuildingType[item].buildingTypeWC = {
                                                                            value: event.value,
                                                                            label: event.label,
                                                                        };

                                                                        setValues({
                                                                            ...values,
                                                                            buildingTypeDetails: tmpbuildingType,
                                                                        });

                                                                        storageService.setAuditValue(2, isValid, 'buildingTypeDetails', tmpbuildingType);
                                                                    }}
                                                                    className={
                                                                        'basic-single' +
                                                                        (values.buildingTypeDetails[item].buildingTypeWC ? ' filled' : '') +
                                                                        ((touched as any)['buildingTypeWC-' + item] &&
                                                                        !values.buildingTypeDetails[item].buildingTypeWC
                                                                            ? ' invalid'
                                                                            : '') +
                                                                        (!values.buildingTypeDetails[item].buildingTypeWC ? ' required' : '')
                                                                    }
                                                                    placeholder={`2`}
                                                                    styles={colourStyles}
                                                                />
                                                            </td>
                                                            {/* Emission */}
                                                            <td>
                                                                <Select
                                                                    key={`buildingType__${values.buildingTypeDetails[item].buildingTypeEmission?.value}`}
                                                                    defaultValue={renderDefaultValueSelect(
                                                                        buildingTypeEmissionList,
                                                                        values.buildingTypeDetails[item].buildingTypeEmission?.value || ''
                                                                    )}
                                                                    options={buildingTypeEmissionList}
                                                                    isClearable={false}
                                                                    isMulti={false}
                                                                    isSearchable={false}
                                                                    isDisabled={auditSent || values.buildingTypeDetails[item].buildingLocked}
                                                                    inputId={'buildingTypeEmission-' + item}
                                                                    onBlur={handleBlur}
                                                                    onChange={(event: any) => {
                                                                        let tmpbuildingTypeEmission = values.buildingTypeDetails;

                                                                        tmpbuildingTypeEmission[item].buildingTypeEmission = {
                                                                            value: event.value,
                                                                            label: event.label,
                                                                        };

                                                                        setValues({
                                                                            ...values,
                                                                            buildingTypeDetails: tmpbuildingTypeEmission,
                                                                        });

                                                                        storageService.setAuditValue(
                                                                            2,
                                                                            isValid,
                                                                            'buildingTypeDetails',
                                                                            tmpbuildingTypeEmission
                                                                        );
                                                                    }}
                                                                    className={
                                                                        'basic-single' +
                                                                        (values.buildingTypeDetails[item].buildingTypeEmission ? ' filled' : '') +
                                                                        ((touched as any)['buildingTypeEmission-' + item] &&
                                                                        !values.buildingTypeDetails[item].buildingTypeEmission
                                                                            ? ' invalid'
                                                                            : '') +
                                                                        (!values.buildingTypeDetails[item].buildingTypeEmission ? ' required' : '')
                                                                    }
                                                                    placeholder={`Système principal`}
                                                                    styles={colourStyles}
                                                                />
                                                            </td>
                                                            {/* ECS */}
                                                            <td>
                                                                <Select
                                                                    key={`buildingType__${values.buildingTypeDetails[item].buildingTypeECS?.value}`}
                                                                    defaultValue={renderDefaultValueSelect(
                                                                        buildingTypeECSList,
                                                                        values.buildingTypeDetails[item].buildingTypeECS?.value || ''
                                                                    )}
                                                                    options={buildingTypeECSList}
                                                                    isClearable={false}
                                                                    isMulti={false}
                                                                    isSearchable={false}
                                                                    isDisabled={auditSent || values.buildingTypeDetails[item].buildingLocked}
                                                                    inputId={'buildingTypeECS-' + item}
                                                                    onBlur={handleBlur}
                                                                    onChange={(event) => {
                                                                        let tmpbuildingTypeECS = values.buildingTypeDetails;

                                                                        tmpbuildingTypeECS[item].buildingTypeECS = {
                                                                            value: event.value,
                                                                            label: event.label,
                                                                        };

                                                                        setValues({
                                                                            ...values,
                                                                            buildingTypeDetails: tmpbuildingTypeECS,
                                                                        });

                                                                        storageService.setAuditValue(2, isValid, 'buildingTypeDetails', tmpbuildingTypeECS);
                                                                    }}
                                                                    className={
                                                                        'basic-single' +
                                                                        (values.buildingTypeDetails[item].buildingTypeECS ? ' filled' : '') +
                                                                        ((touched as any)['buildingTypeECS-' + item] &&
                                                                        !values.buildingTypeDetails[item].buildingTypeECS
                                                                            ? ' invalid'
                                                                            : '') +
                                                                        (!values.buildingTypeDetails[item].buildingTypeECS ? ' required' : '')
                                                                    }
                                                                    placeholder={`Lié au chauffage`}
                                                                    styles={colourStyles}
                                                                />
                                                            </td>
                                                            {/* Bouton suppression */}
                                                            <td>
                                                                <button
                                                                    type="button"
                                                                    className="btn icon-remove icon-remove-red"
                                                                    onClick={() => {
                                                                        let updatedObject = values.buildingTypeDetails;
                                                                        updatedObject.splice(item, 1);
                                                                        // Si plus tableau vide, injection objet vide
                                                                        if (updatedObject.length === 0) {
                                                                            updatedObject = [
                                                                                ...updatedObject,
                                                                                {
                                                                                    buildingType: '',
                                                                                    buildingTypeSurface: '',
                                                                                    identicalBuildingType: '',
                                                                                    buildingTypeMainRoom: '',
                                                                                    buildingTypeBathroom: '',
                                                                                    buildingTypeBathroom2: '',
                                                                                    buildingTypeWC: '',
                                                                                    buildingTypeEmission: '',
                                                                                    buildingTypeECS: '',
                                                                                    buildingLocked: false,
                                                                                },
                                                                            ];
                                                                        }

                                                                        setValues({
                                                                            ...values,
                                                                            buildingTypeDetails: updatedObject,
                                                                        });

                                                                        // Mise à jour du localStorage
                                                                        storageService.setAuditValue(2, isValid, 'buildingTypeDetails', updatedObject);
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>

                                                        <tr className="separator">
                                                            <td colSpan={10}>&nbsp;</td>
                                                        </tr>
                                                    </tbody>
                                                ))}
                                        </table>
                                    </div>
                                    <button
                                        className={'btn btn-green icon-add btn-square'}
                                        disabled={values.buildingTypeDetails.length >= 20 || (errors.buildingTypeDetails as any)?.length > 0}
                                        onClick={() => {
                                            const indexLastBuilding = values.buildingTypeDetails.length - 1;
                                            values.buildingTypeDetails[indexLastBuilding].buildingLocked = true;

                                            values.buildingTypeDetails = [
                                                ...values.buildingTypeDetails,
                                                {
                                                    buildingType: '',
                                                    buildingTypeSurface: '',
                                                    identicalBuildingType: '',
                                                    buildingTypeMainRoom: '',
                                                    buildingTypeBathroom: '',
                                                    buildingTypeBathroom2: '',
                                                    buildingTypeWC: '',
                                                    buildingTypeEmission: '',
                                                    buildingTypeECS: '',
                                                    buildingLocked: false,
                                                },
                                            ];

                                            setValues({
                                                ...values,
                                                buildingTypeDetails: values.buildingTypeDetails,
                                            });
                                            // Mise à jour du local storage
                                            storageService.setAuditValue(2, isValid, 'buildingTypeDetails', values.buildingTypeDetails);
                                        }}
                                        type={'button'}
                                    >
                                        Ajouter un type de logement
                                    </button>
                                </div>
                            </div>
                        )}

                        {values.projectType !== '3' && (
                            <div className="card mb-5">
                                <div className="card-title">
                                    <IconDraw fill="#76ca4f" width={40} height={40} className="icon" />
                                    <h2>Typologie du logement</h2>
                                </div>
                                <div className="card-body">
                                    <fieldset className="w-100" disabled={auditSent}>
                                        <div className="row mb-3">
                                            <div className="col-12 col-md-6 mb-3 mb-md-0">
                                                <label htmlFor="generalOptionsMain" className="form-label">
                                                    Nombre de pièces principales (hors cuisine)<sup>*</sup>
                                                </label>
                                                <div className="input-group">
                                                    <input
                                                        type="number"
                                                        key={`generalOptionsMain__1`}
                                                        min={1}
                                                        className={
                                                            'form-control' +
                                                            (values.generalOptionsMain ? ' filled' : '') +
                                                            (touched.generalOptionsMain && errors.generalOptionsMain ? ' invalid' : '') +
                                                            (!values.generalOptionsMain ? ' required' : '')
                                                        }
                                                        placeholder="1"
                                                        id="generalOptionsMain"
                                                        onBlur={handleBlur}
                                                        defaultValue={values.generalOptionsMain}
                                                        onChange={(event) => {
                                                            updateValue(event, handleChange, isValid, 2, 'generalOptionsMain');
                                                        }}
                                                        onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                                    />
                                                    <span className="input-group-text">pièce(s)</span>
                                                </div>
                                                {touched.generalOptionsMain && errors.generalOptionsMain && (
                                                    <div className="invalid-feedback">{errors.generalOptionsMain}</div>
                                                )}
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <label htmlFor="generalOptionsBathroom" className="form-label">
                                                    Nombre de salle de bain<sup>*</sup>
                                                </label>
                                                <div className="input-group">
                                                    <input
                                                        type="number"
                                                        min={1}
                                                        className={
                                                            'form-control' +
                                                            (values.generalOptionsBathroom || values.generalOptionsBathroom === 0 ? ' filled' : '') +
                                                            (touched.generalOptionsBathroom && errors.generalOptionsBathroom ? ' invalid' : '') +
                                                            (!values.generalOptionsBathroom ? ' required' : '')
                                                        }
                                                        placeholder="1"
                                                        id="generalOptionsBathroom"
                                                        onBlur={handleBlur}
                                                        defaultValue={values.generalOptionsBathroom}
                                                        onChange={(event) => updateValue(event, handleChange, isValid, 2, 'generalOptionsBathroom')}
                                                        onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                                    />
                                                    <span className="input-group-text">pièce(s)</span>
                                                </div>
                                                {touched.generalOptionsBathroom && errors.generalOptionsBathroom && (
                                                    <div className="invalid-feedback">{errors.generalOptionsBathroom}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-12 col-md-6 mb-3 mb-md-0">
                                                <label htmlFor="generalOptionsWC" className="form-label">
                                                    Nombre de WC indépendant<sup>*</sup>
                                                </label>
                                                <div className="input-group">
                                                    <input
                                                        type="number"
                                                        min={0}
                                                        className={
                                                            'form-control' +
                                                            (values.generalOptionsWC || values.generalOptionsWC === 0 ? ' filled' : '') +
                                                            (touched.generalOptionsWC && errors.generalOptionsWC ? ' invalid' : '') +
                                                            (!values.generalOptionsWC ? ' required' : '')
                                                        }
                                                        placeholder="2"
                                                        id="generalOptionsWC"
                                                        onBlur={handleBlur}
                                                        defaultValue={values.generalOptionsWC}
                                                        onChange={(event) => updateValue(event, handleChange, isValid, 2, 'generalOptionsWC')}
                                                        onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                                    />
                                                    <span className="input-group-text">pièce(s)</span>
                                                </div>
                                                {touched.generalOptionsWC && errors.generalOptionsWC && (
                                                    <div className="invalid-feedback">{errors.generalOptionsWC}</div>
                                                )}
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <label htmlFor="generalOptionsOtherWater" className="form-label">
                                                    Nombre d’autres salles d’eau (cellier, buanderie...)<sup>*</sup>
                                                </label>
                                                <div className="input-group">
                                                    <input
                                                        type="number"
                                                        min={0}
                                                        className={
                                                            'form-control' +
                                                            (values.generalOptionsOtherWater || values.generalOptionsOtherWater === 0 ? ' filled' : '') +
                                                            (touched.generalOptionsOtherWater && errors.generalOptionsOtherWater ? ' invalid' : '') +
                                                            (!values.generalOptionsOtherWater ? ' required' : '')
                                                        }
                                                        placeholder="1"
                                                        id="generalOptionsOtherWater"
                                                        onBlur={handleBlur}
                                                        defaultValue={values.generalOptionsOtherWater}
                                                        onChange={(event) => updateValue(event, handleChange, isValid, 2, 'generalOptionsOtherWater')}
                                                        onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                                    />
                                                    <span className="input-group-text">pièce(s)</span>
                                                </div>
                                                {touched.generalOptionsOtherWater && errors.generalOptionsOtherWater && (
                                                    <div className="invalid-feedback">{errors.generalOptionsOtherWater}</div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            {!domTomCheck(currentZipCode) && (
                                                <div className="col-12 col-md-6 mb-3 mb-md-0">
                                                    <label htmlFor="temperatureTarget" className="form-label">
                                                        Température de consigne de chauffage<sup>*</sup>
                                                    </label>
                                                    <div className="input-group">
                                                        <input
                                                            type="number"
                                                            min={10}
                                                            max={30}
                                                            className={
                                                                'form-control' +
                                                                (values.temperatureTarget || values.temperatureTarget === 0 ? ' filled' : '') +
                                                                (touched.temperatureTarget && errors.temperatureTarget ? ' invalid' : '') +
                                                                (!values.temperatureTarget ? ' required' : '')
                                                            }
                                                            placeholder="18"
                                                            id="temperatureTarget"
                                                            onBlur={handleBlur}
                                                            value={values.temperatureTarget || ''}
                                                            onChange={(event) => updateValue(event, handleChange, isValid, 2, 'temperatureTarget')}
                                                            onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                                        />
                                                        <span className="input-group-text">°C</span>
                                                    </div>
                                                    {touched.temperatureTarget && errors.temperatureTarget && (
                                                        <div className="invalid-feedback">{errors.temperatureTarget}</div>
                                                    )}
                                                </div>
                                            )}
                                            <div className="col-12 col-md-6">
                                                <label htmlFor="buildingPeople" className="form-label">
                                                    Nombre d’occupants<sup>*</sup>
                                                </label>
                                                <div className="input-group">
                                                    <input
                                                        type="number"
                                                        min={0}
                                                        className={
                                                            'form-control' +
                                                            (values.buildingPeople || values.buildingPeople === 0 ? ' filled' : '') +
                                                            (touched.buildingPeople && errors.buildingPeople ? ' invalid' : '') +
                                                            (!values.buildingPeople ? ' required' : '')
                                                        }
                                                        placeholder="1"
                                                        id="buildingPeople"
                                                        onBlur={handleBlur}
                                                        defaultValue={values.buildingPeople}
                                                        onChange={(event) => updateValue(event, handleChange, isValid, 2, 'buildingPeople')}
                                                        onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                                    />
                                                    <span className="input-group-text">habitant(s)</span>
                                                </div>
                                                {touched.buildingPeople && errors.buildingPeople && (
                                                    <div className="invalid-feedback">{errors.buildingPeople}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mb-5">
                                            <div className="col-12 col-md-6">
                                                <label htmlFor="occupationLevel" className="form-label">
                                                    Niveau d’occupation<sup>*</sup>
                                                    <div className="help-text" data-tip data-for="helpTypo">
                                                        <IconHelp fill="#E69736" width={14} height={14} className="icon" />
                                                    </div>
                                                </label>
                                                <div className="row">
                                                    <div className="col">
                                                        <Range
                                                            values={[values.occupationLevel]}
                                                            step={0.01}
                                                            disabled={auditSent}
                                                            min={0}
                                                            max={1}
                                                            onChange={(event) => {
                                                                setValues({
                                                                    ...values,
                                                                    occupationLevel: event[0],
                                                                });
                                                                updateValue(event[0], handleChange, isValid, 2, 'occupationLevel');
                                                            }}
                                                            renderTrack={({ props, children }) => (
                                                                <div
                                                                    onMouseDown={props.onMouseDown}
                                                                    onTouchStart={props.onTouchStart}
                                                                    style={{
                                                                        ...props.style,
                                                                        height: '36px',
                                                                        display: 'flex',
                                                                        width: '100%',
                                                                    }}
                                                                >
                                                                    <div
                                                                        ref={props.ref}
                                                                        style={{
                                                                            height: '6px',
                                                                            width: '100%',
                                                                            borderRadius: '4px',
                                                                            background: getTrackBackground({
                                                                                values: [values.occupationLevel],
                                                                                colors: ['#FA8530', '#C8C8C8'],
                                                                                min: 0,
                                                                                max: 1,
                                                                            }),
                                                                            alignSelf: 'center',
                                                                        }}
                                                                    >
                                                                        {children}
                                                                    </div>
                                                                </div>
                                                            )}
                                                            renderThumb={({ props, isDragged }) => (
                                                                <div
                                                                    {...props}
                                                                    style={{
                                                                        ...props.style,
                                                                        height: '20px',
                                                                        width: '20px',
                                                                        borderRadius: '50%',
                                                                        backgroundColor: '#F9F9F9',
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        boxShadow: '0px 2px 6px #AAA',
                                                                        outline: 'none',
                                                                    }}
                                                                ></div>
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-auto ml-5">
                                                        <div className="input-group">
                                                            <input
                                                                type="number"
                                                                min={0}
                                                                max={100}
                                                                className={
                                                                    'form-control' + (touched.occupationLevel && errors.occupationLevel ? ' invalid' : '')
                                                                }
                                                                placeholder="50"
                                                                style={{ width: 80 }}
                                                                id="occupationLevel"
                                                                onBlur={handleBlur}
                                                                value={Math.round(values.occupationLevel * 100) || 0}
                                                                onChange={(event) => {
                                                                    updateValue(
                                                                        parseFloat(event.target.value) / 100,
                                                                        handleChange,
                                                                        isValid,
                                                                        2,
                                                                        'occupationLevel'
                                                                    );
                                                                    setValues({
                                                                        ...values,
                                                                        occupationLevel: event.target.value === '0' ? 0 : parseFloat(event.target.value) / 100,
                                                                    });
                                                                }}
                                                                readOnly
                                                                onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
                                                            />
                                                            <span className="input-group-text">%</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ReactTooltip id="helpTypo" place="bottom" type={'light'} effect={'solid'}>
                                                    <h3>Niveau d’occupation du logement :</h3>
                                                    <p>Nombre de jours de présence, horaire d’occupation jour par jour.</p>
                                                </ReactTooltip>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        )}

                        {
                            // Maison individuelle et Bâtiment
                            ['1', '4'].includes(values.projectType) && (
                                <div className="card mb-5">
                                    <div className="card-title">
                                        <RoofIcon fill="#76ca4f" width={40} height={40} className="icon" />
                                        <h2>Toiture</h2>
                                    </div>

                                    <div className="card-body">
                                        <div className="row mb-3">
                                            <div className="col-12 col-md-6 mb-md-0 mb-3">
                                                <label htmlFor="tilt" className="form-label">
                                                    Inclinaison de la toiture<sup>*</sup>
                                                </label>
                                                <Select
                                                    value={renderDefaultValueSelect(tiltList, values.tilt)}
                                                    options={tiltList}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    isMulti={false}
                                                    isDisabled={auditSent}
                                                    inputId="tilt"
                                                    onBlur={handleBlur}
                                                    onChange={(event) => {
                                                        setValues({
                                                            ...values,
                                                            tilt: event.value,
                                                        });
                                                        storageService.setAuditValue(2, isValid, 'tilt', event.value, event.label);
                                                    }}
                                                    className={
                                                        'basic-single' +
                                                        (values.tilt ? ' filled' : '') +
                                                        (touched.tilt && errors.tilt ? ' invalid' : '') +
                                                        (!values.tilt ? ' required' : '')
                                                    }
                                                    placeholder={`Choisissez l'inclinaison de la toiture`}
                                                    styles={colourStyles}
                                                />
                                                {touched.tilt && errors.tilt && <div className="invalid-feedback">{errors.tilt}</div>}
                                            </div>

                                            <div className="col-12 col-md-6 text-center">
                                                <ToitureSvg className="img-fluid" />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-12 col-md-6 mb-mb-0 mb-3">
                                                {['1', '4'].includes(values.projectType) && (
                                                    <>
                                                        <label htmlFor="foldNumber" className="form-label label-flex">
                                                            Nombre de pans<sup>*</sup>
                                                        </label>

                                                        <Select
                                                            value={renderDefaultValueSelect(foldNumberList, values.foldNumber)}
                                                            options={foldNumberList}
                                                            isClearable={false}
                                                            isSearchable={false}
                                                            isMulti={false}
                                                            isDisabled={auditSent}
                                                            inputId="foldNumber"
                                                            onBlur={handleBlur}
                                                            onChange={(event) => {
                                                                setValues({
                                                                    ...values,
                                                                    foldNumber: event.value,
                                                                });
                                                                storageService.setAuditValue(2, isValid, 'foldNumber', event.value, event.label);
                                                            }}
                                                            className={
                                                                'basic-single' +
                                                                (values.foldNumber ? ' filled' : '') +
                                                                (touched.foldNumber && errors.foldNumber ? ' invalid' : '') +
                                                                (!values.foldNumber ? ' required' : '')
                                                            }
                                                            placeholder={`1`}
                                                            styles={colourStyles}
                                                        />
                                                        {touched.foldNumber && errors.foldNumber && <div className="invalid-feedback">{errors.foldNumber}</div>}
                                                    </>
                                                )}
                                            </div>

                                            <div className="col-12 col-md-6 text-center">
                                                <PansSvg className="img-fluid" />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-12 col-md-6">
                                                <label htmlFor="roofExposure" className="form-label label-flex">
                                                    Exposition de la toiture<sup>*</sup>
                                                    <AddStepPhotos
                                                        prop={buildingPictureRoofing}
                                                        values={values}
                                                        setFieldValue={setFieldValue}
                                                        fieldValue={'buildingPictureRoofing'}
                                                        disabled={auditSent}
                                                    />
                                                </label>
                                                <Select
                                                    value={renderDefaultValueSelect(domTomRoofExposure(), values.roofExposure)}
                                                    options={domTomRoofExposure()}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    isMulti={false}
                                                    isDisabled={auditSent}
                                                    inputId="roofExposure"
                                                    onBlur={handleBlur}
                                                    onChange={(event) => {
                                                        setValues({
                                                            ...values,
                                                            roofExposure: event.value,
                                                        });
                                                        storageService.setAuditValue(2, isValid, 'roofExposure', event.value, event.label);
                                                    }}
                                                    className={
                                                        'basic-single' +
                                                        (values.roofExposure ? ' filled' : '') +
                                                        (touched.roofExposure && errors.roofExposure ? ' invalid' : '') +
                                                        (!values.roofExposure ? ' required' : '')
                                                    }
                                                    placeholder={`Choisissez l'exposition de la toiture`}
                                                    styles={colourStyles}
                                                />
                                                {touched.roofExposure && errors.roofExposure && <div className="invalid-feedback">{errors.roofExposure}</div>}
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-12 col-md-6">
                                                <CheckboxService
                                                    handleCheckbox={solarMask}
                                                    values={values}
                                                    isValid={isValid}
                                                    setValues={setValues}
                                                    name={'solarMask'}
                                                    propName={values.solarMask}
                                                    title={"Présence d'un masque solaire ?"}
                                                    emptyLabel={true}
                                                    border={true}
                                                    disabled={auditSent}
                                                />
                                            </div>

                                            {values.solarMask && (
                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="solarMaskPercent" className="label-flex">
                                                        Masque solaire<sup>*</sup>
                                                        <div className="help-text" data-tip data-for="solarMaskPercent">
                                                            <IconHelp fill="#E69736" width={14} height={14} className="icon" />
                                                        </div>
                                                        <AddStepPhotos
                                                            prop={buildingPicturesSolarMask}
                                                            values={values}
                                                            setFieldValue={setFieldValue}
                                                            fieldValue={'buildingPicturesSolarMask'}
                                                            disabled={!values.solarMask || auditSent}
                                                        />
                                                    </label>

                                                    <Select
                                                        defaultValue={renderDefaultValueSelect(solarMaskList, values.solarMaskPercent)}
                                                        options={solarMaskList}
                                                        isSearchable={false}
                                                        isClearable={false}
                                                        isMulti={false}
                                                        isDisabled={!values.solarMask}
                                                        inputId={'solarMaskPercent'}
                                                        onBlur={handleBlur}
                                                        onChange={(event) => {
                                                            setValues({
                                                                ...values,
                                                                solarMaskPercent: event.label,
                                                            });
                                                            storageService.setAuditValue(2, isValid, 'solarMaskPercent', event.value, event.label);
                                                        }}
                                                        className={
                                                            'basic-single' +
                                                            (values.solarMaskPercent ? ' filled' : '') +
                                                            (touched.solarMaskPercent && errors.solarMaskPercent ? ' invalid' : '') +
                                                            (!values.solarMaskPercent ? ' required' : '')
                                                        }
                                                        placeholder={`20% (panneaux non exposés au soleil)`}
                                                        styles={colourStyles}
                                                    />

                                                    {touched.solarMaskPercent && errors.solarMaskPercent && (
                                                        <div className="invalid-feedback">{errors.solarMaskPercent}</div>
                                                    )}

                                                    <ReactTooltip id="solarMaskPercent" place="bottom" type={'light'} effect={'solid'}>
                                                        <h3>Calcul du masque solaire :</h3>
                                                        <p>
                                                            Pour calculer l'ensoleillement d'un lieu, il faut impérativement prendre en compte les masques
                                                            solaires. Le masque solaire est l'ensemble des éléments (arbres, bâtiments, montagnes) qui peuvent
                                                            faire de l'ombre pendant la journée. 80% correspond à l’obstruction de la toiture par l’ombrage,
                                                            soit 20% d’ensoleillement maximal. 0% correspond à l’absence d’ombrage sur la toiture, soit 100%
                                                            d’ensoleillement.
                                                        </p>
                                                    </ReactTooltip>
                                                </div>
                                            )}
                                        </div>

                                        <div className="row">
                                            <div className="col-12 col-md-6 mb-3 mb-md-0">
                                                <div className="solar-flex">
                                                    <SolarMaskIcon />
                                                    <p className="text-italic mb-0">
                                                        (Le <strong>masque solaire</strong> est l'ensemble des éléments (arbres, bâtiments, montagnes) qui
                                                        peuvent faire de l'ombre pendant la journée. Veuillez indiquer l’impact en % sur la gêne occasionnée
                                                        pendant la journée d’ensoleillement)
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <SolarPanels mapRef={mapRef} values={values} setValues={setValues} auditSent={auditSent} />
                                </div>
                            )
                        }

                        <div className="card mb-5">
                            <div className="card-title">
                                <IconPool fill="#76ca4f" width={40} height={40} className="icon" />
                                <h2>Les équipements de mon habitation</h2>
                            </div>

                            <div className="card-body">
                                {(values.projectType === '1' || values.projectType === '4') && (
                                    <div className="row row-bt no-border mb-3">
                                        <div className={values.poolHeat ? 'col-12 col-md-6 mb-3' : 'col-12 col-md-6 mb-md-0 mb-3'}>
                                            <CheckboxService
                                                handleCheckbox={pool}
                                                values={values}
                                                isValid={isValid}
                                                setValues={setValues}
                                                name={'pool'}
                                                propName={values.pool}
                                                title={"Présence d'une piscine ?"}
                                                disabled={auditSent}
                                            />
                                        </div>
                                        {values.pool && (
                                            <div className="col-12 col-md-6 mb-md-0 mb-3">
                                                <CheckboxService
                                                    handleCheckbox={poolHeat}
                                                    values={values}
                                                    isValid={isValid}
                                                    setValues={setValues}
                                                    name={'poolHeat'}
                                                    propName={values.poolHeat}
                                                    title={'La piscine est-elle chauffée ?'}
                                                    disabled={auditSent}
                                                />
                                            </div>
                                        )}

                                        {values.poolHeat && (
                                            <div className="col-12 col-md-6">
                                                <label htmlFor="poolHeatType">
                                                    Type de chauffage pour la piscine<sup>*</sup>
                                                </label>

                                                <Select
                                                    value={renderDefaultValueSelect(poolHeatList, values.poolHeatType)}
                                                    options={poolHeatList}
                                                    isSearchable={false}
                                                    isClearable={false}
                                                    isMulti={false}
                                                    isDisabled={!values.poolHeat || !values.pool || auditSent}
                                                    inputId={'poolHeatType'}
                                                    onBlur={handleBlur}
                                                    onChange={(event) => {
                                                        setValues({
                                                            ...values,
                                                            poolHeatType: event.value,
                                                        });
                                                        storageService.setAuditValue(2, isValid, 'poolHeatType', event.value, event.label);
                                                    }}
                                                    className={
                                                        'basic-single' +
                                                        (values.poolHeatType ? ' filled' : '') +
                                                        (touched.poolHeatType && errors.poolHeatType ? ' invalid' : '') +
                                                        (!values.poolHeatType ? ' required' : '')
                                                    }
                                                    placeholder={`Pompe à chaleur`}
                                                    styles={colourStyles}
                                                />

                                                {touched.poolHeatType && errors.poolHeatType && <div className="invalid-feedback">{errors.poolHeatType}</div>}
                                            </div>
                                        )}
                                    </div>
                                )}

                                {(values.projectType === '1' || values.projectType === '4') && (
                                    <div className="row row-bt mb-3">
                                        <div className="col-12 col-md-6" style={{ minHeight: '70px' }}>
                                            <CheckboxService
                                                handleCheckbox={veranda}
                                                values={values}
                                                isValid={isValid}
                                                setValues={setValues}
                                                name={'veranda'}
                                                propName={values.veranda}
                                                title={"Présence d'une veranda ?"}
                                                emptyLabel={true}
                                                disabled={auditSent}
                                            />
                                        </div>

                                        {values.veranda && (
                                            <div className="col-12 col-md-6">
                                                <label htmlFor="verandaAge" className="form-label">
                                                    Année de construction de la véranda
                                                </label>
                                                <Select
                                                    value={renderDefaultValueSelect(verandaAndPergolaAgeList, values.verandaAge)}
                                                    options={verandaAndPergolaAgeList}
                                                    isSearchable={false}
                                                    isClearable={false}
                                                    isMulti={false}
                                                    isDisabled={auditSent}
                                                    inputId={'verandaAge'}
                                                    onBlur={handleBlur}
                                                    onChange={(event) => {
                                                        setValues({
                                                            ...values,
                                                            verandaAge: event.value,
                                                        });
                                                        storageService.setAuditValue(2, isValid, 'verandaAge', event.value, event.label);
                                                    }}
                                                    className={
                                                        'basic-single' +
                                                        (values.verandaAge ? ' filled' : '') +
                                                        (touched.verandaAge && errors.verandaAge ? ' invalid' : '') +
                                                        (!values.verandaAge ? ' required' : '')
                                                    }
                                                    placeholder={getHouseAge ? getHouseAge : minYear}
                                                    styles={colourStyles}
                                                />
                                                {touched.verandaAge && errors.verandaAge && <div className="invalid-feedback">{errors.verandaAge}</div>}
                                            </div>
                                        )}
                                    </div>
                                )}

                                {(values.projectType === '1' || values.projectType === '4') && (
                                    <div className="row row-bt mb-3">
                                        <div className="col-12 col-md-6" style={{ minHeight: '70px' }}>
                                            <CheckboxService
                                                handleCheckbox={pergola}
                                                values={values}
                                                isValid={isValid}
                                                setValues={setValues}
                                                name={'pergola'}
                                                propName={values.pergola}
                                                title={"Présence d'une pergola ?"}
                                                emptyLabel={true}
                                                disabled={auditSent}
                                            />
                                        </div>

                                        {values.pergola && (
                                            <div className="col-12 col-md-6">
                                                <label htmlFor="pergolaAge" className="form-label">
                                                    Année de construction de la pergola
                                                </label>
                                                <Select
                                                    defaultValue={renderDefaultValueSelect(verandaAndPergolaAgeList, values.pergolaAge)}
                                                    options={verandaAndPergolaAgeList}
                                                    isSearchable={false}
                                                    isClearable={false}
                                                    isMulti={false}
                                                    isDisabled={auditSent}
                                                    inputId={'pergolaAge'}
                                                    onBlur={handleBlur}
                                                    onChange={(event) => {
                                                        setValues({
                                                            ...values,
                                                            pergolaAge: event.value,
                                                        });
                                                        storageService.setAuditValue(2, isValid, 'pergolaAge', event.value, event.label);
                                                    }}
                                                    className={
                                                        'basic-single' +
                                                        (values.pergolaAge ? ' filled' : '') +
                                                        (touched.pergolaAge && errors.pergolaAge ? ' invalid' : '') +
                                                        (!values.pergolaAge ? ' required' : '')
                                                    }
                                                    placeholder={getHouseAge ? getHouseAge : minYear}
                                                    styles={colourStyles}
                                                />
                                                {touched.pergolaAge && errors.pergolaAge && <div className="invalid-feedback">{errors.pergolaAge}</div>}
                                            </div>
                                        )}
                                    </div>
                                )}

                                <div className={values.projectType === '1' || values.projectType === '4' ? `row mb-3 row-bt` : `row mb-3`}>
                                    <div className="col-12 col-md-6">
                                        <CheckboxService
                                            handleCheckbox={waterSoftener}
                                            values={values}
                                            isValid={isValid}
                                            setValues={setValues}
                                            name={'waterSoftener'}
                                            propName={values.waterSoftener}
                                            title={"Présence d'un adoucisseur d'eau ?"}
                                            emptyLabel={true}
                                            disabled={auditSent}
                                        />
                                    </div>

                                    {values.waterSoftener && (
                                        <div className="col-12 col-md-6">
                                            <CheckboxService
                                                handleCheckbox={waterSoftenerOver10Years}
                                                values={values}
                                                isValid={isValid}
                                                setValues={setValues}
                                                name={'waterSoftenerOver10Years'}
                                                propName={values.waterSoftenerOver10Years}
                                                title={"L'adoucisseur d'eau a été installé il y a plus de 10 ans ?"}
                                                emptyLabel={true}
                                                disabled={auditSent}
                                            />
                                        </div>
                                    )}
                                </div>

                                <div className="row row-bt mb-3">
                                    <div className="col-12 col-md-6">
                                        <CheckboxService
                                            handleCheckbox={ledLighting}
                                            values={values}
                                            isValid={isValid}
                                            setValues={setValues}
                                            name={'ledLighting'}
                                            propName={values.ledLighting}
                                            title={'Eclairage LED majoritaire (>50%) ?'}
                                            emptyLabel={true}
                                            disabled={auditSent}
                                        />
                                    </div>
                                </div>

                                <div className="row row-bt mb-3">
                                    <div className="col-12 col-md-6">
                                        <CheckboxService
                                            handleCheckbox={alarm}
                                            values={values}
                                            isValid={isValid}
                                            setValues={setValues}
                                            name={'alarm'}
                                            propName={values.alarm}
                                            title={"Présence d'une alarme dans l'habitation ?"}
                                            emptyLabel={true}
                                            disabled={auditSent}
                                        />
                                    </div>

                                    {values.alarm && (
                                        <div className="col-12 col-md-6">
                                            <CheckboxService
                                                handleCheckbox={alarmOver10Years}
                                                values={values}
                                                isValid={isValid}
                                                setValues={setValues}
                                                name={'alarmOver10Years'}
                                                propName={values.alarmOver10Years}
                                                title={"Le système d'alarme a été installé il y a plus de 10 ans ?"}
                                                emptyLabel={true}
                                                disabled={auditSent}
                                            />
                                        </div>
                                    )}
                                </div>

                                {(values.projectType === '1' || values.projectType === '4') && (
                                    <div className="row row-bt mb-3">
                                        <div className="col-12 col-md-6">
                                            <CheckboxService
                                                handleCheckbox={garageDoor}
                                                values={values}
                                                isValid={isValid}
                                                setValues={setValues}
                                                name={'garageDoor'}
                                                propName={values.garageDoor}
                                                title={"Présence d'une porte de garage ?"}
                                                emptyLabel={true}
                                                disabled={auditSent}
                                            />
                                        </div>

                                        {values.garageDoor && (
                                            <div className="col-12 col-md-6">
                                                <CheckboxService
                                                    handleCheckbox={garageDoorMotorized}
                                                    values={values}
                                                    isValid={isValid}
                                                    setValues={setValues}
                                                    name={'garageDoorMotorized'}
                                                    propName={values.garageDoorMotorized}
                                                    title={'La porte de garage est-elle motorisée ?'}
                                                    emptyLabel={true}
                                                    disabled={auditSent}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}

                                {(values.projectType === '1' || values.projectType === '4') && (
                                    <div className="row row-bt mb-3">
                                        <div className="col-12 col-md-6">
                                            <CheckboxService
                                                handleCheckbox={portal}
                                                values={values}
                                                isValid={isValid}
                                                setValues={setValues}
                                                name={'portal'}
                                                propName={values.portal}
                                                title={"Présence d'un portail ?"}
                                                emptyLabel={true}
                                                disabled={auditSent}
                                            />
                                        </div>

                                        {values.portal && (
                                            <div className="col-12 col-md-6">
                                                <CheckboxService
                                                    handleCheckbox={portalMotorized}
                                                    values={values}
                                                    isValid={isValid}
                                                    setValues={setValues}
                                                    name={'portalMotorized'}
                                                    propName={values.portalMotorized}
                                                    title={'Le portail est-il motorisé ?'}
                                                    emptyLabel={true}
                                                    disabled={auditSent}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}

                                <div className="row row-bt mb-3">
                                    <div className="col-12 col-md-6">
                                        <CheckboxService
                                            handleCheckbox={electricVehicle}
                                            values={values}
                                            isValid={isValid}
                                            setValues={setValues}
                                            name={'electricVehicle'}
                                            propName={values.electricVehicle}
                                            title={'Véhicule électrique ?'}
                                            emptyLabel={true}
                                            disabled={auditSent}
                                        />
                                    </div>

                                    {values.electricVehicle && (
                                        <div className="col-12 col-md-6">
                                            <CheckboxService
                                                handleCheckbox={electricalTerminal}
                                                values={values}
                                                isValid={isValid}
                                                setValues={setValues}
                                                name={'electricalTerminal'}
                                                propName={values.electricalTerminal}
                                                title={"Présence d'une borne de recharge électrique ?"}
                                                emptyLabel={true}
                                                disabled={auditSent}
                                            />
                                        </div>
                                    )}
                                </div>

                                <div className="row row-bt mb-3">
                                    <div className="col-12 col-md-6">
                                        <CheckboxService
                                            handleCheckbox={hybridVehicle}
                                            values={values}
                                            isValid={isValid}
                                            setValues={setValues}
                                            name={'hybridVehicle'}
                                            propName={values.hybridVehicle}
                                            title={'Véhicule hybride ?'}
                                            emptyLabel={true}
                                            disabled={auditSent}
                                        />
                                    </div>
                                </div>

                                <div className="row row-bt">
                                    <div className="col-12 col-md-6">
                                        <CheckboxService
                                            handleCheckbox={energyLabels}
                                            values={values}
                                            isValid={isValid}
                                            setValues={setValues}
                                            name={'energyLabels'}
                                            propName={values.energyLabels}
                                            title={'Les étiquettes énergétiques des appareils électroménager sont-elles majoritairement des A++ ?'}
                                            emptyLabel={true}
                                            disabled={auditSent}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="navigation text-center">
                            <button className={'btn btn-secondary maxi-min-width mx-2 mb-3'} onClick={() => returnToHome(2, '/audit')}>
                                Retour au menu principal
                            </button>
                            <button
                                type="button"
                                onClick={(event: any) => {
                                    event.preventDefault();
                                    if (Object.entries(errors).length !== 0) {
                                        // Préparation liste d'étapes en erreur
                                        let errorsList: string[] = [];
                                        Object.keys(errors).forEach((field: any) => {
                                            // @ts-ignore
                                            if (typeof errors[field] === 'object') {
                                                // @ts-ignore
                                                errors[field].forEach((element: any) => {
                                                    for (let key in element) {
                                                        if (typeof element[key] === 'object') {
                                                            errorsList = [...errorsList, element[key].value];
                                                        } else {
                                                            errorsList = [...errorsList, element[key]];
                                                        }
                                                    }
                                                });
                                            } else {
                                                // @ts-ignore
                                                errorsList = [...errorsList, `${errors[field]}`];
                                            }
                                        });
                                        // Affichage message
                                        AlertSwal.fire({
                                            title: `Champs manquants`,
                                            html: `<p class="mb-0">Vous ne pouvez pas valider l’étape en cours car certains champs n’ont pas été saisis ou sont en erreur:<br/>
                                        <span>${errorsList.toString().replace(/(,)/g, '<br/>')}</span></p>`,
                                            cancelButtonText: 'Continuer la saisie',
                                            showConfirmButton: false,
                                            showCancelButton: true,
                                            focusCancel: true,
                                            showClass: {
                                                popup: 'errors',
                                                backdrop: 'swal2-backdrop-show swal2-backdrop-black',
                                            },
                                            customClass: {
                                                cancelButton: 'btn btn-primary maxi-min-width',
                                            },
                                        });
                                    } else {
                                        validateThenRoute(2, ROUTE_AUDIT_INFO_SYSTEMES_ENERGETIQUES);
                                    }
                                }}
                                className={'btn btn-green maxi-min-width mx-2 mb-3'}
                            >
                                Étape suivante
                            </button>
                        </div>
                    </div>
                );
            }}
        </Formik>
    );
};

export default HomeInformations;
