import React, { Suspense } from 'react';
import Routes from './routing/Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import CookieBanner from './services/componentsHelpers/cookieBanner';
import { RecoilRoot } from 'recoil';

import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';

const cacheBusterProps = {
    currentVersion: packageInfo.version,
    isEnabled: process.env.NODE_ENV === 'production', //If false, the library is disabled.
    isVerboseMode: true, //If true, the library writes verbose logs to console.
    //loadingComponent:<Loading /> //If not pass, nothing appears at the time of new version check.
    metaFileDirectory: '.', // /If public assets are hosted somewhere other than root on your server
};

function App() {
    return (
        <main className="App">
            <CacheBuster {...cacheBusterProps}>
                <Suspense fallback={<></>}>
                    <RecoilRoot>
                        <Router>
                            <CookiesProvider>
                                <Routes />
                            </CookiesProvider>
                        </Router>
                    </RecoilRoot>
                </Suspense>
            </CacheBuster>

            <CookieBanner />
        </main>
    );
}

export default App;
