import React from 'react';
import { LabelledString } from '../../../../services/tools/TypeHelper';
import { FinancoSimulation } from '../../../../services/apiParticulierService';

// Icons
import { ReactComponent as IconCheck } from '../../../../assets/icons/icon-check-list.svg';

interface Financial {
    list: FinancoSimulation | null;
}

export const FinancialList: React.FC<Financial> = ({ list }) => {
    // Transform response into {label, value}
    const listMap: Array<LabelledString> = [
        {
            label: 'Montant de la commande',
            value: list ? `${list?.purchaseAmount} €` : '',
        },
        {
            label: 'Versement comptant',
            value: list ? `${list?.depositAmount} €` : '',
        },
        {
            label: 'Montant total du crédit',
            value: list ? `${list?.loanAmount} €` : '',
        },
        {
            label: 'Frais de dossier',
            value: list ? `${list?.fees} €` : '',
        },
        {
            label: 'Durée du contrat de crédit',
            value: list ? `${list?.contractPeriod} mois` : '',
        },
        {
            label: 'Délais avant le paiement des mensualités',
            value: list ? `${list?.paymentPostponement} mois` : '',
        },
        {
            label: 'Nombre de mensualités',
            value: list ? `${list?.numberOfPayments}` : '',
        },
        {
            label: 'Mensualité sans assurance',
            value: list ? `${list?.monthlyPaymentInsuranceNotIncluded} €` : '',
        },
        {
            label: 'Mensualité avec assurance',
            value: list ? `${list?.monthlyPaymentInsuranceIncluded} €` : '',
        },
        {
            label: 'Frais de montant total dû hors assurance',
            value: list ? `${list?.totalAmountInsuranceNotIncluded} €` : '',
        },
        {
            label: 'Montant dû assurances comprises',
            value: list ? `${list?.totalAmountInsuranceIncluded} €` : '',
        },
        {
            label: 'TAEG',
            value: list ? `${list?.annualPercentageRate}%` : '',
        },
        {
            label: 'Taux débiteur fixe',
            value: list ? `${list?.fixedBorrowingRate}%` : '',
        },
        {
            label: "Taux annuel en pourcentage de l'assurance",
            value: list ? `${list?.insuranceAnnualPercentageRate}%` : '',
        },
        {
            label: 'Taux nominal',
            value: list ? `${list?.nominalRate}%` : '',
        },
        {
            label: 'Montant total des intérêts',
            value: list ? `${list?.totalInterestAmount} €` : '',
        },
    ];

    return (
        <>
            <h3 className="financial-title mb-5">
                Votre proposition de financement<sup>*</sup>
            </h3>
            <ul className="financial-list" data-active={list ? 'true' : 'false'}>
                {listMap.map((element, index) => (
                    <li key={index}>
                        <div className="icon">
                            <IconCheck fill="#333" />
                        </div>
                        <span className="label">{element.label}</span>
                        <span className="result">{element.value}</span>
                    </li>
                ))}
            </ul>
        </>
    );
};
