import React from 'react';
import { Field, useFormikContext } from 'formik';
import AppFormField from '../../../../components/Forms/AppFormField';
import AppFormSelectField from '../../../../components/Forms/AppFormSelectField';
import { currencyFormat, LabelledString } from '../../../../services/tools/TypeHelper';
import { initialValues, MarketingOrderProduct, Product, quantityOptions } from '../MarketingEnums';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

// Icons
import { ReactComponent as IconMagnifier } from '../../../../assets/icons/icon-magnifier.svg';

// Images
import Energetique from '../../../../assets/images/espace-agent/shop/1.jpg';
import Ampleur from '../../../../assets/images/espace-agent/shop/2.png';
import Pv from '../../../../assets/images/espace-agent/shop/3.jpg';
import National from '../../../../assets/images/espace-agent/shop/4.jpg';
import Habitat from '../../../../assets/images/espace-agent/shop/5.png';

type KakemonoProps = {
    handleQuantityChange: (product: Product, selectedOption: LabelledString) => void;
};

export const Kakemono: React.FC<KakemonoProps> = ({ handleQuantityChange }) => {
    const { values } = useFormikContext<MarketingOrderProduct>();
    const AlertSwal = withReactContent(Swal);

    const kakemonoPreviewModal = (url: string) => {
        AlertSwal.fire({
            title: '',
            html: <img src={url} alt="Kakemono" className="img-fluid" style={{ width: 'auto', maxHeight: '80vh' }} />,
            width: 400,
            confirmButtonText: 'Fermer',
            customClass: {
                confirmButton: 'btn btn-continue min-width',
            },
            reverseButtons: true,
        });
    };

    const kakemonoImages: Record<string, string> = {
        "Kakemono - Rénovation d'ampleur": Ampleur,
        'Kakemono - Renovation énergétique': Energetique,
        'Kakemono - Installation photovoltaïque': Pv,
        'Kakemono - Réseau national': National,
        'Kakemono - Améliorer votre habitat': Habitat,
    };

    return (
        <>
            <h2 className="ps-4">Roll up - Kakémonos</h2>

            <div className="kakemono-container mb-4 px-4">
                {Object.keys(initialValues)
                    .filter((product) => product.includes('Kakemono'))
                    .map((product, index, array) => {
                        const isLastTwoProducts = index >= array.length - 2;
                        const isQuantityZero = isLastTwoProducts && (!values[product]?.quantity || values[product]?.quantity?.value === '0');

                        return (
                            <div key={product} className="kakemono-item">
                                <div className="kakemono" data-title={isLastTwoProducts ? 'Personnalisable' : ''}>
                                    <div className="kakemono--image" onClick={() => kakemonoPreviewModal(kakemonoImages[product])}>
                                        <img src={kakemonoImages[product]} alt={product} />
                                        <div className="magnifier">
                                            <IconMagnifier width={18} height={18} />
                                        </div>
                                    </div>
                                    <div className="kakemono--content">
                                        <h5 className="kakemono--title mb-3">{product.replace('Kakemono - ', '')}</h5>
                                        <Field
                                            name={`${product}.quantity`}
                                            component={AppFormSelectField}
                                            options={quantityOptions}
                                            onChange={(selectedOption: LabelledString) => handleQuantityChange(product, selectedOption)}
                                            placeholder="Quantité"
                                            inline={false}
                                        />
                                        <p className="kakemono--price">Prix unitaire {currencyFormat(initialValues[product]?.price ?? 0, 2)} HT</p>
                                    </div>
                                    {isLastTwoProducts && (
                                        <>
                                            <Field
                                                labelName="Nom"
                                                name={`${product}.customization.name`}
                                                component={AppFormField}
                                                inline={false}
                                                disabled={isQuantityZero}
                                                isRequired
                                                placeholder="Nom"
                                                customClass="kakemono-field"
                                                className={'form-control'}
                                                data-required={!isQuantityZero}
                                            />
                                            <Field
                                                labelName="Prénom"
                                                name={`${product}.customization.firstname`}
                                                component={AppFormField}
                                                inline={false}
                                                disabled={isQuantityZero}
                                                placeholder="Prénom"
                                                className={'form-control'}
                                                customClass="kakemono-field"
                                            />
                                            <Field
                                                labelName="Téléphone"
                                                name={`${product}.customization.phone`}
                                                component={AppFormField}
                                                inline={false}
                                                disabled={isQuantityZero}
                                                isRequired
                                                placeholder="Téléphone"
                                                customClass="kakemono-field"
                                                className={'form-control'}
                                                data-required={!isQuantityZero}
                                                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                                    const charCode = e.which ? e.which : e.keyCode;
                                                    if (charCode < 48 || charCode > 57) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        );
                    })}
            </div>
        </>
    );
};
