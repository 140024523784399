import { Labelled, LabelledString, generateNumberList } from '../../services/tools/TypeHelper';
import {
    ModalArrowProps,
    ModalAuventProps,
    ModalChimneyProps,
    ModalCircleProps,
    ModalDormerProps,
    ModalDrawProps,
    ModalInsulationProps,
    ModalObservationProps,
    ModalPacProps,
    ModalPanelProps,
    ModalPhotovoltaiqueProps,
    ModalTextProps,
    ModalType,
    ModalVeluxProps,
    Orientation,
} from '../../services/tools/konvaTools';

export type ExternalProps = {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    paragraph: string;
    onConfirm: (values: any) => void;
    options?: LabelledString[];
};

export const initialValuesText: ModalTextProps = {
    message: '',
};

export const initialPhotovoltaique: ModalPhotovoltaiqueProps = {
    orientation: { label: 'Paysage', value: 'landscape' },
    columns: { label: '1', value: 1 },
    lines: { label: '1', value: 1 },
    color: undefined,
};

export const initialValuesPanel: ModalPanelProps = {
    orientation: { label: 'Paysage', value: 'landscape' },
    columns: { label: '1', value: 1 },
    lines: { label: '1', value: 1 },
    color: undefined,
};

export const initialValuesArrow: ModalArrowProps = {
    lineType: undefined,
    endPointType: undefined,
    color: undefined,
};

export const initialValuesObservation: ModalObservationProps = {
    message: '',
};

export const initialValuesDraw: ModalDrawProps = {
    message: '',
    color: undefined,
    opacity: true,
};

export const initialValuesPac: ModalPacProps = {
    columns: { label: '1', value: 1 },
    lines: { label: '1', value: 1 },
    color: undefined,
    opacity: true,
};

export const initialValuesAuvent: ModalAuventProps = {
    texture: undefined,
};

export const initialValuesInsulation: ModalInsulationProps = {
    color: undefined,
    opacity: true,
};

export const initialValuesVelux: ModalVeluxProps = {
    message: '',
    color: undefined,
};

export const initialValuesChimney: ModalChimneyProps = {
    message: '',
    color: undefined,
};

export const initialValuesDormer: ModalDormerProps = {
    message: '',
    color: undefined,
};

export const initialValuesCircle: ModalCircleProps = {
    message: '',
    color: undefined,
};

export const generateColumnsLines = generateNumberList(1, 30, 1);

export const orientationList: Array<Labelled<Orientation>> = [
    {
        label: 'Paysage',
        value: 'landscape',
    },
    {
        label: 'Portrait',
        value: 'portrait',
    },
];

export const rawOptions: Array<LabelledString> = [
    { label: 'Bleu', value: '#5189b3' },
    { label: 'Vert', value: '#008000' },
    { label: 'Rouge', value: '#FF0000' },
    { label: 'Gris', value: '#808080' },
    { label: 'Indigo', value: '#4B0082' },
    { label: 'Turquoise', value: '#40E0D0' },
    { label: 'Rose', value: '#FFC0CB' },
    { label: 'Jaune', value: '#FFFF00' },
    { label: 'Orange', value: '#FFA500' },
    { label: 'Violet', value: '#EE82EE' },
    { label: 'Noir', value: '#000000' },
    { label: 'Blanc', value: '#FFF' },
    { label: 'Ecru', value: '#faeee3' },
];

export const textureOptions: Array<LabelledString> = [
    {
        label: 'Bois',
        value: 'wood',
    },
    {
        label: 'Acier',
        value: 'steel',
    },
];

export const lineOptions: Array<LabelledString> = [
    {
        label: 'Trait',
        value: 'solid',
    },
    {
        label: 'Pointillés',
        value: 'dashed',
    },
];

export const endPointOptions: Array<LabelledString> = [
    {
        label: 'Ligne',
        value: 'line',
    },
    {
        label: 'Flèche',
        value: 'arrow',
    },
    {
        label: 'Double flèche',
        value: 'doubleArrow',
    },
];

export const defaultOption: Array<LabelledString> = [
    { label: 'Noir', value: '#000000' },
    { label: 'Aluminium', value: '#848789' },
];

export type ModalDP5Props = {
    title: string;
    paragraph: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    onConfirm: (values: any) => void;
    modalType: ModalType;
};
