import React from 'react';

/**
 * Fonction pour vérifier le type d'une valeur et la convertir si nécessaire
 * @param value (Valeur à convertir si nécessaire)
 */
export const checkNumber = (value: string | number) => {
    if (typeof value === 'string') {
        return parseFloat(value.toString());
    }
    return value;
};

/**
 * Fonction pour retourner l'index de l'élément de la liste des éléments
 * @param elementList (liste complète des éléments)
 * @param value (valeur à chercher)
 */
export const returnIndexElementIntoElementList = (elementList: { label: string, value: string, icon?: string }[], value: string) => {
    const element = elementList.find((element: any) => element.value === value);
    if (element) {
        return elementList.indexOf(element);
    }
    return 0;
};

/**
 * Fonction pour vérifier le type d'une valeur et la convertir si nécessaire
 * @param screenWidth (LONGUEUR TOTALE de la div contenant le plan)
 * @param screenHeight (LARGEUR TOTALE de la div contenant le plan)
 * @param shape (Forme du plan)
 * @param planWidth (LARGEUR TOTALE du plan)
 * @param planLength (LONGUEUR TOTALE du plan)
 * @param L1
 * @param L2
 * @param L3
 * @param L4
 * @param L5
 * @param L6
 * @param L7
 * @param L8
 */
export const RenderShape = (screenWidth: number, screenHeight: number, shape: string, planWidth: number, planLength: number, L1: number, L2: number, L3: number, L4: number, L5: number, L6: number, L7: number, L8: number) => {

    /**
     * Ajout d'un multiplicateur pour dessiner les polygones
     */
    planWidth = planWidth * 10
    planLength = planLength * 10
    L1 = L1 * 10
    L2 = L2 * 10
    L3 = L3 * 10
    L4 = L4 * 10
    L5 = L5 * 10
    L6 = L6 * 10
    L7 = L7 * 10
    L8 = L8 * 10

    /**
     * Définition de la tolérance pour coller les barres et les textes
     */
    const tolerance = 3;
    let textTolerance = tolerance + 2;
    if (planLength > 1000 || planWidth > 1000) {
        textTolerance = tolerance + 10;
    }

    /**
     * Calcul de la taille de la police
     */
    let fontSize = planWidth / 37;
    if (fontSize < 2.25) {
        fontSize = 2.25;
    }
    if (fontSize > 30) {
        fontSize = 30;
    }

    const viewBox = `-${tolerance * 15} -${tolerance * 15} ` + (planLength + (tolerance * 30)) + ' ' + (planWidth + (tolerance * 30));

    return (
        <div id="map">
            {/*    Forme: Rectangle    */}
            {shape === '1' &&
            <>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}>
                    <defs>
                        <marker className="arrow" id="arrow" viewBox="0 0 10 10" refX="10" refY="5" markerWidth="1" markerHeight="1"
                                markerUnits="userSpaceOnUse" orient="auto-start-reverse">
                            <path d="M 0 0 L 10 5 L 0 10 z"></path>
                        </marker>
                    </defs>
                    <polygon stroke="#828282" strokeWidth="0.1" strokeOpacity="0.25" fill="#E9F6D9"
                             points={'' + planLength + ' 0 0 0 0 ' + planWidth + ' ' + planLength + ' ' + planWidth + ' ' + planLength + ' 0'}/>
                    <g id='planLength'>
                        <text x={planLength / 2} y={-textTolerance} fontSize={fontSize}>LONGUEUR TOTALE<tspan fontSize={fontSize} fontWeight="600"> {(planLength / 10).toFixed(2)}m</tspan></text>
                        <line x1="0" x2={planLength} y1={-tolerance} y2={-tolerance}></line>
                    </g>
                    <g id='planWidth'>
                        <text x={planLength + textTolerance} y={planWidth / 2} fontSize={fontSize} transform={'rotate(90 ' + (planLength + textTolerance) + ',' + planWidth / 2 + ')'}>
                            LARGEUR TOTALE<tspan fontSize={fontSize} fontWeight="600"> {(planWidth / 10).toFixed(2)}m</tspan></text>
                        <line x1={planLength + tolerance} x2={planLength + tolerance} y1={0} y2={planWidth}></line>
                    </g>
                </svg>
            </>
            }
            {/*    Forme: L    */}
            {shape === '11' &&
            <>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}>
                    <defs>
                        <marker className="arrow" id="arrow" viewBox="0 0 10 10" refX="10" refY="5" markerWidth="1" markerHeight="1"
                                markerUnits="userSpaceOnUse" orient="auto-start-reverse">
                            <path d="M 0 0 L 10 5 L 0 10 z"></path>
                        </marker>
                    </defs>
                    <polygon stroke="#828282" strokeWidth="0.1" strokeOpacity="0.25" fill="#E9F6D9"
                             points={'' + planLength + ' 0 0 0 0 ' + L2 + ' ' + L1 + ' ' + L2 + ' ' + L1 + ' ' + planWidth + ' ' + planLength + ' ' + planWidth + ' ' + planLength + ' 0'}/>
                     <g id='planLength'>
                         <text x={planLength / 2} y={-textTolerance} fontSize={fontSize}>LONGUEUR TOTALE<tspan fontSize={fontSize} fontWeight="600"> {(planLength / 10).toFixed(2)}m</tspan></text>
                         <line x1="0" x2={planLength} y1={-tolerance} y2={-tolerance}></line>
                     </g>
                    <g id='planWidth'>
                        <text x={planLength + textTolerance} y={planWidth / 2} fontSize={fontSize} transform={'rotate(90 ' + (planLength + textTolerance) + ',' + planWidth / 2 + ')'}>
                            LARGEUR TOTALE<tspan fontSize={fontSize} fontWeight="600"> {(planWidth / 10).toFixed(2)}m</tspan></text>
                        <line x1={planLength + tolerance} x2={planLength + tolerance} y1={0} y2={planWidth}></line>
                    </g>
                    <g id='L1'>
                        <text x={L1 / 2} y={L2 + textTolerance + textTolerance} fontSize={fontSize}>
                            L1<tspan fontSize={fontSize} fontWeight="600"> {(L1 / 10).toFixed(2)}m</tspan></text>
                        <line x1={0} x2={L1} y1={L2 + tolerance} y2={L2 + tolerance}></line>
                    </g>
                    <g id='L2'>
                        <text x={-(L2 / 2)} y={-textTolerance} fontSize={fontSize} transform={'rotate(-90)'}>
                            L2<tspan fontSize={fontSize} fontWeight="600"> {(L2 / 10).toFixed(2)}m</tspan></text>
                        <line x1={0 - tolerance} x2={0 - tolerance} y1={0} y2={L2}></line>
                    </g>
                </svg>
            </>
            }
            {/*    Forme: L inversé    */}
            {shape === '12' &&
            <>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}>
                    <defs>
                        <marker className="arrow" id="arrow" viewBox="0 0 10 10" refX="10" refY="5" markerWidth="1" markerHeight="1"
                                markerUnits="userSpaceOnUse" orient="auto-start-reverse">
                            <path d="M 0 0 L 10 5 L 0 10 z"></path>
                        </marker>
                    </defs>
                    <polygon stroke="#828282" strokeWidth="0.1" strokeOpacity="0.25" fill="#E9F6D9"
                             points={'' + planLength + ' ' + planWidth + ' 0 ' + planWidth + ' 0 ' + L2 + ' ' + L1 + ' ' + L2 + ' ' + L1 + ' 0 ' + planLength + ' 0 ' + planLength + ' ' + planWidth + ''}/>
                    <g id='planLength'>
                        <text x={planLength / 2} y={-textTolerance} fontSize={fontSize}>LONGUEUR TOTALE<tspan fontSize={fontSize} fontWeight="600"> {(planLength / 10).toFixed(2)}m</tspan></text>
                        <line x1="0" x2={planLength} y1={-tolerance} y2={-tolerance}></line>
                    </g>
                    <g id='planWidth'>
                        <text x={planLength + textTolerance} y={planWidth / 2} fontSize={fontSize} transform={'rotate(90 ' + (planLength + textTolerance) + ',' + planWidth / 2 + ')'}>
                            LARGEUR TOTALE<tspan fontSize={fontSize} fontWeight="600"> {(planWidth / 10).toFixed(2)}m</tspan></text>
                        <line x1={planLength + tolerance} x2={planLength + tolerance} y1={0} y2={planWidth}></line>
                    </g>
                    <g id='L1'>
                        <text x={L1 / 2} y={L2 + textTolerance +2} fontSize={fontSize}>L1<tspan fontSize={fontSize} fontWeight="600"> {(L1 / 10).toFixed(2)}m</tspan></text>
                        <line x1={0} x2={L1} y1={L2 + tolerance} y2={L2 + tolerance}></line>
                    </g>
                    <g id='L2'>
                        <text x={L1 + textTolerance} y={L2 / 2} fontSize={fontSize} transform={'rotate(90 ' + (L1 + textTolerance) + ',' + L2 / 2 + ')'}>
                            L2<tspan fontSize={fontSize} fontWeight="600"> {(L2 / 10).toFixed(2)}m</tspan></text>
                        <line x1={L1 + tolerance} x2={L1 + tolerance} y1={0} y2={L2}></line>
                    </g>
                </svg>
            </>
            }
            {/*    Forme: T    */}
            {shape === '21' &&
            <>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}>
                    <defs>
                        <marker className="arrow" id="arrow" viewBox="0 0 10 10" refX="10" refY="5" markerWidth="1" markerHeight="1"
                                markerUnits="userSpaceOnUse" orient="auto-start-reverse">
                            <path d="M 0 0 L 10 5 L 0 10 z"></path>
                        </marker>
                    </defs>
                    <polygon stroke="#828282" strokeWidth="0.1" strokeOpacity="0.25" fill="#E9F6D9"
                             points={'' + planLength + ' 0 0 0 0 ' + (planWidth - L2) + ' ' + L1 + ' ' + (planWidth - L2) + ' ' + L1 + ' ' + planWidth + ' ' + (planLength - L3) + ' ' + planWidth + ' ' + (planLength - L3) + ' ' + (planWidth - L4) + ' ' + planLength + ' ' + (planWidth - L4) + ' ' + planLength + ' 0'}/>
                    <g id='planLength'>
                        <text x={planLength / 2} y={-textTolerance} fontSize={fontSize}>LONGUEUR TOTALE<tspan fontSize={fontSize} fontWeight="600"> {(planLength / 10).toFixed(2)}m</tspan></text>
                        <line x1="0" x2={planLength} y1={-tolerance} y2={-tolerance}></line>
                    </g>
                    <g id='planWidth'>
                        <text x={planLength + textTolerance} y={planWidth / 2} fontSize={fontSize} transform={'rotate(90 ' + (planLength + textTolerance) + ',' + planWidth / 2 + ')'}>
                            LARGEUR TOTALE<tspan fontSize={fontSize} fontWeight="600"> {(planWidth / 10).toFixed(2)}m</tspan></text>
                        <line x1={planLength + tolerance} x2={planLength + tolerance} y1={0} y2={planWidth}></line>
                    </g>
                    <g id='L1'>
                        <text x={L1 / 2} y={(planWidth - L2) - textTolerance} fontSize={fontSize}>L1<tspan fontSize={fontSize} fontWeight="600"> {(L1 / 10).toFixed(2)}m</tspan></text>
                        <line x1={0} x2={L1} y1={(planWidth - L2) - tolerance} y2={(planWidth - L2) - tolerance}></line>
                    </g>
                    <g id='L2'>
                        <text x={-(planWidth - L2 + (L2/2))} y={L1 - textTolerance} fontSize={fontSize} transform={'rotate(-90)'}>
                            L2<tspan fontSize={fontSize} fontWeight="600"> {(L2 / 10).toFixed(2)}m</tspan></text>
                        <line x1={L1 - tolerance} x2={L1 - tolerance} y1={(planWidth - L2)} y2={planWidth}></line>
                    </g>
                    <g id='L3'>
                        <text x={planLength - (L3/2)} y={(planWidth - L4) - textTolerance} fontSize={fontSize}>L3<tspan fontSize={fontSize} fontWeight="600"> {(L3 / 10).toFixed(2)}m</tspan></text>
                        <line x1={planLength - L3} x2={planLength} y1={(planWidth - L4) - tolerance} y2={(planWidth - L4) - tolerance}></line>
                    </g>
                    <g id='L4'>
                        <text x={(planWidth - (L4/2))} y={-(planLength- L3 + textTolerance)} fontSize={fontSize} transform={'rotate(90 ' + 0 + ',' + 0 + ')'}>
                            L4<tspan fontSize={fontSize} fontWeight="600"> {(L4 / 10).toFixed(2)}m</tspan></text>
                        <line x1={planLength - L3 + tolerance} x2={planLength - L3 + tolerance} y1={(planWidth - L4)} y2={planWidth}></line>
                    </g>
                </svg>
            </>
            }
            {/*    Forme: T inversé    */}
            {shape === '22' &&
            <>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}>
                    <defs>
                        <marker className="arrow" id="arrow" viewBox="0 0 10 10" refX="10" refY="5" markerWidth="1" markerHeight="1"
                                markerUnits="userSpaceOnUse" orient="auto-start-reverse">
                            <path d="M 0 0 L 10 5 L 0 10 z"></path>
                        </marker>
                    </defs>
                    <polygon stroke="#828282" strokeWidth="0.1" strokeOpacity="0.25" fill="#E9F6D9"
                             points={'0 ' + planWidth + ' 0 0 ' + (planLength - L1) + ' 0 ' + (planLength - L1) + ' ' + L2 + ' ' + planLength + ' ' + L2 + ' ' + planLength + ' ' + (planWidth - L4) + ' ' + (planLength - L3) + ' ' + (planWidth - L4) + ' ' + (planLength - L3) + ' ' + planWidth + ' 0 ' + planWidth + ''}/>
                    <g id='planLength'>
                        <text x={planLength / 2} y={-textTolerance} fontSize={fontSize}>LONGUEUR TOTALE<tspan fontSize={fontSize} fontWeight="600"> {(planLength / 10).toFixed(2)}m</tspan></text>
                        <line x1="0" x2={planLength} y1={-tolerance} y2={-tolerance}></line>
                    </g>
                    <g id='planWidth'>
                        <text x={planLength + textTolerance} y={planWidth / 2} fontSize={fontSize} transform={'rotate(90 ' + (planLength + textTolerance) + ',' + planWidth / 2 + ')'}>
                            LARGEUR TOTALE<tspan fontSize={fontSize} fontWeight="600"> {(planWidth / 10).toFixed(2)}m</tspan></text>
                        <line x1={planLength + tolerance} x2={planLength + tolerance} y1={0} y2={planWidth}></line>
                    </g>
                    <g id='L1'>
                        <text x={planLength - (L1/2)} y={L2 - textTolerance} fontSize={fontSize}>L1<tspan fontSize={fontSize} fontWeight="600"> {(L1 / 10).toFixed(2)}m</tspan></text>
                        <line x1={planLength - L1} x2={planLength} y1={L2 - tolerance} y2={L2 - tolerance}></line>
                    </g>
                    <g id='L2'>
                        <text x={-(L2/2)} y={planLength - L1 - textTolerance} fontSize={fontSize} transform={'rotate(-90)'}>
                            L2<tspan fontSize={fontSize} fontWeight="600"> {(L2 / 10).toFixed(2)}m</tspan></text>
                        <line x1={planLength - L1 - tolerance} x2={planLength - L1 - tolerance} y1={0} y2={L2}></line>
                    </g>
                    <g id='L3'>
                        <text x={planLength -(L3/ 2)} y={planWidth - L4 + tolerance + tolerance} fontSize={fontSize}>L3<tspan fontSize={fontSize} fontWeight="600"> {(L3 / 10).toFixed(2)}m</tspan></text>
                        <line x1={planLength - L3} x2={planLength} y1={planWidth - L4 + tolerance} y2={planWidth - L4 + tolerance}></line>
                    </g>
                    <g id='L4'>
                        <text x={-(planWidth - (L4/2))} y={planLength - L3 - textTolerance} fontSize={fontSize} transform={'rotate(-90)'}>
                            L4<tspan fontSize={fontSize} fontWeight="600"> {(L4 / 10).toFixed(2)}m</tspan></text>
                        <line x1={planLength - L3 - tolerance} x2={planLength - L3 - tolerance} y1={planWidth - L4} y2={planWidth}></line>
                    </g>
                </svg>
            </>
            }
            {/*    Forme: U    */}
            {shape === '31' &&
            <>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}>
                    <defs>
                        <marker className="arrow" id="arrow" viewBox="0 0 10 10" refX="10" refY="5" markerWidth="1" markerHeight="1"
                                markerUnits="userSpaceOnUse" orient="auto-start-reverse">
                            <path d="M 0 0 L 10 5 L 0 10 z"></path>
                        </marker>
                    </defs>
                    <polygon stroke="#828282" strokeWidth="0.1" strokeOpacity="0.25" fill="#E9F6D9"
                             points={'0 0 ' + planLength + ' 0 ' + planLength + ' ' + planWidth + ' ' + (L4 + L1) + ' ' + planWidth + ' ' + (L4 + L1) + ' ' + (planWidth - L2) + ' ' + L4 + ' ' + (planWidth - L2) + ' ' + L4 + ' ' + planWidth + ' 0 ' + planWidth + ' 0 0'}/>
                    <g id='planLength'>
                        <text x={planLength / 2} y={-textTolerance} fontSize={fontSize}>LONGUEUR TOTALE<tspan fontSize={fontSize} fontWeight="600"> {(planLength / 10).toFixed(2)}m</tspan></text>
                        <line x1="0" x2={planLength} y1={-tolerance} y2={-tolerance}></line>
                    </g>
                    <g id='planWidth'>
                        <text x={planLength + textTolerance} y={planWidth / 2} fontSize={fontSize} transform={'rotate(90 ' + (planLength + textTolerance) + ',' + planWidth / 2 + ')'}>
                            LARGEUR TOTALE<tspan fontSize={fontSize} fontWeight="600"> {(planWidth / 10).toFixed(2)}m</tspan></text>
                        <line x1={planLength + tolerance} x2={planLength + tolerance} y1={0} y2={planWidth}></line>
                    </g>
                    <g id='L1'>
                        <text x={L4 + (L1 / 2)} y={planWidth - L2 - textTolerance} fontSize={fontSize}>L1<tspan fontSize={fontSize} fontWeight="600"> {(L1 / 10).toFixed(2)}m</tspan></text>
                        <line x1={L4} x2={L1 + L4} y1={planWidth - L2 - tolerance} y2={planWidth - L2 - tolerance}></line>
                    </g>
                    <g id='L2'>
                        <text x={planWidth - (L2/2)} y={-(L1 + L4 + textTolerance)} fontSize={fontSize} transform={'rotate(90 0, 0)'}>
                            L2<tspan fontSize={fontSize} fontWeight="600"> {(L2 / 10).toFixed(2)}m</tspan></text>
                        <line x1={L1 + L4 + tolerance} x2={L1 + L4 + tolerance} y1={planWidth - L2} y2={planWidth}></line>
                    </g>
                    <g id='L4'>
                        <text x={L4 / 2} y={planWidth - textTolerance} fontSize={fontSize}>L4<tspan fontSize={fontSize} fontWeight="600"> {(L4 / 10).toFixed(2)}m</tspan></text>
                        <line x1={0} x2={L4} y1={planWidth - tolerance} y2={planWidth - tolerance}></line>
                    </g>
                </svg>
            </>
            }
            {/*    Forme: U inversé    */}
            {shape === '32' &&
            <>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}>
                    <defs>
                        <marker className="arrow" id="arrow" viewBox="0 0 10 10" refX="10" refY="5" markerWidth="1" markerHeight="1"
                                markerUnits="userSpaceOnUse" orient="auto-start-reverse">
                            <path d="M 0 0 L 10 5 L 0 10 z"></path>
                        </marker>
                    </defs>
                    <polygon stroke="#828282" strokeWidth="0.1" strokeOpacity="0.25" fill="#E9F6D9"
                             points={'0 ' + planWidth + ' 0 0 ' + planLength + ' 0 ' + planLength + ' ' + ((planWidth - L4) - L1) + ' ' + (planLength - L2) + ' ' + ((planWidth - L4) - L1) + ' ' + (planLength - L2) + ' ' + (planWidth - L4) + ' ' + planLength + ' ' + (planWidth - L4) + ' ' + planLength + ' ' + planWidth + ' 0 ' + planWidth + ''}/>
                    <g id='planLength'>
                        <text x={planLength / 2} y={-textTolerance} fontSize={fontSize}>LONGUEUR TOTALE<tspan fontSize={fontSize} fontWeight="600"> {(planLength / 10).toFixed(2)}m</tspan></text>
                        <line x1="0" x2={planLength} y1={-tolerance} y2={-tolerance}></line>
                    </g>
                    <g id='planWidth'>
                        <text x={planLength + textTolerance} y={planWidth / 2} fontSize={fontSize} transform={'rotate(90 ' + (planLength + textTolerance) + ',' + planWidth / 2 + ')'}>
                            LARGEUR TOTALE<tspan fontSize={fontSize} fontWeight="600"> {(planWidth / 10).toFixed(2)}m</tspan></text>
                        <line x1={planLength + tolerance} x2={planLength + tolerance} y1={0} y2={planWidth}></line>
                    </g>
                    <g id='L1'>
                        <text x={-(planWidth - L4 - (L1/2))} y={planLength - L2 - textTolerance} fontSize={fontSize} transform={'rotate(-90)'}>
                            L1<tspan fontSize={fontSize} fontWeight="600"> {(L1 / 10).toFixed(2)}m</tspan></text>
                        <line x1={planLength - L2 - tolerance} x2={planLength - L2 - tolerance} y1={planWidth - L4 - L1} y2={planWidth - L4}></line>
                    </g>
                    <g id='L2'>
                        <text x={planLength - (L2/2)} y={planWidth - L1 - L4 - textTolerance} fontSize={fontSize}>L2<tspan fontSize={fontSize} fontWeight="600"> {(L2 / 10).toFixed(2)}m</tspan></text>
                        <line x1={planLength - L2} x2={planLength} y1={planWidth - L1 - L4 - tolerance} y2={planWidth - L1 - L4 - tolerance}></line>
                    </g>
                    <g id='L4'>
                        <text x={-(planWidth - (L4/2))} y={planLength - L3 - textTolerance} fontSize={fontSize} transform={'rotate(-90)'}>
                            L4<tspan fontSize={fontSize} fontWeight="600"> {(L4 / 10).toFixed(2)}m</tspan></text>
                        <line x1={planLength - L3 - tolerance} x2={planLength - L3 - tolerance} y1={planWidth - L4} y2={planWidth}></line>
                    </g>
                </svg>
            </>
            }
            {/*     Forme: Y    */}
            {shape === '41' &&
            <>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}>
                    <defs>
                        <marker className="arrow" id="arrow" viewBox="0 0 10 10" refX="10" refY="5" markerWidth="1" markerHeight="1"
                                markerUnits="userSpaceOnUse" orient="auto-start-reverse">
                            <path d="M 0 0 L 10 5 L 0 10 z"></path>
                        </marker>
                    </defs>
                    <polyline stroke="#828282" strokeWidth="0.1" strokeOpacity="0.25" fill="#E9F6D9"
                              points={'' + (planLength - L6) + ' 0 ' + L2 + ' 0 ' + L2 + ' ' + L1 + ' 0 ' + L1 + ' 0 ' + (planWidth - L3) + ' ' + L4 + ' ' + (planWidth - L3) + ' ' + L4 + ' ' + planWidth + ' ' + planLength + ' ' + planWidth + ' ' + planLength + ' ' + L5 + ' ' + (planLength - L6) + ' ' + L5 + ' ' + (planLength - L6) + ' 0 ' + L2 + ' 0'}/>
                    <g id='planLength'>
                        <text x={planLength / 2} y={-textTolerance} fontSize={fontSize}>LONGUEUR TOTALE<tspan fontSize={fontSize} fontWeight="600"> {(planLength / 10).toFixed(2)}m</tspan></text>
                        <line x1="0" x2={planLength} y1={-tolerance} y2={-tolerance}></line>
                    </g>
                    <g id='planWidth'>
                        <text x={planLength + textTolerance} y={planWidth / 2} fontSize={fontSize} transform={'rotate(90 ' + (planLength + textTolerance) + ',' + planWidth / 2 + ')'}>
                            LARGEUR TOTALE<tspan fontSize={fontSize} fontWeight="600"> {(planWidth / 10).toFixed(2)}m</tspan></text>
                        <line x1={planLength + tolerance} x2={planLength + tolerance} y1={0} y2={planWidth}></line>
                    </g>
                    <g id='L1'>
                        <text x={L2 + textTolerance} y={L1 / 2} fontSize={fontSize} transform={'rotate(90 ' + (L2 + textTolerance) + ',' + L1 / 2 + ')'}>
                            L1<tspan fontSize={fontSize} fontWeight="600"> {(L1 / 10).toFixed(2)}m</tspan></text>
                        <line x1={L2 + tolerance} x2={L2 + tolerance} y1={0} y2={L1}></line>
                    </g>
                    <g id='L2'>
                        <text x={L2/2} y={L1 - textTolerance} fontSize={fontSize}>L2<tspan fontSize={fontSize} fontWeight="600"> {(L2 / 10).toFixed(2)}m</tspan></text>
                        <line x1={0} x2={L2} y1={L1 - tolerance} y2={L1 - tolerance}></line>
                    </g>
                    <g id='L3'>
                        <text x={planWidth - (L3/2)} y={-(L4 + textTolerance)} fontSize={fontSize} transform={'rotate(90 0, 0)'}>
                            L3<tspan fontSize={fontSize} fontWeight="600"> {(L3 / 10).toFixed(2)}m</tspan></text>
                        <line x1={L4 + tolerance} x2={L4 + tolerance} y1={planWidth - L3} y2={planWidth}></line>
                    </g>
                    <g id='L4'>
                        <text x={L4/ 2} y={planWidth - L3 + tolerance + textTolerance} fontSize={fontSize}>L4<tspan fontSize={fontSize} fontWeight="600"> {(L4 / 10).toFixed(2)}m</tspan></text>
                        <line x1={0} x2={L4} y1={planWidth - L3 + tolerance} y2={planWidth - L3 + tolerance}></line>
                    </g>
                    <g id='L5'>
                        <text x={-(L5/2)} y={planLength - L6 - textTolerance} fontSize={fontSize} transform={'rotate(-90)'}>
                            L5<tspan fontSize={fontSize} fontWeight="600"> {(L5 / 10).toFixed(2)}m</tspan></text>
                        <line x1={planLength - L6 - tolerance} x2={planLength - L6 - tolerance} y1={0} y2={L5}></line>
                    </g>
                    <g id='L6'>
                        <text x={planLength - (L6/2)} y={L5 - textTolerance} fontSize={fontSize}>L6<tspan fontSize={fontSize} fontWeight="600"> {(L6 / 10).toFixed(2)}m</tspan></text>
                        <line x1={planLength - L6} x2={planLength} y1={L5 - tolerance} y2={L5 - tolerance}></line>
                    </g>
                </svg>
            </>
            }
            {/*     Forme: Y inversé    */}
            {shape === '42' &&
            <>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}>
                    <defs>
                        <marker className="arrow" id="arrow" viewBox="0 0 10 10" refX="10" refY="5" markerWidth="1" markerHeight="1"
                                markerUnits="userSpaceOnUse" orient="auto-start-reverse">
                            <path d="M 0 0 L 10 5 L 0 10 z"></path>
                        </marker>
                    </defs>
                    <polyline stroke="#828282" strokeWidth="0.1" strokeOpacity="0.25" fill="#E9F6D9"
                              points={'' + L4 + ' ' + planWidth + ' ' + L4 + ' ' + planWidth + ' ' + L4 + ' ' + (planWidth - L3) + ' 0 ' + (planWidth - L3) + ' 0 ' + L1 + ' ' + L2 + ' ' + L1 + ' ' + L2 + ' 0 ' + planLength + ' 0 ' + planLength + ' ' + (planWidth - L5) + ' ' + (planLength - L6) + ' ' + (planWidth - L5) + ' ' + (planLength - L6) + ' ' + planWidth + ' ' + L4 + ' ' + planWidth + ''}/>
                    <g id='planLength'>
                        <text x={planLength / 2} y={-textTolerance} fontSize={fontSize}>LONGUEUR TOTALE<tspan fontSize={fontSize} fontWeight="600"> {(planLength / 10).toFixed(2)}m</tspan></text>
                        <line x1="0" x2={planLength} y1={-tolerance} y2={-tolerance}></line>
                    </g>
                    <g id='planWidth'>
                        <text x={planLength + textTolerance} y={planWidth / 2} fontSize={fontSize} transform={'rotate(90 ' + (planLength + textTolerance) + ',' + planWidth / 2 + ')'}>
                            LARGEUR TOTALE<tspan fontSize={fontSize} fontWeight="600"> {(planWidth / 10).toFixed(2)}m</tspan></text>
                        <line x1={planLength + tolerance} x2={planLength + tolerance} y1={0} y2={planWidth}></line>
                    </g>
                    <g id='L1'>
                        <text x={L2 + textTolerance} y={L1 / 2} fontSize={fontSize} transform={'rotate(90 ' + (L2 + textTolerance) + ',' + L1 / 2 + ')'}>
                            L1<tspan fontSize={fontSize} fontWeight="600"> {(L1 / 10).toFixed(2)}m</tspan></text>
                        <line x1={L2 + tolerance} x2={L2 + tolerance} y1={0} y2={L1}></line>
                    </g>
                    <g id='L2'>
                        <text x={L2/2} y={L1 - textTolerance} fontSize={fontSize}>L2<tspan fontSize={fontSize} fontWeight="600"> {(L2 / 10).toFixed(2)}m</tspan></text>
                        <line x1={0} x2={L2} y1={L1 - tolerance} y2={L1 - tolerance}></line>
                    </g>
                    <g id='L3'>
                        <text x={planWidth - (L3/2)} y={-(L4 + textTolerance)} fontSize={fontSize} transform={'rotate(90 0, 0)'}>
                            L3<tspan fontSize={fontSize} fontWeight="600"> {(L3 / 10).toFixed(2)}m</tspan></text>
                        <line x1={L4 + tolerance} x2={L4 + tolerance} y1={planWidth - L3} y2={planWidth}></line>
                    </g>
                    <g id='L4'>
                        <text x={L4/ 2} y={planWidth - L3 + tolerance + textTolerance} fontSize={fontSize}>L4<tspan fontSize={fontSize} fontWeight="600"> {(L4 / 10).toFixed(2)}m</tspan></text>
                        <line x1={0} x2={L4} y1={planWidth - L3 + tolerance} y2={planWidth - L3 + tolerance}></line>
                    </g>
                    <g id='L5'>
                        <text x={-(planWidth - (L5/2))} y={planLength - L6 - textTolerance} fontSize={fontSize} transform={'rotate(-90)'}>
                            L5<tspan fontSize={fontSize} fontWeight="600"> {(L5 / 10).toFixed(2)}m</tspan></text>
                        <line x1={planLength - L6 - tolerance} x2={planLength - L6 - tolerance} y1={planWidth - L5} y2={planWidth}></line>
                    </g>
                    <g id='L6'>
                        <text x={planLength - (L6/2)} y={planWidth - L5 - textTolerance} fontSize={fontSize}>L6<tspan fontSize={fontSize} fontWeight="600"> {(L6 / 10).toFixed(2)}m</tspan></text>
                        <line x1={planLength - L6} x2={planLength} y1={planWidth - L5 - tolerance} y2={planWidth - L5 - tolerance}></line>
                    </g>
                </svg>
            </>
            }
            {/*    Forme: X    */}
            {shape === '51' &&
            <>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}>
                    <defs>
                        <marker className="arrow" id="arrow" viewBox="0 0 10 10" refX="10" refY="5" markerWidth="1" markerHeight="1"
                                markerUnits="userSpaceOnUse" orient="auto-start-reverse">
                            <path d="M 0 0 L 10 5 L 0 10 z"></path>
                        </marker>
                    </defs>
                    <polyline stroke="#828282" strokeWidth="0.1" strokeOpacity="0.25" fill="#E9F6D9"
                              points={'' + (planLength - L3) + ' 0 ' + L1 + ' 0 ' + L1 + ' ' + L2 + ' 0 ' + L2 + ' 0 ' + (planWidth - L6) + ' ' + L5 + ' ' + (planWidth - L6) + ' ' + L5 + ' ' + planWidth + ' ' + (planLength - L7) + ' ' + planWidth + ' ' + (planLength - L7) + ' ' + (planWidth - L8) + ' ' + planLength + ' ' + (planWidth - L8) + ' ' + planLength + ' ' + L4 + ' ' + (planLength - L3) + ' ' + L4 + ' ' + (planLength - L3) + ' 0'}/>
                    <g id='planLength'>
                        <text x={planLength / 2} y={-textTolerance} fontSize={fontSize}>LONGUEUR TOTALE<tspan fontSize={fontSize} fontWeight="600"> {(planLength / 10).toFixed(2)}m</tspan></text>
                        <line x1="0" x2={planLength} y1={-tolerance} y2={-tolerance}></line>
                    </g>
                    <g id='planWidth'>
                        <text x={planLength + textTolerance} y={planWidth / 2} fontSize={fontSize} transform={'rotate(90 ' + (planLength + textTolerance) + ',' + planWidth / 2 + ')'}>
                            LARGEUR TOTALE<tspan fontSize={fontSize} fontWeight="600"> {(planWidth / 10).toFixed(2)}m</tspan></text>
                        <line x1={planLength + tolerance} x2={planLength + tolerance} y1={0} y2={planWidth}></line>
                    </g>
                    <g id='L1'>
                        <text x={L1/2} y={L2 - textTolerance} fontSize={fontSize}>L1<tspan fontSize={fontSize} fontWeight="600"> {(L1 / 10).toFixed(2)}m</tspan></text>
                        <line x1={0} x2={L1} y1={L2 - tolerance} y2={L2 - tolerance}></line>
                    </g>
                    <g id='L2'>
                        <text x={L1 + textTolerance} y={L2 / 2} fontSize={fontSize} transform={'rotate(90 ' + (L1 + textTolerance) + ',' + L2 / 2 + ')'}>
                            L2<tspan fontSize={fontSize} fontWeight="600"> {(L2 / 10).toFixed(2)}m</tspan></text>
                        <line x1={L1 + tolerance} x2={L1 + tolerance} y1={0} y2={L2}></line>
                    </g>
                    <g id='L3'>
                        <text x={planLength - (L3/2)} y={L4 - textTolerance} fontSize={fontSize}>L3<tspan fontSize={fontSize} fontWeight="600"> {(L3 / 10).toFixed(2)}m</tspan></text>
                        <line x1={planLength - L3} x2={planLength} y1={L4 - tolerance} y2={L4 - tolerance}></line>
                    </g>
                    <g id='L4'>
                        <text x={-(L4/2)} y={planLength - L3 - textTolerance} fontSize={fontSize} transform={'rotate(-90)'}>
                            L4<tspan fontSize={fontSize} fontWeight="600"> {(L4 / 10).toFixed(2)}m</tspan></text>
                        <line x1={planLength - L3 - tolerance} x2={planLength - L3 - tolerance} y1={0} y2={L4}></line>
                    </g>
                    <g id='L5'>
                        <text x={L5/ 2} y={planWidth - L6 + tolerance + textTolerance} fontSize={fontSize}>L5<tspan fontSize={fontSize} fontWeight="600"> {(L5 / 10).toFixed(2)}m</tspan></text>
                        <line x1={0} x2={L5} y1={planWidth - L6 + tolerance} y2={planWidth - L6 + tolerance}></line>
                    </g>
                    <g id='L6'>
                        <text x={L5 + textTolerance} y={planWidth - (L6 / 2)} fontSize={fontSize} transform={'rotate(90 ' + (L5 + textTolerance) + ',' + (planWidth - (L6 / 2)) + ')'}>
                            L6<tspan fontSize={fontSize} fontWeight="600"> {(L6 / 10).toFixed(2)}m</tspan></text>
                        <line x1={L5 + tolerance} x2={L5 + tolerance} y1={planWidth - L6} y2={planWidth}></line>
                    </g>
                    <g id='L7'>
                        <text x={planLength - (L7/ 2)} y={planWidth - L8 + tolerance + textTolerance} fontSize={fontSize}>L7<tspan fontSize={fontSize} fontWeight="600"> {(L7 / 10).toFixed(2)}m</tspan></text>
                        <line x1={planLength - L7} x2={planLength} y1={planWidth - L8 + tolerance} y2={planWidth - L8 + tolerance}></line>
                    </g>
                    <g id='L8'>
                        <text x={-(planWidth - (L8/2))} y={planLength - L7 - textTolerance} fontSize={fontSize} transform={'rotate(-90)'}>
                            L8<tspan fontSize={fontSize} fontWeight="600"> {(L8 / 10).toFixed(2)}m</tspan></text>
                        <line x1={planLength - L7 - tolerance} x2={planLength - L7 - tolerance} y1={planWidth - L8} y2={planWidth}></line>
                    </g>
                </svg>
            </>
            }
            {/*    Forme: H    */}
            {shape === '52' &&
            <>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}>
                    <defs>
                        <marker className="arrow" id="arrow" viewBox="0 0 10 10" refX="10" refY="5" markerWidth="1" markerHeight="1"
                                markerUnits="userSpaceOnUse" orient="auto-start-reverse">
                            <path d="M 0 0 L 10 5 L 0 10 z"></path>
                        </marker>
                    </defs>
                    <polyline stroke="#828282" strokeWidth="0.1" strokeOpacity="0.25" fill="#E9F6D9"
                              points={'0 0 ' + L6 + ' 0 ' + L6 + ' ' + L7 + ' ' + (L6 + L5) + ' ' + L7 + ' ' + (L6 + L5) + ' 0 ' + planLength + ' 0 ' + planLength + ' ' + planWidth + ' ' + (L4 + L1) + ' ' + planWidth + ' ' + (L4 + L1) + ' ' + (planWidth - L2) + ' ' + L4 + ' ' + (planWidth - L2) + ' ' + L4 + ' ' + planWidth + ' 0 ' + planWidth + ' 0 0'}/>
                    <g id='planLength'>
                        <text x={planLength / 2} y={-textTolerance} fontSize={fontSize}>LONGUEUR TOTALE<tspan fontSize={fontSize} fontWeight="600"> {(planLength / 10).toFixed(2)}m</tspan></text>
                        <line x1="0" x2={planLength} y1={-tolerance} y2={-tolerance}></line>
                    </g>
                    <g id='planWidth'>
                        <text x={planLength + textTolerance} y={planWidth / 2} fontSize={fontSize} transform={'rotate(90 ' + (planLength + textTolerance) + ',' + planWidth / 2 + ')'}>
                            LARGEUR TOTALE<tspan fontSize={fontSize} fontWeight="600"> {(planWidth / 10).toFixed(2)}m</tspan></text>
                        <line x1={planLength + tolerance} x2={planLength + tolerance} y1={0} y2={planWidth}></line>
                    </g>
                    <g id='L1'>
                        <text x={L4 + (L1/2)} y={planWidth - L2 - textTolerance} fontSize={fontSize}>L1<tspan fontSize={fontSize} fontWeight="600"> {(L1 / 10).toFixed(2)}m</tspan></text>
                        <line x1={L4} x2={L4 + L1} y1={planWidth - L2 - tolerance} y2={planWidth - L2 - tolerance}></line>
                    </g>
                    <g id='L2'>
                        <text x={planWidth - (L2/2)} y={-(L1 + L4 + textTolerance)} fontSize={fontSize} transform={'rotate(90 0, 0)'}>
                            L2<tspan fontSize={fontSize} fontWeight="600"> {(L2 / 10).toFixed(2)}m</tspan></text>
                        <line x1={L1 + L4 + tolerance} x2={L1 + L4 + tolerance} y1={planWidth - L2} y2={planWidth}></line>
                    </g>
                    <g id='L4'>
                        <text x={L4/2} y={planWidth - textTolerance} fontSize={fontSize}>L4<tspan fontSize={fontSize} fontWeight="600"> {(L4 / 10).toFixed(2)}m</tspan></text>
                        <line x1={0} x2={L4} y1={planWidth - tolerance} y2={planWidth - tolerance}></line>
                    </g>
                    <g id='L5'>
                        <text x={L6 + (L5/2)} y={L7 - textTolerance} fontSize={fontSize}>L5<tspan fontSize={fontSize} fontWeight="600"> {(L5 / 10).toFixed(2)}m</tspan></text>
                        <line x1={L6} x2={L6 + L5} y1={L7 - tolerance} y2={L7 - tolerance}></line>
                    </g>
                    <g id='L6'>
                        <text x={L6 / 2} y={tolerance + textTolerance} fontSize={fontSize}>L6<tspan fontSize={fontSize} fontWeight="600"> {(L6 / 10).toFixed(2)}m</tspan></text>
                        <line x1={0} x2={L6} y1={tolerance} y2={tolerance}></line>
                    </g>
                    <g id='L7'>
                        <text x={L7/2} y={-(L6 + L5 + textTolerance)} fontSize={fontSize} transform={'rotate(90 0, 0)'}>
                            L7<tspan fontSize={fontSize} fontWeight="600"> {(L7 / 10).toFixed(2)}m</tspan></text>
                        <line x1={L6 + L5 + tolerance} x2={L6 + L5 + tolerance} y1={0} y2={L7}></line>
                    </g>
                </svg>
            </>
            }
        </div>
    );
};
