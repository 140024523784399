import React from 'react';
import { Link } from 'react-router-dom';

// Icon
import { ReactComponent as IconBack } from '../../assets/icons/icon-back.svg';

type TBack = {
    title: string;
    url: string;
};

export const Back: React.FC<TBack> = ({ title, url }) => {
    return (
        <Link to={url} className="link-back">
            <IconBack width={20} />
            &nbsp;{title}
        </Link>
    );
};
