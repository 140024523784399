import React, { useState } from 'react';
import { FormValues } from '../FinancementEnums';
import DatePicker from 'react-datepicker';
import * as storageService from '../../../../services/localStorageService';

import { format, getYear, getMonth } from 'date-fns';
import range from 'lodash/range';

type DatesProps = {
    values: FormValues;
    setValues: (values: React.SetStateAction<FormValues>, shouldValidate?: boolean | undefined) => void;
};

const Dates: React.FC<DatesProps> = ({ values, setValues }) => {
    const localSim = storageService.getSim();
    const birth = localSim?.birthDate?.value;
    let formattedDate: Date | null = null;

    if (birth && birth !== '') {
        // Date format from localStorage => yyyy-MM-dd as string
        const [year, month, day] = birth.split('-');
        formattedDate = new Date(Number(year), Number(month) - 1, Number(day));
    }

    const [startDate, setStartDate] = useState<Date | null>(formattedDate);
    const maxDate: Date = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 18);
    const years = range(1900, getYear(maxDate) + 1, 1);

    const months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

    const handleDate = (date: Date) => {
        setStartDate(date);

        setValues({
            ...values,
            birthDate: format(date, 'yyyy-MM-dd'),
        });

        storageService.setSimValue('birthDate', format(date, 'yyyy-MM-dd'));
    };

    const renderCustomHeader = ({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
    }: {
        date: Date;
        changeYear: (year: number) => void;
        changeMonth: (month: number) => void;
        decreaseMonth: () => void;
        increaseMonth: () => void;
        prevMonthButtonDisabled: boolean;
        nextMonthButtonDisabled: boolean;
    }) => (
        <div
            style={{
                margin: 10,
                display: 'flex',
                justifyContent: 'flex-start',
            }}
        >
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className="react-datepicker__navigation react-datepicker__navigation--previous">
                {'<'}
            </button>
            <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(Number(value))} className="custom-select">
                {years.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>

            <select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))} className="custom-select">
                {months.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>

            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} className="react-datepicker__navigation react-datepicker__navigation--next">
                {'>'}
            </button>
        </div>
    );

    return (
        <>
            <label htmlFor="birthDate">Date de naissance de l'emprunteur {values.paiementAvecFinancement && <sup>*</sup>}</label>
            <div className="custom-datepicker">
                <DatePicker
                    renderCustomHeader={renderCustomHeader}
                    selected={startDate}
                    onChange={handleDate}
                    disabled={!values.paiementAvecFinancement}
                    placeholderText="JJ/MM/AAAA"
                    dateFormat={'dd/MM/yyyy'}
                    name="birthDate"
                    id="birthDate"
                    maxDate={maxDate} // Current year - 18
                    autoComplete="off" // Prevent Chrome autofill
                />
            </div>
        </>
    );
};

export default Dates;
