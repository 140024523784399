import * as Yup from 'yup';

const schemaChimney = () => {
  return Yup.object().shape({
    message: Yup.string().required(),
    color: Yup.object()
      .test('is-valid-color', '', (value) => {
        if (value && typeof value === 'object' && value.label && value.value) {
          return true;
        }

        return false;
      })
      .required()
  });
};

export default schemaChimney;
