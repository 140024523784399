import { TooltipProps } from 'recharts';
import { currencyFormat } from '../../services/tools/TypeHelper';

const ProjectionFinanciereCustomTooltip: React.FC<TooltipProps<string, string>> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <div className="custom-tooltip-header">
                    <p className="custom-tooltip-label mb-0">{label}</p>
                </div>
                <div className="custom-tooltip-body">
                    <ul className="custom-tooltip-list">
                        {payload.map((element, key) => {
                            const stroke: string = (element as any).stroke;
                            return (
                                <li className="custom-tooltip-element mb-0" style={{ color: stroke }} key={key}>{`${element['name']} : ${currencyFormat(
                                    element.value as any
                                )}`}</li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        );
    } else {
        return null;
    }
};
export default ProjectionFinanciereCustomTooltip;
