import React, { useRef } from 'react';
import Konva from 'konva';
import useImage from 'use-image';
import { Transformer, Rect } from 'react-konva';
import { Dot, HistoryItem, RectAttrs } from '../../../services/tools/konvaTools';
import { KonvaEventObject } from 'konva/lib/Node';
import { FormValues } from '../../../pages/pre-visite/photovoltaique/PhotovoltaiqueEnums';

type PanelModuleProps = {
    item: HistoryItem;
    index: number;
    groupRefs: React.MutableRefObject<(Konva.Group | null)[]>;
    setSelectedElementIndex: (value: React.SetStateAction<number | null>) => void;
    selectedElementIndex: number;
    trRef: React.RefObject<Konva.Transformer>;
    values: FormValues;
    setValues: (values: React.SetStateAction<FormValues>, shouldValidate?: boolean | undefined) => void;
};

export const PanelModule: React.FC<PanelModuleProps> = ({ item, index, setSelectedElementIndex, selectedElementIndex, trRef, values, setValues }) => {
    const [image] = useImage(item.image ?? 'anonymous');
    const rectRef = useRef<Konva.Rect | null>();

    const handleRectEvtEnd = (e: KonvaEventObject<Event>, index: number) => {
        const attrs = e.target.getAttrs() as RectAttrs;
        const { width, height } = attrs;
        const x = 0;
        const y = 0;

        const corners: Dot[] = [];
        // Now get the 4 corner points
        corners.push({ x: x, y: y }); // top left
        corners.push({ x: x, y: y + height }); // bottom left
        corners.push({ x: x + width, y: y + height }); // bottom right
        corners.push({ x: x + width, y: y }); // top right

        for (let i = 0; i < 4; i++) {
            // Here be the magic
            corners[i] = e.target.getTransform().point(corners[i]);
        }

        const updatedHistory = values.history.map((item, idx) => {
            if (idx === index) {
                const updatedItem = { ...item, rect: { ...item.rect, attrs: attrs, dots: corners } };
                return updatedItem;
            }

            return item;
        });

        setValues({
            ...values,
            history: updatedHistory,
        });
    };

    return (
        <>
            <Rect
                {...item.rect}
                ref={(ref) => (rectRef.current = ref)}
                onClick={() => {
                    if (!item.deleted) {
                        setSelectedElementIndex(index);
                    }
                }}
                onTap={() => {
                    if (!item.deleted) {
                        setSelectedElementIndex(index);
                    }
                }}
                draggable={true}
                onDragEnd={(e) => handleRectEvtEnd(e, index)}
                stroke={item.color}
                strokeWidth={1}
                fillPatternImage={image}
            />

            {selectedElementIndex === index && (
                <Transformer
                    ref={trRef}
                    nodes={[rectRef.current]}
                    anchorSize={10}
                    onTransformEnd={(e) => handleRectEvtEnd(e, index)}
                    rotateEnabled={true}
                    enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
                />
            )}
        </>
    );
};
