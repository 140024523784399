import React, { useEffect } from 'react';
import { Formik, Field } from 'formik';
import { Link } from 'react-router-dom';
import { ROUTE_PV_THERMODYNAMIQUE } from '../../../routing/paths';
import { validationSchema } from './schema/schema';
import { FormValues, soilTypeList, panelStyles } from './ThermodynamiqueEnums';
import CheckboxTriState from '../../../components/checkbox/TriStateCheckbox';
import Select from 'react-select';
import { PropertyType } from '../../../services/localStorageService';
import * as storageService from '../../../services/localStorageService';

import classnames from 'classnames';

// Icons
import { ReactComponent as IconHotWaterTank } from '../../../assets/icons/pre-visite/icon-hot-water-tank.svg';
import { LabelledString } from '../../../services/tools/TypeHelper';
import UploadInput from '../../../components/Upload/UploadInput';
import { renderDefaultValueSelect } from '../../../services/tools/selectValue';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { currentRouteAtom, nextPackageRouteSelector } from '../../../services/Recoil/Atom/PackageContext.atom';
import { useHistory } from 'react-router';

const Thermodynamique: React.FC = () => {
    const preVisite = storageService.getPrevisit();
    const history = useHistory();
    const setCurrent = useSetRecoilState(currentRouteAtom);
    const nextRoute = useRecoilValue(nextPackageRouteSelector);

    const initialValues: FormValues = {
        length: storageService.checkPropertyExistThenCreateOrRenderPreVisite('length', 'Longueur de la pièce', PropertyType['basic']),
        width: storageService.checkPropertyExistThenCreateOrRenderPreVisite('width', 'Largeur de la pièce', PropertyType['basic']),
        height: storageService.checkPropertyExistThenCreateOrRenderPreVisite('height', 'Hauteur de la pièce', PropertyType['basic']),
        heatedRoom: storageService.checkPropertyExistThenCreateOrRenderPreVisite('heatedRoom', 'Pièce chauffée', PropertyType['boolean']),
        airExtraction: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'airExtraction',
            'Un des murs de la pièce donne t’il sur l’extérieur',
            PropertyType['boolean']
        ),
        pressureReducer: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'pressureReducer',
            'Savez vous s’il existe un réducteur de pression pour le ballon',
            PropertyType['boolean']
        ),
        paving: storageService.checkPropertyExistThenCreateOrRenderPreVisite('paving', 'Dallage à prévoir', PropertyType['boolean']),
        soilType: storageService.checkPropertyExistThenCreateOrRenderPreVisite('soilType', 'Type de sol existant', PropertyType['basic']),
        detachedPart: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'detachedPart',
            'Présence d’un passage d’air de 1,5 à 2,5cm au bas de la porte du local',
            PropertyType['boolean']
        ),
        roomTankInstalledPicture: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'roomTankInstalledPicture',
            'Local ou sera installé le ballon',
            PropertyType['basic']
        ),
        doorwayPicture: storageService.checkPropertyExistThenCreateOrRenderPreVisite('doorwayPicture', 'Passage de porte', PropertyType['basic']),
        outsideWallPicture: storageService.checkPropertyExistThenCreateOrRenderPreVisite(
            'outsideWallPicture',
            'Mur extérieur si local <20m²',
            PropertyType['basic']
        ),
    };

    const handleAirExtraction = (length: number, width: number, height: number) => {
        const result = length * width * height;

        if (length !== 0 && width !== 0 && height !== 0 && result < 18) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        setCurrent(ROUTE_PV_THERMODYNAMIQUE);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Pictures file name
    const roomTankInstalledPictureFileName = preVisite.roomTankInstalledPicture.value_label;
    const doorwayPictureFileName = preVisite.doorwayPicture.value_label;
    const outsideWallPictureFileName = preVisite.outsideWallPicture.value_label;

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} validateOnMount={true} onSubmit={() => {}}>
            {({ values, errors, touched, setValues, setFieldValue }) => {
                const getFieldClasses = (fieldName: keyof FormValues, required = true) => {
                    const fieldValue = values[fieldName];
                    const fieldError = errors[fieldName];
                    const fieldTouched = touched[fieldName];
                    const defaultClass = 'form-control';

                    return classnames(defaultClass, {
                        'has-value': fieldValue !== undefined && fieldValue !== null && fieldValue !== '',
                        'is-required': required,
                        'is-error': fieldError && fieldTouched,
                        'is-valid': !fieldError && fieldTouched,
                    });
                };

                // Debug
                // console.log('Values:', values);
                // console.log('Errors:', errors);
                return (
                    <div className="container">
                        <h1 className="main-title-mini">Ballon Thermo dynamique</h1>

                        <div className="card card-audit-simulator photovoltaique mb-5">
                            <div className="card-header">
                                <IconHotWaterTank width={60} height={60} fill="white" />
                                <h2>Pré visite technique</h2>
                            </div>

                            <div className="card-body">
                                <h5 className="card-body--inner-title mb-4">Pièce ou sera installée le ballon</h5>
                                <div className="row">
                                    <div className="col-12 col-md-4 mb-3">
                                        <div className="form-group">
                                            <label htmlFor="length">
                                                Longueur de la pièce<sup>*</sup>
                                            </label>
                                            <div className="input-group">
                                                <Field
                                                    type="number"
                                                    name="length"
                                                    id="length"
                                                    className={getFieldClasses('length')}
                                                    placeholder="Longueur de la pièce"
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setValues({
                                                            ...values,
                                                            length: e.target.value,
                                                        });

                                                        storageService.setPrevisitValue('length', e.target.value);
                                                    }}
                                                    value={values.length}
                                                />
                                                <span className="input-group-text">m</span>
                                            </div>
                                            {touched.length && errors.length && <span className="invalid-feedback">{errors.length}</span>}
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4 mb-3">
                                        <div className="form-group">
                                            <label htmlFor="width">
                                                Largeur de la pièce<sup>*</sup>
                                            </label>
                                            <div className="input-group">
                                                <Field
                                                    type="number"
                                                    name="width"
                                                    id="width"
                                                    className={getFieldClasses('width')}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setValues({
                                                            ...values,
                                                            width: e.target.value,
                                                        });

                                                        storageService.setPrevisitValue('width', e.target.value);
                                                    }}
                                                    placeholder="Largeur de la pièce"
                                                    value={values.width}
                                                />
                                                <span className="input-group-text">m</span>
                                            </div>
                                            {touched.width && errors.width && <span className="invalid-feedback">{errors.width}</span>}
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4 mb-3">
                                        <div className="form-group">
                                            <label htmlFor="height">
                                                Hauteur de la pièce<sup>*</sup>
                                            </label>
                                            <div className="input-group">
                                                <Field
                                                    type="number"
                                                    name="height"
                                                    id="height"
                                                    className={getFieldClasses('height')}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setValues({
                                                            ...values,
                                                            height: e.target.value,
                                                        });

                                                        storageService.setPrevisitValue('height', e.target.value);
                                                    }}
                                                    placeholder="Hauteur de la pièce"
                                                    value={values.height}
                                                />
                                                <span className="input-group-text">m</span>
                                            </div>
                                            {touched.height && errors.height && <span className="invalid-feedback">{errors.height}</span>}
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4 mb-3">
                                        <div className="form-group">
                                            <CheckboxTriState
                                                emptyLabel={true}
                                                name="heatedRoom"
                                                title="Pièce chauffée"
                                                style={{ height: '43px' }}
                                                onCustomChange={(e: boolean) => {
                                                    storageService.setPrevisitValue('heatedRoom', e);
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4 mb-3">
                                        {(handleAirExtraction(Number(values.length), Number(values.width), Number(values.height)) === true ||
                                            values.heatedRoom) && (
                                            <div className="form-group">
                                                <CheckboxTriState
                                                    emptyLabel={true}
                                                    name="airExtraction"
                                                    title="Un des murs de la pièce donne t’il sur l’extérieur"
                                                    helpText="Si la pièce d’installation est chauffée ou a un volume inférieur à 18m3, une extraction d’air est à réaliser"
                                                    style={{ height: '43px' }}
                                                    onCustomChange={(e: boolean) => {
                                                        storageService.setPrevisitValue('airExtraction', e);
                                                    }}
                                                />
                                                {touched.airExtraction && errors.airExtraction && (
                                                    <span className="invalid-feedback">{errors.airExtraction}</span>
                                                )}
                                            </div>
                                        )}
                                    </div>

                                    <div className="col-12 col-md-4 mb-3">
                                        <div className="form-group">
                                            <CheckboxTriState
                                                emptyLabel={true}
                                                name="pressureReducer"
                                                title="Savez vous s’il existe un réducteur de pression pour le ballon"
                                                style={{ height: '43px' }}
                                                onCustomChange={(e: boolean) => {
                                                    storageService.setPrevisitValue('pressureReducer', e);
                                                }}
                                            />
                                            {touched.pressureReducer && errors.pressureReducer && (
                                                <span className="invalid-feedback">{errors.pressureReducer}</span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4 mb-3">
                                        <div className="form-group">
                                            <label htmlFor="soilType">Type de sol existant</label>
                                            <Select
                                                value={renderDefaultValueSelect(soilTypeList, values.soilType)}
                                                options={soilTypeList}
                                                onChange={(event: LabelledString | null) => {
                                                    if (event) {
                                                        setValues({
                                                            ...values,
                                                            soilType: event.value,
                                                            paving: event.value !== 'terre' && event.value !== 'autre' ? '' : values.paving,
                                                        });

                                                        storageService.setPrevisitValue('soilType', event.value, event.label);

                                                        if (event.value !== 'terre' && event.value !== 'autre') {
                                                            storageService.removePrevisitValue('paving');
                                                        }
                                                    }
                                                }}
                                                className={
                                                    'basic-single' +
                                                    (values.soilType ? ' filled' : '') +
                                                    (touched.soilType && errors.soilType ? ' invalid' : '') +
                                                    (!values.soilType ? ' required' : '')
                                                }
                                                name="soilType"
                                                id="soilType"
                                                isMulti={false}
                                                isClearable={false}
                                                isSearchable={false}
                                                placeholder="Type de sol existant"
                                                styles={panelStyles}
                                            />
                                            {touched.soilType && errors.soilType && <span className="invalid-feedback">{errors.soilType}</span>}
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4 mb-3">
                                        {(values.soilType === 'terre' || values.soilType === 'autre') && (
                                            <div className="form-group">
                                                <CheckboxTriState
                                                    emptyLabel={true}
                                                    name="paving"
                                                    title="Dallage à prévoir"
                                                    style={{ height: '43px' }}
                                                    onCustomChange={(e: boolean) => {
                                                        storageService.setPrevisitValue('paving', e);
                                                    }}
                                                />
                                                {touched.paving && errors.paving && <span className="invalid-feedback">{errors.paving}</span>}
                                            </div>
                                        )}
                                    </div>

                                    <div className="col-12 col-md-4 mb-3">
                                        <div className="form-group">
                                            <CheckboxTriState
                                                emptyLabel={true}
                                                name="detachedPart"
                                                title="Présence d’un passage d’air de 1,5 à 2,5cm au bas de la porte du local"
                                                style={{ height: '43px' }}
                                                onCustomChange={(e: boolean) => {
                                                    storageService.setPrevisitValue('detachedPart', e);
                                                }}
                                            />
                                            {touched.detachedPart && errors.detachedPart && <span className="invalid-feedback">{errors.detachedPart}</span>}
                                        </div>
                                    </div>
                                </div>

                                <h5 className="card-body--inner-title mb-4 mt-5">Télécharger vos photos</h5>
                                <div className="row">
                                    <div className="col-12 col-md-4 mb-3 mb-md-0">
                                        <div className="form-group">
                                            <UploadInput
                                                name="roomTankInstalledPicture"
                                                label="Local ou sera installé le ballon"
                                                setFieldValue={setFieldValue}
                                                pictureFileName={roomTankInstalledPictureFileName}
                                            />
                                            {touched.roomTankInstalledPicture && errors.roomTankInstalledPicture && (
                                                <span className="invalid-feedback">{errors.roomTankInstalledPicture}</span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4 mb-3 mb-md-0">
                                        <div className="form-group">
                                            <UploadInput
                                                name="doorwayPicture"
                                                label="Passage de porte"
                                                setFieldValue={setFieldValue}
                                                pictureFileName={doorwayPictureFileName}
                                            />
                                            {touched.doorwayPicture && errors.doorwayPicture && (
                                                <span className="invalid-feedback">{errors.doorwayPicture}</span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4 mb-3 mb-md-0">
                                        <div className="form-group">
                                            <UploadInput
                                                name="outsideWallPicture"
                                                label={`Mur extérieur\nsi local <20m²`}
                                                smallerLabel={true}
                                                setFieldValue={setFieldValue}
                                                pictureFileName={outsideWallPictureFileName}
                                            />
                                            {touched.outsideWallPicture && errors.outsideWallPicture && (
                                                <span className="invalid-feedback">{errors.outsideWallPicture}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="btn-grp justify-content-end pb-5">
                            <button type="button" onClick={() => history.goBack()} className="btn btn-retour">
                                Retour
                            </button>
                            <Link to={nextRoute} className={`btn btn-continue ${Object.entries(errors).length > 0 ? 'disabled' : ''}`}>
                                Continuer
                            </Link>
                        </div>
                    </div>
                );
            }}
        </Formik>
    );
};

export default Thermodynamique;
