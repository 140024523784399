import { StylesConfig } from 'react-select';
import { ModeConsommation } from '../../services/calculs/calculPhotovoltaique';
import { Labelled } from '../../services/tools/TypeHelper';
import { NiveauAmelioration } from '../../services/calculs/aides/aideRenovationDAmpleurDefs';

// /**
//  * Pour le changement de couleurs du react-select
//  */
export const panelStyles: StylesConfig<Labelled<ModeConsommation>, false> = {
    menuList: (styles) => {
        return {
            ...styles,
            fontSize: '0.875rem',
            backgroundColor: '#434D5D',
            color: '#FFFFFF',
            borderRadius: '4px',
            maxHeight: '100px',
        };
    },

    option: (styles, state) => {
        return {
            ...styles,
            height: '30px',
            padding: '4px 12px',
            backgroundColor: state.isSelected ? '#99a4b5' : '#434D5D',

            '&:hover': {
                backgroundColor: '#606e85',
                color: 'white',
            },
        };
    },

    indicatorSeparator: (styles) => ({
        ...styles,
        display: 'none',
    }),

    control: (styles) => ({
        ...styles,
        backgroundColor: 'white',
        color: '#434D5D',
        boxShadow: 'none',
        border: '1px solid #434D5D',
        minWidth: '70px',
        height: '30px',
        minHeight: 'auto',

        '&:focus': {
            border: '0',
            boxShadow: 'none',
        },

        '&:hover': {
            border: '1px solid #434D5D',
        },
    }),

    placeholder: (styles) => ({
        ...styles,
        fontSize: '0.875rem',
        color: '#CDCDCD',
        height: '1.875rem', // 30px
        lineHeight: '1.875rem', // 30px
    }),

    singleValue: (styles) => ({
        ...styles,
        fontSize: '0.875rem',
        lineHeight: '1.875rem',
        color: '#434D5D',
        height: '1.875rem',
    }),

    valueContainer: (styles) => ({
        ...styles,
        height: '1.875rem',
    }),

    indicatorsContainer: (styles) => ({
        ...styles,
        height: '1.875rem',
    }),

    dropdownIndicator: (styles) => ({
        ...styles,
        color: '#434D5D',

        '&:hover': {
            color: '#434D5D',
        },

        '&:focus': {
            color: '#434D5D',
        },
    }),
};

export const panelStylesActive: StylesConfig<Labelled<ModeConsommation>, false> = {
    menuList: (styles) => {
        return {
            ...styles,
            fontSize: '0.875rem',
            backgroundColor: '#FDB823',
            color: '#FFFFFF',
            borderRadius: '4px',
            maxHeight: '100px',
        };
    },

    option: (styles, state) => {
        return {
            ...styles,
            height: '30px',
            padding: '4px 12px',
            backgroundColor: state.isSelected ? '#FEDB91' : '#FDB823',

            '&:hover': {
                backgroundColor: '#FDC64E',
                color: 'white',
            },
        };
    },

    indicatorSeparator: (styles) => ({
        ...styles,
        display: 'none',
    }),

    control: (styles) => ({
        ...styles,
        backgroundColor: 'white',
        color: '#FEDB91',
        boxShadow: 'none',
        border: '1px solid #FEDB91',
        minWidth: '70px',
        height: '30px',
        minHeight: 'auto',

        '&:focus': {
            border: '0',
            boxShadow: 'none',
        },

        '&:hover': {
            border: '1px solid #FEDB91',
        },
    }),

    placeholder: (styles) => ({
        ...styles,
        fontSize: '0.875rem',
        color: '#CDCDCD',
        height: '1.875rem', // 30px
        lineHeight: '1.875rem', // 30px
    }),

    singleValue: (styles) => ({
        ...styles,
        fontSize: '0.875rem',
        lineHeight: '1.875rem',
        color: '#FEDB91',
        height: '1.875rem',
    }),

    valueContainer: (styles) => ({
        ...styles,
        height: '1.875rem',
    }),

    indicatorsContainer: (styles) => ({
        ...styles,
        height: '1.875rem',
    }),

    dropdownIndicator: (styles) => ({
        ...styles,
        color: '#FEDB91',

        '&:hover': {
            color: '#FEDB91',
        },

        '&:focus': {
            color: '#FEDB91',
        },
    }),
};

export const miniPanelStyles: StylesConfig<Labelled<number>, false> = {
    control: (styles) => ({
        ...styles,
        backgroundColor: '#434D5D',
        color: 'white',
        boxShadow: 'none',
        border: '1px solid transparent',
        minWidth: '70px',
        height: '30px',
        minHeight: 'auto',
    }),

    valueContainer: (styles) => ({
        ...styles,
        height: '1.875rem',
    }),

    indicatorsContainer: (styles) => ({
        ...styles,
        height: '1.875rem', // 30px
        width: '1.25rem', // 20px
    }),

    singleValue: (styles) => ({
        ...styles,
        fontSize: '0.875rem',
        lineHeight: '1.875rem',
        color: 'white',
        height: '1.875rem',
    }),

    menuList: (styles) => {
        return {
            ...styles,
            fontSize: '0.875rem',
            backgroundColor: '#434D5D',
            color: '#FFFFFF',
            borderRadius: '4px',
            maxHeight: '6.25rem', // 100px
        };
    },

    indicatorSeparator: (styles) => ({
        ...styles,
        display: 'none',
    }),

    dropdownIndicator: (styles) => ({
        ...styles,
        padding: '0',
        color: 'white',

        '&:hover': {
            color: 'white',
        },

        '&:focus': {
            color: 'white',
        },
    }),

    option: (styles, state) => ({
        ...styles,
        height: '30px',
        padding: '4px 12px',
        backgroundColor: state.isSelected ? '#99a4b5' : '#434D5D',

        '&:hover': {
            backgroundColor: '#606e85',
            color: 'white',
        },
    }),
};

export const miniPanelStylesActive: StylesConfig<Labelled<number>, false> = {
    control: (styles) => ({
        ...styles,
        backgroundColor: '#fdb823',
        color: 'white',
        boxShadow: 'none',
        border: '1px solid transparent',
        minWidth: '70px',
        height: '30px',
        minHeight: 'auto',

        '&:hover': {
            border: '1px solid transparent',
        },
    }),

    valueContainer: (styles) => ({
        ...styles,
        height: '1.875rem',
    }),

    indicatorsContainer: (styles) => ({
        ...styles,
        height: '1.875rem', // 30px
        width: '1.25rem', // 20px
    }),

    singleValue: (styles) => ({
        ...styles,
        fontSize: '0.875rem',
        lineHeight: '1.875rem',
        color: 'white',
        height: '1.875rem',
    }),

    menuList: (styles) => {
        return {
            ...styles,
            fontSize: '0.875rem',
            backgroundColor: '#fdb823',
            color: '#FFFFFF',
            borderRadius: '4px',
            maxHeight: '6.25rem', // 100px
        };
    },

    indicatorSeparator: (styles) => ({
        ...styles,
        display: 'none',
    }),

    dropdownIndicator: (styles) => ({
        ...styles,
        padding: '0',
        color: 'white',

        '&:hover': {
            color: 'white',
        },

        '&:focus': {
            color: 'white',
        },
    }),

    option: (styles, state) => ({
        ...styles,
        height: '30px',
        padding: '4px 12px',
        backgroundColor: state.isSelected ? '#fedb91' : '#fdb823',

        '&:hover': {
            backgroundColor: '#fdc64e',
            color: 'white',
        },
    }),
};

const styleForReno: StylesConfig<{ [key: string]: any }, false> = {
    menu: (styles) => {
        return {
            ...styles,
            zIndex: '100000', // Ajoute cette ligne pour que le menu apparaisse au-dessus
        };
    },
    menuList: (styles) => {
        return {
            ...styles,
            fontSize: '0.875rem',
            backgroundColor: '#FDB823',
            color: '#FFFFFF',
            borderRadius: '4px',
            maxHeight: '100px',
        };
    },

    option: (styles, state) => {
        return {
            ...styles,
            height: '30px',
            padding: '4px 12px',
            backgroundColor: state.isSelected ? '#FEDB91' : '#FDB823',

            '&:hover': {
                backgroundColor: '#FDC64E',
                color: 'white',
            },
        };
    },

    indicatorSeparator: (styles) => ({
        ...styles,
        display: 'none',
    }),

    control: (styles) => ({
        ...styles,
        backgroundColor: 'white',
        color: '#FEDB91',
        boxShadow: 'none',
        border: '1px solid #FEDB91',
        minWidth: '70px',
        height: '30px',
        minHeight: 'auto',

        '&:focus': {
            border: '0',
            boxShadow: 'none',
        },

        '&:hover': {
            border: '1px solid #FEDB91',
        },
    }),

    placeholder: (styles) => ({
        ...styles,
        fontSize: '0.875rem',
        color: '#CDCDCD',
        height: '1.875rem', // 30px
        lineHeight: '1.875rem', // 30px
    }),

    singleValue: (styles) => ({
        ...styles,
        fontSize: '0.875rem',
        lineHeight: '1.875rem',
        color: '#444444',
        height: '1.875rem',
    }),

    valueContainer: (styles) => ({
        ...styles,
        height: '1.875rem',
    }),

    indicatorsContainer: (styles) => ({
        ...styles,
        height: '1.875rem',
    }),

    dropdownIndicator: (styles) => ({
        ...styles,
        color: '#FEDB91',

        '&:hover': {
            color: '#FEDB91',
        },

        '&:focus': {
            color: '#FEDB91',
        },
    }),
};
export const styleForRenoNiveau = styleForReno as StylesConfig<Labelled<NiveauAmelioration>, false>;
export const styleForRenoSortiePAssoire = styleForReno as StylesConfig<Labelled<boolean>, false>;
