import React, { useEffect, useState } from 'react';
import { Back } from '../../../../components/Back/Back';
import * as routes from '../../../../routing/paths';
import { Link, useParams } from 'react-router-dom';
import * as api from '../../../../services/apiParticulierService';
import { format } from 'date-fns';

// Icons
import { ReactComponent as IconUser } from '../../../../assets/icons/espace-agent/icon-account.svg';
import { ReactComponent as IconBuilding } from '../../../../assets/icons/espace-agent/icon-building.svg';

interface RouteParams {
    id: string;
}

type BusinessProps = {
    company?: {
        address: string;
        city: string;
        email: string;
        id: string;
        legalType: string;
        name: string;
        siret: string;
        zipCode: string;
    };
};

type AgentProps = {
    agent?: api.SubAgent;
};

const AgentInformation: React.FC<AgentProps> = ({ agent }) => {
    const showManager = () => {
        if (agent?.manager && agent.manager.networkDeep! < 2) {
            return true;
        }

        return false;
    };

    return (
        <div className="container-creation p-4 mb-md-0 mb-4 h-100">
            <div className="row">
                <h2 className="d-flex align-items-center gap-2">
                    <IconUser fill="#5a5a5a" height={16} />
                    Informations agent
                </h2>
            </div>

            <div className="row mb-4">
                <div className="col-12 col-md-6">
                    <div className="form-row">
                        <label htmlFor="agentId" className="form-label">
                            Code agent :
                        </label>
                        <p className="form-result">{agent?.agentCode || ''}</p>
                    </div>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-12 col-md-6 mb-4 mb-md-0">
                    <div className="form-row">
                        <label htmlFor="lastname" className="form-label">
                            Nom :
                        </label>
                        <p className="form-result">{agent?.lastname || '-'}</p>
                    </div>
                </div>

                <div className="col-12 col-md-6">
                    <div className="form-row">
                        <label htmlFor="firstname" className="form-label">
                            Prénom :
                        </label>
                        <p className="form-result">{agent?.firstname || '-'}</p>
                    </div>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-12 col-md-6 mb-4 mb-md-0">
                    <div className="form-row">
                        <label htmlFor="phone" className="form-label">
                            Téléphone :
                        </label>
                        <p className="form-result">{agent?.phoneNumber || '-'}</p>
                    </div>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col">
                    <div className="form-row">
                        <label htmlFor="mail" className="form-label">
                            Email :
                        </label>
                        <p className="form-result">{agent?.email.toLowerCase() || '-'}</p>
                    </div>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-12">
                    <div className="form-row">
                        <label htmlFor="address" className="form-label">
                            Adresse :
                        </label>
                        <p className="form-result">{agent?.address || '-'}</p>
                    </div>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-12 col-md-6 mb-4 mb-md-0">
                    <div className="form-row">
                        <label htmlFor="zipCode" className="form-label">
                            Code postal :
                        </label>
                        <p className="form-result">{agent?.zipcode || '-'}</p>
                    </div>
                </div>

                <div className="col-12 col-md-6">
                    <div className="form-row">
                        <label htmlFor="city" className="form-label">
                            Ville :
                        </label>
                        <p className="form-result">{agent?.city || '-'}</p>
                    </div>
                </div>
            </div>

            <div className={`row ${showManager() ? 'mb-4' : ''}`}>
                <div className="col-12 col-md-6">
                    <div className="form-row">
                        <label htmlFor="zipCode" className="form-label">
                            Niveau de réseau :
                        </label>
                        <p className="form-result">{api.formatNetworkDeep(agent?.networkDeep)}</p>
                    </div>
                </div>
            </div>

            {showManager() && (
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="form-row">
                            <label htmlFor="manager" className="form-label">
                                Manager :
                            </label>
                            {agent?.manager && (
                                <p className="form-result">
                                    <Link to={`${routes.ROUTE_EA_NETWORK}/${agent?.manager.id}`} className="form-result--link">
                                        {`${agent?.manager?.firstname} ${agent?.manager?.lastname}` || '-'}
                                    </Link>
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const BusinessStructure: React.FC<BusinessProps> = ({ company }) => {
    return (
        <div className="container-creation p-4 mb-4 h-100">
            <div className="row">
                <h2 className="d-flex align-items-center gap-2">
                    <IconBuilding fill="#5a5a5a" height={16} />
                    Structure commerciale
                </h2>
            </div>

            <div className="row mb-4">
                <div className="col-12">
                    <div className="form-row">
                        <label htmlFor="company" className="form-label">
                            Raison sociale :
                        </label>
                        <p className="form-result">{company?.name || '-'}</p>
                    </div>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-12">
                    <div className="form-row">
                        <label htmlFor="siret" className="form-label">
                            SIRET :
                        </label>
                        <p className="form-result">{company?.siret || '-'}</p>
                    </div>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-12">
                    <div className="form-row">
                        <label htmlFor="legalStatus" className="form-label">
                            Forme juridique :
                        </label>
                        <p className="form-result">{company?.legalType || '-'}</p>
                    </div>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-12">
                    <div className="form-row">
                        <label htmlFor="companyAddress" className="form-label">
                            Adresse :
                        </label>
                        <p className="form-result">{company?.address || '-'}</p>
                    </div>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-12">
                    <div className="form-row">
                        <label htmlFor="companyZipCode" className="form-label">
                            Code postal :
                        </label>
                        <p className="form-result">{company?.zipCode || '-'}</p>
                    </div>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-12">
                    <div className="form-row">
                        <label htmlFor="companyCity" className="form-label">
                            Ville :
                        </label>
                        <p className="form-result">{company?.city || '-'}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const NetworkDetail: React.FC = () => {
    const { id } = useParams<RouteParams>();
    const [agentData, setAgentData] = useState<api.SubAgent | undefined>(undefined);

    useEffect(() => {
        const loadAgent = async () => {
            try {
                const subagent = await api.getSubAgent(id);
                if (subagent && subagent.createdAt) {
                    const dateObject = new Date(subagent.createdAt);
                    const formattedDate = format(dateObject, 'dd/MM/yyyy - kk:mm');
                    setAgentData({ ...subagent, createdAt: formattedDate });
                    // Debugg
                    // console.log('agentData:', agentData);
                } else {
                    console.error("Propriété de date manquante dans la réponse de l'API");
                }
            } catch (error) {
                console.error("Erreur lors de la récupération de la date depuis l'API", error);
            }
        };

        loadAgent();
    }, [id]);

    return (
        <div className="container">
            <div className="list-audit-header d-flex justify-content-between align-items-center w-100 py-4 mb-5">
                <Back title="Mon réseau" url={routes.ROUTE_EA_NETWORK} />
            </div>

            <div className="row">
                <div className="col-12 col-md-7">
                    <AgentInformation agent={agentData} />
                </div>

                <div className="col-12 col-md-5">
                    <BusinessStructure company={agentData?.company} />
                </div>
            </div>
        </div>
    );
};

export default NetworkDetail;
