import { generateNumberList, LabelledString } from '../../../services/tools/TypeHelper';

export const productNames = [
    'Kakemono - Renovation énergétique',
    "Kakemono - Rénovation d'ampleur",
    'Kakemono - Installation photovoltaïque',
    'Kakemono - Réseau nationnal',
    'Kakemono - Améliorer votre habitat',
    'Cartes de visite',
];
export type Product = (typeof productNames)[number];
export type MarketingOrderProduct = {
    [key in Product]?: {
        quantity?: LabelledString;
        price?: number;
        total?: number;
        customization?: Record<string, string>;
    };
};

// Définir un objet avec tous les produits et leurs prix
const MARKETING_PRODUCTS = {
    'Kakemono - Renovation énergétique': 54,
    "Kakemono - Rénovation d'ampleur": 54,
    'Kakemono - Installation photovoltaïque': 54,
    'Kakemono - Réseau national': 54,
    'Kakemono - Améliorer votre habitat': 54,
    'Cartes de visite': 54.99,
} as const;

// Générer dynamiquement initialValues
export const initialValues: MarketingOrderProduct = Object.fromEntries(
    Object.entries(MARKETING_PRODUCTS).map(([product, price]) => {
        // Si c'est "Cartes de visite", inclure les champs de personnalisation spécifiques
        if (product === 'Cartes de visite') {
            return [
                product,
                {
                    quantity: { label: '0', value: '0' },
                    price,
                    total: 0,
                    customization: {
                        name: '',
                        firstname: '',
                        phone: '',
                        mobile: '',
                        email: '',
                        address: '',
                        city: '',
                        postalCode: '',
                    },
                },
            ];
        }
        // Pour les autres produits, pas de champs de personnalisation
        return [
            product,
            {
                quantity: { label: '0', value: '0' },
                price,
                total: 0,
                customization: {}, // Vide ou absent pour les autres produits
            },
        ];
    })
);

export const quantityOptions = generateNumberList(0, 10, 1);

export const cdvQuantityOptions = generateNumberList(0, 1000, 250);
