import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Labelled, LabelledString } from './TypeHelper';
import { BBox } from 'geojson';
import { GeoPosition } from './mapTools';

export const getLabelForValue = <T extends string | number | boolean>(options: Array<Labelled<T>>, candidate: T): string | undefined => {
    for (const element of options) {
        if (candidate === element.value) return element.label;
    }
    return undefined;
};

export interface ErrorBody<T> {
    statusCode: number;
    error: string;
    errorId: string;
    message: string;
    details?: T;
}

export const isErrorBody = (value: unknown): value is ErrorBody<unknown> => {
    if (!value) return false;
    const converted = value as ErrorBody<unknown>;
    if (!converted || !converted.error || !converted.message || !converted.errorId) return false;
    return true;
};

export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

// function to remove a key from a nested object
export const removeKey = <T extends Record<string, unknown>, K = T>(obj: T, key: keyof K): Partial<T> =>
    obj !== Object(obj)
        ? obj
        : Array.isArray(obj)
        ? obj.map((item) => removeKey(item, key))
        : Object.keys(obj)
              .filter((k) => k !== key)
              .reduce((acc, x) => Object.assign(acc, { [x]: removeKey(obj[x] as T, key) }), {});

export type ModalType =
    | 'photovoltaique'
    | 'text'
    | 'observation'
    | 'draw'
    | 'pac'
    | 'insulation'
    | 'arrow'
    | 'circle'
    | 'square'
    | 'velux'
    | 'chimney'
    | 'dormer'
    | 'panel'
    | 'auvent'
    | '';

export interface Dot {
    x: number;
    y: number;
    isHovered?: boolean;
}

export type DrawingModule = { dots: Dot[]; completed: boolean };

// Specific to Arrow elements
const Lines = ['solid', 'dashed'] as const;
export type LineType = (typeof Lines)[number];

const EndPoints = ['line', 'arrow', 'doubleArrow'] as const;
export type EndPointType = (typeof EndPoints)[number];

export type ArrowModule = { lineType: LineType; endPointType: EndPointType };

export type RectAttrs = {
    draggable?: boolean;
    fillPatternImage?: HTMLImageElement;
    height: number;
    offsetX?: number;
    offsetY?: number;
    rotation: number;
    scaleX: number;
    scaleY: number;
    skewX?: number;
    skewY?: number;
    stroke?: string;
    strokeWidth?: number;
    width: number;
    x: number;
    y: number;
};

type RectModule = {
    attrs: RectAttrs;
    dots: Dot[];
};
// Horizontal / Vertical
const Orientations = ['landscape', 'portrait'] as const;
export type Orientation = (typeof Orientations)[number];

export type HistoryItem = {
    id: string;
    message: string;
    type: ModalType;
    deleted?: boolean;
    image?: string;
    opacity?: boolean;
    position?: { x: number; y: number };
    // Drawing
    color?: string;
    drawing?: DrawingModule;
    // Arrows
    arrow?: ArrowModule;
    rect?: RectModule;
};

export type ModalPhotovoltaiqueProps = {
    orientation: Labelled<Orientation>;
    columns: Labelled<number>;
    lines: Labelled<number>;
    color: LabelledString | undefined;
};

export type ModalPanelProps = {
    orientation: Labelled<Orientation>;
    columns: Labelled<number>;
    lines: Labelled<number>;
    color: LabelledString | undefined;
};

export type ModalDrawProps = {
    message: string;
    color: LabelledString | undefined;
    opacity?: boolean;
};

export type ModalTextProps = {
    message: string;
};

export type ModalArrowProps = {
    lineType: LabelledString | undefined;
    endPointType: LabelledString | undefined;
    color: LabelledString | undefined;
};

export type ModalPacProps = {
    columns: Labelled<number>;
    lines: Labelled<number>;
    color: LabelledString | undefined;
    opacity?: boolean;
};

export type ModalInsulationProps = {
    color: LabelledString | undefined;
    opacity?: boolean;
};

export type ModalObservationProps = {
    message: string;
};

export type ModalVeluxProps = {
    message: string;
    color: LabelledString | undefined;
};

export type ModalChimneyProps = {
    message: string;
    color: LabelledString | undefined;
};

export type ModalDormerProps = {
    message: string;
    color: LabelledString | undefined;
};

export type ModalCircleProps = {
    message: string;
    color: LabelledString | undefined;
};

export type ModalAuventProps = {
    texture: LabelledString | undefined;
};

export type ModalTypes =
    | ModalPhotovoltaiqueProps
    | ModalDrawProps
    | ModalPacProps
    | ModalInsulationProps
    | ModalTextProps
    | ModalObservationProps
    | ModalArrowProps
    | ModalVeluxProps
    | ModalChimneyProps
    | ModalDormerProps
    | ModalCircleProps
    | ModalAuventProps;

// https://en.wikipedia.org/wiki/Centroid
export const calculateCentroid = (dots: Dot[]) => {
    let xSum = 0,
        ySum = 0;
    dots.forEach((dot) => {
        xSum += dot.x;
        ySum += dot.y;
    });
    return {
        x: xSum / dots.length,
        y: ySum / dots.length,
    };
};

export const generateImage = (
    color: string | undefined,
    columns: number,
    lines: number,
    iconType: 'photovoltaique' | 'pac' | 'auvent' | 'panel',
    orientation: Orientation,
    iconComponent: React.ReactElement
): Promise<{ image: string; width: number; height: number } | undefined> => {
    return new Promise((resolve, reject) => {
        const canvas = document.createElement('canvas');
        if (!canvas) return;
        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        // Panel default size
        let imageWidth = 0;
        let imageHeight = 0;

        let iconToDisplay = '';
        let customColor = color ?? 'black';
        const divider = columns > lines ? columns : lines;

        if (iconType === 'photovoltaique') {
            // size: 85 * 51
            if (orientation === 'landscape') {
                iconToDisplay = ReactDOMServer.renderToStaticMarkup(React.cloneElement(iconComponent, { fill: color }));
                imageWidth = 340 / divider;
                imageHeight = 204 / divider;
            } else {
                iconToDisplay = ReactDOMServer.renderToStaticMarkup(React.cloneElement(iconComponent, { fill: color }));
                imageWidth = 204 / divider;
                imageHeight = 340 / divider;
            }

            customColor = '#666';
        }

        if (iconType === 'panel') {
            // size: 85 * 51
            if (orientation === 'landscape') {
                iconToDisplay = ReactDOMServer.renderToStaticMarkup(React.cloneElement(iconComponent, { fill: color }));
                imageWidth = 340 / divider;
                imageHeight = 204 / divider;
            } else {
                iconToDisplay = ReactDOMServer.renderToStaticMarkup(React.cloneElement(iconComponent, { fill: color }));
                imageWidth = 204 / divider;
                imageHeight = 340 / divider;
            }

            customColor = '#666';
        }

        // No orientation
        if (iconType === 'pac') {
            iconToDisplay = ReactDOMServer.renderToStaticMarkup(React.cloneElement(iconComponent, { fill: color }));
            imageWidth = 90;
            imageHeight = 65;
        }

        if (iconType === 'auvent') {
            iconToDisplay = ReactDOMServer.renderToStaticMarkup(React.cloneElement(iconComponent, { fill: '#000' }));
            imageWidth = 10;
            imageHeight = 100;
        }

        canvas.width = imageWidth * columns;
        canvas.height = imageHeight * lines;

        const svgUrl = `data:image/svg+xml;utf8,${encodeURIComponent(iconToDisplay)}`;

        const img = new window.Image();
        img.src = svgUrl;

        img.onload = () => {
            for (let row = 0; row < lines; row++) {
                for (let col = 0; col < columns; col++) {
                    ctx.drawImage(img, col * imageWidth, row * imageHeight, imageWidth, imageHeight);
                    ctx.strokeStyle = customColor;
                    ctx.strokeRect(col * imageWidth, row * imageHeight, imageWidth, imageHeight);
                }
            }

            resolve({ image: canvas.toDataURL('image/png'), width: canvas.width, height: canvas.height });
        };

        img.onerror = () => {
            reject('Failed to load image');
        };
    });
};

// Convert geographic coordinates to canvas coordinates
export const convertGeoToCanvas = (lat: number, lng: number, bbox: BBox, canvasWidth: number, canvasHeight: number): { x: number; y: number } => {
    const xRatio = (lng - bbox[1]) / (bbox[3] - bbox[1]);
    const yRatio = (lat - bbox[0]) / (bbox[2] - bbox[0]);

    const canvasX = xRatio * canvasWidth;
    const canvasY = canvasHeight - yRatio * canvasHeight; // Invert because canvas origin is top left

    return { x: canvasX, y: canvasY };
};

// Convert canvas coordinates to geographic coordinates
export const convertCanvasToGeo = (canvasX: number, canvasY: number, bbox: BBox, canvasWidth: number, canvasHeight: number): GeoPosition => {
    const xRatio = canvasX / canvasWidth;
    const yRatio = (canvasHeight - canvasY) / canvasHeight; // Invert because canvas origin is top left

    const lng = bbox[1] + xRatio * (bbox[3] - bbox[1]);
    const lat = bbox[0] + yRatio * (bbox[2] - bbox[0]);

    return { lat, lng };
};

// Il est important de respecter ce scaling de 0.925 / 1
export const STAGE_WIDTH = 684.5;
export const STAGE_HEIGHT = 740;

// Il est important de respecter ce scaling de 0.925 / 1
export const IMG_WIDTH = 740;
export const IMG_HEIGHT = 800;

// DP5
export const STAGE_WIDTH_DP5 = 712;
export const STAGE_HEIGHT_DP5 = 529;
