import * as Yup from 'yup';

const optionShape = Yup.object().shape({
  label: Yup.string(),
  value: Yup.number()
});

const schemaPac = () => {
  return Yup.object().shape({
    columns: optionShape,
    lines: optionShape,
    color: Yup.object()
      .test('is-valid-color', '', (value) => {
        if (value && typeof value === 'object' && value.label && value.value) {
          return true;
        }

        return false;
      })
      .required(),
    opacity: Yup.boolean().notRequired()
  });
};

export default schemaPac;
