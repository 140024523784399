import * as storageService from '../../../services/localStorageService';

export const buildingWindows1Before1990 = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        buildingWindows1Before1990: action,
    });

    storageService.setAuditValue(7, isValid, 'buildingWindows1Before1990', action);
};

export const buildingWindows2Presence = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        buildingWindows2Presence: action,
        buildingWindows2Before1990: '',
        buildingWindows2: '',
        buildingWindows2glazing: '',
        buildingWindows2glazingSize: '',
        buildingWindows2details: action
            ? {
                  tmp: { count: null, surface: null },
              }
            : {},
    });

    storageService.setAuditValue(7, isValid, 'buildingWindows2Presence', action);
    // Mise à jour des champs associés
    storageService.removeAuditValue(7, 'buildingWindows2Before1990');
    storageService.removeAuditValue(7, 'buildingWindows2', true);
    storageService.removeAuditValue(7, 'buildingWindows2glazing', true);
    storageService.removeAuditValue(7, 'buildingWindows2glazingSize', true);
    storageService.setAuditValue(
        7,
        isValid,
        'buildingWindows2details',
        action
            ? {
                  tmp: { count: null, surface: null },
              }
            : {}
    );

    if (!values.buildingWindows2Presence) {
        storageService.removeAuditValue(8, 'buildingPictureBuildingWindows2');

        setValues({
            ...values,
            buildingWindows2Presence: action,
            buildingWindows2Before1990: '',
            buildingWindows2: '',
            buildingWindows2glazing: '',
            buildingWindows2glazingSize: '',
            buildingWindows2details: action
                ? {
                      tmp: { count: null, surface: null },
                  }
                : {},
            buildingPictureBuildingWindows2: '',
        });
    }
};

export const buildingDoorWindows1Presence = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        buildingDoorWindows1Presence: action,
        buildingDoorWindows1: '',
        buildingDoorWindows1glazing: '',
        buildingDoorWindows1details: action
            ? {
                  tmp: { count: null, surface: null },
              }
            : {},
    });
    storageService.setAuditValue(7, isValid, 'buildingDoorWindows1Presence', action);
    // Mise à jour des champs associés
    storageService.removeAuditValue(7, 'buildingDoorWindows1', true);
    storageService.removeAuditValue(7, 'buildingDoorWindows1glazing', true);
    storageService.setAuditValue(
        7,
        isValid,
        'buildingDoorWindows1details',
        action
            ? {
                  tmp: { count: null, surface: null },
              }
            : {}
    );

    if (!values.buildingDoorWindows1Presence) {
        storageService.removeAuditValue(8, 'buildingPictureDoorWindows1');

        setValues({
            ...values,
            buildingDoorWindows1Presence: action,
            buildingDoorWindows1: '',
            buildingDoorWindows1glazing: '',
            buildingDoorWindows1details: action
                ? {
                      tmp: { count: null, surface: null },
                  }
                : {},
            buildingPictureDoorWindows1: '',
        });
    }
};

export const buildingWindows2Before1990 = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        buildingWindows2Before1990: action,
    });

    storageService.setAuditValue(7, isValid, 'buildingWindows2Before1990', action);
};

export const buildingDoor2Presence = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        buildingDoor2Presence: action,
        buildingDoor2: '',
        buildingDoor2Type: '',
    });

    storageService.setAuditValue(7, isValid, 'buildingDoor2Presence', action);
    // Mise à jour des champs associés
    storageService.removeAuditValue(7, 'buildingDoor2', true);
    storageService.removeAuditValue(7, 'buildingDoor2Type', true);

    if (!values.buildingDoor2Presence) {
        storageService.removeAuditValue(8, 'buildingPictureDoor2');

        setValues({
            ...values,
            buildingDoor2Presence: action,
            buildingDoor2: '',
            buildingDoor2Type: '',
            buildingPictureDoor2: '',
        });
    }
};

export const ventilationProtectionMotorized = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        ventilationProtectionMotorized: action,
    });

    storageService.setAuditValue(7, isValid, 'ventilationProtectionMotorized', action);
};

export const buildingDoorUnheatedRoom1Presence = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        buildingDoorUnheatedRoom1Presence: action,
        buildingDoorUnheatedRoom1: '',
        buildingDoorUnheatedRoom1Type: '',
    });

    storageService.setAuditValue(7, isValid, 'buildingDoorUnheatedRoom1Presence', action);
    // Mise à jour des champs associés
    storageService.removeAuditValue(7, 'buildingDoorUnheatedRoom1', true);
    storageService.removeAuditValue(7, 'buildingDoorUnheatedRoom1Type', true);

    if (!values.buildingDoorUnheatedRoom1Presence) {
        storageService.removeAuditValue(8, 'buildingPictureDoorUnheatedRoom1Presence');

        setValues({
            ...values,
            buildingDoorUnheatedRoom1Presence: action,
            buildingDoorUnheatedRoom1: '',
            buildingDoorUnheatedRoom1Type: '',
            buildingPictureDoorUnheatedRoom1Presence: '',
        });
    }
};

export const ventilationProtection = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        ventilationProtection: action,
        ventilationProtectionMotorized: (values.ventilationProtectionMotorized = ''),
    });

    storageService.setAuditValue(7, isValid, 'ventilationProtection', action);
    storageService.setAuditValue(7, isValid, 'ventilationProtectionMotorized', '');
};

export const windows = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        windows: action,
    });

    storageService.setAuditValue(7, isValid, 'windows', action);

    if (!action) {
        const updatedObject = {
            tmp: {
                count: null,
                surface: null,
            },
        };

        setValues({
            ...values,
            windows: action,
            // Typo 1
            ventilationProtection: '',
            buildingPictureBuildingWindows1: '',
            buildingWindows1: '',
            buildingWindows1glazing: '',
            buildingWindows1Before1990: '',
            buildingWindows1details: updatedObject,

            // Typo 2
            buildingWindows2Presence: '',
        });

        storageService.setAuditValue(7, isValid, 'ventilationProtection', '');
        storageService.removeAuditValue(7, 'buildingWindows1', true);
        storageService.removeAuditValue(7, 'buildingWindows1glazing', true);
        storageService.setAuditValue(7, isValid, 'buildingWindows1Before1990', '');
        storageService.setAuditValue(7, isValid, 'buildingWindows1details', updatedObject);
        storageService.setAuditValue(7, isValid, 'buildingWindows2Presence', '');

        storageService.removeAuditValue(8, 'buildingPictureBuildingWindows1');
    }
};
