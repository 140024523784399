import React, { useState } from 'react';
import { buildingToSerialisable, CustomPolygonNonSerialisable, GeoPosition, Path } from '../HomeInformationsEnums';
import { randomKeyGen, removeItemAtIndex } from '../../../services/tools/auditTools';
import { DEFAULT_LAT, DEFAULT_LNG } from '../../../services/tools/mapTools';
import * as storageService from '../../../services/localStorageService';
import { CustomGoogleMapDraw } from './CustomGoogleMapDraw';
import { SolarPanelModal } from './Modal/SolarModalPanel';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

// Icon
import { ReactComponent as IconTrash } from '../../../assets/icons/konva/icon-trash.svg';

type RenderSolarPanelsListProps = {
    deletePolygon: (polygonId: string) => void;
    isAddButtondisabled: () => boolean;
    setCanAddZone: React.Dispatch<React.SetStateAction<boolean>>;
    values: any;
    setValues: React.SetStateAction<any>;
    auditSent: boolean;
};

const RenderSolarPanelsList: React.FC<RenderSolarPanelsListProps> = ({ deletePolygon, isAddButtondisabled, setCanAddZone, values, auditSent }) => {
    const AlertSwal = withReactContent(Swal);
    // Function to translate the exposure
    const translateExposure = (exposure: string | undefined | null) => {
        switch (exposure) {
            case 'E':
                return 'Est';
            case 'SE':
                return 'Sud-Est';
            case 'S':
                return 'Sud';
            case 'SO':
                return 'Sud-Ouest';
            case 'O':
                return 'Ouest';
            default:
                return '';
        }
    };

    const handleDeleteModal = (polygon: string) => {
        console.log('polygon:', polygon);
        AlertSwal.fire({
            title: 'Confirmation de suppression',
            html: (
                <div className="modal-message">
                    Confirmer la suppression de cette zone d'installation ?<br />
                    Cette action est irreversible.
                </div>
            ),
            confirmButtonText: 'Supprimer',
            cancelButtonText: 'Annuler',
            showCancelButton: true,
            reverseButtons: true,
            customClass: {
                confirmButton: 'btn btn-red min-width',
                cancelButton: 'btn btn-secondary min-width',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                deletePolygon(polygon);
            }
        });
    };

    return (
        <div className="table-energy">
            <div className="table-energy--title">
                <h5>Zones d'installation</h5>
            </div>

            <table className="table">
                <thead>
                    <tr>
                        <th className="yellow-1">Intitulé</th>
                        <th className="yellow-1">Superficie (m&#178;)</th>
                        <th className="yellow-2">Inclinaison (°)</th>
                        <th className="yellow-2">Exposition</th>
                        <th className="yellow-3" style={{ width: '60px' }}>
                            &nbsp;
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {values?.solarEnergyPanels.length === 0 ? (
                        <tr>
                            <td colSpan={5} className="text-center">
                                Aucune zone ajoutée
                            </td>
                        </tr>
                    ) : (
                        <>
                            {values?.solarEnergyPanels.map((polygon: any, key: number) => (
                                <tr key={polygon.id}>
                                    <td className="text-center">Zone {key + 1}</td>
                                    <td className="text-center">{polygon.area ? Math.round(polygon.area) : null}</td>
                                    <td className="text-center">{polygon.inclination}</td>
                                    <td className="text-center">{translateExposure(polygon.exposure)}</td>
                                    <td className="text-center">
                                        <button
                                            type="button"
                                            className="btn btn-remove"
                                            title="Supprimer la zone"
                                            aria-roledescription="Supprimer"
                                            onClick={() => handleDeleteModal(polygon.id)}
                                            disabled={auditSent}
                                        >
                                            <IconTrash fill="#848484" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </>
                    )}
                </tbody>
            </table>

            <div className="text-end mt-4">
                <button type="button" className="btn btn-green" onClick={() => setCanAddZone(false)} disabled={isAddButtondisabled() || auditSent}>
                    Ajout zone d'installation
                </button>
            </div>
        </div>
    );
};

type PanelGoogleMapProps = {
    mapRef: React.RefObject<google.maps.Map>;
    values: any;
    setValues: React.SetStateAction<any>;
    auditSent: boolean;
};

export const PanelGoogleMap: React.FC<PanelGoogleMapProps> = ({ mapRef, values, setValues, auditSent }) => {
    const [canAddZone, setCanAddZone] = useState<boolean>(true);
    const [mapKey, setMapKey] = useState<number>(0);
    const [showModal, setShowModal] = useState<{ show: boolean; polygon: CustomPolygonNonSerialisable | null }>({ show: false, polygon: null });

    const step1Values = storageService.getAudit().step1;
    const userAddressLatitude = step1Values.userAddressLatitude.value as number;
    const userAddressLongitude = step1Values.userAddressLongitude.value as number;
    // const insee = localStorage.getItem('insee');

    /**
     * Function to delete a polygon, passed to children
     * @param polygonId position in array of polygon
     */
    const deletePolygon = (polygonId: string) => {
        const polygonIndex = values.solarEnergyPanels.findIndex((polygon: CustomPolygonNonSerialisable) => polygon.id === polygonId);
        if (polygonIndex === -1) return;

        values.solarEnergyPanels[polygonIndex].instance.setMap(null);
        const newPolygons = removeItemAtIndex(values.solarEnergyPanels, polygonIndex);

        const newValues = { ...values, solarEnergyPanels: newPolygons };

        setValues(newValues);
        const { solarEnergyPanelsSerialisable } = buildingToSerialisable(newValues);

        storageService.setAuditValue(2, true, 'solarEnergyPanelsSerialisable', solarEnergyPanelsSerialisable);
        setMapKey((prev) => prev + 1);
    };

    /**
     * Handles the edit event for a polygon on the Google Map.
     * @param id - The ID of the polygon being edited.
     * @param path - The updated path of the polygon.
     */
    const onPolygonEdit = (id: string, path: google.maps.MVCArray<google.maps.LatLng>) => {
        const polygonIndex = values.solarEnergyPanels.findIndex((polygon: any) => polygon.id === id);
        if (polygonIndex === -1) return;

        const newArea = google.maps.geometry.spherical.computeArea(path);
        const newPaths: Path = [];
        for (let i = 0; i < path.getLength(); i++) {
            const xy = path.getAt(i);
            newPaths.push({ lat: xy.lat(), lng: xy.lng() });
        }

        const updatedPolygon = {
            ...values.solarEnergyPanels[polygonIndex],
            area: newArea,
            paths: newPaths,
        };

        const newPolygons = [...values.solarEnergyPanels];
        newPolygons[polygonIndex] = updatedPolygon;

        setValues({ ...values, solarEnergyPanels: newPolygons });

        const { solarEnergyPanelsSerialisable } = buildingToSerialisable({ ...values, solarEnergyPanels: newPolygons });
        storageService.setAuditValue(2, true, 'solarEnergyPanelsSerialisable', solarEnergyPanelsSerialisable);
    };

    /**
     * Callback function triggered when a polygon is completed on the Google Map.
     * @param polygon - The completed polygon object.
     */
    const onPolygonComplete = (polygon: google.maps.Polygon) => {
        setCanAddZone(true);
        const vertices = polygon.getPath();
        const area = google.maps.geometry.spherical.computeArea(vertices);

        const paths: Path = [];
        for (let i = 0; i < vertices.getLength(); i++) {
            const xy = vertices.getAt(i);
            paths.push({ lat: xy.lat(), lng: xy.lng() });
        }

        const newPolygon: CustomPolygonNonSerialisable = {
            instance: polygon,
            id: randomKeyGen(),
            paths,
            area,
            inclination: null,
            exposure: null,
        };

        setShowModal({ show: true, polygon: newPolygon });
    };

    // Check if the add button should be disabled
    const isAddButtondisabled = (): boolean => !canAddZone;

    // Default center of the map
    const center: GeoPosition = {
        lat: Number(userAddressLatitude) ?? DEFAULT_LAT,
        lng: Number(userAddressLongitude) ?? DEFAULT_LNG,
    };

    const closeModal = () => {
        setShowModal({
            polygon: null,
            show: false,
        });
    };

    return (
        <>
            <div className="row">
                <div className="col-12 col-lg-7">
                    <div className="map-filter">
                        <div className="google-map-container" data-locked={auditSent}>
                            <CustomGoogleMapDraw
                                key={mapKey}
                                mapRef={mapRef}
                                withDrawing={!canAddZone}
                                onPolygonEditCallback={onPolygonEdit}
                                onPolygonCompleteCallback={onPolygonComplete}
                                center={center}
                                // addressPolygon={projectAddress?.polygon}
                                polygons={values.solarEnergyPanels}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-5">
                    <RenderSolarPanelsList
                        values={values}
                        setValues={setValues}
                        deletePolygon={deletePolygon}
                        setCanAddZone={setCanAddZone}
                        isAddButtondisabled={isAddButtondisabled}
                        auditSent={auditSent}
                    />
                    {!canAddZone && <p className="text-center text-muted fs-6 mt-5">Merci de dessiner sur votre zone sur le plan ci-contre</p>}
                </div>
            </div>

            <SolarPanelModal show={showModal.show} polygon={showModal.polygon} onClose={closeModal} values={values} setValues={setValues} />
        </>
    );
};
