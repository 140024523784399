import { Theme } from '../../services/calculs/theme';
import { TicketData } from '../../services/calculs/ticket';
import * as api from '../../services/apiParticulierService';
import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';

export type BonDeCommandeContexte = {
    themes: Theme[];
    ticket: TicketData;
    pdfState: PdfState;
    openModal?: boolean;
};
/**
 * attention ce type n'est pas aprfaitement sécurisé.
 * toutes les clés doivent être présente, sinon, on galère avec les context.pdfState['doc_name'].output = undefined
 * on admet que
 * - si input est set, le document doit être présent
 * - si input est undefined, le document NE DOIT PAS être présent
 * - si output est set, le document est déjà prêt
 * - si output est undefined (et input set), le document DOIT encore être téléchargé
 *
 */
export type PdfState = {
    [key in api.DocNames]: pdfIO<key>;
};
// NOOOON : car les docNames peuvent se mélanger !
//export type PdfStateV2 = Record<api.DocNames, pdfIO<api.DocNames>>;

type pdfIO<T extends api.DocNames> = {
    /** Si undefind, le document doit être dans la liasse finale */
    //input?: api.DocParameters;
    input?: api.DocParameters<T>; //{ docName: key; parameters: api.DocParameterType<key> };
    /** Si undefined, le document n'a pas encore été généré (ce qui ne veut pas dire qu'il doive l'être). */
    output?: api.PdfOutput<T>;
};

export const initialPdfState: PdfState = {
    devis: { input: undefined, output: undefined },
    cgv_devis: { input: undefined, output: undefined },
    bon_de_commande: { input: undefined, output: undefined },
    cgv: { input: undefined, output: undefined },
    annexe_bdc: { input: undefined, output: undefined },
    financement_sogys: { input: undefined, output: undefined },
    assurance_mma: { input: undefined, output: undefined },
    contrat_pub: { input: undefined, output: undefined },
    engagement_client_sogys: { input: undefined, output: undefined },
    simulation: { input: undefined, output: undefined },
    attestation_tva: { input: undefined, output: undefined },
    mandat_enedis: { input: undefined, output: undefined },
    mandat_special_client_sogys: { input: undefined, output: undefined },
    mandat_anah: { input: undefined, output: undefined },
};

export const getOutput = (pdfContext: PdfState, names: api.DocNames[]): Array<api.PdfOutput> => {
    const result = Array<api.PdfOutput>();
    for (const name of names) {
        if (pdfContext[name]) {
            const tmp = pdfContext[name].output;
            if (tmp) result.push(tmp);
        }
    }
    return result;
};

export const SHOW_CANCEL_BUTTON_IN_MODAL_COUNT_DOWN = false;

export const buildModalCountDownBase = (milliseconds: number): SweetAlertOptions => ({
    title: 'Génération de votre PDF',
    showCancelButton: SHOW_CANCEL_BUTTON_IN_MODAL_COUNT_DOWN,
    showCloseButton: false,
    showConfirmButton: false,
    focusConfirm: true,
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    cancelButtonText: 'ANNULER',
    customClass: {
        cancelButton: 'btn btn-continue min-width',
    },
    timer: milliseconds,
});
// Modal PDF while clicking on "continue"
// construit la vue des pdf par onglet pour l'affichage. (dans une AlertSwal.fire(...))
export const buildModalPdfContentBase = (): SweetAlertOptions => ({
    title: '',
    // equivalent to :
    // html: <PdfModalContent tabs={pdfList} />,
    width: 1200,
    confirmButtonText: 'Fermer',
    customClass: {
        confirmButton: 'btn btn-continue min-width',
    },
});
export const buildModalSimpleMessage = (message: string): SweetAlertOptions => ({
    title: 'Erreur',
    icon: 'error',
    html: message,
    width: 600,
    confirmButtonText: 'Fermer',
    customClass: {
        confirmButton: 'btn btn-continue min-width',
    },
});

export const aborterCallback = (value: SweetAlertResult<any>, aborter: AbortController, setPdfLoader: (value: React.SetStateAction<boolean>) => void) => {
    // Le cas clic sur cancel, si on décide de le mettre
    if (value && value.dismiss && value.dismiss === Swal.DismissReason.cancel) {
        aborter.abort('cancelled');
    }
    // Le cas timed out si on décide aussi
    if (value && value.dismiss && value.dismiss === Swal.DismissReason.timer) {
        aborter.abort('timedout');
    }
    setPdfLoader(false);
};

export const logBdcError = (err: any): void => {
    // transform error into message.
    const anyErr = err as any;
    let errorMessage = anyErr && anyErr && anyErr.response && anyErr.response.message ? anyErr.response.message : '';
    console.log('received in downloadPdf.catch => ' + errorMessage);
};

export const downloadPdf = async (
    aborter: AbortController,
    clearPdfContext: () => void,
    initialisePdfContext: () => api.DocParameters[],
    context: BonDeCommandeContexte
): Promise<void> => {
    clearPdfContext();
    // on a deux documents optionnels :
    // si on a des annexes.
    const auditId = localStorage.getItem('auditId')!;
    const inputs = initialisePdfContext();
    for (const input of inputs) {
        // télécharge le pdf.
        //const output: api.PdfOutput = await api.createPdf(+auditId, input, 300);
        const output: api.PdfOutput = await api.createPdf(+auditId, input, api.DOWNLOAD_TIME_OUT * 1000, aborter);

        // enrichit le context avec le nouveau PDF.
        context.pdfState[input.docName].output = output;
    }
};

// Là on a fini le parcours bon de commande.
// à chaque étape on a soit téléchargé le document, soit pas.
// maintenant, il faut signer.
export const downloadPdfMissing = async (aborter: AbortController, context: BonDeCommandeContexte): Promise<void> => {
    const auditId = localStorage.getItem('auditId')!;
    const docIO = Object.values(context.pdfState);
    for (const io of docIO) {
        //for (const name of docnameList) {
        //    const io = context.pdfState[name as api.DocNames];
        // console.log('management for doc ' + name);
        // if (!io.input) {
        //     console.log('    ignored');
        //     continue;
        // }
        // if (io.output) {
        //     console.log('    already downloaded');
        //     continue;
        // }

        if (io.input && !io.output) {
            //console.log('    Should download ' + io.input.docName);
            // télécharge le pdf.
            const output: api.PdfOutput = await api.createPdf(+auditId, io.input, api.DOWNLOAD_TIME_OUT * 1000, aborter);
            // enrichit le context avec le nouveau PDF.
            context.pdfState[io.input.docName].output = output;
        }
    }
};
