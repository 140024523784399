import React from 'react';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

import * as storageService from '../../services/localStorageService';

import { ReactComponent as IconPhotos } from '../../assets/icons/icon-upload-file.svg';
import { ReactComponent as IconRemove } from '../../assets/icons/icon-x-mark.svg';

interface Props {
    prop: any;
    setFieldValue: any;
    values: any;
    fieldValue: string;
    disabled?: boolean;
    image?: any;
    required?: boolean;
}

const AddStepPhotos: React.FC<Props> = ({ prop, setFieldValue, values, fieldValue, disabled, required }) => {
    const AlertSwal = withReactContent(Swal);

    /**
     * Liste des champs obligatoires dans l'étape 8
     */
    let mandatoryPhotosStep8: string[] = [
        'buildingPictureGeneral',
        'buildingPictureNorth',
        'buildingPictureEast',
        'buildingPictureSouth',
        'buildingPictureWest',
        'buildingPicturesheaterFeature',
        'buildingPicturesenergyWater',
        'buildingPicturesheaterGeneratorPower',
        'buildingPictureDoor1',
        'buildingPictureBuildingWindows1',
        'energyCostsElectricInvoice1',
    ];

    // Ajouter Gaz dans la liste si heaterType ou heaterType2 === '4'
    if (storageService.getAudit().step3.heaterType.value === '4' || storageService.getAudit().step3.heaterType2.value === '4') {
        mandatoryPhotosStep8 = [...mandatoryPhotosStep8, 'energyCostsGasInvoice1'];
    }

    /**
     * Fonction pour vérifier la validité de l'étape 8 en fonction des champs obligatoires
     */
    const checkValidityStep8 = () => {
        let valueMandatoryStep8: string[] = [];

        mandatoryPhotosStep8.forEach((propertyName: string) => {
            valueMandatoryStep8 = [...valueMandatoryStep8, storageService.getAudit().step8[propertyName].value];
        });

        return valueMandatoryStep8.every((valuePropertyName: string) => valuePropertyName !== '');
    };

    /**
     * Fonction pour confirmer la suppression de l'image (Autre forme)
     */
    const confirmDeleteImage = async () => {
        return await AlertSwal.fire({
            imageUrl: `${values[fieldValue]}`,
            imageHeight: 250,
            html: `Confirmez-vous la suppression de cette image ?`,
            width: 550,
            confirmButtonText: 'Supprimer',
            cancelButtonText: 'Annuler',
            showCancelButton: true,
            focusCancel: true,
            customClass: {
                confirmButton: 'btn btn-red-border min-width',
                cancelButton: 'btn btn-primary min-width',
            },
        }).then((result) => {
            return result.isConfirmed;
        });
    };

    /**
     * Lors du clic sur la zone de largage
     * @param event
     * @param name
     * @param values
     * @param setFieldValue
     * @param isValid
     */
    const fileClick = async (event: any, name: string, values: any, setFieldValue: any) => {
        event.preventDefault();
        if (event.target.files.length) {
            await handleFiles(event.target.files, name, values, setFieldValue);
        }
    };

    /**
     * Manipulation du fichier
     * @param files
     * @param name
     * @param values (valeurs du formulaire)
     * @param setFieldValue (Methode Formik pour mise à jour d'un élément du formulaire)
     * @param isValid (Valeur de la validité du formulaire)
     */
    const handleFiles = async (files: any, name: string, values: any, setFieldValue: any) => {
        if (validateFile(files[0])) {
            // Redimensionnement du fichier
            const resizedFile = await resize(files[0], 500, 500);

            if (resizedFile) {
                // Écriture dans le Formik
                setFieldValue(name, resizedFile);
                // Envoi dans le local storage
                storageService.setAuditValue(8, false, name, resizedFile);
            }

            await AlertSwal.fire({
                title: 'Votre photo a été envoyée, retrouvez là dans l’étape 8',
                icon: 'success',
                imageUrl: `${resizedFile}`,
                imageHeight: 250,
                width: 550,
                confirmButtonText: 'Valider',
                focusConfirm: true,
                customClass: {
                    confirmButton: 'btn btn-primary min-width',
                },
            }).then(() => storageService.setAuditStepValidity(8, checkValidityStep8()));
        } else {
            // Mauvais format
            await AlertSwal.fire({
                title: `Erreur de format`,
                html: `Le format du fichier chargé n'est pas supporté.<br/>Merci de charger une image au format <b>.jpeg</b>, <b>.png</b> ou <b>.webp</b>.`,
                icon: 'error',
                width: 550,
                confirmButtonText: 'Fermer',
                focusConfirm: true,
                customClass: {
                    confirmButton: 'btn btn-primary min-width',
                },
            });
        }
    };

    /**
     * Fonction pour redimensionner l'image
     * @param file (fichier d'origine)
     * @param max_width (largueur maximale)
     * @param max_height (hauteur maximale)
     */
    async function resize(file: any, max_width: number, max_height: number) {
        return new Promise((resolve, reject) => {
            // Préparation du fileReader
            let fileLoader: any = new FileReader();
            let imageObj = new Image();
            fileLoader.onload = function () {
                imageObj.src = this.result;
            };

            let canvas = document.createElement('canvas');
            let context: any = null;

            // Creation d'un canvas caché por dessiner
            canvas.id = 'hiddenCanvas';
            canvas.style.visibility = 'hidden';

            // Gestion des cas avec abandon
            fileLoader.onabort = function () {
                reject(`Le chargement du fichier a été annulé`);
            };

            // Gestion des cas avec erreurs
            fileLoader.onerror = function () {
                reject(`Une erreur s'est produite lors du chargement du fichier`);
            };

            // Lancement readAsDataURL
            fileLoader.readAsDataURL(file);

            // Dessin de la nouvelle image
            imageObj.onload = async (event: any) => {
                // Définition image avec vérification présence info (pour Safari ne respectant pas le standard HTML5)
                const img = event?.path ? event.path[0] : event.target.outerHTML;

                const MAX_WIDTH = max_width;
                const MAX_HEIGHT = max_height;
                // Définition taille de l'image avec vérification présence infos (pour Safari ne respectant pas le standard HTML5)
                let width = img?.width ? img.width : event.target.naturalWidth;
                let height = img?.height ? img.height : event.target.naturalHeight;

                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                document.body.appendChild(canvas);

                // Définition du context à utiliser
                context = canvas.getContext('2d');

                // Dessin
                context.drawImage(imageObj, 0, 0, width, height);
                let dataUrl = canvas.toDataURL('image/jpeg');

                // const blob = dataURItoBlob(dataUrl);

                resolve(dataUrl);
            };

            // Gestion des cas avec abandon
            imageObj.onabort = function () {
                reject(`Le chargement de l'image a été annulé`);
            };

            imageObj.onerror = function () {
                reject(`Une erreur s'est produite lors du chargement de l'image`);
            };
        });
    }

    /**
     * Fonction pour vérifier le format du fichier chargé
     * @param file (fichier d'origine)
     */
    const validateFile = (file: File) => {
        return !!file.type.match('image.*');
    };

    return (
        <div className={disabled ? 'icon-photo disabled' : 'icon-photo'}>
            {values[fieldValue] === '' ? (
                <>
                    <input
                        type="file"
                        ref={prop}
                        disabled={disabled}
                        accept="image/jpg, image/png, image/jpeg, image/webp"
                        onChange={(event) => fileClick(event, fieldValue, values, setFieldValue)}
                        data-name={fieldValue}
                    />
                    <IconPhotos fill={disabled ? 'rgba(0, 0, 0, .25)' : '#5FC8DF'} width={20} height={20} />
                    Ajouter une photo&nbsp;<small>{required ? '(obligatoire)' : '(facultatif)'}</small>
                </>
            ) : (
                <>
                    <button
                        type="button"
                        className="btn-delete"
                        disabled={disabled}
                        onClick={() => {
                            confirmDeleteImage()
                                .then((result: any) => {
                                    if (result) {
                                        setFieldValue(fieldValue, '');
                                        storageService.removeAuditValue(8, fieldValue);
                                    }
                                })
                                .then(() => storageService.setAuditStepValidity(8, checkValidityStep8()));
                        }}
                    >
                        <IconRemove fill={disabled ? 'rgba(0, 0, 0, .25)' : '#5FC8DF'} width={20} height={20} />
                        Supprimer la photo
                    </button>
                </>
            )}
        </div>
    );
};

export default AddStepPhotos;
