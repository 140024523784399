import React from 'react';
import { format } from 'date-fns';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { PdfModal } from '../../../components/PdfModal/PdfModal';
import { Back } from '../../../components/Back/Back';

// Icon
import { ReactComponent as IconEye } from '../../../assets/icons/espace-agent/icon-eye.svg';

const PagePDF: React.FC = () => {
    // date d'installation
    const installationDate: string | null = localStorage.getItem('installationDate');
    // PDF
    const pdfUrl = localStorage.getItem('previsitDocUrl') ?? '';
    const flowId: string = localStorage.getItem('flowId') ?? '';

    const displayDate = (date: string) => {
        return format(Date.parse(date), 'dd/MM/yyyy');
    };

    const displayHours = (date: string) => {
        const hours = format(Date.parse(date), 'kk:mm');

        switch (hours) {
            case '08:00':
                return '08:00 - 12:00';

            case '14:00':
                return '14:00 - 18:00';

            default:
                return '';
        }
    };

    // Modal
    const AlertSwal = withReactContent(Swal);
    const modal = () => {
        AlertSwal.fire({
            title: '',
            html: <PdfModal pdf={pdfUrl} />,
            width: 900,
            confirmButtonText: 'Fermer',
            customClass: {
                confirmButton: 'btn btn-continue min-width',
            },
        });
    };

    return (
        <div className="container py-5">
            <Back title="Retour" url={`/espace-agent/list-audit/${flowId}`} />
            <div className="container-creation py-4 px-4 mt-5">
                <div className="row mb-4">
                    <h2>Liste des documents de la pré-visite</h2>

                    <div className="col-12 col-md-6">
                        <div className="form-inline">
                            <div className="form-group account">
                                <label htmlFor="date" className="col-form-label">
                                    Date d'installation :
                                </label>
                                <div className="position-relative w-100">
                                    <input
                                        type="text"
                                        id="date"
                                        className="form-control invisible-field px-0"
                                        readOnly
                                        value={installationDate ? `${displayDate(installationDate)} ${displayHours(installationDate)}` : 'Non défini'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="btn-grp">
                    <button className="btn btn-orange btn-orange--icon" onClick={modal}>
                        <IconEye fill="#FFF" />
                        PDF Pré-visite
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PagePDF;
