import { Labelled } from '../../../../services/tools/TypeHelper';
import { CreateAgentFormValues } from './CreateAgent';
import * as api from '../../../../services/apiParticulierService';

export const civilityList: Array<Labelled<api.Civility>> = [
    {
        label: 'Monsieur',
        value: 'MR',
    },
    {
        label: 'Madame',
        value: 'MRS',
    },
    {
        label: 'Mademoiselle',
        value: 'MISS',
    },
];

export const EmptyCreateAgentFormValues: CreateAgentFormValues = {
    civility: undefined,
    lastname: '',
    firstname: '',
    email: '',
    phoneNumber: '',
    address: '',
    zipcode: '',
    city: '',
    identityDocument1Id: undefined,

    identityDocument1: null,
};

export const createRandomAgent = (): CreateAgentFormValues => {
    function randName<T>(liste: Array<T>): T {
        return liste[Math.floor(Math.random() * liste.length)];
    }
    const firstname = randName(['Antoine', 'Sophie', 'Louis', 'Marie', 'Pierre', 'Catherine', 'Jean', 'Élisabeth', 'Guillaume', 'Isabelle']);
    const lastname = randName(['Martin', 'Dubois', 'Thomas', 'Lefèvre', 'Robert', 'Richard', 'Petit', 'Durand', 'Leroy', 'Moreau']);
    const address = randName(["5 rue de l'Eglise", '3 rue du général de gaulle']);
    const { zipcode, city } = randName([
        { zipcode: '92100', city: 'Boulogne-Billancourt' },
        { zipcode: '77420', city: 'Champs sur marne' },
        { zipcode: '75002', city: 'Paris' },
    ]);
    return {
        civility: civilityList[0],
        lastname,
        firstname,
        phoneNumber: '0626406536',
        email: 'romain.taillandier@wassa.fr', // firstname + '.' + lastname + '@wassa.io',
        address,
        zipcode,
        city,
        identityDocument1Id: undefined,
        identityDocument1: null,
    };
};
