import IconRectangle from '../../assets/icons/icons-form-select/Rectangle.svg';
import IconL from '../../assets/icons/icons-form-select/L.svg';
import IconLInverse from '../../assets/icons/icons-form-select/L-inverse.svg';
import IconT from '../../assets/icons/icons-form-select/T.svg';
import IconTInverse from '../../assets/icons/icons-form-select/T-inverse.svg';
import IconU from '../../assets/icons/icons-form-select/U.svg';
import IconUInverse from '../../assets/icons/icons-form-select/U-inverse.svg';
import IconY from '../../assets/icons/icons-form-select/Y.svg';
import IconYInverse from '../../assets/icons/icons-form-select/Y-inverse.svg';
import IconX from '../../assets/icons/icons-form-select/X.svg';
import IconH from '../../assets/icons/icons-form-select/H.svg';
import IconCustom from '../../assets/icons/icons-form-select/custom.svg';

import PreviewRectangle from '../../assets/images/previews-form-select/Rectangle.svg';
import PreviewIconL from '../../assets/images/previews-form-select/PreviewIconL.svg';
import PreviewIconLInverse from '../../assets/images/previews-form-select/PreviewIconLInverse.svg';
import PreviewIconT from '../../assets/images/previews-form-select/PreviewIconT.svg';
import PreviewIconTInverse from '../../assets/images/previews-form-select/PreviewIconTInverse.svg';
import PreviewIconU from '../../assets/images/previews-form-select/PreviewIconU.svg';
import PreviewIconUInverse from '../../assets/images/previews-form-select/PreviewIconUInverse.svg';
import PreviewIconY from '../../assets/images/previews-form-select/PreviewIconY.svg';
import PreviewIconYInverse from '../../assets/images/previews-form-select/PreviewIconYInverse.svg';
import PreviewIconX from '../../assets/images/previews-form-select/PreviewIconX.svg';
import PreviewIconH from '../../assets/images/previews-form-select/PreviewIconH.svg';
import { Labelled } from '../../services/tools/TypeHelper';
import { polygonOptions } from './components/helpers';
import { Floor } from '../../services/componentsHelpers/buildingService';

/**
 * Liste des types de project
 */
export const projectTypeList: { label: string; value: string }[] = [
    {
        label: 'Maison individuelle',
        value: '1',
    },
    {
        label: 'Appartement',
        value: '2',
    },
    {
        label: 'Immeuble collectif',
        value: '3',
    },
    {
        label: 'Bâtiment',
        value: '4',
    },
];

/**
 * Liste du nombre de Pans
 */
export const foldNumberList: { label: string; value: string }[] = [
    {
        value: '1',
        label: '1 pan',
    },
    {
        value: '2',
        label: '2 pans',
    },
    {
        value: '3',
        label: '4 pans',
    },
    {
        value: '4',
        label: '5 pans et +',
    },
    {
        value: '5',
        label: 'Toit terrasse',
    },
];

/**
 * Liste des % des masques solaire
 */

export const solarMaskList: { label: string; value: string }[] = [
    {
        label: '0%',
        value: '0',
    },
    {
        label: '10%',
        value: '1',
    },
    {
        label: '20%',
        value: '2',
    },
    {
        label: '30%',
        value: '3',
    },
    {
        label: '40%',
        value: '4',
    },
    {
        label: '50%',
        value: '5',
    },
    {
        label: '60%',
        value: '6',
    },
    {
        label: '70%',
        value: '7',
    },
    {
        label: '80%',
        value: '8',
    },
];

/**
 * Liste du status du bien
 */
export const projectStatusList: { label: string; value: string }[] = [
    {
        label: 'Propriétaire',
        value: '0',
    },
    {
        label: 'Locataire',
        value: '1',
    },
    {
        label: 'Usufruitier',
        value: '2',
    },
];

/**
 * List des type de chauffage pour la piscine
 */
export const poolHeatList: { label: string; value: string }[] = [
    {
        label: 'Pompe à chaleur',
        value: '1',
    },
    {
        label: "Lié à l'habitation",
        value: '2',
    },
];

/**
 * Liste des formes disponibles
 */
export const shapeList: { label: string; id: number; value: string; icon: string; defaultTemplate: Floor; preview?: string }[] = [
    {
        label: 'Rectangulaire',
        id: 1,
        value: '1',
        icon: IconRectangle,
        defaultTemplate: {
            name: '',
            shape: '1',
            height: 2.5,
            length: null,
            width: null,
            orientation: 'N',
            img: '',
            url: '',
            base64: '',
        },
        preview: PreviewRectangle,
    },
    {
        label: 'En L',
        id: 2,
        value: '11',
        icon: IconL,
        defaultTemplate: {
            name: '',
            shape: '11',
            height: 2.5,
            length: null,
            width: null,
            l1: null,
            l2: null,
            orientation: 'N',
            img: '',
            url: '',
            base64: '',
        },
        preview: PreviewIconL,
    },
    {
        label: 'En L inversé',
        id: 3,
        value: '12',
        icon: IconLInverse,
        defaultTemplate: {
            name: '',
            shape: '12',
            height: 2.5,
            length: null,
            width: null,
            l1: null,
            l2: null,
            orientation: 'N',
            img: '',
            url: '',
            base64: '',
        },
        preview: PreviewIconLInverse,
    },
    {
        label: 'En T',
        id: 4,
        value: '21',
        icon: IconT,
        defaultTemplate: {
            name: '',
            shape: '21',
            height: 2.5,
            length: null,
            width: null,
            l1: null,
            l2: null,
            l3: null,
            l4: null,
            orientation: 'N',
            img: '',
            url: '',
            base64: '',
        },
        preview: PreviewIconT,
    },
    {
        label: 'En T inversé',
        id: 5,
        value: '22',
        icon: IconTInverse,
        defaultTemplate: {
            name: '',
            shape: '22',
            height: 2.5,
            length: null,
            width: null,
            l1: null,
            l2: null,
            l3: null,
            l4: null,
            orientation: 'N',
            img: '',
            url: '',
            base64: '',
        },
        preview: PreviewIconTInverse,
    },
    {
        label: 'En U',
        id: 6,
        value: '31',
        icon: IconU,
        defaultTemplate: {
            name: '',
            shape: '31',
            height: 2.5,
            length: null,
            width: null,
            l1: null,
            l2: null,
            l4: null,
            orientation: 'N',
            img: '',
            url: '',
            base64: '',
        },
        preview: PreviewIconU,
    },
    {
        label: 'En U inversé',
        id: 7,
        value: '32',
        icon: IconUInverse,
        defaultTemplate: {
            name: '',
            shape: '32',
            height: 2.5,
            length: null,
            width: null,
            l1: null,
            l2: null,
            l4: null,
            orientation: 'N',
            img: '',
            url: '',
            base64: '',
        },
        preview: PreviewIconUInverse,
    },
    {
        label: 'En Y',
        id: 8,
        value: '41',
        icon: IconY,
        defaultTemplate: {
            name: '',
            shape: '41',
            height: 2.5,
            length: null,
            width: null,
            l1: null,
            l2: null,
            l3: null,
            l4: null,
            l5: null,
            l6: null,
            orientation: 'N',
            img: '',
            url: '',
            base64: '',
        },
        preview: PreviewIconY,
    },
    {
        label: 'En Y inversé',
        id: 9,
        value: '42',
        icon: IconYInverse,
        defaultTemplate: {
            name: '',
            shape: '42',
            height: 2.5,
            length: null,
            width: null,
            l1: null,
            l2: null,
            l3: null,
            l4: null,
            l5: null,
            l6: null,
            orientation: 'N',
            img: '',
            url: '',
            base64: '',
        },
        preview: PreviewIconYInverse,
    },
    {
        label: 'En X',
        id: 10,
        value: '51',
        icon: IconX,
        defaultTemplate: {
            name: '',
            shape: '51',
            height: 2.5,
            length: null,
            width: null,
            l1: null,
            l2: null,
            l3: null,
            l4: null,
            l5: null,
            l6: null,
            l7: null,
            l8: null,
            orientation: 'N',
            img: '',
            url: '',
            base64: '',
        },
        preview: PreviewIconX,
    },
    {
        label: 'En H',
        id: 11,
        value: '52',
        icon: IconH,
        defaultTemplate: {
            name: '',
            shape: '52',
            height: 2.5,
            length: null,
            width: null,
            l1: null,
            l2: null,
            l4: null,
            l5: null,
            l6: null,
            l7: null,
            orientation: 'N',
            img: '',
            url: '',
            base64: '',
        },
        preview: PreviewIconH,
    },
    {
        label: 'Autre forme',
        id: 12,
        value: '99',
        icon: IconCustom,
        defaultTemplate: {
            name: '',
            shape: '99',
            height: 2.5,
            length: null,
            width: null,
            orientation: 'N',
            img: '',
            url: '',
            base64: '',
        },
    },
];

/**
 * Liste des étages disponibles
 */
export const floorList: { label: string; value: string }[] = [
    {
        label: 'Sous-sol',
        value: 'Sous-sol',
    },
    {
        label: 'Rez-de-chaussée',
        value: 'Rez-de-chaussée',
    },
    {
        label: '1er étage',
        value: '1er étage',
    },
    {
        label: '2ème étage',
        value: '2ème étage',
    },
];

/**
 * Liste des orientations disponibles
 */
export const orientationList: { label: string; value: string }[] = [
    {
        label: 'Nord',
        value: 'N',
    },
    {
        label: 'Nord-Est',
        value: 'NE',
    },
    {
        label: 'Est',
        value: 'E',
    },
    {
        label: 'Sud-Est',
        value: 'SE',
    },
    {
        label: 'Sud',
        value: 'S',
    },
    {
        label: 'Sud-Ouest',
        value: 'SO',
    },
    {
        label: 'Ouest',
        value: 'O',
    },
    {
        label: 'Nord-Ouest',
        value: 'NO',
    },
];

/**
 * Liste des angles disponibles pour le toit
 */
export const tiltList: { label: string; value: string }[] = [
    {
        label: '0°',
        value: '0',
    },
    {
        label: '5°',
        value: '1',
    },
    {
        label: '10°',
        value: '2',
    },
    {
        label: '15°',
        value: '3',
    },
    {
        label: '20°',
        value: '4',
    },
    {
        label: '25°',
        value: '5',
    },
    {
        label: '30°',
        value: '6',
    },
    {
        label: '35°',
        value: '7',
    },
    {
        label: '40°',
        value: '8',
    },
    {
        label: '45°',
        value: '9',
    },
    {
        label: '50°',
        value: '10',
    },
    {
        label: '55°',
        value: '11',
    },
    {
        label: '60°',
        value: '12',
    },
];

/**
 * Liste des types de circulation
 */
export const circulationTypeList: { label: string; value: string }[] = [
    {
        label: 'Circulation commune chauffée',
        value: '1',
    },
    {
        label: 'Présence d’un sas',
        value: '2',
    },
    {
        label: 'Circulation centrale',
        value: '3',
    },
    {
        label: 'Parois sur circulation isolées',
        value: '4',
    },
];

/**
 * Liste d'exposition de la toiture
 */
export const roofExposureList: { label: string; value: string }[] = [
    // {
    //     label: "Nord",
    //     value: "1",
    // },
    // {
    //     label: "Nord-Est",
    //     value: "2",
    // },
    // {
    //     label: "Nord-Ouest",
    //     value: "3",
    // },
    {
        label: 'Sud',
        value: '4',
    },
    {
        label: 'Sud-Est',
        value: '5',
    },
    {
        label: 'Sud-Ouest',
        value: '6',
    },
    {
        label: 'Est',
        value: '7',
    },
    {
        label: 'Ouest',
        value: '8',
    },
];

/**
 * Liste du nombre de pièces
 */
export const numberList: { label: string; value: string }[] = [
    {
        label: '1',
        value: '1',
    },
    {
        label: '2',
        value: '2',
    },
    {
        label: '3',
        value: '3',
    },
    {
        label: '4',
        value: '4',
    },
    {
        label: '5',
        value: '5',
    },
    {
        label: '6',
        value: '6',
    },
    {
        label: '7',
        value: '7',
    },
    {
        label: '8',
        value: '8',
    },
    {
        label: '9',
        value: '9',
    },
    {
        label: '10',
        value: '10',
    },
    {
        label: '11',
        value: '11',
    },
    {
        label: '12',
        value: '12',
    },
    {
        label: '13',
        value: '13',
    },
    {
        label: '14',
        value: '14',
    },
    {
        label: '15',
        value: '15',
    },
    {
        label: '16',
        value: '16',
    },
    {
        label: '17',
        value: '17',
    },
    {
        label: '18',
        value: '18',
    },
    {
        label: '19',
        value: '19',
    },
    {
        label: '20',
        value: '20',
    },
];

export const buildingTypeList: { label: string; value: string }[] = [
    {
        value: '1',
        label: 'Studio',
    },
    {
        value: '2',
        label: 'T1',
    },
    {
        value: '3',
        label: 'T2',
    },
    {
        value: '4',
        label: 'T3',
    },
    {
        value: '5',
        label: 'T4',
    },
    {
        value: '6',
        label: 'T5',
    },
    {
        value: '7',
        label: 'T6',
    },
    {
        value: '8',
        label: 'T7',
    },
    {
        value: '9',
        label: 'T8',
    },
];

export const buildingTypeEmissionList: { label: string; value: string }[] = [
    {
        label: 'Système principal',
        value: '1',
    },
    {
        label: 'Système secondaire',
        value: '2',
    },
];

export const buildingTypeECSList: { label: string; value: string }[] = [
    {
        label: 'Electrique',
        value: '1',
    },
    {
        label: 'Lié au chauffage',
        value: '2',
    },
    {
        label: 'Préparateur Gaz',
        value: '3',
    },
];

// Nombre de niveaux (1 à 10)
export const floorCountList: { label: string; value: string }[] = [
    {
        label: '1',
        value: '1',
    },
    {
        label: '2',
        value: '2',
    },
    {
        label: '3',
        value: '3',
    },
    {
        label: '4',
        value: '4',
    },
    {
        label: '5',
        value: '5',
    },
    {
        label: '6',
        value: '6',
    },
    {
        label: '7',
        value: '7',
    },
    {
        label: '8',
        value: '8',
    },
    {
        label: '9',
        value: '9',
    },
    {
        label: '10',
        value: '10',
    },
];

export type GeoPosition = {
    lat: number;
    lng: number;
};

export type Path = GeoPosition[];

export type CustomPolygonSerialisable = {
    paths: Path;
    area?: number;
    panelUnitCount?: number;
    id: string;
    inclination?: number | null;
    exposure?: string | null;
};

export interface CustomPolygonNonSerialisable extends CustomPolygonSerialisable {
    instance: google.maps.Polygon;
}

/**
 * Liste des angles disponibles pour le toit
 */
export const tiltListModal: Array<Labelled<number>> = [
    {
        label: '0°',
        value: 0,
    },
    {
        label: '5°',
        value: 5,
    },
    {
        label: '10°',
        value: 10,
    },
    {
        label: '15°',
        value: 15,
    },
    {
        label: '20°',
        value: 20,
    },
    {
        label: '25°',
        value: 25,
    },
    {
        label: '30°',
        value: 30,
    },
    {
        label: '35°',
        value: 35,
    },
    {
        label: '40°',
        value: 40,
    },
    {
        label: '45°',
        value: 45,
    },
    {
        label: '50°',
        value: 50,
    },
    {
        label: '55°',
        value: 55,
    },
    {
        label: '60°',
        value: 60,
    },
];

export const exposureList: Array<Labelled<string>> = [
    {
        label: 'Sud',
        value: 'S',
    },
    {
        label: 'Sud-Est',
        value: 'SE',
    },
    {
        label: 'Sud-Ouest',
        value: 'SO',
    },
    {
        label: 'Est',
        value: 'E',
    },
    {
        label: 'Ouest',
        value: 'O',
    },
];

export const serialisableToNonSerialisable = (serialisable: CustomPolygonSerialisable[]): CustomPolygonNonSerialisable[] => {
    // Prevent crash if no polygons
    if (!serialisable || !Array.isArray(serialisable)) {
        return [];
    }

    const energyPanelsWithInstance = serialisable.map((polygon) => {
        const newInstance = new google.maps.Polygon({
            paths: polygon.paths,
            ...polygonOptions,
        });

        return { ...polygon, instance: newInstance };
    });
    return energyPanelsWithInstance;
};

export const nonSerialisableToSerialisable = (customs: CustomPolygonNonSerialisable[]): CustomPolygonSerialisable[] => {
    return customs.map((custom) => {
        const vertices = custom.instance.getPath();
        const area = google.maps.geometry.spherical.computeArea(custom.instance.getPath());

        const paths = [];

        for (let i = 0; i < vertices.getLength(); i++) {
            const xy = vertices.getAt(i);
            paths.push({ lat: xy.lat(), lng: xy.lng() });
        }

        return {
            id: custom.id,
            paths,
            area,
            inclination: custom.inclination,
            exposure: custom.exposure,
        };
    });
};

export const buildingToSerialisable = (building: any) => {
    const panel = building.solarEnergyPanels;
    const solarEnergyPanelsSerialisable = nonSerialisableToSerialisable(panel);

    const output = {
        ...building,
        solarEnergyPanels: undefined,
        solarEnergyPanelsSerialisable,
    };

    return output;
};
