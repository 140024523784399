import React from 'react';
import { heatPumpTypeList, heatPumpSourceList, panelStyles, panelDisabled, FormValues } from '../PompeAChaleurEnums';
import { useField, useFormikContext } from 'formik';
import Select from 'react-select';
import * as storageService from '../../../../services/localStorageService';

// Icons
import { ReactComponent as IconPoubelle } from '../../../../assets/icons/icon-remove.svg';
import { renderDefaultValueSelect } from '../../../../services/tools/selectValue';
import { LabelledString } from '../../../../services/tools/TypeHelper';

type HeatingCircuitFieldProps = {
    index: number;
    remove: (index: number) => void;
    values: FormValues;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
};

const HeatingCircuitFields: React.FC<HeatingCircuitFieldProps> = ({ remove, index, values }) => {
    // meta sert à utiliser setValue
    const [typeField, , typeHelpers] = useField(`heatingCircuit.${index}.type`);
    const [sourceField, , sourceHelpers] = useField(`heatingCircuit.${index}.source`);
    const [tempField, , tempHelpers] = useField<string>(`heatingCircuit.${index}.temperature`);

    const { touched } = useFormikContext<FormValues>();

    // Remove element from Array
    const handleRemove = () => {
        let tmp = [...values.heatingCircuit];

        tmp.splice(index, 1);
        storageService.setPrevisitValue('heatingCircuit', tmp);

        remove(index);
    };

    const handleDisabledField = () => {
        if (values.heatingCircuit.length - 1 > index) {
            return true;
        }

        return false;
    };

    return (
        <div className="circuit">
            <div className="row justify-content-between align-items-end">
                <div className="col-12 col-md-1">
                    <h4 className="circuit-title">Circuit {index + 1}</h4>
                </div>
                <div className="col-12 col-md-3">
                    <div className="form-group">
                        <label htmlFor={typeField.name}>
                            Type de circuit<sup>*</sup>
                        </label>
                        <Select
                            value={renderDefaultValueSelect(heatPumpTypeList, values.heatingCircuit[index].type)}
                            options={heatPumpTypeList}
                            onChange={(option: LabelledString | null) => {
                                if (!option) return;
                                let tmp = values.heatingCircuit;
                                tmp[index].type = option.value;

                                typeHelpers.setValue(option.value);
                                storageService.setPrevisitValue('heatingCircuit', tmp);
                            }}
                            isSearchable={false}
                            isClearable={false}
                            isMulti={false}
                            placeholder="Type de circuit"
                            className={
                                'basic-single' +
                                (values.heatingCircuit[index].type ? ' filled' : '') +
                                ((touched as any)['type-' + index] && !values.heatingCircuit[index].type ? ' invalid' : '') +
                                (!values.heatingCircuit[index].type ? ' required' : '')
                            }
                            styles={!handleDisabledField() ? panelStyles : panelDisabled}
                            isDisabled={handleDisabledField()}
                        />
                    </div>
                </div>

                <div className="col-12 col-md-3">
                    <div className="form-group">
                        <label htmlFor={tempField.name}>
                            Temperature du circuit<sup>*</sup>
                        </label>
                        <div className="input-group">
                            <input
                                type="number"
                                className={
                                    'form-control' +
                                    (values.heatingCircuit[index].temperature ? ' filled' : '') +
                                    ((touched as any)['temperature-' + index] && !values.heatingCircuit[index].temperature ? ' invalid' : '') +
                                    (!values.heatingCircuit[index].temperature ? ' required' : '')
                                }
                                step={0.01}
                                max={99}
                                {...tempField}
                                onChange={(e) => {
                                    let tmp = values.heatingCircuit;
                                    tmp[index].temperature = e.target.value;

                                    tempHelpers.setValue(e.target.value);
                                    storageService.setPrevisitValue(`heatingCircuit`, tmp);
                                }}
                                value={tempField.value}
                                onBlur={(e) => {
                                    tempHelpers.setValue(e.target.value);
                                }}
                                placeholder="Temperature du circuit"
                                disabled={handleDisabledField()}
                            />
                            <span className="input-group-text">°C</span>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-3">
                    <div className="form-group">
                        <label htmlFor={sourceField.name}>
                            Source / Emetteur<sup>*</sup>
                        </label>
                        <Select
                            value={renderDefaultValueSelect(heatPumpSourceList, values.heatingCircuit[index].source)}
                            options={heatPumpSourceList}
                            onChange={(option) => {
                                if (!option) return;
                                let tmp = values.heatingCircuit;
                                tmp[index].source = option.value;

                                sourceHelpers.setValue(option.value);
                                storageService.setPrevisitValue('heatingCircuit', tmp);
                            }}
                            name={sourceField.name}
                            isSearchable={false}
                            isClearable={false}
                            isMulti={false}
                            placeholder="Source / Emetteur"
                            styles={!handleDisabledField() ? panelStyles : panelDisabled}
                            className={
                                'basic-single' +
                                (values.heatingCircuit[index].source ? ' filled' : '') +
                                ((touched as any)['source-' + index] && !values.heatingCircuit[index].source ? ' invalid' : '') +
                                (!values.heatingCircuit[index].source ? ' required' : '')
                            }
                            isDisabled={handleDisabledField()}
                        />
                    </div>
                </div>

                {handleDisabledField() ? (
                    <div className="col-12 col-md-1">
                        <button className="btn btn-supprimer" onClick={handleRemove}>
                            <IconPoubelle fill="red" />
                        </button>
                    </div>
                ) : (
                    <div className="col-12 col-md-1"></div>
                )}
            </div>
        </div>
    );
};

export default HeatingCircuitFields;
