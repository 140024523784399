import React from 'react';
import { SubTheme } from '../../services/calculs/theme';
import PackageComponent from './PackageContainer';

// House of cards

export const SubThemeContainerComponent: React.FC<SubTheme> = (subTheme: React.PropsWithChildren<SubTheme>) => {
    return (
        <>
            <div className="tab-body">
                <h4>
                    {subTheme.subThemeType} <span>({subTheme?.packages.length})</span>
                </h4>
                <div className="cards-container">
                    {subTheme.packages && subTheme.packages.map((packageData) => <PackageComponent {...packageData} key={packageData.id} />)}
                </div>
            </div>
        </>
    );
};
