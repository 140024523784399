// binding events to polygon: https://codesandbox.io/s/reactgooglemapsapi-editing-a-polygon-popr2?file=/src/index.js:1577-1901
// loading editable polygons: http://bl.ocks.org/knownasilya/89a32e572989f0aff1f8
// pre-loading-an-editable-drawingmanager-polygon-in-google-maps: https://stackoverflow.com/questions/15504751/pre-loading-an-editable-drawingmanager-polygon-in-google-maps
import React, { CSSProperties, useCallback, useRef } from 'react';
import { DrawingManager, GoogleMap, GoogleMapProps } from '@react-google-maps/api';
import { GeoPosition, CustomPolygon, PolygonWithID } from '../../pages/audit-simulator/rendement/Rendement';
import { randomKeyGen } from '../../services/tools/auditTools';

const containerStyle = {
    width: '100%',
    height: '400px',
};

type CustomGoogleMapDrawProps = {
    center?: GeoPosition;
    polygons?: CustomPolygon[];
    googleMapProps?: GoogleMapProps;
    withDrawing?: boolean;
    style?: CSSProperties;
    onCustomPolygonComplete?: (polygon: PolygonWithID) => void;
    onCustomPolygonEdit?: (polygon: CustomPolygon) => void;
};

export const nonEditablepolygonOptions: google.maps.PolygonOptions = {
    fillOpacity: 0.7,
    strokeWeight: 0,
    draggable: false,
    geodesic: false,
    editable: false,
    clickable: false,
    zIndex: 1,
};

export const editablePolygonOptions: google.maps.PolygonOptions = {
    fillColor: '#434d5d',
    fillOpacity: 0.7,
    strokeColor: '#434d5d',
    strokeOpacity: 1,
    strokeWeight: 4,
    draggable: false,
    geodesic: false,
    editable: true,
    clickable: true,
    zIndex: 1,
};

export const mapOptions: google.maps.MapOptions = {
    tilt: 0,
    disableDefaultUI: false,
    streetViewControl: false,
    disableDoubleClickZoom: true,
    fullscreenControl: false,
    mapTypeControlOptions: {
        mapTypeIds: ['satellite', 'roadmap'],
    },
    mapTypeId: 'satellite',
    styles: [
        {
            featureType: 'poi',
            stylers: [{ visibility: 'off' }],
        },
        {
            featureType: 'transit',
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }],
        },
    ],
    panControl: false,
    scaleControl: true,
    keyboardShortcuts: false,
};

const drawingOptions = {
    drawingMode: google.maps.drawing.OverlayType.POLYGON,
    drawingControlOptions: {
        drawingModes: [],
    },
    polygonOptions: editablePolygonOptions,
};

const GoogleMapsDraw: React.FC<CustomGoogleMapDrawProps> = ({
    googleMapProps,
    onCustomPolygonEdit,
    polygons,
    center,
    style,
    withDrawing,
    onCustomPolygonComplete,
}) => {
    const { mapContainerStyle, options } = googleMapProps ?? {};
    const mapRef = useRef<google.maps.Map | null>(null);

    console.log({
        polygons: polygons?.map((polygon) => {
            return [
                polygon.paths.map((path) => {
                    return [path.lng, path.lat];
                }),
            ];
        }),
    });

    const onPolygonComplete = useCallback(
        (polygon: PolygonWithID) => {
            polygon.id = randomKeyGen();

            if (onCustomPolygonComplete) {
                onCustomPolygonComplete(polygon);
            }
        },
        [onCustomPolygonComplete]
    );

    // DON'T TOUCH THIS CRAPPY THING PLEASE
    // The idea is that we clear existing listeners when a handler passed as a prop has been updated then we reattach them.
    // See https://developers.google.com/maps/documentation/javascript/react-map#map-component-props
    React.useEffect(() => {
        if (!polygons || polygons.length === 0) return;

        return () => {
            // To clear all listeners for the 'insert_at', 'remove_at', and 'set_at' events on all paths:
            polygons.forEach((polygon) => {
                polygon.instance.getPaths().forEach((path) => {
                    ['insert_at', 'remove_at', 'set_at'].forEach((event) => {
                        if (google.maps.event.hasListeners(path, event)) {
                            google.maps.event.clearListeners(path, event);
                        }
                    });
                });
            });
        };
    }, [polygons]);

    // set ref to map
    const onMapLoad = useCallback(
        (map: google.maps.Map) => {
            mapRef.current = map;

            if (!polygons || polygons.length === 0) return;
            polygons.forEach((polygon) => {
                polygon.instance.setMap(mapRef.current);

                // attach event listeners to all paths of all polygons in order to watch area changes
                if (onCustomPolygonEdit) {
                    polygon.instance.getPaths().forEach((path) => {
                        ['insert_at', 'remove_at', 'set_at'].forEach((event) => {
                            if (!google.maps.event.hasListeners(path, event)) {
                                google.maps.event.addListener(path, event, () => onCustomPolygonEdit(polygon));
                            }
                        });
                    });
                }
            });
        },
        [polygons, onCustomPolygonEdit]
    );

    return (
        <div style={style}>
            <GoogleMap
                {...googleMapProps}
                onLoad={onMapLoad}
                options={{ ...mapOptions, ...options }}
                mapContainerStyle={{ ...containerStyle, ...mapContainerStyle }}
                center={center}
            >
                <>{withDrawing && <DrawingManager options={drawingOptions} onPolygonComplete={onPolygonComplete} />}</>
            </GoogleMap>
        </div>
    );
};
export default React.memo(GoogleMapsDraw);
