export const childrenList: { label: string; value: string }[] = [
    {
        value: "0",
        label: "0",
    },
    {
        value: "1",
        label: "1",
    },
    {
        value: "2",
        label: "2",
    },
    {
        value: "3",
        label: "3",
    },
    {
        value: "4",
        label: "4",
    },
    {
        value: "5",
        label: "5",
    },
    {
        value: "6",
        label: "6",
    },
    {
        value: "7",
        label: "7",
    },
    {
        value: "8",
        label: "8",
    },
    {
        value: "9",
        label: "9",
    },
    {
        value: "10",
        label: "10",
    },
];

export const maritalStatusList: { label: string; value: string }[] = [
    {
        label: "Célibataire",
        value: "1",
    },
    {
        label: "Marié",
        value: "2",
    },
    {
        label: "Pacsé",
        value: "3",
    },
    {
        label: "Concubinage",
        value: "4",
    },
];

export const civilityList: { label: string; value: string }[] = [
    {
        label: "Monsieur",
        value: "Mr",
    },
    {
        label: "Madame",
        value: "Mme",
    },
    {
        label: "Mademoiselle",
        value: "Mlle",
    },
];

const zipCodeList: string[] = ['fr', 'gp', 'mq', 'gf', 're', 'pm', 'yt', 'tf', 'wf', 'pf', 'nc', 'bl', 'mf', 'cp'];

export const searchOptions = {
    types: ["address"],
    componentRestrictions: {
        country: zipCodeList
    },
};

export const searchOptionsPostalCode = {
    types: ["(regions)"],
    componentRestrictions: {
        country: zipCodeList
    },
};

export const employerTypeList: { label: string; value: string }[] = [
    {
        value: "0",
        label: "Entreprise privée",
    },
    {
        value: "1",
        label: "Entreprise publique",
    },
    {
        value: "2",
        label: "Entreprise du secteur agricole",
    }
];

export const contractTypeList: { label: string; value: string }[] = [
    {
        value: "0",
        label: "CDI",
    },
    {
        value: "1",
        label: "CDD",
    },
    {
        value: "3",
        label: "Sans emploi",
    },
    {
        value: "4",
        label: "Retraité",
    }
];

type TPublicOptions = {
    label: string;
    value: string;
};

type TPublicCompany = {
    label: string;
    options: TPublicOptions[];
};

export const publicCompanyList: TPublicCompany[] = [
    {
        label: 'Autres',
        options: [
           {
               value: '998',
               label: 'Autres'
           }
       ]
    },
    {
        label: 'Etablissements publics à caractère industriel et commercial (EPIC)',
        options: [
            {
                value: '0',
                label: 'ADEME (Agence de l\'environnement et de la maîtrise de l\'énergie)'
            },
            {
                value: '1',
                label: 'ADIT (Agence pour la diffusion de l\'information technologique - Leader de l\'intelligence stratégique)'
            },
            {
                value: '2',
                label: 'ANDRA (Agence nationale pour la gestion des déchets radioactifs)'
            },
            {
                value: '3',
                label: 'Bpifrance'
            },
            {
                value: '4',
                label: 'B.R.G.M. (Bureau de recherches géologiques et minières)'
            },
            {
                value: '5',
                label: 'C.E.A. (Commissariat à l\'énergie atomique)'
            },
            {
                value: '6',
                label: 'CIRAD (Centre de coopération international en recherche agronomique)'
            },
            {
                value: '7',
                label: 'CNES (Centre national d\'études spatiales)'
            },
            {
                value: '8',
                label: 'Universcience Cité des sciences et de l\'industrie'
            },
            {
                value: '9',
                label: 'C.S.T.B. (Centre scientifique et technique du bâtiment)'
            },
            {
                value: '10',
                label: 'IFPEN I.F.P. Energies nouvelles'
            },
            {
                value: '11',
                label: 'IFREMER (Institut français de recherche pour l\'exploitation de la mer)'
            },
            {
                value: '12',
                label: 'INERIS (Institut national de l\'environnement industriel et des risques)'
            },
            {
                value: '13',
                label: 'I.R.S.N. (Institut de radioprotection et de sûreté nucléaire)'
            },
            {
                value: '14',
                label: 'ONERA (Office national d\'études et de recherches aérospatiales)'
            }
        ]
    },
    {
        label: 'Entreprises publiques hors EPIC',
        options: [
            {
                value: '15',
                label: 'la Poste'
            },
            {
                value: '16',
                label: 'EDF'
            },
            {
                value: '17',
                label: 'Aéroports de Paris'
            },
            {
                value: '18',
                label: 'Air France'
            },
            {
                value: '19',
                label: 'Orange'
            },
            {
                value: '20',
                label: 'France Télévision'
            },
            {
                value: '21',
                label: 'DCNS'
            },
            {
                value: '22',
                label: 'GDF Suez'
            },
            {
                value: '23',
                label: 'GIAT industries-Nexter'
            },
            {
                value: '24',
                label: 'Imprimerie national'
            }
        ]
    },
    {
        label: 'Organismes privés à financement public prédominant (secteur non marchand)',
        options: [
            {
                value: '25',
                label: 'Associations'
            },
            {
                value: '26',
                label: 'Organismes de protection sociale'
            },
            {
                value: '27',
                label: 'Hôpitaux à but non lucratif'
            },
            {
                value: '28',
                label: 'SAFER (société d’aménagement foncier et d’établissement rural)'
            }
        ]
    }
];
