export const allDepts: string[] = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
    "51",
    "52",
    "53",
    "54",
    "55",
    "56",
    "57",
    "58",
    "59",
    "60",
    "61",
    "62",
    "63",
    "64",
    "65",
    "66",
    "67",
    "68",
    "69",
    "70",
    "71",
    "72",
    "73",
    "74",
    "75",
    "76",
    "77",
    "78",
    "79",
    "80",
    "81",
    "82",
    "83",
    "84",
    "85",
    "86",
    "87",
    "88",
    "89",
    "90",
    "91",
    "92",
    "93",
    "94",
    "95",
    "96",
    "97",
    "98",
    "99",
    // DOM TOM / collectivités d'outre-mer
    "971",
    "972",
    "973",
    "974",
    "975",
    "976",
    "984",
    "986",
    "987",
    "988"
];

export const otherProvider: { value: string; label: string; departement: string[]; electricity: boolean; gas: boolean }[] = [
    {
        value: "999",
        label: `Autres`,
        departement: allDepts,
        electricity: true,
        gas: true,
    },
];

/**
 * Liste des types de chauffage (principal)
 */
export const heaterTypeList: { label: string; value: string; included: string[] }[] = [
    {
        label: "Électrique",
        value: "0",
        included: ["1", "2", "3", "4"],
    },
    {
        label: "Bois",
        value: "1",
        included: ["1", "2", "3", "4"],
    },
    {
        label: "Electrique et réseau",
        value: "2",
        included: ["3"],
    },
    {
        label: "Elec. thermodynamique (PAC)",
        value: "3",
        included: ["1", "2", "3", "4"],
    },
    {
        label: "Gaz",
        value: "4",
        included: ["1", "2", "3", "4"],
    },
    {
        label: "Réseau de chaleur",
        value: "5",
        included: ["3"],
    },
    {
        label: "Fioul",
        value: "6",
        included: ["1", "2", "3", "4"],
    },
];

/**
 * Liste des types d'émetteur
 */
export const heaterFeatureList: { label: string; value: string; included: string[] }[] = [
    {
        label: "Convecteur",
        value: "0",
        included: ["0", "2"],
    },
    {
        label: "Panneau rayonnant",
        value: "1",
        included: ["0", "2"],
    },
    {
        label: "Ventilo convecteur",
        value: "2",
        included: ["0", "2"],
    },
    {
        label: "Air soufflé",
        value: "3",
        included: ["3"],
    },
    {
        label: "Radiateur avec vanne thermostatique",
        value: "4",
        included: ["1", "3", "4", "5", "6"],
    },
    {
        label: "Radiateur sans vanne thermostatique",
        value: "5",
        included: ["1", "3", "4", "5", "6"],
    },
    {
        label: "Plancher chauffant",
        value: "6",
        included: ["0", "1", "2", "3", "4", "5", "6"],
    },
    {
        label: "Poêle",
        value: "7",
        included: ["1"],
    },
    {
        label: "Appareil à bûches",
        value: "8",
        included: ["1"],
    },
];

/**
 * Liste des types d'émetteur de gaz
 */
export const heaterSubscriptionGasList: { label: string; value: string; included: string[]; includedProjectType?: string[] }[] = [
    {
        label: "Gaz > Tarif de base",
        value: "34",
        included: ["4"],
        includedProjectType: ["1", "2", "4"],
    },
    {
        label: "Gaz > Tarif B0",
        value: "35",
        included: ["4"],
        includedProjectType: ["1", "2", "4"],
    },
    {
        label: "Gaz > Tarif B1 et 3GB",
        value: "36",
        included: ["4"],
        includedProjectType: ["1", "2", "4"],
    },
    {
        label: "Gaz > Propane",
        value: "42",
        included: ["4"],
        includedProjectType: ["1", "2", "4"],
    },
    {
        label: "Gaz > Tarif B2I",
        value: "37",
        included: ["4"],
        includedProjectType: ["3"],
    },
    {
        label: "Gaz > Tarif B2S",
        value: "38",
        included: ["4"],
        includedProjectType: ["3"],
    },
    {
        label: "Gaz > Tarif B2M",
        value: "39",
        included: ["4"],
        includedProjectType: ["3"],
    },
    {
        label: "Gaz > Tarif TEL",
        value: "40",
        included: ["4"],
        includedProjectType: ["3"],
    },
    {
        label: "Gaz > Tarif TEL Nuit",
        value: "41",
        included: ["4"],
        includedProjectType: ["3"],
    },
];

/**
 * Liste des types d'émetteur d'électrecité
 */
export const heaterSubscriptionElectricityList: { label: string; value: string; included: string[]; includedProjectType?: string[] }[] = [
    {
        label: "Électrique > Tarif bleu Option de base > 3kVA",
        value: "1",
        included: ["0"],
        includedProjectType: ["1", "2", "3", "4"],
    },
    {
        label: "Électrique > Tarif bleu Option de base > 6kVA",
        value: "2",
        included: ["0"],
        includedProjectType: ["1", "2", "3", "4"],
    },
    {
        label: "Électrique > Tarif bleu Option de base > 9kVA",
        value: "3",
        included: ["0"],
        includedProjectType: ["1", "2", "3", "4"],
    },
    {
        label: "Électrique > Tarif bleu Option de base > 12kVA",
        value: "4",
        included: ["0"],
        includedProjectType: ["1", "2", "3", "4"],
    },
    {
        label: "Électrique > Tarif bleu Option de base > 15kVA",
        value: "5",
        included: ["0"],
        includedProjectType: ["1", "2", "3", "4"],
    },
    {
        label: "Électrique > Tarif bleu Option de base > 18kVA",
        value: "6",
        included: ["0"],
        includedProjectType: ["1", "2", "3", "4"],
    },
    {
        label: "Électrique > Tarif bleu Option de base > 24kVA",
        value: "7",
        included: ["0"],
        includedProjectType: ["1", "2", "3", "4"],
    },
    {
        label: "Électrique > Tarif bleu Option de base > 30kVA",
        value: "8",
        included: ["0"],
        includedProjectType: ["1", "2", "3", "4"],
    },
    {
        label: "Électrique > Tarif bleu Option de base > 36kVA",
        value: "9",
        included: ["0"],
        includedProjectType: ["1", "2", "3", "4"],
    },
    {
        label: "Electrique > Tarif bleu Option heures creuses > 6kVA",
        value: "10",
        included: ["0"],
        includedProjectType: ["1", "2", "3", "4"],
    },
    {
        label: "Electrique > Tarif bleu Option heures creuses > 9kVA",
        value: "11",
        included: ["0"],
        includedProjectType: ["1", "2", "3", "4"],
    },
    {
        label: "Electrique > Tarif bleu Option heures creuses > 12kVA",
        value: "12",
        included: ["0"],
        includedProjectType: ["1", "2", "3", "4"],
    },
    {
        label: "Electrique > Tarif bleu Option heures creuses > 15kVA",
        value: "13",
        included: ["0"],
        includedProjectType: ["1", "2", "3", "4"],
    },
    {
        label: "Electrique > Tarif bleu Option heures creuses > 18kVA",
        value: "14",
        included: ["0"],
        includedProjectType: ["1", "2", "3", "4"],
    },
    {
        label: "Electrique > Tarif bleu Option heures creuses > 24kVA",
        value: "15",
        included: ["0"],
        includedProjectType: ["1", "2", "3", "4"],
    },
    {
        label: "Electrique > Tarif bleu Option heures creuses > 30kVA",
        value: "16",
        included: ["0"],
        includedProjectType: ["1", "2", "3", "4"],
    },
    {
        label: "Electrique > Tarif bleu Option heures creuses > 36kVA",
        value: "17",
        included: ["0"],
        includedProjectType: ["1", "2", "3", "4"],
    },
    {
        label: "Électrique > Tarif bleu Option EJP > 12kVA",
        value: "18",
        included: ["0"],
        includedProjectType: ["1", "2", "3", "4"],
    },
    {
        label: "Électrique > Tarif bleu Option EJP > 15kVA",
        value: "19",
        included: ["0"],
        includedProjectType: ["1", "2", "3", "4"],
    },
    {
        label: "Électrique > Tarif bleu Option EJP > 18kVA",
        value: "20",
        included: ["0"],
        includedProjectType: ["1", "2", "3", "4"],
    },
    {
        label: "Électrique > Tarif bleu Option EJP > 36kVA",
        value: "21",
        included: ["0"],
        includedProjectType: ["1", "2", "3", "4"],
    },
    {
        label: "Électrique > Tarif bleu Option Tempo > 9kVA",
        value: "22",
        included: ["0"],
        includedProjectType: ["1", "2", "3", "4"],
    },
    {
        label: "Électrique > Tarif bleu Option Tempo > 12kVA",
        value: "23",
        included: ["0"],
        includedProjectType: ["1", "2", "3", "4"],
    },
    {
        label: "Électrique > Tarif bleu Option Tempo > 15kVA",
        value: "24",
        included: ["0"],
        includedProjectType: ["1", "2", "3", "4"],
    },
    {
        label: "Électrique > Tarif bleu Option Tempo > 18kVA",
        value: "25",
        included: ["0"],
        includedProjectType: ["1", "2", "3", "4"],
    },
    {
        label: "Électrique > Tarif bleu Option Tempo > 30kVA",
        value: "26",
        included: ["0"],
        includedProjectType: ["1", "2", "3", "4"],
    },
    {
        label: "Électrique > Tarif bleu Option Tempo > 36kVA",
        value: "27",
        included: ["0"],
        includedProjectType: ["1", "2", "3", "4"],
    },
    {
        label: "Électrique > Tarif jaune-Version UL",
        value: "28",
        included: ["0"],
        includedProjectType: ["3", "4"],
    },
    {
        label: "Électrique > Tarif jaune-Version UM",
        value: "29",
        included: ["0"],
        includedProjectType: ["3", "4"],
    },
    {
        label: "Électrique > Tarif vert A5-Version TLU",
        value: "30",
        included: ["0"],
        includedProjectType: ["3", "4"],
    },
    {
        label: "Électrique > Tarif vert A5-Version LU",
        value: "31",
        included: ["0"],
        includedProjectType: ["3", "4"],
    },
    {
        label: "Électrique > Tarif vert A5-Version MU",
        value: "32",
        included: ["0"],
        includedProjectType: ["3", "4"],
    },
    {
        label: "Électrique > Tarif vert A5-Version CU",
        value: "33",
        included: ["0"],
        includedProjectType: ["3", "4"],
    },
];

/**
 * Liste des emplacements
 */
export const heaterGeneratorEmplacementList: { label: string; value: string }[] = [
    {
        label: "En volume chauffé",
        value: "0",
    },
    {
        label: "Hors volume chauffé",
        value: "1",
    },
];

/**
 * Liste des types de ventilations
 */
export const ventilationTypeList: { label: string; value: string }[] = [
    {
        label: "Ventilation mécanique Simple flux autoréglables",
        value: "2",
    },
    {
        label: "Ventilation mécanique Simple flux hygroréglable",
        value: "3",
    },
    {
        label: "Ventilation mécanique Double flux",
        value: "5",
    },
    {
        label: "Pas de ventilation mécanique",
        value: "7",
    },
];

/**
 * Liste des types d'énergie de l'Eau chaude sanitaire
 */
export const energyWaterList: { label: string; value: string }[] = [
    {
        label: "Électrique",
        value: "1",
    },
    {
        label: "Lié au chauffage",
        value: "2",
    },
];

/**
 * Liste de l'orientation du chauffe-eau
 */
export const heaterHotWaterElectricalOrientationList: { label: string; value: string }[] = [
    {
        label: "Horizontal",
        value: "0",
    },
    {
        label: "Vertical",
        value: "1",
    },
];

/**
 * Liste de l'emplacement du chauffe-eau
 */
export const heaterHotWaterElectricalEmplacementList: { label: string; value: string }[] = [
    {
        label: "En volume chauffé",
        value: "0",
    },
    {
        label: "Hors volume chauffé",
        value: "1",
    },
];

/**
 * Liste des emplacements du système indépendant chauffe-bain
 */
export const heaterHotWaterIndependentBathHeaterEmplacementList: { label: string; value: string }[] = [
    {
        label: "En volume chauffé",
        value: "1",
    },
    {
        label: "Hors volume chauffé",
        value: "2",
    },
];

// List photovoltaiquePower
export const photovoltaiquePowerList: { label: string; value: string }[] = [
    {
        label: "0 kW < 3 kW",
        value: "1",
    },
    {
        label: "3,01 kW < 6 kW",
        value: "2",
    },
    {
        label: "6,01 kW < 9 kW",
        value: "3",
    },
    {
        label: "> 9 kW",
        value: "4",
    },
];

// List photovoltaiqueConsumption
export const photovoltaiqueConsumptionList: { label: string; value: string }[] = [
    {
        label: "Autoconsommation",
        value: "1",
    },
    {
        label: "Revente",
        value: "2",
    },
    {
        label: "Autoconsommation et revente",
        value: "3",
    },
];

export const condensingHeaterList: { label: string; value: string }[] = [
    {
        value: "1",
        label: "Oui",
    },
    {
        value: "0",
        label: "Non",
    },
    {
        value: "99",
        label: "Inconnu – pas indiqué sur la chaudière",
    },
];
