import * as Yup from 'yup';
import { useRecoilValue } from 'recoil';
import { errorState } from '../../../../services/Recoil/Atom/dp5Error.atom';

const shapeModulSchema = Yup.object().shape({
    color: Yup.string(),
    elements: Yup.array().of(
        Yup.array().of(
            Yup.object().shape({
                x: Yup.number().nullable().typeError('X doit être un chiffre'),
                y: Yup.number().nullable().typeError('Y doit être un chiffre'),
            })
        )
    ),
});

function roofShapeHasData(roofShape: any) {
    return (
        roofShape &&
        Object.values(roofShape as Record<string, { elements: any[] }>).some((module) => module.elements && module.elements.some((row) => row.length > 0))
    );
}

export const GetValidationSchema = () => {
    const error = useRecoilValue(errorState);

    return Yup.object({
        plannedInstallation: Yup.string().required("L'installation prévue sur est obligatoire"),
        electricMeterLocalisation: Yup.string().required("L'emplacement du compteur est obligatoire"),
        electricalTypeNetwork: Yup.string().required('Le type de réseau électrique est obligatoire'),
        electricMeterOutside: Yup.string()
            .notRequired()
            .when('electricMeterLocalisation', {
                is: (value: string) => value === 'exterieur' || value === 'public',
                then: Yup.string().required('Le coffret électrique extérieur est obligatoire'),
            }),
        circuitBreaker: Yup.string().required("L'emplacement du disjoncteur est obligatoire"),
        distance: Yup.string().required('La distance est obligatoire'),
        pdlNumber: Yup.string()
            .required('Le numéro PDL est obligatoire')
            .nullable()
            .test('pdlLength', 'Le numéro PDL est composé de 14 chiffres', (value) => value?.length === 14),
        electricMeter30m: Yup.boolean()
            .notRequired()
            .when('electricMeterLocalisation', {
                is: (value: string) => value === 'exterieur' || value === 'public',
                then: Yup.boolean().required('Veuillez préciser si le coffret électrique est situé à 30m de votre maison'),
            }),
        electricMeterSpace: Yup.boolean().required(`Veuillez préciser si un espace d'au moins 1m x 1m est disponible`),
        remoteReport: Yup.boolean()
            .notRequired()
            .when('linkyMeter', {
                is: false,
                then: Yup.boolean().required('La présence du télé report est obligatoire'),
            }),
        remoteReportLocalisation: Yup.string()
            .notRequired()
            .when('remoteReport', {
                is: true,
                then: Yup.string().required('La localisation du télé report est obligatoire'),
            }),
        groundConnection: Yup.boolean().required('La présence de la mise à la terre est obligatoire'),
        groundConnectionLocalisation: Yup.string()
            .notRequired()
            .when('groundConnection', {
                is: true,
                then: Yup.string().required('La localisation de la mise à la terre est obligatoire'),
            }),
        linkyMeter: Yup.boolean().required('Le compteur Linky est obligatoire'),
        gutterHeight: Yup.string().nullable().required('La hauteur du sol à la gouttière est obligatoire'),
        internet: Yup.boolean().required("Veuillez préciser si vous disposez d'une connexion internet"),
        fai: Yup.string()
            .notRequired()
            .when('internet', {
                is: true,
                then: Yup.string().required("Veuillez préciser le fournisseur d'accès internet"),
            }),
        materialStorage: Yup.string().required('Possibilité de stockage de matériel chez le client est obligatoire'),
        siteAccessibility: Yup.string().max(500, 'Ce champs est limité à 500 caractères').required('Accessibilité du site / type de camion est obligatoire'),
        coverType: Yup.string().required('Le type de couverture est obligatoire'),
        coverTilesDetail: Yup.string()
            .notRequired()
            .when('coverType', {
                is: (value: string) => value === 'autre',
                then: Yup.string().required('Veuillez préciser le type de couverture'),
            }),
        coverConstructionYear: Yup.string().required('Année de construction de la couverture est obligatoire'),
        tilesState: Yup.string().required("L'Etat des éléments de couverture est obligatoire"),
        fullRenovation: Yup.boolean()
            .notRequired()
            .when('tilesState', {
                is: (value: string) => value === 'tres mauvais etat',
                then: Yup.boolean().required('La rénovation complète est obligatoire'),
            }),
        distanceBelow3KmFromCoastline: Yup.boolean().required('La distance du littoral est obligatoire'),
        drawSchema: Yup.boolean(),
        schemaScale: Yup.string()
            .notRequired()
            .when(['drawSchema', 'roofShape'], {
                is: (drawSchema: boolean, roofShape: any) => drawSchema || (!drawSchema && roofShapeHasData(roofShape)),
                then: Yup.string().required("L'echelle du schéma est obligatoire"),
                otherwise: Yup.string().notRequired(),
            }),
        roofShape: Yup.object()
            .shape({
                toiture: shapeModulSchema,
                cheminee: shapeModulSchema,
                velux: shapeModulSchema,
                chien_assis: shapeModulSchema,
                panneaux_photovoltaiques: shapeModulSchema,
                panneaux_paysage: shapeModulSchema,
            })
            .notRequired()
            .when('drawSchema', {
                is: true,
                then: Yup.object()
                    .test('at-least-one-element', 'roofShape doit contenir au moins 1 élément', roofShapeHasData)
                    .required('roofShape est obligatoire'),
                otherwise: Yup.object().notRequired(),
            }),
        parcel: error ? Yup.string().notRequired() : Yup.string().required('Le numéro de parcelle est obligatoire'),
        history: error
            ? Yup.array().notRequired()
            : Yup.array()
                  .min(2, 'Au moins 1 panneau photovoltaïque doit être dessiner sur le DP1/2/2bis')
                  .required('Au moins 1 panneau photovoltaïque doit être dessiner sur le DP1/2/2bis'),
        historyDP5: Yup.array()
            .min(1, 'Au moins 1 panneau photovoltaïque doit être dessiner sur le DP5')
            .required('Au moins 1 panneau photovoltaïque doit être dessiner sur le DP5'),
        // Pictures
        edfMeterPicture: Yup.string().required("La photo compteur du fournisseur d'électricité est obligatoire"),
        electricMeterPicture: Yup.string().required('La photo tableau électrique est obligatoire'),
        circuitBreakerPicture: Yup.string().required('La photo du disjoncteur est obligatoire'),
        inverterOrEnergyMeterRoomPicture: Yup.string().required("La photo du local ou sera installé l'onduleur ou le compteur d'énergie est obligatoire"),
        singleStoreyHousePicture: Yup.string().required('Photo de la maison de plain pied ou se fera l’installation'),
        houseRearFacePicture: Yup.string().required("La photo de la maison de plain pied face arrière ou se fera l'installation est obligatoire"),
        panoramicBackPicture1: Yup.string().required('La photo maison dans son dos pour voir son horizon 1 est obligatoire'),
        panoramicBackPicture2: Yup.string().required('La photo maison dans son dos pour voir son horizon 2 est obligatoire'),
        panoramicBackPicture3: Yup.string().required('La photo maison dans son dos pour voir son horizon 3 est obligatoire'),
        panoramicFacePicture1: Yup.string().required('La photo maison face à soi pour voir la maison dans son environnement 1 est obligatoire'),
        panoramicFacePicture2: Yup.string().required('La photo maison face à soi pour voir la maison dans son environnement 2 est obligatoire'),
        panoramicFacePicture3: Yup.string().required('La photo maison face à soi pour voir la maison dans son environnement 3 est obligatoire'),
    });
};
