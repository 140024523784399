import React, { useEffect, useState } from 'react';
import { Field, Formik } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import {
    panelStyles,
    panelDisabled,
    FormValues,
    scales,
    installationDesProduitsList,
    initialTakingOutInsuranceList,
    initialDefermentPeriodList,
} from './FinancementEnums';
import Select from 'react-select';
import { Labelled, LabelledString, NoticeProps } from '../../../services/tools/TypeHelper';
import withReactContent from 'sweetalert2-react-content';
import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import { validationSchema } from './schema/schema';
import { PropertyType } from '../../../services/localStorageService';
import * as storageService from '../../../services/localStorageService';

import { ROUTE_SIM_ANNEXE, ROUTE_SIM_ENGAGEMENT_CLIENT, ROUTE_SIM_RENDEMENT, ROUTE_SIM_TVA } from '../../../routing/paths';
import * as api from '../../../services/apiParticulierService';
import * as bdc from '../parcoursBdcCommmons';
import PdfModalContent from '../../../components/PdfModalContent/PdfModalContent';
import { ModalCounter } from '../../../components/ModalCounter/ModalCounter';
import { getFirstPanneau, hasFullTVA } from '../../../services/calculs/theme';
// Set default value to <Select />
import { renderDefaultValueSelect } from '../../../services/tools/selectValue';
import { createDynamicList } from '../../../services/tools/auditTools';
import { FinancialList } from './component/FinancialList';
import { format, addDays } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import Dates from './component/Dates';
import { postJsonData } from '../../../services/apiParticulierService';

// Icons
import { ReactComponent as IconFinancement } from '../../../assets/icons/simulator/icon-financement.svg';
import { ReactComponent as Loader } from '../../../assets/icons/loader.svg';

// Style
import './Financement.scss';

// README : voir BonDeCommande.tsx

// Les PDF que cet écran permet de créer
// EN CAS DE CHANGEMENT FAIRE ATTENTION AUX FONCTIONS initialisePdfContext().
const PDF_NAMES_FOR_THIS_SCREEN: api.DocNames[] = ['financement_sogys'];

// TODO nicolas :
// le TAEG fixe et le taux débiteur fixe ne permettent aps la saisie de virgule comme séparateur de centimes.
// alors que le montant total du crédit accepte les deux.

// Simulation défavorable
// actuelleemnt, si la simulation est défavorable, on bloque le finacement définitivement.

const Financement: React.FC = () => {
    const [loader, setLoader] = useState<boolean>(false);
    const [pdfLoader, setPdfLoader] = useState<boolean>(false);
    const [, setSimulation] = useState<api.FinancoSimulation>();
    const [lccStatus, setLccStatus] = useState<boolean>(false);
    const [simulationLoader, setSimulationLoader] = useState<boolean>(false);
    const [simulationDefavorable, setSimulationDefavorable] = useState<boolean>(false);
    const [currentScale, setCurrentScale] = useState<api.FinancoScale>(scales[0].value);
    const [paymentFrequencyList, setPaymentFrequencyList] = useState<Array<LabelledString>>([]);
    const [takingOutInsuranceList, setTakingOutInsuranceList] = useState<Array<Labelled<string | undefined>>>(initialTakingOutInsuranceList);
    const [defermentPeriodList, setDefermentPeriodList] = useState<Array<Labelled<number>>>(initialDefermentPeriodList);
    const flowId = localStorage.getItem('flowId')!;

    // Handle if result is inferior(-) or superior(+) to result
    const checkMinMaxAndSet = (
        e: React.ChangeEvent<HTMLInputElement>,
        values: FormValues,
        setValues: (values: React.SetStateAction<FormValues>, shouldValidate?: boolean | undefined) => void
    ) => {
        const min: number = 0;
        const max: number = Number(reste_a_charge_unformatted_str);
        const result: number = Number(e.target.value);

        // assign min/max value on blur
        setValues({
            ...values,
            deposit: Math.max(min, Math.min(result, max)).toString(),
        });

        storageService.setSimValue('deposit', Math.max(min, Math.min(result, max)).toString());
    };

    const simulate = async (
        data: api.FinancoLoan,
        setValues: (values: React.SetStateAction<FormValues>, shouldValidate?: boolean | undefined) => void,
        values: FormValues
    ): Promise<void> => {
        try {
            setSimulationLoader(true);
            const response: api.FinancoSimulation = await api.postFinancoSimulation(data);
            // Close the error modal, just in case
            AlertSwal.close();
            // Debugg
            // console.log('loan:', response);
            setSimulation(response);

            setValues({
                ...values,
                simulation: true,
                loan: response,
            });

            // Set loan into localStorage
            storageService.setSimValue('loan', response);
            storageService.setSimValue('simulation', true);
            setSimulationLoader(false);
        } catch (e) {
            setSimulationLoader(false);
            console.error(JSON.stringify(e, null, 2));
            AlertSwal.fire({
                icon: 'error',
                title: 'Problème d’accès à la simulation',
                html: <p>Nous rencontrons actuellement un problème d’accès à la simulation de votre financement. Merci de ré-itérer votre demande.</p>,
                confirmButtonText: 'Fermer',
                customClass: {
                    confirmButton: 'btn btn-continue min-width',
                },
            });

            setValues({
                ...values,
                simulation: false,
            });

            storageService.setSimValue('simulation', false);
        }
    };

    const [choixDeOrganismeFinancierList, setChoixDeOrganismeFinancierList] = useState<Array<LabelledString>>([]);

    const AlertSwal = withReactContent(Swal);
    const context = useLocation<bdc.BonDeCommandeContexte>().state;

    // Get LCC from /myself
    useEffect(() => {
        const getAgentLccStatus = async () => {
            const myself = await api.getMyself();
            setLccStatus(myself.fundingEnpowerment?.hasLcc === true);
        };

        const loadScaleList = async () => {
            // Get initial insurance list :
            const scalesFromLocalStorage: string | null = localStorage.getItem('financoScales');
            const scales: Array<Labelled<api.FinancoScale>> = scalesFromLocalStorage ? JSON.parse(scalesFromLocalStorage) : [];
            setCurrentScale(scales[0].value);
        };

        const loadInsuranceList = async () => {
            // Get initial insurance list :
            const insuranceList = await api.getFinancoInsurances({
                scaleId: currentScale.id,
                depositAmount: 0,
                numberOfPayments: 12,
                purchaseAmount:
                    storageService.checkPropertyExistThenCreateOrRenderSimulator('commandAmount', 'Montant de la commande', PropertyType['basic']) ||
                    reste_a_charge_unformatted_str,
            });
            insuranceList.splice(0, 0, initialTakingOutInsuranceList[0]);
            setTakingOutInsuranceList(insuranceList);
        };

        getAgentLccStatus();
        loadScaleList();
        loadInsuranceList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (currentScale !== undefined) {
            //console.log('useEffect.currentScale = ' + JSON.stringify(currentScale, null, 2));
            setPaymentFrequencyList(createDynamicList(currentScale.numberOfPaymentsMin, currentScale.numberOfPaymentsMax));
            setDefermentPeriodList(
                currentScale.paymentPostponements.map((pay) => {
                    return {
                        label: pay.toString(),
                        value: pay,
                    };
                })
            );
        }
    }, [currentScale]);

    // Current agent lcc status => boolean
    const lcc = lccStatus;

    // Total input
    const reste_a_charge_unformatted_number = parseFloat(context.ticket.montant_vente.replace(/\s/g, '').replace(',', '.'));
    const reste_a_charge_unformatted_str = reste_a_charge_unformatted_number.toFixed(2);

    const initialValues: FormValues = {
        // Partie 1
        paiementAuComptant: storageService.checkPropertyExistThenCreateOrRenderSimulator('paiementAuComptant', 'Paiement au comptant', PropertyType['boolean']),
        acompte: storageService.checkPropertyExistThenCreateOrRenderSimulator('acompte', 'Acompte', PropertyType['basic']),
        soldeAInstallation:
            storageService.checkPropertyExistThenCreateOrRenderSimulator('soldeAInstallation', "Solde à l'installation", PropertyType['basic']) ||
            reste_a_charge_unformatted_str,
        installationDesProduits: storageService.checkPropertyExistThenCreateOrRenderSimulator(
            'installationDesProduits',
            'Installation des produits',
            PropertyType['withLabel']
        ),

        // Partie 2
        paiementAvecFinancement: storageService.checkPropertyExistThenCreateOrRenderSimulator(
            'paiementAvecFinancement',
            'Paiement avec financement',
            PropertyType['boolean']
        ),
        choixDeOrganismeFinancier: storageService.checkPropertyExistThenCreateOrRenderSimulator(
            'choixDeOrganismeFinancier',
            "Choix de l'organisme financier",
            PropertyType['withLabel']
        ),
        scale: currentScale,
        commandAmount:
            storageService.checkPropertyExistThenCreateOrRenderSimulator('commandAmount', 'Montant de la commande', PropertyType['basic']) ||
            reste_a_charge_unformatted_str,
        takingOutInsurance: storageService.checkPropertyExistThenCreateOrRenderSimulator(
            'takingOutInsurance',
            'Souscrire une assurance',
            PropertyType['withLabel']
        ),
        birthDate:
            storageService.checkPropertyExistThenCreateOrRenderSimulator('birthDate', "Date de naissance de l'emprunteur", PropertyType['basic']) || null,
        deposit: storageService.checkPropertyExistThenCreateOrRenderSimulator('deposit', 'Acompte', PropertyType['basic']) || 0,
        paymentFrequency: storageService.checkPropertyExistThenCreateOrRenderSimulator(
            'paymentFrequency',
            'Nombre de mensualités (mois)',
            PropertyType['basic']
        ),
        defermentPeriod: storageService.checkPropertyExistThenCreateOrRenderSimulator(
            'defermentPeriod',
            'Période de différé (mois)',
            PropertyType['withLabel']
        ),
        simulation: storageService.checkPropertyExistThenCreateOrRenderSimulator('simulation', 'Simuler un emprunt', PropertyType['boolean']),
        loan: storageService.checkPropertyExistThenCreateOrRenderSimulator('loan', 'Votre simulation', PropertyType['loan']),
    };

    // construit la vue des pdf par onglet pour l'affichage. (dans une AlertSwal.fire(...))
    const buildModalContent = (pdfList: Array<api.PdfOutput>): SweetAlertOptions => {
        const pdfContent = bdc.buildModalPdfContentBase();
        pdfContent.html = <PdfModalContent tabs={pdfList} />;
        return pdfContent;
    };

    // construit la modal qui affiche un compte à rebours.
    const buildModalCountDown = (seconds: number): SweetAlertOptions => {
        const content = bdc.buildModalCountDownBase(seconds * 1000);
        content.html = <ModalCounter timer={seconds} />;
        return content;
    };

    const pdfModal = () => {
        // Extract pdfList
        const pdfList = bdc.getOutput(context.pdfState, PDF_NAMES_FOR_THIS_SCREEN);

        // si on a déjà des pdf, on les affiche
        if (pdfList.length !== 0) {
            AlertSwal.fire(buildModalContent(pdfList));
            return;
        }

        setLoader(false);
        setPdfLoader(true);

        const aborter = new AbortController();

        AlertSwal.fire(buildModalCountDown(api.DOWNLOAD_TIME_OUT)).then((value: SweetAlertResult<any>) => {
            bdc.aborterCallback(value, aborter, setPdfLoader);
        });

        // sinon on les télécharge.
        bdc.downloadPdf(aborter, clearPdfContext, initialisePdfContext, context)
            .then(() => {
                // Extract pdfList
                // same call but context has change because we juste downloaded the pdf.
                const pdfList = bdc.getOutput(context.pdfState, PDF_NAMES_FOR_THIS_SCREEN);
                setPdfLoader(false);
                AlertSwal.close();
                AlertSwal.fire(buildModalContent(pdfList));
            })
            .catch((err) => {
                setLoader(false);
                setPdfLoader(false);

                bdc.logBdcError(err);

                // Modal contenant le message d'erreur
                AlertSwal.fire(bdc.buildModalSimpleMessage(api.ERROR_INDISPONIBLE));
            });
    };

    const { push } = useHistory();

    const clearPdfContext = (): void => {
        //console.log('clearPdfContext');
        for (const docname of PDF_NAMES_FOR_THIS_SCREEN) {
            context.pdfState[docname] = {};
        }
    };

    useEffect(() => {
        api.getListe('organismefinancement').then((e) => {
            setChoixDeOrganismeFinancierList(e);
        });
    }, []);

    // initialise le context des pdf de cet écran.
    const initialisePdfContext = (): api.DocParameters[] => {
        const simdata = storageService.getSim();
        const result = Array<api.DocParameters>();
        // Il y a deux booleens :
        // simdata.paiementAvecFinancement et simdata.paiementAuComptant
        // ils ne sont pas en totale correlation, car ils peuvent être faux tous les deux.
        // ils ne peuvent aps être vrais tous les deux
        // j'élimine le cas emmerdant :
        if (simdata.paiementAvecFinancement?.value !== true && simdata.paiementAuComptant?.value !== true) {
            clearPdfContext();
            return result;
        }

        const avecfinancement: boolean = simdata.paiementAvecFinancement.value === true;
        const simulation: api.FinancoSimulation | undefined =
            avecfinancement && simdata.loan && simdata.loan.value ? (simdata.loan.value as api.FinancoSimulation) : undefined;

        // ATTENTION :
        // DANS l'ideal il faudrait boucler sur PDF_NAMES_FOR_THIS_SCREEN
        // Mais vu que les paramètres sont très différents les uns des autres, on le fait à la main.
        // En cas de changement, s'assurer que tous les PDF décris dans PDF_NAMES_FOR_THIS_SCREEN sont couverts.
        const type_financement = avecfinancement && simulation ? '1' : '3';
        const montant_acompte = avecfinancement && simulation ? simdata.deposit.value : simdata.acompte.value;
        const montant_solde = (reste_a_charge_unformatted_number - +montant_acompte).toFixed(2);
        const montant_credit_total = avecfinancement && simulation ? simulation.loanAmount.toString() : '';
        const montant_credit_plus_interets = avecfinancement && simulation ? simulation.totalAmountInsuranceIncluded.toString() : '';
        const taux_deb_fixe = avecfinancement && simulation ? simulation.fixedBorrowingRate.toString() : '';
        const taeg_credit = avecfinancement && simulation ? simulation.annualPercentageRate.toString() : '';
        const report_credit = avecfinancement && simulation ? '' + simulation.paymentPostponement : '';
        const nb_echeances = avecfinancement && simulation ? simulation.numberOfPayments.toString() : '';
        const mt_echeances = avecfinancement && simulation ? simulation.monthlyPaymentInsuranceIncluded.toString() : '';

        const input: api.DocParameters<'financement_sogys'> = {
            docName: 'financement_sogys',
            parameters: {
                montant_vente: context.ticket.montant_vente,
                montant_vente_unformatted: reste_a_charge_unformatted_str,
                type_financement,
                montant_acompte,
                montant_solde,
                organisme_financier: simdata.choixDeOrganismeFinancier.value,
                montant_credit_total,
                montant_credit_plus_interets,
                report_credit,
                taux_deb_fixe,
                taeg_credit,
                nb_echeances,
                mt_echeances,
            },
        };

        result.push(input);
        context.pdfState['financement_sogys'] = { input, output: undefined };
        return result;
    };

    /**
     * détermine si les PDF nécessaires de cet écran sont correctement initialisés!
     * La logique sera différente sur chaque écran !!!
     * On considère qu'un pdf initialisé lorsque
     *  - il est nécessaire
     *  - son input est correctement définie (!undefined)
     * @returns true si tout est initialisé correctement, false sinon.
     */
    const arePdfInitialized = (): boolean => {
        for (const docname of PDF_NAMES_FOR_THIS_SCREEN) {
            if (context.pdfState[docname].input === undefined) {
                return false;
            }
        }
        return true;
    };

    arePdfInitialized();

    const backBtnClick = (): void => {
        //  on navigue en arrière
        // si la maison est ancienne => ROUTE_SIM_TVA
        // sinon, si un panneau solaire a été choisi : Rendement
        // sinon Annexe.
        const houseIsOld = storageService.getAudit().step2.buildingAge.value;
        const fulltva = hasFullTVA(context.themes);
        const shouldShowTva = houseIsOld && !fulltva;

        const dest = shouldShowTva ? ROUTE_SIM_TVA : getFirstPanneau(context.themes) !== undefined ? ROUTE_SIM_RENDEMENT : ROUTE_SIM_ANNEXE;

        push(dest, context);
    };

    const postCreateFinancoStudy = async (): Promise<api.FinancoStudyStatus> => {
        try {
            const loan = storageService.checkPropertyExistThenCreateOrRenderSimulator('loan', 'Votre simulation', PropertyType['loan']);
            const auditId = +localStorage.getItem('auditId')!;
            const devisId = +localStorage.getItem('devisId')!;
            const step1 = storageService.getAudit()?.step1!;
            const simulation = storageService.getSim()!;
            const studyInput: api.FinancoContextStudyInput = {
                loan,
                auditId,
                devisId,
                email: step1.userEmail?.value,
                borrower: {
                    identity: {
                        birthDate: simulation.birthDate.value, // format yyyy-MM-dd as string
                        firstName: step1.userFirstname?.value,
                        lastName: step1.userLastname?.value,
                        titleCode: step1.userCivility?.value === 'Mr' ? '1' : '2',
                        birthName: step1.userCivility?.value === 'Mr' ? undefined : step1.userLastname?.value,
                    },
                    contactDetails: {
                        cellPhone: step1.userTelephone?.value,
                        email: step1.userEmail?.value,
                        addressLine1: step1.userAddress?.value,
                        city: step1.userLocality?.value,
                        zipCode: step1.userZipCode?.value,
                    },
                },
                capacity: 'PERSONAL',
            };
            //console.log('input = ' + JSON.stringify(studyInput, null, 2));

            const study = await api.postCreateFinancoStudy(studyInput);
            const fundingInput: api.FlowFunding = {
                type: 'Loan',
                url: study.url,
                data: { ...study },
                organism: 'Financo',
                state: study.studyInformation.statusLabel,
            };
            // update flow funding
            await api.updateFundingData(flowId, fundingInput);

            // Pour les tests : Forcer le status à la main.
            // study.studyInformation.statusLabel = 'DEFAVORABLE';

            if (study.studyInformation.statusLabel === 'DEFAVORABLE') {
                console.log('simulation défavorable ! ');
                setSimulationDefavorable(true); // Ne pourra plus bouger
                storageService.setSimValue('paiementAuComptant', true);
                storageService.setSimValue('paiementAvecFinancement', false);
                storageService.setSimValue('loan', null);
                storageService.setSimValue('simulation', false);
            }

            return study;
        } catch (e) {
            console.error('Financo study fail to be sent: ', e);
            throw e;
        }
    };

    const validateAndNavigate = async (): Promise<void> => {
        setLoader(true);
        setPdfLoader(false);

        try {
            // si on n'a pas initialisé le(s) pdf(s), on le fait
            if (!arePdfInitialized()) {
                initialisePdfContext();
            }
            // for (const docname of PDF_NAMES_FOR_THIS_SCREEN) {
            //     console.log('Continuer ecran financement_sogys, context = ' + JSON.stringify(context.pdfState[docname], null, 2));
            // }
            // puis on navigue.

            // on créé maintenant le financement chez financo si nécessaire.
            const paiementAvecFinancement: boolean = storageService.checkPropertyExistThenCreateOrRenderSimulator(
                'paiementAvecFinancement',
                'Paiement avec financement',
                PropertyType['boolean']
            );
            if (!paiementAvecFinancement) {
                // update flow funding
                await api.updateFundingData(flowId, { type: 'Cash' });

                setLoader(false);
                push(ROUTE_SIM_ENGAGEMENT_CLIENT, context);
                return;
            }

            const study = await postCreateFinancoStudy();

            // l'étude financière n'est pas possible côté financo
            if (!study.id || study.id === '' || study.studyInformation.statusLabel === 'DEFAVORABLE') {
                // Modal contenant le message d'erreur
                AlertSwal.fire({
                    title: 'Dossier de financement refusé',
                    icon: 'error',
                    html: (
                        <>
                            <p>Le financement avec notre partenaire Financo n’est pas accessible.</p>
                            <p>Nous vous recommandons de vous rapprocher de votre banque afin de financer votre projet</p>
                        </>
                    ),
                    width: 600,
                    confirmButtonText: 'Fermer',
                    customClass: {
                        confirmButton: 'btn btn-continue min-width',
                    },
                });

                setLoader(false);
                return;
            }
        } catch (err) {
            console.log(err);

            // même si erreur côté financo à la création de l'étude, on ne traite pas pour ne pas bloquer l'utilisateur
            // // Modal contenant le message d'erreur
            // AlertSwal.fire({
            // 	title: 'Erreur',
            // 	icon: 'error',
            // 	html: <p>{api.ERROR_INDISPONIBLE}</p>,
            // 	width: 600,
            // 	confirmButtonText: 'Fermer',
            // 	customClass: {
            // 		confirmButton: 'btn btn-continue min-width',
            // 	},
            // });
        } finally {
            setLoader(false);
            push(ROUTE_SIM_ENGAGEMENT_CLIENT, context);
        }
    };

    const disableLoan = (values: FormValues) => {
        if (values.choixDeOrganismeFinancier && values.deposit && values.paymentFrequency && values.birthDate) {
            return false;
        }
        return true;
    };

    const today: Date = new Date();
    const formattedStartDate = format(today, 'dd/MM/yyyy', { locale: frLocale });
    const endDate = addDays(today, 30);
    const formattedEndDate = format(endDate, 'dd/MM/yyyy', { locale: frLocale });

    const nocticeType: NoticeProps = 'financement';
    const sendAwaitedPhotos = async () => {
        try {
            await postJsonData(`flow/${flowId}/send-notice/${nocticeType}`);
            AlertSwal.fire({
                title: '',
                html: <p>L'email Notice de la liste des documents financo a bien été envoyé.</p>,
                confirmButtonText: 'Fermer',
                customClass: {
                    confirmButton: 'btn btn-primary min-width',
                },
            });
        } catch (error) {
            console.error(error);
            AlertSwal.fire({
                title: 'Erreur',
                html: <p>Une erreur est survenue lors de l'envoi de votre mail.</p>,
                confirmButtonText: 'Fermer',
                customClass: {
                    confirmButton: 'btn btn-primary min-width',
                },
            });
        }
    };

    return (
        <Formik validateOnMount={true} initialValues={initialValues} validationSchema={validationSchema} onSubmit={() => {}} enableReinitialize>
            {({ values, errors, setValues }) => {
                // Debugg
                // console.log('Values:', values);
                // console.log('Errors:', errors);
                return (
                    <div className="container container-audit">
                        <h1 className="main-title-mini">Financement</h1>

                        <div className="card card-audit-simulator financement mb-5">
                            <div className="card-header">
                                <IconFinancement />
                                <h2>Gérer votre plan de financement</h2>
                            </div>

                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 mb-4">
                                        <div className={!lccStatus ? 'd-none row' : 'row'}>
                                            <div className="col-12 col-md-4">
                                                <div className="form-group contract-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        onChange={() => {
                                                            clearPdfContext();
                                                            setValues({
                                                                ...values,
                                                                paiementAuComptant: !values.paiementAuComptant,
                                                                paiementAvecFinancement: false,
                                                            });
                                                            storageService.setSimValue('paiementAuComptant', !values.paiementAuComptant);
                                                            storageService.setSimValue('paiementAvecFinancement', false);

                                                            // Remove values from `Paiement avec financement` from LocalStorage & reset fields
                                                            if (!values.paiementAuComptant || values.paiementAuComptant) {
                                                                storageService.removeSimValue('acompte');
                                                                storageService.removeSimValue('installationDesProduits', true);

                                                                storageService.removeSimValue('choixDeOrganismeFinancier', true);
                                                                storageService.removeSimValue('deposit');
                                                                storageService.removeSimValue('paymentFrequency', true);
                                                                storageService.setSimValue('simulation', false);
                                                                storageService.setSimValue('loan', null);
                                                                setValues({
                                                                    ...values,
                                                                    paiementAuComptant: !values.paiementAuComptant,
                                                                    paiementAvecFinancement: false,

                                                                    acompte: '',
                                                                    soldeAInstallation: reste_a_charge_unformatted_str,
                                                                    installationDesProduits: '',

                                                                    choixDeOrganismeFinancier: '',
                                                                    deposit: '0',
                                                                    paymentFrequency: '',
                                                                    simulation: false,
                                                                    loan: null,
                                                                });
                                                            }
                                                        }}
                                                        id="paiementAuComptant"
                                                        name="paiementAuComptant"
                                                        checked={values.paiementAuComptant}
                                                    />
                                                    <label htmlFor="paiementAuComptant" className="label-to-p text-uppercase">
                                                        Paiement au comptant
                                                    </label>

                                                    <div className="fake-box"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4 mb-4">
                                        <div className="form-group">
                                            <label htmlFor="acompte">Acompte{values.paiementAuComptant && <sup>*</sup>}</label>
                                            <div className="input-group">
                                                <input
                                                    type="number"
                                                    name="acompte"
                                                    id="acompte"
                                                    disabled={!values.paiementAuComptant}
                                                    value={values.acompte || ''}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        clearPdfContext();
                                                        let newSolde = (Number(reste_a_charge_unformatted_str) - Number(event.target.value))
                                                            .toFixed(2)
                                                            .toString();

                                                        if (Number(event.target.value) >= Number(reste_a_charge_unformatted_str)) {
                                                            newSolde = '0';
                                                        }

                                                        setValues({
                                                            ...values,
                                                            acompte: event.target.value,
                                                            soldeAInstallation: newSolde,
                                                        });

                                                        storageService.setSimValue('acompte', event.target.value);
                                                        storageService.setSimValue('soldeAInstallation', newSolde);
                                                    }}
                                                    max={reste_a_charge_unformatted_str}
                                                    className="form-control"
                                                />
                                                <span className="input-group-text">€</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4 mb-4">
                                        <div className="form-group">
                                            <label htmlFor="soldeAInstallation">Solde à l'installation{values.paiementAuComptant && <sup>*</sup>}</label>
                                            <div className="input-group">
                                                <input
                                                    type="number"
                                                    name="soldeAInstallation"
                                                    id="soldeAInstallation"
                                                    disabled={true}
                                                    value={values.soldeAInstallation || reste_a_charge_unformatted_str}
                                                    className="form-control"
                                                />
                                                <span className="input-group-text">€</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4 mb-4">
                                        <div className="form-group">
                                            <label htmlFor="installationDesProduits">
                                                Installation des produits{values.paiementAuComptant && <sup>*</sup>}
                                            </label>
                                            <Select
                                                value={renderDefaultValueSelect(installationDesProduitsList, values.installationDesProduits)}
                                                isClearable={false}
                                                isMulti={false}
                                                isSearchable={false}
                                                id="installationDesProduits"
                                                options={installationDesProduitsList}
                                                placeholder={`Choisissez le moment de l'installation`}
                                                styles={values.paiementAuComptant ? panelStyles : panelDisabled}
                                                isDisabled={!values.paiementAuComptant}
                                                onChange={(event: any) => {
                                                    clearPdfContext();
                                                    setValues({
                                                        ...values,
                                                        installationDesProduits: event.value,
                                                    });

                                                    storageService.setSimValue('installationDesProduits', event.value, event.label);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={!lccStatus ? 'd-none' : 'd-block'}>
                                    <hr className="separator" />

                                    <div className="row pt-4">
                                        <div className="col-12 mb-4">
                                            <div className="row">
                                                <div className="col-12 col-md-4">
                                                    <div className="form-group contract-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            onChange={() => {
                                                                clearPdfContext();
                                                                setValues({
                                                                    ...values,
                                                                    paiementAvecFinancement: !values.paiementAvecFinancement,
                                                                    paiementAuComptant: false,
                                                                });

                                                                storageService.setSimValue('paiementAvecFinancement', !values.paiementAvecFinancement);
                                                                storageService.setSimValue('paiementAuComptant', false);

                                                                // Remove values from `Paiement au comptant` from LocalStorage & reset fields
                                                                if (!values.paiementAvecFinancement || values.paiementAvecFinancement) {
                                                                    storageService.removeSimValue('choixDeOrganismeFinancier', true);
                                                                    storageService.removeSimValue('deposit');
                                                                    storageService.removeSimValue('paymentFrequency', true);
                                                                    storageService.setSimValue('simulation', false);
                                                                    storageService.setSimValue('loan', null);

                                                                    setValues({
                                                                        ...values,
                                                                        paiementAvecFinancement: !values.paiementAvecFinancement,
                                                                        paiementAuComptant: false,

                                                                        acompte: '',
                                                                        soldeAInstallation: reste_a_charge_unformatted_str,
                                                                        installationDesProduits: '',

                                                                        choixDeOrganismeFinancier: '',
                                                                        deposit: '0',
                                                                        paymentFrequency: '',
                                                                        simulation: false,
                                                                        loan: null,
                                                                    });
                                                                }

                                                                if (!values.paiementAvecFinancement && choixDeOrganismeFinancierList.length === 1) {
                                                                    setValues({
                                                                        ...values,
                                                                        paiementAvecFinancement: !values.paiementAvecFinancement,
                                                                        paiementAuComptant: false,
                                                                        choixDeOrganismeFinancier: choixDeOrganismeFinancierList[0].value,
                                                                    });

                                                                    storageService.setSimValue(
                                                                        'choixDeOrganismeFinancier',
                                                                        choixDeOrganismeFinancierList[0].value,
                                                                        choixDeOrganismeFinancierList[0].label
                                                                    );
                                                                }
                                                            }}
                                                            id="paiementAvecFinancement"
                                                            name="paiementAvecFinancement"
                                                            checked={values.paiementAvecFinancement}
                                                            disabled={!lcc || simulationDefavorable}
                                                        />
                                                        <label htmlFor="paiementAvecFinancement" className="label-to-p text-uppercase">
                                                            Paiement avec financement
                                                        </label>

                                                        <div className="fake-box"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 mb-2">
                                            <h3 className="financial-title">Simulez le financement de votre projet de rénovation énergétique</h3>
                                            <p>Un crédit vous engage et doit être remboursé. Vérifiez vos capacités de remboursement avant de vous engager.</p>
                                        </div>

                                        <div className="row mb-4">
                                            <div className="col-12 col-md-4">
                                                <label htmlFor="choixDeOrganismeFinancier">
                                                    Choix de l'organisme financier{values.paiementAvecFinancement && <sup>*</sup>}
                                                </label>
                                                <Select
                                                    value={renderDefaultValueSelect(choixDeOrganismeFinancierList, values.choixDeOrganismeFinancier)}
                                                    options={choixDeOrganismeFinancierList}
                                                    isSearchable={false}
                                                    isClearable={false}
                                                    isMulti={false}
                                                    placeholder={`Choisissez l'organisme financier`}
                                                    isDisabled={!values.paiementAvecFinancement}
                                                    onChange={(event: any) => {
                                                        clearPdfContext();
                                                        setValues({
                                                            ...values,
                                                            choixDeOrganismeFinancier: event.value,
                                                        });

                                                        storageService.setSimValue('choixDeOrganismeFinancier', event.value, event.label);
                                                    }}
                                                    id="choixDeOrganismeFinancier"
                                                    styles={values.paiementAvecFinancement ? panelStyles : panelDisabled}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-4 mb-4 mb-md-0">
                                            <label htmlFor="commandAmount">Montant de la commande</label>
                                            <div className="input-group">
                                                <Field
                                                    type="number"
                                                    className="form-control"
                                                    id="commandAmount"
                                                    name="commandAmount"
                                                    value={values.commandAmount}
                                                    required
                                                    disabled
                                                />
                                                <span className="input-group-text">€</span>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-4">
                                            <Dates values={values} setValues={setValues} />
                                        </div>

                                        <div className="col-12 col-md-4">
                                            <label htmlFor="takingOutInsurance">
                                                Souscrire une assurance<sup>*</sup>
                                            </label>
                                            <Select
                                                value={renderDefaultValueSelect(takingOutInsuranceList, values.takingOutInsurance)}
                                                options={takingOutInsuranceList}
                                                isSearchable={false}
                                                isMulti={false}
                                                isClearable={false}
                                                onChange={(event: Labelled<string | undefined> | null) => {
                                                    if (!event) return;

                                                    setValues({
                                                        ...values,
                                                        takingOutInsurance: event.value,
                                                    });

                                                    storageService.setSimValue('takingOutInsurance', event.value, event.label);
                                                }}
                                                name="takingOutInsurance"
                                                id="takingOutInsurance"
                                                placeholder="Oui"
                                                styles={values.paiementAvecFinancement ? panelStyles : panelDisabled}
                                            />
                                        </div>
                                    </div>

                                    <div className="row pt-4">
                                        <div className="col-12 col-md-4 mb-4">
                                            <label htmlFor="deposit">
                                                Acompte<sup>*</sup>
                                            </label>
                                            <div className="input-group">
                                                <Field
                                                    type="number"
                                                    className="form-control"
                                                    name="deposit"
                                                    id="deposit"
                                                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => checkMinMaxAndSet(e, values, setValues)}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        clearPdfContext();
                                                        setValues({
                                                            ...values,
                                                            deposit: e.target.value,
                                                        });
                                                    }}
                                                    required
                                                    value={values.deposit}
                                                    step={0.01}
                                                    min={0}
                                                    max={reste_a_charge_unformatted_number}
                                                    disabled={!values.paiementAvecFinancement}
                                                />
                                                <span className="input-group-text">€</span>
                                            </div>
                                            <span className="field-explanation">
                                                Min: <strong>{0}</strong> - Max: <strong>{reste_a_charge_unformatted_number}</strong>
                                            </span>
                                        </div>

                                        <div className="col-12 col-md-4">
                                            <label htmlFor="paymentFrequency">
                                                Nombre de mensualités (mois)<sup>*</sup>
                                            </label>
                                            <Select
                                                value={renderDefaultValueSelect(paymentFrequencyList, values.paymentFrequency)}
                                                options={paymentFrequencyList}
                                                id="paymentFrequency"
                                                name="paymentFrequency"
                                                onChange={(e: LabelledString | null) => {
                                                    if (!e) return;

                                                    clearPdfContext();
                                                    setValues({
                                                        ...values,
                                                        paymentFrequency: e.value,
                                                    });

                                                    storageService.setSimValue('paymentFrequency', e.value);
                                                }}
                                                isSearchable={false}
                                                isDisabled={!values.paiementAvecFinancement}
                                                placeholder="Nombre de mensualités (mois)"
                                                styles={values.paiementAvecFinancement ? panelStyles : panelDisabled}
                                            />
                                        </div>

                                        <div className="col-12 col-md-4">
                                            <label htmlFor="defermentPeriod">
                                                Période de différé (mois)<sup>*</sup>
                                            </label>
                                            <Select
                                                defaultValue={renderDefaultValueSelect(defermentPeriodList, values.defermentPeriod)}
                                                options={defermentPeriodList}
                                                id="defermentPeriod"
                                                name="defermentPeriod"
                                                isDisabled={defermentPeriodList.length === 1}
                                                isSearchable={false}
                                                placeholder="Période de différé (mois)"
                                                styles={defermentPeriodList.length === 1 ? panelDisabled : panelStyles}
                                            />
                                        </div>
                                    </div>

                                    <div className="row justify-content-end pt-4">
                                        <div className="col-12 col-md-4 text-end">
                                            <button
                                                type="button"
                                                className="btn btn-continue"
                                                disabled={disableLoan(values) || simulationLoader}
                                                onClick={() => {
                                                    clearPdfContext();
                                                    simulate(
                                                        {
                                                            loan: {
                                                                scaleId: values.scale.id,
                                                                depositAmount: values.deposit,
                                                                purchaseAmount: reste_a_charge_unformatted_str,
                                                                numberOfPayments: values.paymentFrequency!,
                                                                paymentPostponement: Number(values.defermentPeriod)!,
                                                                borrowerInsuranceId: values.takingOutInsurance,
                                                            },
                                                        },
                                                        setValues,
                                                        values
                                                    );
                                                }}
                                            >
                                                {simulationLoader && <Loader />}
                                                <span style={{ opacity: simulationLoader ? 0 : 1 }}>
                                                    {values.simulation ? <>Relancer une simulation</> : <>Simuler mon emprunt</>}
                                                </span>
                                            </button>
                                        </div>
                                    </div>

                                    <hr />

                                    <div className="row pt-2">
                                        <FinancialList list={values.loan ?? null} />

                                        <div className="btn-grp justify-content-end">
                                            <button
                                                type="button"
                                                className="btn btn-secondary d-inline-block"
                                                disabled={!values.paiementAvecFinancement}
                                                onClick={sendAwaitedPhotos}
                                            >
                                                Envoyer la liste des documents attendus
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="btn-grp justify-content-end">
                            <div className="btn btn-retour" onClick={backBtnClick}>
                                Retour
                            </div>
                            <div className={`${Object.entries(errors).length > 0 || loader || pdfLoader ? 'not-allowed' : 'allowed'}`}>
                                <button
                                    type="button"
                                    className={`btn btn-bdc-modal${Object.entries(errors).length > 0 ? ' disabled' : ''}`}
                                    onClick={pdfModal}
                                    disabled={(simulationDefavorable && values.paiementAvecFinancement) || loader || pdfLoader}
                                >
                                    {pdfLoader && <Loader />}
                                    <span style={{ opacity: pdfLoader ? '0' : '1' }}>Visualiser le document</span>
                                </button>
                            </div>

                            <div className={`${Object.entries(errors).length > 0 || loader || pdfLoader ? 'not-allowed' : 'allowed'}`}>
                                <button
                                    className={`btn btn-continue ${Object.entries(errors).length > 0 ? 'disabled' : ''}`}
                                    onClick={validateAndNavigate}
                                    disabled={(simulationDefavorable && values.paiementAvecFinancement) || loader || pdfLoader}
                                >
                                    {loader && <Loader />}
                                    <span style={{ opacity: loader ? '0' : '1' }}>Continuer</span>
                                </button>
                            </div>
                        </div>

                        {values.loan && (
                            <>
                                <p className="cgu-pave mt-5">
                                    * Exemple de financement (hors assurance facultative) pour l'achat d'un crédit lié à une vente d’un montant total de{' '}
                                    {values.loan.purchaseAmount} €, avec un apport de {values.loan.depositAmount} €. Montant total du crédit :{' '}
                                    {values.loan.loanAmount} €, remboursable en {values.loan.numberOfPayments}{' '}
                                    <strong>mensualités de {values.loan.monthlyPaymentInsuranceNotIncluded} €</strong> (hors assurance facultative).
                                    <strong>Taux Annuel Effectif Global (TAEG) fixe : {values.loan.annualPercentageRate}%</strong> hors assurance facultative.
                                    Taux débiteur fixe : {values.loan.fixedBorrowingRate}%. Perceptions forfaitaires : {values.loan.fees} €. Coût total du
                                    crédit : {values.loan.totalAmountInsuranceIncluded} €.
                                    <br />
                                    <strong>Montant total dû par l’emprunteur : {values.loan.totalAmountInsuranceNotIncluded ?? 0} €</strong>. 1ère échéance
                                    à&nbsp;
                                    {values.loan.paymentPostponement > 1
                                        ? values.loan.paymentPostponement + ' jours'
                                        : values.loan.paymentPostponement + ' jour'}
                                    . Durée effective du crédit : {values.loan.contractPeriod} mois. Vous disposez d’un droit de rétractation. Taux Annuel
                                    Effectif de l’Assurance (TAEA) pour un assuré (hors surprimes éventuelles) couvert à 100% sur la base de la couverture
                                    CONFORT (incluant les garanties Décès, Perte Totale et Irréversible d’Autonomie et Incapacité Temporaire Totale de travail)
                                    du contrat « Mon Assurance de personnes » n°5035 : {values.loan.insuranceAnnualPercentageRate}% soit un coût mensuel de
                                    l’assurance de {values.loan.borrowerInsurance?.insuranceMonthlyAmount || '0'} € en sus de la mensualité indiquée plus haut
                                    et inclus dans l’échéance de remboursement. Le coût total de l’assurance sur toute la durée du prêt s’élève à{' '}
                                    {values.loan.borrowerInsurance?.insuranceTotalAmount || '0'} €. Contrat d’assurance facultative de groupe des emprunteurs «
                                    Mon Assurance de personnes » n°5035 (Décès, Perte Totale et Irréversible d’Autonomie, Incapacité Temporaire Totale de
                                    travail, Perte d’Emploi) souscrit par FINANCO auprès des sociétés SURAVENIR et SURAVENIR ASSURANCES, entreprises régies par
                                    le Code des assurances. Offre réservée à des crédits d’un montant minimum de {currentScale.creditAmountMin} € et maximum de{' '}
                                    {currentScale.creditAmountMax} € et dont la durée de remboursement est de {values.loan.contractPeriod} mois. Le TAEG fixe
                                    est de {values.loan.annualPercentageRate}%. Offre valable du {formattedStartDate} au {formattedEndDate}. Sous réserve
                                    d’acceptation par FINANCO – Siège social : 335 rue Antoine de Saint-Exupéry - 29490 GUIPAVAS. SA à Directoire et Conseil de
                                    surveillance au capital de 58 000 000 Euros - RCS BREST B 338 138 795. Société de courtage d’assurances, n°Orias 07 019 193
                                    (vérifiable sur www.orias.fr).”
                                </p>

                                <p className="cgu-pave">
                                    Cette publicité est conçue et diffusée par Sogys (SASU au capital de 360 000€ - RCS Paris 900 198 912 - 76 rue de la Pompe -
                                    75116 Paris) qui n’est pas intermédiaire en opérations de banque et service de paiement. Les points de vente apportent leurs
                                    concours à la réalisation d’opérations de crédit à la consommation en leur qualité d’intermédiaires de crédit non exclusifs
                                    de FINANCO et sans agir en qualité de Prêteur. Ces intermédiaires de crédit peuvent également être soumis au statut
                                    d’Intermédiaires en Opérations de Banque et Service de Paiement (IOBSP) dans ce cas leurs numéros d’immatriculation à
                                    l’ORIAS (consultables sur www.orias.fr) sont achetés à l’accueil.
                                    <br />
                                    <br />
                                    Document publicitaire à valeur non contractuelle.
                                </p>
                            </>
                        )}
                    </div>
                );
            }}
        </Formik>
    );
};
export default Financement;
