import * as Yup from 'yup';

const schemaInsulation = () => {
  return Yup.object().shape({
    color: Yup.object()
      .test('is-valid-color', '', (value) => {
        if (value && typeof value === 'object' && value.label && value.value) {
          return true;
        }

        return false;
      })
      .required(),
    opacity: Yup.boolean().notRequired()
  });
};

export default schemaInsulation;
