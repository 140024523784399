/**
 * Les travaux sont réalisés dans :
 * 0 - Un local affecté exclusivement ou principalement à l’habitation
 * 1 - Des pièces affectées exclusivement à l'habitation situées dans un local affecté pour moins de 50 % à cet usage
 * 2 - Des parties communes de locaux affectés exclusivement ou principalement à l'habitation
 * 3 - Un local antérieurement affecté à un usage autre que d’habitation et transformé à cet usage
 */
export const tvaWorkList: { label: string; value: string }[] = [
    {
        label: 'Un local affecté exclusivement ou principalement à l’habitation',
        value: '0',
    },
    {
        label: "Des pièces affectées exclusivement à l'habitation situées dans un local affecté pour moins de 50 % à cet usage",
        value: '1',
    },
    {
        label: "Des parties communes de locaux affectés exclusivement ou principalement à l'habitation",
        value: '2',
    },
    {
        label: 'Un local antérieurement affecté à un usage autre que d’habitation et transformé à cet usage',
        value: '3',
    },
];

type TworkTypeList = {
    name: string;
    label: string;
    id: string;
};

export const workTypeList: TworkTypeList[] = [
    {
        id: '0',
        name: "Planchers qui ne déterminent pas la résistance ou la rigidité de l'ouvrage",
        label: "Planchers qui ne déterminent pas la résistance ou la rigidité de l'ouvrage",
    },
    {
        id: '1',
        name: 'Huisseries extérieures',
        label: 'Huisseries extérieures',
    },
    {
        id: '2',
        name: 'Cloisons intérieures',
        label: 'Cloisons intérieures',
    },
    {
        id: '3',
        name: 'Installation sanitaire et de plomberies',
        label: 'Installation sanitaire et de plomberies',
    },
    {
        id: '4',
        name: 'Installations électriques',
        label: 'Installations électriques',
    },
    {
        id: '5',
        name: 'Système de chauffage (pour les immeubles situés en métropole)',
        label: 'Système de chauffage (pour les immeubles situés en métropole)',
    },
];
