import React from 'react';

// Icon
import { ReactComponent as IconClose } from '../../../assets/icons/icon-close.svg';

type Modal = {
    modal: any;
};

const Modals: React.FC<Modal> = ({ modal }) => {
    const modalVideo = () => {
        modal.fire({
            customClass: {
                htmlContainer: 'modal-video',
                closeButton: 'modal-close',
            },
            html: (
                <div className="video-container">
                    <iframe
                        width="100%"
                        height="100%"
                        src="https://www.youtube-nocookie.com/embed/-eVQmLRfc0Y?autoplay=1"
                        title="Sogys énergie"
                        style={{ border: '0' }}
                        allow="autoplay;"
                    ></iframe>
                </div>
            ),
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: true,
            showCloseButton: true,
            closeButtonHtml: <IconClose width="34px" height="34px" />,
            padding: 0,
            width: '70vw',
        });
    };

    const modalPdf = () => {
        modal.fire({
            customClass: {
                htmlContainer: 'modal-pdf',
                closeButton: 'modal-close',
            },
            html: (
                <div className="document-container">
                    <iframe
                        width="100%"
                        height="100%"
                        src="https://dev.sabban.fr/brochure-sogys-energie/"
                        title="Book pratique"
                        style={{ border: '0' }}
                    ></iframe>
                </div>
            ),
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: true,
            showCloseButton: true,
            closeButtonHtml: <IconClose width="34px" height="34px" />,
            padding: 0,
            width: '90vw',
        });
    };

    return (
        <div className="modals">
            <button type="button" className="btn btn-header borderless icon-video" onClick={modalVideo}>
                <span>Présentation vidéo</span>
            </button>

            <button type="button" className="btn btn-header borderless icon-document" onClick={modalPdf}>
                <span>Book pratique</span>
            </button>
        </div>
    );
};

export default Modals;
