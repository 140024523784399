import React from 'react';
import { PolymorphicComponentProps } from '../../services/componentsHelpers/polymorpgic';

type Props = {
    children: React.ReactNode;
    color?: string;
    size?: number;
};

// `TextProps` now uses `PolymorphicComponentProps` to add the `as` prop and inherit its prop
type TextProps<C extends React.ElementType> = PolymorphicComponentProps<C, Props>;

export const TextElement = <C extends React.ElementType = 'span'>({ as, children, ...other }: TextProps<C>) => {
    const Component = as || 'span';

    return <Component {...other}>{children}</Component>;
};
