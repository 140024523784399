import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AgentCards } from './EspaceAgentEnums';
import HeaderBanner from '../../components/headerBanner/HeaderBanner';
import ReactDatePicker from 'react-datepicker';
import fr from 'date-fns/locale/fr';

// Icons
import { ReactComponent as IconStudies } from '../../assets/icons/espace-agent/icon-etudes.svg';
import { ReactComponent as IconAccount } from '../../assets/icons/espace-agent/icon-account.svg';
import { ReactComponent as IconMarketing } from '../../assets/icons/espace-agent/icon-marketing.svg';
import { ReactComponent as IconInformation } from '../../assets/icons/espace-agent/icon-information.svg';
import { ReactComponent as IconNetwork } from '../../assets/icons/espace-agent/icon-network.svg';

// Style
import './EspaceAgent.scss';

export type IconProps = {
    width: number;
    height: number;
    fill: string;
};

const EspaceAgent: React.FC = () => {
    const [date, setDate] = useState<Date>(new Date());

    React.useEffect(() => {
        document.title = 'SOGYS.APP - Espace agent';
    }, []);

    const iconsDefault: IconProps = {
        width: 50,
        height: 50,
        fill: '#82786b',
    };

    const displayIcon = (icon: string) => {
        switch (icon) {
            case 'icon-study':
                return <IconStudies {...iconsDefault} />;

            case 'icon-account':
                return <IconAccount {...iconsDefault} />;

            case 'icon-marketing':
                return <IconMarketing {...iconsDefault} />;

            case 'icon-information':
                return <IconInformation {...iconsDefault} />;

            case 'icon-network':
                return <IconNetwork width="70" fill="#82786b" />;

            default:
                return '';
        }
    };

    const redirectToGoogleAgenda = () => {
        const heightPercentage = 50;

        const width = 1100;
        const height = (window.screen.height * heightPercentage) / 100;

        const left = (window.screen.width - width) / 2;
        const top = (window.screen.height - height) / 2;

        window.open(
            'https://calendar.google.com',
            'newwindow',
            `width=${width},height=${height},top=${top},left=${left},toolbar=no,menubar=no,location=no,directories=no,status=no`
        );
    };

    return (
        <>
            <HeaderBanner />

            <div className="container">
                <div className="agent-grid">
                    {AgentCards.map((card, index) => (
                        <div key={index} data-disabled={card.disabled} aria-hidden={card.disabled}>
                            <Link to={card.url} className="agent-card">
                                <div className="agent-card--title">{card.title}</div>
                                <div className="agent-card--body" aria-hidden="true">
                                    <div className="icon">{displayIcon(card.icon)}</div>
                                </div>
                            </Link>
                        </div>
                    ))}
                    {/* Always last element */}
                    <div className="agent-calendar">
                        <div className="agent-calendar--link" onClick={redirectToGoogleAgenda}>
                            <ReactDatePicker locale={fr} startDate={date} onChange={(e: Date) => setDate(e)} inline />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EspaceAgent;
