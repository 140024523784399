/** Get code Insee of a city, from zipCode and city name */
export const getCodeInsee = async (zipCode: string, city: string): Promise<string | undefined> => {
    try {
        const apiUrl = `https://api-adresse.data.gouv.fr/search/?q=${zipCode}+${city}&type=municipality`;

        const response = await fetch(apiUrl);
        if (!response.ok) {
            throw new Error('Error fetching data');
        }

        const data = await response.json();
        if (data.features.length > 0) {
            return data.features[0].properties.citycode;
        } else {
            return undefined;
        }
    } catch (error) {
        console.error('An error occurred:', error);
        return undefined;
    }
};
