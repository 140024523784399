export const authorized = (
  values: any,
  isValid: boolean,
  setValues: any,
  action: boolean
) => {

  setValues({
    ...values,
    authorized: action
  });


  if (values.authorized !== '') {
    setValues({
        ...values,
        authorized: action
    });
  }
};
