export const generateArrayOfYears = (minYear?: number) => {
    const max = new Date().getFullYear();
    // If minYear is set : use minYear otherwise use `1890`
    let min = minYear ? minYear : 1890;
    let years = [];

    for (let i = max; i >= min; i--) {
        years.push({ label: i.toString(), value: i.toString() });
    }

    // If a minYear is set, this message won't display
    if (!minYear) {
        years.push({ label: 'Avant 1890', value: 'Avant 1890' });
    }

    return years;
};
