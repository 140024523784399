import React from 'react';
import { Bar, BarChart, CartesianGrid, LabelList, ResponsiveContainer } from 'recharts';
import { Link, useHistory } from 'react-router-dom';
import * as storageService from '../../services/localStorageService';
import * as devisPdfService from '../../services/calculs/devisPdf';

// ToolTip
import ReactTooltip from 'react-tooltip';

// Calculs
import { estimerDepenseApresTravaux } from '../../services/calculs/applyPreconisation';
import { getEnergieFinalePrimaireTheorique, getEnergieFinalePrimaireApresRenovation, getDepensesParPoste } from '../../services/calculs/bilanEnergieService';
import { PreconisationChauffage } from '../../services/calculs/calculPreconisationsCore';
import { createPreconisationsChauffage } from '../../services/calculs/createPreconisationsChauffage';
import { DUREE_PROJETCTION_FINANCIERE, calculEconomie_IndexationSimple } from '../../services/calculs/calculEconomie';
import Swal from 'sweetalert2';
import * as apiParticulierService from '../../services/apiParticulierService';
import withReactContent from 'sweetalert2-react-content';
import { currencyFormat } from '../../services/tools/TypeHelper';
import { ROUTE_AUDIT_FINALISATION_SANS_RDV, ROUTE_AUDIT_RDV } from '../../routing/paths';
import { ModalCounter } from '../../components/ModalCounter/ModalCounter';
import { sendAllImages } from '../../services/tools/imageHelper';
import { isAppartement } from '../../services/tools/auditTools';

// Styles
import './synthese.scss';

// Icons
import { ReactComponent as IconSyntheseBuche } from '../../assets/icons/synthese/icon-buche.svg';
import { ReactComponent as IconSyntheseFlame } from '../../assets/icons/synthese/icon-flame.svg';
import { ReactComponent as IconSyntheseEuro } from '../../assets/icons/synthese/icon-euro.svg';
import { ReactComponent as IconSyntheseBarrel } from '../../assets/icons/synthese/icon-barrel.svg';
import { ReactComponent as IconSyntheseEnergy } from '../../assets/icons/synthese/icon-energy.svg';
import { ReactComponent as IconHelp } from '../../assets/icons/icon-help.svg';

type TDataLeft = {
    nameChauffage: string;
    nameElectricite: string;
    nameEauChaude: string;

    prixChauffage: number;
    prixAutres: number;
    prixEauChaude: number;

    consoChauffage: number;
    consoElectricite: number;
    consoEauChaude: number;
};

type TDataRight = {
    nameChauffage: string;
    nameEauChaude: string;
    nameElectricite: string;

    prixChauffage: number;
    prixEauChaude: number;
    prixAutres: number;

    ecoChauffage: number;
    ecoEauChaude: number;
    ecoAutres: number;
};

// BEFORE to start this screen we need that ../../services/simulatorService.getAllConfigFiles() has been called, and is finished

const SyntheseEnergetique: React.FC = () => {
    const { push } = useHistory();
    const AlertSwal = withReactContent(Swal);
    // const clientIdentity = storageService.getClientIdentity();

    const AUDIT_PARAM = storageService.stepListToAuditAndClient();
    const INPUT_AUDIT = AUDIT_PARAM.audit;
    // console.log('audit = ' + JSON.stringify(INPUT_AUDIT));
    // console.log("==== affichage du bilan énergetique existant ====");
    // on récupère les énergies finales et primaire en provenance directe de l'audit.
    const energieInitiale = getEnergieFinalePrimaireTheorique(INPUT_AUDIT)!;
    // console.log("Energie finale      (1) : " + Math.round(energieInitiale.energieFinale.total()));
    // console.log("Energie Primaire    (2) : " + Math.round(energieInitiale.energiePrimaire.total()));
    // if (+INPUT_AUDIT.SHab && +INPUT_AUDIT.SHab.value !== 0) {
    //     const energiePrimaireParSurface = energieInitiale.energiePrimaire.total() / +INPUT_AUDIT.SHab.value // Vérifier si c'est / SHab ou / SHON
    //     //console.log("Energie primaire par m² : " + Math.round(energiePrimaireParSurface) + " kWh/m²/an")
    // }
    // A partir des déclaration présente dans l'audit, on calcule le cout, par poste energetique.
    const depenseInitiale = getDepensesParPoste(INPUT_AUDIT)!;

    // console.log("Depense energie totale, données a afficher pour les pastilles 3 à 9.1");
    // console.log("DepenseEnergieParPoste (3) = " + depenseInitiale.total() + "€");
    // console.log("  chauffage (4)   = " + depenseInitiale.chauffage.total() + "€ -> " + depenseInitiale.ratioChauffage() * 100 + "% (4.1)");
    // console.log("  eauChaude (6)   = " + depenseInitiale.eauChaude.total() + "€ -> " + depenseInitiale.ratioEauChaude() * 100 + "% (6.1)");
    // console.log("  autres    (5)   = " + depenseInitiale.autres.total() + "€ -> " + depenseInitiale.ratioAutres() * 100 + "% (5.1)");
    // console.log("DepenseEnergieParSource = " + depenseInitiale.total() + "€");
    // console.log("  electricite (8) = " + depenseInitiale.totalElectricite() + " €");
    // console.log("  fioul (9)       = " + depenseInitiale.totalFioul() + " €");
    // console.log("  gaz (7)         = " + depenseInitiale.totalGaz() + " €");
    // console.log("  bois (9.1)      = " + depenseInitiale.totalBois() + " €");

    //depenseInitiale.logDetail();

    const precosChauffage: Array<PreconisationChauffage> = createPreconisationsChauffage(INPUT_AUDIT);
    const depenseResult = estimerDepenseApresTravaux(precosChauffage, depenseInitiale);

    // ce morceau de code peut être utilisé pour la mise au point !
    // const depenseResult2 = estimerDepenseApresTravaux_HardCoded(depenseInitiale); // Peut servir de référence, pour vérifier si les calculs sont corrects.
    // const economie1 = depenseInitiale.total() - depenseResult.total();
    // const economie2 = depenseInitiale.total() - depenseResult2.total();

    // </Nouvelle logique>

    //depenseResult.logDetail();

    // A ce moment, depenseResult, ne contient pas le cout des abonnements (gaz et electricité)

    // On refait le calcul des énergies primaire et finales à partir de la nouvelle dépense
    const energieResult = getEnergieFinalePrimaireApresRenovation(INPUT_AUDIT, depenseResult)!;

    // console.log("Energie finale   avant (1) / après rénovation (11) : " + Math.round(energieInitiale.energieFinale.total()) + "  /  " + Math.round(energieResult.energieFinale.total()));

    // on calcule les économies sur l'énergie finale, attention à la potentielle division par 0.
    // if (energieInitiale.energieFinale.total() !== 0) {
    //     const economie = energieInitiale.energieFinale.total() - energieResult.energieFinale.total();
    //     const economieRatio = economie / energieInitiale.energieFinale.total();
    // }

    // console.log("Energie primaire avant (2) / après rénovation (12) : " + Math.round(energieInitiale.energiePrimaire.total()) + "  /  " + Math.round(energieResult.energiePrimaire.total()));
    // on calcule les éconmies sur l'énergie primaire, attention à la potentielle division par 0.
    // if (energieInitiale.energiePrimaire.total() !== 0) {
    //     const economie = energieInitiale.energiePrimaire.total() - energieResult.energiePrimaire.total();
    //     const economieRatio = economie / energieInitiale.energiePrimaire.total();
    // }

    // if(+INPUT_AUDIT.SHab && +INPUT_AUDIT.SHab.value !== 0){
    //     const energiePrimaireParSurfaceAvant = energieInitiale.energiePrimaire.total() / +INPUT_AUDIT.SHab.value // Vérifier si c'est / SHab ou / SHON
    //     const energiePrimaireParSurfaceApres = energieResult.energiePrimaire.total() / +INPUT_AUDIT.SHab.value; // Vérifier si c'est / SHab ou / SHON
    //     const ratioEnergiePrimaireParSurface =  ((energiePrimaireParSurfaceAvant - energiePrimaireParSurfaceApres) / energiePrimaireParSurfaceAvant) ;
    // }

    // Pour faciliter le debug, on peut ajouter ca, ca permet d'avoir le avant/après a côté l'un de l'autre.
    // console.log("Avant (déjà calculé avant)");
    // console.log("DepenseEnergieParPoste (3) : " + depenseInitiale.total() + "€");  console.log("  chauffage (4) = " + depenseInitiale.chauffage.total() + "€ -> " + depenseInitiale.ratioChauffage() * 100 + "% (4.1)"); console.log("  eauChaude (6) = " + depenseInitiale.eauChaude.total() + "€ -> " + depenseInitiale.ratioEauChaude() *100 + "% (6.1)"); console.log("  autres    (5) = " + depenseInitiale.autres.total() + "€ -> " + depenseInitiale.ratioAutres() *100 + "% (5.1)");
    // console.log("DepenseEnergieParSource : ");  console.log("  electricite (8) = " + depenseInitiale.totalElectricite() + " €");console.log("  fioul (9) = " + depenseInitiale.totalFioul() + " €");console.log("  gaz (7) = " + depenseInitiale.totalGaz() + " €");console.log("  bois (9.1) = " + depenseInitiale.totalBois() + " €");

    // Economie  année 0
    // console.log("==== calcul des économies année 0 ====");

    // console.log("Après => Affichage des pastilles 13 à 21");
    // console.log("DepenseEnergieParPoste (13) = " + depenseResult.total() + "€");
    // console.log("  chauffage (15)   = " + depenseResult.chauffage.total() + "€ -> " + Math.round(depenseResult.ratioChauffage() * 100) + "% (15.1)");
    // console.log("  eauChaude (17)   = " + depenseResult.eauChaude.total() + "€ -> " + Math.round(depenseResult.ratioEauChaude() * 100) + "% (17.1)");
    // console.log("  autres    (16)   = " + depenseResult.autres.total() + "€ -> " + Math.round(depenseResult.ratioAutres() * 100) + "% (16.1)");
    // console.log("DepenseEnergieParSource = " + depenseResult.total() + "€");
    // console.log("  electricite (19) = " + depenseResult.totalElectricite() + " €");
    // console.log("  fioul (20)       = " + depenseResult.totalFioul() + " €");
    // console.log("  gaz (18)         = " + depenseResult.totalGaz() + " €");
    // console.log("  bois (21)        = " + depenseResult.totalBois() + " €");
    // if (depenseInitiale.total() !== 0) {
    //     const economie = depenseInitiale.total() - depenseResult.total();
    //     const economieRatio = economie / depenseInitiale.total();
    //     console.log("Economie avant / après (14) = " + Math.round(economieRatio * 100) + "%");
    //     console.log("Economie avant / après      = " + economie + "€");
    // }

    // Economie sur 25 ans
    // console.log("==== calcul des économies cumulées sur 25 ans ====");

    const economie = calculEconomie_IndexationSimple(depenseInitiale, depenseResult, DUREE_PROJETCTION_FINANCIERE);

    // console.log("calcul economie sur 25 ans : " + Math.round(economie.total()) + "€");
    // console.log("Economie ParPoste  = " + Math.round(economie.total()) + "€");
    // console.log("  chauffage    = " + Math.round(economie.chauffage.total()) + "€");
    // console.log("  eauChaude    = " + Math.round(economie.eauChaude.total()) + "€");
    // console.log("  autres       = " + Math.round(economie.autres.total()) + "€");
    // console.log("Economie ParSource = " + Math.round(economie.total()) + "€");
    // console.log("  electricite  = " + Math.round(economie.totalElectricite()) + " €");
    // console.log("  fioul        = " + Math.round(economie.totalFioul()) + " €");
    // console.log("  gaz          = " + Math.round(economie.totalGaz()) + " €");
    // console.log("  bois         = " + Math.round(economie.totalBois()) + " €");
    // console.log("Economie moyenne par an : " + Math.round(economie.total() / annees) + "€")

    // @ts-ignore
    const energiePrimaireParSurfaceApres = energieResult.energiePrimaire.total() / +INPUT_AUDIT.SHab.value;

    //#region pastille, et élemetns graphiques.
    // Pastille 11.1
    const pastille111 = () => {
        if (energieInitiale.energieFinale.total() !== 0) {
            const economie = energieInitiale.energieFinale.total() - energieResult.energieFinale.total();
            const economieRatio = economie / energieInitiale.energieFinale.total();
            return '-' + Math.round(economieRatio * 100) + '%';
        }
    };

    // Pastille 12.1
    const pastille121 = () => {
        if (energieInitiale.energiePrimaire.total() !== 0) {
            const economie = energieInitiale.energiePrimaire.total() - energieResult.energiePrimaire.total();
            const economieRatio = economie / energieInitiale.energiePrimaire.total();
            return '-' + Math.round(economieRatio * 100) + '%';
        }
    };

    // Pastille 13.1
    const pastille131 = () => {
        // @ts-ignore
        if (INPUT_AUDIT.SHab && +INPUT_AUDIT.SHab.value !== 0) {
            // @ts-ignore
            const energiePrimaireParSurfaceAvant = energieInitiale.energiePrimaire.total() / +INPUT_AUDIT.SHab.value; // Vérifier si c'est / SHab ou / SHON
            // @ts-ignore
            const energiePrimaireParSurfaceApres = energieResult.energiePrimaire.total() / +INPUT_AUDIT.SHab.value; // Vérifier si c'est / SHab ou / SHON
            const ratioEnergiePrimaireParSurface = (energiePrimaireParSurfaceAvant - energiePrimaireParSurfaceApres) / energiePrimaireParSurfaceAvant;
            return '-' + Math.round(ratioEnergiePrimaireParSurface * 100) + '%';
        }
    };

    // // Pastille 14
    const pastille14 = () => {
        if (depenseInitiale.prix() !== 0) {
            const economie = depenseInitiale.prixHorsAbo() - depenseResult.prixHorsAbo();
            const economieRatio = economie / depenseInitiale.prixHorsAbo();
            return '-' + Math.round(economieRatio * 100) + '%';
        }
    };

    const rightEnergyFinale: { title: string; energyType: string; result: any; class: string; percent: any }[] = [
        {
            title: 'Energie primaire',
            result: Math.round(energieResult.energieFinale.total()),
            energyType: 'kWh',
            class: 'right',
            percent: pastille111(),
        },
        {
            title: 'Energie finale',
            result: Math.round(energieResult.energiePrimaire.total()),
            energyType: 'kWh',
            class: 'right',
            percent: pastille121(),
        },
        {
            title: 'Energie Primaire/m²/An',
            result: Math.round(energiePrimaireParSurfaceApres),
            energyType: 'kWh',
            class: 'right',
            percent: pastille131(),
        },
    ];

    const dataLeft: TDataLeft[] = [
        {
            nameChauffage: 'Chauffage',
            prixChauffage: Math.floor(depenseInitiale.prixChauffage()),
            consoChauffage: Math.floor(depenseInitiale.ratioPrixChauffage() * 100),

            nameEauChaude: 'Eau chaude sanitaire',
            prixEauChaude: Math.floor(depenseInitiale.prixEauChaude()),
            consoEauChaude: Math.floor(depenseInitiale.ratioPrixEauChaude() * 100),

            nameElectricite: 'Autres',
            prixAutres: Math.floor(depenseInitiale.prixAutres()),
            consoElectricite: Math.floor(depenseInitiale.ratioPrixAutres() * 100),
        },
    ];

    const dataRight: TDataRight[] = [
        {
            nameChauffage: 'Chauffage',
            prixChauffage: Math.floor(depenseResult.prixChauffage()),
            ecoChauffage: Math.floor(((depenseInitiale.prixChauffage() - depenseResult.prixChauffage()) / depenseInitiale.prixChauffage()) * 100),

            nameEauChaude: 'Eau chaude sanitaire',
            prixEauChaude: Math.floor(depenseResult.prixEauChaude()),
            ecoEauChaude: Math.floor(((depenseInitiale.prixEauChaude() - depenseResult.prixEauChaude()) / depenseInitiale.prixEauChaude()) * 100),

            nameElectricite: 'Autres',
            prixAutres: Math.floor(depenseResult.prixAutres()),
            ecoAutres: Math.floor(((depenseInitiale.prixAutres() - depenseResult.prixAutres()) / depenseInitiale.prixAutres()) * 100),
        },
    ];
    //#region json list for react componeent

    // Left part
    const leftSyntheseList: { icon: any; title: string; result: number; tooltip?: boolean; tooltipId?: string; tooltipMessage?: string }[] = [
        {
            icon: <IconSyntheseFlame fill="#FFF" />,
            title: 'Consommation gaz',
            result: depenseInitiale.prixGazHorsAbo(),
            tooltip: true,
            tooltipId: 'gaz',
            tooltipMessage: "Consommation hors frais d'abonnement",
        },
        {
            icon: <IconSyntheseEnergy fill="#FFF" />,
            title: 'Consommation electricite',
            result: depenseInitiale.prixElectriciteHorsAbo(),
            tooltip: true,
            tooltipId: 'electricite',
            tooltipMessage: "Consommation hors frais d'abonnement",
        },
        {
            icon: <IconSyntheseBarrel fill="#FFF" />,
            title: 'Consommation fioul',
            result: depenseInitiale.prixFioulHorsAbo(),
        },
        {
            icon: <IconSyntheseBuche fill="#FFF" />,
            title: 'Consommation bois',
            result: depenseInitiale.prixBoisHorsAbo(),
        },
    ];

    // @ts-ignore
    const energiePrimaireParSurface = energieInitiale.energiePrimaire.total() / +INPUT_AUDIT.SHab.value;
    const leftEnergyFinale: { title: string; energyType: string; result: any; class: string }[] = [
        {
            title: 'Energie primaire',
            result: Math.round(energieInitiale.energieFinale.total()),
            energyType: 'kWh',
            class: 'left',
        },
        {
            title: 'Energie finale',
            result: Math.round(energieInitiale.energiePrimaire.total()),
            energyType: 'kWh',
            class: 'left',
        },
        {
            title: 'Energie Primaire/m²/An',
            result: Math.round(energiePrimaireParSurface),
            energyType: 'kWh',
            class: 'left',
        },
    ];

    // Donut legend
    const donutLegend: { name: string; color: string }[] = [
        {
            name: 'Chauffage',
            color: '#E9B533',
        },
        {
            name: 'Autres',
            color: '#F93E1E',
        },
        {
            name: 'Eau chaude',
            color: '#82173A',
        },
    ];

    // Right part
    const rightSyntheseList: { icon: any; title: string; result: number; tooltip?: boolean; tooltipId?: string; tooltipMessage?: string }[] = [
        {
            icon: <IconSyntheseFlame fill="#FFF" />,
            title: 'Consommation gaz',
            result: depenseResult.prixGazHorsAbo(),
            tooltip: true,
            tooltipId: 'gaz',
            tooltipMessage: "Consommation hors frais d'abonnement",
        },
        {
            icon: <IconSyntheseEnergy fill="#FFF" />,
            title: 'Consommation electricite',
            result: depenseResult.prixElectriciteHorsAbo(),
            tooltip: true,
            tooltipId: 'electricite',
            tooltipMessage: "Consommation hors frais d'abonnement",
        },
        {
            icon: <IconSyntheseBarrel fill="#FFF" />,
            title: 'Consommation fioul',
            result: depenseResult.prixFioulHorsAbo(),
        },
        {
            icon: <IconSyntheseBuche fill="#FFF" />,
            title: 'Consommation bois',
            result: depenseResult.prixBoisHorsAbo(),
        },
    ];

    //#endregion

    // Séparateur de millier
    const thousandSeparator = (x: any) => {
        return x.toString().replace(/\B(?=([0-9]]{3})+(?![0-9]))/g, ' ');
    };

    //#region envoi de l'audit
    const agentCode = storageService.getCurrentAgent().agentCode;
    const agentPassword = storageService.getCurrentAgent().agentPassword;

    /**
     * Fonction pour l'envoi de la requête du rendez-vous
     */
    const sendAudit = async () => {
        try {
            const flowId = localStorage.getItem('flowId');

            if (!flowId) {
                throw new Error("Impossible d'envoyer l'audit, parcours utilisateur inexistant");
            }
            // Lancement de la requête
            return apiParticulierService.sendAudit(flowId);
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    // OLD WAY
    // const launchAuditRequest = async () => {
    //     try {
    //         // Lancement de la requête
    //         return apiParticulierService.postAuditData(AUDIT_PARAM);
    //     } catch (error) {
    //         console.error(error);
    //         throw error;
    //     }
    // };

    /**
     * Fonction pour l'envoi de la requête du rendez-vous
     */
    const requestRdvNoSignature = async () => {
        let date = new Date();
        date.setDate(date.getDate() + 1);
        const flowId = localStorage.getItem('flowId');
        if (!flowId) {
            throw new Error("Impossible d'envoyer l'audit, parcours utilisateur inexistant");
        }
        // Préparation du formData pour la requête
        let rdvJson: any = {
            rdv: {
                startDate: date.toISOString().slice(0, 10),
                startTime: date.toISOString().slice(11, 16),
            },
        };

        // Lancement de la requête
        try {
            return await apiParticulierService.requestRdvNoSignature(flowId, rdvJson);
        } catch (error) {
            throw error;
        }
    };
    // OLD WAY
    // const launchRdvRequest = async (auditId: string) => {
    //     let date = new Date();
    //     date.setDate(date.getDate() + 1);

    //     // Préparation du formData pour la requête
    //     let rdvJson: any = {
    //         clientIdentity,
    //         rdv: {
    //             startDate: date.toISOString().slice(0, 10),
    //             startTime: date.toISOString().slice(11, 16),
    //         },
    //         auditId: parseInt(auditId),
    //     };

    //     // Lancement de la requête
    //     try {
    //         return await apiParticulierService.postRdvData(rdvJson);
    //     } catch (error) {
    //         throw error;
    //     }
    // };

    //#endregion

    // Totaux
    const totalLeft: number = depenseInitiale.prixHorsAbo();
    const totalRight: number = depenseResult.prixHorsAbo();
    const totalEconomies: number = totalLeft - totalRight;

    // On créé le 'devis pdf' en mode 'early'
    const createDevis = async () => {
        const auditIdStr = localStorage.getItem('auditId');
        if (auditIdStr === null) {
            console.log('auditId is null');
            return;
        }
        const auditId = +auditIdStr;
        const { audit, clientIdentity } = storageService.stepListToAuditAndClient();

        // Si appartement, on envoie pas de devis du tout :
        if (isAppartement(audit)) {
            console.log('appartement => pas de devis !');
            return;
        }

        const simulationSimple = await devisPdfService.createSimulationSimple(audit);

        const simuAvecPreco = await devisPdfService.createSimulationCompleteAndPv('Early', auditId, audit);
        await apiParticulierService.createDevisPdf('Early', auditId, {
            clientIdentity,
            // audit,
            simulationSimple,
            ...simuAvecPreco,
        });
    };

    const onClickSansRDV = async () => {
        try {
            AlertSwal.fire({
                title: "Envoi de l'audit en cours",
                html: <ModalCounter timer={45} />,
                width: 600,
                showCancelButton: false,
                showConfirmButton: false,
                allowOutsideClick: false,
            });

            /**
             * envoie les images et les plans à l'api, et sauvergarde les urls dans le local storage
             */
            sendAllImages();

            await apiParticulierService.identify(agentCode, agentPassword);
            // update audit data
            await apiParticulierService.updateAuditDataService();
            // send audit to icoll
            await sendAudit();
            const template = storageService.getAudit();
            template.auditSent = true;
            storageService.setAudit(template);
            // request rdv no signature to icoll
            const response = await requestRdvNoSignature();

            if (response?.status === 'Created' || response?.status === 'OK') {
                // Mise à jour localstorage pour indiquer que l'audit est envoyé
                let newTemplate = storageService.getAudit();
                newTemplate.auditSent = true;
                storageService.setAudit(newTemplate);

                // creation du devis avant de naviguer
                await createDevis();
            }
        } catch (e) {
            console.error(e);
        } finally {
            AlertSwal.close();
            push(ROUTE_AUDIT_FINALISATION_SANS_RDV);
        }
    };

    const getFlowState = localStorage.getItem('flowState');

    return (
        <div className="container pb-4" id="synthese">
            <h1 className="main-title-mini">Synthèse énergétique</h1>

            <div className="row">
                <div className="col-12 col-md-6 mb-4 mb-md-0">
                    <h2 className="synthese-title">Etat actuel</h2>
                    <div className="card-synthese">
                        <div className="card-synthese-left">
                            <h3 className="title">Répartition de vos dépenses en énergies</h3>

                            <ul className="list">
                                {leftSyntheseList.map((item: any, index: number) => (
                                    <li key={index}>
                                        {item.icon}
                                        <div className="list-detail">
                                            {item.title}
                                            <span>{currencyFormat(item.result)}</span>
                                        </div>
                                        {item.tooltip && (
                                            <>
                                                <div className="help-text" data-tip data-for={`${item.tooltipId}`}>
                                                    <IconHelp fill="#FFFFFF" width={14} height={14} className="icon" />
                                                </div>

                                                <ReactTooltip id={`${item.tooltipId}`} place="bottom" type="light" effect="solid">
                                                    <h3>{item.tooltipId}</h3>
                                                    <p>{item.tooltipMessage}</p>
                                                </ReactTooltip>
                                            </>
                                        )}
                                    </li>
                                ))}
                            </ul>

                            <div className="total">Total : {currencyFormat(totalLeft).replace(',00', '')}</div>
                        </div>

                        <div className="card-synthese-right">
                            <h3 className="top-title grey">
                                <span className="laius-big">Répartition des dépenses</span>
                            </h3>
                            <ResponsiveContainer width="100%" height={200} maxHeight={200}>
                                <BarChart data={dataLeft} margin={{ top: 20 }} stackOffset="sign">
                                    <CartesianGrid vertical={false} />
                                    <Bar dataKey="prixChauffage" fill="#E9B533" minPointSize={16}>
                                        <LabelList fill={'#785712'} fontSize={'14px'} formatter={(value: number) => value + '€'} position="center" />
                                        <LabelList
                                            fill={'#785712'}
                                            fontSize={'10px'}
                                            dataKey={'consoChauffage'}
                                            formatter={(value: string) => value + '% conso'}
                                            position="top"
                                        />
                                    </Bar>
                                    <Bar dataKey="prixAutres" fill="#F93E1E" minPointSize={16}>
                                        <LabelList fill={'#FFD3C6'} fontSize={'14px'} formatter={(value: number) => value + '€'} position="center" />
                                        <LabelList
                                            fill={'#F93E1E'}
                                            fontSize={'10px'}
                                            dataKey={'consoElectricite'}
                                            formatter={(value: string) => value + '% conso'}
                                            position="top"
                                        />
                                    </Bar>
                                    <Bar dataKey="prixEauChaude" fill="#82173A" minPointSize={16}>
                                        <LabelList fill={'#FCBBCF'} fontSize={'14px'} formatter={(value: number) => value + '€'} position="center" />
                                        <LabelList
                                            fill={'#82173A'}
                                            fontSize={'10px'}
                                            dataKey={'consoEauChaude'}
                                            formatter={(value: string) => value + '% conso'}
                                            position="top"
                                        />
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>

                            <ul className="legend">
                                {donutLegend.map((item: any, index: number) => (
                                    <li key={index}>
                                        <span style={{ background: item.color }}></span>
                                        {item.name}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div className="card-energy">
                        <ul className="list">
                            {leftEnergyFinale.map((item: any, index: number) => (
                                <li className={item.class} key={index}>
                                    <h5>{item.title}</h5>
                                    <div className="circle">
                                        {thousandSeparator(item.result)}
                                        <span>{item.energyType}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div className="col-12 col-md-6 mb-4 mb-md-0">
                    <h2 className="synthese-title">Après renovation énergétique</h2>
                    <div className="card-synthese">
                        <div className="card-synthese-left greener">
                            <h3 className="title">Répartition de vos dépenses en énergies</h3>

                            <ul className="list">
                                {rightSyntheseList.map((item: any, index: number) => (
                                    <li key={index}>
                                        {item.icon}
                                        <div className="list-detail">
                                            {item.title}
                                            <span>{currencyFormat(item.result).replace(',00', '')}</span>
                                        </div>
                                        {item.tooltip && (
                                            <>
                                                <div className="help-text" data-tip data-for={`${item.tooltipId}`}>
                                                    <IconHelp fill="#FFFFFF" width={14} height={14} className="icon" />
                                                </div>

                                                <ReactTooltip id={`${item.tooltipId}`} place="bottom" type="light" effect="solid">
                                                    <h3>{item.tooltipId}</h3>
                                                    <p>{item.tooltipMessage}</p>
                                                </ReactTooltip>
                                            </>
                                        )}
                                    </li>
                                ))}
                            </ul>

                            <div className="total">
                                Total : {currencyFormat(totalRight).replace(',00', '')}
                                <div className="total-economies">Economies annuelles : {currencyFormat(totalEconomies).replace(',00', '')}</div>
                            </div>
                        </div>

                        <div className="card-synthese-right">
                            <h3 className="top-title">
                                <span>
                                    Economies sur 25 ans : <strong>{currencyFormat(Math.round(economie)).replace(',00', '')}</strong>
                                </span>
                                <IconSyntheseEuro fill="#3E7943" width={30} height={30} />
                                Economies : {pastille14()}
                                <span className="laius grey">Profitez des aides d'état</span>
                            </h3>

                            <div id="rightChart">
                                <ResponsiveContainer width="100%" height={200} maxHeight={200}>
                                    <BarChart data={dataRight} margin={{ top: 20 }} stackOffset="sign">
                                        <CartesianGrid vertical={false} />

                                        <Bar dataKey="prixChauffage" fill="#E9B533" stackId="chauffage" minPointSize={16}>
                                            <LabelList
                                                fill={'#785712'}
                                                fontSize={'14px'}
                                                dataKey="prixChauffage"
                                                formatter={(value: string) => value + '€'}
                                                position="center"
                                            />
                                            <LabelList
                                                fill={'#3E7943'}
                                                fontSize={'12px'}
                                                dataKey="ecoChauffage"
                                                formatter={(value: number) => value + "% d'économie"}
                                                position="top"
                                            />
                                        </Bar>

                                        <Bar dataKey="ecoChauffage" fill="transparent" stackId="chauffage" />

                                        <Bar dataKey="prixAutres" fill="#F93E1E" stackId="electricite" minPointSize={16}>
                                            <LabelList
                                                fill={'#FFD3C6'}
                                                fontSize={'14px'}
                                                dataKey="prixAutres"
                                                formatter={(value: string) => value + '€'}
                                                position="center"
                                            />
                                            <LabelList
                                                fill={'#3E7943'}
                                                fontSize={'12px'}
                                                dataKey="ecoAutres"
                                                formatter={(value: number) => value + "% d'économie"}
                                                position="top"
                                            />
                                        </Bar>

                                        <Bar dataKey="ecoAutres" fill="transparent" stackId="electricite" />

                                        <Bar dataKey="prixEauChaude" fill="#82173A" stackId="eauChaude" minPointSize={16}>
                                            <LabelList
                                                fill={'#FCBBCF'}
                                                fontSize={'14px'}
                                                dataKey="prixEauChaude"
                                                formatter={(value: string) => value + '€'}
                                                position="center"
                                            />
                                            <LabelList
                                                fill={'#3E7943'}
                                                fontSize={'12px'}
                                                dataKey="ecoEauChaude"
                                                formatter={(value: number) => value + "% d'économie"}
                                                position="top"
                                            />
                                        </Bar>

                                        <Bar dataKey="ecoEauChaude" fill="transparent" stackId="eauChaude" />
                                    </BarChart>
                                </ResponsiveContainer>
                                {/* TODO: Remove if Skippy.response === true */}
                                {/* <ResponsiveContainer width="100%" height={200} maxHeight={200}>
                                    <BarChart data={dataRight} margin={{ top: 20 }} stackOffset="sign">
                                        <CartesianGrid vertical={false} />
                                        <Bar dataKey="prixChauffage" fill="#E9B533" stackId="chauffage">
                                            <LabelList fill={'#785712'} fontSize={'14px'} dataKey="prixChauffage" formatter={(value: string) => (value + '€')} position="center" />
                                        </Bar>

                                        <Bar dataKey="ecoChauffage" fill="#FFEEBA" stackId="chauffage">
                                            <LabelList fill={'#F93E1E'} fontSize={'12px'} dataKey="ecoChauffage" formatter={(value: number) => (Math.floor(value / depenseInitiale.chauffage.total() * 100) + '% d\'économie')} position="center" />
                                        </Bar>

                                        <Bar dataKey="prixAutres" fill="#F93E1E" stackId="electricite">
                                            <LabelList fill={'#FFD3C6'} fontSize={'14px'} dataKey="prixAutres" formatter={(value: string) => (value + '€')} position="center" />
                                        </Bar>

                                        <Bar dataKey="ecoAutres" fill="#F7D6CC" stackId="electricite">
                                            <LabelList fill={'#F93E1E'} fontSize={'12px'} dataKey="ecoAutres" formatter={(value: number) => (Math.floor(value / depenseInitiale.autres.total() * 100) + '% d\'économie')} position="center" />
                                        </Bar>

                                        <Bar dataKey="prixEauChaude" fill="#82173A" stackId="eauChaude">
                                            <LabelList fill={'#FCBBCF'} fontSize={'14px'} dataKey="prixEauChaude" formatter={(value: string) => (value + '€')} position="center" />
                                        </Bar>

                                        <Bar dataKey="ecoEauChaude" fill="#FEE2E3" stackId="eauChaude">
                                            <LabelList fill={'#F93E1E'} fontSize={'12px'} dataKey="ecoEauChaude" formatter={(value: number) => (Math.floor(value / depenseInitiale.eauChaude.total() * 100) + '% d\'économie')} position="center" />
                                        </Bar>
                                    </BarChart>
                                </ResponsiveContainer> */}
                            </div>

                            <ul className="legend">
                                {donutLegend.map((item: any, index: number) => (
                                    <li key={index}>
                                        <span style={{ background: item.color }}></span>
                                        {item.name}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div className="card-energy">
                        <ul className="list">
                            {rightEnergyFinale.map((item: any, index: number) => (
                                <li className={item.class} key={index}>
                                    <h5>{item.title}</h5>
                                    <div className="circle">
                                        {thousandSeparator(item.result)}
                                        <span>{item.energyType}</span>
                                        <p>{item.percent}</p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            <div className="btn-grp justify-content-end align-items-center pt-4">
                <span className="btn-grp--text">Finaliser mon audit</span>

                <button type="button" onClick={onClickSansRDV} className="btn btn-secondary mb-3 mb-md-0" disabled={getFlowState === 'WithoutRdv'}>
                    Sans rendez-vous
                </button>

                <Link to={ROUTE_AUDIT_RDV} className="btn btn-primary">
                    Avec rendez-vous
                </Link>
            </div>
        </div>
    );
};

export default SyntheseEnergetique;
