import React from 'react';
import AppForm from '../../components/Forms/AppForm';
import AppFormField from '../Forms/AppFormField';
import AppFormSelectField from '../Forms/AppFormSelectField';
import { Title } from '../../components/Title/Title';
import { TextElement } from '../../components/Text/Text';
import { Field, useFormikContext } from 'formik';
import {
    generateColumnsLines,
    ExternalProps,
    initialPhotovoltaique,
    initialValuesDraw,
    initialValuesPac,
    initialValuesInsulation,
    initialValuesText,
    initialValuesArrow,
    initialValuesObservation,
    lineOptions,
    endPointOptions,
    rawOptions,
    ModalDP5Props,
    initialValuesVelux,
    initialValuesChimney,
    initialValuesDormer,
    initialValuesCircle,
    orientationList,
    initialValuesPanel,
    initialValuesAuvent,
    textureOptions,
    defaultOption,
} from './ModalEnums';
import { LabelledString } from '../../services/tools/TypeHelper';
import { OptionProps, SingleValueProps, components } from 'react-select';
import {
    ModalChimneyProps,
    ModalDormerProps,
    ModalVeluxProps,
    ModalTextProps,
    ModalInsulationProps,
    ModalPhotovoltaiqueProps,
    ModalPacProps,
    ModalDrawProps,
    ModalPanelProps,
    ModalAuventProps,
} from '../../services/tools/konvaTools';

// Icon
// import { ReactComponent as IconCheck } from 'assets/icons/icon-checked.svg';

// Schemas
import schemaPhotovoltaique from './schema/schemaPhotovoltaique';
import schemaDraw from './schema/schemaDraw';
import schemaPac from './schema/schemaPac';
import schemaInsulation from './schema/schemaInsulation';
import schemaText from './schema/schemaText';
import schemaArrow from './schema/schemaArrow';
import schemaObservation from './schema/schemaObservation';
import schemaVelux from './schema/schemaVelux';
import schemaChimney from './schema/schemaChimney';
import schemaDormer from './schema/schemaDormer';
import schemaCircle from './schema/schemaCircle';
import schemaPanel from './schema/schemaPanel';
import schemaAuvent from './schema/schemaAuvent';

// CSS
import './Modal.scss';

const ColorOption: React.FC<OptionProps<LabelledString, false>> = (props) => (
    <components.Option {...props}>
        <div className="select-color">
            <span className="sample" style={{ background: props.data.value ? props.data.value : '' }} data-pattern={props.data.value}></span>
            {props.label ? props.label : ''}
        </div>
    </components.Option>
);

const ColorSingleValue: React.FC<SingleValueProps<LabelledString, any>> = (props) => (
    <components.SingleValue {...props}>
        <div className="select-color">
            <span className="sample" style={{ background: props.data.value ? props.data.value : '' }} data-pattern={props.data.value}></span>
            {props.data.label ? props.data.label : ''}
        </div>
    </components.SingleValue>
);

const LineComponentOptions: React.FC<OptionProps<LabelledString, false>> = (props) => (
    <components.Option {...props}>
        <div className="select-line">
            <span className="sample" style={{ borderBottom: `2px ${props.data.value} #000000` }}></span>
            <span style={{ opacity: '0' }}>{props.data.label}</span>
        </div>
    </components.Option>
);

const LineComponentSingleValue: React.FC<SingleValueProps<LabelledString, any>> = (props) => (
    <components.SingleValue {...props}>
        <div className="select-line">
            <span className="sample" style={{ borderBottom: `2px ${props.data.value} #000000` }}></span>
        </div>
    </components.SingleValue>
);

const EndPointComponentOptions: React.FC<OptionProps<LabelledString, false>> = (props) => (
    <components.Option {...props}>
        <div className="select-line">
            <span className="sample" data-form={props.data.value}></span>
            <span style={{ opacity: '0' }}>{props.data.label}</span>
        </div>
    </components.Option>
);

const EndPointComponentSingleValue: React.FC<SingleValueProps<LabelledString, any>> = (props) => (
    <components.SingleValue {...props}>
        <div className="select-line">
            <span className="sample" data-form={props.data.value}></span>
            <span style={{ opacity: '0' }}>{props.data.value}</span>
        </div>
    </components.SingleValue>
);

const TextForm: React.FC<ExternalProps> = ({ paragraph, setShow }) => {
    const { errors } = useFormikContext<ModalTextProps>();

    return (
        <>
            <div className="modal-body">
                <TextElement as="p">{paragraph}</TextElement>
                <div className="modal-split"></div>
                <Field labelName="Intitulé" type="text" name="message" id="message" component={AppFormField} autoFocus />
            </div>
            <div className="modal-footer">
                <div className="btn-grp justify-content-center w-100">
                    <button type="button" className="btn btn-cancel" onClick={() => setShow(false)}>
                        Annuler
                    </button>

                    <button type="submit" className="btn btn-submit" disabled={Object.entries(errors).length > 0}>
                        Ajouter
                    </button>
                </div>
            </div>
        </>
    );
};

const ArrowForm: React.FC<ExternalProps> = ({ paragraph, setShow }) => {
    const { errors } = useFormikContext<ModalTextProps>();

    return (
        <>
            <div className="modal-body">
                <TextElement as="p">{paragraph}</TextElement>
                <div className="modal-split"></div>
                <Field
                    labelName="Type de trait"
                    name="lineType"
                    id="lineType"
                    component={AppFormSelectField}
                    placeholder="Type de trait"
                    options={lineOptions}
                    components={{ SingleValue: LineComponentSingleValue, Option: LineComponentOptions }}
                />
                <Field
                    labelName="Type d'extrémités"
                    name="endPointType"
                    id="endPointType"
                    component={AppFormSelectField}
                    placeholder="Type d'extrémités"
                    options={endPointOptions}
                    components={{ SingleValue: EndPointComponentSingleValue, Option: EndPointComponentOptions }}
                />
                <Field
                    labelName="Couleur"
                    name="color"
                    id="color"
                    component={AppFormSelectField}
                    options={rawOptions}
                    placeholder="Couleur"
                    components={{ SingleValue: ColorSingleValue, Option: ColorOption }}
                />
            </div>
            <div className="modal-footer">
                <div className="btn-grp justify-content-center w-100">
                    <button type="button" className="btn btn-cancel" onClick={() => setShow(false)}>
                        Annuler
                    </button>

                    <button type="submit" className="btn btn-submit" disabled={Object.entries(errors).length > 0}>
                        Ajouter
                    </button>
                </div>
            </div>
        </>
    );
};

const ObservationForm: React.FC<ExternalProps> = ({ paragraph, setShow }) => {
    const { errors } = useFormikContext<ModalTextProps>();

    return (
        <>
            <div className="modal-body">
                <TextElement as="p">{paragraph}</TextElement>
                <div className="modal-split"></div>
                <Field labelName="Intitulé" name="message" id="message" component={AppFormField} placeholder="Intitulé" autoFocus />
            </div>
            <div className="modal-footer">
                <div className="btn-grp justify-content-center w-100">
                    <button type="button" className="btn btn-cancel" onClick={() => setShow(false)}>
                        Annuler
                    </button>

                    <button type="submit" className="btn btn-submit" disabled={Object.entries(errors).length > 0}>
                        Ajouter
                    </button>
                </div>
            </div>
        </>
    );
};

const PhotovoltaiqueForm: React.FC<ExternalProps> = ({ paragraph, setShow, options }) => {
    const { errors } = useFormikContext<ModalPhotovoltaiqueProps>();

    return (
        <>
            <div className="modal-body">
                <TextElement as="p">{paragraph}</TextElement>
                <Field labelName="Disposition" options={orientationList} name="orientation" placeholder="Orientation" component={AppFormSelectField} />
                <Field labelName="Colonnes" options={generateColumnsLines} name="columns" placeholder="Colonnes" component={AppFormSelectField} />
                <Field labelName="Lignes" options={generateColumnsLines} name="lines" placeholder="Lignes" component={AppFormSelectField} />
                <Field
                    labelName="Couleur"
                    options={options}
                    name="color"
                    placeholder="Couleur"
                    component={AppFormSelectField}
                    components={{ SingleValue: ColorSingleValue, Option: ColorOption }}
                />
            </div>
            <div className="modal-footer">
                <div className="btn-grp justify-content-center w-100">
                    <button type="button" className="btn btn-cancel" onClick={() => setShow(false)}>
                        Annuler
                    </button>
                    <button type="submit" className="btn btn-submit" disabled={Object.entries(errors).length > 0}>
                        Ajouter
                    </button>
                </div>
            </div>
        </>
    );
};

const PanelForm: React.FC<ExternalProps> = ({ paragraph, setShow, options }) => {
    const { errors } = useFormikContext<ModalPanelProps>();

    return (
        <>
            <div className="modal-body">
                <TextElement as="p">{paragraph}</TextElement>

                <Field labelName="Disposition" options={orientationList} name="orientation" placeholder="Disposition" component={AppFormSelectField} />
                <Field labelName="Colonnes" options={generateColumnsLines} name="columns" placeholder="Colonnes" component={AppFormSelectField} />
                <Field labelName="Lignes" options={generateColumnsLines} name="lines" placeholder="Lignes" component={AppFormSelectField} />
                <Field
                    labelName="Couleur"
                    options={options}
                    name="color"
                    placeholder="Couleur"
                    component={AppFormSelectField}
                    components={{ SingleValue: ColorSingleValue, Option: ColorOption }}
                />
            </div>
            <div className="modal-footer">
                <div className="btn-grp justify-content-center w-100">
                    <button type="button" className="btn btn-cancel" onClick={() => setShow(false)}>
                        Annuler
                    </button>
                    <button type="submit" className="btn btn-submit" disabled={Object.entries(errors).length > 0}>
                        Ajouter
                    </button>
                </div>
            </div>
        </>
    );
};

const DrawForm: React.FC<ExternalProps> = ({ paragraph, setShow }) => {
    const { errors, values } = useFormikContext<ModalDrawProps>();

    return (
        <>
            <div className="modal-body">
                <TextElement as="p">{paragraph}</TextElement>
                <div className="modal-split"></div>
                <Field labelName="Intitulé" type="text" name="message" placeholder="Intitulé" component={AppFormField} autoFocus />
                <div className="form-split">
                    <Field
                        labelName="Couleur"
                        options={rawOptions}
                        name="color"
                        placeholder="Couleur"
                        component={AppFormSelectField}
                        components={{ SingleValue: ColorSingleValue, Option: ColorOption }}
                    />
                    <div className={`form-group form-group-checkbox ${values.color?.value !== '' ? '' : 'disabled'}`}>
                        <Field type="checkbox" name="opacity" id="opacity" />
                        <label htmlFor="opacity">Couleur opaque</label>
                        <span className="icon">
                            {/* <IconCheck fill="white" height={10} /> */}
                            check
                        </span>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <div className="btn-grp justify-content-center w-100">
                    <button type="button" className="btn btn-cancel" onClick={() => setShow(false)}>
                        Annuler
                    </button>

                    <button type="submit" className="btn btn-submit" disabled={Object.entries(errors).length > 0}>
                        Ajouter
                    </button>
                </div>
            </div>
        </>
    );
};

const PacForm: React.FC<ExternalProps> = ({ paragraph, setShow }) => {
    const { errors } = useFormikContext<ModalPacProps>();

    return (
        <>
            <div className="modal-body">
                <TextElement as="p">{paragraph}</TextElement>
                <div className="modal-split"></div>
                <div className="hidden">
                    <Field labelName="Colonnes" options={generateColumnsLines} name="columns" placeholder="Colonnes" component={AppFormSelectField} />
                    <Field labelName="Lignes" options={generateColumnsLines} name="lines" placeholder="Lignes" component={AppFormSelectField} />
                </div>
                <Field
                    labelName="Couleur"
                    options={rawOptions}
                    name="color"
                    placeholder="Couleur"
                    component={AppFormSelectField}
                    components={{ SingleValue: ColorSingleValue, Option: ColorOption }}
                />
            </div>
            <div className="modal-footer">
                <div className="btn-grp justify-content-center w-100">
                    <button type="button" className="btn btn-cancel" onClick={() => setShow(false)}>
                        Annuler
                    </button>

                    <button type="submit" className="btn btn-submit" disabled={Object.entries(errors).length > 0}>
                        Ajouter
                    </button>
                </div>
            </div>
        </>
    );
};

const InsulationForm: React.FC<ExternalProps> = ({ paragraph, setShow }) => {
    const { errors, values } = useFormikContext<ModalInsulationProps>();

    return (
        <>
            <div className="modal-body">
                <TextElement as="p">{paragraph}</TextElement>
                <div className="modal-split"></div>
                <div className="form-split">
                    <Field
                        labelName="Couleur"
                        options={rawOptions}
                        name="color"
                        placeholder="Couleur"
                        component={AppFormSelectField}
                        components={{ SingleValue: ColorSingleValue, Option: ColorOption }}
                    />
                    <div className={`form-group form-group-checkbox ${values.color?.value !== '' ? '' : 'disabled'}`}>
                        <Field type="checkbox" name="opacity" id="opacity" />
                        <label htmlFor="opacity">Couleur opaque</label>
                        <span className="icon">
                            {/* <IconCheck fill="white" height={10} /> */}
                            check
                        </span>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <div className="btn-grp justify-content-center w-100">
                    <button type="button" className="btn btn-cancel" onClick={() => setShow(false)}>
                        Annuler
                    </button>

                    <button type="submit" className="btn btn-submit" disabled={Object.entries(errors).length > 0}>
                        Ajouter
                    </button>
                </div>
            </div>
        </>
    );
};

const VeluxForm: React.FC<ExternalProps> = ({ paragraph, setShow }) => {
    const { errors } = useFormikContext<ModalVeluxProps>();

    return (
        <>
            <div className="modal-body">
                <TextElement as="p">{paragraph}</TextElement>
                <div className="modal-split"></div>
                <Field labelName="Intitulé" name="message" id="message" component={AppFormField} placeholder="Intitulé" autoFocus />
                <Field
                    labelName="Couleur"
                    name="color"
                    id="color"
                    component={AppFormSelectField}
                    options={rawOptions}
                    placeholder="Couleur"
                    components={{ SingleValue: ColorSingleValue, Option: ColorOption }}
                />
            </div>
            <div className="modal-footer">
                <div className="btn-grp justify-content-center w-100">
                    <button type="button" className="btn btn-cancel" onClick={() => setShow(false)}>
                        Annuler
                    </button>

                    <button type="submit" className="btn btn-submit" disabled={Object.entries(errors).length > 0}>
                        Ajouter
                    </button>
                </div>
            </div>
        </>
    );
};

const ChimneyForm: React.FC<ExternalProps> = ({ paragraph, setShow }) => {
    const { errors } = useFormikContext<ModalChimneyProps>();

    return (
        <>
            <div className="modal-body">
                <TextElement as="p">{paragraph}</TextElement>
                <div className="modal-split"></div>
                <Field labelName="Intitulé" name="message" id="message" component={AppFormField} placeholder="Intitulé" autoFocus />
                <Field
                    labelName="Couleur"
                    name="color"
                    id="color"
                    component={AppFormSelectField}
                    options={rawOptions}
                    placeholder="Couleur"
                    components={{ SingleValue: ColorSingleValue, Option: ColorOption }}
                />
            </div>
            <div className="modal-footer">
                <div className="btn-grp justify-content-center w-100">
                    <button type="button" className="btn btn-cancel" onClick={() => setShow(false)}>
                        Annuler
                    </button>

                    <button type="submit" className="btn btn-submit" disabled={Object.entries(errors).length > 0}>
                        Ajouter
                    </button>
                </div>
            </div>
        </>
    );
};

const DormerForm: React.FC<ExternalProps> = ({ paragraph, setShow }) => {
    const { errors } = useFormikContext<ModalDormerProps>();

    return (
        <>
            <div className="modal-body">
                <TextElement as="p">{paragraph}</TextElement>
                <div className="modal-split"></div>
                <Field labelName="Intitulé" name="message" id="message" component={AppFormField} placeholder="Intitulé" autoFocus />
                <Field
                    labelName="Couleur"
                    name="color"
                    id="color"
                    component={AppFormSelectField}
                    options={rawOptions}
                    placeholder="Couleur"
                    components={{ SingleValue: ColorSingleValue, Option: ColorOption }}
                />
            </div>
            <div className="modal-footer">
                <div className="btn-grp justify-content-center w-100">
                    <button type="button" className="btn btn-cancel" onClick={() => setShow(false)}>
                        Annuler
                    </button>

                    <button type="submit" className="btn btn-submit" disabled={Object.entries(errors).length > 0}>
                        Ajouter
                    </button>
                </div>
            </div>
        </>
    );
};

const CircleForm: React.FC<ExternalProps> = ({ paragraph, setShow }) => {
    const { errors } = useFormikContext<ModalDormerProps>();

    return (
        <>
            <div className="modal-body">
                <TextElement as="p">{paragraph}</TextElement>
                <div className="modal-split"></div>
                <Field labelName="Intitulé" name="message" id="message" component={AppFormField} placeholder="Intitulé" autoFocus />
                <Field
                    labelName="Couleur"
                    name="color"
                    id="color"
                    component={AppFormSelectField}
                    options={rawOptions}
                    placeholder="Couleur"
                    components={{ SingleValue: ColorSingleValue, Option: ColorOption }}
                />
            </div>
            <div className="modal-footer">
                <div className="btn-grp justify-content-center w-100">
                    <button type="button" className="btn btn-cancel" onClick={() => setShow(false)}>
                        Annuler
                    </button>

                    <button type="submit" className="btn btn-submit" disabled={Object.entries(errors).length > 0}>
                        Ajouter
                    </button>
                </div>
            </div>
        </>
    );
};

const AuventForm: React.FC<ExternalProps> = ({ paragraph, setShow }) => {
    const { errors } = useFormikContext<ModalAuventProps>();

    return (
        <>
            <div className="modal-body">
                <TextElement as="p">{paragraph}</TextElement>
                <div className="modal-split"></div>
                <Field
                    labelName="Texture"
                    name="texture"
                    id="texture"
                    component={AppFormSelectField}
                    options={textureOptions}
                    placeholder="Texture"
                    components={{ SingleValue: ColorSingleValue, Option: ColorOption }}
                />
            </div>
            <div className="modal-footer">
                <div className="btn-grp justify-content-center w-100">
                    <button type="button" className="btn btn-cancel" onClick={() => setShow(false)}>
                        Annuler
                    </button>

                    <button type="submit" className="btn btn-submit" disabled={Object.entries(errors).length > 0}>
                        Ajouter
                    </button>
                </div>
            </div>
        </>
    );
};

export const Modal: React.FC<ModalDP5Props> = ({ title, paragraph, show, setShow, modalType, onConfirm }) => {
    if (!show) {
        return null;
    }

    // Init initialValues to empty object
    let initialValues = {};
    let schema = {};
    let fields = null;

    switch (modalType) {
        case 'photovoltaique':
            initialValues = initialPhotovoltaique;
            schema = schemaPhotovoltaique;
            fields = <PhotovoltaiqueForm setShow={setShow} paragraph={paragraph} onConfirm={onConfirm} options={defaultOption} />;
            break;

        case 'panel':
            initialValues = initialValuesPanel;
            schema = schemaPanel;
            fields = <PanelForm setShow={setShow} paragraph={paragraph} onConfirm={onConfirm} options={defaultOption} />;
            break;

        case 'draw':
            initialValues = initialValuesDraw;
            schema = schemaDraw;
            fields = <DrawForm setShow={setShow} paragraph={paragraph} onConfirm={onConfirm} options={defaultOption} />;
            break;

        case 'pac':
            initialValues = initialValuesPac;
            schema = schemaPac;
            fields = <PacForm setShow={setShow} paragraph={paragraph} onConfirm={onConfirm} options={defaultOption} />;
            break;

        case 'insulation':
            initialValues = initialValuesInsulation;
            schema = schemaInsulation;
            fields = <InsulationForm setShow={setShow} paragraph={paragraph} onConfirm={onConfirm} options={defaultOption} />;
            break;

        case 'text':
            initialValues = initialValuesText;
            schema = schemaText;
            fields = <TextForm setShow={setShow} paragraph={paragraph} onConfirm={onConfirm} />;
            break;

        case 'arrow':
            initialValues = initialValuesArrow;
            schema = schemaArrow;
            fields = <ArrowForm setShow={setShow} paragraph={paragraph} onConfirm={onConfirm} />;
            break;

        case 'observation':
            initialValues = initialValuesObservation;
            schema = schemaObservation;
            fields = <ObservationForm setShow={setShow} paragraph={paragraph} onConfirm={onConfirm} />;
            break;

        case 'velux':
            initialValues = initialValuesVelux;
            schema = schemaVelux;
            fields = <VeluxForm setShow={setShow} paragraph={paragraph} onConfirm={onConfirm} />;
            break;

        case 'chimney':
            initialValues = initialValuesChimney;
            schema = schemaChimney;
            fields = <ChimneyForm setShow={setShow} paragraph={paragraph} onConfirm={onConfirm} />;
            break;

        case 'dormer':
            initialValues = initialValuesDormer;
            schema = schemaDormer;
            fields = <DormerForm setShow={setShow} paragraph={paragraph} onConfirm={onConfirm} />;
            break;

        case 'circle':
            initialValues = initialValuesCircle;
            schema = schemaCircle;
            fields = <CircleForm setShow={setShow} paragraph={paragraph} onConfirm={onConfirm} />;
            break;

        case 'auvent':
            initialValues = initialValuesAuvent;
            schema = schemaAuvent;
            fields = <AuventForm setShow={setShow} paragraph={paragraph} onConfirm={onConfirm} />;
            break;

        default:
            break;
    }

    return (
        <div className="modal-custom">
            <div className="modal-content">
                <div className="modal-header">
                    <Title as="h2" className="modal-title">
                        {title}
                    </Title>
                </div>
                <AppForm initialValues={initialValues} onSubmit={onConfirm} validationSchema={schema} validateOnMount>
                    {fields}
                </AppForm>
            </div>
        </div>
    );
};
