import { filterMethod } from '../filterPreconisation';
import { PackageData } from '../package';
import { SubTheme } from '../theme';

// #region  filter ventilation
/**
 * filtrer les packages inutiles du theme 'Ventilation'. Le theme sera modifé et retourné
 * ATTENTION, àl'heure actuelle le theme 'ventilation' n'existe aps encore. Cette fonction n'a pas été testée.
 * @param audit l'audit sur lequel on préconise
 * @param subTheme le theme dont le type doit être 'Ventilation'. Le paramètre sera modifé !
 * @returns le theme filtré.
 */
export const filterVentilation: filterMethod = async (audit: any, subTheme: SubTheme): Promise<SubTheme> => {
    //console.log('passed in filterVentilation');
    // si pas de package, on laisse tomber,c 'est déjà filtré !
    if (subTheme.packages.length === 0) return subTheme;
    // La règle :
    // si ( pas de ventilation  OU ventilation simple flux OU  Double flux installée avant 2019 )
    //     on prend les ventilations double flux
    // sinon, // double flux récente
    //     on prend rien

    let preconiser = false;

    // On utilise la variable ventilationTypeList du fichier src/pages/energy-system-informations/EnergySystemInformationsEnums.ts

    // si on a une double flux
    const isDoubleFlux = audit.ventilationType && +audit.ventilationType.value === 5;
    // si elle est obsolete.
    // ici, audit.ventilationInstallationDate n'est pas une date, c'est un bool (mal nommé)
    // ventilationInstallationDate == true si l'installation est trop vieille, false si récente.
    // l'installation est liée à l'année 2019, et non 2018 comme écrit dans le code
    // si l'installation est avant 2019, il faut proposer la rénovation =>
    const isObsolete = audit.ventilationInstallationDate && audit.ventilationInstallationDate.value === true;

    //preconiser = !(isDoubleFlux && !isObsolete);
    preconiser = !isDoubleFlux || isObsolete;

    if (!preconiser) {
        // a partir de la on a déjà de l'isolation, et elle est épaisse => on vide le theme.
        subTheme.packages = new Array<PackageData>();
        return subTheme;
    }

    // Si on doit préconiser,
    // on selecitonne le premier (on a déjà éliminé le cas ou len <= 0)

    // TODO : selectionner une VM double flux.
    // Autre choses peut-il être recu  ? (je ne pense pas)
    subTheme.packages[0].applicable = true;

    return subTheme;
};

// #endregion
