import React, { useRef, useEffect, useState } from 'react';
import { HistoryItem, IMG_HEIGHT, IMG_WIDTH, STAGE_HEIGHT, STAGE_WIDTH } from '../../../../../services/tools/konvaTools';
import useImage from 'use-image';
import { Image, Layer, Rect, Stage } from 'react-konva';
import Konva from 'konva';
import { MapProps } from './MapEnums';
import { DrawModule } from '../../../../../components/konvaModules/draw/Draw';
import { TextModule } from '../../../../../components/konvaModules/text/Text';
import { PanelModule } from '../../../../../components/konvaModules/panel/Panel';
import { KonvaEventObject } from 'konva/lib/Node';

const Map: React.FC<MapProps> = ({
    url,
    stageRef,
    layerRef,
    selectedElementIndex,
    setSelectedElementIndex,
    imgPosition,
    canDraw,
    setCanDraw,
    style,
    values,
    setValues,
}) => {
    const [image] = useImage(url, 'anonymous');
    const rectRef = useRef<Konva.Rect>(null);
    const groupRefs = useRef<(Konva.Group | null)[]>([]);
    const trRef = useRef<Konva.Transformer>(null);
    const [canvasSize, setCanvasSize] = useState<{ width: number; height: number }>({ width: STAGE_WIDTH, height: STAGE_HEIGHT });

    // Resize
    const aspectRatio: number = STAGE_HEIGHT / STAGE_WIDTH;
    useEffect(() => {
        const windowWidth = window.innerWidth;
        const handleResize = () => {
            if (windowWidth <= 1024) {
                setCanvasSize({ width: 500, height: 500 * aspectRatio });
            } else {
                setCanvasSize({ width: STAGE_WIDTH, height: STAGE_HEIGHT });
            }
        };

        console.log(windowWidth);
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    groupRefs.current = values.history.map((_, i) => groupRefs.current[i] ?? null);

    // Deselect last selected element
    const checkDeselect = (e: any) => {
        // deselect when clicked on empty area
        if (rectRef.current && e.target === rectRef.current) {
            setSelectedElementIndex(null);
        }
    };

    const handleDragMove = (e: KonvaEventObject<DragEvent>, index: number) => {
        const group = e.target;

        const updatedHistory = values.history.map((item, idx) => {
            if (idx === index) {
                return {
                    ...item,
                    position: { x: group.x(), y: group.y() },
                };
            }
            return item;
        });

        setValues({
            ...values,
            history: updatedHistory,
        });
    };

    const handleClosePolygon = () => {
        const lastHistoryItem = values.history[values.history.length - 1]; // last item from history

        if (lastHistoryItem.drawing && lastHistoryItem.drawing?.dots.length >= 2) {
            // update history item with completed polygon
            const updatedHistoryItem = {
                ...lastHistoryItem,
                drawingElements: {
                    ...lastHistoryItem.drawing,
                    completed: true, // set polygone to completed
                },
            };

            // Update history
            const updatedHistory = [...values.history];
            updatedHistory[updatedHistory.length - 1] = updatedHistoryItem;
            setValues({
                ...values,
                history: updatedHistory,
            });

            // Draw is over
            setCanDraw(false);
        }
    };

    const handleStageClick = (e: KonvaEventObject<MouseEvent>) => {
        if (!canDraw) return;
        const stage = e.target.getStage();
        if (!stage) return;
        const point = stage.getRelativePointerPosition();
        if (!point) return;

        const lastHistoryIndex = values.history.length - 1;
        const currentHistoryItem = values.history[lastHistoryIndex];

        if (!currentHistoryItem || !currentHistoryItem.drawing) return;

        const currentDots = currentHistoryItem.drawing.dots;

        const firstDot = currentDots[0];
        if (currentDots.length > 2) {
            const distance = Math.sqrt(Math.pow(point.x - firstDot.x, 2) + Math.pow(point.y - firstDot.y, 2));

            if (distance <= 5) {
                handleClosePolygon();
                return;
            }
        }

        const updatedDots = [...currentDots, { x: point.x, y: point.y, isHovered: false }];

        const updatedHistoryItem: HistoryItem = {
            ...currentHistoryItem,
            drawing: {
                ...currentHistoryItem.drawing,
                dots: updatedDots,
            },
        };

        // Replace current element in History
        const updatedHistory = [...values.history];
        updatedHistory[lastHistoryIndex] = updatedHistoryItem;

        setValues({
            ...values,
            history: updatedHistory,
        });
    };

    useEffect(() => {
        const transformNode = trRef.current;
        const groupNode = selectedElementIndex !== null ? groupRefs.current[selectedElementIndex] : null;

        if (groupNode && transformNode) {
            transformNode.nodes([groupNode]);
        }
    }, [selectedElementIndex, values.history]);

    return (
        <div style={style}>
            <Stage
                ref={stageRef}
                width={canvasSize.width}
                height={canvasSize.height}
                x={imgPosition.x} // center horizontally
                y={imgPosition.y} // center vertically
                onClick={handleStageClick}
                style={{ border: '2px solid #f2f5fb', borderRadius: '10px' }}
            >
                <Layer ref={layerRef}>
                    <Image image={image} width={IMG_WIDTH} height={IMG_HEIGHT} cornerRadius={8} />
                    <Rect
                        width={canvasSize.width}
                        height={canvasSize.height}
                        cornerRadius={10}
                        onMouseDown={checkDeselect}
                        onTouchStart={checkDeselect}
                        ref={rectRef}
                    />

                    {values.history.map((item, index) => {
                        const fontSize = 14;

                        if (item.deleted) {
                            return null;
                        }

                        if (item.type === 'draw') {
                            return (
                                <React.Fragment key={item.id}>
                                    <DrawModule item={item} canDraw={canDraw} index={index} fontSize={fontSize} setValues={setValues} values={values} />
                                </React.Fragment>
                            );
                        }

                        if (item.type === 'panel') {
                            return (
                                <React.Fragment key={item.id}>
                                    <PanelModule
                                        item={item}
                                        index={index}
                                        setSelectedElementIndex={setSelectedElementIndex}
                                        selectedElementIndex={selectedElementIndex!}
                                        groupRefs={groupRefs}
                                        trRef={trRef}
                                        setValues={setValues}
                                        values={values}
                                    />
                                </React.Fragment>
                            );
                        }

                        return (
                            <React.Fragment key={item.id}>
                                <TextModule
                                    item={item}
                                    index={index}
                                    setSelectedElementIndex={setSelectedElementIndex}
                                    selectedElementIndex={selectedElementIndex!}
                                    handleDragMove={handleDragMove}
                                    groupRefs={groupRefs}
                                    trRef={trRef}
                                    fontSize={fontSize}
                                />
                            </React.Fragment>
                        );
                    })}
                </Layer>
            </Stage>
        </div>
    );
};

export default Map;
