// /!\ LE PREMIER QUI ME TYPE CA TROP SPECIFIQUEMENT, IL FINI AU FEU /!\
// UN COMPONENT EST SUPPOSE ETRE UTILISABLE PARTOUT ET PAS QUE DANS LA PREVISITE PAR EXEMPLE...
import React from 'react';

type PdfModalProps = {
    pdf: string;
    title?: string;
};

export const PdfModal: React.FC<PdfModalProps> = ({ pdf, title }) => {
    return (
        <div className="tabs">
            <div className="tabs-header">
                <button className={`btn btn-tabs active`}>{title ?? 'Pré-visite'}</button>
            </div>
            <div className="tabs-body">
                <iframe src={pdf} title="PDF" width="100%" height="600" id="iframePdf" />
            </div>
        </div>
    );
};
