import React, { useEffect, useState } from 'react';

import './Login.scss';
import '../../assets/style/button.scss';
import logo from '../../assets/images/logo-sogys.svg';

import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { AgentIdentity, getAllConfigFiles, identify, isErrorBody } from '../../services/apiParticulierService';

import { useCookies } from 'react-cookie';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ROUTE_EA_INDEX, ROUTE_MENTIONS_LEGALES } from '../../routing/paths';

// Icons
import { ReactComponent as IconLoader } from '../../assets/icons/loader.svg';

const Login: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { push } = useHistory();
    const date = new Date();
    const year = date.getFullYear();

    const [, setCookie] = useCookies();
    const AlertSwal = withReactContent(Swal);

    const [loading, setLoading] = useState(false);

    document.title = 'SOGYS.APP – AUDIT';
    /**
     * Fonction pour lancer la connexion
     * @param values
     */
    const login = async (values: any) => {
        try {
            setLoading(true);

            let agentIdentity: AgentIdentity = await identify(values?.agentCode, values?.agentPassword);

            const authCookieData = { ...agentIdentity, agentCode: values?.agentCode, agentPassword: values?.agentPassword, campagne: undefined };
            setCookie('Auth', JSON.stringify(authCookieData), { path: '/' });

            // load config files now, just after login success !
            await getAllConfigFiles();

            setLoading(false);
            push(ROUTE_EA_INDEX);
            return false;
        } catch (error) {
            if (error) {
                if (isErrorBody(error) && error.errorId === 'errors.blocked_account') {
                    await AlertSwal.fire({
                        title: 'Compte désactivé',
                        text: error.message,
                        width: 550,
                        confirmButtonText: 'Fermer',
                        focusConfirm: true,
                        customClass: {
                            confirmButton: 'btn btn-primary min-width',
                        },
                    });
                } else {
                    await AlertSwal.fire({
                        title: 'Authentification refusée',
                        text: `Vos identifiants ne sont pas reconnus, merci de vérifier les éléments rentrés.`,
                        width: 550,
                        confirmButtonText: 'Fermer',
                        focusConfirm: true,
                        customClass: {
                            confirmButton: 'btn btn-primary min-width',
                        },
                    });
                }
                setLoading(false);
            }
            return true;
        }
    };

    // Forgotten password modal
    const handleForgottenPassword = () => {
        AlertSwal.fire({
            title: 'Réinitialiser votre mot de passe',
            html: (
                <>
                    Pour réinitialiser votre mot de passe, merci de vous rapprocher de votre administrateur.
                    <br />
                    <br />
                    Celui-ci est à votre disposition du Lundi au Vendredi de 9 à 20h au <a href="tel:0826046046">0826 046 046</a> ou par email a&nbsp;
                    <a href="mailto:contact@sogys.fr">contact@sogys.fr</a>.
                </>
            ),
            icon: 'info',
            width: 550,
            confirmButtonText: 'Fermer',
            focusConfirm: true,
            customClass: {
                confirmButton: 'btn btn-primary min-width',
            },
        });
    };

    const schema = Yup.object({
        agentCode: Yup.string().min(4).required().label('agentCode'),
        agentPassword: Yup.string().required('Le mot de passe est obligatoire').label('agentPassword'),
    });

    return (
        <div className="container-form">
            <div className="form-sign-in">
                <Formik
                    onSubmit={(values, formikHelpers) => {
                        login(values).then((reset: boolean) => {
                            if (reset) {
                                formikHelpers.resetForm();
                            }
                        });
                    }}
                    initialValues={{ agentCode: '', agentPassword: '' }}
                    validationSchema={schema}
                >
                    {({ values, handleChange, handleSubmit }) => {
                        return (
                            <form onSubmit={handleSubmit} className="w-100">
                                <div className="container-logo mb-5">
                                    <img src={logo} alt="" width="auto" height="70" />
                                    <h1 className="sr-only">
                                        Audit
                                        <br />
                                        Énergétique
                                    </h1>
                                </div>

                                <label htmlFor="agentCode">
                                    Code agent<sup>*</sup>
                                </label>
                                <input
                                    type="text"
                                    id="agentCode"
                                    className="form-control mb-4"
                                    placeholder="Code agent"
                                    required
                                    autoFocus
                                    autoComplete="chrome-off"
                                    onChange={handleChange}
                                    value={values.agentCode || ''}
                                />

                                <label htmlFor="agentPassword">
                                    Mot de passe<sup>*</sup>
                                </label>
                                <input
                                    type="password"
                                    id="agentPassword"
                                    className="form-control mb-5"
                                    placeholder="Mot de passe"
                                    autoComplete="chrome-off"
                                    onChange={handleChange}
                                    value={values.agentPassword || ''}
                                    required
                                />
                                <div className="text-center mb-3">
                                    <button className="btn btn-primary min-width position-relative" type="submit" disabled={loading}>
                                        <span style={loading ? { opacity: '0' } : { opacity: '1' }}>Connexion</span>
                                        {loading && (
                                            <div className="login-loader">
                                                <IconLoader width={38} height={38} />
                                            </div>
                                        )}
                                    </button>
                                </div>

                                <div className="text-center">
                                    <button type="button" className="btn btn-password" onClick={handleForgottenPassword}>
                                        Mot de passe oublié
                                    </button>
                                </div>
                            </form>
                        );
                    }}
                </Formik>

                <div className="legal absolute">
                    Tous droits réservés - &copy;{' '}
                    <a href="http://www.sogys.fr" target="_blank" rel="noreferrer">
                        SOGYS
                    </a>{' '}
                    {year === 2021 ? '2021' : `2021 - ${year}`} -{' '}
                    <Link to={ROUTE_MENTIONS_LEGALES} target="_blank">
                        Mentions légales
                    </Link>
                </div>
            </div>
            <div className="login-background" aria-hidden="true"></div>
        </div>
    );
};

export default Login;
