import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { getAllConfigFiles, postJsonData } from '../../services/apiParticulierService';
import { Formik, Form } from 'formik';
import { useCookies } from 'react-cookie';
import ActionLogementSchema from './services/schema';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// Style
import './action-logement.scss';
import { ROUTE_MENTIONS_LEGALES } from '../../routing/paths';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const ActionLogement: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [, setCookie] = useCookies();

    const { push } = useHistory();
    const AlertSwal = withReactContent(Swal);

    const date = new Date();
    const year = date.getFullYear();

    let query = useQuery();
    const creds = { agentCode: query.get('agentCode'), password: query.get('password') };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    document.title = 'SOGYS.APP - AL';

    const actionLogement = async (values: any) => {
        try {
            setLoading(true);

            // TODO use api.
            let response = await postJsonData(`identify?${query}`);

            if (!response) {
                setLoading(false);
                throw new Error();
            }

            if (response.status) {
                await AlertSwal.fire(loginError);
                setLoading(false);
                return true;
            } else {
                response.agentCode = creds.agentCode;
                response.agentPassword = creds.password;
                response.campagne = 'action_logement';
                setCookie('Auth', JSON.stringify(response), { path: '/' });
                // load config files now, just after login success !
                await getAllConfigFiles();
                setLoading(false);
                push('/audit', { from: 'action-logement' });
                return false;
            }
        } catch (error) {
            if (error) {
                await AlertSwal.fire<any>(loginError);
                setLoading(false);
            }
        }
    };

    const loginError: SweetAlertOptions<any> = {
        title: 'Impossible d’accéder à l’audit',
        text: `Votre url de connexion n’est pas reconnue. Merci de vous rapprocher de la personne vous l’ayant communiqué.`,
        icon: 'error',
        width: 550,
        confirmButtonText: 'Fermer',
        focusConfirm: true,
        customClass: {
            confirmButton: 'btn btn-primary min-width',
        },
    };

    return (
        <>
            <div className="container-form al-container-form">
                <div className="form-sign-in al">
                    <div className="container-logo">
                        <div className="al-pre-sub">Préinscription</div>
                        <div className="al-pre-title">Votre Préinscription au programme de rénovation énergétique d’ActionLogement</div>
                    </div>
                    <Formik
                        onSubmit={(values, formikHelpers) => {
                            actionLogement(values).then((reset: boolean | undefined) => {
                                if (reset) {
                                    formikHelpers.resetForm();
                                }
                            });
                        }}
                        initialValues={creds}
                        validationSchema={ActionLogementSchema}
                    >
                        {({ values }) => {
                            return (
                                <Form className="w-100">
                                    <div className="text-center">
                                        <h2 className="al-title">Bonjour</h2>

                                        <p className="al-text">
                                            Bienvenue sur votre espace de pré-inscription au programme d’aides à la rénovation énergétique. 30 mn suffiront à
                                            remplir les 8 étapes de votre dossier.
                                            <br />
                                            En cas de besoin d’aide lors du remplissage, une hotline est à votre disposition,{' '}
                                            <span className="font-weight-bold">7 jours sur 7</span> de <span className="font-weight-bold">8h à 22h</span> au{' '}
                                            <a href="tel:0826046046" className="al-link">
                                                0826 046 046
                                            </a>
                                            .
                                        </p>

                                        <p className="al-text">
                                            Vous recevrez des informations par mail, sur l’état d’avancement de votre dossier, au fur et à mesure de son
                                            évolution.
                                        </p>

                                        <button className="btn btn-primary min-width" type="submit" disabled={loading || !values?.agentCode}>
                                            Démarrer ma simulation
                                        </button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>

                    <div className="legal absolute">
                        Tous droits réservés - &copy;{' '}
                        <a href="http://www.sogys.fr" target="_blank" rel="noreferrer">
                            SOGYS
                        </a>{' '}
                        {year === 2021 ? '2021' : `2021 - ${year}`} -{' '}
                        <Link to={ROUTE_MENTIONS_LEGALES} target="_blank">
                            Mentions légales
                        </Link>
                    </div>

                    <div className="flex-trick"></div>
                </div>

                <div className="al-pave">
                    <div className="al-card">
                        <div className="al-pre-title">Votre Préinscription au programme de rénovation énergétique d’ActionLogement</div>

                        <p className="mt-3">
                            En 2020 Action Logement met en place une enveloppe d’aide de 1 milliards d’euro, grâce aux cotisations au 1% patronal de votre
                            entreprise, qui ont permis à 60 000 foyers de réaliser des travaux de rénovation énergétique sans avoir à débourser un seul €.
                        </p>

                        <p>
                            Ces 60 000 foyers, ont donc bénéficié d’une subvention allant jusqu’à 20 000 €, par foyer fiscal, pour réaliser leurs travaux
                            d’amélioration de l’habitat.(Isolation Thermique des murs, changement de mode de chauffage, au moyen d’une énergie renouvelable,
                            pompe à chaleur Air-Eau/Air-Air, et pose d’équipement d’eau chaude sanitaire).
                        </p>

                        <p>
                            Face à ce succès, l’État et les partenaires sociaux, accélèrent la mise à disposition de sommes considérables, pour répondre aux
                            enjeux climatiques et énergétiques.
                        </p>

                        <p>
                            Nous vous invitons à constituer d’ores et déjà votre dossier, de manière à bénéficier prioritairement de ces aides, et ainsi vous
                            garantir sa finalité.
                        </p>

                        <p>
                            Depuis l’arrêt de l’aide exceptionnelle, du groupe Action Logement en 2020, Nos partenaires ont permis à plus de 6000 foyers, de
                            bénéficier de subventions complémentaires.
                        </p>

                        <p>
                            « Réaliser une rénovation énergétique de sa maison, c’est réduire ses factures d’énergies de 30 à 75 %, augmenter son pouvoir
                            d’achat, et valoriser son bien immobilier. »
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ActionLogement;
