import * as Yup from 'yup';
import * as api from '../../../../../services/apiParticulierService';
import { useRecoilValue } from 'recoil';
import { sponsorState } from '../../../../../services/Recoil/Atom/SponsorMode.atom';

export const GetValidationSchema = () => {
    const sponsor = useRecoilValue(sponsorState);

    return Yup.object().shape({
        civility: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.mixed<api.Civility>().oneOf(Object.values(api.civilityNames)),
            })
            .required('Le champs civilité est obligatoire'),
        lastname: Yup.string().required('Le nom est obligatoire'),
        firstname: Yup.string().required('Le prénom est obligatoire'),
        email: Yup.string().email('Le courriel doit être de type nom@domaine.com').required(`L'email est obligatoire`),
        phoneNumber: Yup.string()
            .nullable()
            .matches(/^((06)|(07))[0-9]{8}$/g, 'Le numéro doit inclure 10 chiffres et commencer par 06 ou 07')
            .typeError('Le format est erroné')
            .required('Le numéro de téléphone est obligatoire'),
        address: Yup.string().required("L'adresse est obligatoire"),
        zipcode: Yup.string().nullable().required('Le code postal est obligatoire'),
        city: Yup.string().required('La ville est obligatoire'),
        identityDocument1: sponsor === 'Pyramidal' ? Yup.string().nullable().notRequired() : Yup.string().nullable().required('Le document est obligatoire'),
    });
};
