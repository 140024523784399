import * as Yup from 'yup';

const schemaAuvent = () => {
  return Yup.object().shape({
    texture: Yup.object()
      .test('is-valid-texture', '', (value) => {
        if (value && typeof value === 'object' && value.label && value.value) {
          return true;
        }

        return false;
      })
      .required()
  });
};

export default schemaAuvent;
