import React, { useState, useEffect } from 'react';
import * as apiFlow from '../../../services/apiFlowService';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { Back } from '../../../components/Back/Back';
import { PdfModal } from '../../../components/PdfModal/PdfModal';
import { useHistory } from 'react-router';

// Icon
import { ReactComponent as IconEye } from '../../../assets/icons/espace-agent/icon-eye.svg';

const PagePDFOrder: React.FC = () => {
    const [documents, setDocuments] = useState<apiFlow.SignedDocs>({});
    const flowId: string = localStorage.getItem('flowId') ?? '';
    const AlertSwal = withReactContent(Swal);
    const { push } = useHistory();

    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const apiDocuments = await apiFlow.getFlowDocuments(flowId);
                setDocuments(apiDocuments);
            } catch (error) {
                console.error(error);
                AlertSwal.fire({
                    title: '',
                    html: <p>Vos documents sont en cours de génération et seront accessibles dans une dizaine de minutes.</p>,
                    confirmButtonText: 'Fermer',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'btn btn-continue min-width',
                    },
                }).then((result) => {
                    if (result.isConfirmed) {
                        push(`/espace-agent/list-audit/${flowId}`);
                    }
                });
            }
        };

        fetchDocuments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flowId]);

    const handleModal = (title: string, pdf: string) => {
        AlertSwal.fire({
            title: '',
            html: <PdfModal pdf={pdf} title={title} />,
            width: 900,
            confirmButtonText: 'Fermer',
            customClass: {
                confirmButton: 'btn btn-continue min-width',
            },
        });
    };

    return (
        <div className="container py-5">
            <Back title="Retour" url={`/espace-agent/list-audit/${flowId}`} />

            <div className="container-creation py-4 px-4 mt-5">
                <div className="row">
                    <h2>Liste des documents de la commande</h2>
                    <p>Vous pouvez charger les documents relatifs à cette commande en les sélectionnant ci-dessous</p>

                    {Object.entries(documents).map(([key, value], index) => {
                        const title = key.replaceAll('_', ' ');

                        return (
                            <div className="col col-md-3 mb-4" key={index}>
                                <button type="button" className="btn btn-orange btn-orange--icon w-100" onClick={() => handleModal(title, value)}>
                                    <IconEye fill="#FFF" />
                                    {title}
                                </button>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default PagePDFOrder;
