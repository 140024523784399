import { ROUTE_EA_COMPTE, ROUTE_EA_FORMATION, ROUTE_EA_LIST, ROUTE_EA_MARKETING, ROUTE_EA_NETWORK } from '../../routing/paths';

type Cards = {
    title: string;
    url: string;
    icon: string;
    disabled?: boolean;
};

export const AgentCards: Cards[] = [
    {
        title: 'Mes études',
        url: ROUTE_EA_LIST,
        icon: 'icon-study',
    },
    {
        title: 'Mon compte',
        url: ROUTE_EA_COMPTE,
        icon: 'icon-account',
    },
    {
        title: 'Mon réseau',
        url: ROUTE_EA_NETWORK,
        icon: 'icon-network',
    },
    {
        title: 'Outil marketing',
        url: ROUTE_EA_MARKETING,
        icon: 'icon-marketing',
    },
    {
        title: 'Espace formation',
        url: ROUTE_EA_FORMATION,
        icon: 'icon-information',
    },
];
