import React, { CSSProperties } from 'react';

type TLabel = {
    htmlFor: string;
    labelTitle: string | undefined;
    isRequired?: boolean;
    hidden?: boolean;
    style?: CSSProperties;
    customClass?: string;
    infoText?: string | JSX.Element | undefined;
    inline?: boolean;
};

export const AppFormLabel: React.FC<TLabel> = ({ htmlFor, labelTitle, isRequired, hidden = false, style, customClass, infoText = undefined, inline }) => {
    return (
        <label htmlFor={htmlFor} style={style} className={customClass} data-hidden={hidden}>
            <span>
                {inline ? (
                    <>
                        {labelTitle}
                        {isRequired && <sup>*</sup>}:
                    </>
                ) : (
                    <>
                        {labelTitle}
                        {isRequired && <sup>*</sup>}
                    </>
                )}
                {/* {labelTitle}
                {isRequired && <sup>*</sup>} */}
                {infoText !== undefined && <span className="fw-300 info-text">&nbsp;{infoText}</span>}
            </span>
        </label>
    );
};
