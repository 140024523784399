import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    length: Yup.string().required('La taille de la pièce est obligatoire'),
    width: Yup.string().required('La largeur est obligatoire'),
    height: Yup.string().required('La hauteur est obligatoire'),
    heatedRoom: Yup.boolean().required('Pièce chauffée est obligatoire'),
    airExtraction: Yup.boolean()
        .notRequired()
        .when(['length', 'width', 'height', 'heatedRoom'], {
            is: (length: string, width: string, height: string, heatedRoom: boolean) =>
                Number(length) * Number(width) * Number(height) < 18 || heatedRoom === true,
            then: Yup.boolean().required("L'extraction d'air est obligatoire"),
        }),
    pressureReducer: Yup.boolean().required('Réducteur de pression est obligatoire'),
    soilType: Yup.string().required('Le type de sol existant est obligatoire'),
    paving: Yup.boolean()
        .notRequired()
        .when('soilType', {
            is: (value: string) => value === 'terre' || value === 'autre',
            then: Yup.boolean().required('Dallage à prévoir est obligatoire'),
        }),
    detachedPart: Yup.boolean().required('Pièce détalonnée est obligatoire'),
    roomTankInstalledPicture: Yup.string().required('La photo du local ou sera installé le ballon est obligatoire'),
    doorwayPicture: Yup.string().required('La photo du du passage de la porte est obligatoire'),
    outsideWallPicture: Yup.string().required('La photo du mur extérieur si local <20m² est obligatoire'),
});
