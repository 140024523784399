import React from 'react';
import * as storageService from '../../services/localStorageService';

import './finalisation.scss';

const FinalisationAL: React.FC = () => {
    const clientNom = storageService.getAudit().step1.userLastname.value;
    const clientPrenom = storageService.getAudit().step1.userFirstname.value;

    const companyLogo = storageService.getCurrentAgent().companyLogo;

    return (
        <div className="container">
            <h1 className="al-pre-sub mt-5 mb-0 text-center">Préinscription</h1>
            <div className="al-pre-title text-center mb-4">Votre Préinscription au programme de rénovation énergétique d’ActionLogement</div>

            <p>
                <strong>
                    Félicitations {clientPrenom} {clientNom}, votre préinscription au programme Action Logement a bien été pris en compte.
                </strong>
            </p>

            <p>
                Dès la réouverture du programme Action Logement votre AMO (Assistant à Maître d'Ouvrage) prendra contact avec vous afin de programmer, dans le
                cadre de votre audit énergétique, une visite de votre habitation.
            </p>

            <p>Nos équipes ne manquerons pas de revenir vers vous au fur et à mesure de l'évolution et la réouverture du Programme Action Logement.</p>

            <p>
                Pour toute question relative à votre dossier vous pouvez contacter nos services à l'adresse mail suivante : &nbsp;
                <a href="mailto:contact@sogys.fr">contact@sogys.fr</a>
            </p>

            <p>À très bientôt</p>

            <p>L'équipe Sogys</p>

            <div className="signature-logos mt-5">
                <div className="main-logo">
                    <img src={companyLogo} alt="Logicoll" aira-hidden="true" />
                </div>
            </div>
        </div>
    );
};

export default FinalisationAL;
