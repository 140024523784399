import React from 'react';
import { currencyFormat, LabelledString } from '../../../../services/tools/TypeHelper';
import { cdvQuantityOptions, initialValues, MarketingOrderProduct, Product } from '../MarketingEnums';
import { Field, useFormikContext } from 'formik';
import AppFormSelectField from '../../../../components/Forms/AppFormSelectField';
import AppFormField from '../../../../components/Forms/AppFormField';

// Images
import ImgCdv from '../../../../assets/images/espace-agent/shop/carte-de-visite.png';

type CarteDeVisiteProps = {
    handleQuantityChange: (product: Product, selectedOption: LabelledString) => void;
};

export const CarteDeVisite: React.FC<CarteDeVisiteProps> = ({ handleQuantityChange }) => {
    const { values } = useFormikContext<MarketingOrderProduct>();
    const cdvDisabled = !values['Cartes de visite']?.quantity || values['Cartes de visite']?.quantity.value === '0';

    return (
        <>
            <div className="row align-items-center mb-3 px-4">
                <hr />
                <div className="col-6">
                    <h2 className="mb-0">Cartes de visite SOGYS</h2>
                </div>
            </div>

            <div className="row align-items-center px-4">
                <div className="col-6">{/* Leave empty */}</div>
                <div className="col-6">
                    <div className="row justify-content-end">
                        <p className="mb-4 col-9 business-card--info">Remplissez les champs visibles sur votre carte de visite</p>
                    </div>
                </div>
                <div className="col-6">
                    <div className="business-card">
                        <div className="business-card--image">
                            <img src={ImgCdv} alt="Cartes de visite" />
                        </div>

                        <div className="business-card--price">
                            {currencyFormat(initialValues['Cartes de visite']?.price ?? 0, 2)} HT<span>Les 250 ex</span>
                        </div>
                    </div>

                    <ul className="business-card--features">
                        <li>Format 85x54mm</li>
                        <li>Papier 350gr</li>
                        <li>Impression quadri R°/V°</li>
                        <li>Pelliculage brillant R*/V°</li>
                    </ul>
                </div>
                <div className="col-6">
                    <Field
                        labelName="Quantité"
                        name="Cartes de visite.quantity"
                        component={AppFormSelectField}
                        options={cdvQuantityOptions}
                        onChange={(selectedOption: LabelledString) => handleQuantityChange('Cartes de visite', selectedOption)}
                        placeholder="Quantité"
                        cdv
                        customLabelClass="col-sm-3"
                        isRequired
                    />
                    <Field
                        labelName="Nom"
                        name="Cartes de visite.customization.name"
                        component={AppFormField}
                        disabled={cdvDisabled}
                        cdv
                        className={'form-control'}
                        customLabelClass="col-sm-3"
                        isRequired
                        placeholder="Nom"
                        data-required={!cdvDisabled}
                    />
                    <Field
                        labelName="Prénom"
                        name="Cartes de visite.customization.firstname"
                        component={AppFormField}
                        disabled={cdvDisabled}
                        cdv
                        className={'form-control'}
                        customLabelClass="col-sm-3"
                        isRequired
                        placeholder="Prénom"
                        data-required={!cdvDisabled}
                    />
                    <Field
                        labelName="Téléphone fixe"
                        name="Cartes de visite.customization.phone"
                        component={AppFormField}
                        disabled={cdvDisabled}
                        cdv
                        className={'form-control'}
                        customLabelClass="col-sm-3"
                        placeholder="Téléphone fixe"
                        onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            const charCode = e.which ? e.which : e.keyCode;
                            if (charCode < 48 || charCode > 57) {
                                e.preventDefault();
                            }
                        }}
                    />
                    <Field
                        labelName="Téléphone Mobile"
                        name="Cartes de visite.customization.mobile"
                        component={AppFormField}
                        disabled={cdvDisabled}
                        cdv
                        className={'form-control'}
                        customLabelClass="col-sm-3"
                        isRequired
                        placeholder="Téléphone Mobile"
                        data-required={!cdvDisabled}
                        onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            const charCode = e.which ? e.which : e.keyCode;
                            if (charCode < 48 || charCode > 57) {
                                e.preventDefault();
                            }
                        }}
                    />
                    <Field
                        type="email"
                        labelName="Email"
                        name="Cartes de visite.customization.email"
                        component={AppFormField}
                        disabled={cdvDisabled}
                        cdv
                        className={'form-control'}
                        customLabelClass="col-sm-3"
                        isRequired
                        placeholder="Email"
                        data-required={!cdvDisabled}
                    />
                    <Field
                        labelName="Adresse"
                        name="Cartes de visite.customization.address"
                        component={AppFormField}
                        disabled={cdvDisabled}
                        cdv
                        className={'form-control'}
                        customLabelClass="col-sm-3"
                        isRequired
                        placeholder="Adresse"
                        data-required={!cdvDisabled}
                    />
                    <Field
                        labelName="Ville"
                        name="Cartes de visite.customization.city"
                        component={AppFormField}
                        disabled={cdvDisabled}
                        cdv
                        className={'form-control'}
                        customLabelClass="col-sm-3"
                        isRequired
                        placeholder="Ville"
                        data-required={!cdvDisabled}
                    />
                    <Field
                        labelName="Code postal"
                        name="Cartes de visite.customization.postalCode"
                        component={AppFormField}
                        disabled={cdvDisabled}
                        cdv
                        className={'form-control'}
                        customLabelClass="col-sm-3"
                        isRequired
                        placeholder="Code postal"
                        data-required={!cdvDisabled}
                    />
                </div>
            </div>
        </>
    );
};
