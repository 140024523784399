import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ROUTE_PV_FIN_DE_COMMANDE } from '../../../routing/paths';
import { useRecoilValue } from 'recoil';

import { contextPackagesSelector, nextPackageRouteSelector } from '../../../services/Recoil/Atom/PackageContext.atom';

// Icons
import { ReactComponent as IconBDC } from '../../../assets/icons/simulator/icon-recapitulatif.svg';
import { BonDeCommandeContexte } from '../../audit-simulator/parcoursBdcCommmons';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as storageService from '../../../services/localStorageService';

const Validation: React.FC = () => {
    const contextPacks = useRecoilValue(contextPackagesSelector);
    const nextRoute = useRecoilValue(nextPackageRouteSelector);
    const AlertSwal = withReactContent(Swal);
    const context = useLocation<BonDeCommandeContexte>().state;
    const simdata = storageService.getSim();
    const paiementAvecFinancement: boolean | undefined = simdata.paiementAvecFinancement?.value;
    const funding = storageService.getFunding();

    // ouvrir l'url financement chez financo si présent
    useEffect(() => {
        if (!paiementAvecFinancement || !context?.openModal) return;
        // si le context n'est pas rempli, il y a eu une erreur Financo, l'agent devra créer l'étude financo à la main
        const url = funding?.fundingUrl ?? process.env.REACT_APP_FINANCO_PARTENAIRE;
        const content =
            "Vous êtes sur le point d'ouvrir un nouvel onglet pour finaliser la demande de prêt.\n\nLa page vous permettant de poursuivre votre commande restera accessible depuis un des onglets de votre navigateur.";

        AlertSwal.fire({
            title: 'Demande de financement',
            html: (
                <>
                    <p style={{ whiteSpace: 'pre-wrap' }}>{content}</p>
                    <div className="swal2-actions">
                        <a href={url} className="btn btn-continue min-width" target="_blank" rel="noreferrer" onClick={() => AlertSwal.close()}>
                            Onglet Financement
                        </a>
                    </div>
                </>
            ),
            width: 600,
            showCancelButton: false,
            showConfirmButton: false,
            confirmButtonText: 'Onglet Financement',
            allowOutsideClick: false,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paiementAvecFinancement, context]);

    return (
        <div className="container">
            <h1 className="main-title-mini">Validation</h1>
            <div className="card card-audit-simulator bdc mb-5">
                <div className="card-header">
                    <IconBDC fill="white" width={60} height={60} />
                    <h2>Pré visite technique</h2>
                </div>
                <div className="card-body">
                    <h4 className="card-body--sub-title text-center mb-4">
                        Afin d'accélérer le processus de pose, vous pouvez réaliser votre pré visite technique
                        <br />
                        nécessaire à la commande des équipements et aux informations à transmettre aux installeurs.
                    </h4>

                    <div className="row justify-content-center mb-4">
                        <div className="col-12 col-lg-10">
                            <div className="custom-textarea">
                                <ul>{contextPacks && contextPacks.map((pack, index) => <li key={index}>{pack.title}</li>)}</ul>
                            </div>
                        </div>
                    </div>

                    <p className="mb-5 text-center">
                        Votre client recevra, après l'installation et acquittement des factures, un avoir de <strong>120 €</strong> sous forme de chèque.
                    </p>

                    <div className="btn-grp justify-content-center">
                        <Link to={ROUTE_PV_FIN_DE_COMMANDE} className={`btn btn-retour`}>
                            Non
                        </Link>

                        <Link to={nextRoute} className={`btn btn-continue`}>
                            Oui
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Validation;
