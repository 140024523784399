import React from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { PropertyType } from '../../../services/localStorageService';
import * as storageService from '../../../services/localStorageService';

// Icons
import { ReactComponent as IconPompeAChaleur } from '../../../assets/icons/synthese/icon-pompeachaleur.svg';
import { ReactComponent as IconCompteur } from '../../../assets/icons/synthese/icon-compteur.svg';
import { ROUTE_SIM_RECOMMANDATION } from '../../../routing/paths';

const PompeAChaleur: React.FC = () => {
    const initialValues = {
        pompeAChaleur: storageService.checkPropertyExistThenCreateOrRenderSimulator('pompeAChaleur', `Pompe à chaleur`, PropertyType['number']),
    };

    const validationSchema = Yup.object({
        pompeAChaleur: Yup.string().required("La valeur de l'ampèrage est obligatoire"),
    });

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={() => {}}>
            {({ values, errors, touched, handleBlur, setValues }) => {
                return (
                    <div className="container container-audit">
                        <h1 className="main-title-mini">Informations liées à votre installation</h1>

                        <div className="card card-audit-simulator pompe-a-chaleur mb-5">
                            <div className="card-header">
                                <IconPompeAChaleur fill="#FFFFFF" />
                                <h2>Pompe à chaleur</h2>
                            </div>

                            <div className="card-body">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-3 col-xxl-3">
                                        <div className="form-group">
                                            <IconCompteur className="immondice" />
                                            <label htmlFor="pompeValue">
                                                Indiquer l'ampèrage au compteur <sup>*</sup>
                                            </label>
                                            <input
                                                type="number"
                                                id="pompeValue"
                                                onBlur={handleBlur}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    setValues({
                                                        ...values,
                                                        pompeAChaleur: event.target.value,
                                                    });

                                                    storageService.setSimValue('pompeAChaleur', event.target.value);
                                                }}
                                                value={values.pompeAChaleur || ''}
                                                className={`form-control ${values.pompeAChaleur ? ' filled' : ''} ${
                                                    touched.pompeAChaleur && errors.pompeAChaleur ? ' invalid' : ''
                                                }`}
                                            />
                                            <span className="value font-weight-bold">A</span>
                                            {errors.pompeAChaleur && <span className="invalid-feedback">{errors.pompeAChaleur}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h3 className="px-5 mt-5 pre-footer-title">Où trouver mon ampérage ?</h3>
                            <div className="card-footer px-5 py-4">
                                <ul>
                                    <li>Compteur Linky : appuyer sur le bouton de défilement jusqu'à l'affichage de puissance du compteur</li>
                                    <li>
                                        Compteur électronique : appuyer 3 fois sur le bouton "Sélection" afin d'afficher la puissance du compteur en ampère (A)
                                        et en kVA
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="btn-grp justify-content-end">
                            <Link to={ROUTE_SIM_RECOMMANDATION} className="btn btn-retour">
                                Retour
                            </Link>
                            <Link
                                to="#!"
                                className={`btn btn-continue ${Object.entries(errors).length > 0 || values.pompeAChaleur?.length === 0 ? 'disabled' : ''}`}
                            >
                                Continuer
                            </Link>
                        </div>
                    </div>
                );
            }}
        </Formik>
    );
};
export default PompeAChaleur;
