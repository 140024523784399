import React from 'react';

interface FormRowProps {
    label: string;
    value: string | number | undefined;
}

export const FormRow: React.FC<FormRowProps> = ({ label, value }) => {
    return (
        <div className="form-group align-items-center account mb-2">
            <label className="col-form-label">{label} :</label>
            <div className="position-relative w-100">
                <span className="result">{value}</span>
            </div>
        </div>
    );
};
