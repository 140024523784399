import * as Yup from 'yup';

export const validationSchema = Yup.object().shape(
    {
        // Partie 1
        paiementAuComptant: Yup.boolean()
            .notRequired()
            .when('paiementAvecFinancement', {
                is: false,
                then: Yup.boolean().oneOf([true], `Requis`),
            }),
        acompte: Yup.number()
            .notRequired()
            .when('paiementAuComptant', {
                is: true,
                then: Yup.number().min(0).required(`Le champs Acompte est obligatoire`),
            }),
        soldeAInstallation: Yup.number()
            .notRequired()
            .when('paiementAuComptant', {
                is: true,
                then: Yup.number().min(0).required(`Le champs solde à l'installation est obligatoire`),
            }),
        installationDesProduits: Yup.string()
            .notRequired()
            .when('paiementAuComptant', {
                is: true,
                then: Yup.string().required(`Le champs installation des produits est obligatoire`),
            }),

        // Partie 2
        paiementAvecFinancement: Yup.boolean()
            .notRequired()
            .when('paiementAuComptant', {
                is: false,
                then: Yup.boolean().oneOf([true], `Required2`),
            }),
        choixDeOrganismeFinancier: Yup.string()
            .notRequired()
            .when('paiementAvecFinancement', {
                is: true,
                then: Yup.string().required(`Le champs choix de l'organisme financier est obligatoire`),
            }),
        birthDate: Yup.string()
            .nullable()
            .notRequired()
            .when('paiementAvecFinancement', {
                is: true,
                then: Yup.string().required("Le champs Date de naissance de l'emprunteur est obligatoire"),
            }),
        deposit: Yup.number()
            .notRequired()
            .when('paiementAvecFinancement', {
                is: true,
                then: Yup.number().min(0, "Le montant de l'acompte ne peut être inférieur à 0").required("L'acompte est obligatoire"),
            }),
        paymentFrequency: Yup.string()
            .notRequired()
            .when('paiementAvecFinancement', {
                is: true,
                then: Yup.string().required('Nombre de mensualités est obligatoire'),
            }),
        defermentPeriod: Yup.string()
            .notRequired()
            .when('paiementAvecFinancement', {
                is: true,
                then: Yup.string().required('Période de différé est obligatoire'),
            }),
        simulation: Yup.boolean()
            .notRequired()
            .when('paiementAvecFinancement', {
                is: true,
                then: Yup.boolean().oneOf([true], '').required('Vous devez lancer une simulation'),
            }),
    },
    [['paiementAuComptant', 'paiementAvecFinancement']]
);
