import React from 'react';

import { Link, useHistory } from 'react-router-dom';

import './TopBar.scss';
import '../../assets/style/button.scss';

import defaultLogo from '../../assets/images/logo-sogys.svg';
import * as storageService from '../../services/localStorageService';

import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import Modals from './modals/modals';
import { postJsonData } from '../../services/apiParticulierService';
import { ROUTE_LOGIN, ROUTE_EA_INDEX } from '../../routing/paths';
import { isLocal } from '../../services/tools/generalTools';

const TopBar: React.FC = () => {
    const { push } = useHistory();

    const AlertSwal = withReactContent(Swal);

    const agent = storageService.getCurrentAgent();
    const campagneFromAudit = storageService.getAuditCampagne();

    const deleteCookie = (name: string) => {
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    };

    /**
     * Fonction pour déconnecter l'agent
     */
    const disconnect = async () => {
        await AlertSwal.fire({
            title: `Déconnexion`,
            html: <p>Merci de confirmer votre demande de déconnexion.</p>,
            width: 550,
            confirmButtonText: 'Me déconnecter',
            cancelButtonText: 'Annuler',
            showCancelButton: true,
            focusCancel: true,
            reverseButtons: true,
            customClass: {
                confirmButton: 'btn btn-red min-width',
                cancelButton: 'btn btn-secondary min-width',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                // reset data
                localStorage.removeItem('flowId');
                localStorage.removeItem('auditType');
                localStorage.removeItem('auditId');
                localStorage.removeItem('devisId');
                localStorage.removeItem('previsitDocUrl');
                localStorage.removeItem('installationDate');
                localStorage.removeItem('flowState');
                storageService.resetRecoAppointment();
                // Reset Audit expert & thermique
                storageService.resetAudit();
                // Reset simulator
                storageService.resetSim();
                // Reset Pre Visit
                storageService.resetAudit();
                // Lancement déconnexion
                // Va supprimer le cookie de session nommé io.wassa.sogys.api.v2
                postJsonData(`logout`);
                // Suppression de tous les cookies
                deleteCookie('Auth');

                push(ROUTE_LOGIN);
            }
        });
    };

    // Ajouter l'url dans cet Array pour que le bouton 'Réinitialiser' apparaisse dans le header
    // const allowedResetUrls: string[] = [ROUTE_AUDIT, ROUTE_EA_INDEX, ROUTE_EA_CREATION];

    const showTopBar = agent.agentCode === process.env.REACT_APP_ICOLL_NAGIOS_AGENT_CODE || isLocal();
    return (
        <div className="navbar shadow-sm fixed-top bg-white">
            {showTopBar && (
                <span className="campagne">
                    <strong>Campagne</strong>: {campagneFromAudit}
                </span>
            )}
            <div className="container">
                {agent.companyLogo ? (
                    <Link to={ROUTE_EA_INDEX} className="container-logo">
                        <img src={agent.companyLogo} alt={agent.companyName ?? ''} />
                    </Link>
                ) : (
                    <Link to={ROUTE_EA_INDEX} className="container-logo">
                        <img src={defaultLogo} alt="Audit Énergétique" width="auto" height="60" />
                        <h1 className="ms-2 sr-only">
                            Audit
                            <br />
                            Énergétique
                        </h1>
                    </Link>
                )}

                {!agent.campagne && <Modals modal={AlertSwal} />}

                {!agent.campagne && (
                    <div className="float-end">
                        <Link to={ROUTE_EA_INDEX} className="btn btn-header icon-profile">
                            <span>Mon espace</span>
                        </Link>
                        {/* {allowedResetUrls.includes(location.pathname.replace(/\/$/, '')) && (
                            <button className="btn btn-header icon-reload" onClick={() => resetAuditTemplate()}>
                                <span>Réinitialiser les données</span>
                            </button>
                        )} */}
                        <button className="btn btn-header icon-disconnect" onClick={disconnect}>
                            <span>Déconnexion</span>
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TopBar;
