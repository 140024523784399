import ImagePhotovoltaique from '../../../assets/images/espace-agent/formation/photovoltaique.jpg';
import ImageProcessSogys from '../../../assets/images/espace-agent/formation/process-sogys.jpg';
import ImageContextReglementaire from '../../../assets/images/espace-agent/formation/contexte-reglementaire.jpg';
import ImageTransfertDeContexte from '../../../assets/images/espace-agent/formation/transfert-de-contexte.jpg';
import ImageDemandeDePret from '../../../assets/images/espace-agent/formation/demande-de-pret.jpg';
import ImageSogysReward from '../../../assets/images/espace-agent/formation/sogys-reward.png';
import ImageSogys from '../../../assets/images/espace-agent/formation/sogys.png';
import ImageAudit from '../../../assets/images/espace-agent/formation/realiser-un-audit.jpg';
import ImageVisio from '../../../assets/images/espace-agent/formation/organisez-votre-visioconference.jpg';
import ImagePreVisite from '../../../assets/images/espace-agent/formation/guide-previsite-technique.png';
import ImageRapport from '../../../assets/images/espace-agent/formation/rapport-audit.jpeg';
import ParcoursReno from '../../../assets/images/espace-agent/formation/parcours-reno.png';

type Cards = {
    title: string;
    url: string;
    image: string;
};

export const EspaceFormationCards: Cards[] = [
    {
        title: 'Le process SOGYS',
        url: 'https://dev.sabban.fr/sogys-process/',
        image: ImageProcessSogys,
    },
    {
        title: "Les options de l'audit",
        url: 'https://dev.sabban.fr/sogys-audit/',
        image: ImageAudit,
    },
    {
        title: 'Organisez votre visioconférence',
        url: 'https://dev.sabban.fr/google-meet-x-sogys/',
        image: ImageVisio,
    },
    {
        title: 'Pre-visite technique',
        url: 'https://dev.sabban.fr/guide-pre-visite-technique/',
        image: ImagePreVisite,
    },
];

// Academy
export const SogysAcademyCards: Cards[] = [
    {
        title: "Parcours rénovation d'ampleur",
        url: 'https://dev.sabban.fr/reno-dampleur/',
        image: ParcoursReno,
    },
    {
        title: 'Rapport d’audit énergétique',
        url: 'https://dev.sabban.fr/audit-sogys/',
        image: ImageRapport,
    },
    {
        title: 'Introduction au\nphotovoltaïque',
        url: 'https://dev.sabban.fr/book-formation-particulier/',
        image: ImagePhotovoltaique,
    },
    {
        title: 'Contexte réglementaire',
        url: 'https://dev.sabban.fr/book-formation-particulier-vol2/',
        image: ImageContextReglementaire,
    },
];

// Financo only
export const EspaceFormationCardsFinanco: Cards[] = [
    {
        title: 'Financo\nTransfert de contexte',
        url: 'https://dev.sabban.fr/sogys-financo-transfert-de-contexte/',
        image: ImageTransfertDeContexte,
    },
    {
        title: 'Financo\nDemande de prêt',
        url: 'https://dev.sabban.fr/sogys-financo-demande-de-pret/',
        image: ImageDemandeDePret,
    },
    {
        title: 'Sogys\nCommissionnement',
        url: 'https://dev.sabban.fr/sogys-commissionnement/',
        image: ImageSogys,
    },
    {
        title: 'CGV Sogys Reward',
        url: 'https://dev.sabban.fr/cgv-sogys-commissionnement/',
        image: ImageSogysReward,
    },
];
