import { BBox, Feature, MultiPolygon } from 'geojson';
import { ParcelleProperties, FeuilleProperties, DivisionProperties } from '../../../pages/pre-visite/photovoltaique/PhotovoltaiqueEnums';
import { atom } from 'recoil';

export type MapProperties = {
    url: string;
    bbox: BBox;
    width: number;
    height: number;
};

export type MapStateProps = {
    map: MapProperties | null;
    parcel: Feature<MultiPolygon, ParcelleProperties> | null;
    feuille: Feature<MultiPolygon, FeuilleProperties> | null;
    section: Feature<MultiPolygon, DivisionProperties> | null;
};

export const mapState = atom<MapStateProps>({
    key: 'mapState',
    default: {
        map: null,
        parcel: null,
        feuille: null,
        section: null,
    },
});
