export type BornePeriode = { min: number; max: number };

export type ScaledGraphData<T extends { label: string }> = { byDay: Array<GraphData<T>>; byMonth: Array<GraphData<T>>; byYear: Array<GraphData<T>> };

export type GraphData<T extends { label: string }> = {
    key: string;
    label: string;
    total: number;
    data: Array<T>;
};

/**
 * Dans une plage de données byYear,byMonth, byDay, retourne les index min et max des donénes utiles,
 * c'est à dire non enrichie par des zéros.
 * @param byPeriod la période à traiter.
 * @returns les index min et max des premièes et dernières données utiles non nulles.
 */
export const getBorne = <T extends { label: string }>(byPeriod: Array<GraphData<T>>): BornePeriode => {
    if (byPeriod.length <= 0) return { min: 0, max: 0 };
    let min = 0;
    let max = byPeriod.length - 1;
    while (byPeriod[min].total === 0 && min < byPeriod.length) ++min;
    while (byPeriod[max].total === 0 && max > 0) --max;
    return { min, max };
};
