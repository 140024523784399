import * as storageService from '../../../services/localStorageService';

export const improvementsWinter = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        improvementsWinter: action,
    });

    storageService.setAuditValue(5, isValid, 'improvementsWinter', action);
};

export const improvementsSummer = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        improvementsSummer: action,
    });

    storageService.setAuditValue(5, isValid, 'improvementsSummer', action);
};

export const improvementsAirRenewal = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        improvementsAirRenewal: action,
    });

    storageService.setAuditValue(5, isValid, 'improvementsAirRenewal', action);
};

export const improvementsAcousticsLightning = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        improvementsAcousticsLightning: action,
    });

    storageService.setAuditValue(5, isValid, 'improvementsAcousticsLightning', action);
};

export const improvementsWorkProject = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        improvementsWorkProject: action,
    });

    storageService.setAuditValue(5, isValid, 'improvementsWorkProject', action);
};

export const improvementsLifeFamily = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        improvementsLifeFamily: action,
    });

    storageService.setAuditValue(5, isValid, 'improvementsLifeFamily', action);
};

export const improvementsGrantsCredits = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        improvementsGrantsCredits: action,
    });

    storageService.setAuditValue(5, isValid, 'improvementsGrantsCredits', action);
};

export const improvementsDPE = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        improvementsDPE: action,
    });

    storageService.setAuditValue(5, isValid, 'improvementsDPE', action);
};

export const improvementsQuotesForWork = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        improvementsQuotesForWork: action,
    });

    storageService.setAuditValue(5, isValid, 'improvementsQuotesForWork', action);
};

export const pathology1 = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        pathology1: action,
    });

    storageService.setAuditValue(5, isValid, 'pathology1', action);
};

export const pathology2 = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        pathology2: action,
    });

    storageService.setAuditValue(5, isValid, 'pathology2', action);
};

export const pathology3 = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        pathology3: action,
    });

    storageService.setAuditValue(5, isValid, 'pathology3', action);
};

export const pathology4 = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        pathology4: action,
    });

    storageService.setAuditValue(5, isValid, 'pathology4', action);
};

export const pathology5 = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        pathology5: action,
    });

    storageService.setAuditValue(5, isValid, 'pathology5', action);
};

export const pathology6 = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        pathology6: action,
    });

    storageService.setAuditValue(5, isValid, 'pathology6', action);
};

export const pathology7 = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        pathology7: action,
    });

    storageService.setAuditValue(5, isValid, 'pathology7', action);
};

export const ventilationProblems = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        ventilationProblems: action,
    });

    storageService.setAuditValue(5, isValid, 'ventilationProblems', action);
};
