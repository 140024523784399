export const flowStepButtonStatusNames = ['complete', 'complete-no-access', 'edit', 'error', '', 'invisible', 'loading'] as const;
export type FlowStepButtonStatus = (typeof flowStepButtonStatusNames)[number];

export const flowStepNames = ['audit', 'extra', 'simulateur', 'previsite', 'financement'] as const;
export type FlowStep = (typeof flowStepNames)[number];

export type FlowStepProperties = {
    label: string | JSX.Element;
    url: string;
    btnStatus: FlowStepButtonStatus;
    target?: '_self' | '_blank';
};

export type FlowSteps = { [key in FlowStep]: FlowStepProperties };

// Default state
export const initialStepProperties: FlowSteps = {
    audit: {
        label: "Visualiser l'audit",
        url: '#!',
        btnStatus: '',
        target: '_self',
    },
    extra: {
        label: '',
        url: '#!',
        btnStatus: 'invisible',
        target: '_self',
    },
    simulateur: {
        label: 'Parcours simulateur & commande',
        url: '#!',
        btnStatus: '',
        target: '_self',
    },
    previsite: {
        label: 'Parcours pré-visite',
        url: '#!',
        btnStatus: '',
        target: '_self',
    },
    financement: {
        label: 'Financement',
        url: '#!',
        btnStatus: '',
        target: '_blank',
    },
};

// // Debugg select
// export const testSelectOptions: Labelled<FlowStatus>[] = [
//     {
//         label: 'Created',
//         value: FlowStatus.Created,
//     },
//     {
//         label: 'WithoutRdv',
//         value: FlowStatus.WithoutRdv,
//     },
//     {
//         label: 'WithoutDevis',
//         value: FlowStatus.WithoutDevis,
//     },
//     {
//         label: 'WithoutCommand',
//         value: FlowStatus.WithoutCommand,
//     },
//     {
//         label: 'WithoutPrevisit',
//         value: FlowStatus.WithoutPrevisit,
//     },
//     {
//         label: 'WithoutInstallation',
//         value: FlowStatus.WithoutInstallation,
//     },
//     {
//         label: 'WithInstallation',
//         value: FlowStatus.WithInstallation,
//     },
//     {
//         label: 'WithFunding',
//         value: FlowStatus.WithFunding,
//     },
//     {
//         label: 'WithFundingRejected',
//         value: FlowStatus.WithFundingRejected,
//     },
//     {
//         label: 'WithFundingAccepted',
//         value: FlowStatus.WithFundingAccepted,
//     },
//     {
//         label: 'WithFundingAcceptedWithoutPrevisit',
//         value: FlowStatus.WithFundingAcceptedWithoutPrevisit,
//     },
//     {
//         label: 'WithFundingAcceptedWithPrevisitWithoutRdv',
//         value: FlowStatus.WithFundingAcceptedWithPrevisitWithoutRdv,
//     },
//     {
//         label: 'WithFundingAcceptedWithPrevisitWithRdv',
//         value: FlowStatus.WithFundingAcceptedWithPrevisitWithRdv,
//     },
// ];
