// import { StylesConfig } from 'react-select';

export const customStyles = {
    menuList: (provided: any) => ({
        ...provided,
        '::-webkit-scrollbar': {
            webkitAppearance: 'none',
            width: '7px',
        },
        '::-webkit-scrollbar-thumb': {
            borderRadius: '8px',
            backgroundColor: 'rgba(0,0,0,0.25)',
            webkitBoxShadow: '0 0 1px rgba(255,255,255,0.5)',
        },
    }),
    menu: (provided: any) => ({
        ...provided,
        padding: '0',
        borderRadius: '8px',
        backgroundColor: 'white',
        border: '1px solid #e0e0e0',
        boxShadow: '0 5px 10px 0 rgba(0,0,0,0.1)',
        textAlign: 'left',
        zIndex: 2,
    }),
    control: (provided: any, state: any) => ({
        ...provided,
        textAlign: 'left',
        height: '2.375rem',
        borderRadius: '5px',
        border: '1px solid #e0e0e0',
        padding: '0 0.75rem',
        fontSize: '1rem',
        lineHeight: '1.4375rem',
        backgroundColor: state.isDisabled ? '#eff3f8' : 'white',
        cursor: state.isDisabled ? 'not-allowed' : 'pointer',
        pointerEvents: 'auto',
        boxShadow: 'none',
        fontFamily: 'Poppins, Helvetica, Arial, sans-serif',
        '&:hover': {
            border: '1px solid #e0e0e0',
        },
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        textAlign: 'left',
        padding: '0 0.5rem',
        fontSize: '1rem',
        lineHeight: '2.5rem',
        textOverflow: 'ellipsis',
        color: '#212529',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontFamily: 'Poppins, Helvetica, Arial, sans-serif',
        background: state.isSelected ? '#e0e0e0' : 'white',
        cursor: 'pointer',
        '&:hover': {
            background: '#e0e0e0',
            color: '#212529',
        },
    }),
    valueContainer: (provided: any) => ({
        ...provided,
        textAlign: 'left',
        padding: 0,
        maxHeight: '2.375rem',
    }),
    indicatorSeparator: (provided: any) => ({
        ...provided,
        display: 'none',
    }),
    indicatorsContainer: (provided: any) => ({
        ...provided,
    }),
    singleValue: (provided: any) => ({
        ...provided,
        textAlign: 'left',
        color: '#212529',
        top: '0',
        position: 'inherit',
        transform: 'inherit',
        maxWidth: '100%',
    }),
    placeholder: (provided: any) => ({
        ...provided,
        textAlign: 'left',
        padding: '0 0.75rem',
        color: 'rgba(107, 120, 151, 0.3)',
    }),
};

export const dropdownIndicatorStyle = {
    margin: '0 12px',
    content: '""',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '6px 5px 0 5px',
    borderColor: '#47dc95 transparent transparent transparent',
};
