import qs from 'qs';
import * as api from './apiParticulierService';
import { Theme } from './calculs/theme';
import { LabelledString } from './tools/TypeHelper';
import { RenovationDAmpleur } from './calculs/aides/aideRenovationDAmpleurDefs';

// const auditTypeNames = ['Thermique', 'Expert'] as const;
// export type AuditType = (typeof auditTypeNames)[number] | `${(typeof auditTypeNames)[number]}Mar`;

const auditTypeNames = ['Thermique', 'Expert', 'ThermiqueMar', 'ExpertMar'] as const;
export type AuditType = (typeof auditTypeNames)[number];

export const isAuditType = (value: string | undefined | null): value is AuditType => {
    if (value === undefined || value === null) return false;
    return (auditTypeNames as readonly string[]).includes(value);
};
export const isAuditThermique = (value: string | undefined | null): boolean => {
    return isAuditType(value) && value.includes('Thermique');
};
export const isAuditMar = (value: string | undefined | null): boolean => {
    return isAuditType(value) && value.includes('Mar');
};

export interface PaginatedResponse<T> {
    pageIndex: number;
    totalPage: number;
    elementsPerPage: number;
    totalElements: number;
    prevPageUrl?: string;
    nextPageUrl?: string;
    elements: Array<T>;
}

export const createFlow = async (type: AuditType): Promise<string> => {
    let response = await api.postJsonData(`flow/${type}`, null);
    if (!response.id) {
        throw response.response;
    }

    return response.id;
};
export const duplicateAudit = async (id: string, withMar: boolean): Promise<string> => {
    let response = await api.postJsonData(`flow/${id}/duplicate-audit${withMar ? '-mar' : ''}`, null);

    if (!response.id) {
        throw response.response;
    }

    return response.id;
};
export const FullFlowStatusNames = [
    'Created',
    'AuditSent',
    'RdvRequested',
    'RdvSigned',
    'WithoutRdv',
    'WithoutDevis',
    'DevisSent',
    'WithoutCommand',
    'CommandSigned',
    'WithoutPrevisit',
    'WithoutInstallation',
    'WithInstallation',
] as const;

export type FullFlowStatus = (typeof FullFlowStatusNames)[number];

export const fundingType = ['Loan', 'Cash'] as const;

export type FundingType = (typeof fundingType)[number];

export type FlowStatusFilter = 'notSent' | 'sentNoRdv' | 'sentWithRdv' | 'orderCompleted' | 'preVisitCompleted' | undefined;
export type FlowOutput = {
    createdAt: string;
    updatedAt?: string;
    id: string;
    state: FullFlowStatus;
    auditType: AuditType;
    customer?: Customer;
    comment?: string | null;
    renoDampleurParams?: RenovationDAmpleur | undefined | null;
    mar?: Mar | null;
    marContactedAt: string | null;
    agent?: api.UserForManager | undefined | null;
};
export type Customer = Partial<{
    userFirstname: string | null;
    userLastname: string | null;
    userAddress: string | null;
    userZipCode: string | null;
    userLocality: string | null;
    userHomeTelephone: string | null;
    userTelephone: string | null;
    userEmail: string | null;
}>;

export type ApiDocument = {
    id: string;
    createdAt: Date;
    updatedAt: Date | null;
    url: string;
    fileName: string;
    extension: string;
};

export type AppointmentOutput = {
    id: string;
    createdAt: Date;
    updatedAt: Date | null;
    date: string;
    latitude: number | null;
    longitude: number | null;
    address: string;
};

type FlowQuotationDoc = {
    createdAt: string;
    extension: string;
    fileName: string;
    filePath: string;
    id: string;
    thirdPartStorageData: string | null;
    updatedAt: string;
    url: string;
};

type EnedisConsent = {
    id: string;
    createdAt: string;
    pdl: string;
    expireAt: string;
    customer: Customer;
    document: api.Document;
};

export type Mar = {
    id: string;
    createdAt: Date;
    updatedAt: Date | null;
    company: string;
    lastname: string;
    firstname: string;
    email: string;
    phoneNumber: string;
    address: string | null;
    city: string | null;
    zipcode: string | null;
    targetDepartments: string[];
};

export type FlowOutputDetail = {
    id: string;
    createdAt: Date;
    updatedAt: Date | null;
    state: FullFlowStatus;
    agentCode: string;
    auditType: AuditType;
    audit: unknown;
    auditId: number | null;
    recoAppointmentId: string | null;
    recoAppointment: AppointmentOutput | null;
    packages: unknown;
    mar: Mar | null;
    marId: string | null;
    marContactedAt: Date | null;
    themes: Array<Theme>;
    devis: unknown;
    devisId: number | null;
    quotationNumber: string | null;
    quotationType: api.DevisPdfType | null;
    quotationExpirationDate: Date | null;
    quotationDocId: string | null;
    quotationDoc: FlowQuotationDoc;
    fundingType: FundingType;
    fundingOrganism: string | null;
    funding: Record<string, unknown>;
    fundingUrl: string | null;
    fundingState: string | null;
    previsit: unknown;
    previsitDoc: ApiDocument | null;
    previsitDocId: string | null;
    previsitAppointmentId: string | null;
    installationAppointmentId: string | null;
    installationAppointment: AppointmentOutput | null;
    customer: Customer | null;
    enedisConsent: EnedisConsent | null;
    comment: string | undefined | null;
    renoDampleurParams: RenovationDAmpleur;
};

export type PaginatedRequest = {
    pageIndex: number;
    elementsPerPage: number;
};

export type GetMyFlowsFilters = {
    state: FlowStatusFilter[];
    createdbefore?: Date;
    createdafter?: Date;
    updatedbefore?: Date;
    updatedafter?: Date;
    ids?: string[];
};

export const FlowsOrderbyKeysNames = ['customerName', 'createdAt', 'updatedAt', 'state'] as const;
export type FlowsOrderbyKeys = (typeof FlowsOrderbyKeysNames)[number];

export type OrderName = 'asc' | 'desc';

export type GetMyFlowsRequest = {
    pagination?: PaginatedRequest;
    orderBy?: { key: FlowsOrderbyKeys; order: OrderName };
    filters?: GetMyFlowsFilters;
};

export type UrlSearchParamsType =
    | string
    | URLSearchParams
    | Record<string, string | readonly string[]>
    | Iterable<[string, string]>
    | readonly [string, string][];

export const getMyFlows = async (params?: GetMyFlowsRequest): Promise<PaginatedResponse<FlowOutput>> => {
    const queryParams = qs.stringify({
        filters: JSON.stringify(params?.filters),
        pagination: JSON.stringify(params?.pagination),
        orderby: JSON.stringify(params?.orderBy),
    });

    const url = `flow/mine?${queryParams}`;

    let response = await api.getData(url);

    return response;
};

export const getMyAgencyFlows = async (params?: GetMyFlowsRequest): Promise<PaginatedResponse<FlowOutput>> => {
    const queryParams = qs.stringify({
        filters: JSON.stringify(params?.filters),
        pagination: JSON.stringify(params?.pagination),
        orderby: JSON.stringify(params?.orderBy),
    });

    const url = `flow/mine/subagents?${queryParams}`;

    let response = await api.getData(url);

    return response;
};

export const getFlow = async (id: string): Promise<FlowOutputDetail> => {
    let response = await api.getData(`flow/${id}`);

    return response;
};

export const getFlowStatus = async (): Promise<Array<LabelledString>> => {
    return await api.getData(`flow/state/dropdown`);
};

export const deleteFlows = async (ids: string[]) => {
    await api.deleteData(`flow`, { ids });
};

// Bon de commande documents
export type SignedDocs = Partial<Record<api.DocNames, string>>;

export const getFlowDocuments = async (flowId: string): Promise<SignedDocs> => {
    return await api.getData(`flow/${flowId}/preco/signed-documents`);
};

export const knownAssociatedDocType = ['energy', 'mar', 'mar_summary', 'other', 'mandat_anah_mpr'] as const;
export type KnownAssociatedDocType = (typeof knownAssociatedDocType)[number];

export type AssociatedDocOutput = {
    id: string;
    createdAt: string;
    comment: KnownAssociatedDocType | string;
    document: api.Document;
};

// Documents
export const associateDocument = async (id: string, documentId: string, comment: string): Promise<{ id: string }> => {
    return await api.postJsonData(`flow/${id}/associate-doc`, { documentId, comment });
};

export const getAssociatedDocuments = async (flowId: string): Promise<Array<AssociatedDocOutput>> => {
    return await api.getData(`flow/${flowId}/associated-docs`);
};
