import React from 'react';
import { PanelGoogleMap } from './PanelGoogleMap';

// Icons
import { ReactComponent as IconDraw } from '../../../assets/icons/icon-draw.svg';

type SolarPanelsProps = {
    mapRef: React.RefObject<google.maps.Map>;
    values: any; // values from step 2
    setValues: React.SetStateAction<any>;
    auditSent: boolean;
};

export const SolarPanels: React.FC<SolarPanelsProps> = (props: SolarPanelsProps) => {
    return (
        <>
            <div className="card-title">
                <IconDraw fill="#76ca4f" width={40} height={40} className="icon" />
                <div className="d-flex flex-column">
                    <h2>Implantation des panneaux solaires</h2>
                    <p>Indiquez la zone de la toiture exposée au sud. Pour une exposition est/ouest, indiquez les zones des deux versants de la toiture.</p>
                </div>
            </div>

            <div className="card-body">
                <PanelGoogleMap mapRef={props.mapRef} values={props.values} setValues={props.setValues} auditSent={props.auditSent} />
            </div>
        </>
    );
};
