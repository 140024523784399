import { StylesConfig } from 'react-select';
import { Labelled, LabelledString, generateNumberList } from '../../../services/tools/TypeHelper';
import { FinancoScale, FinancoSimulation } from '../../../services/apiParticulierService';

/**
 * Liste de l'installation des produits :
 * 0 - Entre le 15ème et 30ème jour suivant la livraison des produits
 * 1 - Le jour de la livraison des produits
 */
export const installationDesProduitsList: LabelledString[] = [
    {
        label: 'Entre le 15ème et 30ème jour suivant la livraison des produits',
        value: '0',
    },
    {
        label: 'Le jour de la livraison des produits',
        value: '1',
    },
];

/**
 * Liste de périodicité :
 * 0 - Mensuel
 * 1 - Annuel
 */
export const periodiciteList: LabelledString[] = [
    {
        label: 'Mensuel',
        value: 'mensuel',
    },
    {
        label: 'Annuel',
        value: 'annuel',
    },
];

export const initialDefermentPeriodList: Labelled<number>[] = [
    {
        label: '6',
        value: 6,
    },
];

/**
 * Loop
 */

export const monthlyList: Array<LabelledString> = generateNumberList(12, 180, 6);
export const annualList: Array<LabelledString> = generateNumberList(1, 15, 1);

// Scales
// TODO : ATTENTION, API UN PEU ETRANGE, RISQUE DE PETER SI MODIFICATION COTE FINANCO
const scalesFromLocalStorage: string | null = localStorage.getItem('financoScales');
export const scales: Array<Labelled<FinancoScale>> = scalesFromLocalStorage ? JSON.parse(scalesFromLocalStorage) : [];

export type FormValues = {
    // au comptant
    paiementAuComptant: boolean;
    acompte: string;
    soldeAInstallation: string;
    installationDesProduits: string;

    // avec financement
    paiementAvecFinancement: boolean;
    scale: FinancoScale;
    choixDeOrganismeFinancier: string;
    commandAmount: string;
    takingOutInsurance: string | undefined;
    birthDate: string | null;
    deposit: string;
    paymentFrequency: string;
    defermentPeriod: string;
    simulation: boolean;
    loan: FinancoSimulation | null;
    // taegFixe: string;
    // tauxDebiteurFixe: string;
    // montantTotalDuCredit: string;
    // periodicite: string;
    // dureeDuContrat: string;
    // nombreEcheance1: string;
    // montantAssuranceComprise1: string;
    // nombreEcheance2: string;
    // montantAssuranceComprise2: string;
    // nombreEcheance3: string;
    // montantAssuranceComprise3: string;
    // totalTtcHorsAssurance: string;
};

/**
 * Pour le changement de couleurs du react-select
 */
export const panelStyles: StylesConfig<any, any> = {
    menuList: (styles) => ({
        ...styles,
        color: '#434D5D',
        borderRadius: '4px',
        backgroundColor: 'white',
        padding: '0',
    }),

    indicatorSeparator: (styles) => ({
        ...styles,
        display: 'none',
    }),

    control: (styles) => ({
        ...styles,
        backgroundColor: '#FFF',
        color: '#434D5D',
        boxShadow: 'none',
        border: '1px solid #A1B5CC',
        minWidth: '100px',
        height: '43px',

        '&:focus': {
            border: '0',
            boxShadow: 'none',
        },
    }),

    placeholder: (styles) => ({
        ...styles,
        color: '#C1C1C1',
    }),

    option: (styles, state) => ({
        ...styles,
        backgroundColor: state.isSelected ? '#EEFAFF' : 'white',
        color: '#434D5D',

        '&:not(:last-child)': {
            borderBottom: '1px solid #E4E4E4',
        },

        '&:hover': {
            backgroundColor: '#EEFAFF',
            color: '#434D5D',
        },
    }),

    singleValue: (styles) => ({
        ...styles,
        color: '#434D5D',
    }),
};

// Select disabled state
export const panelDisabled: StylesConfig<any, true> = {
    control: (styles) => ({
        ...styles,
        height: '43px',
        minWidth: '100px',
        width: '100%',
        backgroundColor: '#F5F5F5',
        border: '1px solid #A1B5CC',
    }),

    singleValue: (styles) => ({
        ...styles,
        color: '#999',
    }),

    placeholder: (styles) => ({
        ...styles,
        color: '#C1C1C1',
    }),

    indicatorSeparator: (styles) => ({
        ...styles,
        display: 'none',
    }),
};

export const initialTakingOutInsuranceList: Array<Labelled<string | undefined>> = [
    {
        value: undefined,
        label: 'Sans assurance',
    },
    // {
    //     value: '2', // id de l'assurance par défaut. HARD CODED !!
    //     label: 'Premium (Décès,PTIA,ITT,PE)', // description de l'assurance par défaut. HARD CODED !!
    // },
    {
        value: '1', // id de l'assurance par défaut. HARD CODED !!
        label: 'Confort (Décès,PTIA,ITT)', // description de l'assurance par défaut. HARD CODED !!
    },
];
