import { BienType, IndicateurKeys, LogementTypeCode, Mutation } from './types';
import * as storageService from '../../services/localStorageService';
import { getCodeInsee } from '../tools/gouv';
import * as api from './api';

export const evaluerMonBienPrixM2 = async (): Promise<number | undefined> => {
    const { audit, clientIdentity } = storageService.stepListToAuditAndClient();
    const shab = +audit.SHab.value;
    // const shon = +audit.SHON.value;
    // const levels = getLevelCount(audit, 'horsSSavecCA');
    // quelle surface prendr ?
    // const surface = shab;
    // const nbPiece = +audit.generalOptionsMain.value;
    const construction = +audit.houseAge.value;
    // const projectType = +audit.projectType.value; // 1 = maison, 2,3,4 : appartement ...
    const currentyear = new Date().getFullYear();
    // const age = currentyear - construction;

    const codeInsee = await getCodeInsee(clientIdentity.userZipCode, clientIdentity.userLocality);
    if (!codeInsee) return undefined;

    console.log('construction = ' + construction);
    console.log('shab = ' + shab);
    console.log('code insee = ' + codeInsee);
    console.log('year = ' + currentyear);
    // On a pas la dernière année, donc année -1,
    // et on veut le milieu de 3 ans : donc -2.
    let results = (await api.indicateurCommuneTriennal(codeInsee, (currentyear - 3).toString())).results[0];

    // quelle variable choisir ?
    console.log('results.pxm2_median_cod1113 = ' + results.pxm2_median_cod1113);
    console.log('results.pxm2_median_mg1 = ' + results.pxm2_median_mg1);

    // trouver mon type de bien :
    const codeBien = getCodeBien(audit);
    const typeLogement = getLogementType(audit);

    const indicateur1: IndicateurKeys = ('pxm2_median_' + codeBien) as IndicateurKeys;
    const indicateur2: IndicateurKeys = ('pxm2_median_' + typeLogement) as IndicateurKeys;
    console.log('indicateur1 = ' + indicateur1);
    console.log('indicateur2 = ' + indicateur2);

    let pmx2 = 0;
    if (pmx2 === 0 && indicateur1 in results && results[indicateur1]) pmx2 = results[indicateur1] ?? 0;
    if (pmx2 === 0 && indicateur2 in results && results[indicateur2]) pmx2 = results[indicateur2] ?? 0;

    console.log('result.indicateur1 = ' + results[indicateur1]);
    console.log('result.indicateur2 = ' + results[indicateur2]);

    // plan B : les mmx ...

    // quelle surface choisir ?
    return pmx2;
};

export type LightMutation = Pick<Mutation, 'idmutation' | 'anneemut' | 'valeurfonc' | 'sbati' | 'codtypbien' | 'libtypbien' | 'sterr'>;

export const chercherDernierPrixConnu = async (): Promise<LightMutation | undefined> => {
    const { audit, clientIdentity } = storageService.stepListToAuditAndClient();
    const currentyear = new Date().getFullYear();

    const code_insee = await getCodeInsee(clientIdentity.userZipCode, clientIdentity.userLocality);
    if (!code_insee) return undefined;
    if (!audit.userAddressLatitude || !audit.userAddressLongitude || !audit.userAddressLatitude.value || !audit.userAddressLongitude.value) return undefined;
    const contains_geom = `{'type':'Point', 'coordinates':[${audit.userAddressLongitude.value},${audit.userAddressLatitude.value}]}`;
    //const contains_geom = `{'type':'Point', 'coordinates':[2.600075,48.854172]}`;
    const date_min = (currentyear - 5).toString();

    const mutations = await api.getMutations({ code_insee, contains_geom, date_min });

    if (!mutations || !mutations.results || mutations.results.length === 0) {
        console.log('Pas de mutations connues');
        return undefined;
    }
    if (mutations.results.length > 1) {
        console.log('Trop de mutations => prendre la plus récente');
        return undefined;
    }
    const mutation = mutations.results[0] as LightMutation;
    //return mutation.valeurfonc ? +mutation.valeurfonc : undefined;
    return mutation;
};

const getCodeBien = (audit: any): BienType => {
    const nbPiece = +audit.generalOptionsMain.value;
    const projectType = +audit.projectType.value; // 1 = maison, 2,3,4 : appartement ...
    const age = new Date().getFullYear() - +audit.houseAge.value;

    // trouver mon code de bien :

    // en fonction de maison / appartement
    let codeBien: string = projectType === 1 ? 'cod111' : 'cod121';

    // age de la construction
    if (age < 1) codeBien += '1';
    else if (age < 4) codeBien += '2';
    else codeBien += '3';

    if (projectType !== 1) {
        // surface pour les appartement
        if (nbPiece <= 1) codeBien += '1';
        else if (nbPiece <= 2) codeBien += '2';
        else if (nbPiece <= 3) codeBien += '3';
        else if (nbPiece <= 4) codeBien += '4';
        else codeBien += '5';
    }

    return codeBien as BienType;
};

const getLogementType = (audit: any): LogementTypeCode => {
    const shab = +audit.SHab.value;
    // quelle surface prendr ?
    const surface = shab;
    const nbPiece = +audit.generalOptionsMain.value;
    const construction = +audit.houseAge.value;
    const projectType = +audit.projectType.value; // 1 = maison, 2,3,4 : appartement ...

    // trouver mon type de logement :
    let typeLogement: string = ''; /// mmx

    // tous les type n'existent pas exemple : mxx n'existe pas.

    if (projectType === 1) {
        // maison
        typeLogement += 'm';

        // surface
        if (surface < 90) typeLogement += 'p';
        else if (surface < 130) typeLogement += 'm';
        else typeLogement += 'g';

        // année de construction
        if (construction < 1945) typeLogement += '1';
        else if (construction < 1960) typeLogement += '2';
        else if (construction < 1974) typeLogement += '3';
        else if (construction < 2012) typeLogement += '4';
        else typeLogement += '5';
    } else {
        // appartement
        typeLogement += 'a';

        // surface
        if (nbPiece <= 2) typeLogement += 'p';
        else if (nbPiece <= 4) typeLogement += 'm';
        else typeLogement += 'g';

        // année de construction
        if (construction < 1945) typeLogement += '1';
        else if (construction < 1960) typeLogement += '2';
        else if (construction < 1974) typeLogement += '3';
        else if (construction < 2012) typeLogement += '4';
        else typeLogement += '5';
    }
    return typeLogement as LogementTypeCode;
};
