import React from 'react';
import { TextElement } from '../Text/Text';

// Style
import './Dropdown.scss';

// Icon
import { ReactComponent as IconChevron } from '../../assets/icons/pre-visite/icon-chevron.svg';

type DropdownProps = {
    label: string;
    items: React.ReactNode[];
    maxWidth?: number;
    disabled?: boolean;
};

export const Dropdown: React.FC<DropdownProps> = ({ label, items, maxWidth, disabled = false }) => {
    return (
        <div className="dropdown" style={{ maxWidth: maxWidth }} data-disabled={disabled}>
            <TextElement as="p" className="dropdown-label">
                {label}
                <IconChevron fill="white" height={16} />
            </TextElement>
            <div className="dropdown-content">
                {items.map((item, index) => (
                    <React.Fragment key={index}>{item}</React.Fragment>
                ))}
            </div>
        </div>
    );
};
