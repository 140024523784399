import React from 'react';
import CheckboxTriState from '../../../../components/checkbox/TriStateCheckbox';
import { FieldArray, FormikErrors, FormikTouched, Field } from 'formik';
import { FormValues, radiatorNumberList, panelStyles, RadiatorCircuit } from '../PompeAChaleurEnums';
import Select from 'react-select';
import { LabelledString } from '../../../../services/tools/TypeHelper';
import { renderDefaultValueSelect } from '../../../../services/tools/selectValue';
import * as storageService from '../../../../services/localStorageService';

// Images
import Radiateur1 from '../../../../assets/images/previsite/radiateur1.png';
import Radiateur2 from '../../../../assets/images/previsite/radiateur2.png';
import Radiateur3 from '../../../../assets/images/previsite/radiateur3.png';
import Radiateur4 from '../../../../assets/images/previsite/radiateur4.png';

// Types
import Type10 from '../../../../assets/images/previsite/type10.png';
import Type11 from '../../../../assets/images/previsite/type11.png';
import Type20 from '../../../../assets/images/previsite/type20.png';
import Type21 from '../../../../assets/images/previsite/type21.png';
import Type22 from '../../../../assets/images/previsite/type22.png';

interface RadiatorsProps {
    values: FormValues;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    errors: FormikErrors<FormValues>;
    touched: FormikTouched<FormValues>;
    setValues: (values: React.SetStateAction<FormValues>, shouldValidate?: boolean | undefined) => void;
}

const Radiators: React.FC<RadiatorsProps> = ({ values, onChange, errors, touched, setValues }) => {
    const setRadiatorCircuit = (numberOfRadiator: number, isIdentical: boolean | undefined) => {
        let newRadiatorCircuit: RadiatorCircuit[] = [];

        if (isIdentical && numberOfRadiator > 1) {
            newRadiatorCircuit = Array.from({ length: 1 }, () => ({
                thermostaticValve: '',
                width: '',
                height: '',
                depth: '',
                inputTemperature: '',
                outputTemperature: '',
                radiatorType: '',
                topView: '',
            }));

            setValues({ ...values, radiatorCircuit: newRadiatorCircuit, numberOfRadiator: numberOfRadiator, identicalRadiators: isIdentical });
            // Update localStorage
            storageService.setPrevisitValue('radiatorCircuit', newRadiatorCircuit);
            storageService.setPrevisitValue('numberOfRadiator', numberOfRadiator);
            storageService.setPrevisitValue('identicalRadiators', true);
        } else if (numberOfRadiator === 1) {
            newRadiatorCircuit = Array.from({ length: numberOfRadiator }, () => ({
                thermostaticValve: '',
                width: '',
                height: '',
                depth: '',
                inputTemperature: '',
                outputTemperature: '',
                radiatorType: '',
                topView: '',
            }));

            setValues({ ...values, radiatorCircuit: newRadiatorCircuit, numberOfRadiator: numberOfRadiator, identicalRadiators: true });
            // Update localStorage
            storageService.setPrevisitValue('radiatorCircuit', newRadiatorCircuit);
            storageService.setPrevisitValue('numberOfRadiator', numberOfRadiator);
            storageService.setPrevisitValue('identicalRadiators', true);
        } else {
            newRadiatorCircuit = Array.from({ length: numberOfRadiator }, () => ({
                thermostaticValve: '',
                width: '',
                height: '',
                depth: '',
                inputTemperature: '',
                outputTemperature: '',
                radiatorType: '',
                topView: '',
            }));

            setValues({ ...values, radiatorCircuit: newRadiatorCircuit, numberOfRadiator: numberOfRadiator, identicalRadiators: isIdentical });
            // Update localStorage
            storageService.setPrevisitValue('radiatorCircuit', newRadiatorCircuit);
            storageService.setPrevisitValue('numberOfRadiator', numberOfRadiator);
            storageService.setPrevisitValue('identicalRadiators', isIdentical);
        }
    };

    return (
        <div className="row mb-3">
            <div className="col-12 col-md-3 mb-3">
                <div className="form-group">
                    <label htmlFor="numberOfRadiator">
                        Nombre de radiateur(s) dans l’habitation<sup>*</sup>
                    </label>
                    <Select
                        value={renderDefaultValueSelect(radiatorNumberList, values.numberOfRadiator.toString())}
                        options={radiatorNumberList}
                        isMulti={false}
                        isClearable={false}
                        isSearchable={false}
                        placeholder={'Nombre de radiateur'}
                        name="numberOfRadiator"
                        onChange={(event: LabelledString | null) => {
                            if (!event) return;
                            setRadiatorCircuit(Number(event.value), values.identicalRadiators);
                            storageService.setPrevisitValue('numberOfRadiator', event.value);
                        }}
                        className={
                            'basic-single' +
                            (values.numberOfRadiator ? ' filled' : '') +
                            (touched.numberOfRadiator && errors.numberOfRadiator ? ' invalid' : '') +
                            (!values.numberOfRadiator ? ' required' : '')
                        }
                        styles={panelStyles}
                    />
                    {touched.numberOfRadiator && errors.numberOfRadiator && <span className="invalid-feedback">{errors.numberOfRadiator}</span>}
                </div>
            </div>

            <div className="col-12 col-md-5 mb-3">
                <CheckboxTriState
                    name="identicalRadiators"
                    title="Les radiateurs de l'habitation sont-ils identiques"
                    emptyLabel={true}
                    style={{ height: '43px' }}
                    onCustomChange={(e: boolean | undefined) => {
                        setRadiatorCircuit(Number(values.numberOfRadiator), e);
                    }}
                    disabled={!values.numberOfRadiator || values.numberOfRadiator === 1}
                />
                {touched.identicalRadiators && errors.identicalRadiators && <span className="invalid-feedback">{errors.identicalRadiators}</span>}
            </div>

            {values.numberOfRadiator && (
                <div className="card-body--inner">
                    <FieldArray name="radiatorCircuit">
                        {() => {
                            return (
                                <div className="radiator-circuits">
                                    {values.radiatorCircuit.map((_, index) => (
                                        <div className="radiator-circuits--item" key={index}>
                                            <h4 className="card-body--inner-title">
                                                {values.identicalRadiators && Number(values.numberOfRadiator) > 1 ? (
                                                    <>Radiateurs</>
                                                ) : values.identicalRadiators && Number(values.numberOfRadiator) === 1 ? (
                                                    <>Radiateur</>
                                                ) : (
                                                    <>Radiateur {index + 1}</>
                                                )}
                                            </h4>
                                            <div className="row">
                                                <div className="col-12 col-md-3 mb-3">
                                                    <CheckboxTriState
                                                        name={`radiatorCircuit.${index}.thermostaticValve`}
                                                        title="Vanne thermostatique"
                                                        onCustomChange={(e: boolean) => {
                                                            let tmp = values.radiatorCircuit;
                                                            tmp[index].thermostaticValve = e;

                                                            storageService.setPrevisitValue('radiatorCircuit', tmp);
                                                        }}
                                                        emptyLabel={true}
                                                    />
                                                </div>

                                                <div className="col-12 col-md-3 mb-3">
                                                    <div className="form-group">
                                                        <label htmlFor={`width-${index}`}>
                                                            Largeur<sup>*</sup>
                                                        </label>
                                                        <div className="input-group">
                                                            <Field
                                                                type="number"
                                                                name={`radiatorCircuit.${index}.width`}
                                                                placeholder="Largeur"
                                                                className={
                                                                    'form-control' +
                                                                    (values.radiatorCircuit[index].width ? ' filled' : '') +
                                                                    ((touched as any)['width-' + index] && !values.radiatorCircuit[index].width
                                                                        ? ' invalid'
                                                                        : '') +
                                                                    (!values.radiatorCircuit[index].width ? ' required' : '')
                                                                }
                                                                id={`width-${index}`}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                    onChange(e);
                                                                    let tmp = values.radiatorCircuit;
                                                                    tmp[index].width = Number(e.target.value);

                                                                    storageService.setPrevisitValue('radiatorCircuit', tmp);
                                                                }}
                                                                value={values.radiatorCircuit[index].width}
                                                            />
                                                            <span className="input-group-text">cm</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-3 mb-3 mb-md-0">
                                                    <div className="form-group">
                                                        <label htmlFor={`height-${index}`}>
                                                            Hauteur<sup>*</sup>
                                                        </label>
                                                        <div className="input-group">
                                                            <Field
                                                                type="number"
                                                                className={
                                                                    'form-control' +
                                                                    (values.radiatorCircuit[index].height ? ' filled' : '') +
                                                                    ((touched as any)['height-' + index] && !values.radiatorCircuit[index].height
                                                                        ? ' invalid'
                                                                        : '') +
                                                                    (!values.radiatorCircuit[index].height ? ' required' : '')
                                                                }
                                                                placeholder="Longueur"
                                                                id={`height-${index}`}
                                                                name={`radiatorCircuit.${index}.height`}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                    onChange(e);
                                                                    let tmp = values.radiatorCircuit;
                                                                    tmp[index].height = Number(e.target.value);

                                                                    storageService.setPrevisitValue('radiatorCircuit', tmp);
                                                                }}
                                                                value={values.radiatorCircuit[index].height}
                                                            />
                                                            <span className="input-group-text">cm</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-3 mb-3 mb-md-0">
                                                    <div className="form-group">
                                                        <label htmlFor={`depth-${index}`}>
                                                            Profondeur<sup>*</sup>
                                                        </label>
                                                        <div className="input-group">
                                                            <Field
                                                                type="number"
                                                                className={
                                                                    'form-control' +
                                                                    (values.radiatorCircuit[index].depth ? ' filled' : '') +
                                                                    ((touched as any)['depth-' + index] && !values.radiatorCircuit[index].depth
                                                                        ? ' invalid'
                                                                        : '') +
                                                                    (!values.radiatorCircuit[index].depth ? ' required' : '')
                                                                }
                                                                placeholder="Profondeur"
                                                                id={`depth-${index}`}
                                                                name={`radiatorCircuit.${index}.depth`}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                    onChange(e);
                                                                    let tmp = values.radiatorCircuit;
                                                                    tmp[index].depth = Number(e.target.value);

                                                                    storageService.setPrevisitValue('radiatorCircuit', tmp);
                                                                }}
                                                                value={values.radiatorCircuit[index].depth}
                                                            />
                                                            <span className="input-group-text">cm</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-3 mb-3 mb-md-0">
                                                    <div className="form-group">
                                                        <label htmlFor={`inputTemperature-${index}`}>Température d'entrée</label>
                                                        <div className="input-group">
                                                            <Field
                                                                type="number"
                                                                step={1}
                                                                min={1}
                                                                max={99}
                                                                className={
                                                                    'form-control' +
                                                                    (values.radiatorCircuit[index].inputTemperature ? ' filled' : '') +
                                                                    ((touched as any)['inputTemperature-' + index] &&
                                                                    !values.radiatorCircuit[index].inputTemperature
                                                                        ? ' invalid'
                                                                        : '')
                                                                }
                                                                name={`radiatorCircuit.${index}.inputTemperature`}
                                                                id={`inputTemperature-${index}`}
                                                                placeholder="Température d'entrée"
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                    onChange(e);
                                                                    let tmp = values.radiatorCircuit;
                                                                    tmp[index].inputTemperature = Number(e.target.value);

                                                                    storageService.setPrevisitValue('radiatorCircuit', tmp);
                                                                }}
                                                                value={values.radiatorCircuit[index].inputTemperature}
                                                            />
                                                            <span className="input-group-text">°C</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-3 mb-3 mb-md-0">
                                                    <div className="form-group">
                                                        <label htmlFor={`outputTemperature-${index}`}>Température de sortie</label>
                                                        <div className="input-group">
                                                            <Field
                                                                type="number"
                                                                step={1}
                                                                min={1}
                                                                max={99}
                                                                className={
                                                                    'form-control' +
                                                                    (values.radiatorCircuit[index].outputTemperature ? ' filled' : '') +
                                                                    ((touched as any)['outputTemperature-' + index] &&
                                                                    !values.radiatorCircuit[index].outputTemperature
                                                                        ? ' invalid'
                                                                        : '')
                                                                }
                                                                name={`radiatorCircuit.${index}.outputTemperature`}
                                                                id={`outputTemperature-${index}`}
                                                                placeholder="Température de sortie"
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                    onChange(e);
                                                                    let tmp = values.radiatorCircuit;
                                                                    tmp[index].outputTemperature = Number(e.target.value);

                                                                    storageService.setPrevisitValue('radiatorCircuit', tmp);
                                                                }}
                                                                value={values.radiatorCircuit[index].outputTemperature}
                                                            />
                                                            <span className="input-group-text">°C</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {values.identicalRadiators === false || (values.identicalRadiators === true && index === 0) ? (
                                                <>
                                                    <h4 className="card-body--inner-title mt-4">Type de radiateur</h4>
                                                    <h5 className="card-body--inner-sub-title mb-3">Vue de face</h5>
                                                    <div className="row mb-3">
                                                        <div className="col-12 col-md-2">
                                                            <div className="form-group form-group-radio">
                                                                <label htmlFor={`radiatorType-${index}-1`}>
                                                                    <img src={Radiateur1} alt="Radiateur alu" aria-hidden={true} />
                                                                    <span>Radiateur Alu</span>
                                                                    <Field
                                                                        type="radio"
                                                                        name={`radiatorCircuit.${index}.radiatorType`}
                                                                        id={`radiatorType-${index}-1`}
                                                                        value="aluminium"
                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                            onChange(e);
                                                                            let tmp = values.radiatorCircuit;
                                                                            tmp[index].radiatorType = 'aluminium';

                                                                            storageService.setPrevisitValue('radiatorCircuit', tmp);
                                                                        }}
                                                                    />
                                                                    <div className="fake-radio"></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-2">
                                                            <div className="form-group form-group-radio">
                                                                <label htmlFor={`radiatorType-${index}-2`}>
                                                                    <img src={Radiateur2} alt="Radiateur alu" aria-hidden={true} />
                                                                    <span>Radiateur Raphaël</span>
                                                                    <Field
                                                                        type="radio"
                                                                        name={`radiatorCircuit.${index}.radiatorType`}
                                                                        id={`radiatorType-${index}-2`}
                                                                        value="raphael"
                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                            onChange(e);
                                                                            let tmp = values.radiatorCircuit;
                                                                            tmp[index].radiatorType = 'raphael';

                                                                            storageService.setPrevisitValue('radiatorCircuit', tmp);
                                                                        }}
                                                                    />
                                                                    <div className="fake-radio"></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-2">
                                                            <div className="form-group form-group-radio">
                                                                <label htmlFor={`radiatorType-${index}-3`}>
                                                                    <img src={Radiateur3} alt="Radiateur alu" aria-hidden={true} />
                                                                    <span>Radiateur NCX</span>
                                                                    <Field
                                                                        type="radio"
                                                                        name={`radiatorCircuit.${index}.radiatorType`}
                                                                        id={`radiatorType-${index}-3`}
                                                                        value="ncx"
                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                            onChange(e);
                                                                            let tmp = values.radiatorCircuit;
                                                                            tmp[index].radiatorType = 'ncx';

                                                                            storageService.setPrevisitValue('radiatorCircuit', tmp);
                                                                        }}
                                                                    />
                                                                    <div className="fake-radio"></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-2">
                                                            <div className="form-group form-group-radio">
                                                                <label htmlFor={`radiatorType-${index}-4`}>
                                                                    <img src={Radiateur4} alt="Radiateur alu" aria-hidden={true} />
                                                                    <span>Radiateur Plissé</span>
                                                                    <Field
                                                                        type="radio"
                                                                        name={`radiatorCircuit.${index}.radiatorType`}
                                                                        id={`radiatorType-${index}-4`}
                                                                        value="plisse"
                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                            onChange(e);
                                                                            let tmp = values.radiatorCircuit;
                                                                            tmp[index].radiatorType = 'plisse';

                                                                            storageService.setPrevisitValue('radiatorCircuit', tmp);
                                                                        }}
                                                                    />
                                                                    <div className="fake-radio"></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <h5 className="card-body--inner-sub-title mb-3">Vue du dessus</h5>
                                                    <div className="row">
                                                        <div className="col-12 col-md-2">
                                                            <div className="form-group form-group-radio">
                                                                <label htmlFor={`topView-${index}-1`}>
                                                                    <img src={Type10} alt="Type 10" aria-hidden={true} />
                                                                    <span>Type 10</span>
                                                                    <Field
                                                                        type="radio"
                                                                        name={`radiatorCircuit.${index}.topView`}
                                                                        id={`topView-${index}-1`}
                                                                        value="type 10"
                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                            onChange(e);
                                                                            let tmp = values.radiatorCircuit;
                                                                            tmp[index].topView = 'type 10';

                                                                            storageService.setPrevisitValue('radiatorCircuit', tmp);
                                                                        }}
                                                                    />
                                                                    <div className="fake-radio"></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-2">
                                                            <div className="form-group form-group-radio">
                                                                <label htmlFor={`topView-${index}-2`}>
                                                                    <img src={Type11} alt="Type 11" aria-hidden={true} />
                                                                    <span>Type 11</span>
                                                                    <Field
                                                                        type="radio"
                                                                        name={`radiatorCircuit.${index}.topView`}
                                                                        id={`topView-${index}-2`}
                                                                        value="type 11"
                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                            onChange(e);
                                                                            let tmp = values.radiatorCircuit;
                                                                            tmp[index].topView = 'type 11';

                                                                            storageService.setPrevisitValue('radiatorCircuit', tmp);
                                                                        }}
                                                                    />
                                                                    <div className="fake-radio"></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-2">
                                                            <div className="form-group form-group-radio">
                                                                <label htmlFor={`topView-${index}-3`}>
                                                                    <img src={Type20} alt="Type 20" aria-hidden={true} />
                                                                    <span>Type 20</span>
                                                                    <Field
                                                                        type="radio"
                                                                        name={`radiatorCircuit.${index}.topView`}
                                                                        id={`topView-${index}-3`}
                                                                        value="type 20"
                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                            onChange(e);
                                                                            let tmp = values.radiatorCircuit;
                                                                            tmp[index].topView = 'type 20';

                                                                            storageService.setPrevisitValue('radiatorCircuit', tmp);
                                                                        }}
                                                                    />
                                                                    <div className="fake-radio"></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-2">
                                                            <div className="form-group form-group-radio">
                                                                <label htmlFor={`topView-${index}-4`}>
                                                                    <img src={Type21} alt="Type 21" aria-hidden={true} />
                                                                    <span>Type 21</span>
                                                                    <Field
                                                                        type="radio"
                                                                        name={`radiatorCircuit.${index}.topView`}
                                                                        id={`topView-${index}-4`}
                                                                        value="type 21"
                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                            onChange(e);
                                                                            let tmp = values.radiatorCircuit;
                                                                            tmp[index].topView = 'type 21';

                                                                            storageService.setPrevisitValue('radiatorCircuit', tmp);
                                                                        }}
                                                                    />
                                                                    <div className="fake-radio"></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-2">
                                                            <div className="form-group form-group-radio">
                                                                <label htmlFor={`topView-${index}-5`}>
                                                                    <img src={Type22} alt="Type 22" aria-hidden={true} />
                                                                    <span>Type 22</span>
                                                                    <Field
                                                                        type="radio"
                                                                        name={`radiatorCircuit.${index}.topView`}
                                                                        id={`topView-${index}-5`}
                                                                        value="type 22"
                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                            onChange(e);
                                                                            let tmp = values.radiatorCircuit;
                                                                            tmp[index].topView = 'type 22';

                                                                            storageService.setPrevisitValue('radiatorCircuit', tmp);
                                                                        }}
                                                                    />
                                                                    <div className="fake-radio"></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : null}
                                        </div>
                                    ))}
                                </div>
                            );
                        }}
                    </FieldArray>
                </div>
            )}
        </div>
    );
};

export default Radiators;
