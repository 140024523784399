import React, { CSSProperties, useCallback, useRef } from 'react';
import { GoogleMap, GoogleMapProps, StreetViewPanorama } from '@react-google-maps/api';
import { GeoPosition } from '../../pages/audit-simulator/rendement/Rendement';

// docs https://developers.google.com/maps/documentation/javascript/streetview?hl=fr

export type GooglePov = {
    heading: number;
    pitch: number;
    zoom: number;
};

export type StreetViewSpec = GooglePov & GeoPosition;

const containerStyle = {
    width: '100%',
    height: '475px',
};

type CustomGoogleMapStreetViewProps = {
    center?: GeoPosition;
    googleMapProps?: GoogleMapProps;
    mapContainerStyle?: CSSProperties;
    panoramaOptions?: google.maps.StreetViewPanoramaOptions;
    streetViewRef: React.MutableRefObject<google.maps.StreetViewPanorama | null>;
};

const defaultMapOptions: google.maps.MapOptions = {
    streetViewControl: false,
    disableDoubleClickZoom: true,
    fullscreenControl: false,
    panControl: false,
    scaleControl: true,
    keyboardShortcuts: false,
};

const defaultPanoramaOptions: google.maps.StreetViewPanoramaOptions = {
    pov: {
        heading: 34,
        pitch: 10,
    },
    zoom: 1,
    visible: true,
};

const GoogleMapsStreetView: React.FC<CustomGoogleMapStreetViewProps> = ({ googleMapProps, center, streetViewRef, mapContainerStyle }) => {
    const mapRef = useRef<google.maps.Map | null>(null);

    // set ref to map
    const onMapLoad = useCallback((map: google.maps.Map) => {
        mapRef.current = map;
    }, []);

    // set ref to street view
    const onStreetViewPanoramaLoad = useCallback((streetViewPanorama: google.maps.StreetViewPanorama) => {
        streetViewRef.current = streetViewPanorama;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <GoogleMap
            {...googleMapProps}
            onLoad={onMapLoad}
            options={defaultMapOptions}
            mapContainerStyle={{ ...containerStyle, ...mapContainerStyle }}
            center={center}
            zoom={18}
        >
            <StreetViewPanorama onLoad={onStreetViewPanoramaLoad} options={{ ...defaultPanoramaOptions, position: center }} />
        </GoogleMap>
    );
};

export default React.memo(GoogleMapsStreetView);
