import React, { useEffect, useMemo, useState } from 'react';
import { Back } from '../../../components/Back/Back';
import * as routes from '../../../routing/paths';
import AppForm from '../../../components/Forms/AppForm';
import { initialValues, MarketingOrderProduct, Product } from './MarketingEnums';
import { useFormikContext } from 'formik';
import { currencyFormat, LabelledString } from '../../../services/tools/TypeHelper';
import { marketingSchema } from './schema/schema';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { createMarketingOrder, MarketingOrder } from '../../../services/apiParticulierService';
import { Kakemono } from './components/kakemono';
import { CarteDeVisite } from './components/carteDeVisite';
import { ReactComponent as Loader } from '../../../assets/icons/loader.svg';

// Icons
import { ReactComponent as IconShop } from '../../../assets/icons/espace-agent/icon-shop.svg';

// Style
import './Marketing.scss';

const MarketingForm: React.FC = () => {
    const { values, errors, setFieldValue } = useFormikContext<MarketingOrderProduct>();

    // useEffect(() => {
    //     // Debug
    //     console.log(values);
    //     console.log(errors);
    // }, [values, errors]);

    const handleQuantityChange = (product: Product, selectedOption: LabelledString) => {
        setFieldValue(`${product}.quantity`, selectedOption);

        if (selectedOption.value === '0') {
            setFieldValue(`${product}.customization`, {
                name: '',
                firstname: '',
                phone: '',
            });
        }

        if (product === 'Cartes de visite' && selectedOption.value === '0') {
            setFieldValue('Cartes de visite.customization.name', '');
            setFieldValue('Cartes de visite.customization.firstname', '');
            setFieldValue('Cartes de visite.customization.phone', '');
            setFieldValue('Cartes de visite.customization.mobile', '');
            setFieldValue('Cartes de visite.customization.email', '');
            setFieldValue('Cartes de visite.customization.address', '');
            setFieldValue('Cartes de visite.customization.city', '');
            setFieldValue('Cartes de visite.customization.postalCode', '');
        }
    };

    const total = useMemo(() => {
        return Object.entries(values).reduce((acc, [product, details]) => {
            if (product === 'Cartes de visite') {
                const quantity = details?.quantity?.value ? parseInt(details.quantity.value, 10) : 0;
                // Calculate the number of 250-card sets
                const sets = Math.ceil(quantity / 250);
                return acc + 54.99 * sets;
            } else {
                const quantity = details?.quantity?.value ? parseInt(details.quantity.value, 10) : 0;
                return acc + (details?.price ?? 0) * quantity;
            }
        }, 0);
    }, [values]);

    return (
        <>
            {/* Kakemono */}
            <Kakemono handleQuantityChange={handleQuantityChange} />
            {/* Carte de visite */}
            <CarteDeVisite handleQuantityChange={handleQuantityChange} />

            <div className="btn-grp justify-content-center mt-5">
                <button type="submit" className="btn btn-continue" disabled={total === 0 || Object.keys(errors).length > 0}>
                    Commander ({currencyFormat(total, 2)} HT)
                </button>
            </div>
        </>
    );
};

const Marketing: React.FC = () => {
    const AlertSwal = withReactContent(Swal);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // Change the title of the page
        document.title = 'SOGYS.APP - Outils Marketing';
    }, []);

    const handleSubmitSuccess = () => {
        AlertSwal.fire({
            title: 'Commande validée',
            text: 'Merci pour votre commande',
            icon: 'success',
            timer: 2000,
            showCancelButton: false,
            showConfirmButton: false,
        });
    };

    const handleSubmit = async (values: MarketingOrderProduct) => {
        setIsLoading(true);
        const transformedValues = Object.entries(values).reduce((acc, [key, value]) => {
            if (value && typeof value === 'object') {
                acc[key] = {
                    ...value,
                    quantity: value.quantity?.value ? parseInt(value.quantity.value, 10) : 0,
                    customization: value.customization,
                };
            }
            return acc;
        }, {} as Record<string, any>);

        const deliveryAddress: Record<string, string> = {
            // Nom: transformedValues['Cartes de visite'].customization.name,
            // Prénom: transformedValues['Cartes de visite'].customization.firstname,
            // 'Téléphone fixe': transformedValues['Cartes de visite'].customization.phone,
            // 'Téléphone mobile': transformedValues['Cartes de visite'].customization.mobile,
            // Email: transformedValues['Cartes de visite'].customization.email,
            // Adresse: transformedValues['Cartes de visite'].customization.address,
            // Ville: transformedValues['Cartes de visite'].customization.city,
            // 'Code postal': transformedValues['Cartes de visite'].customization.postalCode,
        };

        const marketingOrder: MarketingOrder = {
            order: transformedValues,
            total: calculateTotal(values),
            deliveryAddress,
        };

        try {
            await createMarketingOrder(marketingOrder);
            AlertSwal.close(); // Ferme la modal de confirmation
            handleSubmitSuccess();
        } catch (error) {
            console.error('API error:', error);
            AlertSwal.fire({
                title: 'Erreur',
                text: 'Une erreur est survenue lors de la commande. Veuillez réessayer.',
                icon: 'error',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const showValidationModal = (total: number, values: MarketingOrderProduct) => {
        if (!total) return;

        AlertSwal.fire({
            title: 'Confirmation de commande',
            text: 'Merci de confirmer votre commande.\nSuite à celle-ci vous serez recontacté par les équipes de Sogys afin de finaliser celle-ci.',
            showCancelButton: true,
            confirmButtonText: (
                <span>
                    {isLoading ? <Loader width={16} height={16} /> : null}
                    Commander {currencyFormat(total, 2)} HT
                </span>
            ),
            cancelButtonText: 'Annuler',
            customClass: {
                cancelButton: 'btn btn-retour min-width',
                confirmButton: 'btn btn-continue min-width',
            },
            reverseButtons: true,
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            preConfirm: () => {
                return handleSubmit(values);
            },
        });
    };

    const calculateTotal = (values: MarketingOrderProduct) => {
        return Object.entries(values).reduce((acc, [product, details]) => {
            if (product === 'Cartes de visite') {
                const quantity = details?.quantity?.value ? parseInt(details.quantity.value, 10) : 0;
                const sets = Math.ceil(quantity / 250);
                return acc + 54.99 * sets;
            } else {
                const quantity = details?.quantity?.value ? parseInt(details.quantity.value, 10) : 0;
                return acc + (details?.price ?? 0) * quantity;
            }
        }, 0);
    };

    return (
        <>
            <div className="container mt-5">
                <Back title="Mon espace" url={routes.ROUTE_EA_INDEX} />
            </div>

            <div className="container py-5 px-4">
                <div className="container-creation py-4 row justify-content-center">
                    <div className="row">
                        <h2 className="d-flex align-items-center gap-2">
                            <IconShop fill="#5a5a5a" height={16} />
                            Boutique
                        </h2>
                    </div>

                    <AppForm
                        initialValues={initialValues}
                        onSubmit={(values) => showValidationModal(calculateTotal(values), values)}
                        validationSchema={marketingSchema}
                        validateOnMount
                        enableReinitialize
                    >
                        <MarketingForm />
                    </AppForm>
                </div>
            </div>
        </>
    );
};

export default Marketing;
