import { fr } from 'date-fns/locale';
import { MRcalcReport, SeriesCalcParams, seriescalc, SeriesCalcOutput } from './api';
import { format, parse } from 'date-fns';
import { GraphData, ScaledGraphData } from '../tools/graphHelper';
import * as enedisService from '../../services/enedis/enedis';
import { InstallationPV } from '../calculs/installationPV';

export const getMyPvgisSerie = async (input: SeriesCalcParams): Promise<Array<GraphData<PvGraphShortDataEntry>>> => {
    const result = await seriescalc(input);
    return groupByDay(result.outputs.hourly);
};

export type PvGraphShortDataEntry = {
    label: string;
    production: number;
};

export type PvGraphDataEntry = {
    datakey: string;
    label: string;
    consoEnedis: number;
    consoEnedisApres: number;
    consoEnedisApresBatterie: number;
    production: number;
    autoconsommation: number;
    revente: number;
    reventeAvecBatterie: number;
    chargeBatterie: number;
    restitutionBatterie: number;
};

export const groupByDay = (points: Array<SeriesCalcOutput>): Array<GraphData<PvGraphShortDataEntry>> => {
    if (!points || points.length <= 0) return [];

    console.time('groupByDay');

    // on transforme la liste en object indexé sur la date (ca va regrouper tous les jours)
    // la forme sera
    // const day = {
    //     "2023-09-19" : { value: 123, label : "2023-09-19"},
    //     "2023-09-18" : { value: 456, label : "2023-09-18"}
    // }
    // value contiendra la somme de cette journée.

    // pour faciliter la syntaxe, par la suite, on va transformer les points dont la valeur est une string, en number.
    // et je n'ai besoin que de la date.
    // WARNING! normalement tout est en W => PUISSANCE
    // on converti tout en KWh => ENERGIE
    // WARNING : il se trouve qu'on a des periodes d'une heure. donc P === energy coup de bol!
    // Sinon, il faudrait convertir (comme dans l'api enedis.)

    const pointsSimplified = points.map((p) => {
        return {
            time: p.time,
            p: (p.P ?? 0) / 1000,
            energyKWh: (p.P ?? 0) / 1000, // kWh
            'G(i)': (p['G(i)'] ?? 0) / 1000, // kW/m²
            'Gb(i)': (p['Gb(i)'] ?? 0) / 1000,
            'Gd(i)': (p['Gd(i)'] ?? 0) / 1000,
            'Gr(i)': (p['Gr(i)'] ?? 0) / 1000,
            // H_sun: p.H_sun,
            // T2m: p.T2m,
            // WS10m: p.WS10m,
            // Int: p.Int,
        };
    });

    const indexedHoursEntries: { [k in string]: PvGraphShortDataEntry } = {};
    pointsSimplified.forEach((element) => {
        const HH = element.time.slice(0, 11); // d est du format yyyyMMdd:HHmm, la date est composée des 10 premiers caractères
        let date = parse(HH, 'yyyyMMdd:HH', new Date(), { locale: fr });

        let label = format(date, 'HH', { locale: fr }) + 'h';
        if (indexedHoursEntries[HH] === undefined)
            indexedHoursEntries[HH] = {
                production: element.energyKWh,
                label,
            };
    });

    const groupedByDay: { [k in string]: GraphData<PvGraphShortDataEntry> } = {};
    const hourKeys = Object.keys(indexedHoursEntries);
    hourKeys.forEach((hourKey) => {
        const day = hourKey.slice(0, 8); // day est du format yyyyMMdd le jour est composée des 8 premiers caractères

        if (groupedByDay[day] === undefined)
            groupedByDay[day] = {
                key: hourKey,
                label: format(parse(day, 'yyyyMMdd', new Date()), 'dd MMMM yyyy', { locale: fr }),
                data: [],
                total: 0,
            };
        groupedByDay[day].total += indexedHoursEntries[hourKey].production;
        groupedByDay[day].data.push(indexedHoursEntries[hourKey]);
    });
    const days = Object.values(groupedByDay);
    return days;
};

export const buildGraphData = (
    installation: InstallationPV,
    pvgisData: Array<GraphData<PvGraphShortDataEntry>>,
    enedisData: enedisService.GroupedMesuresDetaillees,
    MaxChargeBatterie: number
): ScaledGraphData<PvGraphDataEntry> => {
    sanitise29Fev(pvgisData);
    sanitise29Fev(enedisData.byDay);

    const result: ScaledGraphData<PvGraphDataEntry> = { byDay: [], byMonth: [], byYear: [] };

    //#region calculs par jour

    const enedisStartYear = +enedisData.byYear[0].label;
    const pvgisStartYear = +pvgisData[0].key.slice(0, 4);
    const deltaYear = enedisStartYear - pvgisStartYear;

    // console.log('jours enedis = ' + enedisData.byDay.length + '   &   jours pvgis = ' + pvgisData.length);
    // console.log('de ' + enedisData.byDay[0].label + ' à ' + enedisData.byDay[enedisData.byDay.length - 1].label);

    let chargeBatterie = 0;
    for (let dayIndex = 0; dayIndex < enedisData.byDay.length - 1; ++dayIndex) {
        const hourData: Array<PvGraphDataEntry> = [];
        // les mois n'ont pas le même jour sur les févriers des bisextiles !
        const maxHours = Math.min(enedisData.byDay[dayIndex].data.length, pvgisData[dayIndex].data.length);
        for (let hourIndex = 0; hourIndex < maxHours; ++hourIndex) {
            const consoEnedis = enedisData.byDay[dayIndex].data[hourIndex].energieKWh;
            const prod = pvgisData[dayIndex].data[hourIndex].production;
            // dans le cas de la journée, on peut autoconsomer TOUT
            //(car à un moment de la journée c'est pas la nuit. Et c'est la nuit qui nous plafonne à 70%)
            const autoconsommation = Math.min(consoEnedis, prod);
            let revente = Math.max(0, prod - autoconsommation);
            let consoEnedisApres = consoEnedis - autoconsommation;
            let consoEnedisApresBatterie = consoEnedisApres;
            let reventeAvecBatterie = revente;
            let decharge = 0;

            if (revente > 0 && chargeBatterie < MaxChargeBatterie) {
                let recharge = revente;
                if (recharge + chargeBatterie > MaxChargeBatterie) recharge = MaxChargeBatterie - chargeBatterie;
                chargeBatterie += recharge;
                reventeAvecBatterie -= recharge;
            }

            if (revente === 0 && chargeBatterie > 0) {
                decharge = consoEnedis - autoconsommation;
                if (decharge > chargeBatterie) decharge = chargeBatterie;
                chargeBatterie -= decharge;
                consoEnedisApresBatterie -= decharge;
            }

            hourData.push({
                datakey: pvgisData[dayIndex].key,
                label: enedisData.byDay[dayIndex].data[hourIndex].label ?? 'undefined',
                consoEnedis,
                consoEnedisApres,
                consoEnedisApresBatterie,
                production: prod,
                autoconsommation,
                revente,
                reventeAvecBatterie,
                chargeBatterie,
                restitutionBatterie: decharge,
            });
        }
        result.byDay[dayIndex] = {
            key: pvgisData[dayIndex].key,
            label: enedisData.byDay[dayIndex].label,
            total: pvgisData[dayIndex].total,
            data: hourData,
        };
    }

    //#endregion calculs par jour

    //#region maintenant on groupe tout ca par mois.

    const groupedByMonth: { [k in string]: GraphData<PvGraphDataEntry> } = {};

    for (const day of result.byDay) {
        // Les données de day.data[i].datakey sont du format yyyyMMdd
        // avec les anneés pvgis.
        // Il faut mettre les années enedis !
        const dayKey = day.data[0].datakey.slice(0, 8);
        const dayIndex = day.data[0].datakey.slice(6, 8);
        const monthKey = day.data[0].datakey.slice(0, 6);
        const monthIndex = +day.data[0].datakey.slice(4, 6);

        const currentPvGisYear = +day.data[0].datakey.slice(0, 4);

        // Si le mois n'existait pas :
        if (groupedByMonth[monthKey] === undefined) {
            groupedByMonth[monthKey] = {
                key: dayKey,
                data: [],
                total: 0,
                label: format(new Date(currentPvGisYear + deltaYear, monthIndex - 1), 'MMMM yyyy', { locale: fr }),
            };
        }

        // on va créer des jours à partir des 24 heures du jour
        // réinitialise le grouped day
        const groupedDay: PvGraphDataEntry = {
            datakey: dayKey,
            label: dayIndex,
            consoEnedis: 0,
            consoEnedisApres: 0,
            consoEnedisApresBatterie: 0,
            production: 0,
            autoconsommation: 0,
            revente: 0,
            reventeAvecBatterie: 0,
            chargeBatterie: 0,
            restitutionBatterie: 0,
        };

        // eslint-disable-next-line no-loop-func
        day.data.forEach((element) => {
            groupedDay.consoEnedis += element.consoEnedis;
            groupedDay.consoEnedisApres += element.consoEnedisApres;
            groupedDay.consoEnedisApresBatterie += element.consoEnedisApresBatterie;
            groupedDay.production += element.production;
            groupedDay.autoconsommation += element.autoconsommation;
            groupedDay.revente += element.revente;
            groupedDay.reventeAvecBatterie += element.reventeAvecBatterie;
            groupedDay.restitutionBatterie += element.restitutionBatterie;
            groupedByMonth[monthKey].total += element.consoEnedis;
        });

        groupedByMonth[monthKey].data.push(groupedDay);
    }

    result.byMonth = Object.values(groupedByMonth);

    //#endregion

    //#region maintenant on groupe tout ca par an

    const groupedByYear: { [k in string]: GraphData<PvGraphDataEntry> } = {};

    for (const month of result.byMonth) {
        // Les données de day.data[i].datakey sont du format yyyyMMdd
        // avec les anneés pvgis.
        // Il faut mettre les années enedis !
        const monthKey = month.data[0].datakey.slice(0, 6);
        const monthIndex = +month.data[0].datakey.slice(4, 6);
        const yearpv = month.data[0].datakey.slice(0, 4);
        const yearKey = +yearpv + deltaYear;

        // Si l'année n'existait pas :
        if (groupedByYear[yearKey] === undefined) {
            groupedByYear[yearKey] = {
                key: yearKey + '',
                data: [],
                total: 0,
                label: yearKey + '',
            };
        }

        // on va créer des mois à partir des 30 jours du mois
        // réinitialise le grouped day
        const groupedMonth: PvGraphDataEntry = {
            datakey: monthKey,
            label: format(new Date(yearKey, monthIndex - 1), 'MMMM', { locale: fr }),
            consoEnedis: 0,
            consoEnedisApres: 0,
            consoEnedisApresBatterie: 0,
            production: 0,
            autoconsommation: 0,
            revente: 0,
            reventeAvecBatterie: 0,
            chargeBatterie: 0,
            restitutionBatterie: 0,
        };
        // eslint-disable-next-line no-loop-func
        month.data.forEach((element) => {
            groupedMonth.consoEnedis += element.consoEnedis;
            groupedMonth.consoEnedisApres += element.consoEnedisApres;
            groupedMonth.consoEnedisApresBatterie += element.consoEnedisApresBatterie;
            groupedMonth.production += element.production;
            groupedMonth.autoconsommation += element.autoconsommation;
            groupedMonth.revente += element.revente;
            groupedMonth.reventeAvecBatterie += element.reventeAvecBatterie;
            groupedMonth.restitutionBatterie += element.restitutionBatterie;
            groupedByYear[yearKey].total += element.consoEnedis;
        });

        groupedByYear[yearKey].data.push(groupedMonth);

        result.byYear = Object.values(groupedByYear);
    }

    //#endregion

    // Pour tester les calculs et créer les formules pour les préco.
    // Ne pas supprimer pour l'instant.
    // if (result.byDay) {
    //     let totalAvant = 0,
    //         totalApres = 0,
    //         totalApresApres = 0;
    //     for (let i = borneByDay.min; i < borneByDay.max; ++i) {
    //         for (const allData of result.byDay[i].data) {
    //             totalAvant += allData.consoEnedis;
    //             totalApres += allData.consoEnedisApres;
    //             totalApresApres += allData.consoEnedisApresBatterie;
    //         }
    //     }
    //     const jours = borneByDay.max - borneByDay.min;
    //     const gainBatterie = totalApres - totalApresApres;
    //     const gainBatterieParJour = gainBatterie / jours;
    //     const efficaciteBatt = gainBatterieParJour / MaxChargeBatterie;
    //     console.log('Big total = ' + JSON.stringify({ totalAvant, totalApres, totalApresApres }, null, 2));
    //     console.log('par jours = ' + JSON.stringify({ gainBatterie, gainBatterieParJour }, null, 2));
    //     console.log('ratio / batt = ' + JSON.stringify({ efficaciteBatt }, null, 2));
    // }
    return result;
};

type MonthRadiationEntry = Required<Omit<MRcalcReport['outputs']['monthly'][0], 'year' | 'month'>>;
export type MonthlyRadiation = { month: number; radiation: MonthRadiationEntry };
export type MonthlyRadiations = Array<MonthlyRadiation>;

const sanitise29Fev = <T extends { label: string }>(pvgisData: Array<GraphData<T>>): void => {
    const feb29Indexes = [59, 424, 789];

    for (const feb29 of feb29Indexes) {
        if (pvgisData.length >= feb29 && pvgisData[feb29].label.startsWith('29')) {
            // pvgis est sur 3 ans max, donc une seule année bisextile.
            pvgisData.splice(feb29, 1);
            break;
        }
    }
};
