import { getData } from '../apiParticulierService';
import { IndicateurCommune, Mutation, Paginated } from './types';
import QueryString from 'qs';

export const indicateurCommuneTriennal = async (codeInsee: string, year: string): Promise<Paginated<IndicateurCommune>> => {
    const url = `tech/foncier/indicateurs/dv3f/communes/triennal/${codeInsee}/?annee=${year}`;

    return await getData(url);
};

export const getMutations = async (params: Record<string, string>): Promise<Paginated<Mutation>> => {
    const qs = QueryString.stringify(params);
    const url = 'tech/foncier/dvf_opendata/mutations/?' + qs;

    return await getData(url);
};
