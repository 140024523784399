import * as storageService from '../../../services/localStorageService';

export const ceilingInsulationPresence = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        ceilingInsulationPresence: action,
    });

    storageService.setAuditValue(6, isValid, 'ceilingInsulationPresence', action);

    // Réinitialisation des champs associés
    storageService.removeAuditValue(6, 'ceilingInsulationThickness');
    storageService.removeAuditValue(6, 'ceilingInsulationResistance');
    storageService.removeAuditValue(6, 'ceilingInsulationAge');

    setValues({
        ...values,
        ceilingInsulationPresence: action,
        ceilingInsulationThickness: null,
        ceilingInsulationResistance: '',
        ceilingInsulationAge: '',
    });
};

export const floorInsulationPresence = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        floorInsulationPresence: action,
    });

    storageService.setAuditValue(6, isValid, 'floorInsulationPresence', action);
    // Réinitialisation des champs enfants
    storageService.removeAuditValue(6, 'floorInsulationThickness');
    storageService.removeAuditValue(6, 'floorInsulationResistance');
    storageService.removeAuditValue(6, 'floorInsulationAge');
    setValues({
        ...values,
        floorInsulationPresence: action,
        floorInsulationThickness: null,
        floorInsulationResistance: '',
        floorInsulationAge: '',
    });
};

export const rampantIsolated = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        rampantIsolated: action,
    });

    storageService.setAuditValue(6, isValid, 'rampantIsolated', action);

    if (values.rampantIsolated !== '') {
        setValues({
            ...values,
            rampantIsolated: action,
        });
    }
};

export const sharedWall = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        sharedWall: action,
    });

    storageService.setAuditValue(6, isValid, 'sharedWall', action);

    if (values.sharedWall !== '') {
        setValues({
            ...values,
            sharedWall: action,
        });
    }
};

export const sharedHouseWall = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        sharedHouseWall: action,
    });

    storageService.setAuditValue(6, isValid, 'sharedHouseWall', action);

    if (values.sharedHouseWall !== '') {
        setValues({
            ...values,
            sharedHouseWall: action,
        });
    }
};

export const unheatedRoomPresence = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    storageService.setAuditValue(6, isValid, 'unheatedRoomPresence', action);
    // Réinitialisation des champs associés
    storageService.setAuditValue(6, isValid, 'unheatedRoomInsulationPresence', false);
    storageService.setAuditValue(6, isValid, 'unheatedRoomInsulationExterior', false);
    storageService.removeAuditValue(6, 'unheatedRoomInsulationThickness');
    storageService.removeAuditValue(6, 'unheatedRoomInsulationResistance');
    storageService.removeAuditValue(6, 'unheatedRoomInsulationAge');

    setValues({
        ...values,
        unheatedRoomPresence: action,
        unheatedRoomComposition: '0',
        customUnheatedRoomInsulation: 'withoutUnheatedRoomInsulation',
        unheatedRoomInsulationThickness: null,
        unheatedRoomInsulationResistance: '',
        unheatedRoomInsulationAge: '',
    });
};
