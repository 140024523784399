import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './assets/style/global.scss';
import { caclulationOrder, checkThemeOrder, displayOrder } from './services/calculs/theme';

// This will make crash code at first run, if those 2 variables are not correctly defined.
checkThemeOrder(displayOrder, 'displayOrder');
checkThemeOrder(caclulationOrder, 'caclulationOrder');

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
