import { PropertyType } from '../../../services/localStorageService';
import * as storageService from '../../../services/localStorageService';

export const ventilationInstallationDate = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        ventilationInstallationDate: action,
    });

    storageService.setAuditValue(3, isValid, 'ventilationInstallationDate', action);
};

export const heaterOutdoorProbe = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        heaterOutdoorProbe: action,
    });

    storageService.setAuditValue(3, isValid, 'heaterOutdoorProbe', action);
};

export const heaterProgrammer = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        heaterProgrammer: action,
    });

    storageService.setAuditValue(3, isValid, 'heaterProgrammer', action);
};

export const heaterAlternateSystem = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        heaterAlternateSystem: action,
    });

    storageService.setAuditValue(3, isValid, 'heaterAlternateSystem', action);
};

export const heaterHotWaterIndependent = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        heaterHotWaterIndependent: action,
        heaterHotWaterIndependentBathHeaterEmplacement: '',
    });

    storageService.setAuditValue(3, isValid, 'heaterHotWaterIndependent', action);

    // Réinitialisation du champ heaterHotWaterIndependentBathHeaterEmplacement
    storageService.setAuditValue(3, isValid, 'heaterHotWaterIndependentBathHeaterEmplacement', '', '');
};

export const photovoltaique = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        photovoltaique: action,
    });

    storageService.setAuditValue(3, isValid, 'photovoltaique', action);

    // Purge photovoltaique
    if (values.photovoltaique !== '') {
        storageService.removeAuditValue(3, 'photovoltaiquePower');
        storageService.removeAuditValue(3, 'photovoltaiqueConsumption');

        setValues({
            ...values,
            photovoltaique: action,
            photovoltaiquePower: undefined,
            photovoltaiqueConsumption: undefined,
        });
    }
};

export const heaterHotWaterThermodynamic = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        heaterHotWaterThermodynamic: action,
    });

    storageService.setAuditValue(3, isValid, 'heaterHotWaterThermodynamic', action);
    storageService.setAuditValue(3, isValid, 'heaterHotSolarWaterThermodynamic', (values.heaterHotSolarWaterThermodynamic = false));

    if (action) {
        setValues({
            ...values,
            heaterHotWaterThermodynamic: action,
            heaterHotSolarWaterThermodynamic: values.heaterHotSolarWaterThermodynamic,
        });
    }
};

export const heaterHotSolarWaterThermodynamic = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        heaterHotSolarWaterThermodynamic: action,
    });

    storageService.setAuditValue(3, isValid, 'heaterHotSolarWaterThermodynamic', action);
    storageService.setAuditValue(3, isValid, 'heaterHotWaterThermodynamic', (values.heaterHotWaterThermodynamic = false));

    if (action) {
        setValues({
            ...values,
            heaterHotSolarWaterThermodynamic: action,
            heaterHotWaterThermodynamic: values.heaterHotWaterThermodynamic,
        });
    }
};

export const heaterHotWaterIncludedAccumulation = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        heaterHotWaterIncludedAccumulation: action,
    });

    storageService.setAuditValue(3, isValid, 'heaterHotWaterIncludedAccumulation', action);
};

export const heaterHotWaterIncludedInstant = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    setValues({
        ...values,
        heaterHotWaterIncludedInstant: action,
    });

    storageService.setAuditValue(3, isValid, 'heaterHotWaterIncludedInstant', action);
};

export const heaterHealWholeHouse = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    // Mise à jour de la valeur dans le Formik
    setValues({
        ...values,
        heaterType2: '',
        heaterHealWholeHouse: action,
        heaterSurface: null,
        multipleHydronicZoneHeatingSystem: '',
        heaterSubscriptionGas: '',
        gasProvider: '',
        heaterBrand: '',
        condensingHeater: '',
    });

    // Mise à jour local storage
    storageService.setAuditValue(3, isValid, 'heaterHealWholeHouse', action ? '1' : '2', action ? 'oui' : 'non');
    storageService.setAuditValue(3, isValid, 'heaterSurface', null);
    storageService.setAuditValue(3, isValid, 'multipleHydronicZoneHeatingSystem', '');
    // Reset valeur champ energyCostsGasFuelWood étape 4
    storageService.setAuditValue(4, false, 'energyCostsGasFuelWood', null);
    // Mise à jour du calcul énergétique étape 4
    storageService.checkPropertyExistThenCreateOrRender(4, 'energyCostsElectric', `Dépense annuelle en électricité`, PropertyType['number']);
    storageService.setAuditValue(4, false, 'energyCostsSum', storageService.getAudit().step4.energyCostsElectric.value);

    // Purge heaterType2 & Gas invoices
    storageService.removeAuditValue(3, 'heaterType2', true);
    storageService.removeAuditValue(3, 'heaterSubscriptionGas', true);
    storageService.removeAuditValue(3, 'gasProvider', true);
    storageService.removeAuditValue(3, 'heaterBrand');
    storageService.removeAuditValue(3, 'condensingHeater', true);

    storageService.removeAuditValue(8, 'energyCostsGasInvoice1');
    storageService.removeAuditValue(8, 'energyCostsGasInvoice2');
    storageService.removeAuditValue(8, 'energyCostsGasInvoice3');
};

export const multipleHydronicZoneHeatingSystem = (values: any, isValid: boolean, setValues: any, action: boolean) => {
    // Mise à jour de la valeur dans le Formik
    setValues({
        ...values,
        multipleHydronicZoneHeatingSystem: action,
    });

    storageService.setAuditValue(3, isValid, 'multipleHydronicZoneHeatingSystem', action);
};
