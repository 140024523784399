import React from 'react';
import { useCounter } from '../../services/tools/auditTools';

interface ModalProps {
    message?: string;
    timer: number; // seconds
}

export const ModalCounter: React.FC<ModalProps> = ({ message, timer }) => {
    const counter = useCounter(timer);

    return (
        <div>
            {message ? <p>{message}</p> : <p>Merci de patienter...</p>}
            <div className="swal2-loader mt-3 mx-auto d-flex"></div>
            <p className="mt-3">{counter}</p>
        </div>
    );
};
