import * as Yup from 'yup';

const schemaArrow = Yup.object().shape({
  lineType: Yup.object()
    .test('is-valid-line', '', (value) => {
      if (value && typeof value === 'object' && value.label && value.value) {
        return true;
      }
      return false;
    })
    .required(),
  endPointType: Yup.object()
    .test('is-valid-endPoint', '', (value) => {
      if (value && typeof value === 'object' && value.label && value.value) {
        return true;
      }
      return false;
    })
    .required(),
  color: Yup.object()
    .test('is-valid-color', '', (value) => {
      if (value && typeof value === 'object' && value.label && value.value) {
        return true;
      }

      return false;
    })
    .required()
});

export default schemaArrow;
